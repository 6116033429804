import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss'

import { moibleMedia } from '../../constants/index'

const useStyles = createUseStyles({
  times: {
    height: '32px',
    display: 'flex',
    backgroundColor: '#efeff4',
    alignItems: 'center',
    borderBottom: '1px solid #D5D5D9',
    whiteSpace: 'nowrap',
    padding: '0 20px 0 15px',
    [moibleMedia]: {
      height: '100%',
      whiteSpace: 'normal',
      padding: 0,
      display: 'block',
      backgroundColor: '#fff'
    }
  },
  time: {
    width: '280px',
    minWidth: '280px',
    [moibleMedia]: {
      display: 'flex',
      width: '50px',
      minWidth: '50px',
      height: '80px',
      paddingLeft: '5px'
    }
  },
  currentTime: {
    position: 'absolute',
    top: '22px',
    height: '100vh',
    width: '2px',
    backgroundColor: '#ff0039',
  },
  currentTimeDisplay: {
    position: 'absolute',
    top: '-16px',
    left: '-20px',
    fontSize: '13px',
    width: '41px',
    height: '20px',
    textAlign: 'center',
    color: '#fff',
    background: '#ff0039',
    lineHeight: '20px',
    borderRadius: '2px',
  },
});

function TimeBar({ isMobile, firstHour, lastHour, currentTimePosition, currentTimeDisplay }) {
  const classes = useStyles();
  const times = []

  for (let hour = firstHour; hour <= lastHour; hour++) {
    times.push(<div key={hour} className={classes.time} style={{}}>{hour % 24}:00</div>)
  }

  return (
    <div>
      <div className={classes.times}>{times}</div>
      {!isMobile && <div
        className={classes.currentTime}
        style={{
          left: currentTimePosition,
          display: currentTimePosition ? 'block' : 'none'
        }}
      >
        <div className={classes.currentTimeDisplay}>
          {currentTimeDisplay}
        </div>
      </div>}
    </div>
  );
}


TimeBar.propTypes = {
  isMobile: PropTypes.bool,
  firstHour: PropTypes.number,
  lastHour: PropTypes.number,
  currentTimePosition: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  currentTimeDisplay: PropTypes.string
};

export default TimeBar;
