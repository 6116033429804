import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl'
import { createUseStyles } from 'react-jss'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '120px',
    // height: 'calc(100% - 64px)',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.9px',
    zIndex: 1,
    paddingBottom: '32px',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    minHeight: '120px',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.9px',
    color: '#888888',
    borderBottom: '1px solid #dddde7',
    marginBottom: '0px',
    '& .doctors-0, .cancelled': {
      minHeight: '119px',
      maxHeight: '120px',
    },
    '& .doctors-1': {
      minHeight: '120px',
      maxHeight: '120px'
    },
    '& .doctors-2': {
      minHeight: '240px'
    },
    '& .doctors-3': {
      minHeight: '360px'
    },
    '& .doctors-4': {
      minHeight: '480px'
    },
    '& .doctors-5': {
      minHeight: '600px'
    }
  },
  doctor: {
    display: 'flex',
    minHeight: '120px',
    alignItems: 'center',
    border: '1px solid #dddde7',
    justifyContent: 'center',
    borderTop: 'none',
    borderRight: 'none',
    '&:last-child': {
      minHeight: '119px',
      borderBottom: 'none'
    }
  },
  typeLabel: {
    display: 'flex',
    minWidth: '40px',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '120px',
    maxHeight: '120px',
    padding: '8px',
    wordBreak: 'break-all',
  },
  doctorWrap: {
    flexDirection: 'column',
    minWidth: '80px',
    alignItems: 'stretch',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '120px',
    color: '#888888',
  },
  wrapperMobile: {
    width: '100vw',
    height: '60px',
    overflowY: 'hidden',
    overflowX: 'scroll',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 9,
    position: 'fixed',
    top: '92px',
    left: 0
  },
  mobileButton: {
    width: '76px',
    minWidth: '76px',
    height: '60px',
    backgroundColor: '#ffffff',
    color: '#888888',
    fontSize: '14px',
    letterSpacing: '0.9px',
    padding: '10px',
    wordWrap: 'break-word',
    textAlign: 'center',
    borderRight: '1px solid #e4e4e4'
  }
});

function DoctorsList({ firstHour, lastHour, doctors, doctorShifts, appointmentTypes, ui, rowHeights, setUiState }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const classes = useStyles();
  const cancelledRowHeight = Math.max(120, rowHeights.cancelled?.minHeight ?? 0)

  if (!doctors) {
    return ('Loading...')
  }

  const getDoctorCount = (type) => {
    const count = doctors.filter(doctor => (
      doctor.active && (doctor.appointmentType === type && ((doctorShifts[doctor.id] && doctorShifts[doctor.id].length) || ui.noShifts))
    )).length

    return count
  }

  if (isMobile) {
    const viewingDoctor = ui.viewingDoctor || (doctors[0]?.id ?? undefined)
    return (
      <div className={classes.wrapperMobile}>
        {appointmentTypes.map((type, key) => {
          let doctorCount = 0
          const doctorElements = doctors.map((doctor, key) => {
            if (doctor.active) {
              if (doctor.appointmentType !== type || (!ui.noShifts && (!doctorShifts[doctor.id] || !doctorShifts[doctor.id].length))) {
                return null
              }
              doctorCount++
              return <div
                key={key}
                className={classes.mobileButton}
                onClick={() => setUiState({
                  ...ui,
                  viewingDoctor: doctor.id
                })}
                style={{
                  borderBottom: viewingDoctor === doctor.id ? '2px solid blue' : ''
                }}
              >
                {doctor.displayName}
              </div>
            }

            return null
          })
          if (!doctorCount) {
            doctorElements.push(<div
              key={type}
              className={classes.mobileButton}
              onClick={() => setUiState({
                ...ui,
                viewingDoctor: type
              })}
              style={{
                borderBottom: viewingDoctor === type ? '2px solid blue' : ''
              }}
            >
              <FormattedMessage id={`appointment.sidebar.appointmentType.${type}`} />
            </div>)
          }
          return doctorElements
        }).concat([
          <div
            key='cancelled'
            className={classes.mobileButton}
            onClick={() => setUiState({
              ...ui,
              viewingDoctor: 'cancelled'
            })}
            style={{
              borderBottom: viewingDoctor === 'cancelled' ? '2px solid red' : ''
            }}
          >
            <FormattedMessage id='appointment.sidebar.cancelled' />
          </div>
        ])}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {appointmentTypes.map((type, index) => {
        let typeRowHeight = 0
        const doctorElements = doctors.map((doctor, index) => {
          if (doctor.active) {
            if (doctor.appointmentType !== type || (!ui.noShifts && (!doctorShifts[doctor.id] || !doctorShifts[doctor.id].length))) {
              return null
            }

            const rowHeight = rowHeights[doctor.id].minHeight || 120
            typeRowHeight += parseInt(rowHeight, 10)

            return <div
              key={index}
              className={classes.doctor}
              style={{
                minHeight: rowHeight
              }}
            >
              {doctor.displayName}
            </div>
          }

          return null
        })
        if (!typeRowHeight) {
          const rowHeight = rowHeights[type]?.minHeight || 120
          typeRowHeight += rowHeight

        }

        typeRowHeight = Math.max(120, typeRowHeight)
        return <div
          key={index}
          className={`${classes.typeContainer} ${'doctors-' + getDoctorCount(type)}`}
          style={{
            minHeight: getDoctorCount(type) === 0 ? `${typeRowHeight}px` : null,
            maxHeight: `${typeRowHeight}px`,
          }}
        >
          <div
            className={classes.typeLabel}
            style={{
              writingMode: getDoctorCount(type) === 0 ? 'unset' : 'vertical-lr',
              textOrientation: getDoctorCount(type) === 0 ? 'unset' : 'upright',
            }}
          >
            <FormattedMessage id={`appointment.sidebar.appointmentType.${type}`} />
          </div>
          <div
            className={classes.doctorWrap}
            style={{
              display: getDoctorCount(type) === 0 ? 'none' : 'flex'
            }}
          >
            {doctorElements}
          </div>
        </div>
      })}
      <div
        className={`${classes.typeContainer} cancelled`}
        style={{
          minHeight: `${cancelledRowHeight}px`
        }}
      >
        <div
          className={classes.typeLabel}
          style={{
            writingMode: 'unset',
            textOrientation: 'unset',
          }}
        >
          <FormattedMessage id='appointment.sidebar.cancelled' />
        </div>
      </div>
    </div>
  )
}

DoctorsList.propTypes = {
  firstHour: PropTypes.number,
  lastHour: PropTypes.number,
  doctors: PropTypes.arrayOf(PropTypes.object.isRequired),
  doctorShifts: PropTypes.object.isRequired,
  appointmentTypes: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  rowHeights: PropTypes.object.isRequired,
  setUiState: PropTypes.func.isRequired
};

export default DoctorsList;
