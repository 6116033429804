const i18n = {
  'step': {
    'action': {
      'create': '建立',
      'approve': '批准',
      'reject': '否決',
      'modify': '修改',
      'update': '更新',
      'add': '新增',
      'remove': '移除',
      'reset': '重設',
      'confirm': '確認',
      'void': '作廢'
    }
  },
}
export default i18n
