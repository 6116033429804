import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import SelectUserDialog from './SelectUserDialog';
import TabContainer from '../containers/TabContainer/TabContainer'
import { objectToArray } from '../modules/data';
import ButtonProgress from './ButtonProgress';

const department = []
if (['santea', 'lexcellence'].includes(process.env.BRANCH_ENV)) {
  department.push(
    'nurse',
    'salesRep',
    'customerService',
    'management',
    'marketing',
    'hr',
    'financial',
    'itDept',
    // 'nutritionist',
    // 'psychologist',
  )
} else if (['reborn'].includes(process.env.BRANCH_ENV)) {
  department.push('nurse', 'salesRep')
} else if (['ibeautyTaichung'].includes(process.env.BRANCH_ENV)) {
  department.push('nurse', 'salesRep', 'customerService', 'financial')
} else {
  department.push('nurse', 'salesRep', 'customerService')
}

export default function EditApproval({ handleClose }) {
  const { formatMessage } = useIntl()
  const [newData, setNewData] = useState(null)
  const [userDialogData, setUserDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const userMapping = useSelector(state => state.firebase.data.users);
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const type = department[activeTabIndex]

  function onStepUsersChanged(index, users, step) {
    let updateData = { ...newData[type] }
    let newUsers = []
    for (const user of users) {
      newUsers.push(user.id)
    }

    updateData[index][step].users = newUsers

    setNewData({ ...newData, [type]: { ...updateData } })
  }

  function showSelectUserPopup(field, data, step) {
    setUserDialogData({ index: field, user: data, step })
  }

  async function saveApproval() {
    setLoadingApprove(true)

    try {
      await (firebase.functions().httpsCallable('saveApproval'))({ ...newData })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('generateApprovals').onSnapshot(snapshot => {
      let snapshots = {}
      snapshot.forEach(doc => {
        snapshots[doc.id] = { ...doc.data() }
      });
      setNewData(snapshots)
    }, err => { })

    return () => unsubscribe()
  }, []);

  if (!newData) {
    return ('Loading...')
  }

  const fields = [
    { name: 'managerLeaves', order: 0 },
    { name: 'managerOvertimes', order: 1 },
    { name: 'managerPunchClockRevise', order: 2 },
    { name: 'normalLeaves', order: 3 },
    { name: 'normalOvertimes', order: 4 },
    { name: 'normalPunchClockRevise', order: 5 },
  ].map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field) {
    const data = { ...newData[type][field.name] }
    const newData1 = data[1] ? { ...data[1] } : {}
    const newData2 = data[2] ? { ...data[2] } : {}
    const step1 = newData1.users ? newData1.users.map(user => userMapping[user].displayName).join(', ') : ''
    const step2 = newData2.users ? newData2.users.map(user => userMapping[user].displayName).join(', ') : ''

    if (['managerLeaves', 'normalLeaves'].includes(field.name)) {
      return <div style={{ display: 'flex', flexDirection: 'row', margin: '20px 0' }} key={field.name} >
        <Grid item xs={6} sm={4} md={4}>
          <TextField
            multiline={field.multiline}
            type="text"
            disabled
            label={formatMessage({ id: 'header.approval.root' })}
            value={formatMessage({ id: `header.approval.type.${field.name}` })}
            fullWidth
            size="small"
            variant="outlined"
          />
        </Grid>
        <Grid sx={{ margin: '0 10px' }} item xs={6} sm={4} md={4}>
          <TextField
            multiline={field.multiline}
            type="text"
            label={newData1.name}
            value={step1}
            fullWidth
            size="small"
            variant="outlined"
            onClick={(e) => showSelectUserPopup(field.name, newData1.users, '1')}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <TextField
            multiline={field.multiline}
            type="text"
            label={newData2.name}
            value={step2}
            fullWidth
            size="small"
            variant="outlined"
            onClick={(e) => showSelectUserPopup(field.name, newData2.users, '2')}
          />
        </Grid>
      </div>
    } else {
      return <div style={{ display: 'flex', flexDirection: 'row', margin: '20px 0' }} key={field.name} >
        <Grid sx={{ marginRight: '10px' }} item xs={6} sm={4} md={4}>
          <TextField
            multiline={field.multiline}
            type="text"
            disabled
            label={formatMessage({ id: 'header.approval.root' })}
            value={formatMessage({ id: `header.approval.type.${field.name}` })}
            fullWidth
            size="small"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={8} md={8}>
          <TextField
            multiline={field.multiline}
            type="text"
            label={newData1.name}
            value={step1}
            fullWidth
            size="small"
            variant="outlined"
            onClick={(e) => showSelectUserPopup(field.name, newData1.users, '1')}
          />
        </Grid>
      </div>
    }
  }

  const tabs = [];

  for (const key of department) {
    tabs.push({
      label: formatMessage({ id: process.env.BRANCH_ENV === 'santea' ? `tab.schedule.santea.${key}` : `tab.schedule.${key}` }),
      component: <div style={{ marginTop: '10px' }}>{fields.map(field => createField(field))}</div>
    })
  }

  const onTabSelected = (tabIndex) => {
    setActiveTabIndex(tabIndex)
  };

  let defaultSelect = 0

  return (
    <div style={{ flexGrow: 1 }}>
      {userDialogData && <SelectUserDialog
        handleClose={() => setUserDialogData(null)}
        handleSave={onStepUsersChanged}
        users={objectToArray(userMapping).filter(u => u.active)}
        defaultSelectedItems={userDialogData.user}
        index={userDialogData.index}
        step={userDialogData.step}
      />}
      <Dialog
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth='lg'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{formatMessage({ id: 'header.approval.title' })}</DialogTitle>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
          <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
        </div>
        <DialogActions>
          <ButtonProgress handleClick={() => saveApproval()} handleClose={handleClose} loading={loadingApprove} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditApproval.propTypes = {
  handleClose: PropTypes.func.isRequired
};
