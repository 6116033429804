import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { createUseStyles } from 'react-jss'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AddIcon from '@mui/icons-material/Add';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import EditIcon from '@mui/icons-material/Edit';

import TimeBar from './TimeBar'
import DoctorsList from './DoctorsList'
import ScheduleRow from './ScheduleRow'
import AppointmentPopover from './AppointmentPopover'
import { objectToArray } from '../../modules/data'
import { timeToMinutes, minutesToLeft, minutesToHeight, minutesToWidth } from '../../modules/time'
import AppointmentStatusDialog from '../../components/AppointmentStatusDialog';
import { moibleMedia } from '../../constants/index'
import ContextStore from '../../modules/context';

dayjs.extend(utc)
dayjs.extend(timezone)

const commentStyles = {
  container: {
    // position: 'absolute',
    width: '215px',
    height: 'auto',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #ffc107',
    zIndex: 1,
    boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)',
  },
  header: {
    height: '24px',
    lineHeight: '24px',
    backgroundColor: '#ffc107',
    padding: '0 8px',
    fontSize: '15px',
    fontWeight: '500',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: '16px',
    height: '16px',
  },
  body: {
    'minHeight': '24px',
    'fontSize': '15px',
    'fontWeight': '500',
    'textAlign': 'left',
    'color': 'rgba(0, 0, 0, 0.54)',
    'padding': '8px',
  },
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '.9px',
    paddingTop: '32px',
    zIndex: 2,
    [moibleMedia]: {
      height: '100%',
      width: '100%',
    }
  },
  rowBody: {
    overflow: 'scroll',
    position: 'absolute',
    top: '32px',
    left: '120px',
    width: 'calc(100% - 120px)',
    height: 'calc(100% - 32px)',
    zIndex: 1,
    [moibleMedia]: {
      height: '100%',
      width: 'calc(100% - 50px)',
      top: 0,
      left: '50px',
    }
  },
  timebarContainer: {
    position: 'absolute',
    left: '120px',
    top: 0,
    width: 'calc(100% - 120px)',
    minWidth: 'calc(100% - 120px)',
    // height: '100%',
    zIndex: 1,
    fontSize: '15px',
    textAlign: 'left',
    color: '#6e747d',
    display: 'flex',
    alignItems: 'top',
    pointerEvents: 'none',
    flexShrink: 0,
    overflow: 'hidden',
    [moibleMedia]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50px',
      minWidth: '50px',
      // zIndex: 2,
      height: '100vh',
      backgroundColor: '#fff',
      boxShadow: 'none',
      fontSize: '12px',
      fontWeight: 500,
      letterSpacing: '0.8px',
      textAlign: 'center',
      alignContent: 'center',
      color: '#939393',
      display: 'flex',
      flexDirection: 'column',
      padding: '13px 0 0 0',
    }
  },
  wrapper: {
    position: 'absolute',
    overflow: 'hidden',
    top: '32px',
    height: 'calc(100% - 32px)',
    flexShrink: 0,
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.5), inset 0 1px 0 0 rgba(0, 0, 0, 0.1)',
    zIndex: 2,
    backgroundColor: '#efeff4',
    [moibleMedia]: {
      top: 0
    }
  },
  header: {
    position: 'absolute',
    height: '32px',
    width: '120px',
    padding: '8px 0',
    top: 0,
    left: 0,
    borderBottom: '1px solid #dddde7',
    color: '#8F8F91',
    background: '#efeff4',
    zIndex: 2,
    textAlign: 'center',
    [moibleMedia]: {
      display: 'none',
    }
  },
  commentMenu: {
    padding: 0
  }
});

function ScheduleDay({ doctors, doctorShifts, doctorLeaves, appointmentTypes, appointments, patientList, comments }) {
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const childRef = useRef()
  const doclist = useRef()
  const timebar = useRef()
  const [menuInfo, setMenuInfo] = useState(null)
  const menuOpen = Boolean(menuInfo);
  const [commentCenuInfo, setCommentCenuInfo] = useState(null)
  const commentMenuOpen = Boolean(commentCenuInfo);
  const [dialogData, setDialogData] = useState(null)
  const firstHour = useRef(9)
  const lastHour = useRef(21)
  let rowAppointments = {}
  const [currentTimePosition, setCurrentTimePosition] = useState(0)
  const [currentTimeDisplay, setCurrentTimeDisplay] = useState('')
  const { uiState, setUiState } = useContext(ContextStore)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  useEffect(() => {
    const timer = dayjs(uiState.date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? setInterval(updateCurrentTime, 5000) : null
    updateCurrentTime()
    return () => {
      if (timer) { clearInterval(timer) }
    };
  }, [uiState.date]);

  const handleScroll = (scrollTop, scrollLeft) => {
    if (isMobile) {
      timebar.current.scrollTop = scrollTop
    } else {
      if (doclist && doclist.current) {
        doclist.current.scrollTop = scrollTop
      }
      if (timebar && timebar.current) {
        timebar.current.scrollLeft = scrollLeft
      }
    }
  }

  const updateCurrentTime = () => {
    if (dayjs(uiState.date).format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD')) {
      setCurrentTimePosition(0)
      setCurrentTimeDisplay('')
    }

    let position
    let currentTWTime = dayjs().tz('Asia/Taipei')

    if (currentTWTime.format('YYYY-MM-DD') === dayjs(uiState.date).format('YYYY-MM-DD')) {
      const minutesSinceStart = currentTWTime.diff(currentTWTime.set('hours', firstHour.current || 10).set('minutes', 0), 'minutes')
      if (minutesSinceStart < lastHour.current * 60) {
        position = minutesToLeft(minutesSinceStart)
      }
    }

    if (currentTimePosition !== `${position}px`) {
      setCurrentTimePosition(position ? `${position}px` : false)
      setCurrentTimeDisplay(currentTWTime.format('HH:mm'))
    }
  };

  function selectComment(event, comment) {
    const containerPosition = childRef.current.getBoundingClientRect()
    setUiState({
      ...uiState,
      showClickMenu: true,
      clickMenuPosition: {
        left: `${event.pageX - containerPosition.left + childRef.current.scrollLeft}px`,
        top: `${event.pageY - containerPosition.top + childRef.current.scrollTop}px`,
      },
      clickMenuType: 'comment',
      clickMenuData: comment,
    })

    setCommentCenuInfo({ anchor: event.currentTarget })
  }

  function editComment(comment) {
    setUiState({
      ...uiState,
      showCommentSidebar: true,
      editComment: comment,
      showClickMenu: false,
      clickMenuType: ''
    })

    setCommentCenuInfo(null)
  }

  function selectAppointment(event, appointment) {
    if (isMobile) {
      setUiState({
        ...uiState,
        editAppointment: appointment,
        showAppointmentSidebar: true,
      })
    } else {
      setUiState({
        ...uiState,
        selectedAppointment: appointment,
        selectedAppointmentEl: event.currentTarget
      })
    }
  }

  function selectHour(event, hour, appointmentType, doctor) {
    const containerPosition = childRef.current.getBoundingClientRect()
    setUiState({
      ...uiState,
      showClickMenu: true,
      clickMenuPosition: {
        left: `${event.pageX - containerPosition.left + childRef.current.scrollLeft}px`,
        top: `${event.pageY - containerPosition.top + childRef.current.scrollTop}px`,
      },
      clickMenuType: 'hour',
      clickMenuData: {
        hour,
        appointmentType,
        doctor: doctor,
      }
    })
    setMenuInfo({ anchor: event.currentTarget })
  }

  function sortByTime(a, b) {
    return (a.hour * 100 + a.minute) -
      (b.hour * 100 + b.minute)
  }

  const currentDateString = dayjs(uiState.date).format('YYYY-MM-DD')
  const appointmentData = objectToArray(appointments)
  appointmentData.forEach((appointment) => {
    if (currentDateString !== appointment.date) {
      return
    }
    let doctor = appointment.doctor
    let type = appointment.appointmentType
    let totalDuration = objectToArray(appointment.treatments).reduce((acc, treatment) => (
      acc + parseInt(treatment.duration || 0)
    ), 0)
    totalDuration = Math.min(totalDuration, 720) // max duration of 12 hours
    firstHour.current = Math.min(firstHour.current, appointment.hour)
    lastHour.current = Math.max(lastHour.current, Math.ceil(appointment.hour + totalDuration / 60))
    if (appointment.status === 'cancelled') {
      rowAppointments.cancelled = rowAppointments.cancelled || []
      rowAppointments.cancelled.push(appointment)
    } else if (appointment.status === 'cancelanotherappointment') {
      rowAppointments.cancelled = rowAppointments.cancelled || []
      rowAppointments.cancelled.push(appointment)
    } else {
      rowAppointments[type] = rowAppointments[type] || {}
      rowAppointments[type][doctor] = rowAppointments[type][doctor] || []
      rowAppointments[type][doctor].push(appointment)
    }
  })

  for (let t in rowAppointments) {
    if (Array.isArray(rowAppointments[t])) {
      rowAppointments[t].sort(sortByTime)
    } else {
      for (let d in rowAppointments[t]) {
        rowAppointments[t][d].sort(sortByTime)
      }
    }
  }

  let rowComments = {}
  const commentData = objectToArray(comments)
  commentData.forEach((comment) => {
    for (let key in comment.rows) {
      if (comment.rows[key]) {
        const newDoctor = doctors.filter(d => d.active && d.appointmentType === key)
        if (comment.rows[key] && ['beauty', 'preventative', 'plastic'].includes(key)) {
          for (const d in newDoctor) {
            rowComments[newDoctor[d].id] = rowComments[key] || []
            rowComments[newDoctor[d].id].push(comment)
          }
        }
        rowComments[key] = rowComments[key] || []
        rowComments[key].push(comment)
      }
    }
  })

  function calculateStartTop(hour, minute) {
    return minutesToHeight(
      timeToMinutes(hour, minute, firstHour.current)
    )
  }
  function calculateStartLeft(hour, minute) {
    return minutesToLeft(
      timeToMinutes(hour, minute, firstHour.current)
    )
  }
  function calculateEndLeft(appointment, i) {
    const treatments = objectToArray(appointment.treatments, 'order')

    const width = treatments.reduce((acc, cur) =>
      acc + minutesToWidth(cur.duration), 0)
    return calculateStartLeft(appointment.hour, appointment.minute) + width
  }
  function calculateEndTop(appointment, i) {
    const treatments = objectToArray(appointment.treatments, 'order')
    const width = treatments.reduce((acc, cur) =>
      acc + minutesToWidth(cur.duration), 0)
    return calculateStartTop(appointment.hour, appointment.minute) + (width || 0)
  }

  function positionAppointments() {
    const appointmentPosition = {};
    const rowHeights = {};

    function rowHightCalculate(appointments, type, doctor) {

      let lastEndTop = 0
      let lastTop = 0
      let lastLeft = 0
      let maxOverlaps = 0

      const appointmentRowLastEndLeft = []
      const gutter = 100
      for (const i in appointments) {
        const appointment = appointments[i]
        const startLeft = calculateStartLeft(appointment.hour, appointment.minute)

        const endLeft = calculateEndLeft(appointment, parseInt(i))
        let indexOfLine = appointmentRowLastEndLeft.findIndex(endLeft => startLeft > endLeft + gutter)

        if (indexOfLine > -1) {
          appointmentRowLastEndLeft[indexOfLine] = endLeft
        } else {
          appointmentRowLastEndLeft.push(endLeft)
          indexOfLine = appointmentRowLastEndLeft.length - 1
        }
        const top = indexOfLine * 60

        if (isMobile) {
          if (calculateStartTop(appointment.hour, appointment.minute) < lastEndTop) {
            lastLeft += 136
            maxOverlaps = Math.max(maxOverlaps, lastLeft / 136)
          } else {
            lastLeft = 0
          }
          lastEndTop = calculateEndTop(appointment, i)
          appointmentPosition[appointment.id] = {
            top,
            left: lastLeft,
            verticalPosition: lastTop / 60
          }
        } else {
          appointmentPosition[appointment.id] = {
            top,
            left: startLeft,
            verticalPosition: lastTop / 60
          }
        }
      }

      const rowsCount = appointmentRowLastEndLeft.length

      const minHeight = Math.max(120, ((rowsCount + 1) * 60))
      rowHeights[doctor?.id || type] = {
        minHeight,
        rowsCount
      }
    }

    for (const type of appointmentTypes) {
      if (['beautyTreatment'].includes(type)) {
        rowHightCalculate(rowAppointments[type] ? rowAppointments[type][undefined] || [] : [], type)
      } else {
        const doctorList = doctors.filter((doctor) => doctor.appointmentType === type)
        for (const doctor of doctorList) {
          rowHightCalculate(rowAppointments[type] ? rowAppointments[type][doctor && doctor.id] || [] : [], type, doctor)
        }
      }
    }

    if (rowAppointments.cancelled) {
      rowHightCalculate(rowAppointments.cancelled || [], 'cancelled')
    }

    return {
      appointmentPosition,
      rowHeights
    }
  }

  const handlePopoverClose = () => {
    setUiState({
      ...uiState,
      selectedAppointment: false,
      selectedAppointmentEl: {}
    });
  };

  async function handleAppointmentChange(appointment, field, value, dialog) {
    const { updateWithMeta } = firebase
    const location = appointment.patient ? 'kardexes' : 'appointments'
    let promises = []

    handlePopoverClose()

    if (value === 'arrived' || value === 'late') {
      let arrivetime = dayjs().format('DD:HH:mm')
      promises.push(updateWithMeta(`${location}/${appointment.id}`, { [field]: value, arrivetime }))
    } else if (value === 'complete') {
      let completetime = dayjs().format('DD:HH:mm')

      let d = '' // stay time(d)
      let h = '' // stay time(h)
      let m = '' // stay time(m)
      let ad, ah, am, bd, bh, bm

      ad = parseInt(completetime.split(':')[0]) // complete time(day)
      ah = parseInt(completetime.split(':')[1]) // complete time(h)
      am = parseInt(completetime.split(':')[2]) // complete time(m)

      if (appointment.arrivetime) {
        bd = parseInt(appointment.arrivetime.split(':')[0]) // arrive time(day)
        bh = parseInt(appointment.arrivetime.split(':')[1]) // arrive time(h)
        bm = parseInt(appointment.arrivetime.split(':')[2]) // arrive time(m)
      }

      if ((ad - bd) < 1) {
        d = 0
      } else if ((ad - bd) > 0) {
        d = ad - bd
      }

      if ((ah - bh) >= 0) {
        h = ah - bh
      } else if ((ah - bh) < 0 && (bd - ad) > 0) {
        h = (ah - bh) + 24
        d = d - 1
      }

      if ((am - bm) < 0) {
        m = (am - bm) + 60
        h = h - 1
      } else if ((am - bm) >= 0) {
        m = am - bm
      }

      let staytime = String(d + '天' + h + '時' + m + '分')
      promises.push(updateWithMeta(`${location}/${appointment.id}`, { [field]: value, staytime, completetime }))
    } else if (value === 'cancelanotherappointment' || dialog) {
      handleDialogOpen(appointment, field, value, location)
    } else {
      promises.push(updateWithMeta(`${location}/${appointment.id}`, { [field]: value }))
    }

    try {
      await Promise.all(promises)
    } catch (ex) {
      console.log(ex)
    }
  }

  function handleDialogOpen(appointment, field, value, type) {
    setDialogData({ appointment, field, value, type })
  }

  const handleClose = () => {
    setCommentCenuInfo(null)
    setMenuInfo(null);
    setDialogData(null)
    if (uiState.clickMenuType === 'hour') {
      setUiState({
        ...uiState,
        showClickMenu: false,
        clickMenuType: '',
      })
    }
  };

  const onEditAppointment = (data, type) => {
    if (type === 'new') {
      if (uiState.clickMenuType === 'hour') {
        setUiState({
          ...uiState,
          showClickMenu: false,
          clickMenuType: '',
          showAppointmentSidebar: true,
          showCommentSidebar: false,
        })
      } else {
        setUiState({
          ...uiState,
          showAppointmentSidebar: true,
          showCommentSidebar: false,
        })
      }
    } else {
      setUiState({
        ...uiState,
        editAppointment: data,
        showAppointmentSidebar: true,
        selectedAppointment: false,
        selectedAppointmentEl: {}
      })
    }

    setMenuInfo(null);
  }

  const onEditComment = (data, type) => {
    if (type === 'new') {
      if (uiState.clickMenuType === 'hour') {
        setUiState({
          ...uiState,
          showClickMenu: false,
          clickMenuType: '',
          showCommentSidebar: true,
          showAppointmentSidebar: false,
        })
      } else {
        setUiState({
          ...uiState,
          showCommentSidebar: true,
          showAppointmentSidebar: false,
        })
      }
    } else {
      setUiState({
        ...uiState,
        editComment: data,
        showCommentSidebar: true
      })
    }

    setCommentCenuInfo(null)
    setMenuInfo(null);
  }

  const timeBarState = {
    currentTimePosition: currentTimePosition,
    currentTimeDisplay: currentTimeDisplay
  }

  const calculatefunc = {
    calculateStartTop,
    calculateStartLeft,
    calculateEndLeft,
    calculateEndTop
  }

  const scheduleRowProps = {
    firstHour: firstHour.current,
    lastHour: lastHour.current,
    patientList,
    isMobile,
    selectHour,
    selectAppointment: selectAppointment,
    selectComment: selectComment
  }


  const viewingDoctor = uiState.viewingDoctor || (doctors[0] && doctors[0].id) // need check 2021/8/6

  let rowPosition = -1

  const { rowHeights, appointmentPosition } = positionAppointments()
  return (
    <div
      style={{
        minWidth: isMobile ? 'calc(100vw - 50px)' : 'calc(100vw - 120px)'
      }}
      className={classes.container}
    >
      {dialogData && <AppointmentStatusDialog
        data={dialogData}
        dialogTital={formatMessage({ id: `cancelled.${dialogData.value}` })}
        handleClose={handleClose}
        setUiState={setUiState}
        uiState={uiState}
      />}
      {menuInfo && <Menu
        id="menu-appbar"
        anchorEl={menuInfo.anchor}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {<span>
          <MenuItem onClick={() => onEditAppointment({}, 'new')}>
            <AddIcon style={{ marginRight: '10px' }} />
            {formatMessage({ id: 'appointment.anchor.newReservation' })}
          </MenuItem>
          <MenuItem onClick={() => onEditComment({}, 'new')}>
            <InsertCommentIcon style={{ marginRight: '10px' }} />
            {formatMessage({ id: 'appointment.anchor.newComment' })}
          </MenuItem>
        </span>}
      </Menu>}
      {commentMenuOpen && <Menu
        id="menu-appbar"
        anchorEl={commentCenuInfo.anchor}
        classes={{ list: classes.commentMenu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        open={commentMenuOpen}
        onClose={handleClose}
      >
        {<span>
          <div style={{ ...commentStyles.container, ...uiState.clickMenuPosition }}>
            <div style={commentStyles.header}>
              {formatMessage({ id: 'appointment.popover.comment' })}
              <EditIcon onClick={() => editComment(uiState.clickMenuData)} style={commentStyles.icon} />
            </div>
            <div style={commentStyles.body}>
              {uiState.clickMenuData.content}
            </div>
          </div>
        </span>}
      </Menu>}
      <div className={classes.header}>Physicians</div>
      <div className={classes.wrapper} ref={doclist}>
        <DoctorsList
          firstHour={firstHour.current}
          lastHour={lastHour.current}
          doctors={doctors}
          doctorShifts={doctorShifts}
          rowHeights={rowHeights}
          appointmentTypes={appointmentTypes}
          ui={uiState}
          setUiState={setUiState}
        />
      </div>
      <div className={classes.timebarContainer} ref={timebar}>
        <TimeBar isMobile={isMobile} firstHour={firstHour.current} lastHour={lastHour.current} {...timeBarState} />
      </div>
      <div
        ref={childRef}
        className={classes.rowBody}
        onScroll={() => handleScroll(childRef.current.scrollTop, childRef.current.scrollLeft)}
      >
        {appointmentTypes.map((type) => {
          let rowCount = 0
          const rows = doctors.filter((doctor) => doctor.appointmentType === type).map((doctor, key) => {
            const row = doctor.active && (!isMobile || viewingDoctor === doctor.id) && (doctorShifts[doctor.id]?.length || uiState.noShifts) ?
              <ScheduleRow
                comments={rowComments[doctor.id] || []}
                doctor={doctor}
                appointmentType={type}
                doctorShifts={doctorShifts[doctor.id]}
                doctorLeaves={doctorLeaves[doctor.id]}
                rowPosition={rowPosition}
                rowHeights={rowHeights}
                appointmentPosition={appointmentPosition}
                key={key}
                appointments={rowAppointments[type] ? rowAppointments[type][doctor && doctor.id] || [] : []}
                {...timeBarState}
                {...scheduleRowProps}
                calculatefunc={calculatefunc}
              /> : null

            if (row) {
              rowPosition++
              rowCount++
            }
            return row
          })
          if (!rowCount && (!isMobile || viewingDoctor === type)) {
            rowPosition++
            rows.push(<ScheduleRow
              comments={rowComments[type] || []}
              appointmentType={type}
              rowPosition={rowPosition}
              rowHeights={rowHeights}
              appointmentPosition={appointmentPosition}
              key={type}
              appointments={rowAppointments[type] ? rowAppointments[type][undefined] || [] : []}
              {...timeBarState}
              {...scheduleRowProps}
              calculatefunc={calculatefunc}
            />)
          }
          rowPosition++
          return rows
        })}
        {!isMobile || viewingDoctor === 'cancelled' ? <ScheduleRow
          comments={rowComments.cancelled || []}
          appointmentType='cancelled'
          rowPosition={rowPosition}
          appointments={rowAppointments.cancelled || []}
          rowHeights={rowHeights}
          appointmentPosition={appointmentPosition}
          {...timeBarState}
          {...scheduleRowProps}
          calculatefunc={calculatefunc}
        /> : null}
        {uiState.selectedAppointment && !isMobile &&
          <AppointmentPopover
            handleAppointmentChange={handleAppointmentChange}
            handlePopoverClose={handlePopoverClose}
            ui={uiState}
            handleDialogOpen={handleDialogOpen}
            onEditAppointment={onEditAppointment}
          />
        }
      </div>
    </div>
  );
}

ScheduleDay.propTypes = {
  appointments: PropTypes.object.isRequired,
  appointmentTypes: PropTypes.array.isRequired,
  doctors: PropTypes.arrayOf(PropTypes.object.isRequired),
  doctorShifts: PropTypes.object.isRequired,
  doctorLeaves: PropTypes.object.isRequired,
  patientList: PropTypes.object.isRequired,
  comments: PropTypes.object.isRequired,
};

export default ScheduleDay;

