import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import ButtonProgress from '../../../components/ButtonProgress';

function EditSocialMediaPage({ handleClose, data, patientId }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [patientData, setPatientData] = useState(data)


  const _fields = [
    { name: 'email', type: 'text', md: 12, sm: 12, order: 0 },
    { name: 'line', type: 'text', md: 12, sm: 12, order: 1 },
    { name: 'weChat', type: 'text', md: 12, sm: 12, order: 2 },
    { name: 'whatsApp', type: 'text', md: 12, sm: 12, order: 3 },
  ]

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function savePatient() {
    setLoadingApprove(true)
    const updateData = {
      email: patientData.email || '',
      line: patientData.line || '',
      weChat: patientData.weChat || '',
      whatsApp: patientData.whatsApp || '',
    }

    for (const field of Object.keys(updateData)) {
      if (updateData[field] === '') {
        delete updateData[field]
      }
    }

    try {
      await (firebase.functions().httpsCallable('savePatients'))({ id: patientId, ...updateData })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  function updateData(field, value) {
    let newValue = value
    let newData = { ...patientData, [field.name]: newValue }

    setPatientData(newData)
  }


  function createField(field) {
    let newValue = patientData[field.name] ? patientData[field.name] : ''
    let labelText = formatMessage({ id: `patient.dialog.${field.name}` })

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        required={field.required}
        label={labelText}
        value={newValue}
        fullWidth
        size="small"
        variant="outlined"
        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
      />
    </Grid>
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'patient.dialog.information.edit' })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {fields.map(field => createField(field))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonProgress handleClick={() => savePatient()} handleClose={handleClose} loading={loadingApprove} buttonText='button.save' />
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditSocialMediaPage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired
};

export default EditSocialMediaPage;
