const LeaveType = [
  { name: '特休', value: 'annual_leave', type: 'all' },
  { name: '病假', value: 'sick_leave', type: 'all' },
  { name: '生理假', value: 'menstrual_leave', type: 'f' },
  { name: '事假', value: 'personal_leave', type: 'all' },
  { name: '喪假', value: 'bereavement_leave', type: 'all' },
  { name: '婚假', value: 'marriage_leave', type: 'all' },
  { name: '產假', value: 'maternity_leave', type: 'f' },
  { name: '產檢假', value: 'reproductive_leave', type: 'f' },
  { name: '陪產假', value: 'paternity_leave', type: 'all' },
  { name: '安胎假', value: 'fetal_leave', type: 'f' },
  { name: '家庭照顧假', value: 'family_leave', type: 'all' },
  { name: '公傷假', value: 'publicinjury_leave', type: 'all' },
  { name: '補休假', value: 'overtime_leave', type: 'all' },
  { name: '公假', value: 'business_leave', type: 'all' },
  { name: '疫苗接種假', value: 'vaccination_leave', type: 'all' },
  { name: '防疫隔離假', value: 'quarantine_leave', type: 'all' },
  { name: '防疫照顧假', value: 'epidemicPreventionCare_leave', type: 'all' },
];

export default LeaveType
