import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import firebase from 'firebase/app';

import { createTheme, ThemeProvider, StyledEngineProvider, styled } from '@mui/material/styles';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FabMui from '@mui/material/Fab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import StatusIndicator from '../../enum/StatusIndicator'
import TabContainer from '../../containers/TabContainer/TabContainer'
import ConsultPage from './ProfileTab/ConsultPage'
import CertificateOfDiagnosis from './ProfileTab/CertificateOfDiagnosis'
import MedicinePage from './ProfileTab/MedicinePage'
import TreatmentPage from './ProfileTab/TreatmentPage'
import { formatName } from '../../modules/uitls';

const primaryColor = process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence' ? {
  light: '#fffff7',
  main: '#eeccc4',
  dark: '#bb9b94',
  contrastText: '#a58a88',
} : process.env.BRANCH_ENV === 'santea' ? {
  light: '#6efeff',
  main: '#26CAD3',
  dark: '#0099a2',
  contrastText: '#fff',
} : {
  light: '#a3ffff',
  main: '#6EDBD5',
  dark: '#35a9a4',
  contrastText: '#fff',
}

const Root = styled('div')(({ theme }) => ({
  fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  '& > *': {
    marginTop: theme.spacing(2),
  },
}));

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '-2px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const whiteFontColor = (process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence') ? {
  '&:hover': {
    color: '#fff',
    '@media (hover: none)': {
      color: '#fff'
    }
  }
} : {}

const Fab = styled(FabMui, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    ...whiteFontColor,
    ...(open === 'left' && {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(11),
      zIndex: 1000,
      '&.Mui-disabled': {
        backgroundColor: '#ddd'
      }
    }),
    ...(open === 'right' && {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 1000,
      '&.Mui-disabled': {
        backgroundColor: '#ddd'
      }
    }),
  })
);

const theme = createTheme({
  palette: {
    primary: primaryColor,
  },
});

function ReviewKardexPage({ currentUser }) {
  const { formatMessage } = useIntl()
  const history = useHistory();
  const { patientId, kardexId } = useParams()
  const [kardex, setKardex] = useState(null)
  const [patient, setPatient] = useState(null)
  const [userMapping, setUserMapping] = useState({})
  const [nextKardexId, setNextKardexId] = useState(null)
  const [previousKardexId, setPreviousKardexId] = useState(null)
  const [injection, setInjection] = useState({})
  const [inquirie, setInquirie] = useState({})
  const [templateMapping, setTemplateMapping] = useState({})
  const TabData = filterTabData()

  useEffect(() => {
    const ref = firebase.database().ref('inquiryTemplates')
    const onDataChange = ref.on('value', snapshot => {
      setTemplateMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('injections')
    const onDataChange = ref.on('value', snapshot => {
      setInjection(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('inquiries')
    const onDataChange = ref.on('value', snapshot => {
      setInquirie(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('users')
    const onDataChange = ref.on('value', snapshot => {
      setUserMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patients').child(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setPatient(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, [patientId]);

  useEffect(() => {
    const ref = firebase.database().ref('kardexes').orderByChild('patient').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      const data = []
      Object.keys(snapshots).forEach(doc => {
        data.push({ key: doc, ...snapshots[doc] })
      })

      for (const count of Object.keys(data)) {
        if (data[count].key === kardexId) {
          setNextKardexId(data[Number(count) + 1] && data[Number(count) + 1].key)
          setPreviousKardexId(data[Number(count) - 1] && data[Number(count) - 1].key)
        }
      }

      setKardex(snapshots[kardexId])
    });
    return () => ref.off('value', onDataChange)
  }, [history.location.pathname]);

  if (!kardex || !patient) {
    return ('Loading...')
  }

  const _fields = [
    { name: 'doctor', sm: 6, md: 2, order: 0 },
    { name: 'nurse', sm: 6, md: 2, order: 1 },
    { name: 'salesRep', sm: 6, md: 2, order: 2 },
    { name: 'source', sm: 6, md: 2, roots: true, order: 3 },
    { name: 'remarks', sm: 12, md: 12, multiline: true, order: 4 },
  ]

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function filterTabData() {
    let newInquirie = {}
    let newInjection = {}
    if (kardex && kardex.inquiries) {
      for (let inquirieKey in kardex.inquiries) {
        newInquirie[inquirieKey] = inquirie[inquirieKey]
      }
    }

    if (kardex && kardex.injections) {
      for (let injectionKey in kardex.injections) {
        newInjection[injectionKey] = injection[injectionKey]
      }
    }

    return {
      injection: newInjection,
      inquirie: newInquirie,
      inquiryTemplate: templateMapping,
      injectionTemplate: templateMapping
    }
  }

  function createField(field, value, kardex) {
    let newValue = value
    let labelText = ''

    if (field.roots) {
      labelText = formatMessage({ id: `kardex.profile.${field.name}.roots` })
      newValue = newValue ? formatMessage({ id: `kardex.profile.${field.name}.${newValue}` }) : ''
    } else {
      labelText = formatMessage({ id: `kardex.profile.${field.name}` })
    }

    if (['doctor', 'nurse', 'salesRep'].includes(field.name)) {
      newValue = userMapping[value] ? userMapping[value].displayName : ''
    } else if (field.name === 'remarks') {
      newValue = kardex.comment + '\n' + (kardex.nurseComment || '') + '\n' + (kardex.doctorComment || '')
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        fullWidth
        variant="standard"
        size="small"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  const _tabs = [
    {
      label: formatMessage({ id: 'kardex.tab.treatment' }),
      component: <TreatmentPage
        treatments={kardex.treatments}
      />,
      order: 0
    },
    {
      label: formatMessage({ id: 'kardex.tab.consult' }),
      component: <ConsultPage
        field={{
          path: 'inquiries',
          templatePath: 'inquiryTemplates',
          data: TabData.inquirie,
          template: TabData.inquiryTemplate,
          mapping: inquirie
        }}
        patient={patient}
        patientId={patientId}
        kardexId={kardexId}
      />,
      order: 1
    },
    {
      label: formatMessage({ id: 'kardex.tab.injection' }),
      component: <ConsultPage
        field={{
          path: 'injections',
          templatePath: 'inquiryTemplates',
          data: TabData.injection,
          template: TabData.injectionTemplate,
          mapping: injection
        }}
        patient={patient}
        patientId={patientId}
        kardexId={kardexId}
      />,
      order: 2
    },
    {
      label: formatMessage({ id: 'kardex.tab.medicine' }),
      component: <MedicinePage
        currentUser={currentUser}
        patient={patient}
        kardex={kardex}
        kardexId={kardexId}
        patientId={patientId}
        docType={'prescription'}
      />,
      order: 3
    },
    {
      label: formatMessage({ id: 'kardex.tab.controlledDrugMedicine' }),
      component: <MedicinePage
        currentUser={currentUser}
        patient={patient}
        kardex={kardex}
        kardexId={kardexId}
        patientId={patientId}
        docType={'controlledDrug'}
      />,
      order: 4
    },
    {
      label: formatMessage({ id: 'kardex.tab.certificateOfDiagnosis' }),
      component: <CertificateOfDiagnosis
        currentUser={currentUser}
        patient={patient}
        kardex={kardex}
        kardexId={kardexId}
      />,
      order: 5
    },
  ]

  const tabs = _tabs.sort((a, b) => {
    return a.order - b.order
  })

  const onTabSelected = (tabIndex) => {
  }

  return (
    <Root>
      <div style={{ padding: '40px 20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px' }}>
          <div style={{ fontSize: '34px', fontWeight: 400, color: '#888', }}>{formatName(patient.name)}</div>
          <div style={{ fontSize: '14px', fontWeight: 500, color: '#828a99', marginLeft: '35px' }}><CakeIcon sx={{ marginRight: '5px' }} />{patient.birthDate}</div>
          <div style={{ fontSize: '14px', fontWeight: 500, color: '#828a99', marginLeft: '35px' }}><PhoneIcon sx={{ marginRight: '5px' }} />{patient.phone}</div>
        </div>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Fab
              open={'right'}
              color="primary"
              disabled={!nextKardexId ? true : false}
              onClick={() => history.push(`${nextKardexId}`)}
            >
              <ChevronRightIcon />
            </Fab>
          </ThemeProvider>
        </StyledEngineProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Fab
              open={'left'}
              color="primary"
              disabled={!previousKardexId ? true : false}
              onClick={() => history.push(`${previousKardexId}`)}
            >
              <ChevronLeftIcon />
            </Fab>
          </ThemeProvider>
        </StyledEngineProvider>
        <Paper style={{ marginTop: '25px' }}>
          <Grid container spacing={2} sx={{ padding: '20px' }}>
            <Grid item xs={12} sm={6} md={6}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '21px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                  <AssignmentIcon fontSize="small" sx={{ color: '#9ea6b7', marginRight: '10px' }} />
                  {kardex.displayId}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', fontSize: '15px', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px' }}>
                    {formatMessage({ id: 'kardex.profile.reservation' })}
                  </span>
                  <span style={{ color: '#ffbf38' }}>
                    {`${kardex.date} ${kardex.time}`}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <span style={{ fontSize: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ height: '12px', width: '12px', minWidth: '12px', borderRadius: '12px', backgroundColor: StatusIndicator(kardex.status), marginRight: '4px' }} >{''}</div>
                {formatMessage({ id: `kardex.profile.status.${kardex.status}` })}
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative', marginTop: '20px' }}>
              <Divider />
              <DividerText>
                <Typography color="textSecondary" variant="caption">{formatMessage({ id: 'kardex.profile.generalInfo' })}</Typography>
              </DividerText>
            </Grid>
            {fields.map(field => createField(field, kardex[field.name], kardex))}
          </Grid>
        </Paper>
        <Paper sx={{ marginTop: '20px' }}>
          <Grid container spacing={2} sx={{ padding: '20px' }}>
            <TabContainer activeTabIndex={0} defaultSelect={0} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
          </Grid>
        </Paper>
      </div>
    </Root>
  );
}

ReviewKardexPage.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

export default ReviewKardexPage;
