import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function AppointmentStatusDialog(props) {
  const { data, dialogTital, handleClose, setUiState, uiState } = props
  const { update } = firebase
  const appointment = data.appointment

  async function handleClick() {
    if (data.value === 'cancelanotherappointment') {
      setUiState({
        ...uiState,
        editAppointment: { ...appointment, id: 'new' },
        showAppointmentSidebar: true
      })
    }

    try {
      await update(`${data.type}/${appointment.id}`, { [data.field]: data.value })
      handleClose()
    } catch (ex) {
      console.log(ex)
      handleClose()
    }
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{dialogTital}</DialogTitle>
        <DialogActions>
          <Button sx={{ marginRight: '5px' }} color='inherit' onClick={handleClose} autoFocus>
            <FormattedMessage id={'cancelled.cancelNo'} />
          </Button>
          <Button
            style={{
              borderRadius: '16px',
              border: '1px solid #d32f2f',
              color: '#d32f2f',
              lineHeight: 1,
              margin: '4px'
            }}
            onClick={() => handleClick()}
            autoFocus
          >
            <FormattedMessage id={'cancelled.cancelYes'} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AppointmentStatusDialog.propTypes = {
  dialogTital: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setUiState: PropTypes.func.isRequired,
  uiState: PropTypes.object.isRequired,
};
