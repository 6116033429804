const i18n = {
  'manage': {
    'title': '銷售管理',
    'selectPatient': '選擇客戶',
    'tab': {
      'purchase': '營收單',
      'consume': '可用療程'
    },
    'purchase': {
      'table': {
        'sn': '營收單號',
        'date': '日期',
        'totalCost': '總金額',
        'totalPaid': '已收金額',
        'agentName': '營收單負責人',
        'status': '付款狀態',
        'confirmed': '確認',
        'uncollected': '未付',
        'collected': '已付',
        'processing': '處理中'
      },
      'dialog': {
        'title': {
          'purchase': '營收單作廢',
          'payment': '付款作廢'
        },
        'voidReason': '作廢原因',
      },
      'payment': {
        'date': '付款日期',
        'status': {
          'root': '付款方式',
          'closeout': '付清餘額',
          'all': '全額',
          'partial': '部分付款',
        },
        'type': {
          'root': '付費方式',
          'cash': '現金',
          'visa': 'Visa',
          'master': 'Master',
          'jcb': 'JCB',
          'wireTransfer': '匯款',
          'ecPay': '綠界科技',
          'wechatPay': '微信電子錢包',
          'points': '儲值點數',
          'mktExpence': '行銷支出',
          'ae': '美國運通',
          'alipay': '支付寶',
          'unionpay': '銀聯',
          'online': '線上刷卡',
          'benefit': '員工福利點數',
          'refundPoint': '現金點數'
        },
        'treatment': '指定療程',
        'note': '備註',
        'totlePaid': '已收金額',
        'void': '已作廢'
      }
    },
    'consume': {
      'table': {
        'productId': '產品ID',
        'productName': '產品名稱',
        'quantityAvailable': '可用數量/購買數量',
        'purchaseOrderSN': '營收單ID'
      },
      'dialog': {
        'title': {
          'transfer': '療程轉讓',
          'refund': '療程退費'
        },
        'transfer': {
          'currentTitle': '當前客戶',
          'switchTitle': '轉讓客戶',
          'add': '選擇客戶',
          'remove': '移除客戶',
          'treatment': '轉讓療程',
          'patient': {
            'name': '姓名',
            'birthDate': '生日',
            'phone': '電話',
            'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
          },
          'name': '名稱',
          'nickname': '簡稱',
          'price': '售價',
          'amount': '數量'
        },
        'refund': {
          'name': '名稱',
          'nickname': '簡稱',
          'price': '售價',
          'currentTitle': '當前療程',
          'amount': '數量',
          'purchaseOrder': '營收單資訊',
          'purchaseOrderSN': '營收單號',
          'status': '付款狀態',
          'refundPrice': '欲退款金額',
          'totalCost': '總金額',
          'totalPaid': '已收金額',
          'agentName': '營收單負責人',
          'refundType': '退款方式',
          'note': '備註',
          'payment': {
            'root': '付款方式',
            'cash': '現金',
            'visa': 'Visa',
            'master': 'Master',
            'jcb': 'JCB',
            'wireTransfer': '匯款',
            'ecPay': '綠界科技',
            'wechatPay': '微信電子錢包',
            'points': '儲值點數',
            'mktExpence': '行銷支出',
            'ae': '美國運通',
            'alipay': '支付寶',
            'unionpay': '銀聯',
            'online': '線上刷卡',
            'benefit': '員工福利點數',
            'refundPoint': '現金點數'
          }
        },
      }
    }
  }
}

export default i18n