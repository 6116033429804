import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';

import SecondaryNav from '../../containers/SecondaryNav/SecondaryNav'
import ContextStore from '../../modules/context';

function Overtimes({ currentUser, type }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: `sideMenu.leaveOvertimeWork.${type}` })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  return (
    <Box p={3}>
      <SecondaryNav currentUser={currentUser} type={type} />
    </Box>
  );
}

Overtimes.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  type: PropTypes.string.isRequired
};

export default Overtimes;
