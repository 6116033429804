const i18n = {
  'calendar': {
    'title': '會議室/車位預約表',
    'today': '今天',
    'group': {
      'meeting3F': '3F會議室',
      'meeting6F': '6F大廳',
      'parking35': '車位(B2-35)',
      'parking36': '車位(B2-36)',
      'parking20': '車位(B1-20)',
    },
    'dialog': {
      'new': '新增預約',
      'edit': '編輯預約',
      'startTime': '開始時間',
      'endTime': '結束時間',
      'title': '註記',
      'createdBy': '建立人員: ',
      'group': {
        'root': '預約類型',
        'meeting3F': '3F會議室',
        'meeting6F': '6F大廳',
        'parking35': '車位(B2-35)',
        'parking36': '車位(B2-36)',
        'parking20': '車位(B1-20)',
      },
    }
  }
}
export default i18n

