import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import IconButtonMui from '@mui/material/IconButton'
import AppointmentIcon from '@mui/icons-material/Event';
import PatientsIcon from '@mui/icons-material/AssignmentInd';
import ProductsIcon from '@mui/icons-material/Assignment';
import MerchandiseIcon from '@mui/icons-material/Inventory';
import StaffIcon from '@mui/icons-material/People';
import LeaveIcon from '@mui/icons-material/EventNote';
import ScheduleIcon from '@mui/icons-material/GridOn';
import FinanceIcon from '@mui/icons-material/AttachMoney';
import AnnouncementIcon from '@mui/icons-material/InfoOutlined';
import ManageIcon from '@mui/icons-material/ChromeReaderMode';
import DDAPPIcon from '@mui/icons-material/PhoneIphone';
import QuotationIcon from '@mui/icons-material/RequestQuote';

import ContextStore from '../../modules/context';
import RebornLogo from '../../static/reborn.png'
import IbeautyLogo from '../../static/ibeauty.png'
import SanteaLogo from '../../static/santea.png'

const iconColor = process.env.BRANCH_ENV === 'lexcellence' || process.env.BRANCH_ENV === 'reborn' ?
  '#a58a88' : process.env.BRANCH_ENV === 'santea' ? '#26cad3' : '#6edbd5'
const logos = process.env.BRANCH_ENV === 'lexcellence' || process.env.BRANCH_ENV === 'reborn' ?
  RebornLogo : process.env.BRANCH_ENV === 'santea' ? SanteaLogo : IbeautyLogo

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  height: '100%',
  width: '100%',
  overflowY: 'scroll'
}))

const Title = styled('div')(() => ({
  fontSize: '20px',
  textAlign: 'center',
  color: '#666',
  fontWeight: 500,
  letterSpacing: '12px',
  fontFamily: 'Noto Sans TC'
}))

const IconTable = styled('div')(() => ({
  marginLeft: '1rem',
  marginRight: '1rem',
  position: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '0 5%',
  display: 'flex',
  justifyContent: 'center'
}))

const Icon = styled('div')(() => ({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '240px',
  height: '256px',
  margin: '40px 20px 0',
  fontSize: '21px',
  fontWeight: 600,
  textAlign: 'center',
  color: '#666'
}));

const IconButton = styled(IconButtonMui)(() => ({
  width: '120px',
  height: '120px',
  backgroundColor: '#fff',
  color: iconColor,
  '&:hover': {
    backgroundColor: iconColor,
    color: '#fff',
    '@media (hover: none)': {
      color: iconColor,
    }
  },
  '& svg': {
    width: '75px',
    height: '75px',
    borderRadius: '120px',
    // padding: '20px',
    overflow: 'visible'
  }
}));

function Home({ userRight }) {
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory()
  const { formatMessage } = useIntl()

  useEffect(() => {
    setBreadcrumbs([{
      text: ''
    }])
    return () => {
    };
  }, [history.location.pathname]);

  return (
    <Root>
      <img alt='' src={logos} style={{ width: '224px', margin: '0 auto' }}></img>
      <Title>{formatMessage({ id: 'homePage.welcome' })}</Title>
      <IconTable>
        {
          <Icon>
            <IconButton
              onClick={() => history.push('/appointments/schedule')}
              size="large">
              <AppointmentIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.appointments' })}
          </Icon>
        }
        {
          <Icon>
            <IconButton
              onClick={() => history.push('/patients')}
              size="large">
              <PatientsIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.patients' })}
          </Icon>
        }
        {
          userRight['product-view'] && <Icon>
            <IconButton
              onClick={() => history.push('/products')}
              size="large">
              <ProductsIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.product' })}
          </Icon>
        }
        {
          <Icon>
            <IconButton
              onClick={() => history.push('/merchandises')}
              size="large">
              <MerchandiseIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.merchandises' })}
          </Icon>
        }
        {
          <Icon>
            <IconButton
              onClick={() => history.push('/schedule/nurse')}
              size="large">
              <ScheduleIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.clinicSchedule' })}
          </Icon>
        }
        {
          userRight['staff-view'] && <Icon>
            <IconButton
              onClick={() => history.push('/staffManagement/staff')}
              size="large">
              <StaffIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.staff' })}
          </Icon>
        }
        {
          <Icon>
            <IconButton
              onClick={() => history.push('/leaveOvertimeWork/leaves')}
              size="large">
              <LeaveIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.leaveOvertimeWork' })}
          </Icon>
        }
        {
          userRight['manage-view'] && <Icon>
            <IconButton
              onClick={() => history.push('/manages')}
              size="large">
              <ManageIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.manages' })}
          </Icon>
        }
        {
          userRight['finance-view'] && <Icon>
            <IconButton
              onClick={() => history.push('/finance')}
              size="large">
              <FinanceIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.finance' })}
          </Icon>
        }
        {
          <Icon>
            <IconButton
              onClick={() => history.push('/announcement')}
              size="large">
              <AnnouncementIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.announcement' })}
          </Icon>
        }
        {
          ['lexcellence', 'ibeauty'].includes(process.env.BRANCH_ENV) && <Icon>
            <IconButton
              onClick={() => history.push('/ddapp')}
              size="large">
              <DDAPPIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.ddapp' })}
          </Icon>
        }
        {
          ['lexcellence', 'santea'].includes(process.env.BRANCH_ENV) && <Icon>
            <IconButton
              onClick={() => history.push('/quotation')}
              size="large">
              <QuotationIcon />
            </IconButton>
            {formatMessage({ id: 'homePage.quotation' })}
          </Icon>
        }
      </IconTable>
    </Root>
  );
}

Home.propTypes = {
  userRight: PropTypes.object.isRequired,
};

export default Home;
