import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import TabContainer from '../../containers/TabContainer/TabContainer';
import FinanceBonus from './FinanceBonus'
import SalaryPage from './SalaryPage';
import BalanceHistotyPage from './BalanceHistotyPage'
import ContextStore from '../../modules/context'

function Finance({ userRight }) {
  const { setBreadcrumbs } = useContext(ContextStore)
  const { formatMessage } = useIntl()
  const history = useHistory();
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.finance' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  const onTabSelected = (tabIndex) => {
    setActiveTabIndex(tabIndex)
  };

  let defaultSelect = 0
  const _tabs = [
    { label: formatMessage({ id: 'finance.tab.purchase' }), component: <FinanceBonus />, order: 0 }
  ]

  if (userRight['salary-edit']) {
    _tabs.push({ label: formatMessage({ id: 'finance.tab.salary' }), component: <SalaryPage />, order: 1 })
  }

  if (userRight['finance-edit']) {
    _tabs.push({ label: formatMessage({ id: 'finance.tab.balance' }), component: <BalanceHistotyPage />, order: 2 })
  }

  const tabs = _tabs.sort((a, b) => a.order - b.order)

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif' }}>
      <div style={{ padding: '40px 20px' }}>
        <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
      </div>
    </div>
  );
}

Finance.propTypes = {
  userRight: PropTypes.object.isRequired
};

export default Finance;
