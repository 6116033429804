import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from '../../../modules/sort';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import EnhancedTableRow from '../../../components/EnhancedTableRow';
import SimpleTableToolbar from '../../../components/SimpleTableToolbar'

function TreatmentDetail({ kardex, patientMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const currentTreatment = Object.keys(kardex.patients).reduce((acc, cur) => {
    for (const productId of Object.keys(kardex.patients[cur].treatments)) {
      const product = kardex.patients[cur].treatments[productId]
      if (product.type === 'product') {
        if (['8', '9', '7'].includes(product.code.cat0)) continue;
        acc.push({
          id: productId,
          name: product.name,
          patient: patientMapping[cur]?.name,
          comment: kardex.patients[cur].comment
        })
      }
    }

    return acc
  }, [])

  const headerCells = [
    { text: 'name' },
    { text: 'patient' },
    { text: 'comment' },
  ].map(c => { c.text = formatMessage({ id: `report.treatment.detail.${c.text}` }); return c })

  const rowCells = [
    { field: 'name' },
    { field: 'patient' },
    { field: 'comment' },
  ]

  return (
    <Grid item xs={12} sm={12} md={12}>
      <TableContainer component={Paper}>
        <Table size="small" sx={{ marginTop: '4px' }} aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={currentTreatment.length}
          />
          <TableBody>
            {stableSort(currentTreatment, getComparator(order, orderBy)).map(treatment => (
              <EnhancedTableRow
                key={treatment.id}
                rowCells={rowCells}
                cellData={treatment}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );

}

TreatmentDetail.propTypes = {
  kardex: PropTypes.object.isRequired,
  patientMapping: PropTypes.object.isRequired
};

function TreatmentList({ kardexes, userMapping, patientMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');

  const formatData = (data) => {
    const newData = { ...data }
    newData.name = userMapping[newData.id].displayName
    newData.customer = Object.keys(newData.patients).length

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerCells = [
    { text: 'name' },
    { text: 'customer' },
  ].map(c => { c.text = formatMessage({ id: `report.treatment.${c.text}` }); return c })

  const rowCells = [
    { field: 'name' },
    { field: 'customer' },
  ]

  const kardexMapping = kardexes.reduce((acc, cur) => {
    if (cur.doctor) {
      if (!acc[cur.doctor]) {
        acc[cur.doctor] = { [cur.patient]: cur }
      } else {
        acc[cur.doctor] = {
          ...acc[cur.doctor],
          [cur.patient]: cur
        }
      }
    }

    if (cur.nurse) {
      if (!acc[cur.nurse]) {
        acc[cur.nurse] = { [cur.patient]: cur }
      } else {
        acc[cur.nurse] = {
          ...acc[cur.nurse],
          [cur.patient]: cur
        }
      }
    }

    if (cur.salesRep) {
      if (!acc[cur.salesRep]) {
        acc[cur.salesRep] = { [cur.patient]: cur }
      } else {
        acc[cur.salesRep] = {
          ...acc[cur.salesRep],
          [cur.patient]: cur
        }
      }
    }

    return acc
  }, {})

  const currentKardex = Object.keys(kardexMapping).reduce((acc, cur) => {
    acc.push({
      id: cur,
      patients: { ...kardexMapping[cur] }
    })
    return acc
  }, [])

  return (
    <Grid item xs={12} sm={12} md={12} sx={{ padding: '10px' }}>
      <SimpleTableToolbar
        title={'report.treatment.title'}
      />
      <TableContainer component={Paper}>
        <Table size="small" sx={{ marginTop: '4px' }} aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={currentKardex.length}
            expandable
          />
          <TableBody>
            {stableSort(currentKardex.map(c => formatData(c)), getComparator(order, orderBy)).map(kardex => (
              <EnhancedTableRow
                key={kardex.id}
                rowCells={rowCells}
                cellData={kardex}
                expandable
                expandContent={<TreatmentDetail
                  kardex={kardex}
                  patientMapping={patientMapping}
                />}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

TreatmentList.propTypes = {
  kardexes: PropTypes.arrayOf(PropTypes.object.isRequired),
  userMapping: PropTypes.object,
  patientMapping: PropTypes.object.isRequired
};

export default TreatmentList;
