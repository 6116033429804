import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AppNav from './containers/AppNav/AppNav';
import ApplicationSent from './components/ApplicationSent';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import AppointmentIcon from '@mui/icons-material/Event';
import PatientsIcon from '@mui/icons-material/AssignmentInd';
import ProductsIcon from '@mui/icons-material/Assignment';
import MerchandisesIcon from '@mui/icons-material/LocalHospital';
import StaffIcon from '@mui/icons-material/People';
import LeaveIcon from '@mui/icons-material/EventNote';
import ScheduleIcon from '@mui/icons-material/GridOn';
import FinanceIcon from '@mui/icons-material/AttachMoney';
import AnnouncementIcon from '@mui/icons-material/InfoOutlined';
import ManageIcon from '@mui/icons-material/ChromeReaderMode';
import SmsIcon from '@mui/icons-material/Message';
import ReportIcon from '@mui/icons-material/Assessment';
// import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import QuotationIcon from '@mui/icons-material/RequestQuote';

import Home from './pages/Home/Home'
import Leaves from './pages/Leaves/Leaves';
import Overtimes from './pages/Overtimes/Overtimes';
import PunchClockRevise from './pages/PunchClockRevise/PunchClockRevise';
import EditPunchClockRevisePage from './pages/PunchClockRevise/EditPunchClockRevisePage';
import EditLeavePage from './pages/Leaves/EditLeavePage';
import EditOvertimePage from './pages/Overtimes/EditOvertimePage';
import PunchClockException from './pages/PunchClockExceptions/PunchClockException';
import Staff from './pages/Staffs/Staff';
import Schedule from './pages/Schedule/Schedule';
import EditStaffPage from './pages/Staffs/EditStaffPage';
import EditStaffSalaryPage from './pages/Staffs/EditStaffSalaryPage';
import Patient from './pages/Patients/Patient';
import PatientTab from './pages/Patients/PatientTab';
import Announcement from './pages/Announcement/Announcement';
import ReviewKardexPage from './pages/Patients/ReviewKardexPage';
import DocumentContainer from './pages/Patients/DocumentPage/DocumentContainer';
import SmsList from './pages/SmsList/SmsList';
import Appointments from './pages/Appointments/Appointments';
import AppointmentList from './pages/Appointments/AppointmentList';
import AppointmentEditHistory from './pages/Appointments/AppointmentEditHistory';
import DoctorSchedule from './pages/Appointments/DoctorSchedule';
import Products from './pages/Product/Products'
import Merchandises from './pages/Merchandise/Merchandises';
import Manages from './pages/Manages/Manages';
import Reports from './pages/Reports/Reports';
import Finance from './pages/Finance/Finance';
import DDappList from './pages/DDapp/DDappList';
// import Analytics from './pages/Analytics/Analytics';
import CalendarPage from './pages/Appointments/CalendarPage';
import QuotationList from './pages/Quotations/QuotationList';

import ContextStore from './modules/context';
import useViewport from './hooks/windowSize';
import { tabletMedia, moibleMedia } from './constants/index'

import './App.css'

const drawerWidth = 240;
const drawerRightWidth = 860;
const version = '1.1.5'

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 480,
      tablet: 1024,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: 'calc(100vh - 64px)',
    width: '100%',
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    margin: open === 'right' ? 0 : `0 0 0 -${drawerWidth}px`,
    ...(open === 'left' && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

function BlankPage({ currentUser, userRight }) {
  return (
    <Home currentUser={currentUser} userRight={userRight} />
  )
}

BlankPage.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  userRight: PropTypes.object.isRequired,
};

function App({ user, userRight }) {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const currentUser = user;
  const { width } = useViewport()

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [drawerStatus, setDrawerStatus] = useState({
    content: {},
    display: 'none',
  });
  const [uiState, setUiState] = useState({
    date: dayjs(),
    noShifts: false,
    showNurse: true,
    showRoom: true,
    showSalesRep: true,
    showPatientType: false
  });

  const contextValue = useMemo(
    () => ({ breadcrumbs, setBreadcrumbs, drawerStatus, setDrawerStatus, uiState, setUiState }),
    [breadcrumbs, drawerStatus, uiState]
  );

  const menuItems = filterByUserRights([
    {
      text: 'appointments', icon: <AppointmentIcon />, path: 'appointments', expandable: true, subMenuItems: filterByUserRights([
        { text: 'appointments.schedule', path: 'schedule' },
        { text: 'appointments.editList', path: 'editList' },
      ])
    },
    { text: 'patients', icon: <PatientsIcon />, path: 'patients', expandable: false },
    { text: 'products', icon: <ProductsIcon />, path: 'products', expandable: false, userRight: 'product-view' },
    { text: 'merchandises', icon: <MerchandisesIcon />, path: 'merchandises', expandable: false },
    { text: 'clinicSchedule', icon: <ScheduleIcon />, path: 'schedule/nurse', expandable: false },
    {
      text: 'staffManagement', icon: <StaffIcon />, path: 'staffManagement', userRight: 'staff-view', expandable: true, subMenuItems: filterByUserRights([
        { text: 'staffManagement.staff', path: 'staff' },
        { text: 'staffManagement.punchClockException', path: 'punchClockException', userRight: 'exception-edit' },
      ])
    },
    {
      text: 'leaveOvertimeWork', icon: <LeaveIcon />, path: 'leaveOvertimeWork', expandable: true, subMenuItems: filterByUserRights([
        { text: 'leaveOvertimeWork.owner', path: 'leaves' },
        { text: 'leaveOvertimeWork.review', path: 'leaves/review', userRight: 'leaveOvertime-view' },
        { text: 'leaveOvertimeWork.all', path: 'leaves/all', userRight: 'leaveOvertime-all' },
      ])
    },
    { text: 'manages', icon: <ManageIcon />, path: 'manages', expandable: false, userRight: 'manage-view' },
    { text: 'finance', icon: <FinanceIcon />, path: 'finance', expandable: false, userRight: 'finance-view' },
    { text: 'announcement', icon: <AnnouncementIcon />, path: 'announcement', expandable: false },
  ]);

  // if (['lexcellence'].includes(process.env.BRANCH_ENV)) {
  //   menuItems.push({ text: 'analytics', icon: <AnalyticsIcon />, path: 'analytics', expandable: false })
  // }

  if (['lexcellence', 'santea'].includes(process.env.BRANCH_ENV)) {
    menuItems.push({ text: 'quotation', icon: <QuotationIcon />, path: 'quotation', expandable: false })
  }

  if (['reborn', 'lexcellence'].includes(process.env.BRANCH_ENV)) {
    menuItems.push({
      text: 'reports', icon: <ReportIcon />, path: 'reports', expandable: true, subMenuItems: filterByUserRights([
        { text: 'reports.personalBonus', path: 'personal/bonus' },
        { text: 'reports.purchase.root', path: 'purchase/day', userRight: 'report-view' },
        { text: 'reports.consume.root', path: 'consume/day', userRight: 'report-view' },
      ])
    })
  }

  if (!['santea', 'reborn'].includes(process.env.BRANCH_ENV)) {
    menuItems[0].subMenuItems.push({ text: 'appointments.list', path: 'list' })
  }

  if (['santea', 'ibeauty', 'lexcellence'].includes(process.env.BRANCH_ENV)) {
    menuItems[0].subMenuItems.push({ text: 'appointments.calendar', path: 'calendar' })
  }

  if (currentUser.department !== 'doctor') {
    menuItems.push({ text: 'smsList', icon: <SmsIcon />, path: 'smsList', expandable: false })
  }

  function filterByUserRights(items) {
    return items.filter(i => !i.userRight || userRight[i.userRight])
  };

  const [expend, setExpend] = useState({});

  const handleDrawerOpen = () => {
    setDrawerStatus({
      ...drawerStatus,
      display: 'left'
    });
  };

  const handleDrawerClose = () => {
    setDrawerStatus(status => ({
      ...status,
      display: 'none'
    }));
  };

  const handleScheduleDrawerOpen = () => {
    setDrawerStatus({
      ...drawerStatus,
      display: 'right'
    });
  };

  const getMenuItem = ({ text, icon, path, expandable, subMenuItems }, index) => {
    if (text === '-') {
      return <Divider key={`-${index}`} />
    } else {
      return (
        expandable ? (
          <div key={text}>
            <ListItem button onClick={() => setExpend({ ...expend, [text]: !expend[text] })}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={formatMessage({ id: `sideMenu.${text}.root` })} />
              {expend[text] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse key={`${text}+`} in={expend[text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subMenuItems.map(({ text, path: subPath }) => (
                  <Link key={`/${path}/${subPath}`} to={`/${path}/${subPath}`} style={{ textDecoration: 'none', color: '#000' }}>
                    <ListItem button>
                      {/* <ListItemIcon><StarBorder /></ListItemIcon> */}
                      <ListItemText primary={formatMessage({ id: `sideMenu.${text}` })} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>
          </div>
        ) : (
          <Link key={path} to={`/${path}`} style={{ textDecoration: 'none', color: '#000' }}>
            <ListItem button key={text}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={formatMessage({ id: `sideMenu.${text}` })} />
            </ListItem>
          </Link>
        )
      )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex' }}>
        <ContextStore.Provider value={{ ...contextValue }}>
          <AppNav
            userRight={userRight}
            isDrawerOpen={drawerStatus.display}
            currentUser={currentUser}
            handleDrawerOpen={handleDrawerOpen}
            handleScheduleDrawerOpen={handleScheduleDrawerOpen}
          />
          {drawerStatus.display !== 'right' && <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={drawerStatus.display === 'left'}
          >
            <DrawerHeader sx={{ justifyContent: 'space-between' }}>
              <span style={{ paddingLeft: '4px' }}>版本 {version}</span>
              <IconButton onClick={handleDrawerClose} size="large">
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {menuItems.map((item, index) => getMenuItem(item, index))}
            </List>
          </Drawer>}
          {drawerStatus.display !== 'left' && <Drawer
            sx={{
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerRightWidth,
                boxSizing: 'border-box',
                [moibleMedia]: {
                  width: width,
                },
                [tabletMedia]: {
                  width: width,
                }
              },
              order: 2
            }}
            variant="persistent"
            anchor="right"
            open={drawerStatus.display === 'right'}
          >
            <DrawerHeader sx={{ justifyContent: 'flex-start' }}>
              <IconButton onClick={handleDrawerClose} size="large">
                {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <div>
              <DoctorSchedule doctorShiftsWeek={drawerStatus.content} />
            </div>
          </Drawer>}
          <Main open={drawerStatus.display}>
            <DrawerHeader />
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => <BlankPage {...props} currentUser={currentUser} history={history} userRight={userRight} />}
              />
              <Route
                path="/leaveOvertimeWork/leaves/applicationSent"
                exact
                render={(props) =>
                  <ApplicationSent
                    title={formatMessage({ id: 'leaveSent.leaveApplicationSent' })}
                    message={formatMessage({ id: 'leaveSent.sentMessage' })}
                    buttonText={formatMessage({ id: 'leaveSent.newBack' })}
                    buttonClick={() => history.push('/leaveOvertimeWork/leaves')}
                  />
                }
              />
              <Route
                path="/leaveOvertimeWork/overtimes/applicationSent"
                exact
                render={(props) =>
                  <ApplicationSent
                    title={formatMessage({ id: 'overtimeSent.overtimeApplicationSent' })}
                    message={formatMessage({ id: 'overtimeSent.sentMessage' })}
                    buttonText={formatMessage({ id: 'overtimeSent.newBack' })}
                    buttonClick={() => history.push('/leaveOvertimeWork/overtimes')}
                  />
                }
              />
              <Route
                path="/leaveOvertimeWork/punchClockRevise/applicationSent"
                exact
                render={(props) =>
                  <ApplicationSent
                    title={formatMessage({ id: 'punchClockReviseSent.punchClockReviseApplicationSent' })}
                    message={formatMessage({ id: 'punchClockReviseSent.sentMessage' })}
                    buttonText={formatMessage({ id: 'punchClockReviseSent.newBack' })}
                    buttonClick={() => history.push('/leaveOvertimeWork/punchClockRevise')}
                  />
                }
              />
              <Route
                path="/leaveOvertimeWork/leaves"  // tabName: leaves
                exact
                render={(props) => <Leaves {...props} currentUser={currentUser} type={'owner'} />}
              />
              <Route
                path="/leaveOvertimeWork/leaves/edit/:leaveId"
                exact
                render={(props) => <EditLeavePage {...props} currentUser={currentUser} />}
              />
              <Route
                path="/leaveOvertimeWork/leaves/review" // tabName: reviewLeaves
                exact
                render={(props) => <Leaves {...props} currentUser={currentUser} type={'review'} />}
              />
              <Route
                path="/leaveOvertimeWork/leaves/all" // tabName: allLeaves
                exact
                render={(props) => <Leaves {...props} currentUser={currentUser} type={'all'} />}
              />
              <Route
                path="/leaveOvertimeWork/overtimes" // tabName: overtimes
                exact
                render={(props) => <Overtimes {...props} currentUser={currentUser} type={'owner'} />}
              />
              <Route
                path="/leaveOvertimeWork/overtimes/edit/:overtimeId"
                exact
                render={(props) => <EditOvertimePage {...props} currentUser={currentUser} />}
              />
              <Route
                path="/leaveOvertimeWork/overtimes/review" // tabName:reviewOovertimes
                exact
                render={(props) => <Overtimes {...props} currentUser={currentUser} type={'review'} />}
              />
              <Route
                path="/leaveOvertimeWork/overtimes/all" // tabName: allOvertimes
                exact
                render={(props) => <Overtimes {...props} currentUser={currentUser} type={'all'} />}
              />
              <Route
                path="/leaveOvertimeWork/punchClockRevise" // tabName: allOvertimes
                exact
                render={(props) => <PunchClockRevise {...props} currentUser={currentUser} type={'owner'} />}
              />
              <Route
                path="/leaveOvertimeWork/punchClockRevise/new"
                exact
                render={(props) => <EditPunchClockRevisePage {...props} />}
              />
              <Route
                path="/leaveOvertimeWork/punchClockRevise/review" // tabName:reviewOovertimes
                exact
                render={(props) => <PunchClockRevise {...props} currentUser={currentUser} type={'review'} />}
              />
              <Route
                path="/leaveOvertimeWork/punchClockRevise/all" // tabName: allOvertimes
                exact
                render={(props) => <PunchClockRevise {...props} currentUser={currentUser} type={'all'} />}
              />
              <Route
                path="/leaveOvertimeWork/punchClockRevise/edit/:punchClockReviseId"
                exact
                render={(props) => <EditPunchClockRevisePage {...props} currentUser={currentUser} />}
              />
              <Route
                path="/schedule/:staffType"
                exact
                render={(props) => <Schedule {...props} currentUser={currentUser} userRight={userRight} />}
              />
              <Route
                path="/staffManagement/punchClockException"
                exact
                render={(props) => <PunchClockException {...props} />}
              />
              <Route
                path="/staffManagement/staff"
                exact
                render={(props) => <Staff {...props} userRight={userRight} />}
              />
              <Route
                path="/staffManagement/staff/edit/:staffId"
                exact
                render={(props) => <EditStaffPage {...props} />}
              />
              <Route
                path="/staffManagement/staff/editSalary/:staffId"
                exact
                render={(props) => <EditStaffSalaryPage {...props} />}
              />
              <Route
                path="/patients"
                exact
                render={(props) => <Patient {...props} userRight={userRight} />}
              />
              <Route
                path="/patients/:patientId/:tabName"
                exact
                render={(props) => <PatientTab {...props} />}
              />
              <Route
                path="/patients/:patientId/:tabName/:kardexId"
                exact
                render={(props) => <ReviewKardexPage {...props} currentUser={currentUser} />}
              />
              <Route
                path="/patients/:patientId/:tabName/:kardexId/:docType"
                exact
                render={(props) => <DocumentContainer {...props} />}
              />
              <Route
                path="/announcement"
                exact
                render={(props) => <Announcement {...props} currentUser={currentUser} userRight={userRight} />}
              />
              <Route
                path="/smsList"
                exact
                render={(props) => <SmsList {...props} currentUser={currentUser} />}
              />
              <Route
                path="/appointments/schedule"
                exact
                render={(props) => <Appointments {...props} currentUser={currentUser} userRight={userRight} />}
              />
              <Route
                path="/appointments/list"
                exact
                render={(props) => <AppointmentList {...props} currentUser={currentUser} />}
              />
              <Route
                path="/appointments/editList"
                exact
                render={(props) => <AppointmentEditHistory {...props} currentUser={currentUser} />}
              />
              <Route
                path="/appointments/calendar"
                exact
                render={(props) => <CalendarPage {...props} currentUser={currentUser} userRight={userRight} />}
              />
              <Route
                path="/ddapp" // [DDAPP]
                exact
                render={(props) => <DDappList {...props} userRight={userRight} />}
              />
              <Route
                path="/products"
                exact
                render={(props) => <Products {...props} />}
              />
              <Route
                path="/merchandises"
                exact
                render={(props) => <Merchandises {...props} />}
              />
              <Route
                path="/reports/:typeName/:dateType"
                exact
                render={(props) => <Reports {...props} currentUser={currentUser} />}
              />
              <Route
                path="/manages"
                exact
                render={(props) => <Manages {...props} />}
              />
              <Route
                path="/finance"
                exact
                render={(props) => <Finance {...props} userRight={userRight} />}
              />
              {/* <Route
                path="/analytics"
                exact
                render={(props) => <Analytics {...props} />}
              /> */}
              <Route
                path="/quotation"
                exact
                render={(props) => <QuotationList {...props} />}
              />
              <Redirect to="/" />
            </Switch>
          </Main>
        </ContextStore.Provider >
      </div >
    </ThemeProvider>
  );
}

App.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  userRight: PropTypes.object.isRequired,
};

export default App;
