const i18n = {
  'smsList': {
    'table': {
      'date': '發送日期',
      'time': '發送時間',
      'createdBy': '發送人',
      'status': '發送狀態'
    },
    'status': {
      'done': '發送完成',
      'pending': '等候發送',
      'sending': '發送中',
      'editing': '未儲存',
      'canceled': '取消發送',
      'failed': '發送失敗',
    },
    'review': {
      'content': '簡訊內容',
      'patient': {
        'name': '客戶',
        'birthDate': '生日',
        'phone': '電話',
        'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
        'lastAppointmentDateTime': '最新預約'
      }
    },
    'dialog': {
      'balance': '剩餘點數',
      'add': '發送簡訊',
      'content': '簡訊內容',
      'smsType': {
        'immediately': '立即發送',
        'schedule': '排程發送'
      },
      'date': '日期',
      'time': '時間',
      'patient': {
        'root': '客戶',
        'title': '選擇客戶',
        'add': '新增客戶',
        'edit': '移除客戶',
        'name': '姓名',
        'birthDate': '生日',
        'comment': '註記',
        'phone': '電話',
        'salesRepName': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
      }
    }
  }
}
export default i18n
