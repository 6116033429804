import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

import TabContainer from '../../../containers/TabContainer/TabContainer'
import MedicalHistory from '../ProfileTab/MedicalHistory'
import SkinCareHistory from '../ProfileTab/SkinCareHistory'
import Preferences from '../ProfileTab/Preferences'
import EditInformationDialog from '../EditProfilePage/EditInformationDialog'
import EditICEPage from '../EditProfilePage/EditICEPage'
import EditSocialMediaPage from '../EditProfilePage/EditSocialMediaPage'
import EditHistoryPage from '../EditProfilePage/EditHistoryPage'
import { formatName } from '../../../modules/uitls'

const Root = styled('div')(({ theme }) => ({
  fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  '& > *': {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const DividerText = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function Profile({ currentUser, patient, benefit, userMapping, patientMapping, historyMapping, productMapping, storedValueCard }) {
  const { formatMessage } = useIntl()
  const [activeTabIndex, setActiveTabIndex] = useState('skinCareHistory')
  const [openEditDialog, setOpenEditDialog] = useState(null)

  const formatData = (patient) => {
    const newData = { ...patient }
    newData.salesRepName = userMapping[newData.salesRep] && userMapping[newData.salesRep].displayName
    newData.name = formatName(newData.name)

    if (newData.nationality || newData.type || newData.infosource) {
      if (newData.type === '新客') {
        newData.type = 'new'
      } else if (newData.type === '舊客') {
        newData.type = 'regular'
      } else if (newData.type === '舊帶新') {
        newData.type = 'introduced'
      } else if (newData.type === '員工') {
        newData.type = 'employee'
      } else if (newData.type === '員工眷屬') {
        newData.type = 'staffRelatives'
      } else if (newData.type === '業務') {
        newData.type = 'sales'
      } else if (newData.type === '意見領袖') {
        newData.type = 'kol'
      } else if (newData.type === '公關客') {
        newData.type = 'pr'
      }

      if (newData.infosource === '戶外廣告') {
        newData.infosource = 'advertising'
      } else if (newData.infosource === '報章雜誌') {
        newData.infosource = 'newspapermagazine'
      } else if (newData.infosource === '官網') {
        newData.infosource = 'officialwebsite'
      } else if (newData.infosource === 'Facebook官方帳號') {
        newData.infosource = 'facebook'
      } else if (newData.infosource === '部落格') {
        newData.infosource = 'blog'
      } else if (newData.infosource === 'Google') {
        newData.infosource = 'google'
      } else if (newData.infosource === '宣傳品(體驗卷,DM)') {
        newData.infosource = 'propagandamaterial'
      } else if (newData.infosource === '生活圈') {
        newData.infosource = 'line'
      } else if (newData.infosource === '醫師自帶') {
        newData.infosource = 'doctor'
      } else if (newData.infosource === 'Instagram') {
        newData.infosource = 'instagram'
      }

      if (newData.nationality === '台灣') {
        newData.nationality = 'taiwan'
      } else if (newData.nationality === '香港') {
        newData.nationality = 'hongkong'
      } else if (newData.nationality === '澳門') {
        newData.nationality = 'macao'
      } else if (newData.nationality === '大陸') {
        newData.nationality = 'china'
      } else if (newData.nationality === '其他') {
        newData.nationality = 'other'
      }
    }

    if (benefit) {
      newData.earned = benefit.earned
      newData.used = benefit.used
      newData.balance = benefit.balanceHistory ?
        benefit.earned - benefit.used + benefit.balanceHistory :
        benefit.earned - benefit.used
    }
    return newData
  }

  const patientData = formatData(patient)

  const _fields = [
    { name: 'customerId', sm: 6, md: 2, order: 0 },
    { name: 'name', sm: 6, md: 2, order: 1 },

    { name: 'gender', sm: 6, md: 2, roots: true, order: 3 },
    { name: 'birthDate', sm: 6, md: 2, order: 4 },
    { name: 'phone', sm: 6, md: 2, order: 5 },
    { name: 'identityCardNumber', sm: 6, md: 2, order: 4 },
    { name: 'salesRep', sm: 6, md: 2, order: 7 },
    { name: 'nationality', sm: 6, md: 2, roots: true, order: 8 },
    { name: 'type', sm: 6, md: 2, roots: true, order: 9 },
    { name: 'infosource', sm: 6, md: 2, roots: true, order: 11 },

    { name: 'line', type: '-', sm: 12, md: 12, order: 13 },
    { name: 'maritalStatus', sm: 6, md: 2, roots: true, order: 13 },
    { name: 'occupation', sm: 6, md: 2, order: 14 },
    { name: 'VISIA', sm: 6, md: 2, order: 15 },

    { name: 'line2', type: '-', sm: 12, md: 12, order: 16 },

    { name: 'address', sm: 12, md: 12, order: 17 },
    { name: 'comment', sm: 12, md: 12, order: 18 },
    { name: 'line3', type: '-', sm: 12, md: 12, order: 98 }
  ]

  if (['lexcellence', 'ibeautyTaichung', 'ibeauty'].includes(process.env.BRANCH_ENV)) {
    _fields.push({ name: 'customercode', sm: 6, md: 2, order: 2 })
  }

  if (patientData.type === 'employee') {
    _fields.push({ name: 'employeeId', sm: 6, md: 2, order: 10 })
    _fields.push({ name: 'benefitline', text: formatMessage({ id: 'staff.profile.benefit.roots' }), type: '-', order: 20 })
    _fields.push({ name: 'earned', sm: 6, md: 3, benefit: true, order: 21 })
    _fields.push({ name: 'used', sm: 6, md: 3, benefit: true, order: 22 })
    _fields.push({ name: 'balance', sm: 6, md: 3, benefit: true, order: 23 })
    // _fields.push()
  } else if (patientData.type === 'staffRelatives') {
    _fields.push({ name: 'employeeId', sm: 6, md: 2, order: 10 })
  } else if (patientData.referrer) {
    _fields.push({ name: 'referrer', sm: 6, md: 2, order: 10 })
  }

  if (Object.keys(storedValueCard).length > 0) {
    _fields.push({ name: 'storedValueCardLine', text: formatMessage({ id: 'patient.profile.storedValueBalance' }), type: '-', order: 24 })
    let newOrder = 25
    for (const product of Object.keys(storedValueCard)) {
      _fields.push({ name: `${productMapping[product].name}`, type: 'storedValue', sm: 3, value: storedValueCard[product], order: newOrder })
      newOrder += 1
    }
  }

  if (patientData.cantWait) {
    _fields.push({ name: 'cantWait', color: '#ffbf38', type: 'status', sm: 3, order: 99 })
  }
  if (patientData.allergy) {
    _fields.push({ name: 'allergy', color: '#fe2851', type: 'status', sm: 3, order: 99 })
  }
  if (patientData.blackList) {
    _fields.push({ name: 'blackList', color: '#000', type: 'status', sm: 3, order: 99 })
  }
  if (patientData.noPhone) {
    _fields.push({ name: 'noPhone', color: '#a1d938', type: 'status', sm: 3, order: 99 })
  }
  if (patientData.noSms) {
    _fields.push({ name: 'noSms', color: '#2882ff', type: 'status', sm: 3, order: 99 })
  }
  if (patientData.vip) {
    _fields.push({ name: 'vip', color: '#c665c9', type: 'status', sm: 3, order: 99 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  const socialMediaFields = [
    { name: 'email', sm: 3, md: 3, order: 0 },
    { name: 'line', sm: 3, md: 3, order: 1 },
    { name: 'weChat', sm: 3, md: 3, order: 2 },
    { name: 'whatsApp', sm: 3, md: 3, order: 3 },
  ]

  const emergencyContactFields = [
    { name: 'name', type: 'emergencyContact', sm: 3, md: 3, order: 0 },
    { name: 'phoneNumber', type: 'emergencyContact', sm: 3, md: 3, order: 1 },
    { name: 'line', type: '-', sm: 12, md: 12, order: 2 }
  ]

  function createField(field, value, patient) {
    let newValue = patientData[field.name] ? patientData[field.name] : ''
    let labelText = ''

    if (field.name === 'salesRep') {
      newValue = patient.salesRepName
    }

    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}></Grid>
      }
    } else if (field.type === 'status') {
      return <Grid item key={field.name} xs={3} sm={field.sm} md={2} sx={{ position: 'relative' }}>
        <Chip sx={{ backgroundColor: field.color, color: '#fff', fontSize: '15px', minWidth: '100px' }} label={formatMessage({ id: `patient.profile.status.${field.name}` })} />
      </Grid>
    } else if (field.type === 'emergencyContact') {
      labelText = formatMessage({ id: `patient.profile.emergencyContacts.${field.name}` })
      newValue = patientData['ICE'][value][field.name]

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={labelText}
          value={newValue}
          fullWidth
          variant="standard"
          size="small"
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    } else if (field.type === 'storedValue') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={field.name}
          value={field.value}
          fullWidth
          variant="standard"
          size="small"
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    }

    if (field.name === 'customerId') {
      const birthDateM = patientData.birthDate ? patientData.birthDate.split('-')[1] : ''
      const birthDateD = patientData.birthDate ? patientData.birthDate.split('-')[2] : ''
      newValue = `${birthDateM !== undefined ? birthDateM : ''}${birthDateD !== undefined ? birthDateD : ''}${patientData.name}`
    } else if (field.name === 'referrer') {
      newValue = patientMapping[value] && formatName(patientMapping[value].name)
    } else if (['gender', 'maritalStatus', 'nationality', 'infosource', 'type'].includes(field.name) && value) {
      if (field.name === 'infosource' &&
        !['advertising',
          'newspapermagazine',
          'officialwebsite',
          'facebook',
          'blog',
          'google',
          'propagandamaterial',
          'line',
          'doctor',
          'instagram'].includes(value)) {
        newValue = `${formatMessage({ id: `patient.profile.${field.name}.other` })}: ${value}`
      } else {
        newValue = formatMessage({ id: `patient.profile.${field.name}.${value}` })
      }
    }

    if (field.roots) {
      labelText = formatMessage({ id: `patient.profile.${field.name}.roots` })
    } else if (field.benefit) {
      labelText = formatMessage({ id: `patient.profile.benefit.${field.name}` })
    } else {
      labelText = formatMessage({ id: `patient.profile.${field.name}` })
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        fullWidth
        variant="standard"
        size="small"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  const tabs = [
    { label: formatMessage({ id: 'patient.tab.skinCareHistory' }), component: <SkinCareHistory skinCareHistory={historyMapping.skinCareHistory} /> },
    { label: formatMessage({ id: 'patient.tab.medicalHistory' }), component: <MedicalHistory medicalHistory={historyMapping.medicalHistory} /> },
    { label: formatMessage({ id: 'patient.tab.cpf' }), component: <Preferences preferences={historyMapping.preferences} /> },
  ]

  let defaultSelect
  const onTabSelected = (tabIndex) => {
    defaultSelect = tabIndex

    if (tabIndex === 0) {
      setActiveTabIndex('skinCareHistory')
    } else if (tabIndex === 1) {
      setActiveTabIndex('medicalHistory')
    } else if (tabIndex === 2) {
      setActiveTabIndex('preferences')
    }
  }

  function handleClose() {
    setOpenEditDialog(null)
  }

  return (
    <Root>
      {openEditDialog && openEditDialog.type === 'information' && <EditInformationDialog
        currentUser={currentUser}
        handleClose={() => handleClose()}
        data={patientData}
        patientId={patient.id}
      />}
      {openEditDialog && openEditDialog.type === 'ICE' && <EditICEPage
        handleClose={() => handleClose()}
        data={patientData}
        patientId={patient.id}
      />}
      {openEditDialog && openEditDialog.type === 'socialMedia' && <EditSocialMediaPage
        handleClose={() => handleClose()}
        data={patientData}
        patientId={patient.id}
      />}
      {openEditDialog && openEditDialog.type === activeTabIndex && <EditHistoryPage
        handleClose={() => handleClose()}
        data={historyMapping[`${activeTabIndex}`]}
        patientId={patient.id}
        historyType={activeTabIndex}
      />}
      <Paper>
        <Grid container spacing={2} sx={{ padding: '20px' }}>
          <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative' }}>
            <Divider sx={{ mt: '8px' }} />
            <DividerText style={{ top: '-6px', }}>
              <Typography color="textSecondary" variant="caption">
                {formatMessage({ id: 'patient.profile.information' })}
              </Typography>
              <Tooltip title={formatMessage({ id: 'button.edit' })}>
                <IconButton onClick={e => setOpenEditDialog({ type: 'information' })} size="large">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </DividerText>
          </Grid>
          {fields.map(field => createField(field, patientData[field.name], patientData))}
          <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative', marginTop: '20px' }}>
            <Divider sx={{ mt: '8px' }} />
            <DividerText style={{ top: '-6px', }}>
              <Typography color="textSecondary" variant="caption">
                {formatMessage({ id: 'patient.profile.emergencyContacts.root' })}
              </Typography>
              <Tooltip title={formatMessage({ id: 'button.edit' })}>
                <IconButton onClick={e => setOpenEditDialog({ type: 'ICE' })} size="large">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </DividerText>
          </Grid>
          <>{patientData['ICE'] && Object.keys(patientData['ICE']).map(key => (emergencyContactFields.map(field => createField(field, key, patientData))))}</>
          <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative', marginTop: '20px' }}>
            <Divider sx={{ mt: '8px' }} />
            <DividerText style={{ top: '-6px', }}>
              <Typography color="textSecondary" variant="caption">
                {formatMessage({ id: 'patient.profile.socialMedia' })}
              </Typography>
              <Tooltip title={formatMessage({ id: 'button.edit' })}>
                <IconButton onClick={e => setOpenEditDialog({ type: 'socialMedia' })} size="large">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </DividerText>
          </Grid>
          {socialMediaFields.map(field => createField(field, patientData[field.name], patientData))}
        </Grid>
      </Paper>
      <Paper>
        <Grid container spacing={2} sx={{ padding: '20px' }}>
          <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative', marginTop: '20px', marginBottom: '20px' }}>
            <Divider sx={{ mt: '8px' }} />
            <DividerText style={{ top: '-6px', }}>
              <Typography color="textSecondary" variant="caption">
                {formatMessage({ id: 'patient.preferences.roots' })}
              </Typography>
              <Tooltip title={formatMessage({ id: 'button.edit' })}>
                <IconButton onClick={e => setOpenEditDialog({ type: activeTabIndex })} size="large">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </DividerText>
          </Grid>
          <div style={{ padding: '10px' }}>
            <TabContainer activeTabIndex={0} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
          </div>
        </Grid>
      </Paper>
    </Root>
  );
}

Profile.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,

  }),
  patient: PropTypes.object.isRequired,
  benefit: PropTypes.object,
  userMapping: PropTypes.object,
  patientMapping: PropTypes.object.isRequired,
  historyMapping: PropTypes.object.isRequired,
  productMapping: PropTypes.object.isRequired,
  storedValueCard: PropTypes.object.isRequired
};

export default Profile;
