import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import firebase from 'firebase/app';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { objectToArray } from '../../../modules/data'
import DatePickerField from '../../../components/DatePickerField'
import SelectPatientDialog from '../../../components/SelectPatientDialog'
import SelectStaffDialog from '../../../components/SelectStaffDialog'
import { formatName } from '../../../modules/uitls'
import ButtonProgress from '../../../components/ButtonProgress';

const newMapping = {
  gender: ['male', 'female'],
  type: ['new', 'regular', 'introduced', 'employee', 'staffRelatives', 'sales', 'kol', 'pr'],
  countryCode: ['Taiwan', 'HK', 'China', 'USA', 'Japan', 'Singapore'],
  nationality: ['taiwan', 'hongkong', 'macao', 'china', 'other'],
  infosource: ['advertising', 'newspapermagazine', 'officialwebsite', 'facebook', 'blog', 'google', 'propagandamaterial', 'line', 'doctor', 'instagram', 'other'],
  maritalStatus: ['married', 'single']
}

function EditInformationDialog({ currentUser, handleClose, data, patientId }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [patients, setPatients] = useState([]);
  const [users, setUsers] = useState([])
  const [openSelectPatientDialog, setOpenSelectPatientDialog] = useState(false)
  const [openSelectStaffDialog, setOpenSelectStaffDialog] = useState(false)
  const defultData = {
    name: formatName(data.name) || '',
    gender: data.gender || '',
    identityCardNumber: data.identityCardNumber || '',
    phone: data.phone || '',
    birthDate: data.birthDate || '',
    salesRep: data.salesRep || '',
    type: data.type || '',
    customercode: data.customercode || '',
    nationality: data.nationality || '',
    infosource: !['advertising', 'newspapermagazine', 'officialwebsite', 'facebook', 'blog', 'google', 'propagandamaterial', 'line', 'doctor', 'instagram', ''].includes(data.infosource) ?
      'other' : data.infosource || '',
    infosourceOther: !['advertising', 'newspapermagazine', 'officialwebsite', 'facebook', 'blog', 'google', 'propagandamaterial', 'line', 'doctor', 'instagram', ''].includes(data.infosource) ?
      data.infosource : '',
    maritalStatus: data.maritalStatus || '',
    occupation: data.occupation || '',
    address: data.address || '',
    VISIA: data['VISIA'] || '',
    comment: data.comment || '',
    blackList: data.blackList || false,
    vip: data.vip || false,
    cantWait: data.cantWait || false,
    noPhone: data.noPhone || false,
    noSms: data.noSms || false,
    employeeId: data.employeeId || '',
    referrer: data.referrer || '',
  }
  const [patientData, setPatientData] = useState(defultData)
  const filterUser = users && users.filter(u => {
    if (['reborn', 'lexcellence'].includes(process.env.BRANCH_ENV)) {
      return ['salesRep', 'nurse'].includes(u.department) && u.active
    } else if (process.env.BRANCH_ENV === 'santea') {
      return ['nurse'].includes(u.department) && u.active
    } else {
      return ['salesRep'].includes(u.department) && u.active
    }
  })

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setPatients(objectToArray(snapshot.val()))
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('users')
    const onDataChange = ref.on('value', snapshot => {
      setUsers(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  const _fields = [
    { name: 'name', type: 'text', md: 6, sm: 12, required: true, order: 0 },
    { name: 'gender', type: 'text', md: 6, sm: 12, required: true, roots: true, select: true, order: 2 },
    { name: 'identityCardNumber', type: 'text', md: 6, sm: 12, order: 3 },
    // { name: 'countryCode', type: 'text', md: 6, sm: 12, roots: true, select: true, order: 3 },
    { name: 'phone', type: 'text', md: 6, sm: 12, order: 4 },
    { name: 'birthDate', type: 'time', md: 6, sm: 12, order: 5 },
    { name: 'salesRep', type: 'salesRep', select: true, md: 6, sm: 12, order: 11 },
    { name: 'type', type: 'text', md: 6, sm: 12, roots: true, select: true, order: 7 },
    { name: 'nationality', type: 'text', md: 6, sm: 12, roots: true, select: true, order: 10 },
    { name: 'infosource', type: 'text', md: 6, sm: 12, roots: true, select: true, order: 9 },
    { name: 'maritalStatus', type: 'text', md: 6, sm: 12, roots: true, select: true, order: 12 },
    { name: 'occupation', type: 'text', md: 6, sm: 12, order: 13 },
    { name: 'address', type: 'text', md: 6, sm: 12, order: 14 },
    { name: 'VISIA', type: 'text', md: 6, sm: 12, order: 15 },
    { name: 'comment', multiline: true, type: 'text', md: 12, sm: 12, order: 16 },

    { name: 'blackList', type: 'check', md: 3, sm: 6, order: 17 },
    { name: 'cantWait', type: 'check', md: 3, sm: 6, order: 18 },
    { name: 'noPhone', type: 'check', md: 3, sm: 6, order: 19 },
    { name: 'noSms', type: 'check', md: 3, sm: 6, order: 20 },
    { name: 'vip', type: 'check', md: 3, sm: 6, order: 21 }
  ]

  if (patientData.type === 'employee' || patientData.type === 'staffRelatives') {
    _fields.push({ name: 'employeeId', type: 'text-select', required: true, md: 6, sm: 12, order: 8 },)
  } else if (patientData.type === 'introduced') {
    _fields.push({ name: 'referrer', selectPatient: true, type: 'text', required: true, md: 6, sm: 12, order: 8 },)
  }

  if (patientData.infosource === 'other') {
    _fields.push({ name: 'infosourceOther', type: 'text', required: true, md: 6, sm: 12, order: 9 },)
  }

  if (['lexcellence', 'ibeautyTaichung', 'ibeauty'].includes(process.env.BRANCH_ENV)) {
    _fields.push({ name: 'customercode', type: 'text', md: 6, sm: 12, order: 1 },)
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function savePatient() {
    setLoadingApprove(true)

    let err = {}
    let newData = patientData

    for (let field of fields) {
      if (field.required && patientData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...patientData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setPatientData(newData)
      return setLoadingApprove(false)
    }

    const updateData = {
      name: patientData.name || '',
      gender: patientData.gender || '',
      identityCardNumber: patientData.identityCardNumber || '',
      phone: patientData.phone || '',
      customercode: patientData.customercode || '',
      birthDate: patientData.birthDate !== '' ? dayjs(patientData.birthDate).format('YYYY-MM-DD') : null,
      salesRep: patientData.salesRep || '',
      type: patientData.type ? formatMessage({ id: `patient.dialog.type.${patientData.type}` }) : '',
      nationality: patientData.nationality ? formatMessage({ id: `patient.dialog.nationality.${patientData.nationality}` }) : '',
      infosource: patientData.infosource ? formatMessage({ id: `patient.dialog.infosource.${patientData.infosource}` }) : '',
      maritalStatus: patientData.maritalStatus || '',
      occupation: patientData.occupation || '',
      address: patientData.address || '',
      VISIA: patientData['VISIA'] || '',
      comment: patientData.comment || '',
      blackList: patientData.blackList || false,
      vip: patientData.vip || false,
      cantWait: patientData.cantWait || false,
      noPhone: patientData.noPhone || false,
      noSms: patientData.noSms || false,
      employeeId: patientData.employeeId || '',
      referrer: patientData.referrer || '',
    }

    for (const field of Object.keys(updateData)) {
      if (updateData[field] === '') {
        delete updateData[field]
      }
    }

    if (patientData.infosource === 'other') {
      updateData.infosource = patientData.infosourceOther
    }

    try {
      await (firebase.functions().httpsCallable('savePatients'))({ id: patientId, ...updateData })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  function updateData(field, value) {
    let newValue = value

    if (field.name === 'birthDate') {
      if (!value) {
        newValue = ''
      }
    }

    let newData = { ...patientData, [field.name]: newValue }

    setPatientData(newData)
  }

  function handleCheckChange(event, field) {
    updateData({ name: `${field.name}` }, event.target.checked);
  };

  function createField(field) {
    let newValue = patientData[field.name] ? patientData[field.name] : ''
    let labelText = ''
    const selectFields = newMapping[`${field.name}`]

    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }

    if (field.name === 'referrer') {
      const patientName = patients.filter(p => p.id === newValue)
      newValue = patientName[0] ? formatName(patientName[0].name) : ''
    }

    if (field.name === 'identityCardNumber') {
      newValue = newValue ? newValue.toUpperCase() : ''
    }

    if (field.roots) {
      labelText = formatMessage({ id: `patient.dialog.${field.name}.roots` })
    } else {
      labelText = formatMessage({ id: `patient.dialog.${field.name}` })
    }

    if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    } else if (field.type === 'time') {
      labelText = formatMessage({ id: `patient.dialog.${field.name}` })

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          label={labelText}
          closeToolbar={false}
          value={newValue !== '' ? dayjs(newValue) : null}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'text') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select={field.select ? field.select : null}
          size="small"
          variant="outlined"
          onClick={() => field.selectPatient ? setOpenSelectPatientDialog(true) : {}}
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          error={patientData[`${field.name}_err`] ? true : false}
          helperText={patientData[`${field.name}_err`]}
        >
          {
            field.select && selectFields.map((selects, idx) => {
              return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `patient.dialog.${field.name}.${selects}` })}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'salesRep') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select={field.select}
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        >
          {
            filterUser.map((user, idx) => {
              return <MenuItem key={`${idx}`} value={user.id}>{user.displayName}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'check') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <FormControlLabel
          control={<Checkbox
            checked={patientData[field.name]}
            onChange={e => { handleCheckChange(e, field) }}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />}
          label={labelText}
        />
      </Grid>
    } else if (field.type === 'text-select') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          size="small"
          variant="outlined"
          onClick={() => setOpenSelectStaffDialog(true)}
          error={patientData[`${field.name}_err`] ? true : false}
          helperText={patientData[`${field.name}_err`]}
        />
      </Grid>
    }
  }

  const handleSelectPatienSave = (patient) => {
    updateData({ name: 'referrer' }, patient.id)
  }

  const handleSelectPatienClose = () => {
    setOpenSelectPatientDialog(false)
  }

  const handleSelectStaffSave = (staff) => {
    updateData({ name: 'employeeId' }, staff.employeeId)
  }

  const handleSelectStaffClose = () => {
    setOpenSelectStaffDialog(false)
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {openSelectPatientDialog && <SelectPatientDialog
        handleClose={handleSelectPatienClose}
        handleSave={handleSelectPatienSave}
        patients={patients || []}
        dialogTitle={formatMessage({ id: 'selectReferrerDialog.title' })}
      />}
      {openSelectStaffDialog && <SelectStaffDialog
        handleClose={handleSelectStaffClose}
        handleSave={handleSelectStaffSave}
        defaultSelectedItems={patientData.employeeId}
        dialogTitle={formatMessage({ id: 'selectEmployeeIdDialog.title' })}
      />}
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'patient.dialog.information.edit' })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: '8px' }}>
            {fields.map(field => createField(field))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonProgress handleClose={handleClose} handleClick={() => savePatient()} loading={loadingApprove} buttonText='button.save' />
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditInformationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired
};

export default EditInformationDialog;
