import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';

import { objectToArray } from '../modules/data'
import DatePickerField from './DatePickerField';
import SelectPatientDialog from './SelectPatientDialog'
import SelectStaffDialog from './SelectStaffDialog'
import ButtonProgress from './ButtonProgress';

const newMapping = {
  gender: ['male', 'female'],
  type: ['new', 'regular', 'introduced', 'employee', 'staffRelatives', 'sales', 'kol', 'pr'],
  countryCode: ['Taiwan', 'HK', 'China', 'USA', 'Japan', 'Singapore'],
  infosource: ['advertising', 'newspapermagazine', 'officialwebsite', 'facebook', 'blog', 'google', 'propagandamaterial', 'line', 'doctor', 'instagram', 'other'],
  nationality: ['taiwan', 'hongkong', 'macao', 'china', 'other']
}

export default function NewPatientDialog({ dialogTital, handleClose, patients, handleSave = null, defaultData = null }) {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [users, setUsers] = useState([])
  const [patientData, setPatientData] = useState({
    name: defaultData ? defaultData.name : '',
    gender: '',
    countryCode: 'Taiwan',
    phone: defaultData ? defaultData.phone : '',
    type: 'new',
    salesRep: '',
    birthDate: '',
    employeeId: '',
    referrer: '',
    infosource: '',
    infosourceOther: '',
    nationality: 'taiwan',
    identityCardNumber: ''
  })
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingThird, setLoadingThird] = useState(false);
  const [openSelectPatientDialog, setOpenSelectPatientDialog] = useState(false)
  const [openSelectStaffDialog, setOpenSelectStaffDialog] = useState(false)
  const filterUser = users && users.filter(u => {
    if (['reborn', 'lexcellence'].includes(process.env.BRANCH_ENV)) {
      return u.active && ['salesRep', 'nurse'].includes(u.department) && u.active
    } else if (process.env.BRANCH_ENV === 'santea') {
      return u.active && ['nurse'].includes(u.department) && u.active
    } else {
      return u.active && ['salesRep'].includes(u.department) && u.active
    }
  })

  useEffect(() => {
    const ref = firebase.database().ref('users')
    const onDataChange = ref.on('value', snapshot => {
      setUsers(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  const numberRule = /[^0-9]/g

  const _fields = [
    { name: 'name', type: 'text', md: 6, sm: 6, required: true, order: 0 },
    { name: 'gender', type: 'text', md: 6, sm: 6, required: true, roots: true, select: true, order: 1 },
    { name: 'nationality', type: 'text', md: 6, sm: 6, select: true, required: true, roots: true, order: 2 },
    { name: 'identityCardNumber', type: 'text', md: 6, sm: 6, order: 3 },
    { name: 'countryCode', type: 'text', md: 6, sm: 6, roots: true, select: true, order: 4 },
    { name: 'phone', type: 'phone', md: 6, sm: 6, order: 5, allowCharacter: numberRule, mask: '9999-999-999' },
    { name: 'birthDate', type: 'time', md: 6, sm: 6, order: 6 },
    { name: 'salesRep', type: 'salesRep', select: true, md: 6, sm: 6, order: 7 },
    { name: 'type', type: 'text', md: 6, sm: 6, roots: true, select: true, order: 8 },
  ]

  if (patientData.type === 'employee' || patientData.type === 'staffRelatives') {
    _fields.push({ name: 'employeeId', type: 'text-select', required: true, md: 6, sm: 6, order: 9 },)
  } else if (patientData.type === 'introduced') {
    _fields.push({ name: 'referrer', selectPatient: true, type: 'text', required: true, md: 6, sm: 6, order: 10 },)
  } else if (patientData.type === 'new') {
    _fields.push({ name: 'infosource', type: 'text', required: true, md: 6, sm: 6, roots: true, select: true, order: 9 },)
  }

  if (patientData.infosource === 'other') {
    _fields.push({ name: 'infosourceOther', type: 'text', required: true, md: 6, sm: 12, order: 11 },)
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function savePatient(nextStep) {
    if (nextStep) {
      setLoadingThird(true)
    } else {
      setLoadingApprove(true)
    }

    let err = {}
    let newData = patientData

    for (let field of fields) {
      if (field.required && patientData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...patientData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setPatientData(newData)

      if (nextStep) {
        return setLoadingThird(false)
      } else {
        return setLoadingApprove(false)
      }
    }

    let data = {
      name: newData.name,
      birthDate: newData.birthDate !== '' ? dayjs(newData.birthDate).format('YYYY-MM-DD') : null,
      phone: formatPhoneNum(newData.countryCode, newData.phone),
      type: formatMessage({ id: `patient.dialog.type.${newData.type}` }),
      employeeId: newData.employeeId,
      gender: newData.gender,
      identityCardNumber: newData.identityCardNumber,
      referrer: newData.referrer,
      salesRep: newData.salesRep.id,
      infosource: formatMessage({ id: `patient.dialog.infosource.${newData.infosource}` }),
    }

    if (patientData.infosource === 'other') {
      newData.infosource = patientData.infosourceOther
    }

    for (const key of Object.keys(data)) {
      if (!data[key]) {
        delete data[key]
      }
    }

    try {
      const res = await (firebase.functions().httpsCallable('savePatients'))({ id: 'new', ...data })
      if (handleSave && res) {
        handleSave(res.data)
      } else if (nextStep && res) {
        setLoadingThird(false)
        history.push(`/patients/${res.data}/profile`)
      }

      if (!nextStep) {
        setLoadingApprove(false)
        handleClose()
      }

    } catch (ex) {
      console.log(ex)

      if (nextStep) {
        setLoadingThird(false)
      } else {
        setLoadingApprove(false)
      }

      handleClose()
    }
  }

  function formatPhoneNum(countryCode, phone) {
    let countryCodeNum = ''
    if (countryCode === 'Taiwan') {
      countryCodeNum = '+886'
    } else if (countryCode === 'HK') {
      countryCodeNum = '+852'
    } else if (countryCode === 'China') {
      countryCodeNum = '+86'
    } else if (countryCode === 'USA') {
      countryCodeNum = '+1'
    } else if (countryCode === 'Japan') {
      countryCodeNum = '+81'
    } else if (countryCode === 'Singapore') {
      countryCodeNum = '+65'
    }

    const newPhone = phone.replaceAll('-', '')
    const firstNum = newPhone.slice(0, 1)
    const phones = firstNum === '0' ? newPhone.slice(1) : newPhone

    const value = `${countryCodeNum}${phones}`

    return value
  }

  function updateData(field, value) {
    let newValue = value

    if (field.name === 'birthDate') {
      if (!value) {
        newValue = ''
      }
    }

    let err = validateField(field, value)
    let newData = { ...patientData, [field.name]: newValue, [`${field.name}_err`]: err }

    setPatientData(newData)
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    if (field.name === 'phone' && value !== '(   )    -    #     ' && value !== '') {
      let p = value.split('#')[0].trim()
      if (p.length < 12) return formatMessage({ id: 'form.mobile.formatError' })
    }

    return ''
  }

  function onPhoneChanged(field, value) {
    updateData({ name: `${field.name}` }, value);
  }

  function createField(field) {
    let newValue = patientData[field.name]
    let labelText = ''
    let selectFields;

    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }

    if (field.name === 'referrer') {
      const patientName = patients.filter(p => p.id === newValue)
      newValue = patientName[0] ? patientName[0].name : ''
    }

    if (field.name === 'identityCardNumber') {
      newValue = newValue ? newValue.toUpperCase() : ''
    }

    if (field.roots) {
      if (field.name === 'type') {
        labelText = newValue ? formatMessage({ id: `patient.dialog.${field.name}.${newValue}` }) : ''
      } else {
        labelText = formatMessage({ id: `patient.dialog.${field.name}.roots` })
      }
    } else {
      labelText = formatMessage({ id: `patient.dialog.${field.name}` })
    }

    if (['gender', 'type', 'countryCode', 'infosource', 'nationality'].includes(field.name)) {
      selectFields = newMapping[`${field.name}`]
    }

    if (field.type === '-') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    } else if (field.type === 'time') {
      labelText = formatMessage({ id: `patient.dialog.${field.name}` })

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          closeToolbar={false}
          label={labelText}
          value={newValue !== '' ? dayjs(newValue) : null}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'text') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select={field.select ? field.select : null}
          size="small"
          variant="outlined"
          onClick={() => field.selectPatient ? setOpenSelectPatientDialog(true) : {}}
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          error={patientData[`${field.name}_err`] ? true : false}
          helperText={patientData[`${field.name}_err`]}
        >
          {
            field.select && selectFields.map((selects, idx) => {
              return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `patient.dialog.${field.name}.${selects}` })}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'salesRep') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select={field.select}
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        >
          {
            filterUser.map((user, idx) => {
              return <MenuItem key={`${idx}`} value={user}>{user.displayName}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'phone') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <InputMask
          mask={field.mask}
          maskChar=" "
          onChange={e => { onPhoneChanged(field, e.target.value) }}
          value={patientData[field.name]}
        >
          {() => <TextField
            type="text"
            size="small"
            label={labelText}
            variant="outlined"
            onCompositionStart={
              e => {
                e.target.addEventListener('input', e2 => {
                  if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                    e2.preventDefault()
                    e2.stopPropagation()
                    e2.stopImmediatePropagation()
                  }
                }, { once: true })
              }
            }
            error={patientData[`${field.name}_err`] ? true : false}
            helperText={patientData[`${field.name}_err`]}
            fullWidth
          />}
        </InputMask>
      </Grid>
    } else if (field.type === 'text-select') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          size="small"
          variant="outlined"
          onClick={() => setOpenSelectStaffDialog(true)}
          error={patientData[`${field.name}_err`] ? true : false}
          helperText={patientData[`${field.name}_err`]}
        />
      </Grid>
    }
  }

  const handleSelectPatienSave = (patient) => {
    updateData({ name: 'referrer' }, patient.id)
  }

  const handleSelectPatienClose = () => {
    setOpenSelectPatientDialog(false)
  }

  const handleSelectStaffSave = (staff) => {
    updateData({ name: 'employeeId' }, staff.employeeId)
  }

  const handleSelectStaffClose = () => {
    setOpenSelectStaffDialog(false)
  }

  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      {openSelectPatientDialog && <SelectPatientDialog
        handleClose={handleSelectPatienClose}
        handleSave={handleSelectPatienSave}
        patients={patients || []}
        dialogTitle={formatMessage({ id: 'selectEmployeeIdDialog.title' })}
      />}
      {openSelectStaffDialog && <SelectStaffDialog
        handleClose={handleSelectStaffClose}
        handleSave={handleSelectStaffSave}
        defaultSelectedItems={patientData.employeeId}
        dialogTitle={formatMessage({ id: 'selectReferrerDialog.title' })}
      />}
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTital}</DialogTitle>
        <DialogContent sx={{ minHeight: '410px' }}>
          <Grid sx={{ pt: '8px' }} container spacing={2}>
            {fields.map(field => createField(field))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonProgress
            handleClose={handleClose}
            handleClick={() => savePatient()}
            loading={loadingApprove}
            thirdButton={['santea', 'lexcellence'].includes(process.env.BRANCH_ENV) ?
              {
                handleSave: () => savePatient(true),
                text: 'button.saveEdit',
                loading: loadingThird
              } : null
            }
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

NewPatientDialog.propTypes = {
  dialogTital: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  patients: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSave: PropTypes.func,
  defaultData: PropTypes.object
};
