import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import firebase from 'firebase/app';

import 'chart.js'

import Grid from '@mui/material/Grid';

import TabContainer from '../../containers/TabContainer/TabContainer';
import PurchaseReport from './PurchaseReport';
import PersonalBonus from './PersonalBonus';
import NormalPersonalBonus from './NormalPersonalBonus'
import ConsumedMerchandises from './ConsumedMerchandises'

function Reports({ currentUser }) {
  const { formatMessage } = useIntl()
  const { typeName, dateType } = useParams()
  const history = useHistory()
  const [patientMapping, setPatientMapping] = useState({})
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const pathname = history.location.pathname

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      setPatientMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    if (pathname === `/reports/${typeName}/day` && activeTabIndex !== 0) {
      setActiveTabIndex(0)
    } else if (pathname === `/reports/${typeName}/month` && activeTabIndex !== 1) {
      setActiveTabIndex(1)
    } else if (pathname === `/reports/${typeName}/season` && activeTabIndex !== 2) {
      setActiveTabIndex(2)
    }
    return () => {
    };
  }, [pathname]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      history.push(`/reports/${typeName}/day`);
    } else if (tabIndex === 1) {
      history.push(`/reports/${typeName}/month`);
    } else if (tabIndex === 2) {
      history.push(`/reports/${typeName}/season`);
    }
  };

  let defaultSelect
  if (dateType === 'day') {
    defaultSelect = 0
  } else if (dateType === 'month') {
    defaultSelect = 1
  } else if (dateType === 'season') {
    defaultSelect = 2
  }

  function createComponent() {
    if (typeName === 'personal') {
      if (['lexcellence', 'reborn'].includes(process.env.BRANCH_ENV)) {
        return <PersonalBonus currentUser={currentUser} patientMapping={patientMapping} />
      } else {
        return <NormalPersonalBonus currentUser={currentUser} patientMapping={patientMapping} />
      }
    } else if (typeName === 'consume') {
      const tabs = [
        {
          label: formatMessage({ id: 'report.consumedMerchandise.title.day' }),
          component: <ConsumedMerchandises currentUser={currentUser} />,
          order: 0
        },
        {
          label: formatMessage({ id: 'report.consumedMerchandise.title.month' }),
          component: <ConsumedMerchandises currentUser={currentUser} />,
          order: 0
        },
      ].sort((a, b) => a.order - b.order)

      return <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif' }}>
        <div style={{ padding: '20px', }}>
          <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
        </div>
      </div>
    } else {
      const tabs = [
        {
          label: formatMessage({ id: 'report.title.purchase.day' }),
          component: <PurchaseReport patientMapping={patientMapping} />,
          order: 0
        },
        {
          label: formatMessage({ id: 'report.title.purchase.month' }),
          component: <PurchaseReport patientMapping={patientMapping} />,
          order: 0
        },
        {
          label: formatMessage({ id: 'report.title.purchase.season' }),
          component: <PurchaseReport patientMapping={patientMapping} />,
          order: 0
        }
      ].sort((a, b) => a.order - b.order)

      return <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif' }}>
        <div style={{ padding: '20px', }}>
          <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
        </div>
      </div>
    }
  }

  return (
    <Grid container spacing={0} sx={{ marginTop: '15px', fontFamily: 'Roboto, sans-serif' }}>
      {createComponent()}
    </Grid>
  );
}

Reports.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    announcement: PropTypes.number.isRequired
  }),
};

export default Reports;
