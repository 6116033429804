import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import SalaryIcon from '@mui/icons-material/AttachMoney';

import ReviewStaff from './ReviewStaff'
import FabComponent from '../../components/FabComponent'
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import SearchBox from '../../components/SearchBox';
import ExpandButton from '../../components/ExpandButton';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import { objectToArray } from '../../modules/data'
import warehouse from '../../modules/warehouseConfig'
import OccupationMapping from '../../enum/OccupationMapping';
import ContextStore from '../../modules/context';

function Staff({ userRight }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('status')
  const [users, setUsers] = useState([])
  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [benefit, setBenefit] = useState(null)
  const filteredUsers = currentFilter && currentFilter.text ? filterByText() : users

  useEffect(() => {
    const ref = firebase.database(warehouse).ref('benefitBalances')
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      setBenefit(snapshots)
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('users')
    const onDataChange = ref.on('value', snapshot => {
      setUsers(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.staffManagement.staff' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  if (!benefit) {
    return ('Loading...')
  }

  const formatData = (user) => {
    const newData = { ...user }
    newData.name = newData.displayName
    newData.status = newData.active ? 'active' : 'inactive'
    newData.statusName = newData.active ? '啟用' : '停用'
    if (['santea'].includes(process.env.BRANCH_ENV) && ['nurse', 'salesRep'].includes(newData.department)) {
      newData.departmentName = formatMessage({ id: `staff.profile.departmentSantea.${newData.department}` })
    } else {
      newData.departmentName = formatMessage({ id: `staff.profile.department.${newData.department}` })
    }
    newData.position = newData.isManagement ? '主管' : '職員'
    newData.gender = formatMessage({ id: `staff.profile.gender.${newData.gender}` })
    newData.appointmentType = newData.appointmentType && formatMessage({ id: `staff.profile.appointmentType.${newData.appointmentType}` })
    newData.occupation = OccupationMapping.filter(o => o.value === newData.occupation)[0]?.name
    if (benefit[newData.employeeId]) {
      newData.earned = benefit[newData.employeeId].earned
      newData.used = benefit[newData.employeeId].used
      newData.balance = benefit[newData.employeeId].balanceHistory ?
        benefit[newData.employeeId].earned - benefit[newData.employeeId].used + benefit[newData.employeeId].balanceHistory :
        benefit[newData.employeeId].earned - benefit[newData.employeeId].used
    }

    if (!newData.active) {
      newData.textColor = '#bab7b7'
    }

    return newData
  }

  function filterByText() {
    if (currentFilter.name === 'name') {
      return users.filter(s => s.displayName && s.displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'email') {
      return users.filter(s => s.email.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'department') {
      return users.filter(s => formatMessage({ id: `staff.profile.department.${s.department}` }).toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'onBoardingDate') {
      return users.filter(s => s.onBoardingDate.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else {
      return users.filter(s => formatMessage({ id: `staff.table.${s.active ? 'active' : 'inactive'}` }).toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const headerCells = [
    { text: 'name', sort: 'name' },
    { text: 'email', sort: 'email' },
    { text: 'department', sort: 'department' },
    { text: 'occupation', sort: 'occupation' },
    { text: 'onBoardingDate', sort: 'onBoardingDate' },
    { text: 'status', sort: 'active' },
  ].map(c => { c.text = formatMessage({ id: `staff.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'name', textColor: true, account: true },
    { field: 'email', textColor: true },
    { field: 'departmentName', textColor: true },
    { field: 'occupation', textColor: true },
    { field: 'onBoardingDate', textColor: true },
    { field: 'statusName', textColor: true },
  ]

  const filterItems = [
    { name: 'name' },
    { name: 'email', },
    { name: 'department' },
    { name: 'onBoardingDate' },
    { name: 'status' },
  ].map(i => { i.text = formatMessage({ id: `staff.table.${i.name}` }); return i })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', padding: '20px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'noMessage'}
            bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
            toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filteredUsers.length}
                expandable
                actionButton
              />
              <TableBody>
                {stableSort(filteredUsers.map(r => formatData(r)), getComparator(order, orderBy)).map(user => (
                  <EnhancedTableRow
                    key={user.id}
                    rowCells={rowCells}
                    cellData={user}
                    expandable
                    expandContent={<ReviewStaff user={user} />}
                    actionIcons={<>
                      {userRight['salary-edit'] ? <Tooltip title={formatMessage({ id: 'button.salary' })}>
                        <IconButton
                          onClick={() => history.push(`/staffManagement/staff/editSalary/${user.id}`)}
                          size="large">
                          <SalaryIcon />
                        </IconButton>
                      </Tooltip> : null}
                      {userRight['staff-edit'] ? <Tooltip title={formatMessage({ id: 'button.edit' })}>
                        <IconButton
                          onClick={() => history.push(`/staffManagement/staff/edit/${user.id}`)}
                          size="large">
                          <EditIcon />
                        </IconButton>
                      </Tooltip> : null}
                    </>
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {userRight['staff-edit'] && <div>
          <FabComponent onClick={() => history.push('/staffManagement/staff/edit/new')} />
        </div>}
      </Grid>
    </div>
  );
}

Staff.propTypes = {
  userRight: PropTypes.object.isRequired
};

export default Staff;
