const i18n = {
  'patient': {
    'table': {
      'name': '客戶',
      'birthDate': '生日',
      'phone': '電話',
      'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
      'lastAppointmentDateTime': '最新預約',
      'identityCardNumber': 'ID',
    },
    'filter': {
      'blackList': {
        'root': '黑名單搜尋',
        'all': '全部',
        'on': '黑名單',
        'off': '非黑名單'
      },
      'vip': {
        'root': 'VIP搜尋',
        'all': '全部',
        'on': 'VIP',
        'off': '非VIP'
      }
    },
    'tab': {
      'profile': '個人資料',
      'kardexes': 'Kardex',
      'consumption': '療程歷史紀錄',
      'certificateOfDiagnosisList': '診斷證明歷史紀錄',
      'prescriptionSign': '處方箋歷史紀錄',
      'purchases': '營收單',
      'agreements': '同意書',
      'medicalHistory': '特殊病史與用藥史',
      'skinCareHistory': '美容醫療史',
      'cpf': 'CPF',
    },
    'dialog': {
      'information': {
        'add': '新建客戶',
        'edit': '編輯資訊'
      },
      'editEmergencyContacts': '編輯緊急聯絡人',
      'name': '姓名',
      'blackList': '黑名單',
      'vip': 'VIP客戶',
      'cantWait': '不耐久候',
      'noPhone': '勿電話',
      'noSms': '勿簡訊',
      'occupation': '職業',
      'address': '地址',
      'VISIA': 'VISIA',
      'comment': '註記',
      'maritalStatus': {
        'roots': '婚姻',
        'married': '已婚',
        'single': '未婚'
      },
      'nationality': {
        'roots': '國籍',
        'taiwan': '台灣',
        'hongkong': '香港',
        'macao': '澳門',
        'china': '大陸',
        'other': '其他'
      },
      'customercode': '客戶代碼',
      'infosourceOther': '其他資訊',
      'infosource': {
        'advertising': '戶外廣告',
        'newspapermagazine': '報章雜誌',
        'officialwebsite': '官網',
        'facebook': 'Facebook官方帳號',
        'blog': '部落格',
        'google': 'Google',
        'propagandamaterial': '宣傳品(體驗卷,DM)',
        'line': '生活圈',
        'doctor': '醫師自帶',
        'instagram': 'Instagram',
        'other': '其他',
      },
      'gender': {
        'roots': '性別',
        'male': '男',
        'female': '女',
      },
      'identityCardNumber': '身份證字號',
      'countryCode': {
        'roots': '國碼',
        'Taiwan': '台灣(+886)',
        'HK': '香港(+852)',
        'China': '大陸(+86)',
        'USA': '美加(+1)',
        'Japan': '日本(+81)',
        'Singapore': '新加坡(+65)',
      },
      'phone': '電話',
      'birthDate': '生日',
      'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '負責業務' : '負責客代',
      'type': {
        'roots': '客戶類型',
        'new': '新客',
        'regular': '舊客',
        'introduced': '舊帶新',
        'employee': '員工',
        'staffRelatives': '員工眷屬',
        'sales': '業務',
        'kol': '意見領袖',
        'pr': '公關客'
      },
      'referrer': '介紹人',
      'employeeId': '員工編號',
      'selectStaff': {
        'displayName': '員工姓名',
        'employeeId': '員工編號'
      },
      'email': 'Email',
      'weChat': 'WeChat',
      'whatsApp': 'WhatsApp',
      'line': 'LINE',
      'skinCareHistory': {
        'edit': '編輯美容醫療史',
        'laser': '光療',
        'ipl': '脈衝光',
        'pl': '皮秒',
        'fx': '飛梭',
        'ultheraphy': '音波',
        'thermage': '電波',
        'laserOthers': '其他',
        'beautyTreatments': '美容',
        'chemicalPeeling': '酸類煥膚',
        'moisturizer': '保濕導入',
        'lpg': 'LPG體雕',
        'beautyTreatmentOthers': '其他',
        'injection': '注射',
        'beautyInjection': '肉毒桿菌',
        'injectionSite': '注射部位',
        'lastInjectionTime': '注射時間',
        'filler': '填充物',
        'fillerName': '填充物名稱',
        'fillerSite': '注射部位',
        'fillerTime': '注射時間'
      },
      'medicalHistory': {
        'edit': '編輯特殊病史與藥史',
        'disease': '特殊病史',
        'keloid': '蟹足腫',
        'heartDisease': '心臟病',
        'immuneSystem': '免疫系統疾病',
        'diabetes': '糖尿病',
        'hypertension': '高血壓',
        'eczema': '皮膚濕疹',
        'herpes': '單純皰疹',
        'hepatitisB': 'B型肝炎',
        'hepatitisC': 'C型肝炎',
        'diseaseOthers': '其他',
        'healthSupplementInUse': '使用中保健品',
        'ginseng': '人參',
        'ginkgo': '銀杏',
        'multivitamin': '綜合維他命',
        'vitaminE': '維他命E',
        'otherHealthSupplement': '其他',
        'pregnancy': '懷孕',
        'numberWeeks': '懷孕-週數',
        'allergyDrug': '藥物過敏',
        'drugName': '藥物名稱',
        'allergicSymptoms': '過敏症狀',
        'surgery': '手術史',
        'surgeryName': '手術史',
        'medicationInUse': '使用中藥物',
        'medicationInUseName': '使用中藥物-藥物名稱'
      },
      'preferences': {
        'edit': '編輯偏好與歷史紀錄',
        'phototherapy': '光療',
        'anestheticP': '麻藥',
        'preferenceP': '喜好',
        'microShaping': '微整',
        'anestheticM': '麻藥',
        'preferenceM': '喜好',
        'lift': '拉提',
        'anestheticL': '麻藥',
        'preferenceL': '喜好',
        'surgery': '手術-喜好',
        'gynecology': '婦科-喜好',
        'cosmetology': '美容-喜好',
        'bodyFiguring': '體雕-喜好',
        'careProduct': '保品-喜好',
        'customerPreferences': '顧客喜好',
      },
    },
    'medicalHistory': {
      'disease': {
        'roots': '特殊病史',
        'keloid': '蟹足腫',
        'heartDisease': '心臟病',
        'immuneSystem': '免疫系統疾病',
        'diabetes': '糖尿病',
        'hypertension': '高血壓',
        'eczema': '皮膚濕疹',
        'herpes': '單純皰疹',
        'hepatitisB': 'B型肝炎',
        'hepatitisC': 'C型肝炎',
        'others': '其他'
      },
      'healthSupplementInUse': {
        'roots': '使用中保健品',
        'ginseng': '人參',
        'ginkgo': '銀杏',
        'multivitamin': '綜合維他命',
        'vitaminE': '維他命E',
        'others': '其他',
      },
      'pregnancy': '懷孕',
      'numberWeeks': '懷孕-週數',
      'allergyDrug': '藥物過敏',
      'drugName': '藥物名稱',
      'allergicSymptoms': '過敏症狀',
      'surgery': '手術史',
      'medicationInUse': '使用中藥物',
      'medicationInUseName': '使用中藥物-藥物名稱'
    },
    'skinCareHistory': {
      'edit': '編輯美容醫療史',
      'laser': {
        'roots': '光療',
        'ipl': '脈衝光',
        'pl': '皮秒',
        'fx': '飛梭',
        'ultheraphy': '音波',
        'thermage': '電波',
        'others': '其他'
      },
      'beautyTreatments': {
        'roots': '美容',
        'chemicalPeeling': '酸類煥膚',
        'moisturizer': '保濕導入',
        'lpg': 'LPG體雕',
        'others': '其他'
      },
      'beautyInjection': '注射',
      'injectionSite': '肉毒桿菌-注射部位',
      'lastInjectionTime': '肉毒桿菌-注射時間',
      'filler': '填充物',
      'fillerName': '填充物名稱',
      'fillerSite': '注射部位',
      'fillerTime': '注射時間'
    },
    'preferences': {
      'roots': '偏好與歷史紀錄',
      'phototherapy': '光療',
      'anestheticP': '麻藥',
      'preferenceP': '喜好',
      'microShaping': '微整',
      'anestheticM': '麻藥',
      'preferenceM': '喜好',
      'lift': '拉提',
      'anestheticL': '麻藥',
      'preferenceL': '喜好',
      'surgery': '手術-喜好',
      'gynecology': '婦科-喜好',
      'cosmetology': '美容-喜好',
      'bodyFiguring': '體雕-喜好',
      'careProduct': '保品-喜好',
      'customerPreferences': '顧客喜好',
      'anesthetic': {
        'root': '麻藥',
        'icePack': '冰敷',
        'painkiller': '止痛藥',
        'hypoallergenic': '低敏麻藥',
        'normal': '一般麻藥',
        'no': '不敷麻'
      }
    },
    'profile': {
      'information': '資訊',
      'emergencyContacts': {
        'root': '緊急聯絡人',
        'name': '姓名',
        'phoneNumber': '電話',
      },
      'socialMedia': '社群媒體',
      'name': '姓名',
      'phone': '電話號碼',
      'email': 'Email',
      'line': 'LINE',
      'status': {
        'cantWait': '不耐久候',
        'blackList': '黑名單',
        'noPhone': '勿電話',
        'noSms': '勿簡訊',
        'vip': 'VIP客戶',
        'allergy': '過敏'
      },
      'gender': {
        'roots': '性別',
        'male': '男',
        'female': '女',
      },
      'address': '地址',
      'allergy': '過敏',
      'birthDate': '生日',
      'blackList': '黑名單',
      'cantWait': '不耐久候',
      'noPhone': '勿電話',
      'noSms': '勿簡訊',
      'type': {
        'roots': '客戶類型',
        'new': '新客',
        'regular': '舊客',
        'introduced': '舊帶新',
        'employee': '員工',
        'staffRelatives': '員工眷屬',
        'sales': '業務',
        'kol': '意見領袖',
        'pr': '公關客'
      },
      'employeeId': '員工編號',
      'nationality': {
        'roots': '國籍',
        'taiwan': '台灣',
        'hongkong': '香港',
        'macao': '澳門',
        'china': '大陸',
        'other': '其他'
      },
      'infosource': {
        'advertising': '戶外廣告',
        'newspapermagazine': '報章雜誌',
        'officialwebsite': '官網',
        'facebook': 'Facebook官方帳號',
        'blog': '部落格',
        'google': 'Google',
        'propagandamaterial': '宣傳品(體驗卷,DM)',
        'line': '生活圈',
        'doctor': '醫師自帶',
        'instagram': 'Instagram',
        'other': '其他',
      },
      'referrer': '介紹人',
      'id': 'ID',
      'identityCardNumber': '身份證字號',
      'comment': '註記',
      'VISIA': 'VISIA',
      'maritalStatus': {
        'roots': '婚姻',
        'married': '已婚',
        'single': '未婚'
      },
      'customercode': '客戶代碼',
      'customerId': '客戶編號',
      'tag': '標籤',
      'occupation': '職業',
      'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
      'weChat': 'WeChat',
      'whatsApp': 'WhatsApp',
      'benefit': {
        'roots': '員工福利點數額度',
        'earned': '年度福利點數',
        'used': '年度已用',
        'balance': '可用總額'
      },
      'storedValueBalance': '儲值點數'
    }
  },
}

if (process.env.BRANCH_ENV === 'lexcellence') {
  i18n.patient.profile.infosource.roots = '如何得知安珀(測試)'
  i18n.patient.dialog.infosource.roots = '如何得知安珀(測試)'
} else if (process.env.BRANCH_ENV === 'reborn') {
  i18n.patient.profile.infosource.roots = '如何得知安珀'
  i18n.patient.dialog.infosource.roots = '如何得知安珀'
} else if (process.env.BRANCH_ENV === 'santea') {
  i18n.patient.profile.infosource.roots = '如何得知聖諦亞'
  i18n.patient.dialog.infosource.roots = '如何得知聖諦亞'
} else if (process.env.BRANCH_ENV === 'ibeauty') {
  i18n.patient.profile.infosource.roots = '如何得知聖緹雅醫美'
  i18n.patient.dialog.infosource.roots = '如何得知聖緹雅醫美'
} else if (process.env.BRANCH_ENV === 'ibeautyTaichung') {
  i18n.patient.profile.infosource.roots = '如何得知聖緹雅時尚'
  i18n.patient.dialog.infosource.roots = '如何得知聖緹雅時尚'
} else if (process.env.BRANCH_ENV === 'ibeautyPlastic') {
  i18n.patient.profile.infosource.roots = '如何得知聖緹雅整外'
  i18n.patient.dialog.infosource.roots = '如何得知聖緹雅整外'
} else if (process.env.BRANCH_ENV === 'ibeautyHsinchu') {
  i18n.patient.profile.infosource.roots = '如何得知聖緹雅美學'
  i18n.patient.dialog.infosource.roots = '如何得知聖緹雅美學'
}

export default i18n