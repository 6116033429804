import React from 'react';
import { useIntl } from 'react-intl';

import EnhancedTable from '../../components/EnhancedTable';

function QuotationList() {
  const { formatMessage } = useIntl()

  const headerCells = [
    { text: 'date', sort: 'date' },
  ].map(c => { c.text = formatMessage({ id: `quotation.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'date' },
  ]

  return (
    <div style={{ padding: '20px' }}>
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="date"
        headerCells={headerCells}
        rowCells={rowCells}
        expandable
        getExpandContent={quotation =>
          <div>aaaa</div>
        }
        tableData={[]}
      />
    </div>
  );
}

export default QuotationList;
