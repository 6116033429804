import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from '../modules/sort';
import EnhancedTableRow from './EnhancedTableRow';
import EnhancedTableHead from './EnhancedTableHead';
import SearchBox from './SearchBox';
import ExpandButton from './ExpandButton';
import SimpleTableToolbar from './SimpleTableToolbar';
import { formatName } from '../modules/uitls';

export default function SelectPatientDialog({ handleClose, handleSave, patients, size = 'xl', dialogTitle, isMobile }) {
  const { formatMessage } = useIntl()
  const [currentFilter, setCurrentFilter] = useState(null)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [expand, setExpand] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const userMapping = useSelector(state => state.firebase.data.users);
  const formatedPatients = patients.map(p => { p.name = formatName(p.name); return p })

  const _headerCells = [
    { text: 'name', sort: 'name', order: 0 },
    // { text: 'birthDate', sort: 'birthDate', order: 1 },
    // { text: 'phone', sort: 'phone', order: 2 },
    { text: 'salesRep', sort: 'salesRep', order: 3 },
  ]

  const _rowCells = [
    { field: 'name', order: 0 },
    // { field: 'birthDate', order: 1 },
    // { field: 'phone', order: 2 },
    { field: 'salesRepName', order: 3 },
  ]

  const headerCells = (isMobile ?
    [
      ..._headerCells,
      {
        text: (['phone', 'birthDate'].includes(currentFilter?.name) ? currentFilter?.name : 'birthDate'),
        sort: (['phone', 'birthDate'].includes(currentFilter?.name) ? currentFilter?.name : 'birthDate'),
        order: 2
      }
    ] :
    [
      ..._headerCells,
      { text: 'birthDate', sort: 'birthDate', order: 1 },
      { text: 'phone', sort: 'phone', order: 2 }
    ]
  ).map(c => { c.text = formatMessage({ id: `patient.table.${c.text}` }); return c }).sort((a, b) => a.order - b.order)

  const rowCells = (isMobile ?
    [
      ..._rowCells,
      { field: (['phone', 'birthDate'].includes(currentFilter?.name) ? currentFilter?.name : 'birthDate'), order: 2 }
    ] :
    [
      ..._rowCells,
      { field: 'birthDate', order: 1 },
      { field: 'phone', order: 2 }
    ]
  ).sort((a, b) => a.order - b.order)

  const filterItems = [
    { name: 'name' },
    { name: 'birthDate', },
    { name: 'phone' },
    { name: 'salesRep' },
  ].map(i => { i.text = formatMessage({ id: `patient.table.${i.name}` }); return i })

  function filterByText() {
    if (currentFilter.name === 'name') {
      return formatedPatients.filter(s => s.name.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'birthDate') {
      return formatedPatients.filter(s => s.birthDate && s.birthDate.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'phone') {
      return formatedPatients.filter(s => s.phone && s.phone.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'salesRep') {
      return formatedPatients.filter(s => s.salesRep && userMapping[s.salesRep].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatData = (data) => {
    const newData = { ...data }
    newData.salesRepName = userMapping[newData.salesRep] ? userMapping[newData.salesRep].displayName : ''

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const onApply = (patient) => {
    handleSave(patient)
    handleClose()
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredPatients = currentFilter && currentFilter.text ? filterByText() : formatedPatients

  return (
    <Dialog
      fullWidth={true}
      maxWidth={size}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <SimpleTableToolbar
              title={'noMessage'}
              bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
              toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <EnhancedTableHead
                  headerCells={headerCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredPatients.length}
                />
                <TableBody>
                  {stableSort(filteredPatients.map(r => formatData(r)), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(patient => (
                      <EnhancedTableRow
                        key={patient.id}
                        rowCells={rowCells}
                        cellData={patient}
                        onRowClick={() => onApply(patient)}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredPatients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={formatMessage({ id: 'button.perPage' })}
              labelDisplayedRows={({ from, to, count }) => `第${from}至${to}列 (共${count}列)`}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
      </DialogActions>
    </Dialog>

  );
}

SelectPatientDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  patients: PropTypes.arrayOf(PropTypes.object).isRequired,
  dialogTitle: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  size: PropTypes.string,
  isMobile: PropTypes.bool
};