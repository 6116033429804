import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { minutesToLeft, minutesToWidth, minutesToTop, minutesToHeight, timeToMinutes } from '../../modules/time'
import { objectToArray } from '../../modules/data'
import StatusIndicator from '../../components/StatusIndicator'
import { treatmentColorAndBgColor } from '../../modules/treatment'
import { moibleMedia } from '../../constants/index'
import { formatName } from '../../modules/uitls';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5px',
    [moibleMedia]: {
      padding: 0,
      height: 'auto',
      width: '134px'
    }
  },
  row1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 5px 0',
    minHeight: '20px',
    [moibleMedia]: {
      padding: '0 2px',
      marginBottom: 0,
      maxHeight: '21px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  row2: {
    display: 'flex',
    flexDirection: 'row',
    [moibleMedia]: {
      flexDirection: 'column',
    }
  },
  patientInfo: {
    color: 'rgba(0, 0, 0, 0.7)',
    margin: '0 10px 0 0'
  },
  rep: {
    color: '#828a99',
    margin: 0
  },
  room: {
    color: '#828a99',
    '&:before': {
      content: '""',
      display: 'inline-block',
      width: '4px',
      height: '4px',
      borderRadius: '4px',
      backgroundColor: '#828a99',
      verticalAlign: 'middle',
      margin: '-2px 5px 0'
    }
  },
  treatment: {
    fontSize: '15px',
    color: '#ffffff',
    height: '24px',
    padding: '0 5px',
    backgroundColor: '#d8d8d8',
    fontFamily: 'PingFang TC',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '24px',
    textAlign: 'left',
    '&:first-child': {
      borderRadius: '4px 0 0 4px'
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
    '&:only-child': {
      borderRadius: '4px'
    },
    [moibleMedia]: {
      width: '134px',
      borderRadius: '0!important'
    }
  }
});

function ScheduleRowAppointment({ appointment, firstHour, left, top, patientList, ui, selectAppointment }) {
  const classes = useStyles();
  const rowRef = useRef()
  const userMapping = useSelector(state => state.firebase.data.users);
  const patient = patientList[appointment.patient] || {}
  const nurse = userMapping[appointment.nurse] || {}
  const room = appointment ? appointment.room : {}
  const salesRep = userMapping[appointment.salesRep] || {}
  const treatments = objectToArray(appointment.treatments, 'order').filter(p => !p.parent)
  const minutes = timeToMinutes(appointment.hour, appointment.minute, firstHour)
  const newLeft = minutesToLeft(minutes)
  const newTop = minutesToTop(minutes)
  const comment = String(appointment.comment).split('\n')
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <div
      ref={rowRef}
      className={classes.container}
      style={{
        left: `${isMobile ? left : newLeft}px`,
        top: `${isMobile ? newTop : top}px`,
      }}
      onClick={(e) => selectAppointment(e, appointment)}
    >
      <div className={classes.row1}>
        <StatusIndicator status={appointment.status} />
        <div className={classes.patientInfo}>
          {patient?.birthDate ?? ''} {patient.name ? formatName(patient?.name ?? '') : comment.length > 0 ? comment[0] : '待確認'}{ui.showPatientType && `(${patient.type})`}
        </div>
        <div className={classes.rep}>
          {ui.showSalesRep && salesRep.displayName} {ui.showNurse && nurse.displayName}
        </div>
        {ui.showRoom && <div className={classes.room}>
          {room || <FormattedMessage id='appointment.noRoom' />}
        </div>}
      </div>
      <div className={classes.row2}>
        {treatments.filter(t => !t.hidden).map((treatment, key) => {
          let { color, backgroundColor } = treatmentColorAndBgColor(treatment)
          let treatmentname

          if (appointment.status === 'cancelled' || appointment.status === 'cancelanotherappointment') {
            backgroundColor = treatment.type !== 'pre-surgery' &&
              treatment.type !== 'post-surgery'
              ? '#9EA6B7' : '#e4e7eb'
          }

          treatmentname = treatment.nickname // modify at 2019/12/25 by ping
            ? treatment.nickname : treatment.name

          return <div
            key={key}
            className={classes.treatment}
            style={{
              width: isMobile ? '134px' : minutesToWidth(treatment?.duration ?? 0),
              height: isMobile ? minutesToHeight(treatment?.duration ?? 0) : '24px',
              background: backgroundColor,
              color: color
            }}
          >
            {
              treatmentname
            }
          </div>
        })}
      </div>
    </div>
  );
}

ScheduleRowAppointment.propTypes = {
  appointment: PropTypes.object.isRequired,
  firstHour: PropTypes.number,
  left: PropTypes.number,
  top: PropTypes.number,
  patientList: PropTypes.object.isRequired,
  ui: PropTypes.object,
  selectAppointment: PropTypes.func
};

export default ScheduleRowAppointment;
