import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import firebase from 'firebase/app';
import { createUseStyles } from 'react-jss'

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputMask from 'react-input-mask';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import DatePickerField from '../../components/DatePickerField';
import { moibleMedia } from '../../constants/index';

const primaryColor = process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence' ? {
  light: '#fffff7',
  main: '#eeccc4',
  dark: '#bb9b94',
  contrastText: '#a58a88',
} : process.env.BRANCH_ENV === 'santea' ? {
  light: '#6efeff',
  main: '#26CAD3',
  dark: '#0099a2',
  contrastText: '#fff',
} : {
  light: '#a3ffff',
  main: '#6EDBD5',
  dark: '#35a9a4',
  contrastText: '#fff',
}

const theme = createTheme({
  palette: {
    primary: primaryColor,
  },
});


const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '400px',
    height: '100vh',
    backgroundColor: '#ffffff',
    borderLeft: '1px solid #D4D4D4',
    zIndex: 10,
    paddingBottom: '60px',
    [moibleMedia]: {
      position: 'fixed',
      top: '100px',
      bottom: 0,
      left: 0,
      height: 'auto',
      width: '100vw',
      paddingBottom: 0
    }
  },
  form: {
    width: '100%',
    height: 'calc(100% - 60px)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  header: {
    padding: '0 17px',
    backgroundColor: '#fafafa',
    '& h2': {
      marginBottom: '5px',
    }
  },
  dateTime: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    marginTop: '20px'
  },
  datePicker: {
    width: '140px',
    display: 'inline-flex',
    marginRight: '30px'
  },
  checkList: {
    display: 'flex',
    flexDirection: 'column',
    justifyConteant: 'flex-start',
  },
  listBody: {
    marginTop: '20px',
    // display: 'flex',
    // // flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItems: 'center'
  },
  footer: {
    height: '60px',
    width: '100%',
    backgroundColor: '#fafafa',
    boxShadow: 'inset 0 1px 0 0 rgba(0,0,0,.08)',
    bottom: 0,
    padding: '0 24px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      borderRadius: '16px !important',
      border: '1px solid #ccc !important',
      lineHeight: '1 !important',
      margin: '0 10px 0 0 !important',
      minWidth: '88px'
    }
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
    // color: 'blue'
  },
});

function CommentSidebar({ ui, onRequestClose, doctors, appointmentTypes }) {
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const [loadingApprove, setLoadingApprove] = useState(false);
  const commentId = ui.editComment ? ui.editComment.id : 'new'
  const userMapping = useSelector(state => state.firebase.data.users);
  const defultData = ui.editComment ? {
    date: dayjs(ui.editComment.date),
    startTime: ui.editComment.startTime,
    endTime: ui.editComment.endTime,
    content: ui.editComment.content
  } : {
    date: dayjs(ui.date),
    startTime: ui.clickMenuData ? `${ui.clickMenuData.hour}:00` : '',
    endTime: ui.clickMenuData ? `${ui.clickMenuData.hour + 1}:00` : '',
    content: '',
  }
  const [data, setData] = useState(defultData);
  const itemRows = appointmentTypes.concat(doctors.filter(d => d.active).map(d => d.id)).concat(['cancelled'])
  let newRows = ui.editComment ? ui.editComment.rows : {}
  itemRows.map(i => {
    if (ui.clickMenuData && ui.clickMenuData.doctor && ui.clickMenuData.doctor.id === i) {
      newRows[i] = true
    } else if (ui.clickMenuData && ui.clickMenuData.appointmentType === i && !ui.clickMenuData.doctor) {
      newRows[i] = true
    } else {
      if (!newRows[i]) {
        newRows[i] = false
      }
    }

    return newRows
  })

  const [selectItem, setSelectItem] = useState(newRows);
  const [selectAllItem, setSelectAllItem] = useState({
    notAll: ui.editComment ? true : false,
    isAll: false
  })

  async function saveComment() {
    setLoadingApprove(true)
    const newData = { ...data }
    const newItem = { ...selectItem }
    newData.date = dayjs(newData.date).format('YYYY-MM-DD')
    newData.startHour = parseInt(newData.startTime.split(':')[0])
    newData.startMinute = parseInt(newData.startTime.split(':')[1])
    newData.endHour = parseInt(newData.endTime.split(':')[0])
    newData.endMinute = parseInt(newData.endTime.split(':')[1])

    for (const s in newItem) {
      if (!newItem[s]) {
        delete newItem[s]
      }
    }

    newData.rows = newItem
    try {
      await (firebase.functions().httpsCallable('saveComment'))({ id: commentId, ...newData })
      setLoadingApprove(false)
      onRequestClose()
    } catch (ex) {
      console.log(ex)
      setLoadingApprove(false)
      onRequestClose()
    }
  }

  function updateData(field, value) {
    let newValue = value
    let newData = { ...data, [field.name]: newValue }

    setData(newData)
  }

  const handleToggle = (value) => () => {
    const currentValue = selectItem[value];
    const newSelectItem = { ...selectItem };
    let newItem = { ...newSelectItem, [value]: !currentValue }

    let partCheck = false
    let isAllCheck
    for (const i in newItem) {
      if (newItem[i]) {
        partCheck = true
        isAllCheck = true
      } else {
        isAllCheck = false
      }
    }

    setSelectItem(newItem)
    if (isAllCheck) {
      setSelectAllItem({ isAll: isAllCheck, notAll: false })
    } else {
      setSelectAllItem({ isAll: isAllCheck, notAll: partCheck })
    }
  };

  const selectAllCheck = (event) => {
    let newSelectItem = { ...selectItem };

    for (const i in newSelectItem) {
      newSelectItem[i] = event
    }

    setSelectItem(newSelectItem)
    setSelectAllItem({ ...selectAllItem, isAll: event })
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={classes.container}>
          <form className={classes.form}>
            <div className={classes.header}>
              <h2>
                <FormattedMessage id='appointment.sidebar.comment' />
              </h2>
              <div className={classes.dateTime}>
                <DatePickerField
                  required
                  fullWidth
                  underLine={'standard'}
                  label={formatMessage({ id: 'appointment.sidebar.date' })}
                  value={data.date}
                  onChange={date => updateData({ name: 'date' }, date)}
                  invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
                />
                <InputMask
                  mask={'99:99'}
                  maskChar=" "
                  onChange={e => updateData({ name: 'startTime' }, e.target.value)}
                  value={data.startTime}
                >
                  {() => <TextField
                    style={{ marginLeft: '10px', width: '50%' }}
                    type="text"
                    size="small"
                    variant="standard"
                    fullWidth
                    label={formatMessage({ id: 'appointment.sidebar.startTime' })}
                    onCompositionStart={
                      e => {
                        e.target.addEventListener('input', e2 => {
                          if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                            e2.preventDefault()
                            e2.stopPropagation()
                            e2.stopImmediatePropagation()
                          }
                        }, { once: true })
                      }
                    }
                  />}
                </InputMask>
                <InputMask
                  mask={'99:99'}
                  maskChar=" "
                  onChange={e => updateData({ name: 'endTime' }, e.target.value)}
                  value={data.endTime}
                >
                  {() => <TextField
                    style={{ marginLeft: '5px', width: '50%' }}
                    type="text"
                    size="small"
                    variant="standard"
                    fullWidth
                    label={formatMessage({ id: 'appointment.sidebar.endTime' })}
                    onCompositionStart={
                      e => {
                        e.target.addEventListener('input', e2 => {
                          if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                            e2.preventDefault()
                            e2.stopPropagation()
                            e2.stopImmediatePropagation()
                          }
                        }, { once: true })
                      }
                    }
                  />}
                </InputMask>
              </div>
            </div>
            <div className={classes.checkList}>
              <div style={{ padding: '0 20px' }}>
                <TextField
                  fullWidth
                  label={<FormattedMessage id='appointment.sidebar.comment' />}
                  variant="outlined"
                  value={data.content}
                  multiline
                  minrows={5}
                  onChange={e => updateData({ name: 'content' }, e.target.value)}
                />
              </div>
              <div className={classes.listBody}>
                <div style={{ marginLeft: '15px', padding: '0 20px', borderBottom: '1px solid rgba(0,0,0,.1)' }}>
                  <Checkbox
                    checked={selectAllItem.isAll}
                    indeterminate={selectAllItem.notAll}
                    disableRipple
                    onClick={(e) => selectAllCheck(e.target.checked)}
                  />
                </div>
                <List style={{ padding: '0 20px', borderBottom: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fafafa' }}>
                  {itemRows.map((value, index) => {
                    let newValue = ''
                    if (appointmentTypes.concat(['cancelled']).includes(value)) {
                      newValue = formatMessage({ id: `appointment.sidebar.appointmentType.${value}` })
                    } else {
                      newValue = userMapping[value].displayName
                    }

                    return (
                      <ListItem key={index} role={undefined} dense button onClick={handleToggle(value)}>
                        <ListItemIcon>
                          <Checkbox
                            checked={selectItem[value]}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={index}
                          primary={newValue}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </div>
          </form>
          <div className={classes.footer}>
            <Button size='large' onClick={onRequestClose}>{formatMessage({ id: 'button.cancel' })}</Button>
            <div className={classes.wrapper}>
              <Button size='large' onClick={() => saveComment()} style={{ color: '#eeccc4' }} disabled={loadingApprove} >{formatMessage({ id: 'button.save' })}</Button>
              {loadingApprove && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

CommentSidebar.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  appointmentTypes: PropTypes.array.isRequired,
  doctors: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default CommentSidebar;
