import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import EnhancedTableHead from '../../../components/EnhancedTableHead';
import EnhancedTableRow from '../../../components/EnhancedTableRow';
import { objectToArray } from '../../../modules/data';
import ButtonProgress from '../../../components/ButtonProgress';

function TreatmentDialog({ treatment, treatmentKey, handleClose, userMapping }) {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState({
    ...treatment,
    assistant: treatment.assistant || '',
    cost: treatment.cost || 0,
    key: treatmentKey,
  })
  const { kardexId } = useParams()
  const userData = [];
  for (const u in userMapping) {
    userData.push({ id: u, ...userMapping[u] })
  }
  const assistants = userData.filter(u => u.active && ['nurse'].includes(u.department))

  async function saveTreatment() {
    setLoading(true)

    try {
      await (firebase.functions().httpsCallable('saveKardexTreatment'))({ id: kardexId, ...currentData })
      setLoading(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
      setLoading(false)
      handleClose()
    }
  }

  function createField(field) {
    let newValue = currentData[field.name] || ''
    let labelText = formatMessage({ id: `kardex.treatment.${field.name}` });

    return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        required={field.required}
        label={labelText}
        value={newValue}
        fullWidth
        select={field.select ? field.select : null}
        size="small"
        variant="outlined"
        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        error={currentData[`${field.name}_err`] ? true : false}
        helperText={currentData[`${field.name}_err`]}
      >
        {
          field.select && assistants.map((user, key) => {
            return <MenuItem value={user.id} key={key} >{user.displayName}</MenuItem>
          })
        }
      </TextField>
    </Grid>
  }

  function updateData(field, value) {
    let newValue = value
    if (field.name === 'cost') {
      newValue = Number(newValue)
    }

    let err = validateField(field, value)
    let newData = { ...currentData, [field.name]: newValue, [`${field.name}_err`]: err }

    setCurrentData(newData)
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  const _fields = [
    { name: 'cost', type: 'text', md: 6, sm: 6, required: true, order: 0 },
    { name: 'assistant', type: 'text', md: 6, sm: 6, select: true, order: 1 },
  ]

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })


  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={true} fullWidth >
      <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'kardex.treatment.title' })}</DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid container spacing={2} sx={{ mt: '1px' }}>
          {fields.map(field => createField(field))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonProgress handleClick={() => saveTreatment()} handleClose={handleClose} loading={loading} buttonText='button.save' />
      </DialogActions>
    </Dialog>
  );
}

TreatmentDialog.propTypes = {
  treatment: PropTypes.object.isRequired,
  treatmentKey: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  userMapping: PropTypes.object.isRequired
};

function TreatmentPage({ treatments }) {
  const { formatMessage } = useIntl()
  const filterTreatment = objectToArray(treatments).filter(t => t.type === 'product')
  const treatmentData = filterTreatment.reduce((acc, cur) => {
    if (['7', '8', '9'].includes(cur.cat0)) {
      acc.push(cur)
      filterTreatment.forEach(t => {
        if (t.parent === cur.id) {
          acc.push(t)
        }
      })
    } else if (cur.cat0 === '1' && !cur.parent) {
      acc.push(cur)
    }
    return acc
  }, [])

  const [openDialog, setOpenDialog] = useState(null)
  const userMapping = useSelector(state => state.firebase.data.users);

  const headerCells = [
    { text: 'code' },
    { text: 'name' },
    { text: 'cost' },
    { text: 'assistant' },
  ].map(c => { c.text = formatMessage({ id: `kardex.treatment.${c.text}` }); return c })

  const rowCells = [
    { field: 'code', textColor: true, },
    { field: 'name', textColor: true, },
    { field: 'cost', textColor: true, },
    { field: 'assistant', textColor: true, },
  ]

  function formatData(treatment) {
    const newData = { ...treatment }
    newData.isPackage = newData.parent && 'isPackage'
    newData.assistant = userMapping[newData.assistant]?.displayName

    if (newData.isPackage) {
      newData.textColor = '#bbb'
    }

    return newData
  }

  function handleClose() {
    setOpenDialog(null)
  }
  return (
    <div style={{ padding: '24px 20px' }}>
      {openDialog && <TreatmentDialog
        treatment={{ ...treatments[openDialog] }}
        treatmentKey={openDialog}
        handleClose={handleClose}
        userMapping={userMapping}
      />}
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            rowCount={treatmentData.length}
            expandable
            actionButton
          />
          <TableBody>
            {treatmentData.map(r => formatData(r)).map(treatment => (
              <EnhancedTableRow
                key={treatment.id}
                rowCells={rowCells}
                cellData={treatment}
                expandable
                actionIcons={!['7', '8', '9'].includes(treatment.cat0) ?
                  <Tooltip title={formatMessage({ id: 'button.edit' })}>
                    <IconButton size='small' onClick={() => setOpenDialog(treatment.id)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip> : <div />
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

TreatmentPage.propTypes = {
  treatments: PropTypes.object,
};

export default TreatmentPage;
