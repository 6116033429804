export default function StatusIndicator(status) {
  switch (status) {
  case 'not-confirmed':
    return '#c641e2'
  case 'profile-needed':
    return '#2a9bf2'
  case 'ready':
    return 'solid 2px #000000'
  case 'not-arrived':
    return 'solid 2px #000000'
  case 'arrived':
    return '#ffbf38'
  case 'late':
    return '#fe2851'
  case 'cancelled':
    return '#fe2851'
  case 'complete':
    return '#10d09e'
  case 'cancelanotherappointment':
    return '#d4e20c'
  default:
    return ''
  }
};