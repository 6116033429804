import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from '../../modules/sort';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../components/SimpleTableToolbar'

function BalanceDetail({ data }) {
  const { formatMessage } = useIntl()
  const currentLeaves = Object.keys(data.leaves).reduce((acc, cur) => {
    acc.push({ id: cur, ...data.leaves[cur] })
    return acc
  }, []).sort((a, b) => {
    if (a.name > b.name) {
      return -1
    } else if (a.name < b.name) {
      return 1
    } else {
      return 0
    }
  })

  const currentOvertime = Object.keys(data.overtime).reduce((acc, cur) => {
    acc.push({ id: cur, ...data.overtime[cur] })
    return acc
  }, [])

  const overtimeTypeMapping = {
    'pay': '補薪',
    'shift': '值夜班',
    'leave': '補休'
  }

  const formatData = (data, type) => {
    const newData = { ...data }
    if (type === 'leave') {
      newData.typeName = newData.name
      const newEarned = `${(newData.earned - (newData.earned % 8)) / 8}天${newData.earned % 8}小時`
      const newUsed = `${(newData.used - (newData.used % 8)) / 8}天${newData.used % 8}小時`
      const newBalance = newData.earned - newData.used
      newData.earned = newEarned
      newData.used = newUsed
      newData.balance = `${(newBalance - (newBalance % 8)) / 8}天${newBalance % 8}小時`
    } else {
      newData.overtimeType = overtimeTypeMapping[newData.id]
      newData.earned = `${newData.earned}小時`
    }

    return newData
  }

  const headerCells = [
    { text: 'typeName' },
    { text: 'earned' },
    { text: 'used' },
    { text: 'balance' },
  ].map(c => { c.text = formatMessage({ id: `finance.balance.detail.${c.text}` }); return c })

  const rowCells = [
    { field: 'typeName' },
    { field: 'earned' },
    { field: 'used' },
    { field: 'balance' },
  ]

  const headerCells2 = [
    { text: 'overtimeType' },
    { text: 'earned' },
  ].map(c => { c.text = formatMessage({ id: `finance.balance.detail.${c.text}` }); return c })

  const rowCells2 = [
    { field: 'overtimeType' },
    { field: 'earned' },
  ]

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <SimpleTableToolbar
          title={'finance.balance.title.leave'}
        />
        <TableContainer component={Paper}>
          <Table size="small" sx={{ marginTop: '4px' }} aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              rowCount={currentLeaves.length}
            />
            <TableBody>
              {currentLeaves.map(c => formatData(c, 'leave')).map(data => (
                <EnhancedTableRow
                  key={data.id}
                  rowCells={rowCells}
                  cellData={data}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <SimpleTableToolbar
          title={'finance.balance.title.overtime'}
        />
        <TableContainer component={Paper}>
          <Table size="small" sx={{ marginTop: '4px' }} aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells2}
              rowCount={currentOvertime.length}
            />
            <TableBody>
              {currentOvertime.map(c => formatData(c, 'overtime')).map(data => (
                <EnhancedTableRow
                  key={data.id}
                  rowCells={rowCells2}
                  cellData={data}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

BalanceDetail.propTypes = {
  data: PropTypes.object.isRequired,
};

function BalanceHistotyPage() {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date')
  const userMapping = useSelector(state => state.firebase.data.users);
  const [currentData, setCurrentData] = useState([])

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('balanceHistories').onSnapshot(snapshot => {
      const data = []
      snapshot.forEach(doc => {
        if (userMapping[doc.id] && userMapping[doc.id].active) {
          data.push({ ...doc.data(), id: doc.id })
        }
      });
      setCurrentData(data)
    }, err => { })

    return () => unsubscribe()
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatData = (data) => {
    const newData = { ...data }
    const user = userMapping[newData.id]

    newData.name = user.displayName
    newData.employeeId = user.employeeId
    newData.department = formatMessage({ id: `staff.profile.department.${user.department}` })
    newData.onBoardingDate = user.onBoardingDateOld || user.onBoardingDate

    return newData
  }

  const headerCells = [
    { text: 'name', sort: 'name' },
    { text: 'employeeId', sort: 'employeeId' },
    { text: 'department', sort: 'department' },
    { text: 'onBoardingDate', sort: 'onBoardingDate' },
  ].map(c => { c.text = formatMessage({ id: `finance.balance.${c.text}` }); return c })

  const rowCells = [
    { field: 'name' },
    { field: 'employeeId' },
    { field: 'department' },
    { field: 'onBoardingDate' },
  ]

  return (
    <Grid container spacing={1} sx={{ marginTop: '10px' }}>
      <Grid item xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ marginTop: '4px' }} aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={currentData.length}
              expandable
            />
            <TableBody>
              {stableSort(currentData.map(s => formatData(s)), getComparator(order, orderBy)).map(data => (
                <EnhancedTableRow
                  key={data.id}
                  rowCells={rowCells}
                  cellData={data}
                  expandable
                  expandContent={<BalanceDetail data={data} />}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default BalanceHistotyPage;
