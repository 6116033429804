const i18n = {
  'product': {
    'merchandises': '商品管理',
    'products': '產品管理',
    'selectDialog': {
      'title': '療程列表',
      'code': '品號',
      'name': '名稱',
      'nickname': '簡稱',
      'price': '售價',
      'duration': '療程時間',
    },
    'commonPrice': {
      'title': '常用價格列表',
      'name': '價格名稱',
      'fixedAmount': '固定數量',
      'useAmount': '使用次數',
      'price': '價格',
      'onShelfDate': '上架日期',
      'offShelfDate': '下架日期',
    },
    'merchandise': {
      'name': '品名',
      'detail': {
        'nickname': '簡稱',
        'type': '商品類型',
        'usage': '用量與方法',
        'dose': '藥膏數量（罐/條）',
        'total': '總數量（顆/次）'
      },
      'type': {
        'root': '商品類型',
        'medicines': '藥品',
        'merchandises': '固定商品',
        'adjustables': '變動商品'
      },
      'usage': {
        'root': '用量與方法',
        'QID': '一天四次，三餐飯後/前與睡前(QID)',
        'QD': '一天一次(QD)',
        'BID': '一天兩次(BID)',
        'TID': '一天三次，三餐飯後(TID)',
        'HS': '每晚睡前半小時一次(HS)',
        'QN': '每晚一次(QN)',
        'PRN': '需要時使用(PRN)',
        'Stay': '不用列入處方籤(Stay)'
      },
      'dose': '藥膏數量（罐/條）',
      'total': '總數量（顆/次）'
    },
    'product': {
      'name': '品名',
      'ratio': '營收占比(%)',
      'price': '售價',
      'lowestPrice': '最低售價',
      'cost': '成本',
      'amount': '數量'
    },
    'detail': {
      'type': '分類1',
      'category': '分類2',
    },
    'table': {
      'title': '產品列表',
      'selected': '已選擇 {count} 項商品',
      'header': {
        'code': '品號',
        'name': '名稱',
        'cost': '成本',
        'price': '售價',
        'lowestPrice': '最低售價',
        'duration': '療程時間',
        'onShelfDate': '上架日期',
        'offShelfDate': '下架日期',
      },
      'detail': {
        'type': '分類1',
        'tags': '標籤',
        'code': '品號',
        'name': '名稱',
        'cost': '成本',
        'customers': '客戶',
        'note': '備註',
        'sku': '單位(SKU)',
        'staffprice': '員工價',
        'staffRelativesPrice': '員工眷屬價',
        'vipprice': 'VIP價',
        'consumable': '消耗類型',
        'directSales': '直接銷售',
        'consumedMerchandise': '療程消耗',
        'nickname': '簡稱',
        'onShelfDate': '上架日期',
        'offShelfDate': '下架日期',
        'doctorBonusType': '醫師獎金分類',
        'salesBonusType': ['santea'].includes(process.env.BRANCH_ENV) ? '業務獎金分類' : '客代獎金分類',
        'bonusType': '獎金分類',
        'operationBonus': '操作獎金',
        'description': '描述'
      },
      'bonusType': {
        'none': '無',
        'purchaseOrder': '銷售抽成',
        'treatment': '操作抽成'
      },
      'doctorBonusType': {
        'none': '無',
        'laser': '雷射',
        'microShaping': '微整',
        'lift': '拉提',
        'bodyFiguring': '體雕',
        'instalift': '埋線',
        'noImplantOp': '無植入物手術',
        'other': '其他',
      },
      'salesBonusType': {
        'none': '無',
        'a': 'A類',
        'b': 'B類',
        'c': 'C類',
      },
    },
    'category': {
      'all': '全部',
      'AM': {
        'LS': '光療',
        'AM': '諮詢',
        'LF': '拉提',
        'MI': '微整',
        'BT': '美容',
        'BD': '體雕',
      },
      'OP': {
        'FT': '身體 抽脂',
        'AF': '臉 自體補脂',
        'MA': '身體 隆乳',
        'EY': '臉 眼部',
        'CH': '臉 下巴',
        'NO': '臉 鼻',
        'EL': '身體 頂漿腺',
        'EX': '身體 微創除痣',
        'HH': '頭 植髮'
      },
      'SK': {
        'LT': '化妝水',
        'CM': '卸妝乳',
        'FC': '洗面乳',
        'HS': '洗髮類',
        'ES': '精華液',
        'CR': '乳霜類',
        'AA': '杏仁酸',
        'SP': '防曬類',
        'MA': '面膜',
        'OT': '其他',
      },
      'DP': {
        'PT': '點數',
      },
      'OT': {
        'OT': '其它',
      },
      'CN': {
        'FM': '功醫門診',
        'RH': '復健門診',
        'NC': '營養諮詢',
        'PC': '心理諮詢',
        'FC': '健身運動諮詢',
      },
      'EX': {
        'SN': '超音波',
        'AN': '自律神經檢測',
        'IB': '身體組成分析',
      },
      'TT': {
        'RD': '瀚仕',
        'QP': '立人',
        'UC': '聯合',
        'EB': '凌越',
        'GO': '金萬林',
        'AB': '百歐',
      },
      'GE': {
        'IL': '康健',
        'TS': '東生華',
        'GO': '金萬林',
        'AB': '百歐',
        'GH': '台灣基康',
      },
      'SU': {
        'MG': '中華生醫',
        'RD': '瀚仕',
        'XY': '亞星',
        '1C': '一中心',
        'IP': '恩美利',
        'VL': '健泉',
        'EP': '英霈斯',
        'HL': '赫而司',
        'OS': '歐舒邁克',
      },
      'ND': {
        'TB': '信東',
        'NK': '南光',
        'GH': '佳禾行',
        'SL': '杏林',
        'DS': '大盛',
        'SH': '德祥和',
        'EU': '應元製藥',
        'QU': '群祐生技',
        'OS': '歐舒邁克',
        'ML': '瑪里士',
        'GL': '吉如生技',
        'QL': '全友',
        'VT': '維多生技',
        'CS': '崇興行貿易',
        'XN': '杏恩',
        'MS': '默沙東',
        'UL': '裕利',
        'OT': '其他',
      },
      'CU': {
        'IS': '大腸水療',
        'FR': '遠紅外線',
        'PG': '骨盆帶',
        'IL': '靜脈雷射',
        'HB': '高壓氧艙',
        'OT': '其他'
      }
    },
    'type': {
      'beauty': '醫學美容',
      'AM': '醫美',
      'OP': '手術',
      'SK': '保品',
      'DP': '儲值',
      'OT': '其他',
      'preventative': '預防醫學',
      'CN': '諮詢',
      'EX': '檢查',
      'TT': '檢驗',
      'GE': '基因檢測',
      'SU': '營養品',
      'ND': '針劑',
      'CU': '療程',
      'other': '其他',
    }
  }
}

export default i18n