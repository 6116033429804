const i18n = {
  'form': {
    'field': {
      'isRequired': '欄位為必填',
      'notZero': '不可為零',
      'amount': '不得大於最低數量或為零',
      'price': '不得大於已收金額或為空'
    },
    'date': {
      'formatError': '無效的日期格式',
      'beforeStartDate': '日期不得小於開始日期',
      'afterEndDate': '日期不得大於結束日期',
      'afterToday': '日期不得大於今日',
      'beforeToday': '日期不得早於今日',
      'leaveError': '請假天數/時數錯誤'
    },
    'time': {
      'formatError': '無效的時間格式',
      'weeklyLeave': '本休不得超休'
    },
    'mobile': {
      'lengthError': '手機號碼長度錯誤',
      'formatError': '電話號碼格式錯誤',
    },
  }
}
export default i18n
