import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function Preferences({ preferences }) {
  const { formatMessage } = useIntl()

  if (!preferences) {
    return ('No Information...')
  }

  const _fields = [
    { name: 'phototherapy', text: formatMessage({ id: 'patient.preferences.phototherapy' }), type: '-', order: 0 },
    { name: 'anestheticP', type: 'phototherapy', sm: 12, md: 6, order: 1 },
    { name: 'preferenceP', type: 'phototherapyComment', sm: 12, md: 6, order: 2 },
    { name: 'microShaping', text: formatMessage({ id: 'patient.preferences.microShaping' }), type: '-', order: 3 },
    { name: 'anestheticM', type: 'microShaping', sm: 12, md: 6, order: 4 },
    { name: 'preferenceM', type: 'microShapingComment', sm: 12, md: 6, order: 5 },
    { name: 'lift', text: formatMessage({ id: 'patient.preferences.lift' }), type: '-', order: 6 },
    { name: 'anestheticL', type: 'lift', sm: 12, md: 6, order: 7 },
    { name: 'preferenceL', type: 'liftComment', sm: 12, md: 6, order: 8 },
    { name: 'surgery', sm: 12, md: 4, order: 9 },
    { name: 'gynecology', sm: 12, md: 4, order: 10 },
    { name: 'cosmetology', sm: 12, md: 4, order: 11 },
    { name: 'bodyFiguring', sm: 12, md: 4, order: 12 },
    { name: 'careProduct', sm: 12, md: 4, order: 13 },
    { name: 'customerPreferences', sm: 12, md: 4, order: 14 },
  ]


  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field) {
    let newValue = ''
    let labelText = ''

    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    } else if (['phototherapy', 'microShaping', 'lift'].includes(field.type)) {
      if (preferences[field.type]) {
        newValue = Object.keys(preferences[field.type]).map(x => {
          if (preferences[field.type][x]) {
            return formatMessage({ id: `patient.preferences.anesthetic.${x}` })
          }

          return ''
        }).join(', ')
      }
    } else if (['phototherapyComment', 'microShapingComment', 'liftComment'].includes(field.type)) {
      newValue = preferences[field.type]
    } else {
      newValue = preferences[`${field.name}Comment`] || ''
    }

    if (field.roots) {
      labelText = formatMessage({ id: `patient.preferences.${field.name}.roots` })
    } else {
      labelText = formatMessage({ id: `patient.preferences.${field.name}` })
    }


    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        fullWidth
        size="small"
        variant="standard"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field))}
      </Grid>
    </div>
  );
}

Preferences.propTypes = {
  preferences: PropTypes.object
};

export default Preferences;
