const i18n = {
  'header': {
    'menu': {
      'logout': '登出',
      'editUserRight': '編輯使用者權限',
      'editApproval': '編輯簽核流程',
      'editWorkTime': '編輯上下班時間'
    },
    'appointment': {
      'today': '今天',
      'NewReservation': '新增預約',
      'doctorSchedule': '醫師班表',
      'NewComment': '新增註記',
      'newBooking': '會議室/車位',
      'filter': {
        'noShifts': '顯示未值班醫師',
        'showNurse': ['santea'].includes(process.env.BRANCH_ENV) ? '顯示健管師' : '顯示護理師',
        'showRoom': '顯示診間',
        'showSalesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '顯示業務' : '顯示客代',
        'showPatientType': '顯示客戶類型'
      }
    },
    'userRight': {
      'title': '編輯權限',
      'root': '權限名稱',
      'editUserRight': '編輯使用者權限',
      'exception-edit': '編輯補班補假',
      'finance-edit': '編輯財務',
      'finance-view': '查看財務管理',
      'leaveOvertime-all': '查看全部請假加班',
      'leaveOvertime-view': '簽核請假加班',
      'patient-add': '新增客戶',
      'product-view': '查看療程管理',
      'schedule-edit': '編輯診所班表',
      'staff-edit': '編輯職員',
      'staff-view': '查看職員管理',
      'manage-view': '查看銷售管理',
      'doctorSchedule-edit': '編輯醫師班表',
      'report-view': '查看報表',
      'salary-edit': '編輯薪資管理',
      'calendar-edit': '編輯車位/會議室'
    },
    'approval': {
      'title': '編輯簽核流程',
      'root': '權限名稱',
      'type': {
        'managerLeaves': '主管請假申請',
        'managerOvertimes': '主管加班申請',
        'managerPunchClockRevise': '主管補打卡申請',
        'normalLeaves': '職員請假申請',
        'normalOvertimes': '職員加班申請',
        'normalPunchClockRevise': '職員補打卡申請'
      }
    },
    'workTime': {
      'title': '編輯上下班時間',
    },
    'dialog': {
      'user': {
        'selectUserDialog': '選擇使用者',
        'title': '使用者列表',
        'root': '使用者',
        'displayName': '姓名',
        'email': 'Email'
      }
    },
    'notice': {
      'reviewLeaveOvertime': '待簽核請假加班',
      'ddapp': 'Digital Dr.預約',
      'announcement': '公告'
    }
  }
}

export default i18n