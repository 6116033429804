import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableRow from './EnhancedTableRow';
import SearchBox from './SearchBox';
import { getComparator, stableSort } from '../modules/sort';

function SelectUserDialog({ users, index, step, defaultSelectedItems, handleClose, handleSave }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('displayName');
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => { acc[cur] = true; return acc; }, {}));
  const [currentFilter, setCurrentFilter] = useState(null);
  const filteredUsers = currentFilter ? (users.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))) : users
  const filteredselection = filteredUsers.filter(s => selectedItems[s]).reduce((acc, cur) => { acc[cur] = true; return acc; }, {})

  const headerCells = [
    { text: 'displayName', sort: 'displayName' },
    { text: 'email' },
  ].map(c => { c.text = formatMessage({ id: `header.dialog.user.${c.text}` }); return c })

  const rowCells = [
    { field: 'displayName' },
    { field: 'email' },
  ]

  const filterItems = [
    { name: 'displayName' },
    { name: 'email' },
  ].map(i => { i.text = formatMessage({ id: `header.dialog.user.${i.name}` }); return i })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  };

  const handleCheckboxClick = (id) => {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const onApply = () => {
    const selectedUsers = users.filter(u => selectedItems[u.id]).map(u => ({ name: u.displayName, id: u.id }))
    handleSave(index, selectedUsers, step)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="header.dialog.user.selectUserDialog" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Toolbar sx={{ pl: 2, pr: 1 }}>
            <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
              <FormattedMessage id="header.dialog.user.title" />
            </Typography>
            <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Toolbar>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={Object.keys(filteredselection).length}
                order={order}
                orderBy={orderBy}
                onCheckboxClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredUsers.length}
              />
              <TableBody>
                {stableSort(filteredUsers, getComparator(order, orderBy)).map(user => (
                  <EnhancedTableRow
                    key={user.id}
                    rowCells={rowCells}
                    cellData={user}
                    onCheckboxClick={(e) => handleCheckboxClick(user.id)}
                    selected={selectedItems[user.id] || false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectUserDialog.propTypes = {
  index: PropTypes.string.isRequired,
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.string.isRequired),
  users: PropTypes.arrayOf(PropTypes.object.isRequired),
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  step: PropTypes.string
};

export default SelectUserDialog;
