import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import LeaveType from '../../enum/LeaveType'
import ProgressStep from '../../components/ProgressStep'

const _LeaveAllType = LeaveType.concat().filter(type => type.type === 'all');
const _LeaveType = LeaveType.concat();

const TableCellColor = {
  highlight: {
    color: 'rgb(0, 119, 255)'
  },
  error: {
    color: 'rgb(255, 0, 0)'
  },
}

function LeavesListView({ leaves, currentUser }) {
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.firebase.data.users);
  const [leaveBalance, setLeaveBalance] = useState([])
  const [pendingLeaves, setPendingLeaves] = useState([])
  const leaveHistory = (leaves.history || []).map(h => ({ ...h }))
  const currentStep = leaveHistory.length > 0 ? leaveHistory[leaveHistory.length - 1].step : 0

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('leaveBalances').doc(currentUser.key).onSnapshot(snapshot => {
      setLeaveBalance(snapshot.data())
    }, err => { })

    return () => unsubscribe()
  }, [currentUser.key]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('leaves')
      .where('status', '==', 'pending')
      .where('createdBy', '==', currentUser.key)
      .onSnapshot(snapshot => {
        let snapshots = []
        snapshot.forEach(doc => {
          snapshots.push({ id: doc.id, ...doc.data() })
        })
        setPendingLeaves(snapshots)
      }, err => { })

    return () => unsubscribe()
  }, [currentUser.key]);

  function formatData(value) {
    const { leave } = value
    const newData = { ...value }

    if (newData) {
      let used = newData.used
      let balance = newData.balance
      if (newData.type === leave.type) {
        if (newData.status === 'done') {
          used = newData.used + ((leave.days * 8) + leave.hours)
          balance = newData.balance - ((leave.days * 8) + leave.hours)
        }

        if ((leave.days || leave.hours) > 0) {
          newData.status = balance >= 0 ? 'highlight' : 'error'
        }
      }

      if (newData.type === leave.type &&
        ['business_leave',
          'bereavement_leave',
          'publicinjury_leave',
          'epidemicPreventionCare_leave',
          'vaccination_leave',
          'quarantine_leave'
        ].includes(newData.type)) {
        balance = 0;
        if ((leave.days || leave.hours) > 0) {
          newData.status = 'highlight'
        }
      }

      let newEarned = newData.earned ? { days: (newData.earned - (newData.earned % 8)) / 8, hours: newData.earned % 8 } : { days: 0, hours: 0 };
      let newUsed = used ? { days: (used - (used % 8)) / 8, hours: used % 8 } : { days: 0, hours: 0 };
      let newBalance = newData.earned - used;
      let newSigning = {
        days: 0,
        hours: 0,
      }
      let newVoiding = {
        days: 0,
        hours: 0
      }

      for (const s of pendingLeaves) {
        if (s.type === newData.type) {
          if (s.void) {
            newVoiding.days += s.days
            newVoiding.hours += s.hours
          } else {
            newSigning.days += s.days
            newSigning.hours += s.hours
          }
        }
      }

      const balanceCount = (balance - ((newSigning.days * 8) + newSigning.hours))
      if (['business_leave',
        'bereavement_leave',
        'publicinjury_leave',
        'epidemicPreventionCare_leave',
        'vaccination_leave',
        'quarantine_leave'
      ].includes(newData.type)) {
        newBalance = { days: 0, hours: 0 };
      } else {
        newBalance = { days: (balanceCount - (balanceCount % 8)) / 8, hours: balanceCount % 8 };
      }
      // newBalance = { days: (balance - (balance % 8)) / 8, hours: balance % 8 };
      newData.earned = `${newEarned.days}日${newEarned.hours}小時`
      newData.used = `${newUsed.days}日${newUsed.hours}小時`
      newData.balance = `${newBalance.days}日${newBalance.hours}小時`
      newData.signing = `${newSigning.days}日${newSigning.hours}小時`
      newData.voiding = `${newVoiding.days}日${newVoiding.hours}小時`
    }

    return newData
  }

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))

    log = log.replace(/f{startTime}/g, formatMessage({ id: 'leaves.table.start' }))
    log = log.replace(/f{startDate}/g, formatMessage({ id: 'leaves.leaveForm.startDateTime' }))
    log = log.replace(/f{endTime}/g, formatMessage({ id: 'leaves.table.end' }))
    log = log.replace(/f{endDate}/g, formatMessage({ id: 'leaves.leaveForm.endDateTime' }))
    log = log.replace(/f{type}/g, formatMessage({ id: 'leaves.leaveForm.type' }))
    log = log.replace(/f{reason}/g, formatMessage({ id: 'leaves.leaveForm.reason' }))
    log = log.replace(/f{img}/g, formatMessage({ id: 'leaves.leaveForm.leavecertificate' }))
    log = log.replace(/f{days}/g, formatMessage({ id: 'leaves.leaveForm.leaveDays' }))
    log = log.replace(/f{hours}/g, formatMessage({ id: 'leaves.leaveForm.leaveHours' }))
    log = log.replace(/f{substitute}/g, formatMessage({ id: 'leaves.leaveForm.substitute' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), userMapping[m].displayName)
    }

    let match = [...new Set((log.match(/x{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of match) {
      log = log.replace((new RegExp(`x{${m}}`, 'g')), formatMessage({ id: `leaveType.${m}` }))
    }

    return log
  }

  const ls = leaves.status !== 'void' ? leaves.steps.slice(currentStep, leaves.steps.length).map(s => ({ ...s })) : []
  if (leaves.status !== 'void' && currentStep === 0) {
    ls[0].name = '編輯 / 作廢'
  }
  const steps = [...leaveHistory].concat(ls)

  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <ProgressStep
            activeStep={leaves.history.length}
            steps={steps}
            orie={'horizontal'}
            alternativeLabel={true}
            title={formatMessage({ id: 'leaves.list.step' })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ fontSize: '15px', color: '#828a99', padding: '10px 0' }}><strong>{formatMessage({ id: 'leaves.list.balance' })}</strong></div>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.type' })}</TableCell>
                  <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.earned' })}</TableCell>
                  <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.used' })}</TableCell>
                  <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.signing' })}</TableCell>
                  <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.voiding' })}</TableCell>
                  <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.balance' })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentUser.gender === 'male' ? _LeaveAllType.map(type => ({ name: type.name, ...formatData({ leave: leaves, type: type.value, ...leaveBalance[type.value] }) })).map(i => (
                  <TableRow key={i.name}>
                    <TableCell sx={TableCellColor[i.status]} component="th" scope="row">
                      {i.name}
                    </TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.earned}</TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.used}</TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.signing}</TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.voiding}</TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.balance}</TableCell>
                  </TableRow>
                )) : _LeaveType.map(type => ({ name: type.name, ...formatData({ leave: leaves, type: type.value, ...leaveBalance[type.value] }) })).map(i => (
                  <TableRow key={i.name}>
                    <TableCell sx={TableCellColor[i.status]} component="th" scope="row">
                      {i.name}
                    </TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.earned}</TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.used}</TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.signing}</TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.voiding}</TableCell>
                    <TableCell sx={TableCellColor[i.status]} align="left">{i.balance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
}

LeavesListView.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  leaves: PropTypes.object.isRequired,
};

export default LeavesListView;
