import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TransferIcon from '@mui/icons-material/SupervisorAccount';
import RefundIcon from '@mui/icons-material/MoneyOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import SelectPatientDialog from '../../components/SelectPatientDialog';
import { objectToArray } from '../../modules/data';
import { formatName } from '../../modules/uitls'
import ButtonProgress from '../../components/ButtonProgress'

const newSelects = {
  refundType: ['cash', 'visa', 'master', 'jcb', 'wireTransfer', 'ecPay', 'wechatPay', 'points', 'mktExpence', 'ae', 'alipay', 'unionpay', 'online', 'refundPoint']
}

const DividerText = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '0px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function ConsumeDialog({ productMapping, purchase, dialogType, handleClose, purchaseMapping, payments }) {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const [patientMapping, setPatientMapping] = useState({})
  const [showPatientSelect, setShowPatientSelect] = useState(false)
  const [selectedItems, setSelectedItems] = useState({})
  const [selectData, setSelectData] = useState(null)
  let defaultData = {}
  if (dialogType === 'transfer') {
    defaultData = {
      ...purchase,
      maxAmount: (purchase.quantity - purchase.taken),
      amount: (purchase.quantity - purchase.taken),
    }
  } else {
    defaultData = {
      ...purchase,
      maxAmount: (purchase.quantity - purchase.taken),
      amount: (purchase.quantity - purchase.taken),
      refundPrice: 0,
      refundType: '',
      note: ''
    }
  }

  const [currentData, setCurrentData] = useState(defaultData)
  const userMapping = useSelector(state => state.firebase.data.users);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      setPatientMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  function onDeleteItems() {
    const datas = selectData.filter(m => !selectedItems[m.id])
    if (datas.length !== selectData.length) {
      setSelectData(datas);
      setSelectedItems({});
    }
  }

  function onSelectData(data) {
    setSelectData({ ...data })
    setShowPatientSelect(false)
  }

  function showDialog() {
    setShowPatientSelect(true)
  }

  async function handleSave() {
    setLoading(true)
    let err = {}
    let newData = currentData

    for (let field of fields) {
      if (field.required && currentData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...currentData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setCurrentData(newData)
      return setLoading(false)
    }

    const purchaseId = purchase.id

    if (dialogType === 'transfer') {
      try {
        await (firebase.functions().httpsCallable('purchasedProductTransfer'))({ id: purchaseId, newPatient: selectData.id, data: { ...currentData } })
        setLoading(false)
        handleClose()
      } catch (ex) {
        console.log(ex)
        setLoading(false)
        handleClose()
      }
    } else {
      try {
        await (firebase.functions().httpsCallable('purchasedProductRefund'))({ id: purchaseId, data: { ...currentData } })
        setLoading(false)
        handleClose()
      } catch (ex) {
        console.log(ex)
        setLoading(false)
        handleClose()
      }
    }
  }


  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    if (field.name === 'amount' && (value > currentData.maxAmount || value <= 0)) {
      return formatMessage({ id: 'form.field.amount' })
    }

    if (field.name === 'refundPrice') {
      const newData = purchaseMapping[currentData.purchaseOrder]
      const newPayment = []
      for (const p of Object.keys(newData.payments)) {
        if (payments[p]) {
          newPayment.push(payments[p])
        }
      }

      const totalPaid = newPayment.reduce((acc, payment) => {
        if (payment.void) {
          return acc
        }
        return acc + (payment.collected || 0)
      }, 0)

      if (value > totalPaid || value === '') {
        return formatMessage({ id: 'form.field.price' })
      }
    }

    return ''
  }

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)

    let newData = {}
    newData = { ...currentData, [field.name]: newValue, [`${field.name}_err`]: err }

    setCurrentData(newData)
  }

  const _fields = [
    { name: 'name', current: true, type: 'product', sm: 3, md: 3, order: 5 },
    { name: 'nickname', current: true, type: 'product', sm: 3, md: 3, order: 6 },
    { name: 'price', current: true, type: 'product', sm: 3, md: 3, order: 7 },
    { name: 'amount', current: true, type: 'product', outlined: true, required: true, sm: 3, md: 3, order: 8 },
  ]
  if (dialogType === 'transfer') {
    _fields.push(
      { name: 'name', current: true, type: 'patient', sm: 3, md: 3, order: 0 },
      { name: 'birthDate', current: true, type: 'patient', sm: 3, md: 3, order: 1 },
      { name: 'phone', current: true, type: 'patient', sm: 3, md: 3, order: 2 },
      { name: 'salesRep', current: true, type: 'patient', sm: 3, md: 3, order: 3 },
      { name: 'line', current: true, type: '-', text: formatMessage({ id: `manage.consume.dialog.${dialogType}.treatment` }), sm: 12, md: 12, order: 4 },
    )
  } else {
    _fields.push(
      { name: 'line', current: true, type: '-', text: formatMessage({ id: `manage.consume.dialog.${dialogType}.purchaseOrder` }), sm: 12, md: 12, order: 9 },
      { name: 'purchaseOrderSN', current: true, type: 'purchaseOrder', sm: 3, md: 3, order: 10 },
      { name: 'payment', current: true, type: 'purchaseOrder', sm: 3, md: 3, order: 11 },
      { name: 'status', current: true, type: 'purchaseOrder', sm: 3, md: 3, order: 12 },
      { name: 'refundPrice', current: true, outlined: true, type: 'purchaseOrder', sm: 3, md: 3, order: 13 },
      { name: 'totalCost', current: true, type: 'purchaseOrder', sm: 3, md: 3, order: 14 },
      { name: 'totalPaid', current: true, type: 'purchaseOrder', sm: 3, md: 3, order: 15 },
      { name: 'agentName', current: true, type: 'purchaseOrder', sm: 3, md: 3, order: 16 },
      { name: 'refundType', current: true, outlined: true, select: true, required: true, type: 'purchaseOrder', sm: 3, md: 3, order: 17 },
      { name: 'note', current: true, outlined: true, multiline: true, type: 'purchaseOrder', sm: 12, md: 12, order: 18 },
    )
  }

  const fields = _fields.map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field }).sort((a, b) => { return a.order - b.order })

  const selectFields = [
    { name: 'name', type: 'patient', sm: 3, md: 3, order: 0 },
    { name: 'birthDate', type: 'patient', sm: 3, md: 3, order: 1 },
    { name: 'phone', type: 'patient', sm: 3, md: 3, order: 2 },
    { name: 'salesRep', type: 'patient', sm: 3, md: 3, order: 3 },
  ]

  function createFeild() {
    function createTextFeild(field) {
      let newValue = ''
      let labelText = ''
      let newMapping = ''
      if (field.current) {
        newMapping = currentData[field.type]
      } else {
        if (selectData) {
          newMapping = selectData.id
        }
      }

      if (field.type === '-') {
        if (field.text) {
          return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
            <Divider />
            <DividerText>
              <Typography color="textSecondary" variant="caption">{field.text}</Typography>
            </DividerText>
          </Grid>
        } else {
          return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
        }
      } else if (field.type === 'patient') {
        labelText = formatMessage({ id: `manage.consume.dialog.${dialogType}.${field.type}.${field.name}` })
        const newData = patientMapping[newMapping]
        newValue = newData ? newData[field.name] : ''

        if (field.name === 'name') {
          newValue = formatName(newValue)
        }
      } else if (field.type === 'product') {
        const newData = productMapping[newMapping]
        labelText = formatMessage({ id: `manage.consume.dialog.${dialogType}.${field.name}` })

        if (field.name !== 'amount') {
          newValue = newData ? newData[field.name] : ''
        } else {
          newValue = currentData[field.name]
        }
      } else {
        if (['status', 'payment', 'totalCost', 'totalPaid', 'agentName'].includes(field.name)) {
          if (field.name === 'payment') {
            labelText = formatMessage({ id: `manage.consume.dialog.${dialogType}.${field.name}.root` })
          } else {
            labelText = formatMessage({ id: `manage.consume.dialog.${dialogType}.${field.name}` })
          }

          const newData = purchaseMapping[currentData.purchaseOrder]
          const newPayment = []
          for (const p of Object.keys(newData.payments)) {
            if (payments[p]) {
              if (field.name === 'payment') {
                newPayment.push(formatMessage({ id: `manage.consume.dialog.${dialogType}.payment.${payments[p].paymentType}` }))
              } else {
                newPayment.push(payments[p])
              }
            }
          }

          const totalPaid = newPayment.reduce((acc, payment) => {
            if (payment.void) {
              return acc
            }
            return acc + (payment.collected || 0)
          }, 0)

          if (field.name === 'payment') {
            newValue = newPayment.join(', ')
          } else if (field.name === 'status') {
            if (newData.fullyCollectedConfirmed) {
              newValue = formatMessage({ id: 'purchaseOrder.table.confirmed' })
            } else {
              if (totalPaid === newData.discountPrice) {
                newValue = formatMessage({ id: 'purchaseOrder.table.collected' })
              } else if (totalPaid === 0) {
                newValue = formatMessage({ id: 'purchaseOrder.table.uncollected' })
              } else {
                newValue = formatMessage({ id: 'purchaseOrder.table.partial' })
              }
            }
          } else if (field.name === 'totalCost') {
            newValue = newData.discountPrice
          } else if (field.name === 'totalPaid') {
            newValue = totalPaid
          } else if (field.name === 'agentName') {
            newValue = newData.agent ? userMapping[newData.agent.id].displayName : ''
          }
        } else {
          labelText = formatMessage({ id: `manage.consume.dialog.${dialogType}.${field.name}` })
          newValue = currentData[field.name]
        }
      }

      if (field.name === 'salesRep') {
        newValue = userMapping[newValue] ? userMapping[newValue].displayName : ''
      }

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select={field.select}
          size="small"
          variant={field.outlined ? 'outlined' : 'standard'}
          onChange={(e) => updateData({ name: field.name }, e.target.value)}
          error={currentData[`${field.name}_err`] ? true : false}
          helperText={currentData[`${field.name}_err`]}
        >
          {
            field.select ? newSelects[field.name].map((selects, idx) => {
              return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `manage.consume.dialog.${dialogType}.payment.${selects}` })}</MenuItem>
            }) : null
          }
        </TextField>
      </Grid>
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} sx={{ padding: 0 }}>
          <EnhancedTableToolbar
            title={`manage.consume.dialog.${dialogType}.currentTitle`}
            numSelected={0}
          />
          <Grid item xs={12} sm={12} md={12} component={Paper}>
            <Grid container spacing={2} sx={{ padding: '20px 8px 5px 16px' }}>
              {fields.map(field => createTextFeild(field))}
            </Grid>
          </Grid>
        </Grid>
        {dialogType === 'transfer' && <>
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            <ExpandMoreIcon />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ padding: 0 }}>
            <EnhancedTableToolbar
              title={`manage.consume.dialog.${dialogType}.switchTitle`}
              selectdMessage={`manage.consume.dialog.${dialogType}.selected`}
              numSelected={Object.keys(selectedItems).length}
              toolbox={
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ margin: '8px', whiteSpace: 'nowrap' }}
                  onClick={() => showDialog()}
                >
                  <FormattedMessage id={`manage.consume.dialog.${dialogType}.add`} />
                </Button>
              }
              toolboxForSelection={
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DeleteIcon />}
                  sx={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                  onClick={onDeleteItems}
                >
                  <FormattedMessage id={`manage.consume.dialog.${dialogType}.remove`} />
                </Button>
              }
            />
            <Grid item xs={12} sm={12} md={12} component={Paper}>
              <Grid container spacing={2} sx={{ padding: '20px 8px 5px 16px' }}>
                {selectFields.map(field => createTextFeild(field))}
              </Grid>
            </Grid>
          </Grid>
        </>}
      </Grid >
    );
  }

  return (
    <div>
      {showPatientSelect && <SelectPatientDialog
        handleClose={() => setShowPatientSelect(false)}
        handleSave={onSelectData}
        patients={objectToArray(patientMapping)}
        dialogTitle={formatMessage({ id: 'selectPatientDialog.root' })}
      />}
      <Dialog
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth='lg'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{formatMessage({ id: `manage.consume.dialog.title.${dialogType}` })}</DialogTitle>
        <div style={{ padding: '10px 24px 20px' }}>
          {createFeild()}
        </div>
        <DialogActions>
          <ButtonProgress
            handleClick={() => handleSave()}
            handleClose={handleClose}
            loading={loading}
            buttonText={'button.submit'}
            disabledField={loading || ((dialogType !== 'refund' && !selectData) || currentData.refundPrice_err || currentData.amount_err) ? true : false}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConsumeDialog.propTypes = {
  productMapping: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  dialogType: PropTypes.string.isRequired,
  purchase: PropTypes.object.isRequired,
  purchaseMapping: PropTypes.object,
  payments: PropTypes.object
};

function ConsumePage({ purchases, purchaseMapping, payments }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [productMapping, setProductMapping] = useState({})

  const [dialogOpen, setDialogOpen] = useState(null)

  useEffect(() => {
    const ref = firebase.database().ref('products')
    const onDataChange = ref.on('value', snapshot => {
      setProductMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleClose() {
    setDialogOpen(null)
  }

  const headerCells = [
    { text: 'productId', sort: 'productId', order: 0 },
    { text: 'productName', sort: 'productName', order: 1 },
    { text: 'quantityAvailable', sort: 'quantityAvailable', order: 2 },
    { text: 'purchaseOrderSN', sort: 'purchaseOrderSN', order: 3 },
  ].map(c => { c.text = formatMessage({ id: `manage.consume.table.${c.text}` }); return c }).sort((a, b) => a.order - b.order)

  const rowCells = [
    { field: 'productId', order: 0 },
    { field: 'productName', order: 1 },
    { field: 'quantityAvailable', order: 2 },
    { field: 'purchaseOrderSN', order: 3 },
  ].sort((a, b) => a.order - b.order)

  const formatData = (data) => {
    const newData = { ...data }
    newData.productId = productMapping[newData.product] ? productMapping[newData.product].code : ''
    newData.productName = productMapping[newData.product] ? productMapping[newData.product].name : ''
    newData.quantityAvailable = `${newData.taken}/${newData.quantity}`

    return newData
  }

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', }}>
      {dialogOpen && <ConsumeDialog
        dialogType={dialogOpen.type}
        purchase={dialogOpen.data}
        productMapping={productMapping}
        handleClose={handleClose}
        purchaseMapping={purchaseMapping}
        payments={payments}
      />}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={purchases.length}
            actionButton
          />
          <TableBody>
            {stableSort(purchases.filter(p => p.quantity > p.taken).map(r => formatData(r)), getComparator(order, orderBy)).map(purchase => (
              <EnhancedTableRow
                key={purchase.id}
                rowCells={rowCells}
                cellData={purchase}
                actionIcons={<>
                  <Tooltip title={formatMessage({ id: 'button.transfer' })}>
                    <IconButton size='small' onClick={() => setDialogOpen({ type: 'transfer', data: purchase })}>
                      <TransferIcon />
                    </IconButton>
                  </Tooltip>
                  {purchaseMapping[purchase.purchaseOrder] && purchaseMapping[purchase.purchaseOrder].payments &&
                    <Tooltip title={formatMessage({ id: 'button.refund' })}>
                      <IconButton size='small' onClick={() => setDialogOpen({ type: 'refund', data: purchase })}>
                        <RefundIcon />
                      </IconButton>
                    </Tooltip>}
                </>}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

ConsumePage.propTypes = {
  purchases: PropTypes.arrayOf(PropTypes.object.isRequired),
  consumes: PropTypes.arrayOf(PropTypes.object.isRequired),
  purchaseMapping: PropTypes.object,
  payments: PropTypes.object
};

export default ConsumePage;
