import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekday from 'dayjs/plugin/weekday';
import firebase from 'firebase/app';

import { createUseStyles } from 'react-jss'
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import CircularProgress from '@mui/material/CircularProgress';

import ScheduleRows from './ScheduleRows'
import ScheduleDoctorRows from './ScheduleDoctorRows'
import { doctortPunchCount } from '../../modules/doctorModule'
import { datesData, leaveTimeRangeCount, getWeekDay } from '../../modules/uitls';
import { objectToArray } from '../../modules/data';
import { tabletMedia } from '../../constants/index'
import OccupationMapping from '../../enum/OccupationMapping';

dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);
dayjs.extend(weekday)

const useStyles = createUseStyles({
  scheduleTable: {
    width: '270px',
    height: '60px',
    position: 'sticky',
    zIndex: 2,
    top: '64px',
    backgroundColor: '#f6f6f7',
    borderBottom: '1px solid #d9dce3',
    [tabletMedia]: {
      width: '100%',
    }
  },
  scheduleTableLeft: {
    overflow: 'hidden',
    position: 'absolute',
    height: 'calc(100vh - 64px - 60px)',
    paddingBottom: '20px',
    [tabletMedia]: {
      width: '100%',
      maxHeight: '100px'
    }
  },
  scheduleTableLeftColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '270px',
    borderRight: '1px solid $table-dark-border',
    position: 'relative',
    fontSize: '14px',
    [tabletMedia]: {
      flexDirection: 'row',
      width: '100%',
      overflowY: 'scroll',
    }
  },
  date: {
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    padding: '0 20px',
    borderRight: '1px solid #828a99',
    alignItems: 'center',
    [tabletMedia]: {
      borderLeft: '1px solid #828a99',
    }
  },
  dateYear: {
    display: 'block',
    width: '100%',
    color: '#495057',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    padding: '.25rem .5rem',
    fontSize: '.875rem',
    lineHeight: 1.5,
    borderRadius: '.2rem'
  },
  dateMonth: {
    display: 'block',
    width: '100%',
    color: '#495057',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    padding: '.25rem .5rem',
    fontSize: '.875rem',
    lineHeight: 1.5,
    borderRadius: '.2rem'
  },
  fieldManagerHeader: {
    flex: 1,
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    // borderTop: '1px solid #d9dce3',
    borderRight: '1px solid #828a99',
    borderBottom: '1px solid #828a99',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.9px',
    textAlign: 'left',
    color: '#8c8c8d'
  },
  employeeTable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100px',
    borderBottom: '1px solid #efeff4',
    borderRight: '1px solid #828a99',
    [tabletMedia]: {
      flexDirection: 'column',
      border: '1px solid #828a99',
      minWidth: '100px',
      minHeight: '100px'
    }
  },
  employeeName: {
    padding: '0 30px',
    fontWeight: 500,
    [tabletMedia]: {
      padding: 0,
      fontSize: '10px'
    }
  },
  employeeLeaveCount: {
    width: '110px',
    fontSize: '14px',
    fontweight: 500,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    color: '#8c8c8d',
    marginRight: '5px',
    marginLeft: 'auto',
    [tabletMedia]: {
      fontSize: '12px',
      textAlign: 'center'
    }
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const RepeatMap = {
  even_weeks: '雙週',
  every_week: '每週',
  no: '特約',
  'no-plastic': '特約(無診費)',
  odd_weeks: '單週',
  next_weeks: '隔週',
  leaves: '休診',
  zhongli: '中離',
}

const LeaveTypeMap = {
  weekly_leave: '本休',
  sick_leave: '病假',
  annual_leave: '特休',
  menstrual_leave: '生理假',
  personal_leave: '事假',
  bereavement_leave: '喪假',
  marriage_leave: '婚假',
  maternity_leave: '產假',
  reproductive_leave: '產檢假',
  paternity_leave: '陪產假',
  fetal_leave: '安胎假',
  family_leave: '家庭照顧假',
  publicinjury_leave: '公傷假',
  overtime_leave: '補休假',
  business_leave: '公假',
  epidemicPreventionCare_leave: '防疫照顧假',
  vaccination_leave: '疫苗接種假',
  quarantine_leave: '防疫隔離假'
}

function ScheduleTable({ currentUser, userRight, staffType, workTimes, isTablet }) {
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const [vacationMapping, setVacationMapping] = useState(null)
  const [weeklyLeaves, setWeeklyLeaves] = useState([])
  const [punchClock, setPunchClock] = useState(null)
  const [leaves, setLeaves] = useState([])
  const [comments, setComments] = useState([])
  const [punchClockExceptions, setPunchClockExceptions] = useState([])
  const [punchClockRevise, setPunchClockRevise] = useState(null)
  const [staffData, setStaffData] = useState({});
  const [staffPartTime, setStaffPartTime] = useState({});
  const [leaveBalance, setLeaveBalance] = useState({});
  const [viewingStaff, setViewingStaff] = useState(null)
  const [doctorShift, setDoctorShift] = useState({})
  const [doctorSpecial, setDoctorSpecial] = useState({})
  const [doctorLeave, setDoctorLeave] = useState({})
  const [weeklyHistory, setWeeklyHistory] = useState({})
  const [csvLoading, setCsvLoading] = useState(false)
  const StaffTableRef = useRef()
  const [tableData, setTableData] = useState({
    month: dayjs().format('M'),
    year: dayjs().format('YYYY')
  })
  const dates = datesData(tableData.year, tableData.month)
  const startDateM = Number(`${tableData.year}${tableData.month}`) <= Number(dayjs().format('YYYYMM')) ? dayjs(dates[0]).format('YYYY-MM-DD') : `${dayjs().format('YYYY-MM')}-01`
  const endDateM = dayjs(dates[dates.length - 1]).format('YYYY-MM-DD')
  const sortData = staffData ? objectToArray(staffData).filter(s => s.active).sort((a, b) => {
    let newMapping = {}
    const filterMapping = OccupationMapping.filter(o => o.type === process.env.BRANCH_ENV ||
      (process.env.BRANCH_ENV === 'lexcellence' && o.type === 'reborn') ||
      (process.env.BRANCH_ENV === 'ibeautyTaichung' && o.type === 'ibeauty')
    )
    for (const o of filterMapping) {
      newMapping[o.value] = { ...o }
    }

    const acc = newMapping[a.occupation]?.order ?? Object.keys(newMapping).length
    const cur = newMapping[b.occupation]?.order ?? Object.keys(newMapping).length
    return acc - cur
  }) : []

  const staffKey = []
  for (const o of sortData) {
    staffKey.push(o.id)
  }

  const yearMonthStr = `${tableData.year}${String(tableData.month).padStart(2, '0')}`

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebase.firestore().collection('comments')
      .where('date', '>=', startDateM).where('date', '<=', endDateM)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setComments(data)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);


  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('punchClockExceptions').where('date', '>=', startDateM).where('date', '<=', endDateM).onSnapshot(snapshot => {
      const punchClockException = []
      snapshot.forEach(doc => {
        punchClockException.push({ ...doc.data(), id: doc.id })
      });
      setPunchClockExceptions(punchClockException)
    }, err => { })

    return () => unsubscribe()
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebase.firestore().collection('leaveList')
      .where('status', '==', 'done')
      .where('monthIndex', 'array-contains', yearMonthStr)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setLeaves(data)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [yearMonthStr]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebase.firestore().collection('weeklyLeavesHistory').onSnapshot(snapshot => {
      const data = {}
      snapshot.forEach(doc => {
        data[doc.id] = { ...doc.data() }
      });
      setWeeklyHistory(data)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType === 'doctor' ? firebase.firestore().collection('doctorShifts').where('repeats', '!=', 'no')
      .onSnapshot(snapshot => {
        const shifts = {}
        snapshot.forEach(doc => {
          shifts[doc.id] = { ...doc.data() };
        })

        setDoctorShift(shifts)

      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType === 'doctor' ? firebase.firestore().collection('doctorShifts')
      .where('firstDate', '>=', startDateM).where('firstDate', '<=', endDateM).where('repeats', 'in', ['no', 'no-plastic'])
      .onSnapshot(snapshot => {
        const shifts = {}
        snapshot.forEach(doc => {
          shifts[doc.id] = { ...doc.data() };
        })

        setDoctorSpecial(shifts)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType === 'doctor' ? firebase.firestore().collection('doctorLeaves')
      .where('firstDate', '>=', startDateM).where('firstDate', '<=', endDateM)
      .onSnapshot(snapshot => {
        const snapshots = {}
        snapshot.forEach(doc => {
          snapshots[doc.id] = { ...doc.data() };
        })
        setDoctorLeave(snapshots)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('punchClock')
      .where(firebase.firestore.FieldPath.documentId(), '>=', startDateM).where(firebase.firestore.FieldPath.documentId(), '<=', endDateM)
      .onSnapshot(snapshot => {
        const data = {}
        const reviseData = {}
        snapshot.forEach(doc => {
          data[doc.id] = { ...doc.data().record }
          reviseData[doc.id] = { ...doc.data().revise }
        });

        setPunchClock(data)
        setPunchClockRevise(reviseData)
      }, err => { })
    return () => unsubscribe()
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebase.firestore().collection('weeklyLeaves')
      .where('date', '>=', startDateM).where('date', '<=', endDateM)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setWeeklyLeaves(data)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebase.firestore().collection('vacationMapping').onSnapshot(snapshot => {
      const data = []
      snapshot.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      });
      setVacationMapping(data)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebase.firestore().collection('leaveBalances').onSnapshot(snapshot => {
      const data = []
      snapshot.forEach(doc => {
        data[doc.id] = doc.data()
      });
      setLeaveBalance(data)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const ref = firebase.database().ref('users').orderByChild('department').equalTo(staffType)
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      let newData = {}
      for (const s in snapshots) {
        newData[s] = { id: s, ...snapshots[s] }
      }
      setStaffData(newData)
    });
    return () => ref.off('value', onDataChange)
  }, [staffType]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('userSalarys')
      .where('partTime', '==', true).onSnapshot(snapshot => {
        let newData = {}
        snapshot.forEach(doc => {
          newData[doc.id] = true
        })

        setStaffPartTime(newData)
      }, err => { })

    return () => unsubscribe()
  }, []);

  if (!punchClock || !punchClockRevise) {
    return ('Loading...')
  }

  const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const years = []
  for (const year of [-2, -1, 0, 1, 2]) {
    if (year === 0) {
      years.push(dayjs().format('YYYY'))
    } else if (year < 0) {
      years.push(dayjs().subtract(Math.abs(year), 'years').format('YYYY'))
    } else {
      years.push(dayjs().add(year, 'years').format('YYYY'))
    }
  }

  function updateScheduleData(name, value) {
    const newData = { ...tableData }
    newData[name] = value
    setTableData(newData)
  }

  function onScrollRow(scrollTop) {
    requestAnimationFrame(() => {
      StaffTableRef.current.scrollTop = scrollTop
    });
  }

  const punchClockReviseCount = (key) => {
    let count = 0;
    for (const d of dates) {
      const date = dayjs(d).format('YYYY-MM-DD')
      const newPunchClockRevise = punchClockRevise[date]
      if (newPunchClockRevise) {
        for (const p of Object.keys(newPunchClockRevise)) {

          if (p === key && newPunchClockRevise[p].length > 0) {
            count += newPunchClockRevise[p].length
          }
        }
      }
    }

    return count
  }

  function formatDate(date) {
    if (date < '12:00') {
      return true
    } else {
      return false
    }
  }

  function exportProfile() {
    setCsvLoading(true)
    function getShiftByDate(date, doctor) {
      const startDate = dayjs(date, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD');
      const endDate = dayjs(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');

      const scope = objectToArray(doctorShift).filter(shift => {
        if (shift.lastDate) {
          return shift.lastDate >= startDate;
        } else {
          return shift.firstDate <= endDate;
        }
      })

      const scopeSpecial = objectToArray(doctorSpecial).filter(shift => {
        if (shift.lastDate) {
          return shift.lastDate >= startDate;
        } else {
          return shift.firstDate <= endDate;
        }
      })

      const shifts = scope.concat(scopeSpecial).reduce((acc, shift) => {
        const { repeats } = shift
        let dateM = dayjs(shift.firstDate);

        if (shift.doctor === doctor) {
          if (!['no-plastic', 'no'].includes(repeats)) {
            acc[shift.firstDate] = { ...acc[shift.firstDate], [shift.id]: shift }
            while (dateM.isSameOrBefore(dayjs(shift.lastDate || endDate, 'YYYY-MM-DD'))) {
              dateM = dateM.add(1, 'week')
              const weeks = dateM.week() - dayjs(dateM).startOf('month').week() + 1
              if (repeats === 'every_week' ||
                (repeats === 'even_weeks' && weeks % 2 === 0) ||
                (repeats === 'odd_weeks' && weeks % 2 === 1)) {
                acc[dateM.format('YYYY-MM-DD')] = { ...acc[dateM.format('YYYY-MM-DD')], [shift.id]: shift }
              }
            }
          } else {
            if (acc[shift.firstDate] && (!['no-plastic', 'no'].includes(acc[shift.firstDate].repeats))) {
              acc[`_${shift.firstDate}`] = { ...acc[`_${shift.firstDate}`], [shift.id]: shift }
            } else {
              acc[shift.firstDate] = { ...acc[shift.firstDate], [shift.id]: shift }
            }
          }
        }
        return acc;
      }, {});

      return shifts
    }

    let dep = '';

    const newData = []
    Object.keys(staffData).forEach(s => {
      let newPunchClock = {}
      let newPunchClockRevise = {}


      for (const pc in punchClock) {
        if (punchClock[pc][s]) {
          newPunchClock[pc] = punchClock[pc][s]
        }
      }

      for (const pc in punchClockRevise) {
        if (punchClockRevise[pc][s]) {
          newPunchClockRevise[pc] = punchClockRevise[pc][s]
        }
      }

      if (staffData[s].active) {
        if (staffData.department === 'doctor') {
          newData.push({
            id: s,
            ...staffData[s],
            punchClock: newPunchClock,
            punchClockRevise: newPunchClockRevise
          })
        } else {
          newData.push({
            id: s,
            ...staffData[s],
            punchClock: newPunchClock,
            leaves: leaves.filter(l => l.createdBy === s).concat(weeklyLeaves.filter(w => w.staff === s)),
            punchClockRevise: newPunchClockRevise
          })
        }
      }
    })

    let shiftCount = 0
    let leaveCount = 0;
    newData.forEach(data => {
      dates.forEach(d => {
        const date = dayjs(d).format('YYYY-MM-DD')
        const filterLeaves = data.leaves.filter(leave => leave.startDate === date || leave.date === date)
        if (filterLeaves.length > leaveCount) {
          leaveCount = filterLeaves.length
        }
        let dateShiftMappingCache = getShiftByDate(date, data.id);
        if (dateShiftMappingCache[date] && (Object.keys(dateShiftMappingCache[date]).length > shiftCount)) {
          shiftCount = Object.keys(dateShiftMappingCache[date]).length
        }
      })
    })

    const rows = []
    const currentRow = ['日期', '姓名', '排班上班', '排班下班', '打卡上班', '打卡下班', '出勤時數', '補登上班', '補登下班'];
    const _rows = [];
    const _currentRow = ['日期', '姓名', '請假類型', '開始時間', '結束時間', '補登上班', '補登下班', '打卡上班', '打卡下班', '出勤時數'];

    for (let i = 0; i < leaveCount; i++) {
      currentRow.push('請假類型')
      currentRow.push('開始時間')
      currentRow.push('結束時間')
    }

    for (let i = 0; i < shiftCount; i++) {
      _currentRow.push('排班類型')
      _currentRow.push('排班上班')
      _currentRow.push('排班下班')
    }

    rows.push(currentRow.join(','))
    _rows.push(_currentRow.join(','))

    newData.forEach(data => {
      let totalPunchTime = 0
      dep = data.department
      dates.forEach(d => {
        const weekday = getWeekDay(dayjs(d))
        const date = dayjs(d).format('YYYY-MM-DD')
        let leaveMapping = {};
        let counts = [];
        let dateShiftMappingCache = getShiftByDate(date, data.id);
        let dateLeaveMappingCache = {}
        for (const l of Object.keys(doctorLeave)) {
          if (doctorLeave[l].doctor === data.id) {
            dateLeaveMappingCache[data.id] = { [doctorLeave[l].firstDate]: { ...doctorLeave[l] } }
          }
        }

        for (const leave of data.leaves) {
          if ((leave.startDate === date || leave.endDate === date)) {
            counts.push({ ...leave })
          } else if (!leave.type && leave.date === date) {
            counts.push({ ...leave })
          }
        }

        leaveMapping[date] = counts
        const total = data.punchClock[date] ? dayjs(`${date} ${data.punchClock[date][data.punchClock[date].length - 1]}`, 'YYYY-MM-DD HH:mm')
          .diff(dayjs(`${date} ${data.punchClock[date][0]}`, 'YYYY-MM-DD HH:mm'), 'minutes') : ''
        totalPunchTime += total !== '' && Number(total)

        let punchClockRevise = null
        if (data.punchClockRevise[date]) {
          punchClockRevise = data.punchClockRevise[date].sort((a, b) => {
            if (a > b) {
              return 1
            } else if (a < b) {
              return -1
            } else {
              return 0
            }
          })
        }

        if (dep !== 'doctor') {
          const tempRow = []
          tempRow.push(dayjs(date).format('M/D'))
          tempRow.push(data.displayName)
          tempRow.push(workTimes[weekday] ? workTimes[weekday].startTime : ' ')
          tempRow.push(workTimes[weekday] ? workTimes[weekday].endTime : ' ')
          tempRow.push(data.punchClock[date] ? data.punchClock[date][0] : ' ')
          tempRow.push(data.punchClock[date] ? data.punchClock[date][data.punchClock[date].length - 1] : ' ')
          tempRow.push(`${(total - (total % 60)) / 60}時 ${total % 60}分`)
          tempRow.push(punchClockRevise && formatDate(punchClockRevise[0]) ? punchClockRevise[0] : ' ')
          tempRow.push(punchClockRevise && !formatDate(punchClockRevise[punchClockRevise.length - 1]) ? punchClockRevise[punchClockRevise.length - 1] : ' ')

          const sortLeaves = leaveMapping[date].sort((a, b) => {
            if (a.startTime < b.startTime) {
              return -1
            } else if (a.startTime > b.startTime) {
              return 1
            } else {
              return 0
            }
          })

          for (const leave of sortLeaves) {
            tempRow.push(LeaveTypeMap[leave.type || 'weekly_leave'])
            tempRow.push(leave.startTime)
            tempRow.push(leave.endTime)
          }

          rows.push(tempRow.join(','))
        } else if (dep === 'doctor') {
          const tempRow = []
          tempRow.push(dayjs(date).format('M/D'))
          tempRow.push(data.displayName)
          tempRow.push(dateLeaveMappingCache[data.id] && dateLeaveMappingCache[data.id][date] ? RepeatMap[dateLeaveMappingCache[data.id][date].repeats] : ' ')
          tempRow.push(dateLeaveMappingCache[data.id] && dateLeaveMappingCache[data.id][date] ? dateLeaveMappingCache[data.id][date].startTime : ' ')
          tempRow.push(dateLeaveMappingCache[data.id] && dateLeaveMappingCache[data.id][date] ? dateLeaveMappingCache[data.id][date].endTime : ' ')
          tempRow.push(punchClockRevise && formatDate(punchClockRevise[0]) ? punchClockRevise[0] : ' ')
          tempRow.push(punchClockRevise && !formatDate(punchClockRevise[punchClockRevise.length - 1]) ? punchClockRevise[punchClockRevise.length - 1] : ' ')
          tempRow.push(data.punchClock[date] ? data.punchClock[date][0] : ' ')
          tempRow.push(data.punchClock[date] && data.punchClock[date].length > 1 ? data.punchClock[date][data.punchClock[date].length - 1] : ' ')
          tempRow.push(`${(total - (total % 60)) / 60}時 ${total % 60}分`)

          if (dateShiftMappingCache[date]) {
            const sortShift = objectToArray(dateShiftMappingCache[date]).sort((a, b) => {
              if (a.startTime < b.startTime) {
                return -1
              } else if (a.startTime > b.startTime) {
                return 1
              } else {
                return 0
              }
            })

            for (const shift of sortShift) {
              tempRow.push(shift ? RepeatMap[shift.repeats] : ' ')
              tempRow.push(shift ? shift.startTime : ' ')
              tempRow.push(shift ? shift.endTime : ' ')
            }
          }
          _rows.push(tempRow.join(','))
        }
      })

      if (dep !== 'doctor') {
        rows.push([[`總計,,,,,,${(totalPunchTime - (totalPunchTime % 60)) / 60}時 ${totalPunchTime % 60}分,,,,,,,,`]])
      } else {
        _rows.push([[`總計,,,,,,,,,${(totalPunchTime - (totalPunchTime % 60)) / 60}時 ${totalPunchTime % 60}分`]])
      }
    })

    if (rows.length > 1) {
      try {
        const content = rows.join('\n')
        const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
        const csvUrl = URL.createObjectURL(csvData)
        const aExport = document.createElement('a')
        aExport.href = csvUrl
        aExport.target = '_blank'
        aExport.download = 'Schedule_' + dep + '_' + dayjs().format('YYYY-MM-DD-HHmmss') + '.csv'
        aExport.click()
        setCsvLoading(false)
      } catch (error) {
        console.error(error) // eslint-disable-line
        setCsvLoading(false)
        alert('An error occurred. Please refresh and try again.')
      }
    }
    if (_rows.length > 1) {
      try {
        const content = _rows.join('\n')
        const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
        const csvUrl = URL.createObjectURL(csvData)
        const aExport = document.createElement('a')
        aExport.href = csvUrl
        aExport.target = '_blank'
        aExport.download = 'Schedule_' + dep + '_' + dayjs().format('YYYY-MM-DD-HHmmss') + '.csv'
        aExport.click()
        setCsvLoading(false)
      } catch (error) {
        console.error(error) // eslint-disable-line
        setCsvLoading(false)
        alert('An error occurred. Please refresh and try again.')
      }
    }
  }

  function doctorPunchClock(doctorId) {
    let doctorMapping = {}
    let doctorPunchClock = {}
    let doctorShiftLeave = {}
    for (const d of dates) {
      const date = dayjs(d).format('YYYY-MM-DD')
      const newPunchClock = punchClock[date]
      const newPunchClockRevise = punchClockRevise[date]
      if (newPunchClock) {
        for (const p of Object.keys(newPunchClock)) {
          if (p === doctorId) {
            doctorPunchClock[date] = { punchClock: newPunchClock[p], ...doctorPunchClock[date] }
          }
        }
      }
      if (newPunchClockRevise) {
        for (const p of Object.keys(newPunchClockRevise)) {
          if (p === doctorId) {
            doctorPunchClock[date] = { punchClockRevise: newPunchClockRevise[p], ...doctorPunchClock[date] }
          }
        }
      }

      doctorShiftLeave[date] = doctortPunchCount(date, doctorId, doctorShift, doctorSpecial, doctorLeave)
      doctorMapping[date] = {
        ...doctorPunchClock[date],
        ...doctorShiftLeave[date],
      }
    }


    let count = 0;
    let specialCount = 0
    let leaveCount = 0

    for (const key of Object.keys(doctorMapping)) {
      let shift = doctorMapping[key].shifts
      let special = doctorMapping[key].specials
      let leave = doctorMapping[key].leaves
      let fullPunchClock = doctorMapping[key].punchClock && sortPunchClockData(key, doctorMapping[key].punchClock.concat(doctorMapping[key].punchClockRevise || []))

      if (shift && fullPunchClock) {
        let punchInTime = fullPunchClock[0];
        let punchOutTime = fullPunchClock[fullPunchClock.length - 1];
        let punchInTimeM = dayjs(key + ' ' + punchInTime, 'YYYY-MM-DD HH:mm');
        let punchOutTimeM = dayjs(key + ' ' + punchOutTime, 'YYYY-MM-DD HH:mm');
        let diff = punchOutTimeM.diff(punchInTimeM, 'minutes')
        count += diff;
      }

      if (special.length > 0 && fullPunchClock) {
        for (const s of special) {
          let punchInTime = s.startTime;
          let punchOutTime = s.endTime;
          let punchInTimeM = dayjs(key + ' ' + punchInTime, 'YYYY-MM-DD HH:mm');
          let punchOutTimeM = dayjs(key + ' ' + punchOutTime, 'YYYY-MM-DD HH:mm');
          let diff = punchOutTimeM.diff(punchInTimeM, 'minutes')
          specialCount += diff;
        }
      }

      if (leave && (shift || special) && fullPunchClock) {
        let leaveInTime = leave.startTime;
        let leaveOutTime = leave.endTime;
        let leaveInTimeM = dayjs(key + ' ' + leaveInTime, 'YYYY-MM-DD HH:mm');
        let leaveOutTimeM = dayjs(key + ' ' + leaveOutTime, 'YYYY-MM-DD HH:mm');
        let diff = leaveOutTimeM.diff(leaveInTimeM, 'minutes')

        leaveCount += diff
      }
    }

    const totalShiftCount = count + specialCount

    const resultH = ((totalShiftCount - leaveCount) - ((totalShiftCount - leaveCount) % 60)) / 60
    const resultM = (totalShiftCount - leaveCount) % 60

    const resultHStr = resultH > 0 ? `${resultH}` : '0'
    const resultMStr = resultM > 0 ? `${resultM}` : '0'
    return `${resultHStr}h ${resultMStr}m`
  }

  const sortPunchClockData = (date, punchClockData) => {
    let dateM = dayjs(date, 'YYYY-MM-DD');
    return Array.from(new Set(punchClockData.sort((a, b) => {
      const timeAM = dayjs(dateM.format('YYYY-MM-DD') + ' ' + a, 'YYYY-MM-DD HH:mm');
      const timeBM = dayjs(dateM.format('YYYY-MM-DD') + ' ' + b, 'YYYY-MM-DD HH:mm');
      if (timeAM.isBefore(timeBM)) {
        return -1;
      } else if (timeAM.isBefore(timeBM)) {
        return 1;
      } else {
        return 0;
      }
    })));
  }

  function weeklyEarnedCount(key, total) {
    const currentDateM = dayjs().format('YYYY-MM')
    const weeklyData = weeklyLeaves ? weeklyLeaves.filter(m => m.staff === staffData[key].id) : []
    const monthIndex = [];
    const endM = dayjs(`${tableData.year}-${tableData.month}`).format('YYYY-MM')
    let currentMonth = dayjs();
    let earned = 0;
    let monthWeekly = 0;
    let weeklyCount = 0
    let weekBalance = leaveBalance[key] ? leaveBalance[key]['weekly_leave'].balance : 0
    for (; ;) {
      monthIndex.push(currentMonth.format('YYYY-MM'))
      currentMonth = currentMonth.add(1, 'month')
      if (currentMonth.format('YYYY-MM') > endM) {
        break;
      }
    }

    for (const month of monthIndex) {
      let newM = vacationMapping ? vacationMapping.filter(m => m.id === month) : []
      if (newM.length > 0) {
        monthWeekly += (newM[0].earned * 8)
      }
    }

    for (const data of weeklyData) {
      const newData = {
        startDate: data.date,
        endDate: data.date,
        startHour: data.startTime.split(':')[0],
        startMinute: data.startTime.split(':')[1],
        endHour: data.endTime.split(':')[0],
        endMinute: data.endTime.split(':')[1],
        leaveType: 'weekly_leave'
      }

      const pce = punchClockExceptions.reduce((acc, cur) => {
        if (cur.date === data.date) {
          acc[cur.date] = { ...cur }
        }

        return acc
      }, {})

      let weeklyData = leaveTimeRangeCount(newData, staffType, workTimes, pce)

      if (weeklyData) {
        weeklyCount += (weeklyData.day * 8) + weeklyData.hour
      } else {
        const minutes = dayjs(`${data.date} ${data.endTime}`).diff(dayjs(`${data.date} ${data.startTime}`), 'minutes')
        let hours = (minutes - (minutes % 60)) / 60
        const minute = minutes % 60

        if (minute !== 0) {
          if (minute <= 30) {
            hours += 0.5
          } else {
            hours += 1
          }
        }

        weeklyCount += Math.min(hours, 8)
      }
    }

    if (currentDateM <= endM) {
      earned = (monthWeekly - weeklyCount) + weekBalance
    } else {
      const weekly = weeklyHistory[`${endM}`] ? weeklyHistory[`${endM}`][key] : null
      earned = weekly ? weekly.balance : 0
    }

    if (total) {
      return monthWeekly + weekBalance
    } else {
      return earned
    }
  }

  const leaveCounts = {
    'overtime_leave': 0,
    'annual_leave': 0,
  };

  const rowData = staffType === 'doctor' ? {
    punchClock,
    punchClockRevise,
    punchClockExceptions,
  } : {
    punchClock,
    punchClockRevise,
    punchClockExceptions,
    weeklyLeaves,
    leaves,
    comments
  }

  const doctorRow = {
    doctorShift,
    doctorSpecial,
    doctorLeave
  }

  return (
    <div className={classes.scheduleTable}>
      <div className={classes.date}>
        <div>
          <select onChange={(e) => updateScheduleData('year', e.target.value)} value={tableData.year} className={classes.dateYear}>
            {years.map(year => {
              return <option key={year} value={year}>{year}</option>
            })}
          </select>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <select onChange={(e) => updateScheduleData('month', e.target.value)} value={tableData.month} className={classes.dateMonth}>
            {months.map(month => {
              return <option key={month} value={month}>{month}</option>
            })}
          </select>
        </div>
        {userRight['finance-edit'] && <div className={classes.wrapper}>
          <Button
            style={{ fontSize: '14px', alignSelf: 'center', color: '#666666' }}
            onClick={() => exportProfile()}
            disabled={csvLoading}
          >
            <GetAppIcon />
            {formatMessage({ id: 'button.export' })}
            {csvLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </div>}
      </div>
      <div className={classes.scheduleTableLeft} ref={StaffTableRef}>
        <div className={classes.scheduleTableLeftColumn}>
          {staffKey.map((key, idx) => {
            leaveCounts.overtime_leave = leaveBalance[key] ? leaveBalance[key].overtime_leave.balance : 0;
            leaveCounts.annual_leave = leaveBalance[key] ? leaveBalance[key].annual_leave.balance : 0;
            if (['nurse', 'customerService', 'salesRep'].includes(staffType) && !['santea'].includes(process.env.BRANCH_ENV)) { leaveCounts.weekly_leave = weeklyEarnedCount(key, false) }
            const punchClockReviseCounts = punchClockReviseCount(key)
            return <div
              key={key}
              className={classes.employeeTable}
              onClick={() => isTablet ? setViewingStaff(key) : {}}
              style={{
                borderBottom: isTablet && viewingStaff === key ? '4px solid #3f51b5' : '',
                color: isTablet && viewingStaff === key ? '#3f51b5' : ''
              }}
            >
              <div className={classes.employeeName}>{staffData[key].displayName} </div>
              <div
                style={{
                  color: isTablet && viewingStaff === key ? '#3f51b5' : ''
                }}
                className={classes.employeeLeaveCount}
              >
                {staffPartTime[key] && <div>{'PT'}</div>}
                {(['nurse', 'customerService', 'salesRep'].includes(staffType) && !['santea'].includes(process.env.BRANCH_ENV)) &&
                  <div
                    style={{ color: leaveCounts.weekly_leave < 0 && 'red' }}
                  >
                    {!staffPartTime[key] && `本休: ${(leaveCounts.weekly_leave - (leaveCounts.weekly_leave % 8)) / 8}D ${leaveCounts.weekly_leave % 8}h`}
                  </div>
                }
                {staffType !== 'doctor' ?
                  <>
                    <div style={{ color: leaveCounts.overtime_leave < 0 && 'red' }}>
                      {!staffPartTime[key] && `補休: ${(leaveCounts.overtime_leave - (leaveCounts.overtime_leave % 8)) / 8}D ${leaveCounts.overtime_leave % 8}h`}
                    </div>
                    <div>{!staffPartTime[key] && `特休: ${(leaveCounts.annual_leave - (leaveCounts.annual_leave % 8)) / 8}D ${leaveCounts.annual_leave % 8}h`}</div>
                  </> : <div>{`總時數: ${doctorPunchClock(key)}`}</div>
                }
                {!staffPartTime[key] && <div style={{ color: punchClockReviseCounts > 0 && 'blue' }}>
                  {`忘刷卡: ${punchClockReviseCounts > 0 ? punchClockReviseCounts : 0}`}
                </div>}
              </div>
            </div>
          })}
        </div>
      </div>
      {staffType === 'doctor' ?
        <ScheduleDoctorRows
          currentUser={currentUser}
          month={tableData.month}
          year={tableData.year}
          staffData={staffData}
          staffKey={staffKey}
          userRight={userRight}
          viewingStaff={viewingStaff}
          isTablet={isTablet}
          onScrollRow={onScrollRow}
          {...doctorRow}
          {...rowData}
        /> :
        <ScheduleRows
          currentUser={currentUser}
          staffType={staffType}
          month={tableData.month}
          year={tableData.year}
          staffData={staffData}
          staffKey={staffKey}
          workTimes={workTimes}
          userRight={userRight}
          viewingStaff={viewingStaff}
          isTablet={isTablet}
          weeklyEarnedCount={weeklyEarnedCount}
          onScrollRow={onScrollRow}
          {...rowData}
        />
      }
    </div>
  );
}

ScheduleTable.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  staffType: PropTypes.string.isRequired,
  workTimes: PropTypes.object,
  userRight: PropTypes.object.isRequired,
  isTablet: PropTypes.bool.isRequired
};

export default ScheduleTable;
