const i18n = {
  'homePage': {
    'appointments': '預約',
    'patients': '客戶',
    'product': '產品',
    'merchandises': '耗材',
    'clinicSchedule': '診所班表',
    'staff': '職員',
    'leaveOvertimeWork': '請假與加班',
    'finance': '財務管理',
    'announcement': '公告',
    'manages': '銷售管理',
    'ddapp': 'DDApp',
    'quotation': '報價單'
  }
}

if (process.env.BRANCH_ENV === 'lexcellence') {
  i18n.homePage.welcome = '歡迎來到測試系統管理介面'
} else if (process.env.BRANCH_ENV === 'reborn') {
  i18n.homePage.welcome = '歡迎來到安珀琢玉系統管理介面'
} else if (process.env.BRANCH_ENV === 'santea') {
  i18n.homePage.welcome = '歡迎來到聖諦亞健康管理系統介面'
} else if (process.env.BRANCH_ENV === 'ibeauty') {
  i18n.homePage.welcome = '歡迎來到聖緹雅醫美系統管理介面'
} else if (process.env.BRANCH_ENV === 'ibeautyTaichung') {
  i18n.homePage.welcome = '歡迎來到聖緹雅時尚系統管理介面'
} else if (process.env.BRANCH_ENV === 'ibeautyPlastic') {
  i18n.homePage.welcome = '歡迎來到聖緹雅整形外科客戶系統管理介面'
} else if (process.env.BRANCH_ENV === 'ibeautyHsinchu') {
  i18n.homePage.welcome = '歡迎來到聖緹雅美學客戶系統管理介面'
}


export default i18n