import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgressMui from '@mui/material/CircularProgress';

import ActionDialog from '../../components/ActionDialog';
import ProgressStep from '../../components/ProgressStep'

const CircularProgress = styled(CircularProgressMui)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

const GreenButton = styled(Button)(() => ({
  backgroundColor: green[500],
  '&:hover': {
    backgroundColor: green[700],
    '@media (hover: none)': {
      backgroundColor: green[500]
    }
  },
  color: '#fff'
}));

function ReviewPunchClockReviseView({ punchClockRevise, isHistory, isAll }) {
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.firebase.data.users);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const punchClockReviseHistory = (punchClockRevise.history || []).map(h => ({ ...h }))
  const currentStep = punchClockReviseHistory.length > 0 ? punchClockReviseHistory[punchClockReviseHistory.length - 1].step : 0

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))

    log = log.replace(/f{date}/g, formatMessage({ id: 'punchClockRevise.table.date' }))
    log = log.replace(/f{reason}/g, formatMessage({ id: 'punchClockRevise.table.reason' }))
    log = log.replace(/f{time}/g, formatMessage({ id: 'punchClockRevise.table.time' }))

    return log
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve'].includes(action)) {
      setLoadingApprove(true)
    } else {
      setLoadingReject(true)
    }

    try {
      await (firebase.functions().httpsCallable('reviewPunchClockRevise'))({
        id: punchClockRevise.id,
        action,
        note: text
      })

      if (['approve'].includes(action)) {
        if (currentStep < punchClockRevise.steps.length - 1) {
          setLoadingApprove(false)
        }
      } else {
        setLoadingReject(false)
      }
    } catch (ex) {
      setLoadingApprove(false)
      setLoadingReject(false)
      console.log(ex)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  const ls = punchClockRevise.status !== 'void' ? punchClockRevise.steps.slice(currentStep, punchClockRevise.steps.length).map(s => ({ ...s })) : []
  if (punchClockRevise.status !== 'void' && currentStep === 0) {
    ls[0].name = '編輯 / 作廢'
  }
  const steps = [...punchClockReviseHistory].concat(ls)

  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={dialogData.action === 'reject' ? formatMessage({ id: 'punchClockRevise.detail.rejectReason' }) : formatMessage({ id: 'punchClockRevise.detail.note' })}
        action={dialogData.action}
      />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <ProgressStep
            activeStep={punchClockRevise.history.length}
            steps={steps}
            orie={'horizontal'}
            alternativeLabel={true}
            title={formatMessage({ id: 'punchClockRevise.list.step' })}
          />
        </Grid>
      </Grid>
      <Grid sx={{ paddingTop: '10px' }} container spacing={1}>
        <Grid item key="buttons" xs={12} sm={12} md={12}>
          <div style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            {!isHistory && !isAll && <div style={{ position: 'relative' }}>
              <Button
                variant="contained"
                color="error"
                sx={{ marginRight: '8px' }}
                onClick={() => setDialogData({ action: 'reject', title: '' })}
                disabled={loadingApprove || loadingReject}
              >
                {formatMessage({ id: 'button.reject' })}
              </Button>
              {loadingReject && <CircularProgress size={24} />}
            </div>}
            {!isHistory && !isAll && <div style={{ position: 'relative' }}>
              <GreenButton
                variant="contained"
                color="primary"
                onClick={() => setDialogData({ action: 'approve', title: '' })}
                disabled={loadingApprove || loadingReject}
              >
                {formatMessage({ id: 'button.approve' })}
              </GreenButton>
              {loadingApprove && <CircularProgress size={24} />}
            </div>}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

ReviewPunchClockReviseView.propTypes = {
  isAll: PropTypes.bool,
  isHistory: PropTypes.bool,
  punchClockRevise: PropTypes.object.isRequired,
};

export default ReviewPunchClockReviseView;
