import { createContext } from 'react';
import dayjs from 'dayjs';

const ContextStore = createContext({
  isMobile: false,
  isTablet: false,
  breadcrumbs: [],
  setBreadcrumbs: () => { },
  drawerStatus: {
    content: {},
    display: 'none',
  },
  setDrawerStatus: () => { },
  uiState: {
    date: dayjs(),
    noShifts: false,
    showNurse: true,
    showRoom: true,
    showSalesRep: true,
    showPatientType: false
  },
  setUiState: () => { }
})


export default ContextStore
