import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

export default function ProgressStep({ activeStep, steps, title, orie, alternativeLabel = false }) {
  const [stepDetailText, setStepDetailText] = useState('')

  return <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {stepDetailText &&
          <Dialog onClose={() => setStepDetailText('')} open>
            <Box p={3}>
              <div style={{ whiteSpace: 'pre-line' }}>
                {stepDetailText}
              </div>
            </Box>
          </Dialog>
        }
        <div style={{ marginLeft: '25px', fontSize: '15px', color: '#828a99', padding: '10px 0' }}><strong>{title}</strong></div>
        <Stepper activeStep={activeStep} orientation={orie ? orie : 'vertical'} alternativeLabel={alternativeLabel}>
          {steps.map((step, idx) => (
            <Step key={`${step.name}_${idx}`} {...step.stepProps}>
              <StepLabel {...step.labelProps} sx={{ whiteSpace: 'pre-line' }}>
                {step.hint ? <Tooltip title={step.hint}><span>{step.text}</span></Tooltip> : step.text}
                {step.detail && <Button color="primary" onClick={() => setStepDetailText(step.detail)}>查看修改內容</Button>}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </ThemeProvider>
    </StyledEngineProvider>
  </>;
}

ProgressStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    stepProps: PropTypes.object,
    labelProps: PropTypes.object,
    text: PropTypes.string.isRequired,
    detail: PropTypes.string,
  })).isRequired,
  title: PropTypes.string,
  orie: PropTypes.string,
  alternativeLabel: PropTypes.bool
};
