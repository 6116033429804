import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { getComparator, stableSort } from '../modules/sort';
import EnhancedTableRow from './EnhancedTableRow';
import EnhancedTableHead from './EnhancedTableHead';
import SearchBox from './SearchBox';

export default function SelectMedicineDialog({ defaultSelectedItems, handleClose, defaultCategoryFilter = {}, handleSave, medicines, size = 'lg', dialogTitle }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {}));

  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(defaultCategoryFilter);

  const filteredMedicines = filterMedicines()
  const filteredselection = filteredMedicines.filter(s => selectedItems[s.id]).reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name', sort: 'name' },
    { text: 'unit', sort: 'unit' },
    { text: 'note', sort: 'note' },
  ].map(c => { c.text = formatMessage({ id: `medicine.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'unit' },
    { field: 'note' },
  ]

  const filterItems = [
    { name: 'code' },
    { name: 'name' },
    { name: 'unit' },
    { name: 'note' },
  ].map(i => { i.text = formatMessage({ id: `medicine.table.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'category' },
  ].map(i => { i.text = formatMessage({ id: `medicine.table.${i.name}` }); return i })


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredMedicines.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  };

  const handleCheckboxClick = (id) => {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function filterMedicines() {
    if (!currentFilter &&
      !categoryFilter.category &&
      !categoryFilter.controlledDrug) {
      return medicines
    }
    let items = [...medicines]

    if (categoryFilter.category) {
      items = items.filter(i => i.category === categoryFilter.category)

    }
    if (currentFilter) {
      items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return items
  }

  function getMenuItem(name) {
    if (name === 'category') {
      return [
        { value: '' },
        { value: 'PO' },
        { value: 'OI' },
        { value: 'IV' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `medicine.category.${i.value}` }) : formatMessage({ id: 'medicine.category.all' })
        return i
      })
    } else if (name === 'controlledDrug') {
      return [{ value: '' }, { value: 'type1' }, { value: 'type2' }].map(i => {
        i.label = i.value ? formatMessage({ id: `medicine.controlledDrug.${i.value}` }) : formatMessage({ id: 'medicine.category.all' })
        return i
      })
    }
    return []
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const onApply = (medicine) => {
    const selectedMedicines = medicines.filter(s => selectedItems[s.id]).map(s => ({
      category: s.category,
      code: s.code,
      name: s.name,
      unit: s.unit,
      medicineId: s.id,
    }))
    handleSave(selectedMedicines)
    handleClose()
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={3} sm={3} md={3}>
      <TextField
        select
        disabled={!!defaultCategoryFilter[filter.name]}
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
        value={categoryFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={size}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1, zIndex: 2000 }}>
          <Toolbar sx={{ pl: 2, pr: 1 }}>
            <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
              <FormattedMessage id="medicine.title" />
            </Typography>
            <Grid container spacing={3}>
              {categoryFilterItems.map(f => getFilter(f))}
            </Grid>
            <SearchBox autoFocus filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Toolbar>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={Object.keys(filteredselection).length}
                order={order}
                orderBy={orderBy}
                onCheckboxClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredMedicines.length}
              />
              <TableBody>
                {stableSort(filteredMedicines, getComparator(order, orderBy)).map(medicine => (
                  <EnhancedTableRow
                    key={medicine.id}
                    rowCells={rowCells}
                    cellData={medicine}
                    onCheckboxClick={(e) => handleCheckboxClick(medicine.id)}
                    selected={selectedItems[medicine.id] || false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>

  );
}

SelectMedicineDialog.propTypes = {
  defaultCategoryFilter: PropTypes.object,
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
  handleClose: PropTypes.func.isRequired,
  medicines: PropTypes.arrayOf(PropTypes.object).isRequired,
  dialogTitle: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  size: PropTypes.string
};
