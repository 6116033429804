import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import { createUseStyles } from 'react-jss';

import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';

import { formatName } from '../../../modules/uitls';
import ImageDocument from './ImageDocument'
import ControlledDrugPrescriptionDocument from './ControlledDrugPrescriptionDocument'
import PrescriptionDocument from './PrescriptionDocument'

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: '#828A99',
    zIndex: 9999,
    overflow: 'scroll',
    // ['@media print']: {
    //   background: '#fff',
    //   overflow: 'visible',
    //   bottom: 0,
    //   position: 'relative'
    // }
  },
  header: {
    width: '100%',
    height: '64px',
    position: 'fixed',
    top: 0,
    background: '#888888',
    zIndex: 9,
    // ['@media print']: {
    //   display: 'none'
    // }
  },
  headerDetails: {
    width: '840px',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff'
  },
  page: {
    '& i': {
      fontSize: '13px',
      fontStyle: 'normal',
    },
    fontSize: '15px',
    '& span': {
      display: 'inline-block',
      width: '30px',
      height: '26px',
      borderRadius: '2px',
      backgroundColor: '#1b2840',
      textAlign: 'center',
      lineHeight: '25px',
      margin: '0 5px'
    }
  },
  title: {
    fontSize: '15px',
    textAlign: 'center',
    color: '#ffffff'
  },
  zoom: {
    alignItems: 'center',
    display: 'flex',
    divider: {
      width: '1px',
      height: '16px',
      backgroundColor: '#ffffff'
    }
  },
  printButton: {
    position: 'absolute!important',
    top: '10px',
    right: '10px',
    opacity: '0.54',
    '&: hover': {
      opacity: 1
    }
  },
  content: {
    paddingTop: '85px',
    // ['@media print']: {
    //   paddingTop: 0,
    //   transform: 'scale(1)!important'
    // }
  }
});

function DocumentContainer() {
  const classes = useStyles();
  const childRef = useRef()
  const { patientId, kardexId, docType } = useParams()
  const [images, setImages] = useState(null)
  const [underImages, setUnderImages] = useState(null)
  const [photoImage, setPhotoImage] = useState(null)
  const [stampNurses, setStampNurses] = useState(null)
  const [clinicChop, setClinicChop] = useState(null)
  const [scale, setScale] = useState(1)
  const [page, setPage] = useState(1)
  const [docTitle, setDocTitle] = useState('...')
  const [doctorStamp, setDoctorStamp] = useState(null)

  const [userMapping, setUserMapping] = useState({})
  const [patient, setPatient] = useState(null)
  const [kardex, setKardex] = useState(null)
  const [injection, setInjection] = useState({})
  const [inquirie, setInquirie] = useState({})
  const [templateMapping, setTemplateMapping] = useState({})

  let clinicStampPath = ''
  if (process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence') {
    clinicStampPath = 'reborn.png'
  } else if (process.env.BRANCH_ENV === 'santea') {
    clinicStampPath = 'santea.png'
  } else if (process.env.BRANCH_ENV === 'ibeauty') {
    clinicStampPath = 'ibeauty-beauty.png'
  } else if (process.env.BRANCH_ENV === 'ibeautyPlastic') {
    clinicStampPath = 'ibeauty-plastic.png'
  } else if (process.env.BRANCH_ENV === 'ibeautyTaichung') {
    clinicStampPath = 'ibeauty-taichung.png'
  }

  useEffect(() => {
    getClinicChopURL()
    return () => { }
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('users')
    const onDataChange = ref.on('value', snapshot => {
      setUserMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('inquiryTemplates')
    const onDataChange = ref.on('value', snapshot => {
      setTemplateMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('injections')
    const onDataChange = ref.on('value', snapshot => {
      setInjection(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('inquiries')
    const onDataChange = ref.on('value', snapshot => {
      setInquirie(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('kardexes').child(kardexId)
    const onDataChange = ref.on('value', snapshot => {
      setKardex(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, [kardexId]);

  useEffect(() => {
    const ref = firebase.database().ref('patients').child(patientId)
    const onDataChange = ref.on('value', snapshot => {
      let snapshots = { ...snapshot.val(), name: formatName(snapshot.val().name) }
      setPatient(snapshots)
    });
    return () => ref.off('value', onDataChange)
  }, [patientId]);

  useEffect(() => {

  }, []);

  async function getClinicChopURL() {
    firebase.storage().ref(`clinicStamps/${clinicStampPath}`).getDownloadURL().then((url) => {
      setClinicChop(url)
    })
  }

  const handleScroll = (e) => {
    if (['prescription', 'controlledDrug'].includes(docType)) {
      return
    }

    let page = 1
    let docTitle = '...'

    for (let i = 0; i < childRef.current.children.length; i++) {
      const rect = childRef.current.children[i].getBoundingClientRect()
      if (rect.top < window.innerHeight) {
        docTitle = Object.keys(kardex[docType])[i] + '.png'
        page = i + 1
      }
    }

    setPage(page)
    setDocTitle(docTitle)
  }

  function fetchUnderImages() {
    if (kardex && kardex[docType]) {
      const promises = [];
      const storageRef = firebase.storage().ref('inquiryTemplates')
      for (let docKey in kardex[docType]) {
        if (docType === 'inquiries') {
          for (const template in templateMapping) {
            if (templateMapping[template].name === inquirie[docKey].name) {
              if (templateMapping[template].specificGender === 'S') {
                promises.push(storageRef.child(`${patient.gender === 'male' ? 'M' : 'F'}_${templateMapping[template].name}.jpg`).getDownloadURL().then((url) => {
                  return { key: docKey, url: url, doctorSeal: templateMapping[template].doctorSeal, nurseSeal: templateMapping[template].nurseSeal, titleInfo: templateMapping[template].titleInfo }
                }, (err) => {
                  console.log(err)
                }))
              } else {
                promises.push(storageRef.child(`${templateMapping[template].specificGender}_${templateMapping[template].name}.jpg`).getDownloadURL().then((url) => {
                  return { key: docKey, url: url, doctorSeal: templateMapping[template].doctorSeal, nurseSeal: templateMapping[template].nurseSeal, titleInfo: templateMapping[template].titleInfo }
                }, (err) => {
                  console.log(err)
                }))
              }
            }
          }

          for (const template in templateMapping) {
            if (inquirie[docKey].childPages) {
              for (const s in inquirie[docKey].childPages) {
                if (templateMapping[template].name === inquirie[s].name) {
                  if (templateMapping[template].specificGender === 'S') {
                    promises.push(storageRef.child(`${patient.gender === 'male' ? 'M' : 'F'}_${templateMapping[template].name}.jpg`).getDownloadURL().then((url) => {
                      return { key: s, url: url, doctorSeal: templateMapping[template].doctorSeal, nurseSeal: templateMapping[template].nurseSeal, titleInfo: templateMapping[template].titleInfo }
                    }, (err) => {
                      console.log(err)
                    }))
                  } else {
                    promises.push(storageRef.child(`${templateMapping[template].specificGender}_${templateMapping[template].name}.jpg`).getDownloadURL().then((url) => {
                      return { key: s, url: url, doctorSeal: templateMapping[template].doctorSeal, nurseSeal: templateMapping[template].nurseSeal, titleInfo: templateMapping[template].titleInfo }
                    }, (err) => {
                      console.log(err)
                    }))
                  }
                }
              }
            }
          }

        } else {
          for (const template in templateMapping) {
            if (templateMapping[template].name === injection[docKey].name) {
              if (templateMapping[template].specificGender === 'S') {
                promises.push(storageRef.child(`${patient.gender === 'male' ? 'M' : 'F'}_${templateMapping[template].name}.jpg`).getDownloadURL().then((url) => {
                  return { key: docKey, url: url, doctorSeal: templateMapping[template].doctorSeal, nurseSeal: templateMapping[template].nurseSeal, titleInfo: templateMapping[template].titleInfo }
                }, (err) => {
                  console.log(err)
                }))
              } else {
                promises.push(storageRef.child(`${templateMapping[template].specificGender}_${templateMapping[template].name}.jpg`).getDownloadURL().then((url) => {
                  return { key: docKey, url: url, doctorSeal: templateMapping[template].doctorSeal, nurseSeal: templateMapping[template].nurseSeal, titleInfo: templateMapping[template].titleInfo }
                }, (err) => {
                  console.log(err)
                }))

              }
            }
          }

          for (const template in templateMapping) {
            if (injection[docKey].childPages) {
              for (const s in injection[docKey].childPages) {
                if (templateMapping[template].name === injection[s].name) {
                  if (templateMapping[template].specificGender === 'S') {
                    promises.push(storageRef.child(`${patient.gender === 'male' ? 'M' : 'F'}_${templateMapping[template].name}.jpg`).getDownloadURL().then((url) => {
                      return { key: s, url: url, doctorSeal: templateMapping[template].doctorSeal, nurseSeal: templateMapping[template].nurseSeal, titleInfo: templateMapping[template].titleInfo }
                    }, (err) => {
                      console.log(err)
                    }))
                  } else {
                    promises.push(storageRef.child(`${templateMapping[template].specificGender}_${templateMapping[template].name}.jpg`).getDownloadURL().then((url) => {
                      return { key: s, url: url, doctorSeal: templateMapping[template].doctorSeal, nurseSeal: templateMapping[template].nurseSeal, titleInfo: templateMapping[template].titleInfo }
                    }, (err) => {
                      console.log(err)
                    }))
                  }
                }
              }
            }
          }
        }
      }

      Promise.all(promises).then((results) => {
        const underImages = results.reduce((acc, item) => {
          if (!item || !item.key) {
            return acc
          }
          acc[item.key] = item.url
          return acc
        }, {})

        const doctorSeal = results.reduce((acc, item) => {
          if (!item || !item.key) {
            return acc
          }

          acc[item.key] = item.doctorSeal
          return acc
        }, {})

        const nurseSeal = results.reduce((acc, item) => {
          if (!item || !item.key) {
            return acc
          }

          acc[item.key] = item.nurseSeal
          return acc
        }, {})

        const titleInfo = results.reduce((acc, item) => {
          if (!item || !item.key) {
            return acc
          }

          acc[item.key] = item.titleInfo
          return acc
        }, {})

        setUnderImages({ underImages, doctorSeal, nurseSeal, titleInfo })
      })
    }
  }

  function fetchImages() {
    const storageRef = firebase.storage().ref(`${docType}`)

    let data = {}
    if (docType === 'inquiries') {
      data = { ...inquirie }
    } else {
      data = { ...injection }
    }

    if (kardex && kardex[docType]) {
      const promises = []
      for (let docKey in kardex[docType]) {
        if (data[docKey].photo) {
          storageRef.child(`${docKey}/photo.png`).getDownloadURL().then((url) => {
            if (url) { setPhotoImage({ [docKey]: url }) }
          }, (err) => {
            console.log(err)
          })
        }

        if (data[docKey].childPages) {
          for (let s in data[docKey].childPages) {
            for (let dateKey in data[s].uploadDates) {
              for (let userKey in data[s].uploadDates[dateKey]) {
                promises.push(storageRef.child(`${s}/${dateKey}/${userKey}.png`).getDownloadURL().then((url) => {
                  return { key: s, date: dateKey, url: url, user: userKey }
                }, (err) => {
                  console.log(err)
                }))
              }
            }
          }
        }
        for (let dateKey in data[docKey].uploadDates) {
          for (let userKey in data[docKey].uploadDates[dateKey]) {
            promises.push(storageRef.child(`${docKey}/${dateKey}/${userKey}.png`).getDownloadURL().then((url) => {
              return { key: docKey, date: dateKey, url: url, user: userKey }
            }, (err) => {
              console.log(err)
            }))
          }
        }
      }

      Promise.all(promises).then((results) => {
        const images = results.reduce((acc, item) => {
          if (!item || !item.key) {
            return acc
          }

          if (!acc[item.key]) {
            acc[item.key] = { [item.date]: {} }
          }

          acc[item.key][item.date] = { ...acc[item.key][item.date], [item.user]: item.url }
          return acc
        }, {})

        setImages({ ...images })
      })
    }
  }

  function fetchStampNurseImages() {
    if (kardex && kardex[docType]) {
      const promises = [];
      for (let docKey in kardex[docType]) {
        if (docType === 'inquiries') {
          Object.keys(userMapping).filter(m => userMapping[m].department === 'nurse').forEach(keys => {
            if (keys === inquirie[docKey].nurse) {
              promises.push({ key: docKey, url: userMapping[keys].smallStampUrl })
            }
          })
        } else {
          Object.keys(userMapping).filter(m => userMapping[m].department === 'nurse').forEach(keys => {
            if (keys === injection[docKey].nurse) {
              promises.push({ key: docKey, url: userMapping[keys].smallStampUrl })
            }
          })
        }
      }
      Promise.all(promises).then((results) => {
        const stampNurse = results.reduce((acc, item) => {
          if (!item || !item.key) {
            return acc
          }
          acc[item.key] = item.url
          return acc
        }, {})

        setStampNurses(stampNurse)
      })
    }
  }

  const zoomIn = () => {
    setScale(scale + 0.1)
  }

  const zoomOut = () => {
    setScale(scale - 0.1)
  }

  if (!kardex || !patient) {
    return ('Kardex not found')
  }

  let body, docKeys
  if (docType === 'injections' || docType === 'inquiries') {
    if (!images) {
      fetchImages()
    }

    if (!stampNurses) {
      fetchStampNurseImages()
    }

    if (!underImages) {
      fetchUnderImages()
    }

    if (!images || !stampNurses || !underImages) {
      return ('Loading...')
    }

    docKeys = [];
    if (docType === 'injections') {
      Object.keys(kardex[docType]).sort((a, b) => {
        return injection[a].createdAt - injection[b].createdAt
      }).forEach(s => {
        if (injection[s].childPages) {
          docKeys.push(s)
          for (const c in injection[s].childPages) {
            docKeys.push(c)
          }
        } else {
          const newStr = injection[s].name.substr(injection[s].name.length - 1, 1)
          if (!['+', '~'].includes(newStr)) {
            docKeys.push(s)
          }
        }
      })
    } else {
      Object.keys(kardex[docType]).sort((a, b) => {
        return inquirie[a].createdAt - inquirie[b].createdAt
      }).forEach(s => {
        if (inquirie[s].childPages) {
          docKeys.push(s)
          for (const c in inquirie[s].childPages) {
            docKeys.push(c)
          }
        } else {
          const newStr = inquirie[s].name.substr(inquirie[s].name.length - 1, 1)
          if (!['+', '~'].includes(newStr)) {
            docKeys.push(s)
          }
        }
      })
    }

    body = docKeys.map((key) => (
      <ImageDocument
        docType={docType}
        key={key}
        patient={patient}
        kardex={kardex}
        userMapping={userMapping}
        image={images[key]}
        stampNurse={stampNurses[key]}
        titleInfo={underImages.titleInfo[key]}
        doctorSeal={underImages.doctorSeal[key]}
        nurseSeal={underImages.nurseSeal[key]}
        underImages={underImages.underImages[key]}
        photoImage={photoImage[key]}
      />
    ))
  } else if (docType === 'prescription') {
    if (docTitle === '...') {
      setDocTitle(`${kardex.date}_${patient.name.replace(' ', '-')}_prescription`)
    }
    docKeys = [null]

    if (!doctorStamp && docType === 'prescription' && kardex.doctor) {
      setDoctorStamp(userMapping[kardex.doctor].certificateStampUrl)
    }

    body = <PrescriptionDocument
      kardex={kardex}
      prescription={kardex.prescription}
      patient={patient}
      doctorStamp={doctorStamp}
      clinicChop={clinicChop}
    />
  } else if (docType === 'controlledDrug') {
    if (docTitle === '...') {
      setDocTitle(`${kardex.date}_${patient.name.replace(' ', '-')}_controlledDrug`)
    }
    docKeys = [null]

    if (!doctorStamp && docType === 'controlledDrug' && kardex.doctor) {
      setDoctorStamp(userMapping[kardex.doctor].certificateStampUrl)
    }

    body = <ControlledDrugPrescriptionDocument
      kardex={kardex}
      controlledDrug={kardex.controlledDrug}
      patient={patient}
      doctorStamp={doctorStamp}
      clinicChop={clinicChop}
    />
  }

  return (
    <div className={classes.root} onScroll={handleScroll}>
      <div className={classes.header}>
        <div className={classes.headerDetails}>
          <div className={classes.page} >
            <i>Page</i> <span>{page}</span> of {docKeys.length}
          </div>
          <div className={classes.title}>
            {docTitle}
          </div>
          <div className={classes.zoom}>
            <IconButton style={{ color: '#fff' }} onClick={zoomOut} size="large">
              <RemoveIcon />
            </IconButton>
            <span className={classes.divider} />
            <IconButton style={{ color: '#fff' }} onClick={zoomIn} size="large">
              <AddIcon />
            </IconButton>
          </div>
        </div>
        <IconButton
          className={classes.printButton}
          style={{ color: '#fff' }}
          onClick={() => window.print()}
          size="large">
          <PrintIcon />
        </IconButton>
      </div>
      <div
        ref={childRef}
        className={classes.content}
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top',
        }}
      >
        {body}
      </div>
    </div>
  );
}

export default DocumentContainer;
