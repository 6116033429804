const i18n = {
  'tab': {
    'secondaryNav': {
      'leave': '我的休假',
      'overtime': '我的加班',
      'punchClockRevise': '忘刷卡申請',
      'reviewLeave': '簽核休假申請',
      'reviewOvertime': '簽核加班申請',
      'reviewPunchClockRevise': '簽核忘刷卡申請',
      'allLeave': '全部休假申請',
      'allOvertime': '全部加班申請',
      'allPunchClockRevise': '全部忘刷卡申請',
    },
    'schedule': {
      'nurse': '護理美容部',
      'salesRep': '客戶關係代表部',
      'customerService': '客戶服務部',
      'doctor': '醫師',
      'management': '管理部',
      'marketing': '行銷部',
      'hr': '人力資源資部',
      'financial': '財務部',
      'itDept': '資訊部',
      'nutritionist': '營養師',
      'psychologist': '心理師',
      'santea': {
        'nurse': '健康管理部',
        'salesRep': '業務部',
        'customerService': '客戶服務部',
        'doctor': '醫師',
        'management': '管理部',
        'marketing': '行銷部',
        'hr': '人力資源資部',
        'financial': '財務部',
        'itDept': '資訊部',
        'nutritionist': '營養師',
        'psychologist': '心理師',
      }
    }
  }
}

export default i18n