import React from 'react';
import PropTypes from 'prop-types';

import { createTheme, ThemeProvider, StyledEngineProvider, styled } from '@mui/material/styles';
import FabMui from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const Fab = styled(FabMui, { shouldForwardProp: (prop) => !['reborn', 'lexcellence'].includes(prop) })(
  ({ theme, env }) => ({
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1100,
    ...(['reborn', 'lexcellence'].includes(env) && {
      '&:hover': {
        color: '#fff',
        '@media (hover: none)': {
          color: '#fff'
        }
      }
    })
  })
);

const primaryColor = process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence' ? {
  light: '#fffff7',
  main: '#eeccc4',
  dark: '#bb9b94',
  contrastText: '#a58a88',
} :
  process.env.BRANCH_ENV === 'santea' ? {
    light: '#6efeff',
    main: '#26CAD3',
    dark: '#0099a2',
    contrastText: '#fff',
  } : {
    light: '#a3ffff',
    main: '#6EDBD5',
    dark: '#35a9a4',
    contrastText: '#fff',
  }

const theme = createTheme({
  palette: {
    primary: primaryColor,
  },
});

export default function FabComponent({ onClick, styles, isDisabled, icon }) {
  return <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Fab
          env={process.env.BRANCH_ENV}
          color="primary"
          sx={styles}
          onClick={onClick}
          disabled={isDisabled}
        >
          {icon ? icon : <AddIcon />}
        </Fab>
      </ThemeProvider>
    </StyledEngineProvider>
  </>;
}

FabComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.object,
  isDisabled: PropTypes.bool,
  icon: PropTypes.node
};
