import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';

import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';

import warehouse from '../../modules/warehouseConfig'
import { objectToArray } from '../../modules/data'
import ContextStore from '../../modules/context';
import { formatName } from '../../modules/uitls';
import TabContainer from '../../containers/TabContainer/TabContainer'
import Profile from './PatientPage/Profile'
import Kardex from './PatientPage/Kardex'
import Consumption from './PatientPage/Consumption'
import CertificateOfDiagnosisList from './PatientPage/CertificateOfDiagnosisList'
import PrescriptionSign from './PatientPage/PrescriptionSign'
import Purchases from './PatientPage/Purchases'
import Agreement from './PatientPage/Agreement'

function PatientTab() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const { patientId, tabName } = useParams()
  const history = useHistory();
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const [patient, setPatient] = useState(null)
  const userMapping = useSelector(state => state.firebase.data.users);
  const [benefit, setBenefit] = useState(null)
  const [patientMapping, setPatientMapping] = useState({})
  const [kardex, setKardex] = useState([])
  const [transfers, setTransfers] = useState({})
  const [refundMapping, setRefundMapping] = useState({})
  const [preferences, setPreferences] = useState(null)
  const [medicalHistory, setMedicalHistory] = useState(null)
  const [skinCareHistory, setSkinCareHistory] = useState(null)
  const [agreements, setAgreements] = useState(null)
  const [agreementTemplates, setAgreementTemplates] = useState(null)
  const [purchaseOrders, setPurchaseOrders] = useState(null)
  const [payments, setPayments] = useState({})
  const [purchasedProducts, setPurchasedProducts] = useState(null)
  const [consumedProducts, setConsumedProducts] = useState(null)
  const [productMapping, setProductMapping] = useState({})
  const [storedValueCard, setStoredValueCard] = useState(null)

  const historyMapping = {
    preferences: preferences,
    medicalHistory: medicalHistory,
    skinCareHistory: skinCareHistory
  }

  useEffect(() => {
    const ref = firebase.database().ref('storedValueCard').child(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setStoredValueCard(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('products')
    const onDataChange = ref.on('value', snapshot => {
      setProductMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('purchasedProductRefund').orderByChild('patient').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setRefundMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('purchasedProductTransfer').orderByChild('oldPatient').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setTransfers(transfer => ({
        ...transfer,
        ...snapshot.val()
      }))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('purchasedProductTransfer').orderByChild('newPatient').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setTransfers(transfer => ({
        ...transfer,
        ...snapshot.val()
      }))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('consumedProducts').orderByChild('patient').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setConsumedProducts(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('purchasedProducts').orderByChild('patient').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setPurchasedProducts(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('payments').orderByChild('patient').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      if (snapshots) {
        setPayments(snapshot.val())
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('purchaseOrders').orderByChild('patient').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setPurchaseOrders(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('agreementTemplates')
    const onDataChange = ref.on('value', snapshot => {
      setAgreementTemplates(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('agreements').orderByChild('patientId').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setAgreements(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patientPreferences').child(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setPreferences(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patientMedicalHistory').child(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setMedicalHistory(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patientSkinCareHistory').child(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setSkinCareHistory(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        const snapshots = snapshot.val()
        setPatientMapping(snapshots)
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('kardexes').orderByChild('patient').equalTo(patientId)
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      const data = [];
      if (snapshots) {
        Object.keys(snapshots).forEach(m => {
          data.push({ id: m, ...snapshots[m] })
        })
        setKardex(data)
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patients').child(patientId)
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setPatient({ ...snapshot.val(), id: patientId })
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = patient ? firebase.database(warehouse).ref('benefitBalances').child(`${patient.employeeId}`) : null
    const onDataChange = patient ? ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      setBenefit(snapshots)
    }) : null
    return () => { if (onDataChange && ref) ref.off('value', onDataChange) }
  }, [patient]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/patients',
      text: formatMessage({ id: 'sideMenu.patients' })
    }]
    breadcrumbs.push({ text: formatMessage({ id: `patient.tab.${tabName}` }) })

    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [history.location.pathname]);

  useEffect(() => {
    if (tabName === 'profile') {
      setActiveTabIndex(0)
    } else if (tabName === 'kardexes') {
      setActiveTabIndex(1)
    } else if (tabName === 'consumption') {
      setActiveTabIndex(2)
    } else if (tabName === 'certificateOfDiagnosisList') {
      setActiveTabIndex(3)
    } else if (tabName === 'prescriptionSign') {
      setActiveTabIndex(4)
    } else if (tabName === 'purchases') {
      setActiveTabIndex(5)
    } else if (tabName === 'agreements') {
      setActiveTabIndex(6)
    }
    return () => {
    };
  }, []);

  if (!patient) {
    return ('Loading...')
  }

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      history.push(`/patients/${patientId}/profile`);
    } else if (tabIndex === 1) {
      history.push(`/patients/${patientId}/kardexes`);
    } else if (tabIndex === 2) {
      history.push(`/patients/${patientId}/consumption`);
    } else if (tabIndex === 3) {
      history.push(`/patients/${patientId}/certificateOfDiagnosisList`);
    } else if (tabIndex === 4) {
      history.push(`/patients/${patientId}/prescriptionSign`);
    } else if (tabIndex === 5) {
      history.push(`/patients/${patientId}/purchases`);
    } else if (tabIndex === 6) {
      history.push(`/patients/${patientId}/agreements`);
    }
  };

  const tabs = [
    {
      label: formatMessage({ id: 'patient.tab.profile' }), component:
        <Profile
          patient={patient}
          benefit={benefit}
          userMapping={userMapping || {}}
          patientMapping={patientMapping}
          historyMapping={historyMapping}
          productMapping={productMapping}
          storedValueCard={storedValueCard || {}}
        />
    },
    {
      label: formatMessage({ id: 'patient.tab.kardexes' }), component:
        <Kardex
          kardexes={kardex}
          userMapping={userMapping || {}}
        />
    },
    {
      label: formatMessage({ id: 'patient.tab.consumption' }), component:
        <Consumption
          purchasedProducts={purchasedProducts}
          consumedProducts={consumedProducts}
          userMapping={userMapping || {}}
          kardex={kardex}
          transfers={objectToArray(transfers)}
          refundMapping={refundMapping}
          patientMapping={patientMapping}
          patientId={patientId}
        />
    },
    {
      label: formatMessage({ id: 'patient.tab.certificateOfDiagnosisList' }), component:
        <CertificateOfDiagnosisList
          kardexes={kardex}
          userMapping={userMapping || {}}
        />
    },
    {
      label: formatMessage({ id: 'patient.tab.prescriptionSign' }), component:
        <PrescriptionSign
          kardexes={kardex}
          userMapping={userMapping || {}}
        />
    },
    {
      label: formatMessage({ id: 'patient.tab.purchases' }), component:
        <Purchases
          purchaseOrders={purchaseOrders}
          userMapping={userMapping || {}}
          payments={payments}
        />
    },
    {
      label: formatMessage({ id: 'patient.tab.agreements' }), component:
        <Agreement
          agreements={agreements}
          agreementTemplates={agreementTemplates}
          kardex={kardex}
          userMapping={userMapping || {}}
        />
    },
  ];

  let defaultSelect
  if (tabName === 'profile') {
    defaultSelect = 0
  } else if (tabName === 'kardexes') {
    defaultSelect = 1
  } else if (tabName === 'consumption') {
    defaultSelect = 2
  } else if (tabName === 'certificateOfDiagnosisList') {
    defaultSelect = 3
  } else if (tabName === 'prescriptionSign') {
    defaultSelect = 4
  } else if (tabName === 'purchases') {
    defaultSelect = 5
  } else if (tabName === 'agreements') {
    defaultSelect = 6
  }

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', }}>
      <div style={{ padding: '40px 20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px' }}>
          <div style={{ fontSize: '34px', fontWeight: 400, color: '#888', }}>{formatName(patient.name)}</div>
          <div style={{ fontSize: '14px', fontWeight: 500, color: '#828a99', marginLeft: '35px' }}><CakeIcon sx={{ marginRight: '5px' }} />{patient.birthDate}</div>
          <div style={{ fontSize: '14px', fontWeight: 500, color: '#828a99', marginLeft: '35px' }}><PhoneIcon sx={{ marginRight: '5px' }} />{patient.phone}</div>
        </div>
        <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
      </div>
    </div>
  );
}

export default PatientTab;
