import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import firebase from 'firebase/app';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import FabComponent from '../../components/FabComponent'
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import SearchBox from '../../components/SearchBox';
import ExpandButton from '../../components/ExpandButton';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import ImageUpload from '../../components/ImageUpload'
import ContextStore from '../../modules/context';
import ButtonProgress from '../../components/ButtonProgress';

function AnnouncementDailog({ data, updateData, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [upLoadError, setUpLoadError] = useState('none')

  async function saveAnnouncement() {
    setLoading(true)
    if (data.pdf?.url === '') {
      setLoading(false)
      return setUpLoadError('error')
    } else {
      const newData = {
        subject: data.subject,
        url: data.pdf.url,
        filesname: data.pdf.filesname.name
      }

      try {
        await (firebase.functions().httpsCallable('saveAnnouncement'))({ ...newData })
        setLoading(false)
        setUpLoadError('none')
        handleClose()
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  return (
    <div style={{ padding: '20px' }}>
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title"><FormattedMessage id="announcement.upload.title" /></DialogTitle>
        <DialogContent dividers={true}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label={<FormattedMessage id="announcement.upload.subject" />}
            onChange={(e) => updateData({ name: 'subject' }, e.target.value)}
            value={data.subject}
          />
          {upLoadError === 'none' && <div></div>}
          {upLoadError === 'error' && <div style={{ color: 'red', fontSize: '14px' }}>{<FormattedMessage id="announcement.error" />}</div>}
          {data.pdf?.filesname && <div style={{ color: 'green', fontSize: '14px' }}>{`已上傳 ${data.pdf.filesname.name}`}</div>}
          <div style={{ marginTop: '8px' }}>
            <ImageUpload
              type={'UpLoadPDF'}
              currentImageUrl={data.pdf?.url}
              storagePath={'announcements/'}
              onChange={(url) => updateData({ name: 'pdf' }, url)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonProgress loading={loading} handleClick={() => saveAnnouncement()} handleClose={handleClose} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

AnnouncementDailog.propTypes = {
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function AnnouncementVoid({ data, handleClose }) {
  const [loading, setLoading] = useState(false);

  async function deleteAnnouncement() {
    setLoading(true)
    const newData = { ...data }
    try {
      await (firebase.functions().httpsCallable('deleteAnnouncement'))({ id: newData.id, ...newData })
      setLoading(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <div style={{ padding: '20px' }}>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title"><FormattedMessage id="announcement.void.title" /></DialogTitle>
        <DialogContent>
          <FormattedMessage id="announcement.void.message" />
        </DialogContent>
        <DialogActions>
          <ButtonProgress loading={loading} handleClick={() => deleteAnnouncement()} handleClose={handleClose} />
        </DialogActions>
      </Dialog>
    </div>
  )
}

AnnouncementVoid.propTypes = {
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function Announcement({ userRight, currentUser }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const userMapping = useSelector(state => state.firebase.data.users);
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const defultData = {
    subject: '',
    pdf: {
      url: '',
    }
  }
  const [announcementData, setAnnouncementData] = useState(defultData)
  const [announcements, setAnnouncements] = useState([])
  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [openDialog, setOpenDialog] = useState(null)

  const filtereAnnouncements = currentFilter && currentFilter.text ? filterByText() : announcements


  if (currentUser.announcement !== 0) {
    updateAnnouncementCount();
  }
  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('announcements').onSnapshot(snapshot => {
      const data = []
      snapshot.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      });
      setAnnouncements(data)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.announcement' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  async function updateAnnouncementCount() {
    try {
      await (firebase.functions().httpsCallable('updateAnnouncementCount'))({ id: currentUser.key })
    } catch (ex) {
      console.log(ex)
    }
  }

  function updateData(field, value) {
    let newValue = value

    let newData = { ...announcementData, [field.name]: newValue }

    setAnnouncementData(newData)
  }

  const formatData = (user) => {
    const newData = { ...user }
    newData.name = userMapping[newData.createdBy].displayName

    return newData
  }

  const handleClose = () => {
    setAnnouncementData(defultData)
    setOpenDialog(null)
  }


  function filterByText() {
    if (currentFilter.name === 'createby') {
      return announcements.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'subject') {
      return announcements.filter(s => s.subject.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else {
      return announcements.filter(s => s.date.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const headerCells = [
    { text: 'date', sort: 'date' },
    { text: 'subject', sort: 'subject' },
    { text: 'createby', sort: 'createby' },
  ].map(c => { c.text = formatMessage({ id: `announcement.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'date', account: true },
    { field: 'subject' },
    { field: 'name' },
  ]

  const filterItems = [
    { name: 'date' },
    { name: 'subject' },
    { name: 'createby' },
  ].map(i => { i.text = formatMessage({ id: `announcement.table.${i.name}` }); return i })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif' }}>
      {openDialog && openDialog.type === 'new' && <AnnouncementDailog
        data={announcementData}
        updateData={updateData}
        handleClose={handleClose}
      />}
      {openDialog && openDialog.type === 'void' && <AnnouncementVoid
        data={openDialog.data}
        handleClose={handleClose}
      />}
      <div style={{ padding: '20px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <SimpleTableToolbar
              title={'announcement.title'}
              bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
              toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <EnhancedTableHead
                  headerCells={headerCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filtereAnnouncements.length}
                  actionButton
                />
                <TableBody>
                  {stableSort(filtereAnnouncements.map(r => formatData(r)), getComparator(order, orderBy)).map(announcement => (
                    <EnhancedTableRow
                      key={announcement.id}
                      rowCells={rowCells}
                      cellData={announcement}
                      onRowClick={() => window.open(announcement?.url, '_blank')}
                      actionIcons={<>
                        {userRight['finance-edit'] && <span>
                          <Tooltip title={formatMessage({ id: 'button.void' })}>
                            <IconButton
                              onClick={() => setOpenDialog({ type: 'void', data: announcement })}
                              size="large">
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </span>}
                      </>}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {userRight['finance-edit'] && <div>
            <FabComponent onClick={() => setOpenDialog({ type: 'new' })} />
          </div>}
        </Grid>
      </div>
    </div>
  );
}

Announcement.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    announcement: PropTypes.number.isRequired
  }),
  userRight: PropTypes.object.isRequired,
};

export default Announcement;
