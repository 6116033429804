import dayjs from 'dayjs'

const HOUR_WIDTH = 280
export const minutesToWidth = (minutes) => (
  minutes * (HOUR_WIDTH / 60)
)

export const minutesToHeight = (minutes) => (
  minutes * (80 / 60)
)

export const minutesToLeft = (minutes) => (
  minutesToWidth(minutes) + 10
)

export const minutesToTop = (minutes) => (
  minutesToHeight(minutes) - 21
)

export const timeToMinutes = (hour, minute, firstHour) => (
  ((hour - firstHour) * 60) + minute
)

export const calculateAge = (dob) => (
  dob && dayjs().diff(dob, 'years', false)
)

export const dateToYYYYMMDD = (date) => {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
}

export const dateTimeFormat = (locale, options) => {
  // To resolve: Chrome returning the "日" with numeric dates in Chinese
  if (options.day === 'numeric') {
    return { format: (date) => dayjs(date).format('D') }
  }
  return global.Intl.DateTimeFormat(locale, options)
}
