import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import { getComparator, stableSort } from '../../../modules/sort';
import EnhancedTableRow from '../../../components/EnhancedTableRow';
import EnhancedTableHead from '../../../components/EnhancedTableHead';

function Purchases({ purchaseOrders, userMapping, payments }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [loading, setLoading] = useState(false)

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date', sort: 'date' },
    { text: 'totalCost', sort: 'totalCost' },
    { text: 'totalPaid', sort: 'totalPaid' },
    { text: 'agentName', sort: 'agentName' },
    { text: 'status', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `purchaseOrder.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'sn', textColor: true, },
    { field: 'date', textColor: true, },
    { field: 'totalCost', textColor: true, },
    { field: 'totalPaid', textColor: true, },
    { field: 'agentName', textColor: true, },
    { field: 'statusName', textColor: true, },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function filterPurchaseOrders() {
    let item = [];
    purchaseOrders.forEach(po => {
      const totalCost = po.discountPrice || 0

      let newPayment = []
      if (po.payments) {
        Object.keys(po.payments).forEach(p => {
          if (payments[p]) {
            newPayment.push(payments[p])
          }
        })
      }

      const totalPaid = newPayment.reduce((acc, payment) => {
        if (payment.void) {
          return acc
        }
        return acc + (payment.collected || 0)
      }, 0)

      let statusName = ''
      let status = ''
      if (po.void) {
        status = 'void'
        if (totalPaid === po.discountPrice) {
          statusName = formatMessage({ id: 'purchaseOrder.table.collected' })
        } else if (totalPaid === 0) {
          statusName = formatMessage({ id: 'purchaseOrder.table.uncollected' })
        } else {
          statusName = formatMessage({ id: 'purchaseOrder.table.partial' })
        }

        statusName = `${statusName}(${formatMessage({ id: 'purchaseOrder.table.void' })})`
      } else {
        status = 'normal'
        if (totalPaid === po.discountPrice) {
          statusName = formatMessage({ id: 'purchaseOrder.table.collected' })
        } else if (totalPaid === 0) {
          statusName = formatMessage({ id: 'purchaseOrder.table.uncollected' })
        } else {
          statusName = formatMessage({ id: 'purchaseOrder.table.partial' })
        }
      }

      let textColor = ''
      if (po.void) {
        textColor = '#bab7b7'
      }

      const agentName = po.agent ? userMapping[po.agent.id].displayName : ''

      item.push({
        ...po,
        totalPaid,
        totalCost,
        status,
        statusName,
        agentName,
        textColor
      })
    })

    return item
  }

  async function openPurchaseOrder(purchase) {
    setLoading(true)
    const storageRef = firebase.storage().ref('purchaseOrders')
    if (purchase.pdf) {
      try {
        storageRef.child(`${purchase.patient}/${purchase.kardex || 'default'}/${purchase.id}/${purchase.pdf}`).getDownloadURL().then((url) => {
          window.open(url, '_blank')
        }, () => {
          alert('Purchase order file not found')
        })
      } catch (ex) {
        console.log(ex)
      }
    } else {
      if (purchase.payments) {
        try {
          await firebase.functions().httpsCallable('htmlToPDF')({ purchaseOrderId: purchase.id, customer: process.env.BRANCH_ENV })
          const url = await storageRef.child(`${purchase.patient}/${purchase.kardex || 'default'}/${purchase.id}/${purchase.id}.pdf`).getDownloadURL()
          if (url) {
            window.open(url, '_blank')
          } else {
            alert('Purchase order file not found')
          }
        } catch (ex) {
          console.log(ex)
        }
      }
    }
    setLoading(false)
  }

  const filterPurchaseOrder = filterPurchaseOrders()

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', marginTop: '10px' }}>
      {loading && <Backdrop open={true} style={{ zIndex: 2000, color: '#fff' }}>
        <CircularProgress disableShrink color="inherit" />
      </Backdrop>}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filterPurchaseOrder.length}
              />
              <TableBody>
                {stableSort(filterPurchaseOrder, getComparator(order, orderBy)).map(purchaseOrder => (
                  <EnhancedTableRow
                    key={purchaseOrder.id}
                    rowCells={rowCells}
                    cellData={purchaseOrder}
                    onRowClick={() => openPurchaseOrder(purchaseOrder)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

Purchases.propTypes = {
  userMapping: PropTypes.object,
  purchaseOrders: PropTypes.arrayOf(PropTypes.object.isRequired),
  payments: PropTypes.object.isRequired,
};

export default Purchases;
