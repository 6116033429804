import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
// import dayjs from 'dayjs';
import firebase from 'firebase/app';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import SmsIcon from '@mui/icons-material/Sms';

import ContextStore from '../../modules/context';
import { getComparator, stableSort } from '../../modules/sort';
import { objectToArray } from '../../modules/data'
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import ExpandButton from '../../components/ExpandButton';
import SearchBox from '../../components/SearchBox';
import ReviewSmsPage from './ReviewSmsPage'
import FabComponent from '../../components/FabComponent'
import SendSms from '../../components/SendSms';

function SmsList({ currentUser }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const [sendSmsDialog, setSendSmsDialog] = useState(false)
  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [userMapping, setUserMapping] = useState({});
  const [patientList, setPatientList] = useState(null)
  const [patientListMapping, setPatientListMapping] = useState(null)
  const [smsList, setSmsList] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const ref = firebase.database().ref('users')
    const onDataChange = ref.on('value', snapshot => {
      setUserMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      const data = objectToArray(snapshots)
      setPatientList(data)
      setPatientListMapping(snapshots)
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.smsList' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  useEffect(() => {
    const ref = firebase.database().ref('smsList')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setSmsList(objectToArray(snapshot.val()))
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  const headerCells = [
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'date' },
    { text: 'time', sort: 'time' },
    { text: 'status', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `smsList.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'createdBy' },
    { field: 'date' },
    { field: 'time' },
    { field: 'status' },
  ]

  const filterItems = [
    { name: 'createdBy' },
    { name: 'date', },
    { name: 'time' },
    { name: 'status' },
  ].map(i => { i.text = formatMessage({ id: `smsList.table.${i.name}` }); return i })

  function filterByText() {
    if (currentFilter.name === 'createdBy') {
      return smsList.filter(s => userMapping[s.createdBy] && userMapping[s.createdBy].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'date') {
      return smsList.filter(s => s.date && s.date.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'time') {
      return smsList.filter(s => s.time && s.time.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'status') {
      return smsList.filter(s => s.status && formatStatus(s.status).toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  function formatStatus(status) {
    return formatMessage({ id: `smsList.status.${status}` })
  }

  const formatData = (sms) => {
    const newData = { ...sms }
    newData.createdBy = userMapping[newData.createdBy] ? userMapping[newData.createdBy].displayName : ''
    newData.status = formatStatus(newData.status)

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const handleClose = () => {
    setSendSmsDialog(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredSms = currentFilter && currentFilter.text ? filterByText() : smsList

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif' }}>
      {sendSmsDialog && <SendSms
        dialogTitle={formatMessage({ id: 'smsList.dialog.add' })}
        handleClose={handleClose}
        patientList={patientList}
        userMapping={userMapping}
        type={'smsList'}
      />}
      <div style={{ margin: '20px' }}>
        <FabComponent icon={<SmsIcon />} onClick={() => setSendSmsDialog(true)} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <SimpleTableToolbar
              title={'noMessage'}
              bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
              toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <EnhancedTableHead
                  headerCells={headerCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredSms.length}
                  expandable
                />
                <TableBody>
                  {stableSort(filteredSms.map(r => formatData(r)), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(sms => (
                      <EnhancedTableRow
                        key={sms.id}
                        rowCells={rowCells}
                        cellData={sms}
                        expandable
                        expandContent={<ReviewSmsPage smsId={sms.id} userMapping={userMapping} patientList={patientListMapping} />}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{ display: 'flex', justifyContent: 'center' }}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredSms.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={formatMessage({ id: 'button.perPage' })}
              labelDisplayedRows={({ from, to, count }) => `第${from}至${to}列 (共${count}列)`}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

SmsList.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

export default SmsList;
