import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ImageUpload from '../../components/ImageUpload'
import LeaveType from '../../enum/LeaveType'
import DatePickerField from '../../components/DatePickerField';
import AlertDialog from '../../components/AlertDialog';
import { leaveTimeRangeCount, getVacationDate, getWeekDay } from '../../modules/uitls'
import ButtonProgress from '../../components/ButtonProgress'

const _LeaveAllType = LeaveType.filter(type => type.type === 'all');
const _LeaveType = LeaveType;

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

const Iconbutton = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  fontSize: '17px'
}));

const TableCellColor = {
  highlight: {
    color: 'rgb(0, 119, 255)'
  },
  error: {
    color: 'rgb(255, 0, 0)'
  },
}

function EditLeavePage({ currentUser }) {
  const { formatMessage } = useIntl()
  const { leaveId } = useParams()
  const history = useHistory()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [substitutes, setSubstitutes] = useState([])
  const [leaveBalance, setLeaveBalance] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [timeDialogOpen, setTimeDialogOpen] = useState(false)
  const [leaveError, setLeaveError] = useState(false)
  const [pendingLeaves, setPendingLeaves] = useState([])
  const [currentWorkTime, setCurrentWorkTime] = useState([])
  const [punchClockException, setPunchClockException] = useState({})
  const [leaveData, setLeaveData] = useState({
    leaveType: '',
    startDate: dayjs(),
    startHour: '00',
    startMinute: '00',
    endDate: dayjs(),
    endHour: '00',
    endMinute: '00',
    leaveDay: 1,
    leaveHour: 0,
    newSubstitutes: '',
    reason: '',
    img: ''
  })

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('punchClockExceptions').onSnapshot(snapshot => {
      const punchClockException = {}
      snapshot.forEach(doc => {
        const newDoc = { ...doc.data(), id: doc.id }
        punchClockException[newDoc.date] = { ...newDoc }
      });
      setPunchClockException(punchClockException)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = leaveId !== 'new' ? firebase.firestore().collection('leaves').doc(leaveId).onSnapshot(snapshot => {
      const data = snapshot.data()
      const startTime = String(data.startTime).split(':')
      const endTime = String(data.endTime).split(':')

      setLeaveData({
        leaveType: data.type,
        startDate: dayjs(data.startDate),
        startHour: startTime[0],
        startMinute: startTime[1],
        endDate: dayjs(data.endDate),
        endHour: endTime[0],
        endMinute: endTime[1],
        leaveDay: data.days,
        leaveHour: data.hours,
        newSubstitutes: data.substitute,
        reason: data.reason,
        img: data.img
      })
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [leaveId]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('departments').doc(currentUser.department).onSnapshot(snapshot => {
      const snapshots = snapshot.data()
      if (snapshots) {
        const wt = getWorkTime(leaveData.startDate, snapshots.workTime)
        setCurrentWorkTime(snapshots.workTime)
        if (leaveId === 'new') {
          const start = dayjs(`${dayjs(leaveData.startDate).format('YYYY-MM-DD')} ${wt.startHour}:${wt.startMinute}`)
          const end = dayjs(`${dayjs(leaveData.startDate).format('YYYY-MM-DD')} ${wt.endHour}:${wt.endMinute}`)
          const diffTime = end.diff(start, 'minutes')

          let hours = (diffTime - (diffTime % 60)) / 60
          let minute = diffTime % 60

          let timeData = {
            leaveHour: 0,
            leaveDay: 0
          }

          if (minute >= 30) {
            hours += 0.5
          }

          if (hours < 8) {
            if (['ibeauty'].includes(process.env.BRANCH_ENV) && currentUser.department !== 'nurse') {
              if (hours >= 5) {
                hours -= 1
              }
            } else if (['ibeautyTaichung'].includes(process.env.BRANCH_ENV) && currentUser.department !== 'financial') {
              if (hours >= 5) {
                hours -= 1
              }
            } else {
              if (hours >= 5) {
                hours -= 1
              }
            }

            timeData.leaveHour = hours
          } else if (hours >= 8) {
            timeData.leaveDay += 1
          }

          setLeaveData(leave => ({ ...leave, ...wt, ...timeData }))
        }
      }
    })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('users')
    const onDataChange = ref.on('value', snapshot => {
      const Substitutes = []
      snapshot.forEach(doc => {
        Substitutes.push({ id: doc.key, ...doc.val() })
      });
      setSubstitutes(Substitutes.filter(u => (
        u.active && !u.endedAt && u.key !== currentUser.key && u.department !== 'doctor' && currentUser.department === u.department
      )))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('leaveBalances').doc(currentUser.key).onSnapshot(snapshot => {
      setLeaveBalance(snapshot.data())
    }, err => { })

    return () => unsubscribe()
  }, [currentUser.key]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('leaves')
      .where('status', '==', 'pending')
      .where('createdBy', '==', currentUser.key)
      .onSnapshot(snapshot => {
        let snapshots = []
        snapshot.forEach(doc => {
          snapshots.push({ id: doc.id, ...doc.data() })
        })
        setPendingLeaves(snapshots)
      }, err => { })

    return () => unsubscribe()
  }, [currentUser.key]);

  function getWorkTime(date, currentWorkTime) {
    const weekDay = getWeekDay(dayjs(date))
    const workT = currentWorkTime[weekDay]

    return {
      startHour: workT?.startTime.split(':')[0] ?? '00',
      startMinute: workT?.startTime.split(':')[1] ?? '00',
      endHour: workT?.endTime.split(':')[0] ?? '00',
      endMinute: workT?.endTime.split(':')[1] ?? '00'
    }
  }

  function handleClose() {
    setDialogOpen(null)
    setTimeDialogOpen(null)
  }

  async function onLeaveEdit() {
    setLoadingApprove(true)
    const hours = (leaveData.leaveDay * 8) + leaveData.leaveHour
    let signs = 0

    if (!leaveData.leaveType) {
      setLeaveData({ ...leaveData, leaveType_err: formatMessage({ id: 'form.field.isRequired' }) })
      return setLoadingApprove(false)
    }

    if (hours === 0) {
      setLoadingApprove(false)
      return setTimeDialogOpen(true)
    }

    if (leaveData.leaveType === 'vaccination_leave' && hours > 16) {
      setLoadingApprove(false)
      return setDialogOpen(true)
    }

    for (const s of pendingLeaves) {
      if (leaveData.leaveType === s.type && !s.void) {
        signs += (s.days * 8) + s.hours
      }
    }

    if (((leaveBalance[leaveData.leaveType].balance - signs) - hours) < 0 &&
      !['business_leave',
        'bereavement_leave',
        'publicinjury_leave',
        'epidemicPreventionCare_leave',
        'vaccination_leave',
        'quarantine_leave'
      ].includes(leaveData.leaveType)) {
      setLoadingApprove(false)
      return setDialogOpen(true)
    }

    const newData = {
      type: leaveData.leaveType,
      startDate: dayjs(leaveData.startDate).format('YYYY-MM-DD'),
      startTime: `${leaveData.startHour}:${leaveData.startMinute}`,
      endDate: dayjs(leaveData.endDate).format('YYYY-MM-DD'),
      endTime: `${leaveData.endHour}:${leaveData.endMinute}`,
      reason: leaveData.reason,
      img: leaveData.img,
      days: leaveData.leaveDay,
      hours: leaveData.leaveHour,
      substitute: leaveData.newSubstitutes,
    }

    try {
      await (firebase.functions().httpsCallable('saveLeaves'))({ id: leaveId, ...newData })
      setLoadingApprove(false)
    } catch (ex) {
      console.log(ex)
    }

    history.push('/leaveOvertimeWork/leaves/applicationSent')
  }

  function handleDateChange(filed, date) {
    if (date === null || date.toString() === 'Invalid Date') {
      updateData(filed, '')
    } else {
      updateData(filed, date);
    }
  };

  function validateField(field, value) {
    if ((field.name === 'startDate' || field.name === 'endDate') && value === '') {
      return '無效的日期格式'
    }
    return ''
  }

  function updateData(field, value) {
    let newValue = value
    let err = validateField(field, value)

    let newData = { ...leaveData, [field.name]: newValue, [`${field.name}_err`]: err }
    if (field.name === 'startDate') {
      newData.endDate = value
    }

    if (['startDate', 'endDate'].includes(field.name)) {
      const newWt = getWorkTime(newData.startDate, currentWorkTime)
      newData = { ...newData, ...newWt }
    }

    const leaveOfTime = leaveTimeRangeCount(newData, currentUser.department, currentWorkTime, punchClockException)
    if (!leaveOfTime) {
      setLeaveError(true)
    } else {
      setLeaveError(false)

      newData.leaveDay = leaveOfTime.day
      newData.leaveHour = leaveOfTime.hour
    }

    setLeaveData(newData)
  }

  function formatData(value) {
    const newData = { ...value }
    let newSigning = {
      days: 0,
      hours: 0,
      balance: 0
    }

    let newVoiding = {
      days: 0,
      hours: 0
    }

    for (const s of pendingLeaves) {
      if (s.type === newData.type) {
        if (s.void) {
          newVoiding.days += s.days
          newVoiding.hours += s.hours
        } else {
          newSigning.days += s.days
          newSigning.hours += s.hours
        }
      }
    }

    if (newData) {
      let used = newData.used
      let balance = newData.balance
      let sign = (newSigning.days * 8) + newSigning.hours
      if (newData.type === leaveData.leaveType) {
        used = newData.used + ((leaveData.leaveDay * 8) + leaveData.leaveHour)
        balance = newData.balance - ((leaveData.leaveDay * 8) + leaveData.leaveHour)

        if ((leaveData.leaveDay || leaveData.leaveHour) > 0) {
          newData.status = (balance - sign) >= 0 ? 'highlight' : 'error'
        }
      }

      if (newData.type === leaveData.leaveType &&
        ['business_leave',
          'bereavement_leave',
          'publicinjury_leave',
          'epidemicPreventionCare_leave',
          'vaccination_leave',
          'quarantine_leave'
        ].includes(newData.type)) {
        balance = 0;
        if ((leaveData.leaveDay || leaveData.leaveHour) > 0) {
          newData.status = 'highlight'
        }
      }

      let newEarned = newData.earned ? { days: (newData.earned - (newData.earned % 8)) / 8, hours: newData.earned % 8 } : { days: 0, hours: 0 };
      let newUsed = used ? { days: (used - (used % 8)) / 8, hours: used % 8 } : { days: 0, hours: 0 };
      let newBalance = newData.earned - used;

      const balanceCount = (balance - ((newSigning.days * 8) + newSigning.hours))
      if (['business_leave',
        'bereavement_leave',
        'publicinjury_leave',
        'epidemicPreventionCare_leave',
        'vaccination_leave',
        'quarantine_leave'
      ].includes(newData.type)) {
        newBalance = { days: 0, hours: 0 };
      } else {
        newBalance = { days: (balanceCount - (balanceCount % 8)) / 8, hours: balanceCount % 8 };
      }

      newData.earned = `${newEarned.days}日${newEarned.hours}小時`
      newData.used = `${newUsed.days}日${newUsed.hours}小時`
      newData.balance = `${newBalance.days}日${newBalance.hours}小時`
      newData.signing = `${newSigning.days}日${newSigning.hours}小時`
      newData.voiding = `${newVoiding.days}日${newVoiding.hours}小時`
    }

    return newData
  }

  return (
    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif', padding: '0 20px', }}>
      <div style={{ fontSize: '40px', marginBottom: '20px', marginTop: '20px' }}>
        {leaveId !== 'new' ? formatMessage({ id: 'leaves.editLeave' }) : formatMessage({ id: 'leaves.applyForLeave' })}
      </div>
      {dialogOpen &&
        <AlertDialog
          dialogTital={formatMessage({ id: 'leaves.leaveAlert.insufficientLeaveBalanceTitle' })}
          dialogMsg={
            leaveData.leaveType === 'vaccination_leave' ?
              formatMessage({ id: 'leaves.leaveAlert.vaccinationAlert' }) :
              formatMessage({ id: 'leaves.leaveAlert.insufficientLeaveBalanceContent' })}
          handleClose={handleClose}
        />}
      {timeDialogOpen &&
        <AlertDialog
          dialogTital={formatMessage({ id: 'leaves.leaveAlert.leaveTimeAlertTitle' })}
          dialogMsg={formatMessage({ id: 'leaves.leaveAlert.leaveTimeAlertContent' })}
          handleClose={handleClose}
        />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                type="text"
                size="small"
                label={formatMessage({ id: 'leaves.leaveForm.timeOffType' })}
                variant="outlined"
                required
                error={leaveData['leaveType_err'] ? true : false}
                helperText={leaveData.leaveType_err}
                fullWidth
                onChange={e => updateData({ name: 'leaveType' }, e.target.value)}
                value={leaveData.leaveType}
              >
                {currentUser.gender !== 'male' ? _LeaveType.map((type, idx) => {
                  return <MenuItem key={`${type.value}-${idx}`} value={type.value}>{type.name}</MenuItem>
                }) : _LeaveAllType.map((type, idx) => {
                  return <MenuItem key={`${type.value}-${idx}`} value={type.value}>{type.name}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={10} md={4}>
              <DatePickerField
                required
                fullWidth
                shouldDisableDate={(day) => getVacationDate(day.format('YYYY-MM-DD'), punchClockException, currentUser.department)}
                label={formatMessage({ id: 'leaves.leaveForm.startDateTime' })}
                value={leaveData.startDate}
                onChange={date => handleDateChange({ name: 'startDate' }, date)}
                invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              />
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'leaves.leaveForm.leaveHour' })}
                fullWidth
                value={leaveData.startHour}
                onChange={e => updateData({ name: 'startHour' }, e.target.value)}
              >
                {hours.map((hour, idx) => {
                  return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'leaves.leaveForm.leaveMinute' })}
                fullWidth
                value={leaveData.startMinute}
                onChange={e => updateData({ name: 'startMinute' }, e.target.value)}
              >
                {minutes.map((minute, idx) => {
                  return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={10} md={4}>
              <DatePickerField
                required
                fullWidth
                shouldDisableDate={(day) => getVacationDate(day.format('YYYY-MM-DD'), punchClockException, currentUser.department)}
                label={formatMessage({ id: 'leaves.leaveForm.endDateTime' })}
                value={leaveData.endDate}
                onChange={date => handleDateChange({ name: 'endDate' }, date)}
                minDate={dayjs(leaveData.startDate).toDate()}
                minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
                invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              // minDate={new Date()}
              />
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'leaves.leaveForm.leaveHour' })}
                fullWidth
                value={leaveData.endHour}
                onChange={e => updateData({ name: 'endHour' }, e.target.value)}
              >
                {hours.map((hour, idx) => {
                  return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'leaves.leaveForm.leaveMinute' })}
                fullWidth
                value={leaveData.endMinute}
                onChange={e => updateData({ name: 'endMinute' }, e.target.value)}
              >
                {minutes.map((minute, idx) => {

                  return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            {!leaveError ? <>
              <Grid item xs={12} sm={6} md={3}>
                <Iconbutton>
                  <div>{formatMessage({ id: 'leaves.leaveForm.leaveDays' })}</div>
                  <div style={{ marginLeft: '10px' }}>{leaveData.leaveDay}</div>
                </Iconbutton>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Iconbutton>
                  <div>{formatMessage({ id: 'leaves.leaveForm.leaveHours' })}</div>
                  <div style={{ marginLeft: '10px' }}>{leaveData.leaveHour}</div>
                </Iconbutton>
              </Grid></> : <Grid item xs={12} sm={6} md={3}><div style={{ color: 'red' }}>{formatMessage({ id: 'form.date.leaveError' })}</div></Grid>}
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                fullWidth
                label={formatMessage({ id: 'leaves.leaveForm.substitute' })}
                value={leaveData.newSubstitutes}
                onChange={e => updateData({ name: 'newSubstitutes' }, e.target.value)}
              >
                {substitutes.map((user) => {
                  return <MenuItem key={user.id} value={user.id}>{user.displayName}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={12} md={7}>
              <TextField
                type="text"
                size="small"
                variant="outlined"
                fullWidth
                multiline
                label={formatMessage({ id: 'leaves.leaveForm.reason' })}
                value={leaveData.reason}
                onChange={e => updateData({ name: 'reason' }, e.target.value)}
              >
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={12} md={12}>
              <div>
                {formatMessage({ id: 'leaves.leaveForm.leavecertificate' })}
                <ImageUpload
                  currentImageUrl={leaveData.img}
                  storagePath={`leavesImages/${currentUser.key}/${dayjs().format('YYYY-MM-DD HH:MM')}`}
                  onChange={(url) => updateData({ name: 'img' }, url)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '50px' }}>
              <ButtonProgress
                handleClick={() => onLeaveEdit()}
                handleClose={() => history.push('/leaveOvertimeWork/leaves')}
                loading={loadingApprove}
                buttonText={'button.submit'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <div style={{ fontSize: '30px', marginBottom: '20px' }}>
                {formatMessage({ id: 'leaves.leaveForm.currentBalances' })}
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.type' })}</TableCell>
                        <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.earned' })}</TableCell>
                        <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.used' })}</TableCell>
                        <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.signing' })}</TableCell>
                        <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.voiding' })}</TableCell>
                        <TableCell align="left">{formatMessage({ id: 'leaves.leaveForm.balance' })}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentUser.gender === 'male' ? _LeaveAllType.map(type => ({ name: type.name, ...formatData({ type: type.value, ...leaveBalance[type.value] }) })).map(i => (
                        <TableRow key={i.name}>
                          <TableCell sx={TableCellColor[i.status]} component="th" scope="row">
                            {i.name}
                          </TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.earned}</TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.used}</TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.signing}</TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.voiding}</TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.balance}</TableCell>
                        </TableRow>
                      )) : _LeaveType.map(type => ({ name: type.name, ...formatData({ type: type.value, ...leaveBalance[type.value] }) })).map(i => (
                        <TableRow key={i.name}>
                          <TableCell sx={TableCellColor[i.status]} component="th" scope="row">
                            {i.name}
                          </TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.earned}</TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.used}</TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.signing}</TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.voiding}</TableCell>
                          <TableCell sx={TableCellColor[i.status]} align="left">{i.balance}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

EditLeavePage.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

export default EditLeavePage;
