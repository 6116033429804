const i18n = {
  'button': {
    'cancel': '取消',
    'add': '新增',
    'addLeave': '申請休假',
    'addOvertime': '申請加班',
    'edit': '編輯',
    'save': '儲存',
    'apply': '套用',
    'ok': '確定',
    'submit': '送出',
    'approve': '批准',
    'reject': '否決',
    'void': '作廢',
    'confirm': '確認',
    'close': '關閉',
    'export': '匯出',
    'delete': '刪除',
    'punchClockRevise': '補登打卡記錄',
    'info': '資訊',
    'assignments': '表單',
    'previous': '上一張',
    'next': '下一張',
    'perPage': '每頁列數',
    'switch': '轉換',
    'transfer': '轉讓',
    'refund': '退費',
    'salary': '薪資設定',
    'pdf': '開啟PDF',
    'saveEdit': '確認並編輯'
  },
}
export default i18n
