import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  document: {
    width: '840px',
    height: '1189px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #e2e6ee',
    position: 'relative',
    margin: ' 0 auto 20px auto',
    '& img': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    // ['@media print']: {
    //   width: '100vw',
    //   margin: '-40px 0 -40px 0',
    //   minHeight: 'auto',
    //   position: 'static',
    //   border: 'none',
    //   transform: 'scale(1) !important',
    //   '& img ': {
    //     height: 'auto'
    //   }
    // }
  },
  titleInfo: {
    width: '100px',
    height: '100px',
    fontSize: '40px',
    fontWeight: 'bold',
    position: 'relative',
    margin: '25px auto 10px auto',
    right: '350px',
    '& label': {
      position: 'absolute'
    }
  },
  titleInfoDate: {
    width: '100px',
    height: '100px',
    fontSize: '18px',
    color: 'gray',
    position: 'relative',
    margin: '-60px auto 0 auto',
    right: '350px',
    '& label': {
      position: 'absolute'
    }
  },
  stampDoctor: {
    width: '100px',
    height: '80px',
    position: 'relative',
    margin: '-150px auto 20px auto',
    left: '220px',
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%'
    }
  },
  stampNurse: {
    width: '75px',
    height: '30px',
    position: 'relative',
    margin: '0 auto 20px auto',
    left: '120px',
    top: '35px',
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%'
    }
  },
  label: {
    position: 'absolute',
    margin: '0 auto 20px auto',
    top: '-30px',
    fontSize: '15px',
    color: '#9c9696',
    fontWeight: 'bold'
  }
});

function Document({ image, underImages, stampNurse, patient, userMapping, kardex, titleInfo, doctorSeal, nurseSeal, photoImage }) {
  const classes = useStyles();
  let dotorStamp

  if (userMapping[kardex.doctor]) {
    dotorStamp = userMapping[kardex.doctor].bigStampUrl
  }

  return (
    <div className={classes.document}>
      <img alt='' src={underImages} />
      {image && Object.keys(image).map((date, key) => {
        return Object.keys(image[date]).map((user, key) => {
          return <img alt='' key={key} src={image[date][user]} />
        })
      })}
      {photoImage && <img alt='' style={{ maxHeight: '270px', maxWidth: '270px' }} src={photoImage} />}
      {titleInfo && <div className={classes.titleInfo}>{patient.name}</div>}
      {titleInfo && <div className={classes.titleInfoDate}>{kardex.date}</div>}
      {doctorSeal && dotorStamp && <div className={classes.stampDoctor}>
        <img alt='' src={dotorStamp} />
        {nurseSeal && stampNurse && <div className={classes.stampNurse}>
          <div className={classes.label}><label >護理師</label></div>
          <img alt='' src={stampNurse} />
        </div>}
      </div>}
    </div>
  );
}

Document.propTypes = {
  image: PropTypes.object,
  underImages: PropTypes.string,
  stampNurse: PropTypes.string,
  patient: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  kardex: PropTypes.object.isRequired,
  titleInfo: PropTypes.bool.isRequired,
  doctorSeal: PropTypes.bool,
  nurseSeal: PropTypes.bool,
  photoImage: PropTypes.string
};

export default Document;
