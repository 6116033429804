import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import firebase from 'firebase/app';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { objectToArray } from '../../modules/data'
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import TabContainer from '../../containers/TabContainer/TabContainer';
import SelectPatientDialog from '../../components/SelectPatientDialog';
import ConsumePage from './ConsumePage';
import PurchasePage from './PurchasePage';
import { formatName } from '../../modules/uitls'
import ContextStore from '../../modules/context';

function Manages() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [openPatientDailog, setOpenPatientDailog] = useState(false)
  const [patients, setPatients] = useState({})
  const [filterPatient, setFilterPatient] = useState(null)
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [purchaseMapping, setPurchaseMapping] = useState({})
  const [payments, setPayments] = useState({})
  const [purchasedProducts, setPurchasedProducts] = useState([])
  const [storedValueCard, setStoredValueCard] = useState({})
  const [productMapping, setProductMapping] = useState({})
  const userMapping = useSelector(state => state.firebase.data.users);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      setPatients(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('products')
    const onDataChange = ref.on('value', snapshot => {
      setProductMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    if (filterPatient) {
      const ref = firebase.database().ref('purchaseOrders').orderByChild('patient').equalTo(filterPatient)
      const onDataChange = ref.on('value', snapshot => {
        setPurchaseMapping(snapshot.val())
        setPurchaseOrders(objectToArray(snapshot.val()))
      });
      return () => ref.off('value', onDataChange)
    }
  }, [filterPatient]);

  useEffect(() => {
    if (filterPatient) {
      const ref = firebase.database().ref('purchasedProducts').orderByChild('patient').equalTo(filterPatient)
      const onDataChange = ref.on('value', snapshot => {
        setPurchasedProducts(objectToArray(snapshot.val()))
      });
      return () => ref.off('value', onDataChange)
    }
  }, [filterPatient]);

  useEffect(() => {
    if (filterPatient) {
      const ref = firebase.database().ref('storedValueCard').child(filterPatient)
      const onDataChange = ref.on('value', snapshot => {
        setStoredValueCard(snapshot.val())
      });
      return () => ref.off('value', onDataChange)
    }
  }, [filterPatient]);

  useEffect(() => {
    const ref = firebase.database().ref('payments').orderByChild('patient').equalTo(filterPatient)
    const onDataChange = ref.on('value', snapshot => {
      setPayments(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, [filterPatient]);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.manages' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  const tabs = [
    {
      label: formatMessage({ id: 'manage.tab.purchase' }),
      component: <PurchasePage
        purchaseOrders={purchaseOrders}
        purchasedProducts={purchasedProducts}
        userMapping={userMapping}
        payments={payments || {}}
        storedValueCard={storedValueCard}
        productMapping={productMapping}
      />
    },
    {
      label: formatMessage({ id: 'manage.tab.consume' }),
      component: <ConsumePage
        purchases={purchasedProducts}
        purchaseMapping={purchaseMapping}
        payments={payments || {}}
      />
    }
  ];

  const onTabSelected = (tabIndex) => {
    setActiveTabIndex(tabIndex)
  };

  let defaultSelect = 0

  const handleSelectPatienClose = () => {
    setOpenPatientDailog(false)
  }

  const handleSelectPatienSave = (patient) => {
    setFilterPatient(patient.id)
  }

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', }}>
      {openPatientDailog && <SelectPatientDialog
        handleClose={handleSelectPatienClose}
        handleSave={handleSelectPatienSave}
        patients={objectToArray(patients) || []}
        dialogTitle={formatMessage({ id: 'selectReferrerDialog.title' })}
      />}
      <div style={{ padding: '40px 20px', }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <SimpleTableToolbar
              title={'manage.title'}
              toolbox={<>
                <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Grid item>
                    <TextField
                      value={formatName(patients[filterPatient]?.name ?? '')}
                      label={formatMessage({ id: 'manage.selectPatient' })}
                      variant="outlined"
                      onClick={() => setOpenPatientDailog(true)}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </>}
            />
          </Grid>
          <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
        </Grid>
      </div>
    </div>
  );
}

export default Manages;
