import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import GetAppIcon from '@mui/icons-material/GetApp';

import { objectToArray } from '../../modules/data'
import { getComparator, stableSort } from '../../modules/sort';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../components/SimpleTableToolbar'
import DateRangePickerField from '../../components/DateRangePickerField';
import ExpandButton from '../../components/ExpandButton';
import SearchBox from '../../components/SearchBox';
import { formatName } from '../../modules/uitls';
import ButtonProgress from '../../components/ButtonProgress';

function FinanceBonusDialog({ handleClose, data }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false)
  const purchaseOrderId = data.id
  const [bonus, setBonus] = useState({
    typeA: data.bonus?.a ?? '',
    typeB: data.bonus?.b ?? '',
    typeC: data.bonus?.c ?? '',
  })

  function handleCheckChange(event, field) {
    let newValue = event.target.checked ? dayjs().format('YYYY-MM-DD') : ''
    let newData = { ...bonus, [field.name]: newValue }

    setBonus(newData)
  };

  async function handleSave() {
    setLoadingApprove(true)
    try {
      await (firebase.functions().httpsCallable('saveFinanceBonus'))({ id: purchaseOrderId, ...bonus })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  const fields = [
    { name: 'typeA', order: 0 },
    { name: 'typeB', order: 1 },
    { name: 'typeC', order: 2 }
  ]
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'finance.purchaseBonus.detail.title.setUp' })}</DialogTitle>
      <DialogContent>
        {fields.map(field => {
          return <FormControlLabel
            key={field.name}
            control={<Checkbox
              checked={bonus[field.name] !== '' ? true : false}
              disabled={bonus[field.name] === 'done'}
              onChange={e => handleCheckChange(e, field)}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />}
            label={formatMessage({ id: `finance.purchaseBonus.detail.bonus.${field.name}` })}
          />
        })}
      </DialogContent>
      <DialogActions>
        <ButtonProgress handleClick={() => handleSave()} handleClose={handleClose} loading={loadingApprove} />
      </DialogActions>
    </Dialog>
  );
}

FinanceBonusDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

function FinanceBonusDetail({ data, productMapping }) {
  const { formatMessage } = useIntl()
  const [openDialog, setOpenDialog] = useState(false)
  const products = []
  let isPoints = false

  if (data.products) {
    for (const p of Object.keys(data.products)) {
      const product = productMapping[data.products[p].productId]
      if (product.code.substr(0, 1) === '9') {
        for (const id of Object.keys(product.package)) {
          const newProduct = productMapping[id]
          products.push({
            id: id,
            code: newProduct.code,
            name: newProduct.name,
            amount: data.products[p].amount * product.package[id].amount,
            discountPrice: (Number(data.products[p].discountPrice) * (product.package[id].ratio / 100)) / product.package[id].amount,
            totalPrice: Number(data.products[p].discountPrice) * (product.package[id].ratio / 100) * data.products[p].amount,
          })

          if (newProduct.cat1 === 'DP' && !newProduct.salesBonusType ? true : false) {
            isPoints = true
          }
        }
      } else {
        products.push({
          id: p,
          code: product.code,
          name: product.name,
          amount: data.products[p].amount,
          discountPrice: Number(data.products[p].discountPrice),
          totalPrice: Math.round(Number(data.products[p].amount) * Number(data.products[p].discountPrice)),
        })

        if (product.cat1 === 'DP' && !product.salesBonusType ? true : false) {
          isPoints = true
        }
      }
    }
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const headerCells = [
    { text: 'code' },
    { text: 'name' },
    { text: 'amount' },
    { text: 'discountPrice' },
    { text: 'totalPrice' },
  ].map(c => { c.text = formatMessage({ id: `finance.purchaseBonus.detail.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'amount' },
    { field: 'discountPrice' },
    { field: 'totalPrice' },
  ]

  const _fields = []
  if (data.bonus && data.bonus.a) {
    _fields.push({ name: 'typeA', order: 0 })
  }

  if (data.bonus && data.bonus.b) {
    _fields.push({ name: 'typeB', order: 1 })
  }

  if (data.bonus && data.bonus.c) {
    _fields.push({ name: 'typeC', order: 2 })
  }

  const formatData = (data) => {
    const newData = { ...data }
    newData.discountPrice = newData.discountPrice.toFixed(2).replace(/[.,]00$/, '')
    return newData
  }

  const fields = _fields.sort((a, b) => { return a.order - b.order })

  return (
    <Grid container spacing={2}>
      {openDialog && <FinanceBonusDialog
        handleClose={handleClose}
        data={data}
      />}
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ marginTop: '8px' }}>
          <Typography variant="button" component="div">
            <FormattedMessage id="finance.purchaseBonus.detail.title.product" />:
          </Typography>
        </div>
        <TableContainer sx={{ marginBottom: '8px' }} component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              rowCount={products.length}
            />
            <TableBody>
              {products.map(p => formatData(p)).map(product => (
                <EnhancedTableRow
                  key={product.id}
                  rowCells={rowCells}
                  cellData={product}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {['reborn', 'lexcellence'].includes(process.env.BRANCH_ENV) && !isPoints && <Grid item xs={12} sm={12} md={12}>
        <div style={{ marginTop: '8px' }}>
          <Typography variant="button" component="div">
            <FormattedMessage id="finance.purchaseBonus.detail.title.purchaseBonus" />:
          </Typography>
        </div>
        {fields.map(field => {
          return <Chip
            variant="outlined"
            key={field.name}
            sx={{ margin: '5px 20px 0 0', padding: '0 10px' }}
            color="primary"
            label={formatMessage({ id: `finance.purchaseBonus.detail.bonus.${field.name}` })}
          />
        })}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '4px' }}>
          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
            {formatMessage({ id: 'button.edit' })}
          </Button>
        </div>
      </Grid>}
    </Grid>
  );
}

FinanceBonusDetail.propTypes = {
  data: PropTypes.object.isRequired,
  productMapping: PropTypes.object.isRequired
};

function FinanceBonus() {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [expand, setExpand] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM') + `${dayjs().daysInMonth()}`)
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const userMapping = useSelector(state => state.firebase.data.users);
  const [payments, setPayments] = useState(null)
  const [productMapping, setProductMapping] = useState({})
  const [patinetMapping, setPatientMapping] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const ref = firebase.database().ref('purchaseOrders').orderByChild('date').startAt(startDate).endAt(endDate)
    const onDataChange = ref.on('value', snapshot => {
      setPurchaseOrders(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, [startDate, endDate]);

  useEffect(() => {
    const date = dayjs(startDate).valueOf()
    if (date) {
      const ref = firebase.database().ref('payments').orderByChild('createdAt').startAt(date)
      const onDataChange = ref.on('value', snapshot => {
        const snapshots = snapshot.val()
        if (snapshots) {
          setPayments(snapshots)
        } else {
          setPayments({})
        }
      });
      return () => ref.off('value', onDataChange)
    }
  }, [startDate]);

  useEffect(() => {
    const ref = firebase.database().ref('products')
    const onDataChange = ref.on('value', snapshot => {
      setProductMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      setPatientMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  if (!payments) {
    return ('Loading...')
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const formatData = (data) => {
    const newData = { ...data }
    newData.patientName = formatName(patinetMapping[newData.patient]?.name ?? '')
    return newData
  }

  function exportProfile() {
    const rows = ['營收單號,客戶,日期,總金額,已收金額,營收單負責人,付款方式,備註,付款狀態']
    for (const d of filterData) {
      if (d.payments) {
        for (const p of Object.keys(d.payments)) {
          rows.push([
            d.sn,
            patinetMapping[d.patient]?.name ?? ' ',
            d.date,
            d.totalCost,
            payments[p].collected,
            userMapping[payments[p].agent.id].displayName,
            formatMessage({ id: `finance.purchaseBonus.table.payment.${payments[p].paymentType}` }),
            payments[p].note,
            formatMessage({ id: `finance.purchaseBonus.${payments[p].installment}` }),
          ])
        }
      } else {
        rows.push([
          d.sn,
          patinetMapping[d.patient]?.name ?? ' ',
          d.date,
          d.totalCost,
          d.totalPaid,
          d.agentName,
          ' ',
          ' ',
          d.status
        ])
      }

      rows.push('品號,名稱,數量,單價,總價')

      if (d.products) {
        for (const p of Object.keys(d.products)) {
          const product = productMapping[d.products[p].productId]
          rows.push([
            product.code,
            product.name,
            d.products[p].amount,
            d.products[p].discountPrice,
            d.products[p].discountPrice * d.products[p].amount
          ])
        }
      }
    }

    try {
      const content = rows.join('\n')
      const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const aExport = document.createElement('a')
      aExport.href = csvUrl
      aExport.target = '_blank'
      aExport.download = 'PurchaseOreder_' + dayjs().format('YYYY-MM-DD-HHmmss') + '.csv'
      aExport.click()
    } catch (error) {
      console.error(error) // eslint-disable-line
      alert('An error occurred. Please refresh and try again.')
    }
  }

  function formatPurchaseOrders(data) {
    let item = [];
    data.forEach(po => {
      if (!po.void) {
        const totalCost = po.discountPrice || 0

        let newPayment = []
        if (po.payments) {
          Object.keys(po.payments).forEach(p => {
            if (payments[p]) {
              newPayment.push(payments[p])
            }
          })
        }

        const totalPaid = newPayment.reduce((acc, payment) => {
          if (payment.void) {
            return acc
          }
          return acc + (payment.collected || 0)
        }, 0)
        let typeData = {}
        const paymentType = newPayment.reduce((acc, cur) => {
          if (cur.paymentType) {
            if (!typeData[cur.paymentType]) {
              typeData[cur.paymentType] = true
              acc.push(formatMessage({ id: `finance.purchaseBonus.table.payment.${cur.paymentType}` }))
            }
          }

          return acc
        }, []).join(', ')

        let status = ''

        if (totalPaid === po.discountPrice) {
          status = formatMessage({ id: 'finance.purchaseBonus.collected' })
        } else if (totalPaid === 0) {
          status = formatMessage({ id: 'finance.purchaseBonus.uncollected' })
        } else {
          status = formatMessage({ id: 'finance.purchaseBonus.partial' })
        }

        const agentName = po.agent ? userMapping[po.agent.id].displayName : ''

        item.push({
          ...po,
          totalPaid,
          totalCost,
          status,
          agentName,
          paymentType
        })
      }
    })

    return item
  }

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'patientName', sort: 'patientName' },
    { text: 'date', sort: 'date' },
    { text: 'totalCost', sort: 'totalCost' },
    { text: 'totalPaid', sort: 'totalPaid' },
    { text: 'agentName', sort: 'agentName' },
    { text: 'paymentType', sort: 'paymentType' },
    { text: 'status', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `finance.purchaseBonus.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'sn' },
    { field: 'patientName' },
    { field: 'date' },
    { field: 'totalCost' },
    { field: 'totalPaid' },
    { field: 'agentName' },
    { field: 'paymentType' },
    { field: 'status' },
  ]

  const filterItems = [
    { name: 'sn' },
    { name: 'date' },
    { name: 'agentName' },
    { name: 'patientName' },
  ].map(i => { i.text = formatMessage({ id: `finance.purchaseBonus.table.${i.name}` }); return i })

  async function openPurchaseOrder(purchase) {
    setLoading(true)
    const storageRef = firebase.storage().ref('purchaseOrders')
    if (purchase.pdf) {
      try {
        storageRef.child(`${purchase.patient}/${purchase.kardex || 'default'}/${purchase.id}/${purchase.pdf}`).getDownloadURL().then((url) => {
          window.open(url, '_blank')
        }, () => {
          alert('Purchase order file not found')
        })
      } catch (ex) {
        console.log(ex)
      }
    } else {
      try {
        await firebase.functions().httpsCallable('htmlToPDF')({ purchaseOrderId: purchase.id, customer: process.env.BRANCH_ENV })
        const url = await storageRef.child(`${purchase.patient}/${purchase.kardex || 'default'}/${purchase.id}/${purchase.id}.pdf`).getDownloadURL()
        if (url) {
          window.open(url, '_blank')
        } else {
          alert('Purchase order file not found')
        }
      } catch (ex) {
        console.log(ex)
      }
    }
    setLoading(false)
  }

  function filterByText(data) {
    if (currentFilter.name === 'sn') {
      return data.filter(s => s.sn.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'date') {
      return data.filter(s => s.date.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'agentName') {
      return data.filter(s => s.agentName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'patientName') {
      return data.filter(s => patinetMapping[s.patient].name.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatPurchaseOrder = formatPurchaseOrders(purchaseOrders)
  const filterData = currentFilter && currentFilter.text ? filterByText(formatPurchaseOrder) : formatPurchaseOrder

  return (
    <Grid container spacing={1} sx={{ marginTop: '10px' }}>
      {loading && <Backdrop open={true} sx={{ zIndex: 2000, color: '#fff' }}>
        <CircularProgress disableShrink color="inherit" />
      </Backdrop>}
      <Grid item xs={12} sm={12} md={12}>
        <SimpleTableToolbar
          title={'finance.purchaseBonus.table.title'}
          bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
          toolbox={<Grid container spacing={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={12} sm={1} md={1}>
              <IconButton
                sx={{ fontSize: '14px', alignSelf: 'center', color: '#666666' }}
                onClick={() => exportProfile()}
                size="large">
                <GetAppIcon />
                {formatMessage({ id: 'button.export' })}
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <DateRangePickerField
                startDate={startDate}
                endDate={endDate}
                onStartDateChanged={setStartDate}
                onEndDateChanged={setEndDate}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
            </Grid>
          </Grid>}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ marginTop: '4px' }} aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filterData.length}
              expandable
              actionButton
            />
            <TableBody>
              {stableSort(filterData.map(r => formatData(r)), getComparator(order, orderBy)).map(purchase => (
                <EnhancedTableRow
                  key={purchase.id}
                  rowCells={rowCells}
                  cellData={purchase}
                  expandable
                  expandContent={<FinanceBonusDetail data={purchase} productMapping={productMapping} />}
                  actionIcons={
                    purchase.payments ? <Tooltip title={formatMessage({ id: 'button.pdf' })}>
                      <IconButton onClick={() => openPurchaseOrder(purchase)} size="large">
                        <PdfIcon fontSize="small" />
                      </IconButton>
                    </Tooltip> : <div />
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default FinanceBonus;
