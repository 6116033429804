import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from '../../../modules/sort';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import EnhancedTableRow from '../../../components/EnhancedTableRow';
import SimpleTableToolbar from '../../../components/SimpleTableToolbar'

function PurchaseList({ purchaseOrders, paymentMapping, patientMapping, userMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerCells = [
    { text: 'sn' },
    { text: 'patient' },
    { text: 'total' },
    { text: 'cash' },
    { text: 'swipe' },
    { text: 'wireTransfer' },
    { text: 'mktExpence' },
    { text: 'points' },
    { text: 'benefit' },
    { text: 'thirdParty' },
    { text: 'paid' },
    { text: 'salesRep' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `report.turnover.${c.text}` }); return c })

  const rowCells = [
    { field: 'sn' },
    { field: 'patient' },
    { field: 'total' },
    { field: 'cash' },
    { field: 'swipe' },
    { field: 'wireTransfer' },
    { field: 'mktExpence' },
    { field: 'points' },
    { field: 'benefit' },
    { field: 'thirdParty' },
    { field: 'paid' },
    { field: 'salesRep' },
    { field: 'note' },
  ]

  let totalData = {
    total: 0,
    cash: 0,
    swipe: 0,
    wireTransfer: 0,
    mktExpence: 0,
    points: 0,
    benefit: 0,
    thirdParty: 0,
    collected: 0
  }

  const formatData = (data) => {
    const newData = { ...data }
    newData.patient = patientMapping[newData.patient]?.name
    newData.total = newData.discountPrice
    newData.salesRep = newData.agent && userMapping[newData.agent?.id].displayName

    let totalPaid = 0;
    if (newData.payments) {
      totalData.total += newData.total
      for (const key of Object.keys(newData.payments)) {
        if (paymentMapping[key]) {
          let count = newData[paymentMapping[key].paymentType] || 0
          if (paymentMapping[key].paymentType === 'cash') {
            newData.cash = count + paymentMapping[key].collected
            totalPaid += paymentMapping[key].collected
            totalData.cash += paymentMapping[key].collected
            totalData.collected += paymentMapping[key].collected
          } else if (paymentMapping[key].paymentType === 'wireTransfer') {
            newData.wireTransfer = newData?.wireTransfer ?? 0 + paymentMapping[key].collected
            totalPaid += paymentMapping[key].collected
            totalData.wireTransfer += paymentMapping[key].collected
            totalData.collected += paymentMapping[key].collected
          } else if (paymentMapping[key].paymentType === 'mktExpence') {
            newData.mktExpence = newData?.mktExpence ?? 0 + paymentMapping[key].collected
            totalPaid += paymentMapping[key].collected
            totalData.mktExpence += paymentMapping[key].collected
            totalData.collected += paymentMapping[key].collected
          } else if (paymentMapping[key].paymentType === 'points') {
            newData.points = newData?.points ?? 0 + paymentMapping[key].collected
            totalPaid += paymentMapping[key].collected
            totalData.points += paymentMapping[key].collected
            totalData.collected += paymentMapping[key].collected
          } else if (paymentMapping[key].paymentType === 'benefit') {
            newData.benefit = newData?.benefit ?? 0 + paymentMapping[key].collected
            totalPaid += paymentMapping[key].collected
            totalData.benefit += paymentMapping[key].collected
            totalData.collected += paymentMapping[key].collected
          } else if (['ecPay', 'wechatPay', 'alipay', 'unionpay'].includes(paymentMapping[key].paymentType)) {
            newData.thirdParty = newData?.thirdParty ?? 0 + paymentMapping[key].collected
            totalPaid += paymentMapping[key].collected
            totalData.thirdParty += paymentMapping[key].collected
            totalData.collected += paymentMapping[key].collected
          } else if (['visa', 'master', 'jcb', 'online', 'ae'].includes(paymentMapping[key].paymentType)) {
            newData.swipe = newData?.swipe ?? 0 + paymentMapping[key].collected
            totalPaid += paymentMapping[key].collected
            totalData.swipe += paymentMapping[key].collected
            totalData.collected += paymentMapping[key].collected
          }
        }
      }
    }

    newData.paid = totalPaid
    return newData
  }

  return (
    <Grid item xs={12} sm={12} md={12} sx={{ padding: '10px' }}>
      <SimpleTableToolbar
        title={'report.turnover.title'}
      />
      <TableContainer component={Paper}>
        <Table size="small" sx={{ marginTop: '4px' }} aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={purchaseOrders.length}
          />
          <TableBody>
            {stableSort(purchaseOrders.map(p => formatData(p)), getComparator(order, orderBy)).map(purchase => (
              purchase.payments && <EnhancedTableRow
                key={purchase.id}
                rowCells={rowCells}
                cellData={purchase}
              />
            ))}
            <TableRow>
              <TableCell colSpan={2}>{formatMessage({ id: 'report.turnover.itemTotal' })}</TableCell>
              {Object.keys(totalData).map(data => {
                return <TableCell key={data}>{totalData[data] > 0 && totalData[data]}</TableCell>
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

PurchaseList.propTypes = {
  purchaseOrders: PropTypes.arrayOf(PropTypes.object.isRequired),
  paymentMapping: PropTypes.object.isRequired,
  patientMapping: PropTypes.object.isRequired,
  userMapping: PropTypes.object
};

export default PurchaseList;
