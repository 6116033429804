import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { useFirebase } from 'react-redux-firebase'

import { styled } from '@mui/material/styles';
import AvatarMui from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';

import RebornLogo from '../../static/reborn.png';
import IbeautyLogo from '../../static/ibeauty.png';
import SanteaLogo from '../../static/santea.png';

const LogoItem = styled('div')(() => ({
  margin: '0 auto 54px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '21px',
  marginTop: '100px',
  fontWeight: '500',
  letterSpacing: '11px',
  fontFamily: 'Noto Sans TC'
}));

const LoginPaper = styled('div')(({ theme }) => ({
  margin: theme.spacing(8, 4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Avatar = styled(AvatarMui)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const FormMui = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const logos = process.env.BRANCH_ENV === 'lexcellence' || process.env.BRANCH_ENV === 'reborn' ?
  RebornLogo : process.env.BRANCH_ENV === 'santea' ? SanteaLogo : IbeautyLogo

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        ZOHU YAN CO., LTD.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function LoginForm() {
  const { formatMessage } = useIntl()
  const [page, setPage] = useState('login');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [password, setPassword] = useState('');
  const [snackPack, setSnackPack] = React.useState([]);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const firebase = useFirebase();

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpenMessage(true);
    } else if (snackPack.length && messageInfo && openMessage) {
      // Close an active snack when a new one is added
      setOpenMessage(false);
    }
  }, [snackPack, messageInfo, openMessage]);

  async function onSubmit() {
    if (page === 'login') {
      try {
        await firebase.login({ email, password })
      } catch (ex) {
        console.log(ex)
        if (ex.code === 'auth/user-not-found') {
          setEmailError(formatMessage({ id: 'login.error.email' }))
        } else if (ex.code === 'auth/wrong-password') {
          setPasswordError(formatMessage({ id: 'login.error.password' }))
        }
      }
    } else {
      await firebase.resetPassword(email)
      setSnackPack((prev) => [
        ...prev,
        {
          message: formatMessage({ id: 'login.form.resetPasswordMessage' }),
          key: new Date().getTime()
        }
      ]);
      setPage('login')
    }
  }

  function switchPage(e) {
    e.stopPropagation()
    e.preventDefault()
    setPage(page === 'login' ? 'resetPassword' : 'login')
  }

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMessage(false);
  };

  const handleMessageExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={openMessage}
        autoHideDuration={4000}
        onClose={handleMessageClose}
        TransitionProps={{ onExited: handleMessageExited }}
        message={messageInfo ? messageInfo.message : undefined}
      />
      <CssBaseline />
      <Grid item xs={12} sm={4} md={7} sx={{ backgroundColor: 'rgb(216,216,215,0.5)' }} >
        <LogoItem>
          <img alt='' src={logos} />
          {formatMessage({ id: 'login.welcome' })}
        </LogoItem>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <LoginPaper>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <FormattedMessage id={page === 'login' ? 'login.form.login' : 'login.form.resetPassword'} />
          </Typography>
          <FormMui noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={formatMessage({ id: 'login.form.email' })}
              name="email"
              autoComplete="email"
              onChange={(e) => {
                if (emailError !== '') {
                  setEmailError('')
                }
                setEmail(e.target.value)
              }}
              autoFocus
              error={emailError !== ''}
              helperText={emailError}
            />
            {page === 'login' && <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={formatMessage({ id: 'login.form.password' })}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {
                if (passwordError !== '') {
                  setPasswordError('')
                }
                setPassword(e.target.value)
              }}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  onSubmit()
                }
              }}
              error={passwordError !== ''}
              helperText={passwordError}
            />}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              <FormattedMessage id={page === 'login' ? 'login.form.login' : 'login.form.submit'} />
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/" variant="body2" onClick={switchPage}>
                  <FormattedMessage id={page === 'login' ? 'login.form.forgotPassword' : 'login.form.loginPage'} />
                </Link>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </FormMui>
        </LoginPaper>
      </Grid>
    </Grid>
  );
}

export default LoginForm;
