const i18n = {
  'punchClockException': {
    'table': {
      'date': '日期',
      'time': '時間',
      'type': '類型',
      'reason': '原因',
      'department': '部門'
    },
    'dialog': {
      'normalHoliday': '一般假日',
      'publicHoliday': '例假日',
      'deleteTitle': '刪除',
      'deleteMsg': '確認刪除 {date} 補班/休假?',
      'title': '例外排程',
      'date': '日期',
      'startDate': '開始日期',
      'endDate': '結束日期',
      'startTime': '開始時間',
      'endTime': '結束時間',
      'type': {
        'roots': '類型',
        'on': '補班',
        'off': '補假'
      },
      'reason': '原因',
      'department': {
        'roots': '部門',
        'all': '全部',
        'nurse': '護理美容部',
        'salesRep': '客戶關係代表部',
        'customerService': '客戶服務部',
        'doctor': '醫師',
        'management': '管理部',
        'marketing': '行銷部',
        'hr': '人力資源資部',
        'financial': '財務部',
        'itDept': '資訊部',
        'nutritionist': '營養師',
        'psychologist': '心理師',
      },
    }
  }
}

export default i18n