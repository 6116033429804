import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from '../modules/sort';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableRow from './EnhancedTableRow';

// NOTE: 使用 CustomTableRow 的話就不要套用這個元件
function EnhancedTable({ tableData, defaultOrder, defaultOrderField, headerCells, rowCells, expandable = false, getActionIcons, getExpandContent, getSelectionCount, getRowCheckBoxStatus, onHeaderCheckboxClick, onRowCheckboxClick, onOrderChanged }) {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderField);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder);
    setOrderBy(property);
    if (onOrderChanged) {
      onOrderChanged({ order: newOrder, orderBy })
    }
  };

  const headerProps = {
    headerCells,
    expandable,
    onRequestSort: handleRequestSort,
    rowCount: tableData.length
  }
  if (defaultOrder && defaultOrderField) {
    headerProps.order = order
    headerProps.orderBy = orderBy
  }
  if (onHeaderCheckboxClick) {
    headerProps.onCheckboxClick = onHeaderCheckboxClick
  }
  if (getSelectionCount) {
    headerProps.numSelected = getSelectionCount()
  }
  if (getActionIcons) {
    headerProps.actionButton = true
  }

  function getRow(rowData) {
    const rowProps = {
      rowCells,
      cellData: rowData,
      expandable
    }

    if (getExpandContent) {
      rowProps.expandContent = getExpandContent(rowData)
    }

    if (getActionIcons) {
      rowProps.actionIcons = getActionIcons(rowData)
    }

    if (onRowCheckboxClick) {
      rowProps.onCheckboxClick = () => onRowCheckboxClick(rowData)
    }

    if (getRowCheckBoxStatus) {
      rowProps.selected = getRowCheckBoxStatus(rowData)
    }

    return <EnhancedTableRow
      key={rowData.key || rowData.id}
      {...rowProps}
    />
  }

  return <TableContainer component={Paper}>
    <Table sx={{ minWidth: '650px' }}>
      <EnhancedTableHead {...headerProps} />
      <TableBody>
        {stableSort(tableData, getComparator(order, orderBy)).map(rowData => getRow(rowData))}
      </TableBody>
    </Table>
  </TableContainer>
}

EnhancedTable.propTypes = {
  defaultOrder: PropTypes.oneOf(['asc', 'desc']),
  defaultOrderField: PropTypes.string,
  headerCells: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    sort: PropTypes.string,
    align: PropTypes.string,
  })).isRequired,
  rowCells: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    align: PropTypes.string,
    type: PropTypes.string,
    calculate: PropTypes.string,
    label: PropTypes.string,
    onValueChanged: PropTypes.func,
  })).isRequired,
  expandable: PropTypes.bool,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHeaderCheckboxClick: PropTypes.func,
  onRowCheckboxClick: PropTypes.func,
  getRowCheckBoxStatus: PropTypes.func,
  getActionIcons: PropTypes.func,
  getExpandContent: PropTypes.func,
  getSelectionCount: PropTypes.func,
  onOrderChanged: PropTypes.func,
};

export default EnhancedTable;
