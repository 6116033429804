import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import BackdropMui from '@mui/material/Backdrop';

import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';

import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import ReviewLeavesListView from './ReviewLeavesListView'
import ActionDialog from '../../components/ActionDialog';
import SimpleTableToolbar from '../../components/SimpleTableToolbar'
import DateRangePickerField from '../../components/DateRangePickerField';
import LeaveType from '../../enum/LeaveType'

const _LeaveAllType = LeaveType.concat();

const Backdrop = styled(BackdropMui)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const statusColor = {
  pending: '#c83cc8',
  reject: '#fe2851',
  void: '#fe2851',
  done: '#00b900',
}

function ReviewLeavesList({ currentUser, isAll }) {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const userMapping = useSelector(state => state.firebase.data.users);
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')

  const [pendingLeaves, setPendingLeaves] = useState([])
  const [historLeaves, setHistorLeaves] = useState([])
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [csvLoading, setCsvLoading] = useState(false)

  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [historyStartDate, setHistoryStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [historyEndDate, setHistoryEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  const [typeText, setTypeText] = useState('')
  const [filterText, setFilterText] = useState('')
  const [checked, setChecked] = useState([0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [typeHistoryText, setTypeHistoryText] = useState('')
  const [filterHistoryText, setFilterHistoryText] = useState('')
  const [historyChecked, setHistoryChecked] = useState([0]);
  const [historyAnchorEl, setHistoryAnchorEl] = useState(null);
  const openHistory = Boolean(historyAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHistoryClick = (event) => {
    setHistoryAnchorEl(event.currentTarget);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    let newText = ''
    for (const x of newChecked) {
      if (x.name) {
        newText = newText === '' ? x.name : newText + ',' + x.name
      }
    }

    setTypeText(newText)
    setChecked(newChecked);
  };

  const handleHistoryToggle = (value) => () => {
    const currentIndex = historyChecked.indexOf(value);
    const newChecked = [...historyChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    let newText = ''
    for (const x of newChecked) {
      if (x.name) {
        newText = newText === '' ? x.name : newText + ',' + x.name
      }
    }

    setTypeHistoryText(newText)
    setHistoryChecked(newChecked);
  };

  useEffect(() => {
    setPendingLeaves([])
    const unsubscribe = firebase.firestore().collection('leaves')
      .where('status', '==', 'pending').onSnapshot(snapshot => {
        const leaves = []
        snapshot.forEach(doc => {
          leaves.push({ ...doc.data(), id: doc.id })
        });

        const stepUserLeaves = !isAll ? leaves.filter(leave => {
          const currentStep = leave.history.length > 0 ? leave.history[leave.history.length - 1].step : 0
          return leave.steps[currentStep].users.includes(currentUser.key)
        }) : leaves

        setPendingLeaves(stepUserLeaves)
      }, err => { })

    return () => unsubscribe()
  }, [history.location.pathname]);

  useEffect(() => {
    setHistorLeaves([])
    const unsubscribeA = firebase.firestore().collection('leaves')
      .where('startDate', '>=', historyStartDate).where('startDate', '<=', historyEndDate)
      .where('status', 'in', ['done', 'void', 'reject']).onSnapshot(snapshot => {
        setHistorLeaves(leaves => {
          const leavesMapping = leaves.reduce((acc, cur) => {
            acc[cur.id] = true
            return acc
          }, {})

          snapshot.forEach(doc => {
            if (!leavesMapping[doc.id]) {
              leaves.push({ ...doc.data(), id: doc.id })
            }
          });

          return [...leaves]
        })
      }, err => { })

    const unsubscribeB = firebase.firestore().collection('leaves')
      .where('endDate', '>=', historyStartDate).where('endDate', '<=', historyEndDate)
      .where('status', 'in', ['done', 'void', 'reject']).onSnapshot(snapshot => {
        setHistorLeaves(leaves => {
          const leavesMapping = leaves.reduce((acc, cur) => {
            acc[cur.id] = true
            return acc
          }, {})

          snapshot.forEach(doc => {
            if (!leavesMapping[doc.id]) {
              leaves.push({ ...doc.data(), id: doc.id })
            }
          });

          return [...leaves]
        })
      }, err => { })

    return () => { unsubscribeA(); unsubscribeB() }
  }, [historyStartDate, historyEndDate]);

  if (!historLeaves || !pendingLeaves) {
    return ('Loading...')
  }

  const formatData = (leave) => {
    const newData = { ...leave }
    newData.createdByName = userMapping[newData.createdBy].displayName
    newData.start = `${newData.startDate} ${newData.startTime}`
    newData.typeName = formatMessage({ id: `leaveType.${newData.type}` })
    newData.statusName = `${formatMessage({ id: `leaveStatus.${newData.status}` })}`
    newData.end = `${newData.endDate} ${newData.endTime}`
    newData.day = `${newData.days}天${newData.hours}小時`
    newData.date = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD HH:mm')
    newData.textColor = statusColor[newData.status]
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  async function handleExecute(data) {
    const { text } = data
    const { filed, leaveId } = dialogData
    setLoading(true)

    try {
      await (firebase.functions().httpsCallable('saveLeaves'))({
        ...filed,
        id: leaveId,
        note: text,
        void: true,
      })

      setLoading(false)
    } catch (ex) {
      console.log(ex)
      setLoading(false)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  function HistoryHandleChange(value) {
    setFilterHistoryText(value)
  }

  function handleChange(value) {
    setFilterText(value)
  }

  function getToolbox(isHitory) {
    return <>
      {isHitory ? <Grid item xs={12} sm={12} md={12} >
        <DateRangePickerField
          startDate={isHitory ? historyStartDate : startDate}
          endDate={isHitory ? historyEndDate : endDate}
          onStartDateChanged={isHitory ? setHistoryStartDate : setStartDate}
          onEndDateChanged={isHitory ? setHistoryEndDate : setEndDate}
        />
      </Grid> : <Grid item xs={12} sm={12} md={12} />}
      <Grid item xs={12} sm={4} md={4} sx={{ paddingRight: '10px' }}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'searchbox.createdBy' })}
          onChange={(e) => isHitory ? HistoryHandleChange(e.target.value) : handleChange(e.target.value)}
          variant="outlined"
          fullWidth
          value={isHitory ? filterHistoryText : filterText}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'searchbox.type' })}
          variant="outlined"
          onClick={isHitory ? handleHistoryClick : handleClick}
          value={isHitory ? typeHistoryText : typeText}
          fullWidth
        />
        <Menu
          id="fade-menu"
          anchorEl={isHitory ? historyAnchorEl : anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          open={isHitory ? openHistory : open}
          onClose={() => isHitory ? setHistoryAnchorEl(null) : setAnchorEl(null)}
          TransitionComponent={Fade}
        >
          <List>
            {_LeaveAllType.map((value, idx) => {
              return (
                <ListItem key={`${value}-${idx}`} dense onClick={isHitory ? handleHistoryToggle(value) : handleToggle(value)}>
                  <Checkbox
                    sx={{ padding: '0 4px' }}
                    edge="start"
                    size="small"
                    checked={isHitory ? historyChecked.indexOf(value) !== -1 : checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                  {value.name}
                </ListItem>
              );
            })}
          </List>
        </Menu>
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        {isHitory && isAll &&
          <IconButton
            sx={{ fontSize: '14px', alignSelf: 'center', color: '#666666' }}
            onClick={() => exportProfile()}
            disabled={csvLoading}
            size="large">
            <GetAppIcon></GetAppIcon>
            {formatMessage({ id: 'button.export' })}
          </IconButton>
        }
      </Grid>
    </>;
  }

  const headerCells = [
    { text: 'createdByName', sort: 'createdByName' },
    { text: 'type', sort: 'type' },
    { text: 'start', sort: 'start' },
    { text: 'end', sort: 'end' },
    { text: 'days', sort: 'days' },
    { text: 'date', sort: 'date' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `leaves.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'createdByName' },
    { field: 'typeName' },
    { field: 'start' },
    { field: 'end' },
    { field: 'day' },
    { field: 'date' },
    { field: 'statusName', textColor: true },
  ]

  const historyHeaderCells = [
    { text: 'createdByName', sort: 'createdByName' },
    { text: 'type', sort: 'type' },
    { text: 'start', sort: 'start' },
    { text: 'end', sort: 'end' },
    { text: 'days', sort: 'days' },
    { text: 'date', sort: 'date' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `leaves.table.${c.text}` }); return c })

  const historyRowCells = [
    { field: 'createdByName' },
    { field: 'typeName' },
    { field: 'start' },
    { field: 'end' },
    { field: 'day' },
    { field: 'date' },
    { field: 'statusName', textColor: true },
  ]

  function exportProfile() {
    if (csvLoading) {
      return
    }

    setCsvLoading(true);

    const rows = ['申請人,假別,開始時間,結束時間,天/小時,申請日,狀態']

    stableSort(filterHistoryLeaves.map(r => formatData(r)), getComparator(order, orderBy)).map(leave => (
      rows.push([
        leave.createdByName,
        leave.typeName,
        leave.start,
        leave.end,
        leave.day,
        dayjs(leave.createdAt.seconds * 1000).format('YYYY-MM-DD'),
        leave.statusName
      ])
    ))

    try {
      const content = rows.join('\n')
      const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const a = document.createElement('a')
      a.href = csvUrl
      a.target = '_blank'
      a.download = 'Leaves_' + dayjs().format('YYYY-MM-DD-HHmmss') + '.csv'
      a.click()
      setCsvLoading(false);
    } catch (error) {
      console.error(error) // eslint-disable-line
      setCsvLoading(false);
      alert('An error occurred. Please refresh and try again.')
    }
  }


  function filterItem(isHistory) {
    let type = [];

    if (isHistory) {
      for (const x of historyChecked) {
        if (x.value) {
          type.push(x.value)
        }
      }
      if (typeHistoryText !== '' && filterHistoryText === '') {
        return historLeaves.filter(s => type.includes(s.type));
      } else if (filterHistoryText !== '' && typeHistoryText === '') {
        return historLeaves.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterHistoryText.toLowerCase()));
      } else if (typeHistoryText !== '' && filterHistoryText !== '') {
        return historLeaves.filter(s => type.includes(s.type)).filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterHistoryText.toLowerCase()));
      } else {
        return historLeaves
      }
    } else {
      for (const x of checked) {
        if (x.value) {
          type.push(x.value)
        }
      }
      if (typeText !== '' && filterText === '') {
        return pendingLeaves.filter(s => type.includes(s.type));
      } else if (filterText !== '' && typeText === '') {
        return pendingLeaves.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterText.toLowerCase()));
      } else if (typeText !== '' && filterText !== '') {
        return pendingLeaves.filter(s => type.includes(s.type)).filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterText.toLowerCase()));
      } else {
        return pendingLeaves
      }
    }
  }

  const filterHistoryLeaves = filterItem(true);
  const filterLeaves = filterItem();
  return (
    <div>
      {loading && <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'leaves.detail.voidReason' })}
        action={dialogData.action}
      />}
      <Grid container spacing={1} sx={{ mt: '10px', mb: '40px' }}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'leaves.table.pending'}
            toolbox={getToolbox()}
          />
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                expandable
                onRequestSort={handleRequestSort}
                rowCount={filterLeaves.length}
              />
              <TableBody>
                {stableSort(filterLeaves.map(r => formatData(r)), getComparator(order, orderBy)).map(leave => (
                  <EnhancedTableRow
                    key={leave.id}
                    rowCells={rowCells}
                    cellData={leave}
                    expandable
                    expandContent={<ReviewLeavesListView leaves={leave} isAll={isAll} />}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={12} md={12}>
          < SimpleTableToolbar
            title={'leaves.table.history'}
            toolbox={getToolbox(true)}
          />
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={historyHeaderCells}
                order={order}
                orderBy={orderBy}
                expandable
                onRequestSort={handleRequestSort}
                rowCount={filterHistoryLeaves.length}
                actionButton
              />
              <TableBody>
                {stableSort(filterHistoryLeaves.map(r => formatData(r)), getComparator(order, orderBy)).map(leave => (
                  <EnhancedTableRow
                    key={leave.id}
                    rowCells={historyRowCells}
                    cellData={leave}
                    expandable
                    expandContent={<ReviewLeavesListView leaves={leave} isHistory={true} isAll={isAll} />}
                    actionIcons={<>
                      {(!leave.void || (leave.void && leave.status === 'reject')) && <span>
                        <Tooltip title={formatMessage({ id: 'button.edit' })}>
                          <IconButton
                            onClick={() => history.push(`/leaveOvertimeWork/leaves/edit/${leave.id}`)}
                            size="large">
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={formatMessage({ id: 'button.void' })}>
                          <IconButton
                            onClick={() => setDialogData({ action: 'void', title: '', filed: leave, leaveId: leave.id })}
                            size="large">
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </span>}
                    </>}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div >
  );
};

ReviewLeavesList.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  isAll: PropTypes.bool
};

export default ReviewLeavesList;
