import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import { createTheme, ThemeProvider, StyledEngineProvider, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ButtonMui from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { getComparator, stableSort } from '../../../modules/sort';
import { objectToArray } from '../../../modules/data'
import { formatName } from '../../../modules/uitls';
import SelectMedicineDialog from '../../../components/SelectMedicineDialog';
import EnhancedTableToolbar from '../../../components/EnhancedTableToolbar';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import EnhancedTableRow from '../../../components/EnhancedTableRow';

let paymentNumber = []
let primaryColor = {
  light: '#a3ffff',
  main: '#6EDBD5',
  dark: '#35a9a4',
  contrastText: '#fff',
}

const Toolbutton = styled(ButtonMui)(({ theme }) => ({
  margin: theme.spacing(1),
  whiteSpace: 'nowrap'
}));

const Button = styled(ButtonMui)(({ theme }) => ({
  margin: theme.spacing(1),
  whiteSpace: 'nowrap'
}));

if (process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence') {
  paymentNumber.push('149170570')
  primaryColor = {
    light: '#fffff7',
    main: '#eeccc4',
    dark: '#bb9b94',
    contrastText: '#a58a88',
  }
} else if (process.env.BRANCH_ENV === 'santea') {
  paymentNumber.push('149283643')
  primaryColor = {
    light: '#6efeff',
    main: '#26CAD3',
    dark: '#0099a2',
    contrastText: '#fff',
  }
} else if (process.env.BRANCH_ENV === 'ibeautyTaichung') {
  paymentNumber.push('149102787')
} else if (process.env.BRANCH_ENV === 'ibeautyPlastic') {
  paymentNumber.push('144546752')
} else if (process.env.BRANCH_ENV === 'ibeauty') {
  paymentNumber.push('149310181')
}
const newMapping = {
  division: ['beauty', 'plastic', 'preventative'],
  doctorStamp: ['blank', 'required'],
  payment: paymentNumber
}


const theme = createTheme({
  palette: {
    primary: primaryColor,
  },
});

function MedicinePage({ currentUser, patient, kardex, kardexId, patientId, docType }) {
  const { formatMessage } = useIntl()
  let medicine = {};
  const medicineData = [];
  if (docType === 'prescription') {
    if (kardex.prescription) {
      medicine = kardex.prescription
    } else {
      medicine = {
        division: '',
        payment: '',
        dosingDay: 1,
        comment: ''
      }
    }
  } else {
    if (kardex.controlledDrug) {
      medicine = kardex.controlledDrug
    } else {
      medicine = {
        division: '',
        payment: '',
        dosingDay: 1,
        licence: '',
        disease: '',
        doctorPhone: '',
        comment: '',
        doctorStamp: ''
      }
    }
  }

  if (medicine) {
    Object.keys(medicine.medicines || {}).forEach(key => {
      medicineData.push({ id: key, ...medicine.medicines[key] })
    })
  }

  const [medicineMapping, setMedicineMapping] = useState({})
  const [openSelectMedicineDialog, setOpenSelectMedicineDialog] = useState(false)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selectedItems, setSelectedItems] = useState({});
  const doctorAccount = currentUser.department === 'doctor' && currentUser.key === kardex.doctor.id && !medicine.receivedMedicine ? true : false
  const filterMedicines = []
  if (filterMedicines.length === 0) {
    Object.keys(medicineMapping).forEach(m => {
      if (docType === 'prescription' && !medicineMapping[m].controlledDrug) {
        filterMedicines.push(medicineMapping[m])
      } else if (docType !== 'prescription' && medicineMapping[m].controlledDrug) {
        filterMedicines.push(medicineMapping[m])
      }
    })
  }

  useEffect(() => {
    const ref = firebase.database().ref('medicines')
    const onDataChange = ref.on('value', snapshot => {
      setMedicineMapping(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  const _fields = [
    { name: 'name', sm: 12, md: 12, order: 0 },
    { name: 'identityCardNumber', sm: 12, md: 12, order: 1 },
    { name: 'birthDate', sm: 12, md: 12, order: 2 },
    { name: 'displayId', type: 'kardex', sm: 12, md: 12, order: 3 },
    { name: 'date', type: 'kardex', sm: 12, md: 12, order: 4 },
  ]

  const _fields2 = [
    { name: 'division', type: 'text', select: true, roots: true, sm: 12, md: 4, order: 5 },
    { name: 'payment', type: 'text', select: true, sm: 12, md: 4, order: 6 },
    { name: 'dosingDay', type: 'text', sm: 12, md: 4, order: 7 },
    { name: 'comment', type: 'text', multiline: true, sm: 12, md: 12, order: 12 },
  ]

  if (docType !== 'prescription') {
    _fields2.push(
      { name: 'licence', type: 'text', sm: 12, md: 12, order: 11 },
      { name: 'disease', type: 'text', sm: 12, md: 4, order: 9 },
      { name: 'doctorPhone', type: 'text', sm: 12, md: 4, order: 8 },
      { name: 'doctorStamp', type: 'text', select: true, roots: true, sm: 12, md: 4, order: 10 },
    )
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  const fields2 = _fields2.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function updateData(field, value) {
    let newValue = value
    if (field.name === 'division') {
      if (value !== '') {
        newValue = formatMessage({ id: `kardex.medicine.division.${newValue}` })
      }
    }

    let newData = { ...medicine, [field.name]: newValue }

    if (!['division', 'payment', 'comment', 'licence', 'disease', 'doctorPhone', 'doctorStamp'].includes(field.name) && newData.medicines) {
      let data = {}
      if (field.name === 'dosingDay') {
        for (const m of Object.keys(newData.medicines)) {
          let count = newData.dosingDay ? newData.dosingDay : 1
          let totalCount = newData.medicines[m].dose * usageCount(newData.medicines[m].usage) * count
          newData.medicines[m].total = newData.medicines[m].category === 'OI' ? 1 : totalCount
        }
      } else {
        for (const m of newData.medicines) {
          const newKey = m.id ? m.id : firebase.database().ref('kardexes').child(`${kardexId}/${docType}`).child('medicines').push().key
          data[newKey] = { ...m, total: String(m.total) }
        }
        newData.medicines = data
      }
    }

    if (newData) {
      try {
        await firebase.database().ref('kardexes').child(kardexId).child(docType).update({ ...newData })
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  const _headerCells = [
    { text: 'name', sort: 'name' },
    { text: 'dosingDay', sort: 'dosingDay' },
    { text: 'usage.root', sort: 'usage' },
    { text: 'dose', sort: 'dose' },
    { text: 'unit', sort: 'unit' },
    { text: 'total', sort: 'total' },
  ]

  const _rowCells = [
    { field: 'name' },
    {
      field: 'dosingDay',
      fullWidth: true,
      align: 'left',
      type: 'input-number',
      label: formatMessage({ id: 'medicine.dosingDay' }),
      onValueChanged: onCellValueChanged,
      getEnableStatus: onEnableValue
    },
    {
      field: 'usage',
      fullWidth: true,
      align: 'left',
      type: 'input-select',
      label: formatMessage({ id: 'medicine.usage.root' }),
      onValueChanged: onCellValueChanged,
      getMenuItems: getUsageList,
      getEnableStatus: onEnableValue
    },
    {
      field: 'dose',
      align: 'left',
      type: 'input-number',
      label: formatMessage({ id: 'medicine.dose' }),
      onValueChanged: onCellValueChanged,
      getEnableStatus: onEnableValue
    },
    { field: 'unit' },
    {
      field: 'total',
      align: 'left',
      type: 'input-number',
      label: formatMessage({ id: 'medicine.total' }),
      onValueChanged: onCellValueChanged,
      getEnableStatus: onEnableValue
    },
  ]

  if (docType !== 'prescription') {
    _headerCells.push({ text: 'range', sort: 'range' },)
    _rowCells.push({
      field: 'range',
      fullWidth: true,
      align: 'left',
      type: 'input-select',
      label: formatMessage({ id: 'medicine.range' }),
      onValueChanged: onCellValueChanged,
      getMenuItems: getRangeList,
      getEnableStatus: onEnableValue
    })
  }
  const headerCells = _headerCells.map(c => { c.text = formatMessage({ id: `medicine.${c.text}` }); return c })
  const rowCells = _rowCells

  function getRangeList() {
    return ['1-3', '4-10', '11-40', '41-75', '76-100', '101-200', '201-300', '300以上'].map(v => (
      { label: v, value: v }
    ))
  }

  function getUsageList() {
    if (['lexcellence', 'santea'].includes(process.env.BRANCH_ENV)) {
      return ['QAC', 'QD', 'LAC', 'LPC', 'PAC', 'PD', 'TIDAC', 'TIDPC', 'HSAC', 'BID', 'BIDAC', 'AM', 'PM'].map(m => (
        { label: formatMessage({ id: `medicine.usage.${m}` }), value: m }
      ))
    } else {
      return ['QID', 'QD', 'BID', 'TID', 'HS', 'QN', 'PRN', 'Stay'].map(m => (
        { label: formatMessage({ id: `medicine.usage.${m}` }), value: m }
      ))
    }
  }

  function onEnableValue(data, field) {
    if (medicine.receivedMedicine) {
      return false
    }

    if (field === 'dose') {
      if (data.category === 'PO') {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  function createField(field, value) {
    let newValue = value
    let newLableText = ''
    let selectFields = newMapping[`${field.name}`]

    if (field.roots) {
      newLableText = formatMessage({ id: `kardex.medicine.${field.name}.roots` })
      if (!field.type === 'text') {
        newValue = formatMessage({ id: `kardex.medicine.${field.name}.${newValue}` })
      }
    } else {
      newLableText = formatMessage({ id: `kardex.medicine.${field.name}` })
    }

    if (field.name === 'name') {
      newValue = formatName(newValue)
    }

    if (field.type === 'text') {
      newValue = medicine ? medicine[field.name] : ''
      if (field.name === 'division' && newValue) {
        if (newValue === '醫學美容') {
          newValue = 'beauty'
        } else if (newValue === '整形外科') {
          newValue = 'plastic'
        } else {
          newValue = 'preventative'
        }
      }
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md} style={{ marginTop: '10px' }}>
        <TextField
          multiline={field.multiline}
          type="text"
          disabled={field.disabled || (medicine.receivedMedicine && field.name !== 'comment')}
          required={field.required}
          label={newLableText}
          value={newValue}
          fullWidth
          select={field.select ? field.select : null}
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        >
          {
            field.select && selectFields.map((selects, idx) => {
              return <MenuItem key={`${idx}`} value={selects}>{['division', 'doctorStamp'].includes(field.name) ? formatMessage({ id: `kardex.medicine.${field.name}.${selects}` }) : selects}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'kardex') {
      newValue = kardex[field.name]
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '16px', color: '#828a99' }}>
        <span style={{ marginRight: '10px', fontSize: '14px' }} >{newLableText}</span>
        <span>{newValue}</span>
      </div>
    </Grid>
  }

  function onCellValueChanged(id, field, value) {
    for (let m of medicineData) {
      if (m.id === id) {
        m[field] = value
        m.total = m.category === 'PO' ? usageCount(m.usage) * m.dose * m.dosingDay : 1
      }
    }

    updateData({ name: 'medicines' }, medicineData);
  }

  function onMedicineChanged(medicines) {
    if (medicines.length) {
      for (let m of medicines) {
        m.usage = ['lexcellence', 'santea'].includes(process.env.BRANCH_ENV) ? 'QAC' : 'QD'
        m.total = 1
        m.dose = m.category === 'PO' ? 1 : ''
        m.dosingDay = medicine.dosingDay
        if (docType !== 'prescription') {
          m.range = ''
        }

      }
      updateData({ name: 'medicines' }, (medicineData || []).concat(medicines));
    }
  }

  function usageCount(usage) {
    if (['lexcellence', 'santea'].includes(process.env.BRANCH_ENV)) {
      if (['BID', 'BIDAC'].includes(usage)) {
        return 2
      } else {
        return 1
      }
    } else {
      if (usage === 'QID') {
        return 4
      } else if (['QD', 'HS', 'QN', 'PRN'].includes(usage)) {
        return 1
      } else if (usage === 'BID') {
        return 2
      } else if (usage === 'TID') {
        return 3
      } else if (usage === 'Stay') {
        return 0
      }
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function onDeleteItems() {
    const medicines = medicineData.filter(m => !selectedItems[m.id])
    if (medicines.length !== medicineData.length) {
      updateData({ name: 'medicines' }, medicines);
      setSelectedItems({});
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = medicineData.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  return (
    <div style={{ padding: '40px', fontFamily: 'Roboto, sans-serif' }}>
      {openSelectMedicineDialog && <SelectMedicineDialog
        defaultSelectedItems={[]}
        handleClose={() => setOpenSelectMedicineDialog(false)}
        handleSave={onMedicineChanged}
        medicines={filterMedicines}
        dialogTitle={formatMessage({ id: 'selectMedicineDialog.title' })}
      />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1}>
            <div style={{ marginBottom: '4px' }}>{formatMessage({ id: 'kardex.medicine.information' })}</div>
            {fields.map(field => createField(field, patient[field.name]))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1}>
            {fields2.map(field => createField(field, medicine[field.name]))}
          </Grid>
        </Grid>
        <div style={{ margin: '8px 0', width: '100%' }}>
          <EnhancedTableToolbar
            title="medicine.title"
            selectdMessage="medicine.editMedicine.table.selected"
            numSelected={Object.keys(selectedItems).length}
            toolbox={
              <Toolbutton
                disabled={medicine.receivedMedicine}
                variant="contained"
                color="primary"
                onClick={() => setOpenSelectMedicineDialog(true)}
              >
                <FormattedMessage id={'medicine.edit.addMedicine'} />
              </Toolbutton>
            }
            toolboxForSelection={
              <Toolbutton
                variant="contained"
                color="primary"
                disabled={medicine.receivedMedicine}
                startIcon={<DeleteIcon />}
                sx={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onDeleteItems}
              >
                <FormattedMessage id="medicine.edit.removeMedicine" />
              </Toolbutton>
            }
          />
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={Object.keys(selectedItems).length}
                onCheckboxClick={handleSelectAllClick}
                rowCount={medicineData.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(medicineData, getComparator(order, orderBy)).map(medicine => (
                  <EnhancedTableRow
                    key={`${medicine.id}`}
                    rowCells={rowCells}
                    cellData={medicine}
                    onCheckboxClick={(e) => handleCheckboxClick(medicine.id)}
                    selected={selectedItems[medicine.id] || false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px' }}>
            <div style={{ position: 'relative' }}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Button variant="outlined" size="large" onClick={() => window.open(`/patients/${patientId}/kardexes/${kardexId}/${docType}`)} color="primary">
                    <PictureAsPdfIcon sx={{ marginRight: '4px' }} />
                    <FormattedMessage id="kardex.medicine.exportPdf" />
                  </Button>
                  <Button
                    disabled={!doctorAccount || medicine.receivedMedicine}
                    variant="outlined"
                    size="large"
                    onClick={() => window.open(`/patients/${patientId}/kardexes/${kardexId}/${docType}`)}
                    color="primary"
                  >
                    <PictureAsPdfIcon sx={{ marginRight: '4px' }} />
                    <FormattedMessage id="kardex.medicine.doctorSign" />
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>
            </div>
          </div>
        </div>
      </Grid>
    </div >
  );
}

MedicinePage.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,

  }),
  docType: PropTypes.string.isRequired,
  patient: PropTypes.object.isRequired,
  kardex: PropTypes.object.isRequired,
  kardexId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired
};

export default MedicinePage;
