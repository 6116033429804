import firebase from 'firebase/app';

const warehouse = process.env.BRANCH_ENV === 'lexcellence' ?
  firebase.initializeApp({
    apiKey: 'AIzaSyD_ya-IGxxAvsoTZGIDUXgxmlf6BRfC9EE',
    authDomain: 'warehousedev-4aadf.firebaseapp.com',
    databaseURL: 'https://warehousedev-4aadf.firebaseio.com',
    projectId: 'warehousedev-4aadf',
    storageBucket: 'warehousedev-4aadf.appspot.com',
    messagingSenderId: '564702340091',
    appId: '1:564702340091:web:04130ae8e6cd5654e3215e',
  }, 'warehouse') :
  firebase.initializeApp({
    apiKey: 'AIzaSyAwCLRCRF9msS6TOA1lckyaHx4oAInWH5I',
    authDomain: 'warehouse-dc56d.firebaseapp.com',
    databaseURL: 'https://warehouse-dc56d.firebaseio.com',
    projectId: 'warehouse-dc56d',
    storageBucket: 'warehouse-dc56d.appspot.com',
    messagingSenderId: '58976674760',
    appId: '1:58976674760:web:4875ed48434dd7ee0416fd',
  }, 'warehouse')

export default warehouse

