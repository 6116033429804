const i18n = {
  'sideMenu': {
    'appointments': {
      'root': '預約管理',
      'schedule': '預約表',
      'list': '預約列表',
      'editList': '預約編輯紀錄',
      'ddapp': 'DDApp預約列表',
      'calendar': '會議室/車位'
    },
    'smsList': '簡訊管理',
    'patients': '客戶',
    'products': '產品',
    'merchandises': '耗材',
    'clinicSchedule': '診所班表',
    'analytics': '數據分析',
    'staffManagement': {
      'root': '職員管理',
      'staff': '職員',
      'salary': '薪資管理',
      'punchClockException': '管理補班和休假'
    },
    'manages': '銷售管理',
    'finance': '財務管理',
    'announcement': '公告',
    'leaveOvertimeWork': {
      'root': '請假與加班',
      'owner': '個人請假加班',
      'review': '簽核請假加班',
      'all': '全部請假加班'
    },
    'reports': {
      'root': '報表',
      'purchase': {
        'root': '營收報表',
        'day': '日營收報表',
        'month': '月營收報表',
        'season': '季營收報表'
      },
      'personalBonus': '個人獎金試算',
      'consume': {
        'root': '療程消耗',
        'day': '日療程消耗',
        'month': '月療程消耗'
      }
    },
    'quotation': '報價單'
  }
}
export default i18n
