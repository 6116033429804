const i18n = {
  'medicine': {
    'title': '藥品列表',
    'table': {
      'code': '編號',
      'name': '名稱',
      'unit': '訂貨單位',
      'note': '備註',
      'category': '分類',
    },
    'name': '藥名',
    'dosingDay': '天數',
    'dose': '用量',
    'unit': '單位',
    'total': '發藥量',
    'range': '總處方量範圍',
    'editMedicine': {
      'table': {
        'selected': '已選擇 {count} 個項目',
      },
    },
    'edit': {
      'addMedicine': '新增藥品',
      'removeMedicine': '移除藥品',
    },
    'category': {
      'all': '全部',
      'PO': '口服藥',
      'OI': '藥膏',
      'IV': '針劑',
    },
    'controlledDrug': {
      'type1': '管制性藥品',
      'type2': '非管制性藥品',
    },
  }
}

if (['lexcellence', 'santea'].includes(process.env.BRANCH_ENV)) {
  i18n.medicine.usage = {
    'root': '用法',
    'QAC': '早飯前(QAC)',
    'QD': ' 早飯後(QD)',
    'LAC': ' 午飯前(LAC)',
    'LPC': ' 午飯後(LPC)',
    'PAC': ' 晚飯前(PAC)',
    'PD': ' 晚飯後(PD)',
    'TIDAC': '隨餐飯前(TIDAC)',
    'TIDPC': '隨餐飯後(TIDPC)',
    'HSAC': '睡前一小時(HS)',
    'BID': '早晚飯後(BID)',
    'BIDAC': '早晚飯前(BIDAC)',
    'AM': '上午(AM)',
    'PM': '下午(PM)',
  }
} else {
  i18n.medicine.usage = {
    'root': '用法',
    'QID': '一天四次，三餐飯後/前與睡前(QID)',
    'QD': '一天一次(QD)',
    'BID': '一天兩次(BID)',
    'TID': '一天三次，三餐飯後(TID)',
    'HS': '每晚睡前半小時一次(HS)',
    'QN': '每晚一次(QN)',
    'PRN': '需要時使用(PRN)',
    'Stay': '不用列入處方籤(Stay)'
  }
}

export default i18n