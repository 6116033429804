import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import ToolbarMui from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';

import { getComparator, stableSort } from '../modules/sort';
import EnhancedTableRow from './EnhancedTableRow';
import EnhancedTableHead from './EnhancedTableHead';
import SearchBox from './SearchBox';
import { moibleMedia } from '../constants/index';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Toolbar = styled(ToolbarMui)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  [moibleMedia]: {
    flexDirection: 'column',
  }
}));

const ToolbarTitle = styled(Typography)(({ theme }) => ({
  flex: '1 1 100%',
  flexShrink: 2,
  [moibleMedia]: {
    display: 'none'
  }
}));

export default function SelectProductDialog({ defaultSelectedItems, handleClose, defaultCategoryFilter = { tags: [] }, handleSave, products, size = 'lg', dialogTitle, isMobile }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {}));

  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(defaultCategoryFilter);

  const productData = products.reduce((acc, cur) => {
    const newTags = cur.tags ? cur.tags.split(',') : []
    acc.push({ ...cur, tags: newTags })
    return acc
  }, [])
  const filteredProducts = filterProducts()
  const filteredselection = filteredProducts.filter(s => selectedItems[s.id]).reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})
  const filteredTags = [...new Set(
    (categoryFilter.type ? productData.filter(p => p.cat1.toLowerCase().includes(categoryFilter.type.toLowerCase())) : productData).reduce((acc, cur) => acc.concat(cur.tags || []), [])
  )].sort()

  const _headerCells = [
    { text: 'code', sort: 'code', order: 0 },
  ]

  const _rowCells = [
    { field: 'code', order: 0 },
  ]

  const headerCells = (isMobile ?
    [
      ..._headerCells,
      {
        text: (['name', 'nickname'].includes(currentFilter?.name) ? currentFilter?.name : 'nickname'),
        sort: (['name', 'nickname'].includes(currentFilter?.name) ? currentFilter?.name : 'nickname'),
        order: 2
      }] :
    [
      ..._headerCells,
      { text: 'name', sort: 'name', order: 1 },
      { text: 'nickname', sort: 'nickname', order: 2 },
      { text: 'price', sort: 'price', order: 3 },
      { text: 'duration', sort: 'duration', order: 4 }
    ]
  ).map(c => { c.text = formatMessage({ id: `product.selectDialog.${c.text}` }); return c }).sort((a, b) => a.order - b.order)

  const rowCells = (isMobile ?
    [
      ..._rowCells,
      { field: (['name', 'nickname'].includes(currentFilter?.name) ? currentFilter?.name : 'nickname'), order: 2 }
    ] :
    [
      ..._rowCells,
      { field: 'name', order: 1 },
      { field: 'nickname', order: 2 },
      { field: 'price', order: 3 },
      { field: 'duration', order: 4 },
    ]
  ).sort((a, b) => a.order - b.order)

  const filterItems = [
    { name: 'name' },
    { name: 'code' },
    { name: 'nickname' },
  ].map(i => { i.text = formatMessage({ id: `product.selectDialog.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'type' },
    { name: 'tags' },
  ].map(i => { i.text = formatMessage({ id: `product.table.detail.${i.name}` }); return i })


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredProducts.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  };

  const handleCheckboxClick = (id) => {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function filterProducts() {
    if (!currentFilter && !categoryFilter.type && categoryFilter.tags.length === 0) {
      return productData
    }
    let items = [...productData]
    if (categoryFilter.type) {
      const type = categoryFilter.type.toUpperCase()
      items = items.filter(i => i.cat1 === type)
    }
    if (categoryFilter.tags.length > 0) {
      items = items.filter(i => categoryFilter.tags.every(tag => (i.tags || []).includes(tag)))
    }
    if (currentFilter) {
      items = items.filter(s => (s[currentFilter.name] || '').toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return items
  }

  function getMenuItem(name) {
    const _typeRows = [
      { value: '' },
      { value: 'beauty', header: true }, { value: 'AM' }, { value: 'OP' }, { value: 'SK' }, { value: 'DP' },
      // {value: 'ND'}, {value: 'CU'},
      { value: 'other', header: true }, { value: 'OT' }
    ]

    if (process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence') {
      _typeRows.push({ value: 'preventative', header: true }, { value: 'CN' }, { value: 'EX' }, { value: 'TT' }, { value: 'GE' }, { value: 'SU' })
    }

    if (name === 'type') {
      return _typeRows.map(i => {
        i.label = i.value ? formatMessage({ id: `product.type.${i.value}` }) : formatMessage({ id: 'product.category.all' })
        return i
      })
    }

    return []
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const onApply = () => {
    const selectedProducts = productData.filter(s => selectedItems[s.id]).map(s => ({
      code: s.code,
      name: s.name,
      nickname: s.nickname,
      id: s.id,
      itemCode: Math.floor(Math.random() * (100000)),
      type: 'product',
      assistant: '',
      duration: s.duration || '15',
      cat1: s.cat1,
      // cat2: s.cat2,
      package: s.package ? s.package : null,
    }))

    handleSave(selectedProducts)
    handleClose()
  }

  const getFilter = (filter) => {
    const gridSize = isMobile ? 6 : 3
    if (filter.name === 'tags') {
      return <Grid item key={filter.name} xs={gridSize} sm={gridSize} md={gridSize}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">{formatMessage({ id: 'product.table.detail.tags' })}</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-textbox"
            multiple
            value={categoryFilter.tags}
            onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
            input={<OutlinedInput label={formatMessage({ id: 'product.table.detail.tags' })} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {filteredTags.map((tag) => (
              <MenuItem key={tag} value={tag}>
                <Checkbox checked={categoryFilter.tags.includes(tag)} />
                <ListItemText primary={tag} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    } else {
      return <Grid item key={filter.name} xs={gridSize} sm={gridSize} md={gridSize}>
        <TextField
          select
          disabled={!!defaultCategoryFilter[filter.name]}
          type="text"
          size="small"
          label={filter.text}
          variant="outlined"
          onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
          value={categoryFilter[filter.name] || ''}
          fullWidth
        >
          {getMenuItem(filter.name).map((option, idx) => {
            return option.header ?
              (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
              (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={size}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1, zIndex: 2000 }}>
          <Toolbar>
            <ToolbarTitle variant="h6" id="tableTitle" component="div">
              <FormattedMessage id="product.selectDialog.title" />
            </ToolbarTitle>
            <Grid container spacing={3}>
              {categoryFilterItems.map(f => getFilter(f))}
            </Grid>
            <SearchBox autoFocus filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Toolbar>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={Object.keys(filteredselection).length}
                order={order}
                orderBy={orderBy}
                onCheckboxClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredProducts.length}
              />
              <TableBody>
                {stableSort(filteredProducts, getComparator(order, orderBy)).map(product => (
                  <EnhancedTableRow
                    key={product.id}
                    rowCells={rowCells}
                    cellData={product}
                    onCheckboxClick={(e) => handleCheckboxClick(product.id)}
                    selected={selectedItems[product.id] || false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>

  );
}

SelectProductDialog.propTypes = {
  defaultCategoryFilter: PropTypes.object,
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    nickname: PropTypes.string,
    id: PropTypes.string.isRequired,
  })),
  handleClose: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  dialogTitle: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  size: PropTypes.string,
  isMobile: PropTypes.bool
};
