import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import ButtonProgress from './ButtonProgress';

export default function EditWorkTIme({ handleClose }) {
  const { formatMessage } = useIntl()
  const [newData, setNewData] = useState({})
  const [loadingApprove, setLoadingApprove] = useState(false);

  async function saveWorkTimes() {
    setLoadingApprove(true)

    // try {
    //   await (firebase.functions().httpsCallable('saveUserRights'))({ ...newData })
    //   setLoadingApprove(false)
    //   handleClose()
    // } catch (ex) {
    //   console.log(ex)
    // }
  }

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('workTimeTemplates').onSnapshot(snapshot => {
      let snapshots = {}
      snapshot.forEach(doc => {
        snapshots[doc.id] = { ...doc.data() }
      });
      setNewData(snapshots)
    }, err => { })

    return () => unsubscribe()
  }, []);

  const fields = [
    { name: 'editUserRight', order: 0 },
  ].map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field, department, data) {
    const weekDay = Object.keys(data.workTime).reduce((acc, cur) => {
      console.log(dayjs().weekday())
      return acc
    }, [])

    return <div style={{ display: 'flex', flexDirection: 'row', margin: '20px 0' }} key={field.name} >
      {weekDay.map(time => {
        return <div key={time} >{time}</div>
      })}
      <Grid item xs={6} sm={4} md={4}>
        <TextField
          multiline={field.multiline}
          type="text"
          disabled
          label={formatMessage({ id: 'header.userRight.root' })}
          value={formatMessage({ id: `header.userRight.${field.name}` })}
          fullWidth
          size="small"
          variant="outlined"
        />
      </Grid>
    </div>
  }

  const tabs = [];

  for (const key of Object.keys(newData)) {
    if (key !== 'doctor') {
      tabs.push({
        label: formatMessage({ id: process.env.BRANCH_ENV === 'santea' ? `tab.schedule.santea.${key}` : `tab.schedule.${key}` }),
        component: <div style={{ marginTop: '10px' }}>{fields.map(field => createField(field, key, newData[key]))}</div>
      })
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Dialog
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth='md'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{formatMessage({ id: 'header.workTime.title' })}</DialogTitle>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
          {'Comeing soon...'}
        </div>
        <DialogActions>
          <ButtonProgress handleClose={handleClose} handleClick={() => saveWorkTimes()} loading={loadingApprove} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditWorkTIme.propTypes = {
  handleClose: PropTypes.func.isRequired
};
