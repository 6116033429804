import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from '../../modules/sort';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';

function ReviewSmsPage({ smsId, userMapping, patientList }) {
  const { formatMessage } = useIntl()
  const [smsHistory, setSmsHistory] = useState(null);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  useEffect(() => {
    const ref = firebase.database().ref('smsHistory').child(smsId)
    const onDataChange = ref.on('value', snapshot => {
      setSmsHistory(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, [smsId]);

  if (!smsHistory) {
    return ('Loading...')
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerCells = [
    { text: 'name', sort: 'name' },
    { text: 'birthDate', sort: 'birthDate' },
    { text: 'phone', sort: 'phone' },
    { text: 'salesRep', sort: 'salesRep' },
    { text: 'lastAppointmentDateTime', sort: 'lastAppointmentDateTime' },
  ].map(c => { c.text = formatMessage({ id: `smsList.review.patient.${c.text}` }); return c })

  const rowCells = [
    { field: 'name' },
    { field: 'birthDate' },
    { field: 'phone' },
    { field: 'salesRep' },
    { field: 'lastAppointmentDateTime' },
  ]

  const _fields = [
    { name: 'content', type: 'text', multiline: true, sm: 12, md: 12, order: 0 },
    { name: 'patient', type: 'list', multiline: true, sm: 12, md: 12, order: 0 },
  ]

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  const formatData = (patient) => {
    const newData = { ...patient }
    newData.salesRep = userMapping[newData.salesRep] ? userMapping[newData.salesRep].displayName : ''
    newData.lastAppointmentDateTime = newData.lastAppointmentDateTime.split('_')[0]

    return newData
  }

  function filterPatients() {
    const data = []
    smsHistory.patient.forEach(p => {
      if (patientList[p]) {
        data.push({ id: p, ...patientList[p] })
      }
    })

    return data
  }

  const filterPatient = filterPatients();

  function createField(field, value, sms) {
    let newValue = value
    let labelText = ''

    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} style={{ position: 'relative' }}>
          <Divider />
          <div>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </div>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    } else if (field.type === 'text') {
      labelText = formatMessage({ id: `smsList.review.${field.name}` })

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={labelText}
          value={newValue}
          fullWidth
          variant="standard"
          size="small"
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    } else if (field.type === 'list') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filterPatient.length}
            />
            <TableBody>
              {stableSort(filterPatient.map(r => formatData(r)), getComparator(order, orderBy)).map(sms => (
                <EnhancedTableRow
                  key={sms.id}
                  rowCells={rowCells}
                  cellData={sms}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    }
  }

  return (
    <div>
      <Grid container spacing={3}>
        {fields.map(field => createField(field, smsHistory[field.name], smsHistory))}
      </Grid>
    </div>
  );
}

ReviewSmsPage.propTypes = {
  smsId: PropTypes.string.isRequired,
  userMapping: PropTypes.object,
  patientList: PropTypes.object
};

export default ReviewSmsPage;
