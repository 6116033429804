import leaves from './leaves';
import header from './header';
import login from './login';
import overtimes from './overtimes';
import form from './form';
import button from './button';
import tab from './tab';
import homePage from './homePage';
import stepActions from './stepActions';
import dateTime from './dateTime';
import filterText from './filterText';
import schedule from './schedule';
import staff from './staff';
import sideMenu from './sideMenu';
import announcement from './announcement';
import punchClockRevise from './punchClockRevise';
import punchClockException from './punchClockException';
import patient from './patient';
import dialogs from './dialogs';
import kardexes from './kardexes';
import merchandise from './merchandise';
import patientPage from './patientPage';
import smsList from './smsList';
import appointment from './appointment';
import product from './product';
import manage from './manage';
import finance from './finance';
import report from './report';
import medicine from './medicine';
import analytics from './analytics';
import calendars from './calendars';
import quotation from './quotation';

function flattenObject(ob, prefix = '') {
  const toReturn = {}
  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue

    if ((typeof ob[i]) === 'object') {
      const flatObject = flattenObject(ob[i], prefix + i + '.');
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue
        toReturn[x] = flatObject[x];
      }
    } else {
      toReturn[prefix + i] = ob[i]
    }
  }
  return toReturn
}

const i18n = {
  'noMessage': ' ',
  ...punchClockRevise,
  ...announcement,
  ...staff,
  ...sideMenu,
  ...schedule,
  ...tab,
  ...leaves,
  ...overtimes,
  ...header,
  ...login,
  ...form,
  ...button,
  ...homePage,
  ...stepActions,
  ...dateTime,
  ...filterText,
  ...punchClockException,
  ...patient,
  ...dialogs,
  ...kardexes,
  ...merchandise,
  ...patientPage,
  ...smsList,
  ...appointment,
  ...product,
  ...manage,
  ...finance,
  ...report,
  ...medicine,
  ...analytics,
  ...calendars,
  ...quotation
};

export default flattenObject(i18n)
