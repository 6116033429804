import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import { arrayMoveImmutable } from 'array-move';

import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/PermDeviceInformation';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';

import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import SearchBox from '../../components/SearchBox';
import ExpandButton from '../../components/ExpandButton';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import { formatName } from '../../modules/uitls';
import { objectToArray, objectToNewKey } from '../../modules/data';

import { calculateAge } from '../../modules/time'
import DatePickerField from '../../components/DatePickerField';
import TabContainer from '../../containers/TabContainer/TabContainer';
import SelectPatientDialog from '../../components/SelectPatientDialog';
import SelectProductDialog from '../../components/SelectProductDialog';
import NewPatientDialog from '../../components/NewPatientDialog';
import ButtonProgress from '../../components/ButtonProgress';

import { treatmentColorAndBgColor } from '../../modules/treatment';
import ContextStore from '../../modules/context';
// [DDAPP]
const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  userSelect: 'none',
  zIndex: 11,
  minHeight: '40px'
}

const itemColor = {
  margin: '1px 0',
  width: '10px',
  minHeight: '100%',
  display: 'inline-flex',
  borderRadius: '5px',
  alignSelf: 'stretch'
}

const mainWrap = {
  width: '100%'
}

const itemMain = {
  minHeight: '40px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  background: '#fff',
  userSelect: 'none'
}

const itemName = {
  flex: 1,
  padding: '0 10px',
  fontSize: '15px',
  color: '#414141'
}

const itemDuration = {
  padding: '0 20px',
  fontSize: '15px',
  color: '#757575'
}

const itemEdit = {
  borderTop: '1px solid #E4E4E4',
  borderBottom: '1px solid #E4E4E4',
  padding: '0 18px 10px',
  background: '#F6F6F6',
}

// [5, 10, 15, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480]
const durationOptions = [
  { time: 5 }, { time: 10 }, { time: 15 }, { time: 30 }, { time: 45 }, { time: 60 }, { time: 90 },
  { time: 120 }, { time: 150 }, { time: 180 }, { time: 210 }, { time: 240 }, { time: 270 }, { time: 300 }, { time: 330 },
  { time: 360 }, { time: 390 }, { time: 420 }, { time: 450 }, { time: 480 }
]

const SortableItem = sortableElement(({ item, nurses, arrayIndex, handleChange }) => {
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState(false);
  const treatment = item
  let { backgroundColor } = treatmentColorAndBgColor(treatment)
  let deleteOpen = true

  if (treatment.done) {
    deleteOpen = false
  }

  return (
    <div style={rowStyle}>
      <div style={{ ...itemColor, backgroundColor: backgroundColor }} />
      <div style={mainWrap}>
        <div style={itemMain} onClick={() => setOpen(!open)}>
          <div style={itemName}>
            {treatment.name || ''}
          </div>
          <div style={itemDuration}>
            {treatment.duration || ''} <FormattedMessage id={'appointment.sidebar.minutesShort'} />
          </div>
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit sx={itemEdit}>
          <Grid container spacing={1} sx={{ padding: '8px 0 0 0' }}>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                fullWidth
                id={'name'}
                onChange={(e, val) => handleChange(arrayIndex, 'name', e.target.value)}
                label={formatMessage({ id: 'appointment.sidebar.treatment.showAs' })}
                value={treatment.name}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Autocomplete
                id="duration"
                freeSolo
                value={String(treatment.duration)}
                inputValue={String(treatment.duration)}
                onChange={(e, val) => handleChange(arrayIndex, 'duration', val ?? '')}
                options={durationOptions.map((option) => String(option.time))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={formatMessage({ id: 'appointment.sidebar.treatment.duration' })}
                    onChange={(e, val) => handleChange(arrayIndex, 'duration', e.target.value.replace(/[^0-9]/g, ''))}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5} sm={5} md={5}>
              <TextField
                fullWidth
                id={'nickname'}
                onChange={(e) => handleChange(arrayIndex, 'nickname', e.target.value)}
                label={formatMessage({ id: 'appointment.sidebar.treatment.showNickName' })}
                value={treatment.nickname || ''}
              />
            </Grid>
            {treatment.type === 'product' && !treatment.code.startsWith('9') ? <Grid item xs={4} sm={4} md={4}>
              <TextField
                select
                fullWidth
                id={'assistant'}
                onChange={(e, val) => handleChange(arrayIndex, 'assistant', e.target.value)}
                label={formatMessage({ id: 'appointment.sidebar.treatment.assistant' })}
                value={treatment.assistant || ''}
              >
                {nurses.map((nurse, key) => (
                  <MenuItem value={nurse.id} key={key} >{nurse.displayName}</MenuItem>
                ))}
              </TextField>
            </Grid> : <Grid item xs={4} sm={4} md={4} />}
            {deleteOpen && <Grid item xs={3} sm={3} md={3} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <Button onClick={(e) => handleChange(arrayIndex, false, item.id)}>
                <DeleteIcon />
                <FormattedMessage id='appointment.sidebar.treatment.delete' defaultMessage='Delete' />
              </Button>
            </Grid>}
          </Grid>
        </Collapse>
      </div>
    </div >
  );
});

const SortableList = sortableContainer(({ items, nurses, handleChange }) => {
  return (
    <div>
      {items.filter(i => !i.parent).map((item, index) => (
        <SortableItem
          key={`item-${item.itemCode}`}
          index={index}
          arrayIndex={index}
          handleChange={handleChange}
          item={item}
          nurses={nurses}
        />
      ))}
    </div>
  );
});

function ConfirmUpdateDialog({ handleClose, data }) {
  const { formatMessage } = useIntl()
  const [kardex, setKardex] = useState({})
  let msg = ''
  if (data.status === 'pending') {
    msg = formatMessage({ id: 'appointment.ddapp.message.normal' }, {
      name: data.patientName,
      kardexDate: kardex.date,
      kardexTime: kardex.time,
      kardexID: kardex.displayId,
      date: data.date,
      time: data.time,
    })
  } else {
    msg = formatMessage({ id: 'appointment.ddapp.message.cancelled' }, {
      name: data.patientName,
      kardexID: kardex.displayId
    })
  }

  useEffect(() => {
    const ref = firebase.database().ref('kardexes').child(data.kardex)
    const onDataChange = ref.on('value', snapshot => {
      setKardex(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  async function onConfirm() {
    try {
      await firebase.firestore().collection('appointmentFromDD').doc(data.id).update({ updated: false })
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <div>
      <Dialog
        maxWidth='md'
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'appointment.ddapp.title.updateInfo' })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage({ id: 'appointment.ddapp.button.reserve' })}
          </Button>
          <Button onClick={() => onConfirm()} color="primary">
            {formatMessage({ id: 'appointment.ddapp.button.remove' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmUpdateDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

function EditDDappDialog({ handleClose, appointment, userData, userMapping, userRight }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [openPatientDialog, setOpenPatientDialog] = useState(false)
  const [openProductSelect, setOpenProductSelect] = useState(false)
  const [openNewPatientDialog, setOpenNewPatientDialog] = useState(false)
  const [patients, setPatients] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [treatmentTemplates, setTreatmentTemplates] = useState({})
  const [products, setProducts] = useState([])
  const [productMapping, setProductMapping] = useState({})
  const [treatmentList, setTreatmentList] = useState([]);
  const [selectPatient, setSelectPatient] = useState(null)
  const [currentData, setCurrentData] = useState({
    date: dayjs(appointment.date),
    time: appointment.time,
    doctor: appointment.currentDoctorId,
    patient: '',
    appointmentType: appointment.appointmentType,
    room: appointment.room || '',
    source: '',
    salesRep: '',
    nurse: '',
    comment: ''
  })

  useEffect(() => {
    const ref = firebase.database().ref('products')
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      if (snapshots) {
        const newData = [];
        for (const p of Object.keys(snapshots)) {
          if (snapshots[p].consumable && snapshots[p].cat1 !== 'DP') {
            if (snapshots[p].offShelfDate && snapshots[p].onShelfDate) {
              if (snapshots[p].offShelfDate >= dayjs().format('YYYY-MM-DD') && snapshots[p].onShelfDate <= dayjs().format('YYYY-MM-DD')) {
                newData.push({ id: p, ...snapshots[p] })
              }
            } else if (snapshots[p].offShelfDate && !snapshots[p].onShelfDate) {
              if (snapshots[p].offShelfDate >= dayjs().format('YYYY-MM-DD')) {
                newData.push({ id: p, ...snapshots[p] })
              }
            } else if (snapshots[p].onShelfDate && !snapshots[p].offShelfDate) {
              if (snapshots[p].onShelfDate <= dayjs().format('YYYY-MM-DD')) {
                newData.push({ id: p, ...snapshots[p] })
              }
            } else {
              newData.push({ id: p, ...snapshots[p] })
            }
          }
        }
        setProducts(newData)
        setProductMapping(snapshots)
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('treatmentTemplates')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setTreatmentTemplates(objectToNewKey(snapshot.val(), 'type'))
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('rooms')
    const onDataChange = ref.on('value', snapshot => {
      const snapshotData = snapshot.val()
      if (snapshotData) {
        const data = []
        for (const r of Object.keys(snapshotData)) {
          data.push({ id: r, value: snapshotData[r] })
        }
        setRooms(data)
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patients').orderByChild('DDappPatientId').equalTo(appointment.patientId)
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      if (snapshots) {
        for (const s of Object.keys(snapshots)) {
          setSelectPatient({ id: s, ...snapshots[s], ddapp: true })
        }
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    if (currentData.patient) {
      const ref = firebase.database().ref('patients').child(currentData.patient)
      const onDataChange = ref.on('value', snapshot => {
        const data = snapshot.val()
        setSelectPatient(snapahot => {
          return { ...snapahot, ...data }
        })
      });
      return () => ref.off('value', onDataChange)
    }
  }, [currentData.patient]);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setPatients(objectToArray(snapshot.val()))
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  const newUserData = userData.filter(d => d.active).reduce((acc, cur) => {
    if (!acc[cur.department]) {
      acc[cur.department] = []
    }

    if (['doctor', 'nurse', 'salesRep'].includes(cur.department)) {
      acc[cur.department].push({
        id: cur.id,
        value: cur.displayName
      })
    }

    return acc
  }, {})

  const sourceData = ['phone', 'internets', 'salesRep', 'ddapp'].map(t => {
    return {
      value: formatMessage({ id: `appointment.ddapp.dialog.source.${t}` }),
      id: t
    }
  })

  const selectMapping = {
    nurse: newUserData.nurse,
    salesRep: newUserData.salesRep,
    source: sourceData,
    room: rooms
  }

  const tabs = [
    { label: formatMessage({ id: 'appointment.sidebar.tab.customerInformation' }), component: <div></div> },
    { label: formatMessage({ id: 'appointment.sidebar.tab.arrangeTreatments' }), component: <div></div> },
  ]

  const onTabSelected = (tabIndex) => {
    setActiveTabIndex(tabIndex)
  }

  function updateData(field, value) {
    let newValue = value
    let newData = { ...currentData, [field.name]: newValue }

    setCurrentData(newData)
  }

  const handleSelectPatienSave = (patient) => {
    updateData({ name: 'patient' }, patient.id)
  }

  const fields = [
    { name: 'date', type: 'date', md: 6, sm: 6 },
    { name: 'time', type: 'time', md: 6, sm: 6 }
  ]

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const _fields2 = []
  if (activeTabIndex === 0) {
    _fields2.push({ name: 'doctor', type: 'text', disabled: true, md: 6, sm: 6, order: 3 })
    _fields2.push({ name: 'nurse', type: 'text-select', md: 6, sm: 6, order: 4 })
    _fields2.push({ name: 'source', root: true, type: 'text-select', md: 6, sm: 6, order: 5 })
    _fields2.push({ name: 'salesRep', type: 'text-select', md: 6, sm: 6, order: 6 })
    _fields2.push({ name: 'appointmentType', root: true, disabled: true, type: 'text', md: 6, sm: 6, order: 7 })
    _fields2.push({ name: 'room', type: 'text-select', md: 6, sm: 6, order: 8 })
    _fields2.push({ name: 'comment', type: 'text', md: 12, sm: 12, order: 9 })

    if (!selectPatient?.ddapp) {
      if (userRight['patient-add']) {
        _fields2.push({ name: 'selectPatient', type: 'button', md: 6, sm: 6, order: 0 })
        _fields2.push({ name: 'addPatient', type: 'button', md: 6, sm: 6, order: 1 })
      } else {
        _fields2.push({ name: 'selectPatient', type: 'button', md: 12, sm: 12, order: 0 })
      }
    }

    if (selectPatient) {
      _fields2.push({ name: 'patient', type: 'label', order: 2 })
    }
  } else {
    _fields2.push({ name: 'treatments', type: 'menu', md: 12, sm: 12, order: 0 })
  }

  const fields2 = _fields2.sort((a, b) => a.order - b.order)

  function onClickButton(type) {
    if (type === 'selectPatient') {
      setOpenPatientDialog(true)
    } else if (type === 'addPatient') {
      setOpenNewPatientDialog(true)
    }
  }

  function selectTreatment(treatment, isProduct) {
    if (!treatment) {
      return handleMenuClose()
    }

    treatment = Object.assign({}, treatment, {
      name: treatment.name ? treatment.name : treatment.displayName,
      nickname: treatment.nickname ? treatment.nickname : treatment.displayName,
      type: isProduct ? 'product' : null,
      product: isProduct ? treatment.id : null,
      itemCode: Math.floor(Math.random() * (100000)),
      assistant: treatment.assistant ? treatment.assistant : '',
      order: treatment.order,
      duration: treatment.duration,
    })
    // delete treatment.key

    setTreatmentList(treatmentList.concat(treatment))
    handleMenuClose()
  }

  function shouldCancelStart(e) {
    if (['name', 'duration', 'assistant', 'nickname'].includes(e.target.id)) {
      return true
    }
  }

  function handleTreatmentChange(index, key, value) {
    let treatments = treatmentList
    if (key === false) {
      treatments.splice(index, 1)

      let newTreatment = []
      for (const s in treatments) {
        if (treatments[s].parent !== value) {
          newTreatment.push(treatments[s])
        }
      }
      treatments = newTreatment
    } else {
      treatments[index][key] = value
    }
    setTreatmentList([...treatments])
  }

  function onSortEnd({ oldIndex, newIndex }) {
    setTreatmentList(arrayMoveImmutable(treatmentList, oldIndex, newIndex))
  };

  function onSelectProduct(products) {
    if (products.length) {
      setTreatmentList(treatmentList.concat(products))
    }

    handleMenuClose()
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function getNewPatientData(data) {
    updateData({ name: 'patient' }, data)
  }

  async function saveAppointmentFromDD() {
    setLoadingApprove(true)
    const newData = { ...currentData }
    let timeError = false

    if (newData.time) {
      const newTimeH = newData.time.split(':')[0] || undefined
      const newTimeM = newData.time.split(':')[1] || undefined
      if ((!newTimeH || !newTimeM) || (newTimeH === ' ' || newTimeM === ' ')) {
        timeError = true
      }
      if (newTimeH > '24') {
        timeError = true
      }

      if (newTimeM > '60') {
        timeError = true
      }
    } else {
      timeError = true
    }

    if (timeError) {
      setLoadingApprove(false)
      return alert(formatMessage({ id: 'appointment.sidebar.error.noAppointmentTime' }))
    }

    if (!selectPatient) {
      setLoadingApprove(false)
      return alert(formatMessage({ id: 'appointment.sidebar.error.noPatient' }))
    }

    const treatments = treatmentList.slice()
    let order = 0
    newData.treatments = treatments.reduce((acc, cur) => {
      const key = cur.product ? cur.id : firebase.database().ref('treatments').push().key
      if (cur.type === 'product') {
        acc[key] = {
          assistant: cur?.assistant ?? '',
          cat0: cur.code.slice(0, 1),
          cat1: cur.cat1,
          // cat2: cur.cat2,
          code: cur.code,
          duration: cur.duration,
          done: cur.done ?? null,
          name: cur.name,
          product: cur.product ?? cur.id,
          type: cur.type,
          nickname: cur.nickname ?? '',
          cost: cur.cost ?? null,
          parent: cur.parent ?? null,
          order: cur.parent ? 99 : order,
          quantity: 1
        }

        if (acc[cur.parent] && acc[cur.parent].cat0 === '8') {
          acc[key].assistant = acc[cur.parent].assistant
        }

        if (cur.package) {
          for (const p of Object.keys(cur.package)) {
            const newKey = firebase.database().ref('treatments').push().key
            if (productMapping[p].consumable) {
              acc[newKey] = {
                assistant: cur?.assistant ?? '',
                cat0: productMapping[p].code.slice(0, 1),
                cat1: productMapping[p].cat1,
                // cat2: productMapping[p].cat2,
                code: productMapping[p].code,
                duration: productMapping[p].duration || '',
                parent: key,
                name: productMapping[p].name,
                product: p,
                type: cur.type,
                nickname: productMapping[p].nickname || '',
                order: 99,
                quantity: cur.package[p]?.amount || 1
              }
            }
          }
        }
      } else {
        acc[key] = {
          color: cur.color,
          duration: cur.duration,
          name: cur.name,
          nickname: cur.nickname || '',
          assistant: cur.assistant || '',
          order
        }
      }
      order++
      return acc
    }, {})

    newData.date = dayjs(newData.date).format('YYYY-MM-DD')
    newData.hour = parseInt(newData.time.split(':')[0])
    newData.minute = parseInt(newData.time.split(':')[1])
    newData.status = 'not-confirmed'
    newData.DDappId = appointment.id

    if (selectPatient.ddapp) {
      newData.patient = selectPatient.id
    }

    if (newData.date !== appointment.date || newData.time !== appointment.time) {
      const duration = Object.keys(newData.treatments).reduce((acc, cur) => {
        acc += newData.treatments[cur].duration
        return acc
      }, 0)

      const updateData = {
        date: newData.date,
        time: newData.time,
        duration,
        timezone: 'Asia/Taipei',
        apptId: appointment.bookingId,
        type: '1',
        clinicId: appointment.clinicId,
      }

      try {
        await (firebase.functions().httpsCallable('editKardexFromDD'))(updateData)
      } catch (ex) {
        console.log(ex)
      }
    }

    try {
      await (firebase.functions().httpsCallable('saveKardexFromDD'))({ appointmentFromDDId: appointment.id, ddappPatientId: appointment.patientId, ...newData })
    } catch (ex) {
      console.log(ex)
    }

    setLoadingApprove(false)
    handleClose()
  }

  function createFields(field) {
    if (field.name === 'patient') {
      return <Grid key={field.name} container spacing={1} sx={{ margin: '10px 0' }}>
        <Grid item xs={12} sm={12} md={12}>
          {selectPatient.name}
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {formatMessage({ id: 'appointment.sidebar.patientInfo.gender.root' })} :
          {selectPatient.gender && formatMessage({ id: `appointment.sidebar.patientInfo.gender.${selectPatient.gender}` })}
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {formatMessage({ id: 'appointment.sidebar.patientInfo.age' })}
          : {calculateAge(selectPatient.birthDate)}
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {formatMessage({ id: 'appointment.sidebar.patientInfo.phone' })}
          : {appointment.phone}
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {formatMessage({ id: 'appointment.sidebar.patientInfo.birthDate' })}
          : {selectPatient.birthDate}
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {formatMessage({ id: 'appointment.sidebar.patientInfo.salesRep' })}
          : {userMapping[selectPatient.salesRep] && userMapping[selectPatient.salesRep].displayName}
        </Grid>
      </Grid>
    } else if (field.name === 'treatments') {
      return <Grid key={field.name} container spacing={1} sx={{ margin: '10px 0' }}>
        <Grid item xs={12} sm={12} md={12}>
          <Button color="primary" onClick={handleMenu}>
            <AddIcon />
            {formatMessage({ id: 'appointment.sidebar.button.arrangeTreatments' })}
          </Button>
        </Grid>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => setOpenProductSelect(true)}>
            <FormattedMessage id="appointment.sidebar.treatmentType.product" />
          </MenuItem>
          {Object.keys(treatmentTemplates).map((type, key) => (
            type !== 'product' && <MenuItem key={key} onClick={() => selectTreatment(treatmentTemplates[type])}>
              {treatmentTemplates[type].displayName}
            </MenuItem>
          ))}
        </Menu>
        {treatmentList.length ? <Grid item xs={12} sm={12} md={12}>
          <SortableList
            distance={5}
            items={treatmentList}
            onSortEnd={onSortEnd}
            shouldCancelStart={shouldCancelStart}
            nurses={newUserData.salesRep}
            handleChange={handleTreatmentChange}
          />
        </Grid> : <Grid item xs={12} sm={12} md={12}>
          <div style={{
            height: '80px',
            borderRadius: '4px',
            border: '2px dashed #979797',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '15px',
            opacity: 0.5,
            marginTop: '5px'
          }}>
            <FormattedMessage id={'appointment.sidebar.noTreatments'} />
          </div>
        </Grid>}
      </Grid>
    }

    const newLabel = field.root ? formatMessage({ id: `appointment.ddapp.dialog.${field.name}.root` }) :
      formatMessage({ id: `appointment.ddapp.dialog.${field.name}` })
    let newValue = currentData[field.name]
    if (field.type === 'date') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          required
          fullWidth
          underLine={'standard'}
          label={newLabel}
          value={newValue}
          onChange={date => updateData({ name: field.name }, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'time') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <InputMask
          mask={'99:99'}
          maskChar=""
          onChange={e => updateData({ name: field.name }, e.target.value)}
          value={newValue}
        >
          {() => <TextField
            sx={{ marginLeft: '10px' }}
            type="text"
            size="small"
            variant="standard"
            required
            fullWidth
            label={newLabel}
            onCompositionStart={
              e => {
                e.target.addEventListener('input', e2 => {
                  if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                    e2.preventDefault()
                    e2.stopPropagation()
                    e2.stopImmediatePropagation()
                  }
                }, { once: true })
              }
            }
            error={currentData.time_err ? true : false}
            helperText={currentData.time_err}
          />}
        </InputMask>
      </Grid>
    } else if (field.type === 'button') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <Button color="primary" onClick={() => onClickButton(field.name)}>
          {field.name === 'selectPatient' ? <PersonIcon /> : <AddIcon />}
          {newLabel}
        </Button>
      </Grid>
    } else if (field.type === 'text-select') {
      if (selectPatient && selectPatient.salesRep && field.name === 'salesRep') {
        newValue = selectPatient.salesRep
      }

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          select
          type="text"
          size="small"
          variant="standard"
          label={newLabel}
          fullWidth
          disabled={(currentData.appointmentType === 'beautyTreatment' || currentData.appointmentType === '') ? true : false}
          value={newValue}
          onChange={e => updateData({ name: field.name }, e.target.value)}
        >
          {
            (selectMapping[field.name] || []).map((selcet, idx) => {
              return <MenuItem key={`${idx}`} value={selcet.id}>{selcet.value}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'text') {
      if (field.name === 'doctor') {
        newValue = userMapping[newValue].displayName
      } else if (field.name === 'appointmentType') {
        newValue = formatMessage({ id: `appointment.ddapp.dialog.appointmentType.${newValue}` })
      }

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          type="text"
          size="small"
          disabled={field.disabled}
          variant="standard"
          label={newLabel}
          fullWidth
          value={newValue}
          multiline
          onChange={e => updateData({ name: field.name }, e.target.value)}
        />
      </Grid>
    }
  }

  return (<>
    {openNewPatientDialog && <NewPatientDialog
      dialogTital={formatMessage({ id: 'patient.dialog.information.add' })}
      handleClose={() => setOpenNewPatientDialog(false)}
      patients={patients || []}
      handleSave={getNewPatientData}
      defaultData={{
        name: appointment.patientName,
        phone: appointment.phone.replace('+886', '0')
      }}
    />}
    {openPatientDialog && <SelectPatientDialog
      handleClose={() => setOpenPatientDialog(false)}
      handleSave={handleSelectPatienSave}
      patients={patients || []}
      dialogTitle={formatMessage({ id: 'selectPatientDialog.root' })}
    />}
    {openProductSelect && <SelectProductDialog
      defaultSelectedItems={[]}
      handleClose={() => setOpenProductSelect(false)}
      handleSave={onSelectProduct}
      products={products}
      dialogTitle={formatMessage({ id: 'selectProductDialog.title' })}
      productMapping={productMapping}
    />}
    <Dialog
      fullWidth={true}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'appointment.ddapp.dialog.title' })}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {fields.map(field => createFields(field))}
          <Grid item xs={12} sm={12} md={12}>
            <TabContainer color="primary" activeTabIndex={activeTabIndex} defaultSelect={0} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" position="static" />
          </Grid>
          {fields2.map(field => createFields(field))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonProgress handleClose={handleClose} handleClick={() => saveAppointmentFromDD()} loading={loadingApprove} />
      </DialogActions>
    </Dialog>
  </>);
}

EditDDappDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  appointment: PropTypes.object.isRequired,
  userData: PropTypes.array.isRequired,
  userMapping: PropTypes.object.isRequired,
  userRight: PropTypes.object.isRequired
};

function DDappList({ userRight }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const [ddappData, setDDappData] = useState(null)
  const [ddappUpdateData, setDDappUpdateData] = useState([])
  const [expand, setExpand] = useState(true);
  const [expand2, setExpand2] = useState(true);
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('time')
  const [currentFilter, setCurrentFilter] = useState(null)
  const [currentFilter2, setCurrentFilter2] = useState(null)
  const [openDialog, setOpenDialog] = useState(null)
  const [openConfirmUpdateDialog, setOpenConfirmUpdateDialog] = useState(null)
  const userData = objectToArray(useSelector(state => state.firebase.data.users));
  const userMapping = useSelector(state => state.firebase.data.users)

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('appointmentFromDD').where('updated', '==', true).onSnapshot(snapshot => {
      const snapshots = []
      snapshot.forEach(doc => {
        snapshots.push({ id: doc.id, ...doc.data() });
      })

      setDDappUpdateData(snapshots)
    });
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('appointmentFromDD').where('kardex', '==', '').where('status', '!=', 'canceled').onSnapshot(snapshot => {
      const snapshots = []
      snapshot.forEach(doc => {
        snapshots.push({ id: doc.id, ...doc.data() });
      })

      setDDappData(snapshots)
    });
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.appointments.ddapp' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  if (!ddappData) {
    return ('Loading...')
  }

  const headerCells = [
    { text: 'patientName', sort: 'patientName' },
    { text: 'date', sort: 'date' },
    { text: 'time', sort: 'time' },
    { text: 'doctorName', sort: 'doctorName' },
    { text: 'phone', sort: 'phone' },
    { text: 'email', sort: 'email' },
    { text: 'note', sort: 'note' },
  ].map(c => { c.text = formatMessage({ id: `appointment.ddapp.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'patientName' },
    { field: 'date' },
    { field: 'time' },
    { field: 'doctorName' },
    { field: 'phone' },
    { field: 'email' },
    { field: 'note' },
  ]

  const filterItems = [
    { name: 'patientName' },
    { name: 'date' },
    { name: 'time' },
    { name: 'doctorName' },
    { name: 'phone' },
    { name: 'email' },
  ].map(i => { i.text = formatMessage({ id: `appointment.ddapp.table.${i.name}` }); return i })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const onFilterChanged2 = (name, text) => {
    if (text !== '') {
      setCurrentFilter2({ name, text })
    } else {
      setCurrentFilter2(null)
    }
  }

  const formatData = (data) => {
    const newData = { ...data }
    newData.patientName = formatName(newData.patientName ?? '')
    const doctorData = userData.filter(u => u.DDappDoctorId === newData.doctorId).reduce((acc, cur) => {
      acc.doctorName = cur.displayName
      acc.currentDoctorId = cur.id
      acc.appointmentType = cur.appointmentType

      return acc
    }, {})

    for (const d of Object.keys(doctorData)) {
      newData[d] = doctorData[d]
    }

    return newData
  }

  function filterByText(type) {
    let newFilterData
    if (type === 'new') {
      newFilterData = currentFilter
    } else {
      newFilterData = currentFilter2
    }

    if (newFilterData.name === 'patientName') {
      return ddappData.filter(s => formatName(s.patientName ?? '').toLowerCase().includes(newFilterData.text.toLowerCase()))
    } else if (newFilterData.name === 'date') {
      return ddappData.filter(s => s.date.toLowerCase().includes(newFilterData.text.toLowerCase()))
    } else if (newFilterData.name === 'time') {
      return ddappData.filter(s => s.time.toLowerCase().includes(newFilterData.text.toLowerCase()))
    } else if (newFilterData.name === 'doctorName') {
      return ddappData.filter(s => userData.filter(u => u.DDappDoctorId === s.doctorId)[0].displayName.toLowerCase().includes(newFilterData.text.toLowerCase()))
    } else if (newFilterData.name === 'phone') {
      return ddappData.filter(s => s.phone.toLowerCase().includes(newFilterData.text.toLowerCase()))
    } else if (newFilterData.name === 'email') {
      return ddappData.filter(s => s.email.toLowerCase().includes(newFilterData.text.toLowerCase()))
    }
  }

  function handleClose() {
    setOpenDialog(null)
  }

  const filterData = currentFilter && currentFilter.text ? filterByText('new') : ddappData
  const filterUpdateData = currentFilter2 && currentFilter2.text ? filterByText('update') : ddappUpdateData
  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif', }}>
      {openDialog && <EditDDappDialog
        handleClose={handleClose}
        appointment={openDialog.data}
        userData={userData}
        userMapping={userMapping}
        userRight={userRight}
      />}
      {openConfirmUpdateDialog && <ConfirmUpdateDialog
        data={openConfirmUpdateDialog.data}
        handleClose={() => setOpenConfirmUpdateDialog(null)}
      />}
      <div style={{ padding: '15px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <SimpleTableToolbar
              title={'appointment.ddapp.title.update'}
              bottons={<ExpandButton open={expand2} onExpandChange={setExpand2} />}
              toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged2} />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <EnhancedTableHead
                  headerCells={headerCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filterUpdateData.length}
                  actionButton
                />
                <TableBody>
                  {stableSort(filterUpdateData.map(r => formatData(r)), getComparator(order, orderBy)).map(data => (
                    <EnhancedTableRow
                      key={data.id}
                      rowCells={rowCells}
                      cellData={data}
                      actionIcons={<Tooltip title={formatMessage({ id: 'button.info' })}>
                        <IconButton onClick={() => setOpenConfirmUpdateDialog({ data })} size="large">
                          <UpdateIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <SimpleTableToolbar
              title={'appointment.ddapp.title.new'}
              bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
              toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <EnhancedTableHead
                  headerCells={headerCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filterData.length}
                  actionButton
                />
                <TableBody>
                  {stableSort(filterData.map(r => formatData(r)), getComparator(order, orderBy)).map(data => (
                    <EnhancedTableRow
                      key={data.id}
                      rowCells={rowCells}
                      cellData={data}
                      actionIcons={<Tooltip title={formatMessage({ id: 'button.edit' })}>
                        <IconButton onClick={() => setOpenDialog({ data })} size="large">
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

DDappList.propTypes = {
  userRight: PropTypes.object.isRequired,
};

export default DDappList;
