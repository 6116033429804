const i18n = {
  'login': {
    'form': {
      'email': 'Email 地址',
      'password': '密碼',
      'login': '登入',
      'submit': '送出',
      'resetPassword': '重設密碼',
      'forgotPassword': '忘記密碼？',
      'loginPage': '登入頁面',
      'resetPasswordMessage': '請至信箱收信並重設密碼',
    },
    'error': {
      'email': '帳號錯誤',
      'password': '密碼錯誤'
    }
  }
}

if (process.env.BRANCH_ENV === 'lexcellence') {
  i18n.login.welcome = '歡迎來到測試系統管理介面'
} else if (process.env.BRANCH_ENV === 'reborn') {
  i18n.login.welcome = '歡迎來到安珀琢玉系統管理介面'
} else if (process.env.BRANCH_ENV === 'santea') {
  i18n.login.welcome = '歡迎來到聖諦亞健康管理系統介面'
} else if (process.env.BRANCH_ENV === 'ibeauty') {
  i18n.login.welcome = '歡迎來到聖緹雅醫美系統管理介面'
} else if (process.env.BRANCH_ENV === 'ibeautyTaichung') {
  i18n.login.welcome = '歡迎來到聖緹雅時尚系統管理介面'
} else if (process.env.BRANCH_ENV === 'ibeautyPlastic') {
  i18n.login.welcome = '歡迎來到聖緹雅整形外科客戶系統管理介面'
} else if (process.env.BRANCH_ENV === 'ibeautyHsinchu') {
  i18n.login.welcome = '歡迎來到聖緹雅美學客戶系統管理介面'
}

export default i18n
