import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from '../../../modules/sort';
import EnhancedTableRow from '../../../components/EnhancedTableRow';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../../components/SimpleTableToolbar';
import ExpandButton from '../../../components/ExpandButton';
import SearchBox from '../../../components/SearchBox';

function PrescriptionSign({ kardexes, userMapping }) {
  const { formatMessage } = useIntl()
  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');

  const kardex = []
  if (kardex.length === 0) {
    Object.keys(kardexes).forEach(k => {
      if (kardexes[k].controlledDrug && kardexes[k].prescription) {
        kardex.push(
          { ...kardexes[k], type: 'normal' },
          { ...kardexes[k], type: 'controlledDrugs' }
        )
      } else if (!kardexes[k].controlledDrug && kardexes[k].prescription) {
        kardex.push({ ...kardexes[k], type: 'normal' })
      } else if (kardexes[k].controlledDrug && !kardexes[k].prescription) {
        kardex.push({ ...kardexes[k], type: 'controlledDrugs' })
      }
    })
  }

  const headerCells = [
    { text: 'type', sort: 'type' },
    { text: 'displayId', sort: 'displayId' },
    { text: 'date', sort: 'date' },
    { text: 'dosingDay', sort: 'dosingDay' },
    { text: 'medicines', sort: 'medicines' },
    { text: 'doctor', sort: 'doctor' },

  ].map(c => { c.text = formatMessage({ id: `prescriptionSign.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'type' },
    { field: 'displayId' },
    { field: 'date' },
    { field: 'dosingDay' },
    { field: 'medicines' },
    { field: 'doctor' },
  ]

  const filterItems = [
    { name: 'type' },
    { name: 'displayId' },
    { name: 'date' },
    { name: 'doctor' },
  ].map(i => { i.text = formatMessage({ id: `prescriptionSign.table.${i.name}` }); return i })

  function filterByText() {
    if (currentFilter.name === 'type') {
      return kardex.filter(s => formatMessage({ id: `prescriptionSign.table.${s.type}` }).toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'displayId') {
      return kardex.filter(s => String(s.displayId).toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'date') {
      return kardex.filter(s => s.date.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'doctor') {
      return kardex.filter(s => userMapping[s.doctor] && userMapping[s.doctor].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatData = (kardex) => {
    const newData = { ...kardex }
    if (newData.type === 'normal') {
      newData.dosingDay = newData.prescription.dosingDay
      if (newData.prescription.medicines) {
        newData.medicines = Object.keys(newData.prescription.medicines).map(key => {
          return newData.prescription.medicines[key].name
        }).join(' ,')
      }
    } else if (newData.type === 'controlledDrugs') {
      newData.dosingDay = newData.controlledDrug.dosingDay
      if (newData.controlledDrug.medicines) {
        newData.medicines = Object.keys(newData.controlledDrug.medicines).map(key => {
          return newData.controlledDrug.medicines[key].name
        }).join(' ,')
      }
    }

    newData.doctor = userMapping[newData.doctor] && userMapping[newData.doctor].displayName
    newData.type = formatMessage({ id: `prescriptionSign.table.${newData.type}` })


    return newData
  }


  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function openPrescriptionSign(kardex) {
    if (kardex.type === '一般處方箋') {
      window.open(`/patients/${kardex.patient}/kardexes/${kardex.id}/prescription`)
    } else {
      window.open(`/patients/${kardex.patient}/kardexes/${kardex.id}/controlledDrug`)
    }
  }

  const filterKardex = currentFilter && currentFilter.text ? filterByText() : kardex
  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'noMessage'}
            bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
            toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filterKardex.length}
              />
              <TableBody>
                {stableSort(filterKardex.map(r => formatData(r)), getComparator(order, orderBy)).map(kardex => (
                  <EnhancedTableRow
                    key={`${kardex.type}_${kardex.id}`}
                    rowCells={rowCells}
                    cellData={kardex}
                    onRowClick={() => openPrescriptionSign(kardex)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

PrescriptionSign.propTypes = {
  kardexes: PropTypes.arrayOf(PropTypes.object.isRequired),
  userMapping: PropTypes.object.isRequired,
};

export default PrescriptionSign;
