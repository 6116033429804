import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import firebase from 'firebase/app';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputMask from 'react-input-mask';
import DeleteIcon from '@mui/icons-material/Delete';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ButtonProgress from '../components/ButtonProgress';
import { objectToArray } from '../modules/data';
import DatePickerField from '../components/DatePickerField';
import EnhancedTableToolbar from '../components/EnhancedTableToolbar';
import EnhancedTableRow from '../components/EnhancedTableRow';
import EnhancedTableHead from '../components/EnhancedTableHead';
import { getComparator, stableSort } from '../modules/sort';
import { formatName } from '../modules/uitls';
import SearchBox from '../components/SearchBox';

const dataMapping = {
    smsType: ['immediately', 'schedule']
}

function SelectPatientDialog({ defaultSelectedItems, handleClose, handleSave, patients, size = 'lg', dialogTitle, userMapping }) {
    const { formatMessage } = useIntl()
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {}));
    const [currentFilter, setCurrentFilter] = useState(null);
    const filteredPatient = filteredPatients()
    const filteredselection = filteredPatient.filter(s => selectedItems[s.id]).reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})

    const _headerCells = [
        { text: 'name', sort: 'name', order: 0 },
        { text: 'birthDate', sort: 'birthDate', order: 1 },
        { text: 'phone', sort: 'phone', order: 2 },
        { text: 'salesRepName', sort: 'salesRepName', order: 3 },
    ]

    const _rowCells = [
        { field: 'name', order: 0 },
        { field: 'birthDate', order: 1 },
        { field: 'phone', order: 2 },
        { field: 'salesRepName', order: 3 },
    ]

    if (['santea', 'lexcellence'].includes(process.env.BRANCH_ENV)) {
        _headerCells.push({ text: 'comment', sort: 'comment', order: 4 })
        _rowCells.push({ field: 'comment', order: 4 },)
    }

    const headerCells = _headerCells
        .map(c => { c.text = formatMessage({ id: `smsList.dialog.patient.${c.text}` }); return c })
        .sort((a, b) => a.order - b.order)
    const rowCells = _rowCells.sort((a, b) => a.order - b.order)

    const filterItems = [
        { name: 'name' },
        { name: 'birthDate', },
        { name: 'phone' },
        { name: 'salesRepName' },
    ].map(i => { i.text = formatMessage({ id: `smsList.dialog.patient.${i.name}` }); return i })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = filteredPatient.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
            setSelectedItems(newSelecteds);
            return;
        }
        setSelectedItems({});
    };

    const handleCheckboxClick = (id) => {
        const selected = selectedItems[id] || false
        if (selected) {
            const newSelecteds = { ...selectedItems }
            delete newSelecteds[id]
            setSelectedItems(newSelecteds);
        } else {
            const newSelecteds = { ...selectedItems, [id]: true }
            setSelectedItems(newSelecteds);
        }
    }

    function formatData(patient) {
        const newData = { ...patient }
        newData.name = formatName(newData.name)
        newData.salesRepName = userMapping[newData.salesRep] ? userMapping[newData.salesRep].displayName : ''

        return newData
    }

    function filteredPatients() {
        if (!currentFilter) {
            return patients
        }
        let items = [...patients]

        if (currentFilter) {
            if (currentFilter.name === 'salesRepName') {
                items = items.filter(s => userMapping[s.salesRep] && userMapping[s.salesRep].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
            } else {
                items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
            }
        }
        return items
    }

    const onFilterChanged = (name, text) => {
        if (text !== '') {
            setCurrentFilter({ name, text })
        } else {
            setCurrentFilter(null)
        }
    }

    const onApply = (patient) => {
        const selectedPatients = patients.filter(s => selectedItems[s.id]).map(s => ({
            name: s.name,
            birthDate: s.birthDate,
            phone: s.phone,
            salesRep: s.salesRep,
            id: s.id,
        }))
        handleSave(selectedPatients)
        handleClose()
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={size}
            open={true}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ flexGrow: 1, zIndex: 2000 }}>
                    <Toolbar sx={{ pl: '16px', pr: '8px' }}>
                        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
                            <FormattedMessage id="smsList.dialog.patient.title" />
                        </Typography>
                        <SearchBox autoFocus filterItems={filterItems} onFilterChanged={onFilterChanged} />
                    </Toolbar>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <EnhancedTableHead
                                headerCells={headerCells}
                                numSelected={Object.keys(filteredselection).length}
                                order={order}
                                orderBy={orderBy}
                                onCheckboxClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={filteredPatient.length}
                            />
                            <TableBody>
                                {stableSort(filteredPatient.map(r => formatData(r)), getComparator(order, orderBy)).map(patient => (
                                    <EnhancedTableRow
                                        key={patient.id}
                                        rowCells={rowCells}
                                        cellData={patient}
                                        onCheckboxClick={(e) => handleCheckboxClick(patient.id)}
                                        selected={selectedItems[patient.id] || false}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose} color="primary">
                    <FormattedMessage id="button.cancel" />
                </Button>
                <Button variant="contained" onClick={onApply} color="primary">
                    <FormattedMessage id="button.ok" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SelectPatientDialog.propTypes = {
    defaultSelectedItems: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        birthDate: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    })),
    handleClose: PropTypes.func.isRequired,
    patients: PropTypes.arrayOf(PropTypes.object).isRequired,
    dialogTitle: PropTypes.string.isRequired,
    handleSave: PropTypes.func.isRequired,
    userMapping: PropTypes.object,
    size: PropTypes.string,
};

function SendSms({ dialogTitle, handleClose, patientList, userMapping, type, kardex }) {
    const { formatMessage } = useIntl()
    const [openSelectDialog, setOpenSelectDialog] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState(false);
    const [smsTemplates, setSmsTemplates] = useState(null)
    const [smsBalance, setSmsBalance] = useState(null)
    const [selectedItems, setSelectedItems] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const defaultData = {
        content: '',
        contentType: '',
        smsType: 'immediately',
        date: new Date(),
        patient: type === 'appointment' ? [{
            birthDate: kardex.patient.birthDate,
            id: kardex.appointment.patient,
            name: kardex.patient.name,
            phone: kardex.patient?.phone ?? '',
            salesRep: kardex.patient?.salesRep ?? ''
        }] : [],
    }

    const [smsData, setSmsData] = useState(defaultData)

    useEffect(() => {
        if (!smsBalance) {
            findSmsBalance()
        }
        return () => { }
    }, []);

    useEffect(() => {
        const ref = firebase.database().ref('smsTemplates')
        const onDataChange = ref.on('value', snapshot => {
            const data = objectToArray(snapshot.val())
            setSmsTemplates(data)
            const newData = data[0]
            let newContent = ''
            if (type === 'appointment' && kardex) {
                const date = kardex.appointment.date.split('-')
                const week = dayjs(kardex.appointment.date).format('ddd')
                const treatment = kardex.treatments.filter(t => t.type === 'product')
                const meridiem = kardex.appointment.time < '12:00' ? '上午' :
                    kardex.appointment.time >= '12:00' && kardex.appointment.time < '13:00' ? '中午' : '下午'
                newContent = newData.template
                    .replace('{$patientName}', kardex.patient.name)
                    .replace('{$personalTitle}', kardex.patient.gender === 'male' ? '先生' : '小姐')
                    .replace('{$month}', date[1])
                    .replace('{$day}', date[2])
                    .replace('{$week}', week)
                    .replace('{$meridiem}', meridiem)
                    .replace('{$salesRepName}', kardex.salesRep?.displayName ?? '')
                    .replace('{$startTime}', kardex.appointment.time)
                    .replace('{$doctorName}', kardex.doctor?.displayName ?? '')
                    .replace('{$treatment}', treatment[0]?.name)
            } else {
                newContent = newData.template
            }
            setSmsData({
                ...smsData,
                content: newContent,
                contentType: newData.name,
            })
        });
        return () => ref.off('value', onDataChange)
    }, []);

    if (!smsTemplates) {
        return ('Loading...')
    }

    async function findSmsBalance() {
        try {
            const res = await firebase.functions().httpsCallable('smsBalance')({})
            if (res) {
                setSmsBalance(res.data.point)
            }
        } catch (ex) {
            console.log(ex)
            setSmsBalance(0)
        }
    }

    const headerCells = [
        { text: 'name', sort: 'name' },
        { text: 'birthDate', sort: 'birthDate' },
        { text: 'phone', sort: 'phone' },
        { text: 'salesRepName', sort: 'salesRepName' },
    ].map(c => { c.text = formatMessage({ id: `smsList.dialog.patient.${c.text}` }); return c })

    const rowCells = [
        { field: 'name' },
        { field: 'birthDate' },
        { field: 'phone' },
        { field: 'salesRepName' },
    ]

    const _fields = [
        { name: 'contentType', type: 'select', md: 3, sm: 3, order: 0 },
        { name: 'content', type: 'text', md: 12, sm: 12, multiline: true, order: 1 },
        { name: 'smsType', type: 'select', md: 3, sm: 3, order: 2 },
    ]

    if (smsData.smsType === 'schedule') {
        _fields.push(
            { name: 'date', type: 'time', md: 3, sm: 3, required: true, order: 3 },
            { name: 'time', type: 'text', md: 3, sm: 3, mask: '99:99', required: true, order: 4 },
        )
    }

    const fields = _fields.sort((a, b) => {
        return a.order - b.order
    }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

    function updateData(field, value) {
        let newValue = value

        let err = validateField(field, value)
        let newData = { ...smsData, [field.name]: newValue, [`${field.name}_err`]: err }
        let newContent = ''
        if (field.name === 'contentType') {
            smsTemplates.forEach(s => {
                if (s.name === newData.contentType) {
                    if (type === 'appointment' && kardex) {
                        const date = kardex.appointment.date.split('-')
                        const week = dayjs(kardex.appointment.date).format('ddd')
                        const treatment = kardex.treatments.filter(t => t.type === 'product')
                        const meridiem = kardex.appointment.time < '12:00' ? '上午' :
                            kardex.appointment.time >= '12:00' && kardex.appointment.time < '13:00' ? '中午' : '下午'
                        newContent = s.template
                            .replace('{$patientName}', kardex.patient.name)
                            .replace('{$personalTitle}', kardex.patient.gender === 'male' ? '先生' : '小姐')
                            .replace('{$month}', date[1])
                            .replace('{$day}', date[2])
                            .replace('{$week}', week)
                            .replace('{$meridiem}', meridiem)
                            .replace('{$salesRepName}', kardex.salesRep?.displayName ?? '')
                            .replace('{$startTime}', kardex.appointment.time)
                            .replace('{$doctorName}', kardex.doctor?.displayName ?? '')
                            .replace('{$treatment}', treatment[0]?.name)
                    } else {
                        newContent = s.template
                    }
                }
            })
            newData.content = newContent
        }

        setSmsData(newData)
    }

    function validateField(field, value) {
        if (field.required && value === '') {
            return formatMessage({ id: 'form.field.isRequired' })
        }

        if (field.name === 'time') {
            const newTime = String(value).split(':')
            if (newTime[0] > '24' || newTime[1] > '60') {
                return formatMessage({ id: 'form.date.formatError' })
            }
        }

        return ''
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = smsData.patient.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
            setSelectedItems(newSelecteds);
            return;
        }
        setSelectedItems({});
    }

    function handleCheckboxClick(id) {
        const selected = selectedItems[id] || false
        if (selected) {
            const newSelecteds = { ...selectedItems }
            delete newSelecteds[id]
            setSelectedItems(newSelecteds);
        } else {
            const newSelecteds = { ...selectedItems, [id]: true }
            setSelectedItems(newSelecteds);
        }
    }

    function onSmsDataChanged(field, value) {
        updateData({ name: `${field.name}` }, value);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function onPatientChanged(patient) {

        if (patient.length) {
            updateData({ name: 'patient' }, patient);
        }
    }

    function onDeleteItems() {
        const patients = smsData.patient.filter(m => !selectedItems[m.id])
        if (patients.length !== smsData.length) {
            updateData({ name: 'patient' }, patients);
            setSelectedItems({});
        }
    }

    function formatData(patient) {
        const newData = { ...patient }
        newData.salesRepName = userMapping[newData.salesRep] ? userMapping[newData.salesRep].displayName : ''

        return newData
    }

    async function saveSms() {
        setLoadingApprove(true)
        try {
            const res = await firebase.functions().httpsCallable('saveSmsHistory')({ ...smsData })
            const key = res.data.key
            if (key !== '') {
                await firebase.functions().httpsCallable('sendSMS')({ smsHistoryId: key })
            }
            setLoadingApprove(false)
            handleClose()
        } catch (ex) {
            console.log(ex)
            setLoadingApprove(false)
            handleClose()
        }
    }

    function createField(field) {
        let newValue = smsData[field.name]
        let labelText = ''
        let selectFields;

        if (field.type === '-') {
            return <Grid item key={field.order} xs={12} sm={field.sm} md={12}><Divider /></Grid>
        } else if (field.type === 'time') {
            labelText = formatMessage({ id: `smsList.dialog.${field.name}` })

            return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
                <DatePickerField
                    required
                    fullWidth
                    label={labelText}
                    value={dayjs(newValue)}
                    minDate={new Date()}
                    onChange={date => updateData({ name: `${field.name}` }, date)}
                    invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
                />
            </Grid>
        } else if (field.type === 'text') {
            labelText = formatMessage({ id: `smsList.dialog.${field.name}` })

            if (field.name === 'time') {
                return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
                    <InputMask
                        mask={field.mask}
                        maskChar=" "
                        label={labelText}
                        onChange={e => { onSmsDataChanged(field, e.target.value) }}
                        fullWidth
                    >
                        {() => <TextField
                            type="text"
                            size="small"
                            label={labelText}
                            variant="outlined"
                            onCompositionStart={
                                e => {
                                    e.target.addEventListener('input', e2 => {
                                        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                                            e2.preventDefault()
                                            e2.stopPropagation()
                                            e2.stopImmediatePropagation()
                                        }
                                    }, { once: true })
                                }
                            }
                            error={smsData[`${field.name}_err`] ? true : false}
                            helperText={smsData[`${field.name}_err`]}
                            fullWidth
                        />}
                    </InputMask>
                </Grid>
            } else {
                return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
                    <TextField
                        multiline={field.multiline}
                        type="text"
                        required={field.required}
                        label={labelText}
                        value={newValue}
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
                        error={smsData[`${field.name}_err`] ? true : false}
                        helperText={smsData[`${field.name}_err`]}
                    >
                    </TextField>
                </Grid>
            }
        } else if (field.type === 'select') {
            if (field.name === 'contentType') {
                selectFields = smsTemplates

                return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
                    <TextField
                        multiline={field.multiline}
                        type="text"
                        required={field.required}
                        select
                        label={labelText}
                        value={newValue}
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
                        error={smsData[`${field.name}_err`] ? true : false}
                        helperText={smsData[`${field.name}_err`]}
                    >
                        {
                            selectFields.map((selects, idx) => {
                                return <MenuItem key={selects.id} value={selects.name}>{selects.name}</MenuItem>
                            })
                        }
                    </TextField>
                </Grid>
            } else {
                selectFields = dataMapping[`${field.name}`]

                return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
                    <TextField
                        multiline={field.multiline}
                        type="text"
                        required={field.required}
                        select
                        label={labelText}
                        value={newValue}
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
                        error={smsData[`${field.name}_err`] ? true : false}
                        helperText={smsData[`${field.name}_err`]}
                    >
                        {
                            selectFields.map((selects, idx) => {
                                return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `smsList.dialog.${field.name}.${selects}` })}</MenuItem>
                            })
                        }
                    </TextField>
                </Grid>
            }
        }
    }

    return (
        <>
            {openSelectDialog && <SelectPatientDialog
                defaultSelectedItems={smsData.patient}
                handleClose={() => setOpenSelectDialog(false)}
                handleSave={onPatientChanged}
                patients={patientList}
                dialogTitle={formatMessage({ id: 'selectPatientDialog.root' })}
                userMapping={userMapping}
            />}
            <Dialog
                fullWidth={true}
                open={true}
                onClose={handleClose}
                maxWidth={'md'}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', fontSize: '14px', marginRight: '8px' }}>
                            {`${formatMessage({ id: 'smsList.dialog.balance' })}: ${smsBalance ? smsBalance : 0}`}
                        </div>
                    </Grid>
                </div>
                <DialogContent>
                    <Grid container spacing={2}>
                        {fields.map(field => createField(field))}
                        <div style={{ margin: '8px', width: '100%' }}>
                            <EnhancedTableToolbar
                                title="smsList.dialog.patient.root"
                                selectdMessage="merchandise.editMerchandise.table.selected"
                                numSelected={Object.keys(selectedItems).length}
                                toolbox={
                                    <Button
                                        sx={{ margin: '8px', whiteSpace: 'nowrap' }}
                                        variant="contained"
                                        color="primary"
                                        disabled={type === 'appointment'}
                                        onClick={() => setOpenSelectDialog(true)}
                                    >
                                        <FormattedMessage id={'smsList.dialog.patient.add'} />
                                    </Button>
                                }
                                toolboxForSelection={
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={type === 'appointment'}
                                        startIcon={<DeleteIcon />}
                                        style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                                        onClick={onDeleteItems}
                                    >
                                        <FormattedMessage id="smsList.dialog.patient.edit" />
                                    </Button>
                                }
                            />
                            <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                    <EnhancedTableHead
                                        headerCells={headerCells}
                                        numSelected={Object.keys(selectedItems).length}
                                        onCheckboxClick={handleSelectAllClick}
                                        rowCount={smsData.patient.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {stableSort(smsData.patient.map(r => formatData(r)), getComparator(order, orderBy)).map(patient => (
                                            <EnhancedTableRow
                                                key={`${patient.id}`}
                                                rowCells={rowCells}
                                                cellData={patient}
                                                onCheckboxClick={(e) => handleCheckboxClick(patient.id)}
                                                selected={selectedItems[patient.id] || false}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonProgress
                        handleClick={() => saveSms()}
                        handleClose={handleClose}
                        loading={loadingApprove}
                        disabledField={smsData.patient.length === 0}
                        buttonText='button.save'
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}

SendSms.propTypes = {
    dialogTitle: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    patientList: PropTypes.arrayOf(PropTypes.object.isRequired),
    userMapping: PropTypes.object,
    type: PropTypes.string.isRequired,
    kardex: PropTypes.object
};

export default SendSms;
