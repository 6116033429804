const i18n = {
  'punchClockRevise': {
    'welcome': 'Hi, {name}',
    'applyForPunchClockRevise': '申請忘刷卡',
    'editPunchClockRevise': '編輯申請',
    'table': {
      'date': '忘刷卡日期',
      'time': '忘刷卡時間',
      'reason': '原因',
      'state': '狀態',
      'pending': '等候中',
      'history': '歷史紀錄',
      'createdBy': '申請人',
      'createdAt': '填表日期',
      'hour': '時',
      'minute': '分'
    },
    'punchClockReviseAlert': {
      'title': '缺少欄位',
      'content': '必須填寫原因欄位',
    },
    'list': {
      'step': '步驟',
    },
    'detail': {
      'note': '備註',
      'rejectReason': '否決原因',
      'editReason': '修改原因',
      'voidReason': '作廢原因'
    },
  },
  'punchClockReviseSent': {
    'punchClockReviseApplicationSent': '忘刷卡申請送出',
    'sentMessage': '現正等待審核中，審核完畢後將會通知',
    'newBack': '回到「忘刷卡申請」',
  },
  'punchClockReviseStatus': {
    'pending': '等候中',
    'done': '同意',
    'reject': '否決',
    'void': '作廢'
  }
}

export default i18n