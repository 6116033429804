import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import { createUseStyles } from 'react-jss';

import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { objectToArray } from '../../modules/data'
import StatusIndicator from '../../components/StatusIndicator';
import { calculateAge } from '../../modules/time';
import { formatName } from '../../modules/uitls';
import { treatmentColorAndBgColor } from '../../modules/treatment';
import SendSms from '../../components/SendSms';

const appointmentStatusStrings = [
  'not-confirmed',
  'profile-needed',
  'ready',
  'arrived',
  'late',
  'cancelled',
  'complete',
  'cancelanotherappointment',
  'not-arrived',
]

const useStyles = createUseStyles({
  popover: {
    width: '760px',
    height: '430px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 12px 0 rgba(130, 210, 220, 0.3)',
    padding: '20px 20px 0 20px',
    overflow: 'hidden'
  },
  wrapper: {
    flexDirection: 'row',
    display: 'flex',
    minHeight: '323px',
    maxHeight: '323px'
  },
  left: {
    marginTop: '-16px',
    flex: '1 0 0',
    paddingRight: '25px',
    minWidth: '50%',
    maxWidth: '50%'
  },
  right: {
    flex: '1 0 0',
    borderLeft: '1px solid #f0f0f0',
    paddingLeft: '17px',
    overflowY: 'scroll',
    '& h3': {
      marginTop: 0,
      color: '#7A7A7A',
      fontSize: '15px'
    }
  },
  statusSelect: {
    height: 'auto !important',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px'
  },
  statusAndRoom: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      display: 'inline-flex',
      alignItems: 'center',
      flex: '1 1 0',
    },
    '& div': {
      margin: '0 10px 0 0'
    },
    '& label': {
      color: '#A6A6A6',
      fontSize: '12px',
      flex: 0,
      paddingRight: '10px'
    }
  },
  customerDetails: {
    marginTop: '15px',
  },
  name: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#000000'
  },
  dob: {
    color: '#5D5D5D',
    fontSize: '13px',
    margin: '8px 0'
  },
  people: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  person: {
    color: '#A8A8A8',
    fontSize: '12px'
  },
  personRow: {
    fontSize: '15px',
    color: '#444444'
  },
  status: {
    width: '12px',
    height: '12px',
    background: '#79db0e',
    borderRadius: '12px',
    marginRight: '4px'
  },
  time: {
    fontSize: '13px',
    color: '#3B3B3B'
  },
  buttonsBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '50px',
  },
  buttonsRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonsLeft: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  menuIndex: {
    zIndex: 2100
  },
});

function StatusMenu({ appointment, handleChange }) {
  const classes = useStyles();

  return (
    <div className={classes.statusSelect}>
      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="standard"
          value={appointment.status}
          onChange={(e) => handleChange(appointment, 'status', e.target.value)}
          disableUnderline
        >
          {appointmentStatusStrings.map((status, i) => {
            return <MenuItem key={status} value={status} ><StatusIndicator status={status} position={''} />
              <FormattedMessage id={`appointment.popover.customerStatus.${status}`} />
            </MenuItem >
          })}
        </Select>
      </FormControl>
    </div>
  );
}

StatusMenu.propTypes = {
  appointment: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

function TreatmentsList({ treatment }) {
  let { backgroundColor } = treatmentColorAndBgColor(treatment)

  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
    userSelect: 'none',
    zIndex: 11,
    minHeight: '40px',
  }

  const mainWrap = {
    width: '100%'
  }

  const itemColor = {
    margin: '1px 0',
    width: '10px',
    minHeight: '100%',
    display: 'inline-flex',
    borderRadius: '5px',
    alignSelf: 'stretch'
  }

  const itemMain = {
    minHeight: '40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    userSelect: 'none',
  }

  const itemName = {
    flex: 1,
    padding: '0 10px',
    fontSize: '15px',
    color: '#414141'
  }

  const itemDuration = {
    padding: '0 20px',
    fontSize: '15px',
    color: '#757575'
  }

  return (
    <div style={rowStyle}>
      <div style={{ ...itemColor, backgroundColor: backgroundColor }} />
      <div style={mainWrap}>
        <div style={itemMain}>
          <div style={itemName}>
            {treatment.name}
          </div>
          <div style={itemDuration}>
            {treatment.duration} <FormattedMessage id={'appointment.sidebar.minutesShort'} />
          </div>
        </div>
      </div>
    </div>
  );
}

TreatmentsList.propTypes = {
  treatment: PropTypes.object.isRequired,
};

function AppointmentPopover({ ui, handleAppointmentChange, handlePopoverClose, onEditAppointment }) {
  const classes = useStyles();
  const { formatMessage } = useIntl()
  const [sendSmsDialog, setSendSmsDialog] = useState(false)
  const appointment = { ...ui.selectedAppointment }
  const menuOpen = Boolean(appointment);
  const userMapping = useSelector(state => state.firebase.data.users);
  const [patient, setPatient] = useState(null)
  const naString = <FormattedMessage id='appointment.popover.NA' />
  const doctor = userMapping[appointment.doctor] || null
  const nurse = userMapping[appointment.nurse] || null
  const salesRep = userMapping[appointment.salesRep] || null
  const treatments = objectToArray(appointment.treatments, 'order').filter(p => !p.parent)
  const totalDuration = treatments.reduce((acc, treatment) => (
    acc + parseInt(treatment.duration || 0)
  ), 0)
  let endTime = dayjs(`${appointment.date} ${appointment.time}`).add(totalDuration, 'minute').format('HH:mm')

  useEffect(() => {
    if (appointment.patient) {
      const ref = firebase.database().ref('patients').child(appointment.patient)
      const onDataChange = ref.on('value', snapshot => {
        setPatient(snapshot.val())
      });
      return () => ref.off('value', onDataChange)
    }
  }, [appointment.patient]);

  const handleClose = () => {
    setSendSmsDialog(false)
  }

  return (
    <div>
      {sendSmsDialog && <SendSms
        dialogTitle={formatMessage({ id: 'smsList.dialog.add' })}
        handleClose={handleClose}
        patientList={[]}
        userMapping={userMapping}
        type={'appointment'}
        kardex={{
          doctor: doctor,
          nurse: nurse,
          patient: patient,
          treatments: treatments,
          appointment: appointment,
          salesRep: salesRep
        }}
      />}
      <Popover
        id="menu-appbar"
        anchorEl={ui.selectedAppointmentEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handlePopoverClose}
      >
        <div className={classes.popover}>
          <div className={classes.wrapper}>
            <div className={classes.left}>
              <div className={classes.statusAndRoom}>
                <div>
                  <label><FormattedMessage id='appointment.popover.customerStatus.root' /></label>
                  <StatusMenu appointment={appointment} handleChange={handleAppointmentChange} />
                </div>
                <div>
                  <label><FormattedMessage id='appointment.popover.room' /></label>
                  <div>{appointment.room}</div>
                </div>
              </div>
              <div className={classes.customerDetails}>
                <div className={classes.name}>{patient ? formatName(patient?.name ?? '') : '未選擇客戶'}{patient && patient.customercode ? patient.customercode : ''}</div>
                <div className={classes.dob}>
                  {(patient && patient.gender) && <FormattedMessage id={`appointment.popover.gender.${patient.gender}`} />}
                  {patient && calculateAge(patient.birthDate)} {patient && <FormattedMessage id={'appointment.popover.yearsOld'} />}
                  {(patient && patient.birthDate) && <span><FormattedMessage id={'appointment.popover.birth'} /> : {patient.birthDate || naString}</span>}
                </div>
                <div className={classes.dob}>
                  {(patient && patient.phone) && <span><FormattedMessage id={'appointment.popover.phone'} />: {patient.phone || naString}</span>}
                </div>
                <div className={classes.dob}><FormattedMessage id={'appointment.popover.createdAt'} />: {dayjs(appointment.createdAt).format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
              <div className={classes.people}>
                <div className={classes.person}>
                  <FormattedMessage id='appointment.popover.doctor' />{doctor ? <div className={classes.personRow}>{doctor.displayName}</div> : <div>{naString}</div>}
                </div>
                <div className={classes.person}>
                  <FormattedMessage id='appointment.popover.nurse' /> {nurse ? <div className={classes.personRow}>{nurse.displayName}</div> : <div>{naString}</div>}
                </div>
                <div className={classes.person}>
                  <FormattedMessage id='appointment.popover.salesRep' /> {salesRep ? <div className={classes.personRow}>{salesRep.displayName}</div> : <div>{naString}</div>}
                </div>
              </div>
              <TextField
                fullWidth
                disabled
                style={{
                  marginTop: '10px'
                }}
                label={<FormattedMessage id='appointment.popover.comment' />}
                variant="outlined"
                value={appointment.comment}
                multiline
                minrows={5}
              />
            </div>
            <div className={classes.right}>
              <h3><FormattedMessage id='appointment.popover.arrangeTreatments' /> </h3>
              <div className={classes.time}>{appointment.time}</div>
              <div style={{ margin: '20px 0' }}>{treatments.map((treatment, i) => <TreatmentsList key={i} treatment={treatment} />)}</div>
              <div className={classes.time}>{endTime}</div>
            </div>
          </div>
          <div className={classes.buttonsBody}>
            <div className={classes.buttonsLeft}>
              {appointment.status !== 'cancelled' && appointment.status !== 'cancelanotherappointment' &&
                <Button
                  style={{
                    borderRadius: '16px',
                    border: '1px solid #d32f2f',
                    color: '#d32f2f',
                    lineHeight: 1,
                  }}
                  onClick={() => handleAppointmentChange(appointment, 'status', 'cancelled', true)}
                  autoFocus
                >
                  <FormattedMessage id={'appointment.popover.button.cancelAppointment'} />
                </Button>
              }
            </div>
            <div className={classes.buttonsRight}>
              {patient &&
                <Button
                  style={{
                    borderRadius: '16px',
                    border: '1px solid #d32f2f',
                    color: '#d32f2f',
                    lineHeight: 1,
                    marginRight: '10px'
                  }}
                  onClick={() => window.open(`/patients/${appointment.patient}/kardexes/${appointment.id}`)}
                  autoFocus
                >
                  <FormattedMessage id={'appointment.popover.button.kardex'} />
                </Button>
              }
              {patient &&
                <Button
                  style={{
                    borderRadius: '16px',
                    border: patient.noSms? '1px solid #A8A8A8' : '1px solid #d32f2f',
                    color: patient.noSms? '#A8A8A8' : '#d32f2f',
                    lineHeight: 1,
                    marginRight: '10px'
                  }}
                  onClick={() => setSendSmsDialog(true)}
                  autoFocus
                  disabled={patient.noSms}
                >
                  <FormattedMessage id={'appointment.popover.button.sendSms'} />
                </Button>
              }

              <Button
                style={{
                  borderRadius: '16px',
                  border: '1px solid #d32f2f',
                  color: '#d32f2f',
                  lineHeight: 1,
                }}
                onClick={() => onEditAppointment(appointment, 'edit')}
                autoFocus
              >
                <FormattedMessage id={'appointment.popover.button.edit'} />
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

AppointmentPopover.propTypes = {
  ui: PropTypes.object.isRequired,
  handleAppointmentChange: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.func.isRequired,
  onEditAppointment: PropTypes.func.isRequired,
};

export default AppointmentPopover;
