import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs'
import clsx from 'clsx'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import firebase from 'firebase/app';
import { createUseStyles } from 'react-jss'

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircularProgress from '@mui/material/CircularProgress';

import PunchClock from './PunchClock';
import ScheduleDoctorDate from './ScheduleDoctorDate';
import DatePickerField from '../../components/DatePickerField';
import { datesData } from '../../modules/uitls';
import { doctortPunchCount } from '../../modules/doctorModule';
import { tabletMedia } from '../../constants/index';
import { objectToArray } from '../../modules/data';
import ContextStore from '../../modules/context';
import useViewport from '../../hooks/windowSize';

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
const oddShiftType = ['reborn', 'santea'].includes(process.env.BRANCH_ENV) ?
  ['no', 'every_week', 'odd_weeks', 'next_weeks'] : ['no', 'no-plastic', 'every_week', 'odd_weeks', 'next_weeks']
const evenShiftType = ['reborn', 'santea'].includes(process.env.BRANCH_ENV) ?
  ['no', 'every_week', 'even_weeks', 'next_weeks'] : ['no', 'no-plastic', 'every_week', 'even_weeks', 'next_weeks']
const leaveType = ['leaves']
const drawerWidth = 240;

dayjs.extend(weekOfYear)
const useStyles = createUseStyles({
  scheduleTableContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: '270px',
    top: '60px',
    right: 0,
    backgroundColor: 'white',
  },
  scheduleTableColumn: {
    overflow: 'auto',
    position: 'absolute',
    maxHeight: 'calc(100vh - 64px - 60px)',
    width: 'calc(100vw - 270px - 150px)',
    [tabletMedia]: {
      display: 'none'
    }
  },
  scheduleTableHeader: {
    height: '60px',
    backgroundColor: '#f6f6f7',
    position: 'fixed',
    left: '160px + 270px',
    top: '64px',
    overflow: 'hidden',
    width: 'calc(100vw - 270px - 150px)',
    color: '#5f6578',
    fontSize: '16px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #828a99',
    [tabletMedia]: {
      display: 'none'
    }
  },
  dateTable: {
    position: 'relative',
    width: '116px',
    padding: '3px',
    flexShrink: 0,
    overflowY: 'scroll',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [tabletMedia]: {
      minHeight: '100px',
      padding: 0,
      backgroundColor: '#f6f6f7',
      width: '117px',
      borderLeft: '1px solid #828a99',
      borderRight: '1px solid #828a99',
      borderBottom: '1px solid #d9dce3',
    }
  },
  dateCloumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scheduleRow: {
    height: '100px',
    width: 'calc(100% - 430px)',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    [tabletMedia]: {
      display: 'none'
    }
  },
  scheduleRowTable: {
    position: 'relative',
    width: '116px',
    padding: '3px',
    borderBottom: '1px solid #efeff4',
    flexShrink: 0,
    overflowY: 'scroll',
  },
  scheduleRowTableOffDay: {
    position: 'relative',
    width: '116px',
    padding: '3px',
    borderBottom: '1px solid #efeff4',
    flexShrink: 0,
    overflowY: 'scroll',
    backgroundColor: '#f6f7f8'
  },
  scheduleRowCloumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  punchClock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    padding: '20px 20px'
  },
  punchClockList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  punchClockTime: {
    padding: '5px 0'
  },
  punchClockText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  punchClockButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '30px',
  },
  doctorShift: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px',
    fontSize: '14px'
  },
  doctorShiftText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '20px'
  },
  doctorShiftButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '50px'
  },
  drawer: {
    width: `calc(100vw - (430px + ${drawerWidth}px))`
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: 'blue'
  },
  buttonDelProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -60,
    color: 'blue'
  },
  mobileDate: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    height: '449px',
    borderBottom: '1px solid #828a99',
    borderTop: '1px solid #828a99',
    marginTop: '105px',
    overflow: 'scroll',
  },
  mobileTable: {
    display: 'flex',
    flexDirection: 'row',
  },
  mobileRow: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    width: '100%',
    borderRight: '1px solid #828a99',
    borderBottom: '1px solid #efeff4',
    padding: '6px 0'
  },
  mobileRowDayOff: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    width: '100%',
    borderRight: '1px solid #828a99',
    borderBottom: '1px solid #efeff4',
    backgroundColor: '#f6f7f8',
    padding: '6px 0'
  }
});

const dayMapping = {
  'Mo': '一',
  'Tu': '二',
  'We': '三',
  'Th': '四',
  'Fr': '五',
  'Sa': '六',
  'Su': '日'
}

function ScheduleDoctorRows({
  currentUser,
  month,
  year,
  staffData,
  staffKey,
  userRight,
  viewingStaff,
  isTablet,
  doctorShift,
  doctorSpecial,
  doctorLeave,
  punchClock,
  punchClockRevise,
  punchClockExceptions,
  onScrollRow
}) {
  const { formatMessage } = useIntl()
  const { drawerStatus } = useContext(ContextStore)
  const classes = useStyles();
  const { width, height } = useViewport()
  const [menuInfo, setMenuInfo] = useState(null)
  const [secondMenu, setSecondMenu] = useState(null)
  const [punchClockPathData, setPunchClockPathData] = useState([])
  const [punchClockData, setPunchClockData] = useState([])
  const [punchClockReviseOpen, setPunchClockReviseOpen] = useState(false)
  const [punchClockReviseData, setPunchClockReviseData] = useState({ hour: '09', minute: '00' })
  const [doctorShiftData, setDoctorShiftData] = useState({})
  const [doctorShiftId, setDoctorShiftId] = useState({})
  const [doctorLeaveData, setDoctorLeaveData] = useState({})
  const [doctorLeaveId, setDoctorLeaveId] = useState({})
  const [timeError, setTimeError] = useState(false)
  const menuOpen = Boolean(menuInfo);
  const headerDatesRef = useRef()
  const dates = datesData(year, month)
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const onEdit = (event, content, data, revise) => {
    if (content === 'punchClock') {
      let newEvent = new PunchClock(dayjs(data.date), event, revise ? revise : [])
      setPunchClockData(newEvent.list)
      setPunchClockPathData(data)
    } else {
      const startTime = event.startTime.split(':')
      const endTime = event.endTime.split(':')
      const newData = {
        repeats: event.repeats,
        startDate: dayjs(event.firstDate),
        endDate: event.lastDate ? dayjs(event.lastDate) : null,
        startHour: startTime[0],
        startMin: startTime[1],
        endHour: endTime[0],
        endMin: endTime[1],
      }

      if (content === 'editDoctorShift') {
        setDoctorShiftId(event.id)
        setDoctorShiftData(newData)
      } else {
        setDoctorLeaveId(event.id)
        setDoctorLeaveData(newData)
      }

    }

    setSecondMenu({ [content]: true })
  }

  function updateData(field, value, type) {
    if (type === 'punchClock') {
      let newValue = value
      let newData = { ...punchClockReviseData, [field.name]: newValue }

      setPunchClockReviseData(newData)
    } else {
      let newValue = value

      if (type === 'doctorShift') {
        let newData = { ...doctorShiftData, [field.name]: newValue }
        if (field.name === 'repeats') {
          if (['no', 'no-plastic'].includes(newData.repeats)) {
            newData.endDate = newData.startDate
          } else {
            newData.endDate = null
          }
        } else if (field.name === 'startDate') {
          if (['no', 'no-plastic'].includes(newData.repeats)) {
            newData.endDate = newData.startDate
          }
        }

        setDoctorShiftData(newData)
      } else {
        let newData = { ...doctorLeaveData, [field.name]: newValue }
        if (field.name === 'startDate') {
          newData.endDate = value
        }
        setDoctorLeaveData(newData)
      }
    }
  }

  async function saveDoctorLeave() {
    if (dayjs(`${dayjs(doctorLeaveData.startDate).format('YYYY-MM-DD')} ${doctorLeaveData.startHour}:${doctorLeaveData.startMin}`, 'YYYY-MM-DD HH:mm')
      .isAfter(dayjs(`${dayjs(doctorLeaveData.startDate).format('YYYY-MM-DD')} ${doctorLeaveData.endHour}:${doctorLeaveData.endMin}`, 'YYYY-MM-DD HH:mm'))) {
      setTimeError(true)
      return false
    } else {
      setTimeError(false)
    }
    setLoading(true)
    if (secondMenu.editDoctorLeave) {
      const newData = {
        doctor: menuInfo.staff.id,
        firstDate: dayjs(doctorLeaveData.startDate).format('YYYY-MM-DD'),
        lastDate: dayjs(doctorLeaveData.endDate).format('YYYY-MM-DD'),
        startTime: `${doctorLeaveData.startHour}:${doctorLeaveData.startMin}`,
        endTime: `${doctorLeaveData.endHour}:${doctorLeaveData.endMin}`,
        repeats: doctorLeaveData.repeats,
        updatedAt: new Date(),
        updatedBy: currentUser.key,
      }

      try {
        await firebase.firestore().collection('doctorLeaves').doc(doctorLeaveId).update(newData)
        setLoading(false)
        handleClose();
      } catch (ex) {
        console.log(ex)
      }
    } else {
      const newData = {
        doctor: menuInfo.staff.id,
        firstDate: dayjs(doctorLeaveData.startDate).format('YYYY-MM-DD'),
        lastDate: dayjs(doctorLeaveData.endDate).format('YYYY-MM-DD'),
        startTime: `${doctorLeaveData.startHour}:${doctorLeaveData.startMin}`,
        endTime: `${doctorLeaveData.endHour}:${doctorLeaveData.endMin}`,
        repeats: doctorLeaveData.repeats,
        createdAt: new Date(),
        createdBy: currentUser.key,
      }

      try {
        await firebase.firestore().collection('doctorLeaves').doc().set(newData)
        setLoading(false)
        handleClose();
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  async function saveDoctorShift() {
    if (dayjs(`${dayjs(doctorShiftData.startDate).format('YYYY-MM-DD')} ${doctorShiftData.startHour}:${doctorShiftData.startMin}`, 'YYYY-MM-DD HH:mm')
      .isAfter(dayjs(`${dayjs(doctorShiftData.startDate).format('YYYY-MM-DD')} ${doctorShiftData.endHour}:${doctorShiftData.endMin}`, 'YYYY-MM-DD HH:mm'))) {
      setTimeError(true)
      return false
    } else {
      setTimeError(false)
    }
    setLoading(true)
    if (secondMenu.editDoctorShift) {
      if (['no', 'no-plastic'].includes(doctorShiftData.repeats) && !doctorShiftData.endDate) {
        return false
      } else {
        const newData = {
          doctor: menuInfo.staff.id,
          firstDate: dayjs(doctorShiftData.startDate).format('YYYY-MM-DD'),
          lastDate: doctorShiftData.endDate ? dayjs(doctorShiftData.endDate).format('YYYY-MM-DD') : null,
          startTime: `${doctorShiftData.startHour}:${doctorShiftData.startMin}`,
          endTime: `${doctorShiftData.endHour}:${doctorShiftData.endMin}`,
          repeats: doctorShiftData.repeats,
          updatedAt: new Date(),
          updatedBy: currentUser.key,
        }

        try {
          await firebase.firestore().collection('doctorShifts').doc(doctorShiftId).update(newData)
          setLoading(false)
          handleClose();
        } catch (ex) {
          console.log(ex)
        }
      }
    } else {
      if (['no', 'no-plastic'].includes(doctorShiftData.repeats) && !doctorShiftData.endDate) {
        return false
      } else {
        const newData = {
          doctor: menuInfo.staff.id,
          firstDate: dayjs(doctorShiftData.startDate).format('YYYY-MM-DD'),
          lastDate: doctorShiftData.endDate ? dayjs(doctorShiftData.endDate).format('YYYY-MM-DD') : null,
          startTime: `${doctorShiftData.startHour}:${doctorShiftData.startMin}`,
          endTime: `${doctorShiftData.endHour}:${doctorShiftData.endMin}`,
          repeats: doctorShiftData.repeats,
          createdAt: new Date(),
          createdBy: currentUser.key,
        }

        try {
          await firebase.firestore().collection('doctorShifts').doc().set(newData)
          handleClose();
          setLoading(false)
        } catch (ex) {
          console.log(ex)
        }
      }
    }
  }

  async function addPunchClockRevise() {
    try {
      await firebase.firestore().collection('punchClock').doc(punchClockPathData.date).update({
        [`revise.${punchClockPathData.staffId}`]: firebase.firestore.FieldValue.arrayUnion(`${punchClockReviseData.hour}:${punchClockReviseData.minute}`)
      }, { merge: true })
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  async function deleteDoctorLeave() {
    setDeleteLoading(true)
    try {
      await firebase.firestore().collection('doctorLeaves').doc(doctorLeaveId).delete();
      setDeleteLoading(false)
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  async function deleteDoctorShift() {
    setDeleteLoading(true)
    try {
      await firebase.firestore().collection('doctorShifts').doc(doctorShiftId).delete();
      setDeleteLoading(false)
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  async function deletePunchClockRevise(data) {
    try {
      await firebase.firestore().collection('punchClock').doc(punchClockPathData.date).update({
        [`revise.${punchClockPathData.staffId}`]: firebase.firestore.FieldValue.arrayRemove(data.time)
      }, { merge: true })
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  function openAddMenu() {
    if (secondMenu.punchClock) {
      return (
        <div className={classes.punchClock}>
          {formatMessage({ id: 'schedule.anchor.punchClock' })}
          {punchClockData.map(pc => {
            return (
              <div className={classes.punchClockList} key={pc.time}>
                <div style={{ color: pc.revised ? 'blue' : '' }} className={classes.punchClockTime}>{pc.time}</div>
                {pc.revised &&
                  <IconButton
                    style={{ marginLeft: '50px', color: 'blue' }}
                    onClick={() => deletePunchClockRevise(pc)}
                    size="large">
                    <HighlightOffIcon></HighlightOffIcon>
                  </IconButton>
                }
              </div>
            );
          })}
          {punchClockReviseOpen && <div className={classes.punchClockText}>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              style={{ marginRight: '10px' }}
              onChange={(e) => updateData({ name: 'hour' }, e.target.value, 'punchClock')}
              value={punchClockReviseData.hour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              onChange={(e) => updateData({ name: 'minute' }, e.target.value, 'punchClock')}
              value={punchClockReviseData.minute}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
            <Button style={{ color: 'red', marginTop: '8px' }} onClick={() => addPunchClockRevise()}>
              <AddCircleIcon></AddCircleIcon>
            </Button>
          </div>}
          <div className={classes.leaveButton}>
            <Button style={{ marginRight: '10px' }} variant="contained" onClick={handleClose} color="inherit">
              {formatMessage({ id: 'button.close' })}
            </Button>
            <Button variant="contained" onClick={() => setPunchClockReviseOpen(true)} color="secondary">
              {formatMessage({ id: 'button.punchClockRevise' })}
            </Button>
          </div>
        </div>
      );
    } else {
      const dateM = dayjs(menuInfo.date);
      const weeks = dateM.week() - dayjs(dateM).startOf('month').week() + 1;

      return (
        <div className={classes.doctorShift}>
          {secondMenu.doctorShift || secondMenu.editDoctorShift ?
            formatMessage({ id: 'schedule.anchor.add_doctorShift' }) :
            formatMessage({ id: 'schedule.anchor.add_doctorLeave' })
          }
          <TextField
            margin="dense"
            multiline
            variant="outlined"
            size="small"
            style={{ marginRight: '10px' }}
            disabled
            value={menuInfo.staff.displayName}
          />
          {secondMenu.doctorShift || secondMenu.editDoctorShift ?
            formatMessage({ id: 'schedule.doctor.repeats' }) :
            formatMessage({ id: 'schedule.doctor.leaveType' })
          }
          <TextField
            margin="dense"
            multiline
            variant="outlined"
            size="small"
            select
            style={{ marginRight: '10px' }}
            onChange={
              (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                updateData({ name: 'repeats' }, e.target.value, 'doctorShift') :
                updateData({ name: 'repeats' }, e.target.value, 'doctorLeave')
            }
            value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.repeats : doctorLeaveData.repeats}
          >
            {secondMenu.doctorShift || secondMenu.editDoctorShift ?
              weeks % 2 === 1 ? oddShiftType.map(repeat => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={repeat} value={repeat}>{formatMessage({ id: `schedule.doctor.typeName.${repeat}` })}</MenuItem>
              }) :
                evenShiftType.map(repeat => {
                  return <MenuItem style={{ padding: '0px 0 0 5px' }} key={repeat} value={repeat}>{formatMessage({ id: `schedule.doctor.typeName.${repeat}` })}</MenuItem>
                }) :
              leaveType.map(repeat => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={repeat} value={repeat}>{formatMessage({ id: `schedule.doctor.typeName.${repeat}` })}</MenuItem>
              })
            }
          </TextField>
          <div className={classes.doctorShiftText}>
            <DatePickerField
              margin="dense"
              required
              style={{ width: '150px', marginRight: '10px' }}
              label={formatMessage({ id: 'schedule.doctor.startDate' })}
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.startDate : doctorLeaveData.startDate}
              onChange={
                date => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'startDate' }, date, 'doctorShift') :
                  updateData({ name: 'startDate' }, date, 'doctorLeave')
              }
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
            <TextField
              margin="dense"
              variant="outlined"
              size="small"
              select
              style={{ marginRight: '5px' }}
              onChange={
                (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'startHour' }, e.target.value, 'doctorShift') :
                  updateData({ name: 'startHour' }, e.target.value, 'doctorLeave')
              }
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.startHour : doctorLeaveData.startHour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
            <TextField
              margin="dense"
              variant="outlined"
              size="small"
              select
              onChange={
                (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'startMin' }, e.target.value, 'doctorShift') :
                  updateData({ name: 'startMin' }, e.target.value, 'doctorLeave')
              }
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.startMin : doctorLeaveData.startMin}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
          </div>
          <div className={classes.doctorShiftText}>
            <DatePickerField
              required
              margin="dense"
              disabled={
                ((doctorShiftData && ['no', 'no-plastic'].includes(doctorShiftData.repeats)) ||
                  (doctorLeaveData && doctorLeaveData.repeats === 'leaves')) ? true : false
              }
              label={formatMessage({ id: 'schedule.doctor.endDate' })}
              style={{ width: '150px', marginRight: '10px' }}
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.endDate : doctorLeaveData.endDate}
              onChange={
                date => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'endDate' }, date, 'doctorShift') :
                  updateData({ name: 'endDate' }, date, 'doctorLeave')
              }
              minDate={
                secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  dayjs(doctorShiftData.startDate).toDate() :
                  dayjs(doctorLeaveData.startDate).toDate()
              }
              minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
            <TextField
              margin="dense"
              variant="outlined"
              size="small"
              select
              style={{ marginRight: '5px' }}
              onChange={
                (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'endHour' }, e.target.value, 'doctorShift') :
                  updateData({ name: 'endHour' }, e.target.value, 'doctorLeave')
              }
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.endHour : doctorLeaveData.endHour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
            <TextField
              margin="dense"
              variant="outlined"
              size="small"
              select
              onChange={
                (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'endMin' }, e.target.value, 'doctorShift') :
                  updateData({ name: 'endMin' }, e.target.value, 'doctorLeave')
              }
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.endMin : doctorLeaveData.endMin}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
          </div>
          {timeError && <div style={{ color: 'red' }}>{formatMessage({ id: 'form.time.formatError' })}</div>}
          <div className={classes.doctorShiftButton}>
            {((secondMenu.editDoctorShift && userRight['doctorSchedule-edit']) || secondMenu.editDoctorLeave) && <div className={classes.wrapper}>
              <Button
                style={{ marginRight: '100px' }}
                variant="contained"
                onClick={() => secondMenu.doctorShift || secondMenu.editDoctorShift ? deleteDoctorShift() : deleteDoctorLeave()}
                color="secondary"
                disabled={loading || deleteLoading}
              >
                {formatMessage({ id: 'button.delete' })}
              </Button>
              {deleteLoading && <CircularProgress size={24} className={classes.buttonDelProgress} />}
            </div>}
            <Button style={{ marginRight: '10px' }} variant="contained" onClick={handleClose} color="inherit">
              {formatMessage({ id: 'button.close' })}
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                onClick={() => secondMenu.doctorShift || secondMenu.editDoctorShift ? saveDoctorShift() : saveDoctorLeave()}
                color="primary"
                disabled={loading || deleteLoading}
              >
                {secondMenu.editDoctorShift || secondMenu.editDoctorLeave ? formatMessage({ id: 'button.save' }) : formatMessage({ id: 'button.add' })}
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        </div>
      );
    }
  }

  function openSecondMenu(event) {
    setSecondMenu({ [event]: true })

    if (event === 'doctorShift') {
      const newData = {
        repeats: 'no',
        startDate: dayjs(menuInfo.date),
        startHour: '00',
        startMin: '00',
        endDate: dayjs(menuInfo.date),
        endHour: '00',
        endMin: '00'
      }

      setDoctorShiftData(newData)
    } else if (event === 'doctorLeave') {
      const newData = {
        repeats: 'leaves',
        startDate: dayjs(menuInfo.date),
        startHour: '00',
        startMin: '00',
        endDate: dayjs(menuInfo.date),
        endHour: '00',
        endMin: '00'
      }

      setDoctorLeaveData(newData)
    }
  }

  const handleMenu = (event, date, staff) => {
    setMenuInfo({ date, staff, anchor: event.currentTarget })
  };

  function onContentScroll({ target: { scrollLeft, scrollTop } }) {
    requestAnimationFrame(() => {
      headerDatesRef.current.scrollLeft = scrollLeft
      onScrollRow(scrollTop)
    });
  }

  function filterData() {
    let filterShift = {}
    let filterSpecial = {}
    let fliterLeave = {}

    for (const s of objectToArray(doctorShift)) {
      filterShift[s.doctor] = { ...filterShift[s.doctor], [s.id]: { ...s } }
    }

    for (const s of objectToArray(doctorSpecial)) {
      filterSpecial[s.doctor] = { ...filterSpecial[s.doctor], [s.id]: { ...s } }
    }

    for (const s of objectToArray(doctorLeave)) {
      fliterLeave[s.doctor] = { ...fliterLeave[s.doctor], [s.id]: { ...s } }
    }

    return {
      filterShift,
      filterSpecial,
      fliterLeave
    }
  }

  const { filterShift, filterSpecial, fliterLeave } = filterData()

  const handleClose = () => {
    setMenuInfo(null);
    setSecondMenu(null);
    setDoctorLeaveData(null);
    setDoctorShiftData(null);
    setPunchClockReviseOpen(false);
    setTimeError(false)
    setPunchClockReviseData({
      hour: '09',
      minute: '00'
    })
  };

  if (isTablet && viewingStaff) {
    return (
      <div className={classes.mobileDate} style={{ maxHeight: `calc(${height}px - 290px` }}>
        {menuInfo && userRight['doctorSchedule-edit'] && <Menu
          id="menu-appbar"
          anchorEl={menuInfo.anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: isTablet ? 'center' : 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: isTablet ? 'center' : 'top',
            horizontal: 'right',
          }}
          open={menuOpen}
          onClose={handleClose}
        >
          {!secondMenu ? <span>
            <MenuItem onClick={() => openSecondMenu('doctorLeave')}>
              {formatMessage({ id: 'schedule.anchor.add_doctorLeave' })}
            </MenuItem>
            <MenuItem onClick={() => openSecondMenu('doctorShift')}>
              {formatMessage({ id: 'schedule.anchor.add_doctorShift' })}
            </MenuItem>
          </span> : openAddMenu()}
        </Menu>}
        {dates.map(date => {
          const weekday = dayjs(date).day()
          const isDayOff = weekday === 0;
          const schedule = doctortPunchCount(date, viewingStaff, filterShift[viewingStaff], filterSpecial[viewingStaff], fliterLeave[viewingStaff])

          return <div className={classes.mobileTable} style={{ maxWidth: width }} key={date}>
            <div className={classes.dateTable}>
              <div className={classes.dateCloumn}>
                <div>{`${date.format('M/D')} (${dayMapping[date.format('dd')]})`}</div>
              </div>
            </div>
            <div className={!isDayOff ? classes.mobileRow : classes.mobileRowDayOff} onClick={(e) => handleMenu(e, date, staffData[viewingStaff])}>
              <div className={classes.scheduleRowCloumn}>
                <div>
                  <ScheduleDoctorDate
                    date={date}
                    staff={staffData[viewingStaff]}
                    doctorShift={schedule.shifts}
                    doctorLeave={schedule.leaves}
                    doctorSpecial={schedule.specials}
                    onSubmit={onEdit}
                    punchClock={punchClock}
                    punchClockRevise={punchClockRevise}
                    userRight={userRight}
                    punchClockException={punchClockExceptions}
                  />
                </div>
              </div>
            </div>
          </div>
        })}
      </div>
    );
  }

  const dateRows = staffKey.map((key, idx) => {
    return <div key={idx}>
      <div className={classes.scheduleRow}>
        {dates.map(date => {
          const weekday = dayjs(date).day()
          const isDayOff = weekday === 0;
          const schedule = doctortPunchCount(date, key, filterShift[key], filterSpecial[key], fliterLeave[key])

          return (
            <div key={date} className={!isDayOff ? classes.scheduleRowTable : classes.scheduleRowTableOffDay} onClick={(e) => handleMenu(e, date, staffData[key])}>
              <div className={classes.scheduleRowCloumn}>
                <div>
                  <ScheduleDoctorDate
                    date={date}
                    staff={staffData[key]}
                    doctorShift={schedule.shifts}
                    doctorLeave={schedule.leaves}
                    doctorSpecial={schedule.specials}
                    onSubmit={onEdit}
                    punchClock={punchClock}
                    punchClockRevise={punchClockRevise}
                    userRight={userRight}
                    punchClockException={punchClockExceptions}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  })

  return (
    <div className={classes.scheduleTableContent}>
      <div className={clsx(classes.scheduleTableColumn, { [classes.drawer]: drawerStatus.display !== 'none' })} onScroll={onContentScroll}>
        {menuInfo && userRight['doctorSchedule-edit'] && <Menu
          id="menu-appbar"
          anchorEl={menuInfo.anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menuOpen}
          onClose={handleClose}
        >
          {!secondMenu ? <span>
            <MenuItem onClick={() => openSecondMenu('doctorLeave')}>
              {formatMessage({ id: 'schedule.anchor.add_doctorLeave' })}
            </MenuItem>
            <MenuItem onClick={() => openSecondMenu('doctorShift')}>
              {formatMessage({ id: 'schedule.anchor.add_doctorShift' })}
            </MenuItem>
          </span> : openAddMenu()}
        </Menu>}
        <div className={clsx(classes.scheduleTableHeader, { [classes.drawer]: drawerStatus.display !== 'none' })} ref={headerDatesRef}>
          {dates.map(date => {
            return <div key={date} className={classes.dateTable}>
              <div className={classes.dateCloumn}>
                <div>{`${date.format('M/D')} (${dayMapping[date.format('dd')]})`}</div>
              </div>
            </div>
          })}
        </div>
        {dateRows}
      </div>
    </div>
  );
}


ScheduleDoctorRows.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  staffData: PropTypes.object.isRequired,
  staffKey: PropTypes.arrayOf(PropTypes.string.isRequired),
  userRight: PropTypes.object.isRequired,
  viewingStaff: PropTypes.string,
  isTablet: PropTypes.bool,
  doctorShift: PropTypes.object.isRequired,
  doctorSpecial: PropTypes.object.isRequired,
  doctorLeave: PropTypes.object.isRequired,
  punchClock: PropTypes.object.isRequired,
  punchClockRevise: PropTypes.object.isRequired,
  punchClockExceptions: PropTypes.array.isRequired,
  onScrollRow: PropTypes.func.isRequired
};

export default ScheduleDoctorRows;
