import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ButtonMui from '@mui/material/Button';
import CircularProgressMui from '@mui/material/CircularProgress';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { formatName } from '../../../modules/uitls';

const Button = styled(ButtonMui)(() => ({
  width: '153px',
  height: '48px',
  borderRadius: '4px',
  alignItems: 'center',
  fontSize: '15px',
  lineHeight: '100%',
}));

const CircularProgress = styled(CircularProgressMui)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

const primaryColor = process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence' ? {
  light: '#fffff7',
  main: '#eeccc4',
  dark: '#bb9b94',
  contrastText: '#a58a88',
} : process.env.BRANCH_ENV === 'santea' ? {
  light: '#6efeff',
  main: '#26CAD3',
  dark: '#0099a2',
  contrastText: '#fff',
} : {
  light: '#a3ffff',
  main: '#6EDBD5',
  dark: '#35a9a4',
  contrastText: '#fff',
}

const newMapping = {
  division: ['beauty', 'plastic', 'preventative']
}

const theme = createTheme({
  palette: {
    primary: primaryColor,
  },
});

function CertificateOfDiagnosis({ currentUser, patient, kardex, kardexId }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const certificate = {
    division: kardex.certificateOfDiagnosis ? kardex.certificateOfDiagnosis.division : '',
    diagnosis: kardex.certificateOfDiagnosis ? kardex.certificateOfDiagnosis.diagnosis : '',
    comment: kardex.certificateOfDiagnosis ? kardex.certificateOfDiagnosis.comment : '',
  }
  const doctorAccount = ((currentUser.department === 'doctor' && currentUser.key === kardex.doctor.id) || currentUser.department === 'itDept') && !certificate.pdf ? true : false
  const _fields = [
    { name: 'name', sm: 12, md: 12, order: 0 },
    { name: 'gender', roots: true, sm: 12, md: 12, order: 1 },
    { name: 'birthDate', sm: 12, md: 12, order: 2 },
    { name: 'nationality', sm: 12, md: 12, order: 3 },
    { name: 'identityCardNumber', sm: 12, md: 12, order: 4 },
    { name: 'address', sm: 12, md: 12, order: 5 },
    { name: 'displayId', type: 'kardex', sm: 12, md: 12, order: 6 },
    { name: 'date', type: 'kardex', sm: 12, md: 12, order: 7 },
    { name: 'division', type: 'text', select: true, roots: true, sm: 12, md: 4, order: 8 },
    { name: 'diagnosis', type: 'text', sm: 12, md: 12, order: 9 },
    { name: 'comment', type: 'text', sm: 12, md: 12, order: 10 },
  ]

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function updateData(field, value) {
    let newValue = value
    if (field.name === 'division') {
      newValue = formatMessage({ id: `kardex.certificateOfDiagnosis.division.${newValue}` })
    }
    let newData = { ...certificate, [field.name]: newValue }

    try {
      await firebase.database().ref('kardexes').child(`${kardexId}/certificateOfDiagnosis`).update(newData)
    } catch (ex) {
      console.log(ex)
    }
  }

  async function saveCertificates() {
    const storageRef = firebase.storage().ref('certificates')

    if (!certificate.pdf) {
      setLoadingApprove(true)
      try {
        const res = await (firebase.functions().httpsCallable('certificateOfDiagnosiToPDF'))({ kardexId: kardexId, customer: process.env.BRANCH_ENV })
        if (res) {
          storageRef.child(`${kardexId}/${kardexId}.pdf`).getDownloadURL().then((url) => {
            window.open(url, '_blank')
            setLoadingApprove(false)
          }, () => {
            alert('errorcode: 500')
          })
        }
      } catch (ex) {
        console.log(ex)
        setLoadingApprove(false)
      }
    } else {
      try {
        storageRef.child(`${kardexId}/${kardexId}.pdf`).getDownloadURL().then((url) => {
          window.open(url, '_blank')
        }, () => {
          alert('Purchase order file not found')
        })
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  function createField(field, value) {
    let newValue = value
    let newLableText = ''
    let selectFields = newMapping[`${field.name}`]

    if (field.roots) {
      newLableText = formatMessage({ id: `kardex.certificateOfDiagnosis.${field.name}.roots` })
      if (!field.type === 'text') {
        newValue = formatMessage({ id: `kardex.certificateOfDiagnosis.${field.name}.${newValue}` })
      }
    } else {
      newLableText = formatMessage({ id: `kardex.certificateOfDiagnosis.${field.name}` })
    }

    if (field.name === 'name') {
      newValue = formatName(newValue)
    } else if (field.name === 'gender') {
      newValue = formatMessage({ id: `kardex.certificateOfDiagnosis.${field.name}.${newValue}` })
    }

    if (field.type === 'text') {
      newValue = certificate ? certificate[field.name] : ''
      if (field.name === 'division' && newValue) {
        if (newValue === '醫學美容') {
          newValue = 'beauty'
        } else if (newValue === '整形外科') {
          newValue = 'plastic'
        } else {
          newValue = 'preventative'
        }
      }
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md} style={{ marginTop: '20px' }}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={newLableText}
          value={newValue}
          disabled={certificate.pdf ? true : false}
          fullWidth
          select={field.select ? field.select : null}
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        >
          {
            field.select && selectFields.map((selects, idx) => {
              return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `kardex.certificateOfDiagnosis.${field.name}.${selects}` })}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'kardex') {
      newValue = kardex[field.name]
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '16px', color: '#828a99' }}>
        <span style={{ marginRight: '10px', fontSize: '14px' }} >{newLableText}</span>
        <span>{newValue}</span>
      </div>
    </Grid>
  }

  return (
    <div style={{ padding: '40px 20px', fontFamily: 'Roboto, sans-serif' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} />
        <Grid item xs={12} sm={12} md={6}>
          <div style={{ marginBottom: '4px' }}>{formatMessage({ id: 'kardex.certificateOfDiagnosis.information' })}</div>
          {fields.map(field => createField(field, patient[field.name]))}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px' }}>
            <div style={{ position: 'relative' }}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Button variant="outlined" onClick={() => saveCertificates()} disabled={loadingApprove || !doctorAccount} color="primary">
                    <PictureAsPdfIcon style={{ marginRight: '4px' }} />
                    {!certificate.pdf ? <FormattedMessage id="kardex.certificateOfDiagnosis.exportPdf" /> : <FormattedMessage id="kardex.certificateOfDiagnosis.showPdf" />}
                    {loadingApprove && <CircularProgress size={24} />}
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} />
      </Grid>
    </div>
  );
}

CertificateOfDiagnosis.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,

    department: PropTypes.string.isRequired,
  }),
  patient: PropTypes.object.isRequired,
  kardex: PropTypes.object.isRequired,
  kardexId: PropTypes.string.isRequired
};

export default CertificateOfDiagnosis;
