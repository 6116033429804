import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs'
import { useIntl, FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

import { styled } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CalendarPicker from '@mui/lab/CalendarPicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import ButtonGroup from '@mui/material/ButtonGroup';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import CircularProgressMui from '@mui/material/CircularProgress';

import { moibleMedia } from '../../constants/index';
import warehouse from '../../modules/warehouseConfig';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import ContextStore from '../../modules/context';
import FabComponent from '../../components/FabComponent';
import ButtonProgress from '../../components/ButtonProgress';

import Timeline, { TimelineHeaders, DateHeader, TimelineMarkers, CustomMarker } from "react-calendar-timeline/lib";
import 'react-calendar-timeline/lib/Timeline.css'

let projectColor = ''
if (process.env.BRANCH_ENV === 'santea') {
  projectColor = '#26CAD3'
} else if (process.env.BRANCH_ENV === 'lexcellence' || process.env.BRANCH_ENV === 'reborn') {
  projectColor = '#eeccc4'
} else {
  projectColor = '#6EDBD5'
}

const CircularProgress = styled(CircularProgressMui)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

const DateButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#3b4863',
  [moibleMedia]: {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: '0.75rem'
  },
  color: '#ffffff',
}));

const selectMapping = {
  group: ['meeting3F', 'meeting6F', 'parking35', 'parking36', 'parking20']
}

function CalendarDialog({ currentUser, handleClose, date, calendarId, itemMapping, userRight }) {
  const { formatMessage } = useIntl()
  const [timeError, setTimeError] = useState(false)
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false)
  const data = itemMapping[calendarId] ? itemMapping[calendarId] : {}
  const defaultData = calendarId === 'new' ? {
    startTime: '',
    endTime: '',
    group: '',
    title: ''
  } : {
    startTime: data?.startTime,
    endTime: data?.endTime,
    group: data?.group,
    title: data?.title
  }
  const [currentData, setCurrentData] = useState(defaultData)

  async function saveCalendar(isDelete) {
    if (isDelete) {
      setDeleteLoading(true)
    } else {
      setLoading(true)
    }

    let err = {}
    let newData = currentData

    for (let field of fields) {
      if (field.required && currentData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...currentData, ...err }
    }

    if (Object.keys(err).length > 0 || timeError) {
      setCurrentData(newData)
      if (isDelete) {
        return setDeleteLoading(false)
      } else {
        return setLoading(false)
      }
    }

    try {
      await (firebase.functions().httpsCallable('saveCalendar'))({ calendarId, userName: currentUser.displayName, date, isDelete: isDelete ? true : false, ...currentData })
      if (isDelete) {
        setDeleteLoading(false)
      } else {
        setLoading(false)
      }
      handleClose()
    } catch (ex) {
      console.log(ex)
      if (isDelete) {
        setDeleteLoading(false)
      } else {
        setLoading(false)
      }
      handleClose()
    }
  }

  function createField(field) {
    let newValue = currentData[field.name]
    let labelText = ''

    if (field.root) {
      labelText = formatMessage({ id: `calendar.dialog.${field.name}.root` })
    } else {
      labelText = formatMessage({ id: `calendar.dialog.${field.name}` })
    }

    if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    } else if (field.type === 'time') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <InputMask
          mask={'99:99'}
          maskChar=""
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          value={newValue}
        >
          {() => <TextField
            type="text"
            size="small"
            required
            fullWidth
            label={labelText}
            onCompositionStart={
              e => {
                e.target.addEventListener('input', e2 => {
                  if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                    e2.preventDefault()
                    e2.stopPropagation()
                    e2.stopImmediatePropagation()
                  }
                }, { once: true })
              }
            }
            error={currentData[`${field.name}_err`] ? true : false}
            helperText={currentData[`${field.name}_err`]}
          />}
        </InputMask>
      </Grid>
    } else if (field.type === 'text-select') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          error={currentData[`${field.name}_err`] ? true : false}
          helperText={currentData[`${field.name}_err`]}
        >
          {
            selectMapping[field.name].map((select, idx) => {
              return <MenuItem key={`${idx}`} value={select}>{formatMessage({ id: `calendar.dialog.${field.name}.${select}` })}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={labelText}
          value={newValue}
          fullWidth
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          error={currentData[`${field.name}_err`] ? true : false}
          helperText={currentData[`${field.name}_err`]}
        />
      </Grid>
    }
  }

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)
    let newData = { ...currentData, [field.name]: newValue, [`${field.name}_err`]: err }

    if (newData.endTime !== '' && newData.startTime !== '') {
      if (newData.endTime < newData.startTime) {
        newData.endTime_err = formatMessage({ id: 'form.date.formatError' })
        setTimeError(true)
      } else {
        setTimeError(false)
        delete newData.endTime_err
      }
    }

    setCurrentData(newData)
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  const fields = [
    { name: 'startTime', md: 6, sm: 6, type: 'time', required: true },
    { name: 'endTime', md: 6, sm: 6, type: 'time', required: true },
    { name: 'group', md: 6, sm: 6, type: 'text-select', root: 'true', required: true },
    { name: 'title', md: 6, sm: 6, type: 'text', multiline: true },
  ]

  return (
    <div style={{ flexGrow: 1 }}>
      <Dialog
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth='xs'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{formatMessage({ id: calendarId === 'new' ? 'calendar.dialog.new' : 'calendar.dialog.edit' })}</DialogTitle>
        <Grid sx={{ p: '10px' }} container spacing={2}>
          {fields.map(field => createField(field))}
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>{formatMessage({ id: 'calendar.dialog.createdBy' })}{data.createdBy}</div>
          </Grid>
        </Grid>
        {userRight['calendar-edit'] && <DialogActions>
          {calendarId !== 'new' &&
            <div style={{ position: 'relative', marginRight: 205 }}>
              <Button
                variant="contained"
                onClick={() => saveCalendar(true)}
                color="error"
                disabled={deleteLoading}
              >
                {formatMessage({ id: 'button.delete' })}
              </Button>
              {deleteLoading && <CircularProgress size={24} />}
            </div>}
          <ButtonProgress handleClick={() => saveCalendar()} handleClose={handleClose} loading={loading} />
        </DialogActions>}
      </Dialog>
    </div>
  )
}

CalendarDialog.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  handleClose: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  calendarId: PropTypes.string.isRequired,
  itemMapping: PropTypes.object.isRequired,
  userRight: PropTypes.object.isRequired
};

export default function CalendarPage({ currentUser, userRight }) {
  const { formatMessage } = useIntl()
  const history = useHistory();
  const { setBreadcrumbs } = useContext(ContextStore)
  const [openDialog, setOpenDialog] = useState(null)
  const [currentDate, setCurrentDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [dateAnchorEl, setDateAnchorEl] = useState(null)
  const dateMenuOpen = Boolean(dateAnchorEl)
  const [itemMapping, setItemMapping] = useState({})
  const items = Object.keys(itemMapping || {}).reduce((acc, cur) => {
    acc.push({
      id: cur,
      start_time: dayjs(`${currentDate} ${itemMapping[cur].startTime}`),
      end_time: dayjs(`${currentDate} ${itemMapping[cur].endTime}`),
      title: itemMapping[cur].title,
      group: itemMapping[cur].group,
    })

    return acc
  }, [])

  const groups = [
    { id: 'meeting3F', stackItems: true, title: formatMessage({ id: 'calendar.group.meeting3F' }) },
    { id: 'meeting6F', stackItems: true, title: formatMessage({ id: 'calendar.group.meeting6F' }) },
    { id: 'parking35', stackItems: true, title: formatMessage({ id: 'calendar.group.parking35' }) },
    { id: 'parking36', stackItems: true, title: formatMessage({ id: 'calendar.group.parking36' }) },
    { id: 'parking20', stackItems: true, title: formatMessage({ id: 'calendar.group.parking20' }) }
  ]

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.appointments.calendar' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  useEffect(() => {
    const ref = firebase.database(warehouse).ref('calendars').orderByChild('date').equalTo(currentDate)
    const onDataChange = ref && ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      setItemMapping(snapshots)
    });
    return () => ref.off('value', onDataChange)
  }, [currentDate]);

  function DatePickerButton() {
    return (
      <Menu
        id="menu-date"
        sx={{ zIndex: 3100 }}
        anchorEl={dateAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={dateMenuOpen}
        onClose={handleDateClose}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={'zh-tw'}>
          <CalendarPicker
            date={dayjs(currentDate)}
            onChange={(event) => changeDate(event)}
          />
        </LocalizationProvider>
      </Menu>
    )
  }

  const handleDateClose = () => {
    setDateAnchorEl(null);
  };

  const changeDate = (event) => {
    let newDate = ''
    if (event === 'before') {
      newDate = dayjs(currentDate).subtract(1, 'day').format('YYYY-MM-DD')
    } else if (event === 'after') {
      newDate = dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD')
    } else {
      newDate = dayjs(event).format('YYYY-MM-DD')
      handleDateClose()
    }

    setCurrentDate(newDate)
  }

  const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor: ['parking35', 'parking36', 'parking20'].includes(item.group) ? 'rgba(116, 158, 237, 0.6)' : 'rgba(123, 247, 181, 0.6)',
            color: ['parking35', 'parking36', 'parking20'].includes(item.group) ? '#012975' : '#028c42',
            borderStyle: "solid",
            fontSize: '15px',
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: 1,
            borderRightWidth: 1,
          },
          onMouseDown: () => {
            setOpenDialog({ id: item.id })
          }
        })}
      >
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {itemContext.title}
        </div>
      </div>
    );
  }

  return (
    <Grid container spacing={1} sx={{ p: '20px', fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif' }}>
      {openDialog && <CalendarDialog
        currentUser={currentUser}
        handleClose={() => setOpenDialog(null)}
        date={currentDate}
        calendarId={openDialog.id}
        itemMapping={itemMapping || {}}
        userRight={userRight}
      />}
      {userRight['calendar-edit'] && <FabComponent onClick={() => setOpenDialog({ id: 'new' })} />}
      <Grid item xs={12} sm={12} md={12}>
        <SimpleTableToolbar
          title='calendar.title'
          toolbox={<>
            <DatePickerButton />
            <ButtonGroup size="small" variant="contained" color="primary" aria-label="contained primary button group">
              <DateButton onClick={() => changeDate('before')}><ChevronLeftIcon /></DateButton>
              <DateButton onClick={(event) => setDateAnchorEl(event.currentTarget)}>{dayjs(currentDate).locale('zh-tw').format('M/D (ddd) YYYY')}</DateButton>
              <DateButton onClick={() => changeDate('after')}><ChevronRightIcon /></DateButton>
              <DateButton onClick={() => setCurrentDate(dayjs().format('YYYY-MM-DD'))}><FormattedMessage id="calendar.today" /></DateButton>
            </ButtonGroup>
          </>}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ overflowY: 'scroll' }}>
          <Timeline
            groups={groups}
            items={items}
            defaultTimeStart={dayjs().startOf("day").toDate()}
            defaultTimeEnd={dayjs().startOf("day").add(1, "day").toDate()}
            visibleTimeStart={dayjs(currentDate).startOf('day').valueOf()}
            visibleTimeEnd={dayjs(currentDate).startOf('day').add(1, 'day').valueOf()}
            itemRenderer={itemRenderer}
          >
            <TimelineHeaders style={{ position: 'sticky', top: 0, backgroundColor: projectColor }}>
              <DateHeader />
            </TimelineHeaders>
            <TimelineMarkers>
              <CustomMarker date={Date.now()} >
                {({ styles, date }) => <div style={{ ...styles, backgroundColor: 'red' }} />}
              </CustomMarker>
            </TimelineMarkers>
          </Timeline>
        </div>
      </Grid>
    </Grid>
  );
}

CalendarPage.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  userRight: PropTypes.object.isRequired
};
