import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';

import { objectToArray } from '../../modules/data'
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import SearchBox from '../../components/SearchBox';
import ExpandButton from '../../components/ExpandButton';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import { formatName } from '../../modules/uitls';
import ContextStore from '../../modules/context';

function AppointmentList() {
  const { setBreadcrumbs, uiState } = useContext(ContextStore)
  const { formatMessage } = useIntl()
  const history = useHistory();
  const [kardexes, setKardexes] = useState({})
  const [unkardexes, setUnkardexes] = useState({})
  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('time')
  const [patientList, setPatientList] = useState({})
  const appointments = objectToArray(concatKardexes())
  const userMapping = useSelector(state => state.firebase.data.users);
  const NaStr = formatMessage({ id: 'appointment.popover.NA' })

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setPatientList(snapshot.val())
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('kardexes').orderByChild('date').equalTo(dayjs(uiState.date).format('YYYY-MM-DD'))
    const onDataChange = ref.on('value', snapshot => {
      setKardexes(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, [uiState.date]);

  useEffect(() => {
    const ref = firebase.database().ref('appointments').orderByChild('date').equalTo(dayjs(uiState.date).format('YYYY-MM-DD'))
    const onDataChange = ref.on('value', snapshot => {
      setUnkardexes(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, [uiState.date]);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.appointments.list' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  function concatKardexes() {
    let appointment;

    if (kardexes && !unkardexes) {
      appointment = kardexes
    } else if (!kardexes && unkardexes) {
      appointment = unkardexes
    } else if (kardexes && unkardexes) {
      appointment = Object.assign(kardexes, unkardexes)
    } else {
      appointment = {}
    }

    return appointment
  }

  function filterByText() {
    if (currentFilter.name === 'name') {
      return appointments.filter(s => formatName(patientList[s.patient]?.name ?? '').toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'doctor') {
      return appointments.filter(s => userMapping[s.doctor] && userMapping[s.doctor].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'salesRep') {
      return appointments.filter(s => userMapping[s.salesRep] && userMapping[s.salesRep].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'nurse') {
      return appointments.filter(s => userMapping[s.nurse] && userMapping[s.nurse].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  function exportProfile() {
    const rows = ['santea'].includes(process.env.BRANCH_ENV) ? ['時間,姓名,醫師,業務,健管師,療程'] : ['時間,姓名,醫師,客代,護理師,療程']
    filterAppointments.map(r => formatData(r)).forEach(appointment => {
      rows.push([
        appointment.totalTime,
        appointment.patientName,
        appointment.doctorName,
        appointment.nurseName,
        appointment.salesRepName,
        appointment.treatment
      ])
    })

    try {
      const content = rows.join('\n')
      const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const aExport = document.createElement('a')
      aExport.href = csvUrl
      aExport.target = '_blank'
      aExport.download = 'AppointmentList_' + dayjs().format('YYYY-MM-DD-HHmmss') + '.csv'
      aExport.click()
    } catch (error) {
      console.error(error) // eslint-disable-line
      alert('An error occurred. Please refresh and try again.')
    }
  }

  const headerCells = [
    { text: 'time', sort: 'time' },
    { text: 'name', sort: 'patient' },
    { text: 'doctor', sort: 'doctor' },
    { text: 'salesRep', sort: 'salesRep' },
    { text: 'nurse', sort: 'nurse' },
    { text: 'treatment', sort: 'treatments' },
  ].map(c => { c.text = formatMessage({ id: `appointment.list.${c.text}` }); return c })

  const rowCells = [
    { field: 'totalTime', textColor: true },
    { field: 'patientName', textColor: true },
    { field: 'doctorName', textColor: true },
    { field: 'salesRepName', textColor: true },
    { field: 'nurseName', textColor: true },
    { field: 'treatment', textColor: true },
  ]

  const filterItems = [
    { name: 'name' },
    { name: 'doctor' },
    { name: 'salesRep' },
    { name: 'nurse' },
  ].map(i => { i.text = formatMessage({ id: `appointment.list.${i.name}` }); return i })


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const formatData = (data) => {
    const newData = { ...data }
    newData.totalTime = appointmentTimeArray(newData)
    newData.treatment = treatmentsArray(newData.treatments)
    newData.patientName = patientList[newData.patient] ? formatName(patientList[newData.patient]?.name ?? '') : NaStr
    newData.doctorName = userMapping[newData.doctor] ? userMapping[newData.doctor].displayName : NaStr
    newData.salesRepName = userMapping[newData.salesRep] ? userMapping[newData.salesRep].displayName : NaStr
    newData.nurseName = userMapping[newData.nurse] ? userMapping[newData.nurse].displayName : NaStr

    if (newData.status === 'cancelled') {
      newData.textColor = '#bab7b7'
    }
    return newData
  }

  function appointmentTimeArray(appointment) {
    let totalDuration = objectToArray(appointment.treatments).reduce((acc, treatment) => (
      acc + parseInt(treatment.duration || 0)
    ), 0)

    const startTime = appointment.time
    const endTime = dayjs(`${appointment.date} ${appointment.time}`)
      .add(totalDuration, 'minutes')
      .format('HH:mm')

    return startTime + '-' + endTime
  }

  function treatmentsArray(treatments) {
    let name = ''
    let count = 0;
    const treatment = objectToArray(treatments)

    for (let i = 0; i < (treatment.length - 1); i++) {
      if (treatment[i].type && treatment[i].type === 'product') {
        if (count === 0) {
          name = treatment[i].name
          count++;
        } else {
          name = name + '/' + treatment[i].name
        }
      }
    }

    return name
  }

  const filterAppointments = currentFilter && currentFilter.text ? filterByText() : appointments
  return (
    <div style={{ width: '100%', height: '100%', padding: '40px 20px', fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif' }}>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2}>
          <IconButton
            style={{ fontSize: '14px', alignSelf: 'center', color: '#666666' }}
            onClick={() => exportProfile()}
            size="large">
            <GetAppIcon />
            {formatMessage({ id: 'button.export' })}
          </IconButton>
        </Grid>
        <Grid item xs={10} sm={10} md={10}>
          <SimpleTableToolbar
            title={'announcement.title'}
            bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
            toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filterAppointments.length}
              />
              <TableBody>
                {stableSort(filterAppointments.map(r => formatData(r)), getComparator(order, orderBy)).map(appointment => (
                  <EnhancedTableRow
                    key={appointment.id}
                    rowCells={rowCells}
                    cellData={appointment}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

export default AppointmentList;
