import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs'
import { createUseStyles } from 'react-jss';

const date = dayjs().format('YYYY-MM-DD')

const gridItemStyle = {
  float: 'left',
  padding: '8px 16px',
  height: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  marginLeft: '-1px',
  borderLeft: '1px solid #172033',
  boxSizing: 'border-box',
}
const styles = {
  wrapper: {
    width: '654px',
    margin: '50px auto',
    fontFamily: 'PingFang TC',
    letterSpacing: '-0.1px',
  },
  header: {
    fontSize: '21px',
    fontWeight: '500',
    textAlign: 'center',
    color: '#888888',
    lineHeight: '29px',
  },
  patient: {
    padding: '16px 0',
    color: '#172033',
    fontSize: '13px',
    lineHeight: '18px',
    // textAlign: 'center',
  },
  name: {
    display: 'inline-block',
    width: '163px',
  },
  id: {
    display: 'inline-block',
    width: '163px',
  },
  dob: {
    display: 'inline-block',
    width: '163px',
  },
  medicalId: {
    display: 'inline-block',
    width: '163px',
  },
  phone: {
    display: 'inline-block',
    width: '326px',
  },
  medicaldate: {
    display: 'inline-block',
    width: '163px',
  },
  gender: {
    display: 'inline-block',
    width: '163px',
  },

  table: {
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left',
    color: '#172033',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    width: '654px',
    borderLeft: '1px solid #172033',
    borderRight: '1px solid #172033',
  },
  row: {
    height: '32px',
    borderTop: '1px solid #172033',
    borderBottom: '1px solid #172033',
    marginBottom: '-1px',
    lineHeight: '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  t163: Object.assign({
    width: '163px'
  }, gridItemStyle),
  t490: Object.assign({
    width: '490px'
  }, gridItemStyle),
  t582: Object.assign({
    width: '582px'
  }, gridItemStyle),
  t284: Object.assign({
    width: '284px'
  }, gridItemStyle),
  t124: Object.assign({
    width: '124px'
  }, gridItemStyle),
  t80: Object.assign({
    width: '81px'
  }, gridItemStyle),
  t72: Object.assign({
    width: '72px'
  }, gridItemStyle),
  t218: Object.assign({
    width: '218px',
    textAlign: 'center'
  }, gridItemStyle),
  chop: {
    height: '72px',
    margin: '-5px 0',
  },
  notes: {
    fontSize: '11px',
    textAlign: 'left',
    color: '#172033',
    margin: '8px 0 40px 0',
    lineHeight: '18px',
  },
}

const useStyles = createUseStyles({
  document: {
    width: '800px',
    minHeight: '1189px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #e2e6ee',
    position: 'relative',
    margin: '0 auto 20px auto',
    // ['@media print']: {
    //   width: '100%',
    //   height: 'auto',
    //   margin: '-40px 0 -40px 0',
    //   minHeight: 'auto',
    //   position: 'static',
    //   border: 'none',
    //   transform: 'scale(1) !important'
    // }
  }
});

function PrescriptionDocument({ kardex, prescription, patient, doctorStamp, clinicChop }) {
  const classes = useStyles()
  const medicines = []
  if (medicines.length === 0) {
    if (prescription.medicines) {
      Object.keys(prescription.medicines).forEach(m => {
        medicines.push({ id: m, ...prescription.medicines[m] })
      })
    }
  }
  const sections = ['A', 'B']
  // note: inline styles used in case pdf needs to be generated
  return <div className={classes.document}>
    {sections.map(section => (
      <div key={section} style={styles.wrapper}>
        <div style={styles.header}>
          安珀琢玉醫美診所門診交付處方箋（{section})
        </div>
        <div style={styles.patient}>
          <div style={styles.medicaldate}>
            處方日期：{date}
          </div>
          <div style={styles.phone}>
            病患連絡電話：{patient.phone}
          </div>
          <div style={styles.medicalId}>
            病歷號碼：{kardex.displayId}
          </div>
          <div style={styles.name}>
            客戶姓名：{patient.name}
          </div>
          <div style={styles.gender}>
            性別：{patient.gender === 'male' ? '男' : '女'}
          </div>
          <div style={styles.dob}>
            出生日期：{patient.birthDate}
          </div>
          <div style={styles.id}>
            身分證號：{patient.identityCardNumber}
          </div>
        </div>
        <div style={styles.table}>
          <div style={styles.row}>
            <div style={styles.t163}>
              處方箋類別&nbsp;&nbsp;一般處方箋
            </div>
            <div style={styles.t490}>
              醫院診所服務機構名稱及代號&nbsp;&nbsp;&nbsp;安珀琢玉醫美診所{/* &lt;149170570&gt; */}
            </div>
          </div>
          <div style={Object.assign({}, styles.row, { borderBottom: '2px solid #172033' })}>
            <div style={styles.t163}>
              就醫科別&nbsp;&nbsp;&nbsp;{prescription.division}
            </div>
            <div style={styles.t163}>
              就醫日期&nbsp;&nbsp;&nbsp;{kardex.date}
            </div>
            <div style={styles.t163}>
              機構健保代碼&nbsp;{prescription.payment}{/* prescription.payment */}
            </div>
            <div style={styles.t163}>
              給藥日份&nbsp;&nbsp;&nbsp;{prescription.dosingDay}&nbsp;日
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.t284}>
              藥品名稱及規格（劑型、劑量）
            </div>
            <div style={styles.t124}>
              用量與方法
            </div><div style={styles.t80}>
              單位
            </div>
            <div style={Object.assign({}, styles.t80, {
              fontSize: 10,
              whiteSpace: 'pre-wrap',
              lineHeight: '14px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            })}>
              單次調劑日數
            </div>
            <div style={Object.assign({}, styles.t80, {
              fontSize: 10,
              whiteSpace: 'pre-wrap',
              lineHeight: '14px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            })}>
              單次調劑總處方量
            </div>
          </div>
          {medicines.map((medicine) => (
            <div
              key={medicine.id}
              style={Object.assign({}, styles.row, { height: '44px', lineHeight: '27px' })}
            >
              <div style={Object.assign({}, styles.t284, {
                whiteSpace: 'pre-wrap',
                lineHeight: '16px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              })}>
                {medicine.name}
              </div>
              <div style={Object.assign({}, styles.t124, {
                fontSize: 10,
                whiteSpace: 'pre-wrap',
                lineHeight: '14px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              })}>
                {medicine.usage}
              </div>
              <div style={styles.t80}>
                {medicine.unit}
              </div>
              <div style={styles.t80}>
                {prescription.dosingDay}
              </div>
              <div style={styles.t80}>
                {medicine.total}
              </div>
            </div>
          ))}
          <div
            style={Object.assign({}, styles.row, {
              borderBottom: '2px solid #172033',
              borderTop: '2px solid #172033'
            })}
          >
            <div style={styles.t72}>
              備註
            </div>
            <div style={styles.t582}>
              {kardex.prescription.comment}
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.t218}>
              診治醫師簽章
            </div>
            <div style={styles.t218}>
              處方醫院診所核單
            </div>
            <div style={styles.t218}>
              特約藥局核單
            </div>
          </div>
          <div style={Object.assign({}, styles.row, { height: '80px' })}>
            <div style={styles.t218}>
              {prescription.signed && doctorStamp && <img alt='' style={styles.chop} src={doctorStamp} />}
            </div>
            <div style={styles.t218}>
              <img alt='' style={styles.chop} src={clinicChop} />
            </div>
            <div style={styles.t218}>
              {/* stamp */}
            </div>
          </div>
        </div>
        <div style={styles.notes}>
          電話：（02)8789-3999 傳真：（02)8789-3979 <br />
          注意事項：<br />
          1.  本處方如有塗改，須由原處方醫師確認，否則無效。<br />
          2.  本交付調劑處方為一式兩份（A/B)，一份交病患供調劑用，另一份由處方醫事機構留存備查。<br />
          3.  本調劑之一般處方箋自就醫起三日內有效。
        </div>
      </div>
    ))}
  </div>
}

PrescriptionDocument.propTypes = {
  kardex: PropTypes.object,
  prescription: PropTypes.object,
  patient: PropTypes.object,
  doctorStamp: PropTypes.string,
  clinicChop: PropTypes.string,
};

export default PrescriptionDocument;
