import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import DatePickerField from '../../../components/DatePickerField'
import ButtonProgress from '../../../components/ButtonProgress';

const selectField = ['hypoallergenic', 'normal', 'painkiller', 'icePack', 'no'];

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function EditHistoryPage({ handleClose, data, patientId, historyType }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [checkDailog, setCheckDailog] = useState(null);
  const open = Boolean(checkDailog && checkDailog.anchorEl);

  let defultData = {}

  if (historyType === 'skinCareHistory') {
    defultData = {
      ipl: false,
      pl: false,
      fx: false,
      ultheraphy: false,
      thermage: false,
      laserOthers: '',
      chemicalPeeling: false,
      moisturizer: false,
      lpg: false,
      beautyTreatmentOthers: '',
      beautyInjection: false,
      injectionSite: '',
      lastInjectionTime: '',
      filler: false,
      fillerName: '',
      fillerSite: '',
      fillerTime: '',
    }

    if (data) {
      if (data.laser) {
        for (const key of Object.keys(data.laser)) {
          defultData[key] = data.laser[key]
        }
      }

      if (data.otherLaserTreatmentDetail) {
        defultData.laserOthers = Object.keys(data.otherLaserTreatmentDetail).map(m => {
          return data.otherLaserTreatmentDetail[m].detail
        })[0]
      }

      if (data.beautyTreatments) {
        for (const key of Object.keys(data.beautyTreatments)) {
          defultData[key] = data.beautyTreatments[key]
        }
      }

      if (data.beautyTreatmentDetail) {
        defultData.beautyTreatmentOthers = Object.keys(data.beautyTreatmentDetail).map(m => {
          return data.beautyTreatmentDetail[m].detail
        })[0]
      }

      if (data.beautyInjections) {
        for (const check of Object.keys(data.beautyInjections)) {
          if (data.beautyInjections[check]) {
            defultData.beautyInjection = true
            defultData.injectionSite = data.beautyInjections[check].site
            defultData.lastInjectionTime = data.beautyInjections[check].time
          } else {
            defultData.beautyInjection = false
            defultData.injectionSite = ''
            defultData.lastInjectionTime = ''
          }
        }
      } else {
        defultData.beautyInjection = false
      }

      if (data.fillers) {
        for (const check of Object.keys(data.fillers)) {
          if (data.fillers[check]) {
            defultData.filler = true
            defultData.fillerName = data.fillers[check].name
            defultData.fillerSite = data.fillers[check].site
            defultData.fillerTime = data.fillers[check].time
          } else {
            defultData.filler = false
            defultData.fillerName = ''
            defultData.fillerSite = ''
            defultData.fillerTime = ''
          }
        }
      } else {
        defultData.filler = false
      }
    }
  } else if (historyType === 'medicalHistory') {
    defultData = {
      keloid: false,
      heartDisease: false,
      immuneSystem: false,
      diabetes: false,
      hypertension: false,
      eczema: false,
      herpes: false,
      hepatitisB: false,
      hepatitisC: false,
      diseaseOthers: '',
      ginseng: false,
      ginkgo: false,
      multivitamin: false,
      vitaminE: false,
      otherHealthSupplement: '',
      pregnancy: false,
      numberWeeks: '',
      allergyDrug: false,
      drugName: '',
      allergicSymptoms: '',
      surgery: false,
      surgeryName: '',
      medicationInUse: false,
      medicationInUseName: '',
    }

    if (data) {
      if (data.disease) {
        for (const key of Object.keys(data.disease)) {
          defultData[key] = data.disease[key]
        }
      }

      if (data.othersDisease) {
        defultData.diseaseOthers = Object.keys(data.othersDisease).map(m => {
          return data.othersDisease[m].detail
        })[0]
      }

      if (data.healthSupplementInUse) {
        for (const key of Object.keys(data.healthSupplementInUse)) {
          defultData[key] = data.healthSupplementInUse[key]
        }
      }

      if (data.otherHealthSupplement) {
        defultData.otherHealthSupplement = Object.keys(data.otherHealthSupplement).map(m => {
          return data.otherHealthSupplement[m].detail
        })[0]
      }

      if (data.pregnancy) {
        defultData.pregnancy = true
        defultData.numberWeeks = data.pregnancy
      }

      if (data.allergyDrug) {
        for (const check of Object.keys(data.allergyDrug)) {
          if (data.allergyDrug[check]) {
            defultData.allergyDrug = true
            defultData.drugName = data.allergyDrug[check].name
            defultData.allergicSymptoms = data.allergyDrug[check].symptom
          } else {
            defultData.allergyDrug = false
          }
        }
      } else {
        defultData.allergyDrug = false
      }

      if (data.surgery) {
        for (const check of Object.keys(data.surgery)) {
          if (data.surgery[check]) {
            defultData.surgery = true
            defultData.surgeryName = data.surgery[check].detail
          } else {
            defultData.surgery = false
          }
        }
      } else {
        defultData.surgery = false
      }

      if (data.medicationInUse) {
        for (const check of Object.keys(data.medicationInUse)) {
          if (data.medicationInUse[check]) {
            defultData.medicationInUse = true
            defultData.medicationInUseName = data.medicationInUse[check].name
          } else {
            defultData.medicationInUse = false
          }
        }
      } else {
        defultData.medicationInUse = false
      }
    }
  } else {
    if (data) {
      defultData.anestheticP = {}
      defultData.anestheticM = {}
      defultData.anestheticL = {}
      for (const s of selectField) {
        if (data.phototherapy) {
          if (data.phototherapy[s]) {
            defultData.anestheticP[s] = data.phototherapy[s]
          } else {
            defultData.anestheticP[s] = false
          }
        } else {
          defultData.anestheticP[s] = false
        }

        if (data.microShaping) {
          if (data.microShaping[s]) {
            defultData.anestheticM[s] = data.microShaping[s]
          } else {
            defultData.anestheticM[s] = false
          }
        } else {
          defultData.anestheticM[s] = false
        }

        if (data.lift) {
          if (data.lift[s]) {
            defultData.anestheticL[s] = data.lift[s]
          } else {
            defultData.anestheticL[s] = false
          }
        } else {
          defultData.anestheticL[s] = false
        }
      }
      defultData.preferenceP = data.phototherapyComment
      defultData.preferenceM = data.microShapingComment
      defultData.preferenceL = data.liftComment
      defultData.surgery = data.surgeryComment
      defultData.gynecology = data.gynecologyComment
      defultData.cosmetology = data.cosmetologyComment
      defultData.bodyFiguring = data.bodyFiguringComment
      defultData.careProduct = data.careProductComment
    } else {
      defultData = {
        anestheticP: {
          hypoallergenic: false,
          normal: false,
          painkiller: false,
          icePack: false,
          no: false
        },
        preferenceP: '',
        anestheticM: {
          hypoallergenic: false,
          normal: false,
          painkiller: false,
          icePack: false,
          no: false
        },
        preferenceM: '',
        anestheticL: {
          hypoallergenic: false,
          normal: false,
          painkiller: false,
          icePack: false,
          no: false
        },
        preferenceL: '',
        surgery: '',
        gynecology: '',
        cosmetology: '',
        bodyFiguring: '',
        careProduct: '',
      }
    }
  }

  const [patientData, setPatientData] = useState(defultData)

  const _fields = []
  if (historyType === 'skinCareHistory') {
    _fields.push(
      { name: 'laser', text: formatMessage({ id: 'patient.dialog.skinCareHistory.laser' }), type: '-', order: 0 },
      { name: 'ipl', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'pl', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'fx', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'ultheraphy', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'thermage', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'laserOthers', type: 'text', md: 2, sm: 12, order: 1 },
      { name: 'beautyTreatments', text: formatMessage({ id: 'patient.dialog.skinCareHistory.beautyTreatments' }), type: '-', order: 2 },
      { name: 'chemicalPeeling', type: 'check', md: 2, sm: 12, order: 3 },
      { name: 'moisturizer', type: 'check', md: 2, sm: 12, order: 3 },
      { name: 'lpg', type: 'check', md: 2, sm: 12, order: 3 },
      { name: 'beautyTreatmentOthers', type: 'text', md: 2, sm: 12, order: 4 },
      { name: 'line', text: formatMessage({ id: 'patient.dialog.skinCareHistory.injection' }), type: '-', order: 4 },
      { name: 'beautyInjection', type: 'check', md: 2, sm: 12, order: 5 },
      { name: 'line2', text: formatMessage({ id: 'patient.dialog.skinCareHistory.filler' }), type: '-', order: 8 },
      { name: 'filler', type: 'check', md: 2, sm: 12, order: 9 },
    )
    if (patientData.beautyInjection) {
      _fields.push(
        { name: 'injectionSite', type: 'text', md: 3, sm: 12, order: 6 },
        { name: 'lastInjectionTime', type: 'time', md: 3, sm: 12, order: 7 },
      )
    }
    if (patientData.filler) {
      _fields.push(
        { name: 'fillerName', type: 'text', md: 3, sm: 12, order: 10 },
        { name: 'fillerSite', type: 'text', md: 3, sm: 12, order: 11 },
        { name: 'fillerTime', type: 'time', md: 3, sm: 12, order: 12 },
      )
    }
  } else if (historyType === 'medicalHistory') {
    _fields.push(
      { name: 'disease', text: formatMessage({ id: 'patient.dialog.medicalHistory.disease' }), type: '-', order: 0 },
      { name: 'keloid', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'heartDisease', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'immuneSystem', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'diabetes', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'hypertension', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'eczema', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'herpes', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'hepatitisB', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'hepatitisC', type: 'check', md: 2, sm: 12, order: 1 },
      { name: 'diseaseOthers', type: 'text', md: 2, sm: 12, order: 1 },
      { name: 'healthSupplementInUse', text: formatMessage({ id: 'patient.dialog.medicalHistory.healthSupplementInUse' }), type: '-', order: 2 },
      { name: 'ginseng', type: 'check', md: 2, sm: 12, order: 3 },
      { name: 'ginkgo', type: 'check', md: 2, sm: 12, order: 3 },
      { name: 'multivitamin', type: 'check', md: 2, sm: 12, order: 3 },
      { name: 'vitaminE', type: 'check', md: 2, sm: 12, order: 3 },
      { name: 'otherHealthSupplement', type: 'text', md: 2, sm: 12, order: 4 },
      { name: 'line', type: '-', sm: 12, order: 4 },
      { name: 'pregnancy', type: 'check', md: 2, sm: 12, order: 5 },
      { name: 'line2', type: '-', sm: 12, order: 7 },
      { name: 'allergyDrug', type: 'check', md: 2, sm: 12, order: 8 },
      { name: 'line3', type: '-', sm: 12, order: 10 },
      { name: 'surgery', type: 'check', md: 2, sm: 12, order: 11 },
      { name: 'line4', type: '-', sm: 12, order: 13 },
      { name: 'medicationInUse', type: 'check', md: 2, sm: 12, order: 14 },
    )

    if (patientData.pregnancy) {
      _fields.push({ name: 'numberWeeks', type: 'text', md: 3, sm: 12, order: 6 },)
    }

    if (patientData.allergyDrug) {
      _fields.push(
        { name: 'drugName', type: 'text', md: 3, sm: 12, order: 9 },
        { name: 'allergicSymptoms', type: 'text', md: 3, sm: 12, order: 9 },
      )
    }
    if (patientData.surgery) {
      _fields.push({ name: 'surgeryName', type: 'text', md: 3, sm: 12, order: 12 })
    }
    if (patientData.medicationInUse) {
      _fields.push({ name: 'medicationInUseName', type: 'text', md: 3, sm: 12, order: 15 })
    }
  } else {
    _fields.push(
      { name: 'phototherapy', text: formatMessage({ id: 'patient.dialog.preferences.phototherapy' }), type: '-', order: 0 },
      { name: 'anestheticP', type: 'menu', md: 3, sm: 12, order: 1 },
      { name: 'preferenceP', type: 'text', md: 3, sm: 12, order: 2 },
      { name: 'microShaping', text: formatMessage({ id: 'patient.dialog.preferences.microShaping' }), type: '-', order: 3 },
      { name: 'anestheticM', type: 'menu', md: 3, sm: 12, order: 4 },
      { name: 'preferenceM', type: 'text', md: 3, sm: 12, order: 5 },
      { name: 'lift', text: formatMessage({ id: 'patient.dialog.preferences.lift' }), type: '-', order: 6 },
      { name: 'anestheticL', type: 'menu', md: 3, sm: 12, order: 6 },
      { name: 'preferenceL', type: 'text', md: 3, sm: 12, order: 7 },
      { name: 'line', type: '-', sm: 12, order: 8 },
      { name: 'surgery', type: 'text', md: 3, sm: 12, order: 9 },
      { name: 'gynecology', type: 'text', md: 3, sm: 12, order: 10 },
      { name: 'cosmetology', type: 'text', md: 3, sm: 12, order: 11 },
      { name: 'bodyFiguring', type: 'text', md: 3, sm: 12, order: 12 },
      { name: 'careProduct', type: 'text', md: 3, sm: 12, order: 13 },
      { name: 'customerPreferences', type: 'text', md: 3, sm: 12, order: 14 }
    )
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function savePatientHistory() {
    setLoadingApprove(true)
    let updateData = {}
    if (historyType === 'skinCareHistory') {
      let newLaser = {}
      let newBeautyTreatment = {}
      for (const key of Object.keys(patientData)) {
        if (['ipl', 'pl', 'fx', 'ultheraphy', 'thermage', 'laserOthers'].includes(key)) {
          if (key === 'laserOthers') {
            if (patientData[key] === '') {
              updateData.otherLaserTreatmentDetail = null
            } else {
              updateData.otherLaserTreatmentDetail = { [Math.random().toString(36).slice(2)]: { detail: patientData[key] } }
            }
          } else {
            newLaser[key] = patientData[key] ? patientData[key] : null
          }
        } else if (['chemicalPeeling', 'moisturizer', 'lpg', 'beautyTreatmentOthers'].includes(key)) {
          if (key === 'beautyTreatmentOthers') {
            if (patientData[key] === '') {
              updateData.beautyTreatmentDetail = null
            } else {
              updateData.beautyTreatmentDetail = { [Math.random().toString(36).slice(2)]: { detail: patientData[key] } }
            }
          } else {
            newBeautyTreatment[key] = patientData[key] ? patientData[key] : null
          }
        }
      }

      updateData.laser = newLaser
      updateData.beautyTreatments = newBeautyTreatment

      if (patientData.beautyInjection) {
        updateData.beautyInjections = {
          checked: {
            site: patientData.injectionSite,
            time: dayjs(patientData.lastInjectionTime).format('YYYY-MM-DD')
          }
        }
      } else {
        updateData.beautyInjections = { checked: false }
      }

      if (patientData.filler) {
        updateData.fillers = {
          checked: {
            name: patientData.fillerName,
            site: patientData.fillerSite,
            time: dayjs(patientData.fillerTime).format('YYYY-MM-DD')
          }
        }
      } else {
        updateData.fillers = { checked: false }
      }
    } else if (historyType === 'medicalHistory') {
      let newDisease = {};
      let newHealth = {}
      for (const key of Object.keys(patientData)) {
        if (['keloid', 'heartDisease', 'immuneSystem', 'diabetes', 'hypertension', 'eczema', 'herpes', 'hepatitisB', 'hepatitisC', 'diseaseOthers'].includes(key)) {
          if (key === 'diseaseOthers') {
            if (patientData[key] === '') {
              updateData.othersDisease = null
            } else {
              updateData.othersDisease = { [Math.random().toString(36).slice(2)]: { detail: patientData[key] } }
            }
          } else {
            newDisease[key] = patientData[key] ? patientData[key] : null
          }
        } else if (['ginseng', 'ginkgo', 'multivitamin', 'vitaminE', 'otherHealthSupplement'].includes(key)) {
          if (key === 'otherHealthSupplement') {
            if (patientData[key] === '') {
              updateData.otherHealthSupplement = null
            } else {
              updateData.otherHealthSupplement = { [Math.random().toString(36).slice(2)]: { detail: patientData[key] } }
            }
          } else {
            newHealth[key] = patientData[key] ? patientData[key] : null
          }
        }
      }

      updateData.disease = newDisease
      updateData.healthSupplementInUse = newHealth
      if (patientData.pregnancy) {
        updateData.pregnancy = Number(patientData.numberWeeks)
      } else {
        updateData.pregnancy = 0
      }


      if (patientData.allergyDrug) {
        updateData.allergyDrug = {
          [patientId]: {
            name: patientData.drugName || '',
            symptom: patientData.allergicSymptoms || ''
          }
        }
      } else {
        updateData.allergyDrug = null
      }

      if (patientData.surgery) {
        updateData.surgery = {
          [patientId]: {
            detail: patientData.surgeryName || '',
          }
        }
      } else {
        updateData.surgery = null
      }

      if (patientData.medicationInUse) {
        updateData.medicationInUse = {
          [patientId]: {
            name: patientData.medicationInUseName || '',
          }
        }
      } else {
        updateData.medicationInUse = null
      }
    } else {
      updateData = {
        phototherapy: patientData.anestheticP,
        phototherapyComment: patientData.preferenceP,
        microShaping: patientData.anestheticM,
        microShapingComment: patientData.preferenceM,
        lift: patientData.anestheticL,
        liftComment: patientData.preferenceL,
        surgeryComment: patientData.surgery,
        gynecologyComment: patientData.gynecology,
        cosmetologyComment: patientData.cosmetology,
        bodyFiguringComment: patientData.bodyFiguring,
        careProductComment: patientData.careProduct,
      }

      for (const x of selectField) {
        if (!updateData.phototherapy[x]) {
          delete updateData.phototherapy[x]
        }

        if (!updateData.microShaping[x]) {
          delete updateData.microShaping[x]
        }

        if (!updateData.lift[x]) {
          delete updateData.lift[x]
        }
      }
    }

    try {
      await (firebase.functions().httpsCallable('savePatientHistorys'))({ id: patientId, path: historyType, ...updateData })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  const handleClick = (event, field) => {
    setCheckDailog({
      anchorEl: event.currentTarget,
      name: field.name,
      open: true
    });
  };

  function updateData(field, value) {
    let newValue = value

    let newData = { ...patientData, [field.name]: newValue }

    setPatientData(newData)
  }

  function updateCheckData(field, value) {
    let newValue = value
    let newData = { ...patientData, [field.name]: { ...patientData[field.name], [field.value]: newValue } }

    if (field.value === 'no') {
      for (const v of ['hypoallergenic', 'normal']) {
        newData[field.name][v] = false
      }
    } else if (['hypoallergenic', 'normal'].includes(field.value)) {
      newData[field.name]['no'] = false
    }

    setPatientData(newData)
  }

  function handleCheckChange(event, field) {
    updateData({ name: `${field.name}` }, event.target.checked);
  };

  function createField(field) {
    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    }

    let newValue = patientData[field.name] ? patientData[field.name] : ''
    let labelText = formatMessage({ id: `patient.dialog.${historyType}.${field.name}` })

    if (field.type === 'check') {
      newValue = patientData[field.name] ? patientData[field.name] : false
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <FormControlLabel
          control={<Checkbox
            checked={patientData[field.name]}
            onChange={e => handleCheckChange(e, field)}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />}
          label={labelText}
        />
      </Grid>
    } else if (field.type === 'menu') {
      let newText = []
      Object.keys(patientData[field.name]).forEach(x => {
        if (patientData[field.name][x]) {
          newText.push(formatMessage({ id: `patient.preferences.anesthetic.${x}` }))
        }
      })
      newValue = newText.map(s => s).join(', ')
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={formatMessage({ id: 'patient.preferences.anesthetic.root' })}
          value={newValue}
          fullWidth
          size="small"
          variant="outlined"
          onClick={(e) => handleClick(e, field)}
        />
      </Grid>
    } else if (field.type === 'time') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          closeToolbar={true}
          label={labelText}
          value={newValue !== '' ? dayjs(newValue) : null}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        required={field.required}
        label={labelText}
        value={newValue}
        fullWidth
        size="small"
        variant="outlined"
        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
      />
    </Grid>
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {checkDailog && <Menu
        id="fade-menu"
        anchorEl={checkDailog.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        open={open}
        onClose={() => setCheckDailog(null)}
        TransitionComponent={Fade}
      >
        <List>
          {selectField.map((value, idx) => {
            return (
              <ListItem key={`${value}-${idx}`} dense >
                <Checkbox
                  style={{ padding: '0 4px' }}
                  edge="start"
                  size="small"
                  checked={patientData[checkDailog.name][value]}
                  onClick={e => updateCheckData({ name: `${checkDailog.name}`, value: value }, e.target.checked)}
                  tabIndex={-1}
                  disableRipple
                />
                {formatMessage({ id: `patient.preferences.anesthetic.${value}` })}
              </ListItem>
            );
          })}
        </List>
      </Menu>}
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: `patient.dialog.${historyType}.edit` })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: '8px' }}>
            {fields.map(field => createField(field))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonProgress handleClose={handleClose} handleClick={() => savePatientHistory()} loading={loadingApprove} buttonText={'button.save'} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditHistoryPage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  patientId: PropTypes.string.isRequired,
  historyType: PropTypes.string.isRequired
};

export default EditHistoryPage;
