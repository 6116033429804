import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from '../modules/sort';
import EnhancedTableRow from './EnhancedTableRow';
import EnhancedTableHead from './EnhancedTableHead';
import SearchBox from './SearchBox';
import ExpandButton from './ExpandButton';
import SimpleTableToolbar from './SimpleTableToolbar';
import { objectToArray } from '../modules/data';

export default function SelectStaffDialog({ handleClose, handleSave, defaultSelectedItems, dialogTitle }) {
  const { formatMessage } = useIntl()
  const [currentFilter, setCurrentFilter] = useState(null)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [expand, setExpand] = useState(true);
  const userMapping = useSelector(state => state.firebase.data.users);
  const Staffs = objectToArray(userMapping).filter(u => u.active && u.employeeId !== defaultSelectedItems)

  const headerCells = [
    { text: 'displayName', sort: 'displayName' },
    { text: 'employeeId', sort: 'employeeId' },
  ].map(c => { c.text = formatMessage({ id: `patient.dialog.selectStaff.${c.text}` }); return c })

  const rowCells = [
    { field: 'displayName' },
    { field: 'employeeId' },
  ]

  const filterItems = [
    { name: 'displayName' },
    { name: 'employeeId' },
  ].map(i => { i.text = formatMessage({ id: `patient.dialog.selectStaff.${i.name}` }); return i })

  function filterByText() {
    if (currentFilter.name === 'displayName') {
      return Staffs.filter(s => s.displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'employeeId') {
      return Staffs.filter(s => s.employeeId.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatData = (data) => {
    const newData = { ...data }

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const onApply = (staff) => {
    handleSave(staff)
    handleClose()
  }

  const filteredStaffs = currentFilter && currentFilter.text ? filterByText() : Staffs

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <SimpleTableToolbar
              title={'noMessage'}
              bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
              toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <EnhancedTableHead
                  headerCells={headerCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredStaffs.length}
                />
                <TableBody>
                  {stableSort(filteredStaffs.map(r => formatData(r)), getComparator(order, orderBy)).map(staff => (
                    <EnhancedTableRow
                      key={staff.id}
                      rowCells={rowCells}
                      cellData={staff}
                      onRowClick={() => onApply(staff)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
      </DialogActions>
    </Dialog>

  );
}

SelectStaffDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  defaultSelectedItems: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
};
