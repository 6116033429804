import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default function SimpleTableToolbar(props) {
  const { title, note, toolbox } = props;

  return (
    <Toolbar sx={{ pl: 2, pr: 1 }}>
      <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
        <FormattedMessage id={title} />
        {note && note}
      </Typography>
      {toolbox}
    </Toolbar>
  );
};

SimpleTableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  toolbox: PropTypes.element,
  note: PropTypes.string,
};
