import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { getComparator, stableSort } from '../../modules/sort';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import FabComponent from '../../components/FabComponent'
import DatePickerField from '../../components/DatePickerField'
import ButtonProgress from '../../components/ButtonProgress';

function SalaryDialog({ handleClose }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [monthTime, setMonthTime] = useState(dayjs().format('YYYY-MM'));

  async function handleSave() {
    setLoadingApprove(true)
    try {
      await (firebase.functions().httpsCallable('createSalaryList'))({ monthTime })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'finance.salary.dialog.title' })}</DialogTitle>
      <DialogContent>
        <Grid container sx={{ p: 1 }} spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <DatePickerField
              required
              label={formatMessage({ id: 'finance.salary.dialog.month' })}
              value={dayjs(monthTime)}
              inputFormat="YYYY-MMM"
              onChange={date => setMonthTime(dayjs(date).format('YYYY-MM'))}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonProgress handleClose={handleClose} handleClick={() => handleSave()} loading={loadingApprove} />
      </DialogActions>
    </Dialog>
  );
}

SalaryDialog.propTypes = {
  handleClose: PropTypes.func.isRequired
};

function SalaryDetail({ salaryData, userMapping }) {
  const { formatMessage } = useIntl()
  const currentData = Object.keys(salaryData).reduce((acc, cur) => {
    acc.push({ id: cur, ...salaryData[cur] })
    return acc
  }, [])

  const formatData = (data) => {
    const newData = { ...data }
    newData.name = userMapping[newData.id].displayName
    newData.onBoardingCount = dayjs().startOf('date').diff(dayjs(newData.onBoardingDate).startOf('date'), 'day')
    return newData
  }

  const headerCells = [
    { text: 'name' },
    { text: 'onBoardingDate' },
    { text: 'onBoardingCount' },

    { text: 'basicSalary' },
    { text: 'fullAttendance' },
    { text: 'subsistenceAllowance' },
    { text: 'jobAllowance' },
    { text: 'licenseAllowance' },
    { text: 'otherPlus' },
    { text: 'personalLeave', style: { color: 'red' } },
    { text: 'sickLeave', style: { color: 'red' } },
    { text: 'dueAmount' },

    { text: 'healthInsurance', style: { color: 'red' } },
    { text: 'laborInsurance', style: { color: 'red' } },
    { text: 'incomeTax', style: { color: 'red' } },
    { text: 'secondGenerationNHI', style: { color: 'red' } },
    { text: 'otherMinus', style: { color: 'red' } },
    { text: 'deduction', style: { color: 'red' } },

    { text: 'actualAmount' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `finance.salary.detail.${c.text}` }); return c })

  const rowCells = [
    { field: 'name' },
    { field: 'onBoardingDate' },
    { field: 'onBoardingCount' },

    { field: 'basicSalary' },
    { field: 'fullAttendance' },
    { field: 'subsistenceAllowance' },
    { field: 'jobAllowance' },
    { field: 'licenseAllowance' },
    { field: 'otherPlus' },
    { field: 'personalLeave', style: { color: 'red' } },
    { field: 'sickLeave', style: { color: 'red' } },
    { field: 'dueAmount', style: { fontWeight: 'bold' } },

    { field: 'healthInsurance', style: { color: 'red' } },
    { field: 'laborInsurance', style: { color: 'red' } },
    { field: 'incomeTax', style: { color: 'red' } },
    { field: 'secondGenerationNHI', style: { color: 'red' } },
    { field: 'otherMinus', style: { color: 'red' } },
    { field: 'deduction', style: { color: 'red' } },

    { field: 'actualAmount', style: { fontWeight: 'bold' } },
    { field: 'note' },
  ]

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ marginTop: '4px' }} aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              rowCount={currentData.length}
            />
            <TableBody>
              {currentData.map(c => formatData(c)).map(data => (
                <EnhancedTableRow
                  key={data.id}
                  rowCells={rowCells}
                  cellData={data}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

SalaryDetail.propTypes = {
  salaryData: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired
};

function SalaryPage() {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date')
  const [salaryList, setSalaryList] = useState([])
  const [openDailog, setOpenDailog] = useState(false)
  const userMapping = useSelector(state => state.firebase.data.users);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('salaryList').onSnapshot(snapshot => {
      const data = []
      snapshot.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      });
      setSalaryList(data)
    }, err => { })

    return () => unsubscribe()
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatData = (data) => {
    const newData = { ...data }
    newData.yearMonth = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM')
    newData.createdAt = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD')
    newData.createdBy = userMapping[newData.createdBy].displayName
    return newData
  }

  const headerCells = [
    { text: 'yearMonth', sort: 'yearMonth' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'createdAt', sort: 'createdAt' },
  ].map(c => { c.text = formatMessage({ id: `finance.salary.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'yearMonth' },
    { field: 'createdBy' },
    { field: 'createdAt' },
  ]

  return (
    <Grid container spacing={1} sx={{ marginTop: '10px' }}>
      <FabComponent onClick={() => setOpenDailog(true)} />
      {openDailog && <SalaryDialog
        handleClose={() => setOpenDailog(false)}
      />}
      <Grid item xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ marginTop: '4px' }} aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={salaryList.length}
              expandable
            />
            <TableBody>
              {stableSort(salaryList.map(s => formatData(s)), getComparator(order, orderBy)).map(data => (
                <EnhancedTableRow
                  key={data.id}
                  rowCells={rowCells}
                  cellData={data}
                  expandable
                  expandContent={<SalaryDetail salaryData={data.salaryData} userMapping={userMapping} />}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default SalaryPage;
