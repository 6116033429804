const i18n = {
  'selectPatientDialog': {
    'root': '客戶列表',
    'title': '選擇客戶',
  },
  'selectReferrerDialog': {
    'title': '選擇介紹人',
  },
  'selectEmployeeIdDialog': {
    'title': '選擇員工編號',
  },
  'selectMedicineDialog': {
    'title': '選擇藥品',
  },
  'selectProductDialog': {
    'title': '選擇療程',
  },
  'cancelled': {
    'cancelled': '確定取消此預約？',
    'cancelanotherappointment': '確定取消此預約並重新預約？',
    'cancelYes': '取消此預約',
    'cancelNo': '不取消'
  }
}
export default i18n
