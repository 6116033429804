import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import ContextStore from '../../modules/context';

function NormalPersonalBonus({ currentUser }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  console.log(currentUser)
  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.reports.personalBonus' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  return (
    <div>aaaaaa</div>
  );
}

NormalPersonalBonus.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    announcement: PropTypes.number.isRequired
  }),
};

export default NormalPersonalBonus;
