import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import EnhancedTableRow from '../../../components/EnhancedTableRow';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../../modules/sort';

function Kardex({ kardexes, userMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')

  const headerCells = [
    { text: 'kardexId', sort: 'displayId' },
    { text: 'date', sort: 'date' },
    { text: 'arrangeTreatments', sort: 'treatmentsTime' },
    { text: 'doctor', sort: 'doctorName' },
    { text: 'nurse', sort: 'nurseName' },
    { text: 'salesRep', sort: 'salesRepName' },
    { text: 'staytime', sort: 'staytime' },
  ].map(c => { c.text = formatMessage({ id: `kardex.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'displayId' },
    { field: 'date' },
    { field: 'treatmentsTime' },
    { field: 'doctorName' },
    { field: 'nurseName' },
    { field: 'salesRepName' },
    { field: 'staytime' },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatData = (kardex) => {
    const newData = { ...kardex }

    let totalDuration = 0
    if (newData.treatments) {
      for (const key of Object.keys(newData.treatments)) {
        totalDuration += newData.treatments[key].duration
      }
    }
    const endTime = dayjs(`${newData.date} ${newData.time}`, 'YYYY-MM-DD HH:mm').add(totalDuration, 'minute').format('HH:mm')
    newData.treatmentsTime = `${newData.time}-${endTime}`
    newData.doctorName = userMapping[newData.doctor] ? userMapping[newData.doctor].displayName : ''
    newData.nurseName = userMapping[newData.nurse] ? userMapping[newData.nurse].displayName : ''
    newData.salesRepName = userMapping[newData.salesRep] ? userMapping[newData.salesRep].displayName : ''

    return newData
  }

  function handleClick(kardexId) {
    window.open(`kardexes/${kardexId}`)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ marginTop: '20px', width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif' }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={kardexes.length}
              />
              <TableBody>
                {stableSort(kardexes.map(r => formatData(r)), getComparator(order, orderBy)).map(kardex => (
                  <EnhancedTableRow
                    key={kardex.id}
                    rowCells={rowCells}
                    cellData={kardex}
                    onRowClick={() => handleClick(kardex.id)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Grid>
  );
}

Kardex.propTypes = {
  kardexes: PropTypes.arrayOf(PropTypes.object.isRequired),
  userMapping: PropTypes.object.isRequired
};

export default Kardex;
