import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import PdfIcon from '@mui/icons-material/PictureAsPdf';

import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import ActionDialog from '../../components/ActionDialog';
import PaymentView from './PaymentView';

function PurchasePage({ purchaseOrders, userMapping, payments, purchasedProducts, storedValueCard, productMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [openDialog, setOpenDialog] = useState(null)
  const [loading, setLoading] = useState(false)

  function isConsume(purchase) {
    const newData = purchasedProducts.filter(p => p.purchaseOrder === purchase.id)
    for (const d of newData) {
      if (d.consumptionHistory) {
        return false
      }
    }

    if (purchase.payments) {
      for (const paymentKey of Object.keys(purchase.payments)) {
        const payment = payments[paymentKey]
        if (!payment) { continue; }
        for (const productKey of Object.keys(purchase.products)) {
          const productId = purchase.products[productKey].productId
          const product = productMapping[productId]
          if (!product) { continue; }
          if (product.cat1 === 'DP' && !product.consumable) {
            if (storedValueCard[productId]) {
              if (payment.collected > storedValueCard[productId]) {
                return false
              }
            }
          }
        }
      }
    }

    return true
  }

  async function handleExecute(data) {
    setLoading(true)
    const purchaseOrderId = openDialog.id
    const newData = { ...openDialog, reason: { ...data } }

    try {
      await (firebase.functions().httpsCallable('purchaseOrdersVoid'))({ id: purchaseOrderId, ...newData })
    } catch (ex) {
      console.log(ex)
    }
    setLoading(false)
  }

  function filterPurchaseOrders() {
    let item = [];
    purchaseOrders.forEach(po => {
      if (!po.void) {
        const totalCost = po.discountPrice || 0

        let newPayment = []
        if (po.payments) {
          Object.keys(po.payments).forEach(p => {
            if (payments[p]) {
              newPayment.push(payments[p])
            }
          })
        }

        const totalPaid = newPayment.reduce((acc, payment) => {
          if (payment.void) {
            return acc
          }
          return acc + (payment.collected || 0)
        }, 0)

        let status = ''

        if (totalPaid === po.discountPrice) {
          status = formatMessage({ id: 'purchaseOrder.table.collected' })
        } else if (totalPaid === 0) {
          status = formatMessage({ id: 'purchaseOrder.table.uncollected' })
        } else {
          status = formatMessage({ id: 'purchaseOrder.table.partial' })
        }

        const agentName = po.agent ? userMapping[po.agent.id].displayName : ''

        item.push({
          ...po,
          totalPaid,
          totalCost,
          status,
          agentName
        })
      }
    })

    return item
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date', sort: 'date' },
    { text: 'totalCost', sort: 'totalCost' },
    { text: 'totalPaid', sort: 'totalPaid' },
    { text: 'agentName', sort: 'agentName' },
    { text: 'status', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `manage.purchase.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'sn' },
    { field: 'date' },
    { field: 'totalCost' },
    { field: 'totalPaid' },
    { field: 'agentName' },
    { field: 'status' },
  ]

  async function openPurchaseOrder(purchase) {
    setLoading(true)
    const storageRef = firebase.storage().ref('purchaseOrders')
    if (purchase.pdf) {
      try {
        storageRef.child(`${purchase.patient}/${purchase.kardex || 'default'}/${purchase.id}/${purchase.pdf}`).getDownloadURL().then((url) => {
          window.open(url, '_blank')
        }, () => {
          alert('Purchase order file not found')
        })
      } catch (ex) {
        console.log(ex)
      }
    } else {
      if (purchase.payments) {
        try {
          await firebase.functions().httpsCallable('htmlToPDF')({ purchaseOrderId: purchase.id, customer: process.env.BRANCH_ENV })
          const url = await storageRef.child(`${purchase.patient}/${purchase.kardex || 'default'}/${purchase.id}/${purchase.id}.pdf`).getDownloadURL()
          if (url) {
            window.open(url, '_blank')
          } else {
            alert('Purchase order file not found')
          }
        } catch (ex) {
          console.log(ex)
        }
      }
    }
    setLoading(false)
  }

  const filterPurchases = filterPurchaseOrders()

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', }}>
      {loading && <Backdrop open={true} sx={{ zIndex: 2000, color: '#fff' }}>
        <CircularProgress disableShrink color="inherit" />
      </Backdrop>}
      {openDialog && <ActionDialog
        title={formatMessage({ id: 'manage.purchase.dialog.title.purchase' })}
        handleClose={() => setOpenDialog(null)}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'manage.purchase.dialog.voidReason' })}
        action={'void'}
      />}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filterPurchases.length}
            expandable
            actionButton
          />
          <TableBody>
            {stableSort(filterPurchases, getComparator(order, orderBy)).map(purchase => (
              <EnhancedTableRow
                key={purchase.id}
                rowCells={rowCells}
                cellData={purchase}
                expandable
                expandContent={<PaymentView purchaseOrder={purchase} productMapping={productMapping} />}
                actionIcons={<>
                  <Tooltip title={formatMessage({ id: 'button.pdf' })}>
                    <IconButton onClick={() => openPurchaseOrder(purchase)} size="large">
                      <PdfIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {isConsume(purchase) ?
                    <Tooltip title={formatMessage({ id: 'button.void' })}>
                      <IconButton onClick={() => setOpenDialog(purchase)} size="large">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip> : null}
                </>}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div >
  );
}

PurchasePage.propTypes = {
  purchaseOrders: PropTypes.arrayOf(PropTypes.object.isRequired),
  purchasedProducts: PropTypes.arrayOf(PropTypes.object.isRequired),
  userMapping: PropTypes.object,
  payments: PropTypes.object.isRequired,
  storedValueCard: PropTypes.object
};

export default PurchasePage;
