const i18n = {
  'merchandise': {
    'table': {
      'title': '耗材列表',
      'code': '品號',
      'name': '品名',
      'nickname': '簡稱',
      'sku': '訂貨單位',
      'note': '備註',
      'detail': {
        'controlledDrug': '管制類別',
        'type': '分類1',
        'category': '分類2',
        'code': '品號',
        'name': '名稱',
        'nickname': '簡稱',
        'note': '備註',
        'sku': '庫存單位(SKU)',
      },
      'header': {
        'code': '品號',
        'name': '名稱',
        'nickname': '簡稱',
        'sku': '庫存單位(SKU)',
        'onShelfDate': '上架日期',
        'offShelfDate': '下架日期',
      },
    },
    'medicine': '藥品',
    'name': '藥名',
    'dosingDay': '天數',
    'usage': {
      'root': '用法',
      'QID': '一天四次，三餐飯後/前與睡前(QID)',
      'QD': '一天一次(QD)',
      'BID': '一天兩次(BID)',
      'TID': '一天三次，三餐飯後(TID)',
      'HS': '每晚睡前半小時一次(HS)',
      'QN': '每晚一次(QN)',
      'PRN': '需要時使用(PRN)',
      'Stay': '不用列入處方籤(Stay)'
    },
    'dose': '用量',
    'unit': '單位',
    'total': '發藥量',
    'editMerchandise': {
      'table': {
        'selected': '已選擇 {count} 個項目',
      },
    },
    'edit': {
      'addMerchandise': '新增藥品',
      'removeMerchandise': '移除商品',
    },
    'category': {
      'all': '全部',
      'M': '藥品',
      'DPO': '口服藥',
      'DOI': '藥膏',
      'DIV': '針劑',
      'DSC': '保養品/保健品',
      'I': '耗材',
      'CIN': '填充注射',
      'CPR': '電音波類(探頭)',
      'CBD': '體雕(點數卡)',
      'CIP': '植入物',
      'CCO': '消耗醫材',
      'CWI': '線材',
      'COT': '其他',
    },
    'type': {
      'g': '正品',
      'i': '沙貨',
      'b': '正品+沙貨',
    },
    'controlledDrug': {
      'type1': '管制性藥品',
      'type2': '非管制性藥品',
    },
  }
}
export default i18n