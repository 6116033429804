import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { useSelector, Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { useFirebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';

import LoginForm from './pages/LoginForm/LoginForm';
import { store, firebase } from './store/index';
import { getCurrentUser } from './modules/user';
import App from './App';
import i18n from './i18n/i18n'

const cache = createIntlCache()
const intl = createIntl({
  locale: 'zh-TW',
  messages: i18n
}, cache)



function UserIsLoaded({ auth }) {
  useFirebaseConnect([
    {
      path: '/users',
      storeAs: 'users'
    },
    {
      path: `/users/${auth.uid}`,
      storeAs: 'user'
    },
  ])

  store.firestore.setListeners([
    { collection: 'userRights', where: [firebase.firestore.FieldPath.documentId(), '==', auth.uid], storeAs: 'userRights' },
  ])

  const userRight = useSelector(state => state.firestore.data.userRights);
  const user = useSelector(state => state.firebase.data.user);
  if (!user) {
    return (<div className='loginWithGoogle'>
      loading...
    </div>)
  }

  const currentUserRight = userRight && userRight[auth.uid] ? userRight[auth.uid] : {}
  const currentUser = getCurrentUser({
    ...user,
    key: auth.uid,
  });

  return (
    <RawIntlProvider value={intl}>
      <BrowserRouter>
        <App user={currentUser} userRight={currentUserRight || {}}/>
      </BrowserRouter>
    </RawIntlProvider>
  )
}

UserIsLoaded.propTypes = {
  auth: PropTypes.object,
};


function AuthIsLoaded() {

  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) {
    return (
      <div className='loginWithGoogle'>
        loading...
      </div>
    )
  } else if (isEmpty(auth)) {
    return (
      <RawIntlProvider value={intl}>
        <LoginForm />
      </RawIntlProvider>
    )
  };
  return <UserIsLoaded auth={auth} />
}

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    // useFirestoreForProfile: true
  },
  dispatch: store.dispatch,
  // createFirestoreInstance
};

render(
  // <React.StrictMode>
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded />
    </ReactReduxFirebaseProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// if (module.hot) {
//   module.hot.accept(App);
// }
