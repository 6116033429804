import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DatePickerField from '../../components/DatePickerField';
import AlertDialog from '../../components/AlertDialog';
import OvertimeWorkType from '../../enum/OvertimeWorkType'
import ButtonProgress from '../../components/ButtonProgress'
import { getWeekDay } from '../../modules/uitls'

const _OvertimeType = OvertimeWorkType.concat().filter(type => type.type === 'all');;
const _OvertimeNurseType = OvertimeWorkType.concat()

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

function EditOvertimePage({ currentUser }) {
  const { formatMessage } = useIntl()
  const { overtimeId } = useParams()
  const history = useHistory()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [overtimeBalance, setOvertimeBalance] = useState([])
  const [dialogOpen, setDialogOpen] = useState(null)
  const [punchClockException, setPunchClockException] = useState({})
  const [pendingOvertimes, setPendingOvertimes] = useState([])
  const [punchClock, setPunchClock] = useState({})
  const [overtimeData, setOvertimeData] = useState({
    overtimeType: currentUser.department !== 'nurse' ? _OvertimeType[0].value : _OvertimeNurseType[0].value,
    startDate: dayjs().subtract(1, 'day'),
    startHour: '09',
    startMinute: '00',
    endDate: dayjs().subtract(1, 'day'),
    endHour: '23',
    endMinute: '00',
    hours: 0,
    reason: '',
  })

  const startDateM = dayjs(overtimeData.startDate).format('YYYY-MM-DD')
  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('punchClock').where(firebase.firestore.FieldPath.documentId(), '==', startDateM).onSnapshot(snapshot => {
      let punchClock = []
      snapshot.forEach(doc => {
        const data = doc.data()
        if (data.record && data.record[currentUser.key]) {
          punchClock = punchClock.concat(data.record[currentUser.key])
        }

        if (data.revise && data.revise[currentUser.key]) {
          punchClock = punchClock.concat(data.revise[currentUser.key])
        }
      });

      punchClock.sort()
      if (punchClock.length > 0) {
        setOvertimeData(data => ({
          ...data,
          endHour: punchClock[punchClock.length - 1].split(':')[0],
          endMinute: String(Number(punchClock[punchClock.length - 1].split(':')[1]) - (Number(punchClock[punchClock.length - 1].split(':')[1]) % 5)).padStart(2, '0')
        }))
      } else {
        setOvertimeData(data => ({
          ...data,
          endHour: '23',
          endMinute: '00'
        }))
      }

      setPunchClock(punchClock)
    }, err => { })
    return () => unsubscribe()
  }, [startDateM]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('departments').doc(currentUser.department).onSnapshot(snapshot => {
      const snapshots = snapshot.data()
      const workTimeMapping = snapshots.workTime
      const weekDay = getWeekDay(dayjs(startDateM))

      let time = ''
      if (workTimeMapping[weekDay]) {
        time = workTimeMapping[weekDay].endTime
      } else {
        time = '09:00'
      }

      setOvertimeData(data => ({
        ...data,
        startHour: time.split(':')[0],
        startMinute: time.split(':')[1]
      }))
    })
    return () => unsubscribe()
  }, [startDateM]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('punchClockExceptions').where('date', '==', startDateM).onSnapshot(snapshot => {
      let time = ''
      let pcE = {}
      snapshot.forEach(doc => {
        const data = doc.data()
        pcE = { id: doc.id, ...data }
        if (data) {
          if (data.type === 'on') {
            time = data.endTime
          } else {
            time = '09:00'
          }
        }
      })

      if (time) {
        setOvertimeData(data => ({
          ...data,
          startHour: time.split(':')[0],
          startMinute: time.split(':')[1]
        }))
      }

      setPunchClockException(pcE)
    })
    return () => unsubscribe()
  }, [startDateM]);

  useEffect(() => {
    const unsubscribe = overtimeId !== 'new' ? firebase.firestore().collection('overtimes').doc(overtimeId).onSnapshot(snapshot => {
      const data = snapshot.data()
      const startTime = String(data.startTime).split(':')
      const endTime = String(data.endTime).split(':')

      setOvertimeData({
        overtimeType: data.type,
        startDate: dayjs(data.startDate),
        startHour: startTime[0],
        startMinute: startTime[1],
        endDate: dayjs(data.endDate),
        endHour: endTime[0],
        endMinute: endTime[1],
        hours: data.hours,
        reason: data.reason,
      })
    }, err => { }) : null;

    return () => { if (unsubscribe) unsubscribe() }
  }, [overtimeId]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('overtimeBalances').doc(currentUser.key).onSnapshot(snapshot => {
      setOvertimeBalance(snapshot.data())
    }, err => { })

    return () => unsubscribe()
  }, [currentUser.key]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('overtimes')
      .where('status', '==', 'pending')
      .where('createdBy', '==', currentUser.key)
      .onSnapshot(snapshot => {
        let snapshots = []
        snapshot.forEach(doc => {
          snapshots.push({ id: doc.id, ...doc.data() })
        })
        setPendingOvertimes(snapshots)
      }, err => { })

    return () => unsubscribe()
  }, [currentUser.key]);

  function handleClose() {
    setDialogOpen(null)
  }

  async function onOvertimeSave() {
    setLoadingApprove(true)

    if (overtimeData.reason === '') {
      setLoadingApprove(false)
      return setDialogOpen({
        title: formatMessage({ id: 'overtimes.overtimeAlert.title' }),
        message: formatMessage({ id: 'overtimes.overtimeAlert.content' })
      })
    }

    if (punchClock.length === 0) {
      setLoadingApprove(false)
      return setDialogOpen({
        title: formatMessage({ id: 'overtimes.overtimeAlert.punchTitle' }),
        message: formatMessage({ id: 'overtimes.overtimeAlert.punchContent' })
      })
    }

    const newData = {
      type: overtimeData.overtimeType,
      startDate: dayjs(overtimeData.startDate).format('YYYY-MM-DD'),
      startTime: `${overtimeData.startHour}:${overtimeData.startMinute}`,
      endDate: dayjs(overtimeData.endDate).format('YYYY-MM-DD'),
      endTime: `${overtimeData.endHour}:${overtimeData.endMinute}`,
      reason: overtimeData.reason,
      hours: hoursToPeriods / 2,
    }

    if (dayjs(overtimeData.startDate).day() === 0 || punchClockException.publicHoliday) {
      newData.publicHoliday = true
    }

    if (newData.endTime > punchClock[punchClock.length - 1]) {
      setLoadingApprove(false)
      return setDialogOpen({
        title: formatMessage({ id: 'overtimes.overtimeAlert.timeTitle' }),
        message: formatMessage({ id: 'overtimes.overtimeAlert.timeContent' })
      })
    }

    try {
      await (firebase.functions().httpsCallable('saveOvertimes'))({ id: overtimeId, ...newData })
      setLoadingApprove(false)
    } catch (ex) {
      console.log(ex)
    }

    history.push('/leaveOvertimeWork/overtimes/applicationSent')
  }

  function timeRangeToPeriods() {
    const startDate = dayjs(overtimeData.startDate).format('YYYY-MM-DD')
    const startTime = `${overtimeData.startHour}:${overtimeData.startMinute}`
    const endDate = dayjs(overtimeData.endDate).format('YYYY-MM-DD')
    const endTime = `${overtimeData.endHour}:${overtimeData.endMinute}`
    const start = `${startDate} ${startTime}`;
    const end = `${endDate} ${endTime}`;
    const reg = /\d+-\d+-\d+ \d+:\d+/;

    if (reg.test(start) && reg.test(end)) {
      const startM = dayjs(start, 'YYYY-MM-DD HH:mm');
      const endM = dayjs(end, 'YYYY-MM-DD HH:mm');
      dayjs.extend(duration)
      const minutes = dayjs.duration(endM.diff(startM)).asMinutes();
      var periods = 0;
      if (minutes >= 30) {
        periods = Math.floor(Math.floor(minutes / 15) / 2.0);
      }
      return periods;
    }
  }

  const hoursToPeriods = timeRangeToPeriods();

  function handleDateChange(filed, date) {
    if (date === null || date.toString() === 'Invalid Date') {
      updateData(filed, '')
    } else {
      updateData(filed, date);
    }
  };

  function validateField(field, value) {
    if ((field.name === 'startDate' || field.name === 'endDate') && value === '') {
      return '無效的日期格式'
    }
    return ''
  }

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)

    let newData = { ...overtimeData, [field.name]: newValue, [`${field.name}_err`]: err }
    if (field.name === 'startDate') {
      newData.endDate = value
    }

    setOvertimeData(newData)
  }

  const BalanceRow = () => {
    if (overtimeBalance.pay === undefined || overtimeBalance.leave === undefined) return null;
    let signing = {
      pay: 0,
      leave: 0,
      shift: 0
    }
    for (const s of pendingOvertimes) {
      signing[s.type.replace('overtime_', '')] += s.hours
    }

    let pay = overtimeBalance.pay.balance * 2
    let leave = overtimeBalance.leave.balance * 2
    let shift = overtimeBalance.shift.balance
    let signPay = signing.pay * 2
    let sigLeave = signing.leave * 2
    let periods = parseFloat(hoursToPeriods);
    let overtime = pay + leave + signPay + sigLeave;

    if (overtimeData && !['approved', 'rejected'].includes(overtimeData.status)) {
      if (overtimeData.overtimeType === 'overtime_pay') {
        pay = `${pay + signPay + periods}`;
      } else if (overtimeData.overtimeType === 'overtime_leave') {
        leave = `${leave + sigLeave + periods}`;
      } else if (overtimeData.overtimeType === 'overtime_shift') {
        let shiftperiods = 1
        shift = `${shift + signing.shift + shiftperiods}`;
        leave = `${leave + sigLeave + periods}`;
      }
    }

    return (
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.totalOvertime' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.signPay' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.pay' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.signLeave' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.leave' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.allowance' })}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.periods' })}</TableCell>
                <TableCell align="left">{overtime}</TableCell>
                <TableCell align="left">{signPay}</TableCell>
                <TableCell align="left">{pay}</TableCell>
                <TableCell align="left">{sigLeave}</TableCell>
                <TableCell align="left">{leave}</TableCell>
                <TableCell align="left">{shift}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>
    )
  };

  return (
    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif', padding: '0 20px', }}>
      <div style={{ fontSize: '40px', marginBottom: '20px', marginTop: '20px' }}>
        {overtimeId !== 'new' ? formatMessage({ id: 'overtimes.editOvertime' }) : formatMessage({ id: 'overtimes.applyForOvertime' })}
      </div>
      {dialogOpen &&
        <AlertDialog
          dialogTital={dialogOpen.title}
          dialogMsg={dialogOpen.message}
          handleClose={handleClose}
        />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10} md={4}>
              <DatePickerField
                required
                fullWidth
                label={formatMessage({ id: 'overtimes.overtimeForm.startDateTime' })}
                value={overtimeData.startDate}
                onChange={date => handleDateChange({ name: 'startDate' }, date)}
                invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
                maxDate={dayjs().subtract(1, 'day').toDate()}
              />
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'overtimes.overtimeForm.overtimeHour' })}
                fullWidth
                value={overtimeData.startHour}
                onChange={e => updateData({ name: 'startHour' }, e.target.value)}
              >
                {hours.map((hour, idx) => {
                  return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'overtimes.overtimeForm.overtimeMinute' })}
                fullWidth
                value={overtimeData.startMinute}
                onChange={e => updateData({ name: 'startMinute' }, e.target.value)}
              >
                {minutes.map((minute, idx) => {
                  return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={10} md={4}>
              <DatePickerField
                required
                fullWidth
                disabled
                label={formatMessage({ id: 'overtimes.overtimeForm.endDateTime' })}
                value={overtimeData.endDate}
                onChange={date => handleDateChange({ name: 'endDate' }, date)}
                minDate={dayjs(overtimeData.startDate).toDate()}
                minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
                invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              // minDate={new Date()}
              />
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'overtimes.overtimeForm.overtimeHour' })}
                fullWidth
                value={overtimeData.endHour}
                onChange={e => updateData({ name: 'endHour' }, e.target.value)}
              >
                {hours.map((hour, idx) => {
                  return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'overtimes.overtimeForm.overtimeMinute' })}
                fullWidth
                value={overtimeData.endMinute}
                onChange={e => updateData({ name: 'endMinute' }, e.target.value)}
              >
                {minutes.map((minute, idx) => {
                  return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={6} md={3}>
              <div style={{ fontSize: '17px' }}>{formatMessage({ id: 'overtimes.overtimeForm.hours' })}</div>
              <div>{hoursToPeriods / 2}</div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div style={{ fontSize: '17px' }}>{formatMessage({ id: 'overtimes.overtimeForm.periods' })}</div>
              <div>{hoursToPeriods}</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                type="text"
                size="small"
                label={formatMessage({ id: 'overtimes.overtimeForm.convert' })}
                variant="outlined"
                fullWidth
                onChange={e => updateData({ name: 'overtimeType' }, e.target.value)}
                value={overtimeData.overtimeType}
              >
                {currentUser.department !== 'nurse' ? _OvertimeType.map((type, idx) => {
                  return <MenuItem key={`${type.value}-${idx}`} value={type.value}>{type.name}</MenuItem>
                }) : _OvertimeNurseType.map((type, idx) => {
                  return <MenuItem key={`${type.value}-${idx}`} value={type.value}>{type.name}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={12} md={7}>
              <TextField
                type="text"
                size="small"
                variant="outlined"
                fullWidth
                multiline
                label={formatMessage({ id: 'overtimes.overtimeForm.reason' })}
                value={overtimeData.reason}
                onChange={e => updateData({ name: 'reason' }, e.target.value)}
              >
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '20px' }}>
              <ButtonProgress
                handleClose={() => history.push('/leaveOvertimeWork/overtimes')}
                handleClick={() => onOvertimeSave()}
                loading={loadingApprove}
                buttonText={'button.submit'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <div style={{ fontSize: '30px', marginBottom: '20px' }}>
                {formatMessage({ id: 'overtimes.overtimeForm.currentBalances' })}
              </div>
              <div>
                <BalanceRow />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

EditOvertimePage.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

export default EditOvertimePage;
