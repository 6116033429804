const i18n = {
  'staff': {
    'table': {
      'name': '姓名',
      'active': '啟用',
      'inactive': '停用',
      'department': '部門',
      'occupation': '職稱',
      'email': 'Email',
      'onBoardingDate': '到職日',
      'status': '狀態',
    },
    'salary': {
      'fullTime': '正職',
      'partTime': '兼職',
      'hourlyPay': '時薪',
      'basicSalary': '底薪',
      'fullAttendance': '全勤',
      'subsistenceAllowance': '伙食津貼',
      'jobAllowance': '職務津貼',
      'licenseAllowance': '證照津貼',
      'otherPlus': '其他(+)',
      'insuredDate': '投保日期',
      'insuredAmount': '投保金額',
      'dependents': '眷屬人數',
      'incomeTax': '所得稅',
      'secondGenerationNHI': '二代健保',
      'otherMinus': '其他(-)',
      'note': '備註'
    },
    'profile': {
      'fullTime': '轉正職日期',
      'information': '資訊',
      'salaryInformation': '{name} 薪資設定',
      'name': '姓名',
      'onBoardingDate': '到職日',
      'email': 'Email',
      'employeeId': '員工編號',
      'occupation': '職稱',
      'phone': '電話',
      'address': '地址',
      'birthDate': '出生日期',
      'identityCardNumber': '身分證號',
      'emergencyContacts': '緊急聯絡人',
      'emergencyName': '姓名',
      'emergencyPhone': '電話',
      'note': '備註',
      'department': {
        'roots': '部門',
        'nurse': '護理美容部',
        'salesRep': '客戶關係代表部',
        'customerService': '客戶服務部',
        'doctor': '醫師',
        'management': '管理部',
        'marketing': '行銷部',
        'hr': '人力資源資部',
        'financial': '財務部',
        'itDept': '資訊部',
        'nutritionist': '營養師',
        'psychologist': '心理師',
      },
      'departmentSantea': {
        'nurse': '健康管理部',
        'salesRep': '業務部',
        'customerService': '客戶服務部',
        'doctor': '醫師',
        'management': '管理部',
        'marketing': '行銷部',
        'hr': '人力資源資部',
        'financial': '財務部',
        'itDept': '資訊部',
        'nutritionist': '營養師',
        'psychologist': '心理師',
      },
      'gender': {
        'roots': '性別',
        'female': '女',
        'male': '男'
      },
      'onBoardingDateOld': '到職日(舊)',
      'endOn': '離職日期',
      'position': {
        'roots': '位階',
        'management': '主管',
        'employee': '職員',
      },
      'appointmentType': {
        'roots': '科別',
        'beauty': '醫學美容',
        'beautyTreatment': ['santea'].includes(process.env.BRANCH_ENV) ? '療程' : '美容保養',
        'gynecology': '婦女全人',
        'neurology': '神經內科',
        'plastic': '整形外科',
        'preventative': '預防醫學'
      },
      'benefit': {
        'roots': '員工福利點數額度',
        'earned': '年度福利點數',
        'used': '年度已用',
        'balance': '可用總額'
      },
      'stamps': {
        'roots': '印章/頭像',
        'certificateStamp': '證照印章',
        'smallStamp': '小章',
        'bigStamp': '大章',
        'profile': '頭像'
      }
    },
    'dialog': {
      'title': {
        'add': '新增職員',
        'edit': '編輯職員',
      },
    },
  },
}

export default i18n