import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss'

import ContextStore from '../../modules/context';
import ScheduleSidebar from './ScheduleSidebar'
import { tabletMedia } from '../../constants/index'

const useStyles = createUseStyles({
  scheduleContainer: {
    top: '64px',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif',
    [tabletMedia]: {
      overflow: 'hidden',
      height: '100%',
      maxWidth: 'calc(100vw - 10px)'
    }
  },
});

function Schedule({ currentUser, userRight }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.clinicSchedule' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  return (
    <div className={classes.scheduleContainer}>
      <ScheduleSidebar currentUser={currentUser} userRight={userRight} />
    </div>
  );
}

Schedule.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  userRight: PropTypes.object.isRequired,
};

export default Schedule;
