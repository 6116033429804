import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs'
import { createUseStyles } from 'react-jss';

const date = dayjs().format('YYYY-MM-DD')

const gridItemStyle = {
  float: 'left',
  padding: '8px 16px',
  height: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  marginLeft: '-1px',
  borderLeft: '1px solid #172033',
  boxSizing: 'border-box',
}
const styles = {
  wrapper: {
    width: '654px',
    margin: '50px auto',
    fontFamily: 'PingFang TC',
    letterSpacing: '-0.1px',
  },
  header: {
    fontSize: '21px',
    fontWeight: '500',
    textAlign: 'center',
    color: '#888888',
    lineHeight: '29px',
  },
  patient: {
    padding: '16px 0',
    color: '#172033',
    fontSize: '13px',
    lineHeight: '18px',
    // textAlign: 'center',
  },
  name: {
    display: 'inline-block',
    width: '163px',
  },
  id: {
    display: 'inline-block',
    width: '163px',
  },
  dob: {
    display: 'inline-block',
    width: '163px',
  },
  medicalId: {
    display: 'inline-block',
    width: '163px',
  },
  phone: {
    display: 'inline-block',
    width: '326px',
  },
  medicaldate: {
    display: 'inline-block',
    width: '163px',
  },
  gender: {
    display: 'inline-block',
    width: '163px',
  },

  table: {
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left',
    color: '#172033',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    width: '654px',
    borderLeft: '1px solid #172033',
    borderRight: '1px solid #172033',
  },
  row: {
    height: '32px',
    borderTop: '1px solid #172033',
    borderBottom: '1px solid #172033',
    marginBottom: '-1px',
    lineHeight: '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  t163: Object.assign({
    width: '163px'
  }, gridItemStyle),
  t108: Object.assign({
    width: '108px'
  }, gridItemStyle),
  t159: Object.assign({
    width: '159px'
  }, gridItemStyle),
  t218: Object.assign({
    width: '218px',
    textAlign: 'center'
  }, gridItemStyle),
  t490: Object.assign({
    width: '490px'
  }, gridItemStyle),
  t582: Object.assign({
    width: '582px'
  }, gridItemStyle),
  t173: Object.assign({
    width: '173px'
  }, gridItemStyle),
  t124: Object.assign({
    width: '124px'
  }, gridItemStyle),
  t80: Object.assign({
    width: '81px'
  }, gridItemStyle),
  t50: Object.assign({
    width: '50px'
  }, gridItemStyle),
  t72: Object.assign({
    width: '72px'
  }, gridItemStyle),
  t326: Object.assign({
    width: '326px'
  }, gridItemStyle),
  chop: {
    height: '72px',
    margin: '-5px 0',
  },
  notes: {
    fontSize: '11px',
    textAlign: 'left',
    color: '#172033',
    margin: '8px 0 40px 0',
    lineHeight: '18px',
  },
  checkbox: {

  }
}

const useStyles = createUseStyles({
  document: {
    width: '800px',
    minHeight: '1189px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #e2e6ee',
    position: 'relative',
    margin: '0 auto 20px auto',
    // ['@media print']: {
    //   width: '100%',
    //   height: 'auto',
    //   margin: '-40px 0 -40px 0',
    //   minHeight: 'auto',
    //   position: 'static',
    //   border: 'none',
    //   transform: 'scale(1) !important'
    // }
  }
})

function ControlledDrugPrescriptionDocument({ kardex, controlledDrug, patient, doctorStamp, clinicChop }) {
  const classes = useStyles()
  const medicines = []
  if (medicines.length === 0) {
    for (const m of Object.keys(controlledDrug.medicines || {})) {
      medicines.push(controlledDrug.medicines[m])
    }
  }
  const sections = ['A', 'B']

  return <div className={classes.document}>
    {sections.map(section => (
      <div key={section} style={styles.wrapper}>
        <div style={styles.header}>
          安珀琢玉醫美診所門診交付處方箋（{section})
        </div>
        <div style={styles.patient}>
          <div style={styles.medicaldate}>
            處方日期：{date}
          </div>
          <div style={styles.phone}>
            病患連絡電話：{patient.phone}
          </div>
          <div style={styles.medicalId}>
            病歷號碼：{kardex.displayId}
          </div>
          <div style={styles.name}>
            客戶姓名：{patient.name}
          </div>
          <div style={styles.gender}>
            性別：{patient.gender === 'male' ? '男' : '女'}
          </div>
          <div style={styles.dob}>
            出生日期：{patient.birthDate}
          </div>
          <div style={styles.id}>
            身分證號：{patient.identityCardNumber}
          </div>
        </div>
        <div style={styles.table}>
          <div style={styles.row}>
            <div style={styles.t163}>
              處方箋類別&nbsp;&nbsp;一般處方箋
            </div>
            <div style={styles.t490}>
              醫院診所服務機構名稱及代號&nbsp;&nbsp;&nbsp;安珀琢玉醫美診所{/* &lt;149170570&gt; */}
            </div>
          </div>
          <div style={Object.assign({}, styles.row, { borderBottom: '2px solid #172033' })}>
            <div style={styles.t163}>
              就醫科別&nbsp;&nbsp;&nbsp;{controlledDrug.division}
            </div>
            <div style={styles.t163}>
              就醫日期&nbsp;&nbsp;&nbsp;{kardex.date}
            </div>
            <div style={styles.t163}>
              機構健保代碼&nbsp;{controlledDrug.payment}
            </div>
            <div style={styles.t163}>
              給藥日份&nbsp;&nbsp;&nbsp;{controlledDrug.dosingDay}&nbsp;日
            </div>
          </div>
          <div
            style={Object.assign({}, styles.row, {
              borderBottom: '2px solid #172033',
              borderTop: '2px solid #172033'
            })}
          >
            <div style={styles.t72}>
              疾病名稱
            </div>
            <div style={styles.t582}>
              {kardex.controlledDrug.disease}
            </div>
          </div>
          <div style={styles.row}>
            <div style={Object.assign({}, styles.t173, {
              fontSize: 10,
              whiteSpace: 'pre-wrap',
              lineHeight: '14px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            })}>
              藥品名稱及規格（劑型、劑量）
            </div>
            <div style={Object.assign({}, styles.t108, {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            })}>
              用量與方法
            </div>
            <div style={Object.assign({}, styles.t50, {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            })}>
              單位
            </div>
            <div style={Object.assign({}, styles.t80, {
              fontSize: 10,
              whiteSpace: 'pre-wrap',
              lineHeight: '14px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            })}>
              單次調劑日數
            </div>
            <div style={Object.assign({}, styles.t80, {
              fontSize: 10,
              whiteSpace: 'pre-wrap',
              lineHeight: '14px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            })}>
              單次調劑總處方量
            </div>
            <div style={Object.assign({}, styles.t159, {
              fontSize: 10,
              whiteSpace: 'pre-wrap',
              lineHeight: '14px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            })}>
              單次調劑總處方量範圍
            </div>
          </div>
          {medicines.map((medicine) => (
            <div
              key={medicine.id}
              style={Object.assign({}, styles.row, { height: '100px', lineHeight: '27px', overflow: 'inherit' })}
            >
              <div style={Object.assign({}, styles.t173, {
                whiteSpace: 'pre-wrap',
                lineHeight: '16px',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                flexDirection: 'row'
              })}>
                {medicine.name}
              </div>
              <div style={Object.assign({}, styles.t108, {
                fontSize: 10,
                whiteSpace: 'pre-wrap',
                lineHeight: '14px',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                flexDirection: 'row',
              })}>
                {medicine.usage}
              </div>
              <div style={Object.assign({}, styles.t50, {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
              })}>
                {medicine.sku}
              </div>
              <div style={Object.assign({}, styles.t80, {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              })}>
                {controlledDrug.dosingDay}
              </div>
              <div style={Object.assign({}, styles.t80, {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              })}>
                {medicine.total}
              </div>
              <div style={Object.assign({}, styles.t159, {
                fontSize: 10,
                whiteSpace: 'pre-wrap',
                lineHeight: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              })}>
                <div>
                  {medicine.range && medicine.range === '1-3' ? <label>&nbsp;☑1-3&nbsp;</label> : <label>&nbsp;☐1-3&nbsp;</label>}
                  {medicine.range && medicine.range === '4-10' ? <label>&nbsp;☑4-10&nbsp;</label> : <label>&nbsp;☐4-10&nbsp;</label>}
                  {medicine.range && medicine.range === '11-40' ? <label>&nbsp;☑11-40&nbsp;</label> : <label>&nbsp;☐11-40&nbsp;</label>}
                </div>
                <div>
                  {medicine.range && medicine.range === '41-75' ? <label>&nbsp;☑41-75&nbsp;</label> : <label>&nbsp;☐41-75&nbsp;</label>}
                  {medicine.range && medicine.range === '76-100' ? <label>&nbsp;☑76-100&nbsp;</label> : <label>&nbsp;☐76-100&nbsp;</label>}
                </div>
                <div>
                  {medicine.range && medicine.range === '101-200' ? <label>&nbsp;☑101-200&nbsp;</label> : <label>&nbsp;☐101-200&nbsp;</label>}
                  {medicine.range && medicine.range === '201-300' ? <label>&nbsp;☑201-300&nbsp;</label> : <label>&nbsp;☐201-300&nbsp;</label>}
                </div>
                <div>
                  {medicine.range && medicine.range === '300以上' ? <label>&nbsp;☑300以上&nbsp;</label> : <label>&nbsp;☐300以上&nbsp;</label>}
                </div>
              </div>
            </div>
          ))}
          <div
            style={Object.assign({}, styles.row, {
              borderBottom: '2px solid #172033',
              borderTop: '2px solid #172033'
            })}
          >
            <div style={styles.t72}>
              備註
            </div>
            <div style={styles.t582}>
              {kardex.controlledDrug.comment}
            </div>
          </div>
          <div
            style={Object.assign({}, styles.row, {
              borderBottom: '2px solid #172033',
              borderTop: '2px solid #172033'
            })}
          >
            <div style={styles.t326}>
              管制藥品使用執照號碼&nbsp;&nbsp;&nbsp;{kardex.controlledDrug.licence}
            </div>
            <div style={styles.t326}>
              (牙)醫師聯絡電話&nbsp;&nbsp;&nbsp;{kardex.controlledDrug.doctorPhone}
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.t218}>
              調劑人員專業證書字號及簽章
            </div>
            <div style={styles.t108}>
              調劑日期
            </div>
            <div style={styles.t163}>
              調劑機構名稱
            </div>
            <div style={styles.t163}>
              領受人簽名
            </div>
          </div>
          <div style={Object.assign({}, styles.row, { height: '80px' })}>
            <div style={styles.t218}>
              {/* stamp */}
            </div>
            <div style={styles.t108}>
              {/* stamp */}
            </div>
            <div style={styles.t163}>
              {/* stamp */}
            </div>
            <div style={styles.t163}>
              {/* stamp */}
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.t218}>
              診治醫師簽章
            </div>
            <div style={styles.t218}>
              處方醫院診所核單
            </div>
            <div style={styles.t218}>
            </div>
          </div>
          <div style={Object.assign({}, styles.row, { height: '80px' })}>
            <div style={styles.t218}>
              {controlledDrug.signed && doctorStamp && <img alt='' style={styles.chop} src={doctorStamp} />}
            </div>
            <div style={styles.t218}>
              <img alt='' style={styles.chop} src={clinicChop} />
            </div>
            <div style={Object.assign({}, styles.t218, {
              fontSize: 10,
              whiteSpace: 'pre-wrap',
              lineHeight: '14px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'left'
            })}>
              <div>
                <label>&nbsp;☑本處方箋限調劑一次&nbsp;</label>
              </div>
              <div>
                <label>&nbsp;☐本處方箋為連續處方箋，限調劑_次&nbsp;</label>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.notes}>
          電話：（02)8789-3999 傳真：（02)8789-3979 <br />
          注意事項：<br />
          1.  本處方如有塗改，須由原處方醫師確認，否則無效。<br />
          2.  本交付調劑處方為一式兩份（A/B)，一份交病患供調劑用，另一份由處方醫事機構留存備查。<br />
          3.  本調劑之一般處方箋自就醫起三日內有效。
        </div>
      </div>
    ))}
  </div>
}

ControlledDrugPrescriptionDocument.propTypes = {
  kardex: PropTypes.object,
  controlledDrug: PropTypes.object,
  patient: PropTypes.object,
  doctorStamp: PropTypes.string,
  clinicChop: PropTypes.string,
};

export default ControlledDrugPrescriptionDocument;
