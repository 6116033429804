import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import ProgressStep from '../../components/ProgressStep'

function PunchClockReviseListView({ punchClockRevise }) {
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.firebase.data.users);
  const punchClockReviseHistory = (punchClockRevise.history || []).map(h => ({ ...h }))
  const currentStep = punchClockReviseHistory.length > 0 ? punchClockReviseHistory[punchClockReviseHistory.length - 1].step : 0

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))

    log = log.replace(/f{date}}/g, formatMessage({ id: 'punchClockRevise.table.date' }))
    log = log.replace(/f{reason}/g, formatMessage({ id: 'punchClockRevise.table.reason' }))
    log = log.replace(/f{time}/g, formatMessage({ id: 'punchClockRevise.table.time' }))

    return log
  }

  const ls = punchClockRevise.status !== 'void' ? punchClockRevise.steps.slice(currentStep, punchClockRevise.steps.length).map(s => ({ ...s })) : []
  if (punchClockRevise.status !== 'void' && currentStep === 0) {
    ls[0].name = '編輯 / 作廢'
  }
  const steps = [...punchClockReviseHistory].concat(ls)

  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <ProgressStep
            activeStep={punchClockRevise.history.length}
            steps={steps}
            orie={'horizontal'}
            alternativeLabel={true}
            title={formatMessage({ id: 'punchClockRevise.list.step' })}
          />
        </Grid>
      </Grid>
    </div>
  )
}

PunchClockReviseListView.propTypes = {
  punchClockRevise: PropTypes.object.isRequired,
};

export default PunchClockReviseListView;
