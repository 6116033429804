import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function SkinCareHistory({ skinCareHistory }) {
  const { formatMessage } = useIntl()

  if (!skinCareHistory) {
    return ('No Information...')
  }

  const _fields = [
    { name: 'laser', roots: true, sm: 12, md: 12, order: 0 },
    { name: 'beautyTreatments', roots: true, sm: 12, md: 12, order: 1 },
    { name: 'beautyInjection', text: formatMessage({ id: 'patient.skinCareHistory.beautyInjection' }), type: '-', order: 2 },
    { name: 'injectionSite', type: 'injection', sm: 12, md: 6, order: 3 },
    { name: 'lastInjectionTime', type: 'injection', sm: 12, md: 6, order: 4 },
    { name: 'filler', text: formatMessage({ id: 'patient.skinCareHistory.filler' }), type: '-', order: 5 },
    { name: 'fillerName', type: 'filler', sm: 12, md: 4, order: 6 },
    { name: 'fillerSite', type: 'filler', sm: 12, md: 4, order: 7 },
    { name: 'fillerTime', type: 'filler', sm: 12, md: 4, order: 8 },
  ]


  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field, value, medical) {
    let newValue = ''
    let labelText = ''

    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    } else if (field.type === 'injection') {
      let tableMapping = skinCareHistory.beautyInjections
      if (tableMapping) {
        let keys = Object.keys(tableMapping)
        let newMapping = {}

        if (keys.length > 0) {
          newMapping = tableMapping[keys[0]]
        }

        if (field.name === 'injectionSite') {
          newValue = newMapping.site || ''
        } else {
          newValue = newMapping.time || ''
        }
      }
    } else {
      let tableMapping = skinCareHistory.fillers
      if (tableMapping) {
        let keys = Object.keys(tableMapping)
        let newMapping = {}

        if (keys.length > 0) {
          newMapping = tableMapping[keys[0]]
        }

        if (field.name === 'fillerName') {
          newValue = newMapping.name || ''
        } else if (field.name === 'fillerSite') {
          newValue = newMapping.site || ''
        } else {
          newValue = newMapping.time || ''
        }
      }
    }

    if (field.roots) {
      labelText = formatMessage({ id: `patient.skinCareHistory.${field.name}.roots` })
    } else {
      labelText = formatMessage({ id: `patient.skinCareHistory.${field.name}` })
    }

    if (['laser', 'beautyTreatments'].includes(field.name)) {
      let tableMapping = undefined
      if (field.name === 'laser') {
        tableMapping = skinCareHistory.otherLaserTreatmentDetail
      } else if (field.name === 'beautyTreatments') {
        tableMapping = skinCareHistory.beautyTreatmentDetail
      }


      let notOther = ''
      let other = ''

      if (value) {
        notOther = Object.keys(value).filter(key => value[key]).map(s => {
          return formatMessage({ id: `patient.skinCareHistory.${field.name}.${s}` })
        })
        if (tableMapping) {
          let keys = Object.keys(tableMapping) && Object.keys(tableMapping)
          let detail = ''
          if (keys.length > 0) {
            detail = tableMapping[keys[0]].detail || ''
          }

          other = `${formatMessage({ id: `patient.skinCareHistory.${field.name}.others` })} : ${detail}`
        }

        newValue = notOther.concat(other).filter(s => s).join(', ')
      }
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        fullWidth
        variant="standard"
        size="small"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field, skinCareHistory[field.name], skinCareHistory))}
      </Grid>
    </div>
  );
}

SkinCareHistory.propTypes = {
  skinCareHistory: PropTypes.object
};

export default SkinCareHistory;
