import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs'

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import TabContainer from '../../..//containers/TabContainer/TabContainer'
import { getComparator, stableSort } from '../../../modules/sort';
import EnhancedTableRow from '../../../components/EnhancedTableRow';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../../components/SimpleTableToolbar'
import ExpandButton from '../../../components/ExpandButton';
import SearchBox from '../../../components/SearchBox';
import { formatName } from '../../../modules/uitls';

function Treatments({ data, docType, userMapping, kardex, patientMapping, patientId, refundMapping, productMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('desc');

  let defaultOrder = 'date'
  if (docType === 'taken') {
    defaultOrder = 'purchaseOrderSN'
  } else if (docType === 'transfer') {
    defaultOrder = 'createdAt'
  }
  const [orderBy, setOrderBy] = useState(defaultOrder);
  const [expand, setExpand] = useState(true);
  const [currentUseCanFilter, setCurrentUseCanFilter] = useState(null)
  const [currentUseOutFilter, setCurrentuseOutFilter] = useState(null)

  const filterItems = docType === 'taken' ? [
    { name: 'productId' },
    { name: 'productName', },
    { name: 'purchaseOrderSN' },
  ].map(i => { i.text = formatMessage({ id: `consumption.table.${docType}.${i.name}` }); return i }) : []

  const _headerCells = [
    { text: 'productId', sort: 'productId', order: 0 },
    { text: 'productName', sort: 'productName', order: 1 },
  ]

  const _rowCells = [
    { field: 'productId', order: 0 },
    { field: 'productName', order: 1 },
  ]

  const headerCells = []
  const rowCells = []

  if (docType === 'taken') {
    headerCells.push(
      ..._headerCells,
      { text: 'quantityAvailable', sort: 'quantityAvailable', order: 2 },
      { text: 'purchaseOrderSN', sort: 'purchaseOrderSN', order: 3 },
      { text: 'source', sort: 'source', order: 4 },
    )

    rowCells.push(
      ..._rowCells,
      { field: 'quantityAvailable', order: 2 },
      { field: 'purchaseOrderSN', order: 3 },
      { field: 'source', order: 4 },
    )
  } else if (docType === 'quantity') {
    headerCells.push(
      ..._headerCells,
      { text: 'date', sort: 'date', order: 2 },
      { text: 'taken', sort: 'taken', order: 3 },
      { text: 'doctor', sort: 'doctor', order: 4 },
      { text: 'nurse', sort: 'nurse', order: 5 },
      { text: 'assistant', sort: 'assistant', order: 6 },
      { text: 'type', sort: 'type', order: 7 },
    )

    rowCells.push(
      ..._rowCells,
      { field: 'date', order: 2 },
      { field: 'taken', order: 3 },
      { field: 'doctor', order: 4 },
      { field: 'nurse', order: 5 },
      { field: 'assistant', order: 6 },
      { field: 'type', order: 7 },
    )
  } else if (docType === 'transfer') {
    headerCells.push(
      ..._headerCells,
      { text: 'patient', sort: 'patient', order: 2 },
      { text: 'createdAt', sort: 'createdAt', order: 3 },
      { text: 'status', sort: 'status', order: 4 },
      { text: 'amount', sort: 'amount', order: 5 },
    )

    rowCells.push(
      ..._rowCells,
      { field: 'patientName', order: 2 },
      { field: 'createdAt', order: 3 },
      { field: 'status', order: 4 },
      { field: 'amount', order: 5 },
    )
  } else {
    headerCells.push(
      ..._headerCells,
      { text: 'date', sort: 'date', order: 2 },
      { text: 'type', sort: 'type', order: 3 },
      { text: 'price', sort: 'price', order: 4 },
      { text: 'amount', sort: 'amount', order: 5 },
      { text: 'note', sort: 'note', order: 6 },
    )
    rowCells.push(
      ..._rowCells,
      { field: 'date', order: 2 },
      { field: 'type', order: 3 },
      { field: 'price', order: 4 },
      { field: 'amount', order: 5 },
      { field: 'note', order: 6 },
    )
  }

  headerCells.map(c => { c.text = formatMessage({ id: `consumption.table.${docType}.${c.text}` }); return c }).sort((a, b) => a.order - b.order)
  rowCells.sort((a, b) => a.order - b.order)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onUseCanFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentUseCanFilter({ name, text })
    } else {
      setCurrentUseCanFilter(null)
    }
  }

  const onUseOutFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentuseOutFilter({ name, text })
    } else {
      setCurrentuseOutFilter(null)
    }
  }

  const formatData = (data) => {
    const newData = { ...data }
    newData.productId = productMapping[newData.product] ? productMapping[newData.product].code : ''
    newData.productName = productMapping[newData.product] ? productMapping[newData.product].name : ''
    if (docType === 'taken') {
      newData.quantityAvailable = `${newData.taken}/${newData.quantity}`
      if (newData.purchasedProductTransfer) {
        newData.source = formatMessage({ id: `consumption.table.${docType}.sourceTransfer` })
      } else {
        if (newData.purchaseOrder) {
          newData.source = formatMessage({ id: `consumption.table.${docType}.sourceDefault` })
        } else {
          newData.source = formatMessage({ id: `consumption.table.${docType}.storedValueCard` })
        }

      }
    } else if (docType === 'quantity') {
      newData.date = dayjs(newData.createdAt).format('YYYY-MM-DD')
      newData.assistant = userMapping[newData.assistant] ? userMapping[newData.assistant].displayName : ''
      if (newData.void) {
        newData.type = formatMessage({ id: `consumption.table.${docType}.void` })
      } else if (newData.purchasedProductTransfer) {
        newData.type = formatMessage({ id: `consumption.table.${docType}.transfer` })
      } else if (newData.purchasedProductRefund) {
        newData.type = formatMessage({ id: `consumption.table.${docType}.refund` })
      } else {
        newData.type = formatMessage({ id: `consumption.table.${docType}.kardex` })
      }

      kardex.forEach(k => {
        if (newData.kardex === k.id) {
          newData.doctor = userMapping[k.doctor] ? userMapping[k.doctor].displayName : ''
          newData.nurse = userMapping[k.nurse] ? userMapping[k.nurse].displayName : ''
        }
      })
    } else if (docType === 'transfer') {
      if (newData.oldPatient === patientId) {
        const name = formatName(patientMapping[newData.newPatient].name) || ''
        newData.patientName = `${formatMessage({ id: `consumption.table.${docType}.to` }, { patientName: name })}` || ''
        newData.status = formatMessage({ id: `consumption.table.${docType}.output` })
      } else if (newData.newPatient === patientId) {
        const name = formatName(patientMapping[newData.oldPatient].name) || ''
        newData.patientName = `${formatMessage({ id: `consumption.table.${docType}.from` }, { patientName: name })}` || ''
        newData.status = formatMessage({ id: `consumption.table.${docType}.input` })
      }
    } else {
      const newRefund = refundMapping[newData.purchasedProductRefund]
      if (newRefund) {
        newData.date = newRefund.date
        newData.type = formatMessage({ id: `manage.consume.dialog.${docType}.payment.${newRefund.type}` })
        newData.price = newRefund.price
        newData.amount = newRefund.amount
        newData.note = newRefund.note
      }
    }
    return newData
  }

  function filterByText(dataType) {
    if (dataType === 'useCan') {
      const newData = data.filter(d => d.quantity > d.taken)
      if (currentUseCanFilter.name === 'productId') {
        return newData.filter(s => productMapping[s.product] && productMapping[s.product].code.toLowerCase().includes(currentUseCanFilter.text.toLowerCase()))
      } else if (currentUseCanFilter.name === 'productName') {
        return newData.filter(s => productMapping[s.product] && (productMapping[s.product]?.name ?? '').toLowerCase().includes(currentUseCanFilter.text.toLowerCase()))
      } else if (currentUseCanFilter.name === 'purchaseOrderSN') {
        return newData.filter(s => s.purchaseOrderSN && s.purchaseOrderSN.toLowerCase().includes(currentUseCanFilter.text.toLowerCase()))
      }
    } else {
      const newData = data.filter(d => d.quantity <= d.taken)
      if (currentUseOutFilter.name === 'productId') {
        return newData.filter(s => productMapping[s.product] && productMapping[s.product].code.toLowerCase().includes(currentUseOutFilter.text.toLowerCase()))
      } else if (currentUseOutFilter.name === 'productName') {
        return newData.filter(s => productMapping[s.product] && (productMapping[s.product]?.name ?? '').toLowerCase().includes(currentUseOutFilter.text.toLowerCase()))
      } else if (currentUseOutFilter.name === 'purchaseOrderSN') {
        return newData.filter(s => s.purchaseOrderSN && s.purchaseOrderSN.toLowerCase().includes(currentUseOutFilter.text.toLowerCase()))
      }
    }
  }

  let filterData = []
  let filterUseOutData = []
  if (docType === 'quantity') {
    filterData = data
  } else if (docType === 'taken') {
    filterData = currentUseCanFilter && currentUseCanFilter.text ? filterByText('useCan') : data.filter(d => d.quantity > d.taken)
    filterUseOutData = currentUseOutFilter && currentUseOutFilter.text ? filterByText('useOut') : data.filter(d => d.quantity <= d.taken)
  } else if (docType === 'transfer') {
    filterData = data.filter(d => (d.newPatient === patientId || d.oldPatient === patientId))
  } else {
    filterData = data.filter(d => d.purchasedProductRefund)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        {docType === 'taken' && <SimpleTableToolbar
          title={`consumption.table.${docType}.header.useCan`}
          bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
          toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onUseCanFilterChanged} />}
        />}
        <TableContainer component={Paper}>
          <Table style={{ marginTop: '4px' }} aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filterData.length}
            />
            <TableBody>
              {stableSort(filterData.map(r => formatData(r)), getComparator(order, orderBy)).map(d => (
                <EnhancedTableRow
                  key={d.id}
                  rowCells={rowCells}
                  cellData={d}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {docType === 'taken' && <Grid item xs={12} sm={12} md={12}>
        <SimpleTableToolbar
          title={`consumption.table.${docType}.header.useOut`}
          bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
          toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onUseOutFilterChanged} />}
        />
        <TableContainer component={Paper}>
          <Table style={{ marginTop: '4px' }} aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filterUseOutData.length}
            />
            <TableBody>
              {stableSort(filterUseOutData.map(r => formatData(r)), getComparator(order, orderBy)).map(d => (
                <EnhancedTableRow
                  key={d.id}
                  rowCells={rowCells}
                  cellData={d}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>}
    </Grid>
  );
}

Treatments.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired),
  docType: PropTypes.string.isRequired,
  userMapping: PropTypes.object,
  kardex: PropTypes.arrayOf(PropTypes.object),
  patientMapping: PropTypes.object,
  patientId: PropTypes.string,
  refundMapping: PropTypes.object,
  productMapping: PropTypes.object
};

function Consumption({ purchasedProducts, consumedProducts, userMapping, kardex, patientMapping, transfers, patientId, refundMapping }) {
  const { formatMessage } = useIntl()
  const [productMapping, setProductMapping] = useState({})

  useEffect(() => {
    const ref = firebase.database().ref('products')
    const onDataChange = ref.on('value', snapshot => {
      setProductMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  const tabs = [
    {
      label: formatMessage({ id: 'consumption.tab.takenTreatments' }),
      component: <Treatments
        data={purchasedProducts.filter(s => !s.void)}
        docType={'taken'}
        userMapping={userMapping}
        kardex={kardex}
        productMapping={productMapping} />
    },
    {
      label: formatMessage({ id: 'consumption.tab.quantityTreatments' }),
      component: <Treatments
        data={consumedProducts.filter(s => !s.void && productMapping[s.product]?.cat1 !== 'DP')}
        docType={'quantity'}
        userMapping={userMapping}
        kardex={kardex}
        productMapping={productMapping} />
    },
    {
      label: formatMessage({ id: 'consumption.tab.transferTreatments' }),
      component: <Treatments
        data={transfers}
        docType={'transfer'}
        userMapping={userMapping}
        kardex={kardex}
        patientMapping={patientMapping}
        patientId={patientId}
        productMapping={productMapping}
      />
    },
    {
      label: formatMessage({ id: 'consumption.tab.refundTreatments' }),
      component: <Treatments
        data={consumedProducts}
        docType={'refund'}
        userMapping={userMapping}
        kardex={kardex}
        refundMapping={refundMapping}
        productMapping={productMapping}
      />
    },
  ];

  const onTabSelected = (tabIndex) => { }
  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', marginTop: '10px' }}>
      <TabContainer activeTabIndex={0} defaultSelect={0} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
    </div>
  );
}

Consumption.propTypes = {
  purchasedProducts: PropTypes.arrayOf(PropTypes.object.isRequired),
  consumedProducts: PropTypes.arrayOf(PropTypes.object.isRequired),
  userMapping: PropTypes.object,
  kardex: PropTypes.arrayOf(PropTypes.object),
  transfers: PropTypes.arrayOf(PropTypes.object.isRequired),
  patientMapping: PropTypes.object,
  patientId: PropTypes.string.isRequired,
  refundMapping: PropTypes.object
};

export default Consumption;
