import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import SelectUserDialog from './SelectUserDialog';
import { objectToArray } from '../modules/data';
import ButtonProgress from './ButtonProgress'

export default function EditUserRights({ handleClose }) {
  const { formatMessage } = useIntl()
  const [newData, setNewData] = useState({})
  const [userDialogData, setUserDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const userMapping = useSelector(state => state.firebase.data.users);

  function onStepUsersChanged(index, users) {
    const newUser = { ...newData }
    let value = []

    for (const user of users) {
      value.push(user.id)
    }

    newUser[index] = value
    setNewData({ ...newUser })
  }

  function showSelectUserPopup(field, data) {
    setUserDialogData({ index: field, user: data })
  }

  async function saveUserRights() {
    setLoadingApprove(true)

    try {
      await (firebase.functions().httpsCallable('saveUserRights'))({ ...newData })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('userRights').onSnapshot(snapshot => {
      const newMap = {
        'editUserRight': [],
        'exception-edit': [],
        'finance-edit': [],
        'finance-view': [],
        'leaveOvertime-all': [],
        'leaveOvertime-view': [],
        'patient-add': [],
        'product-view': [],
        'schedule-edit': [],
        'staff-edit': [],
        'staff-view': [],
        'manage-view': [],
        'doctorSchedule-edit': [],
        'report-view': [],
        'salary-edit': [],
        'calendar-edit': []
      }
      snapshot.forEach(doc => {
        const data = doc.data();
        for (const k in data) {
          if (data[k]) {
            newMap[k].push(doc.id)
          }
        }
      });

      setNewData(newMap)
    }, err => { })

    return () => unsubscribe()
  }, []);

  const fields = [
    { name: 'editUserRight', order: 0 },
    { name: 'exception-edit', order: 1 },
    { name: 'finance-edit', order: 2 },
    { name: 'finance-view', order: 3 },
    { name: 'leaveOvertime-all', order: 4 },
    { name: 'leaveOvertime-view', order: 5 },
    { name: 'patient-add', order: 6 },
    { name: 'product-view', order: 7 },
    { name: 'schedule-edit', order: 8 },
    { name: 'staff-edit', order: 9 },
    { name: 'staff-view', order: 10 },
    { name: 'manage-view', order: 11 },
    { name: 'doctorSchedule-edit', order: 12 },
    { name: 'report-view', order: 13 },
    { name: 'salary-edit', order: 14 },
    { name: 'calendar-edit', order: 15 },
  ].map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field) {
    let newValue = newData[field.name] ? newData[field.name].map(user => userMapping[user].displayName).join(', ') : ''

    return <Grid sx={{ marginBottom: '10px' }} key={field.name} container spacing={2}>
      <Grid item xs={6} sm={4} md={4}>
        <TextField
          multiline={field.multiline}
          type="text"
          disabled
          label={formatMessage({ id: 'header.userRight.root' })}
          value={formatMessage({ id: `header.userRight.${field.name}` })}
          fullWidth
          size="small"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6} sm={8} md={8}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={formatMessage({ id: 'header.dialog.user.root' })}
          value={newValue}
          fullWidth
          size="small"
          variant="outlined"
          onClick={(e) => showSelectUserPopup(field.name, newData[field.name])}
        />
      </Grid>
    </Grid>
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {userDialogData && <SelectUserDialog
        handleClose={() => setUserDialogData(null)}
        handleSave={onStepUsersChanged}
        users={objectToArray(userMapping).filter(u => u.active)}
        defaultSelectedItems={userDialogData.user}
        index={userDialogData.index}
      />}
      <Dialog
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth='md'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{formatMessage({ id: 'header.userRight.title' })}</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: '10px' }}>
            {fields.map(field => createField(field))}
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonProgress handleClick={() => saveUserRights()} handleClose={handleClose} loading={loadingApprove}/>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditUserRights.propTypes = {
  handleClose: PropTypes.func.isRequired
};
