const i18n = {
  'certificateOfDiagnosis': {
    'table': {
      'displayId': 'ID',
      'date': '診療日期',
      'issuanceDate': '開立日期',
      'doctor': '診治醫師'
    }
  },
  'prescriptionSign': {
    'table': {
      'type': '類型',
      'controlledDrugs': '管制藥品處方箋',
      'normal': '一般處方箋',
      'displayId': 'ID',
      'date': '診療日期',
      'dosingDay': '天數',
      'medicines': '藥品',
      'doctor': '診治醫師'
    }
  },
  'agreement': {
    'table': {
      'displayName': '名稱',
      'doctor': '醫師',
      'nurse': '護理師',
      'date': '日期',
      'detail': {
        'category': '類型'
      },
    },
    'all': '全部',
    'category': {
      'O': '其他',
      'H': '衛教確認',
      'A': '麻醉',
      'CS': '酷塑-冷凍減脂',
      'L': '雷射',
      'FL': '拉提',
      'MCS': '微整型',
      'PS': '手術',
    }
  },
  'purchaseOrder': {
    'table': {
      'sn': '營收單號',
      'date': '日期',
      'totalCost': '總金額',
      'totalPaid': '已收金額',
      'agentName': '營收單負責人',
      'status': '付款狀態',
      'confirmed': '確認',
      'uncollected': '未付',
      'collected': '付清',
      'partial': '部分付款',
      'void': '作廢'
    }
  },
  'consumption': {
    'tab': {
      'takenTreatments': '可用療程',
      'quantityTreatments': '已消耗療程',
      'transferTreatments': '療程轉讓',
      'refundTreatments': '療程退費'
    },
    'table': {
      'quantity': {
        'productId': '產品ID',
        'productName': '產品名稱',
        'date': '消耗日期',
        'taken': '數量',
        'doctor': '醫生',
        'nurse': '護理師',
        'assistant': '跟診人員',
        'type': '消耗類型',
        'void': '營收單作廢',
        'kardex': 'Kardex消耗',
        'transfer': '轉讓消耗',
        'refund': '退費消耗'
      },
      'taken': {
        'header': {
          'useCan': '可用療程',
          'useOut': '已用完療程'
        },
        'productId': '產品ID',
        'productName': '產品名稱',
        'quantityAvailable': '已用數量/購買數量',
        'purchaseOrderSN': '營收單ID',
        'source': '來源',
        'sourceDefault': '營收單',
        'storedValueCard': '儲值點數',
        'sourceTransfer': '轉讓'
      },
      'transfer': {
        'productId': '產品ID',
        'productName': '產品名稱',
        'status': '型態',
        'output': '轉出',
        'input': '轉入',
        'patient': '轉讓狀態',
        'amount': '數量',
        'createdAt': '轉讓日期',
        'to': '轉給 {patientName}',
        'from': '轉自 {patientName}'
      },
      'refund': {
        'productId': '產品ID',
        'productName': '產品名稱',
        'amount': '數量',
        'date': '退費日期',
        'price': '退費金額',
        'type': '退費方式',
        'note': '備註'
      }
    }
  }
}
export default i18n