export const treatmentColorAndBgColor = (treatment) => {
  let backgroundColor = treatment.color

  let color = treatment.type === 'pre-surgery' ||
    treatment.type === 'post-surgery'
    ? '#989ba7' : '#ffffff'

  if (treatment.type === 'product') {
    if (process.env.BRANCH_ENV === 'santea') {
      backgroundColor = '#FF2A9D'
    } else {
      switch (treatment.cat1) {
      case 'AM':
        backgroundColor = '#FF2A9D'
        break
      case 'OP':
        backgroundColor = 'green'
        break
      case 'SK':
      case 'DP':
      case 'OT':
        backgroundColor = 'grey'
        break
      default:
        break
      }
    }
  }

  return {
    color,
    backgroundColor,
  }
}
