const i18n = {
  'report': {
    'title': {
      'purchase': {
        'day': '日營收報表',
        'month': '月營收報表',
        'season': '季營收報表'
      },
      'consume': {
        'day': '日療程消耗',
        'month': '月療程消耗'
      }
    },
    'date': '選擇日期',
    'month': '選擇月份',
    'startMonth': '選擇開始月份',
    'endMonth': '選擇結束月份',
    'turnover': {
      'title': '結帳報表',
      'current': '當前營業額',
      'total': '銷售額',
      'cash': '現金',
      'swipe': '刷卡',
      'wireTransfer': '匯款',
      'mktExpence': '行銷支出',
      'benefit': '員工福利點數',
      'points': '儲值點數',
      'thirdParty': '第三方支付',
      'uncollected': '未付金額',
      'paid': '實付金額',
      'note': '備註',
      'patient': '客戶',
      'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
      'sn': '營收單號',
      'itemTotal': '合計'
    },
    'turnoverType': {
      'title': '收入類別',
      'type': {
        'total': '合計營業額',
        'beauty': '醫學美容',
        'AM': '醫美',
        'OP': '手術',
        'SK': '保品',
        'DP': '儲值',
        'OT': '其他',
        'preventative': '預防醫學',
        'CN': '諮詢',
        'EX': '檢查',
        'TT': '檢驗',
        'GE': '基因檢測',
        'SU': '營養品',
        'ND': '針劑',
        'CU': '療程',
        'other': '其他',
      }
    },
    'treatment': {
      'title': '療程明細',
      'name': '服務人',
      'customer': '客戶人數',
      'detail': {
        'name': '療程名稱',
        'patient': '客戶名稱',
        'comment': '備註'
      }
    },
    'customerCount': {
      'title': '來客數: {count}',
      'new': '新客: {count}',
      'old': '舊客: {count}',
      'oldWithNew': '舊帶新: {count}',
      'service': '僅服務',
      'turnoverRate': '(成交率: {rate}%)',
      'table': {
        'name': '客戶姓名',
        'customercode': '客戶代碼',
        'purchaseAmount': '消費金額',
        'totoal': '合計人數',
        'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
        'doctor': '醫師'
      }
    },
    'personalBonus': {
      'title': '獎金列表',
      'table': {
        'purchaseOrderDate': '營收單日期',
        'kardexDate': '日期',
        'sn': '營收單號',
        'kardexId': 'Kardex ID',
        'patientName': '客戶名',
        'productName': '品名',
        'amount': '數量',
        'purchasePrice': '單價',
        'typeA': '開發獎金(A)',
        'typeB': '結單獎金(B)',
        'typeC': '服務獎金(C)',
        'operationBonus': '操作獎金',
        'commission': '銷售獎金'
      },
      'total': {
        'typeA': '開發獎金合計',
        'typeB': '結單獎金合計',
        'typeC': '服務獎金合計',
        'operationBonus': '操作獎金合計',
        'commission': '銷售獎金合計',
        'root': '合計',
        'allTotal': '總獎金合計'
      }
    },
    'consumedMerchandise': {
      'title': {
        'day': '日消耗列表',
        'month': '月消耗列表'
      },
      'detail': {
        'unitAmount': '數量',
        'patient': '客戶',
        'dateTime': '消耗時間',
        'source': '來源',
        'sourceType': {
          'purchase': '營收單',
          'kardex': '療程消耗',
          'prescription': '一般處方箋',
          'controlledDrugs': '管制藥品處方箋'
        },
        'sourceId': '營收單號/Kardex ID'
      },
      'table': {
        'code': '品號',
        'name': '名稱',
        'nickname': '簡稱',
        'sku': '庫存單位(SKU)',
        'amount': '數量',
      }
    }
  }
}

export default i18n
