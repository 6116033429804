import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'

import ScheduleRowAppointment from './ScheduleRowAppointment';
import { moibleMedia } from '../../constants/index';
import ContextStore from '../../modules/context';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '120px',
    color: '#373737',
    borderBottom: '1px solid #D4D4D4',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#eee',
    [moibleMedia]: {
      margin: '20px 0 0 0',
      flexDirection: 'column',
      width: '100%',
      minHeight: '960px !important',
      background: 'transparent',
      backgroundColor: '#eee',
    }
  },
  beautyTreatment: {
    backgroundColor: '#fff',
  },
  cancelled: {
    opacity: 0.5
  },
  hourSlot: {
    position: 'relative',
    width: '280px',
    borderLeft: '1px solid #D4D4D4',
    '&:after': {
      content: '""',
      display: 'block',
      width: '1px',
      height: '100%',
      background: '#f3f3f6',
      position: 'absolute',
      left: '139px',
    },
    [moibleMedia]: {
      width: '100%',
      minHeight: '80px',
      borderBottom: '1px solid #D4D4D4',
      borderLeft: 'none',
      '&:after': {
        width: '100%',
        height: '1px',
        background: '#f3f3f6',
        position: 'absolute',
        top: '40px',
        left: 0
      }
    }
  },
  hourSlots: {
    alignItems: 'stretch',
    alignSelf: 'stretch',
    display: 'flex',
    [moibleMedia]: {
      flexDirection: 'column',
    }
  },
  shift: {
    backgroundColor: '#fff',
    display: 'flex',
    position: 'absolute',
    top: 0,
    height: '100%',
    [moibleMedia]: {
      height: '80px',
      width: '100%',
      left: '0 !important',
      backgroundColor: '#fff'
    }
  },
  leaves: {
    backgroundImage: 'repeating-linear-gradient(45deg, #F2F2F2 0, #F2F2F2 1px, transparent 0, transparent 50%)',
    backgroundSize: '25px 25px',
    backgroundColor: '#d4d4d4',
    display: 'flex',
    position: 'absolute',
    top: 0,
    height: '100%',
    [moibleMedia]: {
      height: '80px',
      width: '100%',
      left: '0 !important',
      backgroundColor: '#d4d4d4',
      backgroundImage: 'repeating-linear-gradient(45deg, #F2F2F2 0, #F2F2F2 1px, transparent 0, transparent 50%)',
      backgroundSize: '25px 25px',
    }
  },
  currentTime: {
    position: 'absolute',
    height: '100%',
    width: '2px',
    backgroundColor: '#ff0039',
    zIndex: 2,
  },
  comment: {
    backgroundColor: 'rgba(255, 193, 7, 0.08)',
    borderLeft: 'solid 2px #ffc107',
    borderRight: 'solid 2px #ffc107',
    display: 'flex',
    position: 'absolute',
    top: 0,
    height: '100%',
    [moibleMedia]: {
      height: '80px',
      width: '100%',
      left: '0 !important',
      border: 'solid 2px #ffc107',
      borderLeft: 'none',
      borderRight: 'none'
    }
  }
});

function ScheduleRow({
  appointments,
  appointmentType,
  doctor,
  doctorShifts,
  doctorLeaves,
  firstHour,
  lastHour,
  rowPosition,
  selectAppointment,
  selectComment,
  selectHour,
  patientList,
  currentTimePosition,
  rowHeights,
  appointmentPosition,
  calculatefunc,
  isMobile,
  comments }) {
  const { uiState } = useContext(ContextStore)
  const classes = useStyles();
  const rowRef = useRef()
  let weekday = dayjs(uiState.date).weekday()
  const totalHours = lastHour + 1 - firstHour
  const appointmentElements = positionAppointments(true)

  useEffect(() => {
    positionAppointments()
    return () => {
      positionAppointments()
    };
  }, [appointments]);

  function positionAppointments(withElements) {
    const elements = appointments.map((appointment, i) => {
      if (withElements) {
        return <ScheduleRowAppointment
          key={i}
          ui={uiState}
          top={appointmentPosition[appointment.id]?.top}
          left={appointmentPosition[appointment.id]?.left}
          verticalPosition={appointmentPosition[appointment.id]?.verticalPosition}
          rowPosition={rowPosition}
          appointment={appointment}
          selectAppointment={selectAppointment}
          patientList={patientList}
          firstHour={firstHour}
        />
      }

      return null
    })

    const rowsCount = rowHeights[(doctor && doctor.id) || appointmentType]?.rowsCount ?? 0

    if (rowRef.current) {
      const minHeight = Math.max(120, ((rowsCount + 1) * 60))
      const minWidth = isMobile ? (rowsCount + 2) * 136 : (lastHour + 1 - firstHour) * 280 + 20

      rowRef.current.style.minHeight = `${minHeight}px`
      rowRef.current.style.minWidth = `${minWidth}px`
    }

    return elements
  }

  return (
    <div ref={rowRef} className={clsx(classes.row, {
      [classes.cancelled]: appointmentType === 'cancelled',
      [classes.beautyTreatment]: weekday !== 0 && appointmentType === 'beautyTreatment',
    })}>
      {!isMobile && <div
        className={classes.currentTime}
        style={{
          left: currentTimePosition,
          display: currentTimePosition ? 'block' : 'none'
        }}
      />}
      <div className={classes.hourSlots}>
        {doctorShifts && doctorShifts.map((shift, i) =>
          <div
            className={classes.shift}
            key={i}
            style={{
              top: `${isMobile
                ? calculatefunc.calculateStartTop(shift.startHour, shift.startMinute)
                : 0}px`,
              left: `${isMobile
                ? '0'
                : calculatefunc.calculateStartLeft(shift.startHour, shift.startMinute)}px`,
              width: `${isMobile
                ? '100%'
                : (calculatefunc.calculateStartLeft(shift.endHour, shift.endMinute) - calculatefunc.calculateStartLeft(shift.startHour, shift.startMinute))}px`,
              height: `${isMobile
                ? (calculatefunc.calculateStartTop(shift.endHour, shift.endMinute) - calculatefunc.calculateStartTop(shift.startHour, shift.startMinute))
                : '100%'}px`
            }}
          />
        )}

        {doctorLeaves && doctorLeaves.map((leave, i) =>
          <div
            className={classes.leaves}
            key={i}
            style={{
              top: `${isMobile
                ? calculatefunc.calculateStartTop(leave.startHour, leave.startMinute)
                : 0}px`,
              left: `${isMobile
                ? '0'
                : calculatefunc.calculateStartLeft(leave.startHour, leave.startMinute)}px`,
              width: `${isMobile
                ? '100%'
                : (calculatefunc.calculateStartLeft(leave.endHour, leave.endMinute) - calculatefunc.calculateStartLeft(leave.startHour, leave.startMinute))}px`,
              height: `${isMobile
                ? (calculatefunc.calculateStartTop(leave.endHour, leave.endMinute) - calculatefunc.calculateStartTop(leave.startHour, leave.startMinute))
                : '100%'}px`
            }}
          />
        )}

        {Array.from(Array(totalHours)).map((o, i) => {
          let style = {}
          if (
            uiState.showClickMenu &&
            uiState.clickMenuType === 'hour' &&
            uiState.clickMenuData &&
            uiState.clickMenuData.hour === (firstHour + i) &&
            uiState.clickMenuData.appointmentType === appointmentType &&
            (!uiState.clickMenuData.doctor || uiState.clickMenuData.doctor.id === doctor.id)
          ) {
            style.border = '2px solid #ff0039'
            style.background = 'rgba(0, 0, 0, 0.02)'
          }
          return <div
            onClick={(e) => selectHour(e, firstHour + i, appointmentType, doctor)}
            style={style}
            className={classes.hourSlot} key={i}
          />
        })}
      </div>
      {comments.map((comment, i) =>
        <div
          key={i}
          className={classes.comment}
          onClick={(e) => selectComment(e, comment)}
          style={{
            top: `${isMobile
              ? calculatefunc.calculateStartTop(comment.startHour, comment.startMinute)
              : 0}px`,
            left: `${isMobile
              ? '0'
              : calculatefunc.calculateStartLeft(comment.startHour, comment.startMinute)}px`,
            width:
              isMobile
                ? '100%'
                : `${(calculatefunc.calculateStartLeft(comment.endHour, comment.endMinute) - calculatefunc.calculateStartLeft(comment.startHour, comment.startMinute))}px`,
            height:
              isMobile
                ? `${(calculatefunc.calculateStartTop(comment.endHour, comment.endMinute) - calculatefunc.calculateStartTop(comment.startHour, comment.startMinute))}px`
                : '100%'
          }}
        />
      )}
      {appointmentElements}
    </div>
  );
}

ScheduleRow.propTypes = {
  appointments: PropTypes.arrayOf(PropTypes.object.isRequired),
  appointmentType: PropTypes.string,
  doctor: PropTypes.object,
  doctorShifts: PropTypes.arrayOf(PropTypes.object.isRequired),
  doctorLeaves: PropTypes.arrayOf(PropTypes.object.isRequired),
  firstHour: PropTypes.number,
  lastHour: PropTypes.number,
  rowPosition: PropTypes.number,
  selectHour: PropTypes.func,
  selectAppointment: PropTypes.func,
  selectComment: PropTypes.func,
  patientList: PropTypes.object.isRequired,
  currentTimePosition: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  currentTimeDisplay: PropTypes.string,
  rowHeights: PropTypes.object.isRequired,
  appointmentPosition: PropTypes.object.isRequired,
  calculatefunc: PropTypes.objectOf(PropTypes.func.isRequired),
  comments: PropTypes.arrayOf(PropTypes.object.isRequired),
  isMobile: PropTypes.bool.isRequired
};

export default ScheduleRow;
