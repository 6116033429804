import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';

import ContextStore from '../../modules/context';
import { getComparator, stableSort } from '../../modules/sort';
import DatePickerField from '../../components/DatePickerField'
import SimpleTableToolbar from '../../components/SimpleTableToolbar'
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { formatName } from '../../modules/uitls';

function PersonalBonus({ currentUser, patientMapping }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const [monthTime, setMonthTime] = useState(dayjs().format('YYYY-MM'));
  const [currentData, setCurrentData] = useState(null)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  let totalTable = []

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.reports.personalBonus' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  useEffect(() => {
    (firebase.functions().httpsCallable('calculatePersonalBonus'))({ monthTime: monthTime, currentUserId: currentUser.key }).then(res => {
      setCurrentData(res.data.data)
    }).catch(ex => {
      console.log(ex)
    })

    return () => { }
  }, [monthTime, currentUser]);

  if (!currentData) {
    return (
      <Grid container spacing={1} sx={{ padding: '0 10px 40px 10px', position: 'fixed', bottom: 0 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Backdrop open />
          <LinearProgress color="primary" />
        </Grid>
      </Grid>
    );
  }

  function exportProfile() {
    totalTable = []
    const rows = []
    const purchaseRows = PurchaseHeaderCells.reduce((acc, cur) => {
      acc.push(cur.text)
      return acc
    }, [])
    const kardexRows = kardexHeaderCells.reduce((acc, cur) => {
      acc.push(cur.text)
      return acc
    }, [])

    rows.push(purchaseRows.join(','))

    const _purchaseData = purchaseData.map(p => formatData(p))
    for (const purchase of _purchaseData) {
      rows.push([
        purchase.purchaseOrderDate,
        purchase.sn,
        purchase.patientName,
        purchase.productName,
        purchase.amount,
        purchase.purchasePrice,
        purchase.typeA,
        purchase.typeB,
        purchase.typeC,
        purchase.commission
      ])
    }

    rows.push([' '])
    rows.push(kardexRows.join(','))

    const _kardexData = kardexData.map(p => formatData(p))
    for (const kardex of _kardexData) {
      rows.push([
        kardex.kardexDate,
        kardex.kardexId,
        kardex.patientName,
        kardex.productName,
        kardex.amount,
        kardex.operationBonus,
      ])
    }

    const totalHeaderRows = ['typeA', 'typeB', 'typeC', 'commission', 'operationBonus', 'allTotal'].reduce((acc, cur) => {
      acc.push(formatMessage({ id: `report.personalBonus.total.${cur}` }))
      return acc
    }, [])

    rows.push([' '])
    rows.push(['', '', '', ''].concat(totalHeaderRows).join(','))

    rows.push([
      '', '', '', '',
      totalCount('a'),
      totalCount('b'),
      totalCount('c'),
      totalCount('com'),
      totalCount('ob'),
      totalCount('ob') + totalCount('total'),
    ])

    try {
      const content = rows.join('\n')
      const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const a = document.createElement('a')
      a.href = csvUrl
      a.target = '_blank'
      a.download = `${monthTime}月-${formatMessage({ id: 'sideMenu.reports.personalBonus' })}_.csv`
      a.click()
    } catch (error) {
      console.error(error) // eslint-disable-line
      alert('An error occurred. Please refresh and try again.')
    }
  }

  const kardexHeaderCells = [
    { text: 'kardexDate' },
    { text: 'kardexId' },
    { text: 'patientName' },
    { text: 'productName' },
    { text: 'amount' },
    { text: 'operationBonus', align: 'right' },
  ].map(c => { c.text = formatMessage({ id: `report.personalBonus.table.${c.text}` }); return c })

  const kardexRowCells = [
    { field: 'kardexDate' },
    { field: 'kardexId' },
    { field: 'patientName' },
    { field: 'productName' },
    { field: 'amount' },
    { field: 'operationBonus', align: 'right' },
  ]

  const PurchaseHeaderCells = [
    { text: 'purchaseOrderDate' },
    { text: 'sn' },
    { text: 'patientName' },
    { text: 'productName' },
    { text: 'amount' },
    { text: 'purchasePrice' },
    { text: 'typeA', align: 'right' },
    { text: 'typeB', align: 'right' },
    { text: 'typeC', align: 'right' },
    { text: 'commission', align: 'right' },
  ].map(c => { c.text = formatMessage({ id: `report.personalBonus.table.${c.text}` }); return c })

  const PurchaseRowCells = [
    { field: 'purchaseOrderDate' },
    { field: 'sn' },
    { field: 'patientName' },
    { field: 'productName' },
    { field: 'amount' },
    { field: 'purchasePrice' },
    { field: 'typeA', align: 'right' },
    { field: 'typeB', align: 'right' },
    { field: 'typeC', align: 'right' },
    { field: 'commission', align: 'right' },
  ]

  const formatData = (data) => {
    const newData = { ...data }
    newData.patientName = formatName(patientMapping[newData.patient]?.name ?? '')
    newData.purchasePrice = Number(newData.purchasePrice).toFixed(2).replace(/[.,]00$/, '')

    if (['員工', '員工眷屬'].includes(patientMapping[newData.patient]?.type)) {
      delete newData.typeA
      delete newData.typeB
      delete newData.typeC
      delete newData.operationBonus
      delete newData.commission
    }

    totalTable.push(newData)
    return newData
  }

  function totalCount(type) {
    if (type === 'a') {
      return totalTable.reduce((acc, cur) => {
        if (cur.typeA) {
          acc += cur.typeA
        }

        return acc
      }, 0)
    } else if (type === 'b') {
      return totalTable.reduce((acc, cur) => {
        if (cur.typeB) {
          acc += cur.typeB
        }

        return acc
      }, 0)
    } else if (type === 'c') {
      return totalTable.reduce((acc, cur) => {
        if (cur.typeC) {
          acc += cur.typeC
        }

        return acc
      }, 0)
    } else if (type === 'ob') {
      return totalTable.reduce((acc, cur) => {
        if (cur.operationBonus) {
          acc += Number(cur.operationBonus)
        }

        return acc
      }, 0)
    } else if (type === 'com') {
      return totalTable.reduce((acc, cur) => {
        if (cur.commission) {
          acc += Number(cur.commission)
        }

        return acc
      }, 0)
    } else if (type === 'total') {
      return totalTable.reduce((acc, cur) => {
        if (cur.typeA) {
          acc += cur.typeA
        }
        if (cur.typeB) {
          acc += cur.typeB
        }
        if (cur.typeC) {
          acc += cur.typeC
        }
        if (cur.commission) {
          acc += Number(cur.commission)
        }

        return acc
      }, 0)
    }
  }

  const kardexData = currentData.kardexData
  const purchaseData = currentData.purchaseData

  return (
    <Grid container spacing={0} sx={{ marginTop: '15px', padding: '20px', fontFamily: 'Roboto, sans-serif' }}>
      <Grid item xs={12} sm={12} md={12}>
        <SimpleTableToolbar
          title={'report.personalBonus.title'}
          toolbox={<>
            <IconButton
              sx={{ fontSize: '14px', alignSelf: 'center', color: '#666666' }}
              onClick={() => exportProfile()}
              size="large">
              <GetAppIcon></GetAppIcon>
              {formatMessage({ id: 'button.export' })}
            </IconButton>
            <DatePickerField
              required
              label={formatMessage({ id: 'report.month' })}
              value={dayjs(monthTime)}
              inputFormat="YYYY-MMM"
              onChange={date => setMonthTime(dayjs(date).format('YYYY-MM'))}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
          </>}
        />
        <TableContainer component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={PurchaseHeaderCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={purchaseData.length}
            />
            <TableBody>
              {stableSort(purchaseData.map(p => formatData(p)), getComparator(order, orderBy)).map((data, index) => (
                <EnhancedTableRow
                  key={`${data.id}-${index}`}
                  rowCells={PurchaseRowCells}
                  cellData={data}
                />
              ))}
              <TableRow>
                <TableCell rowSpan={5} colSpan={8} />
                <TableCell >{formatMessage({ id: 'report.personalBonus.total.typeA' })}</TableCell>
                <TableCell align='right'>{totalCount('a')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell >{formatMessage({ id: 'report.personalBonus.total.typeB' })}</TableCell>
                <TableCell align='right'>{totalCount('b')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell >{formatMessage({ id: 'report.personalBonus.total.typeC' })}</TableCell>
                <TableCell align='right'>{totalCount('c')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell >{formatMessage({ id: 'report.personalBonus.total.commission' })}</TableCell>
                <TableCell align='right'>{totalCount('com')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell >{formatMessage({ id: 'report.personalBonus.total.root' })}</TableCell>
                <TableCell align='right'>{totalCount('total')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} sx={{ marginTop: '15px' }}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={kardexHeaderCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={kardexData.length}
            />
            <TableBody>
              {stableSort(kardexData.map(k => formatData(k)), getComparator(order, orderBy)).map((data, index) => (
                <EnhancedTableRow
                  key={`${data.id}-${index}`}
                  rowCells={kardexRowCells}
                  cellData={data}
                />
              ))}
              <TableRow>
                <TableCell rowSpan={2} colSpan={4} />
                <TableCell >{formatMessage({ id: 'report.personalBonus.total.operationBonus' })}</TableCell>
                <TableCell align='right' >{totalCount('ob')}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell sx={{ color: 'blue' }}>{formatMessage({ id: 'report.personalBonus.total.allTotal' })}</TableCell>
                <TableCell sx={{ color: 'blue' }} align='right' >{totalCount('ob') + totalCount('total')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

PersonalBonus.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    announcement: PropTypes.number.isRequired
  }),
  patientMapping: PropTypes.object.isRequired
};

export default PersonalBonus;
