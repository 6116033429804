import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFirebase } from 'react-redux-firebase';
import firebase from 'firebase/app';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw'

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CalendarPicker from '@mui/lab/CalendarPicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';

import FilterListIcon from '@mui/icons-material/FilterList';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import AddIcon from '@mui/icons-material/Add';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MenuIcon from '@mui/icons-material/Menu';
import LeaveIcon from '@mui/icons-material/EventNote';
import AnnouncementIcon from '@mui/icons-material/InfoOutlined';
import DDAPPIcon from '@mui/icons-material/PhoneIphone';

import RebornLogo from '../../static/reborn-64x64.png'
import IbeautyLogo from '../../static/ibeauty-white.png'
import SanteaLogo from '../../static/santea-white.png'
import EditUserRights from '../../components/EditUserRights'
import EditApproval from '../../components/EditApproval';
import EditWorkTIme from '../../components/EditWorkTIme';
import { tabletMedia, moibleMedia } from '../../constants/index'
import ContextStore from '../../modules/context';

const icons = process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence' ?
  RebornLogo : process.env.BRANCH_ENV === 'santea' ? SanteaLogo : IbeautyLogo

const drawerWidth = 240;
const drawerRightWidth = 860;

const projectColor = {}
if (process.env.BRANCH_ENV === 'santea') {
  projectColor.backgroundColor = '#26CAD3'
} else if (process.env.BRANCH_ENV === 'lexcellence' || process.env.BRANCH_ENV === 'reborn') {
  projectColor.backgroundColor = '#eeccc4'
} else {
  projectColor.backgroundColor = '#6EDBD5'
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'none',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...projectColor,
  zIndex: 1200,
  ...(open === 'left' && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(open === 'right' && {
    width: `calc(100% - ${drawerRightWidth}px)`,
    marginRight: `${drawerRightWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppointmentDateRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  order: 0,
  [moibleMedia]: {
    order: 2,
    marginLeft: '4px',
    marginTop: '8px'
  }
}));

const AppointmentBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 2,
  alignItems: 'center',
  marginRight: '8px',
  [moibleMedia]: {
    flexDirection: 'column',
    marginBottom: '8px',
  }
}));

const Breadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  '& ol > :not(:last-child)': {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  flexGrow: 1,
}));

const DateButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#3b4863',
  [moibleMedia]: {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: '0.75rem'
  },
  color: '#ffffff',
}));

const ComponentNone = styled('div')(({ theme }) => ({
  [tabletMedia]: {
    display: 'none'
  },
  [moibleMedia]: {
    display: 'none'
  }
}));


const AccountButton = styled('div')(({ theme }) => ({
  marginTop: '5px',
  [tabletMedia]: {
    marginTop: 0
  },
  [moibleMedia]: {
    marginRight: 0
  }
}));

function AppNavBar({ currentUser, userRight, isDrawerOpen, handleDrawerOpen, handleScheduleDrawerOpen }) {
  const history = useHistory();
  const firebaseRedux = useFirebase()
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const filterMenuOpen = Boolean(filterAnchorEl);
  const [dateAnchorEl, setDateAnchorEl] = useState(null)
  const dateMenuOpen = Boolean(dateAnchorEl)
  const [openUserRight, setOpenUserRight] = useState(false)
  const [openApproval, setOpenApproval] = useState(false)
  const [openWorkTime, setOpenWorkTime] = useState(false)

  const [leaves, setLeaves] = useState([])
  const [overtimes, setOvertimes] = useState([])
  const [punchClockRevise, setPunchClockRevise] = useState([])
  const [normalDDapp, setNormalDDapp] = useState([])
  const [updateDDapp, setUpdateDDapp] = useState([])
  const { breadcrumbs, uiState, setUiState } = useContext(ContextStore)
  const ddappCount = updateDDapp.length + normalDDapp.length
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  useEffect(() => {
    if (['lexcellence', 'ibeauty'].includes(process.env.BRANCH_ENV)) {
      const unsubscribe = firebase.firestore().collection('appointmentFromDD').where('kardex', '==', '').where('status', '!=', 'canceled').onSnapshot(snapshot => {
        const snapshots = []
        snapshot.forEach(doc => {
          snapshots.push({ id: doc.id, ...doc.data() });
        })
        setNormalDDapp(snapshots)
      });
      return () => unsubscribe()
    }
  }, []);

  useEffect(() => {
    if (['lexcellence', 'ibeauty'].includes(process.env.BRANCH_ENV)) {
      const unsubscribe = firebase.firestore().collection('appointmentFromDD').where('updated', '==', true).onSnapshot(snapshot => {
        const snapshots = []
        snapshot.forEach(doc => {
          snapshots.push({ id: doc.id, ...doc.data() });
        })

        setUpdateDDapp(snapshots)
      });
      return () => unsubscribe()
    }
  }, []);

  useEffect(() => {
    const unsubscribe = userRight['leaveOvertime-view'] ? firebase.firestore().collection('leaves').where('status', '==', 'pending').onSnapshot(snapshot => {
      const leaves = []
      snapshot.forEach(doc => {
        leaves.push({ ...doc.data(), id: doc.id })
      });
      setLeaves(leaves)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [history.location.pathname]);

  useEffect(() => {
    const unsubscribe = userRight['leaveOvertime-view'] ? firebase.firestore().collection('overtimes').where('status', '==', 'pending').onSnapshot(snapshot => {
      const overtimes = []
      snapshot.forEach(doc => {
        overtimes.push({ ...doc.data(), id: doc.id })
      });
      setOvertimes(overtimes)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [history.location.pathname]);

  useEffect(() => {
    const unsubscribe = userRight['leaveOvertime-view'] ? firebase.firestore().collection('punchClockRevise').where('status', '==', 'pending').onSnapshot(snapshot => {
      const punchClockRevises = []
      snapshot.forEach(doc => {
        punchClockRevises.push({ ...doc.data(), id: doc.id })
      });
      setPunchClockRevise(punchClockRevises)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [history.location.pathname]);

  const leaveNotifications = leaves.filter(leave => {
    const currentStep = leave.history.length > 0 ? leave.history[leave.history.length - 1].step : 0
    return leave.steps[currentStep].users.includes(currentUser.key)
  })

  const overtimeNotifications = overtimes.filter(overtime => {
    const currentStep = overtime.history.length > 0 ? overtime.history[overtime.history.length - 1].step : 0
    return overtime.steps[currentStep].users.includes(currentUser.key)
  })

  const punchClockReviseNotifications = punchClockRevise.filter(revise => {
    const currentStep = revise.history.length > 0 ? revise.history[revise.history.length - 1].step : 0
    return revise.steps[currentStep].users.includes(currentUser.key)
  })

  const totalNotifications = leaveNotifications.length + overtimeNotifications.length + punchClockReviseNotifications.length

  useEffect(() => {
    if (history.location.pathname !== '/appointments/schedule') {
      setUiState({
        ...uiState,
        showAppointmentSidebar: false,
        selectedAppointment: false,
        selectedAppointmentEl: {},
        clickMenuData: null,
        editAppointment: null,
        showCommentSidebar: false,
        editComment: null
      })
    }
    return () => {
    };
  }, [history.location.pathname]);

  const handleFilterMenu = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenUserRight(false);
    setOpenApproval(false)
    setOpenWorkTime(false)
  };

  const openUserRightDialog = () => {
    setAnchorEl(null);
    setOpenUserRight(true)
  }

  const openApprovalDialog = () => {
    setAnchorEl(null);
    setOpenApproval(true)
  }

  const openWorkTimeDialog = () => {
    setAnchorEl(null);
    setOpenWorkTime(true)
  }

  const handleDateClose = () => {
    setDateAnchorEl(null);
  };

  const fiterCheck = (field, check) => {
    setUiState({
      ...uiState,
      [field]: check
    })
  }

  const changeDate = (event) => {
    let newDate = ''
    if (event === 'before') {
      newDate = dayjs(uiState.date).subtract(1, 'day')
    } else if (event === 'after') {
      newDate = dayjs(uiState.date).add(1, 'day')
    } else {
      newDate = dayjs(event)
      handleDateClose()
    }

    setUiState({
      ...uiState,
      date: newDate
    })
  }

  function DatePickerButton() {
    return (
      <Menu
        id="menu-date"
        sx={{ zIndex: 2100 }}
        anchorEl={dateAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={dateMenuOpen}
        onClose={handleDateClose}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={'zh-tw'}>
          <CalendarPicker
            date={uiState.date}
            onChange={(event) => changeDate(event)}
          />
        </LocalizationProvider>
      </Menu>
    )
  }

  const appointmentsPage = [
    '/appointments/schedule',
    '/appointments/editList',
    '/appointments/list',
  ];

  return (
    <AppBar position="fixed" open={isDrawerOpen}>
      {openUserRight && <EditUserRights currentUser={currentUser} handleClose={handleClose} />}
      {openApproval && <EditApproval currentUser={currentUser} handleClose={handleClose} />}
      {openWorkTime && <EditWorkTIme currentUser={currentUser} handleClose={handleClose} />}
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(isDrawerOpen === 'left' && { display: 'none' }) }}
          size="large">
          <MenuIcon />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={() => history.push('/')}
          edge="start"
          sx={{
            marginRight: '16px',
            [moibleMedia]: {
              display: 'none'
            }
          }}
          size="large">
          <img alt='' style={{ height: '30px', width: '30px' }} src={icons}></img>
        </IconButton>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs.map((b, index) => b.link ?
            (<Link key={`link-${index}`} sx={{ cursor: 'pointer', textDecoration: 'none' }} href={b.link} onClick={e => { e.preventDefault(); history.push(b.link); }}>
              <Typography sx={{ color: '#FFF' }} variant="h6">{b.text}</Typography>
            </Link>) :
            (<Typography key={`link-${index}`} sx={{ color: '#FFF' }} variant="h6">{b.text}</Typography>))
          }
        </Breadcrumbs>
        <AppointmentBody>
          {appointmentsPage.includes(history.location.pathname) && <AppointmentDateRow>
            <DatePickerButton />
            <ButtonGroup size="small" variant="contained" color="primary" aria-label="contained primary button group">
              <DateButton onClick={() => changeDate('before')}><ChevronLeftIcon /></DateButton>
              <DateButton onClick={(event) => setDateAnchorEl(event.currentTarget)}>{dayjs(uiState.date).locale('zh-tw').format('M/D (ddd) YYYY')}</DateButton>
              <DateButton onClick={() => changeDate('after')}><ChevronRightIcon /></DateButton>
              <DateButton onClick={() => setUiState({ ...uiState, date: dayjs() })}><FormattedMessage id="header.appointment.today" /></DateButton>
            </ButtonGroup>
          </AppointmentDateRow>}
          {history.location.pathname === '/appointments/schedule' && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', order: 1 }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleFilterMenu}
              color="inherit"
              size="large">
              <FilterListIcon />
            </IconButton>
            <Menu
              id="menu-filter"
              sx={{ zIndex: 2100 }}
              anchorEl={filterAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={filterMenuOpen}
              onClose={handleFilterClose}
            >
              <List>
                <ListItem dense>
                  <FormControlLabel
                    control={<Checkbox checked={uiState.noShifts} onChange={e => fiterCheck('noShifts', e.target.checked)} name="noShifts" />}
                    label={<FormattedMessage id={'header.appointment.filter.noShifts'} />}
                  />
                </ListItem>
                <ListItem dense>
                  <FormControlLabel
                    control={<Checkbox checked={uiState.showPatientType} onChange={e => fiterCheck('showPatientType', e.target.checked)} name="showPatientType" />}
                    label={<FormattedMessage id={'header.appointment.filter.showPatientType'} />}
                  />
                </ListItem>
                <ListItem dense>
                  <FormControlLabel
                    control={<Checkbox checked={uiState.showRoom} onChange={e => fiterCheck('showRoom', e.target.checked)} name="showRoom" />}
                    label={<FormattedMessage id={'header.appointment.filter.showRoom'} />}
                  />
                </ListItem>
                <ListItem dense>
                  <FormControlLabel
                    control={<Checkbox checked={uiState.showNurse} onChange={e => fiterCheck('showNurse', e.target.checked)} name="showNurse" />}
                    label={<FormattedMessage id={'header.appointment.filter.showNurse'} />}
                  />
                </ListItem>
                <ListItem dense>
                  <FormControlLabel
                    control={<Checkbox checked={uiState.showSalesRep} onChange={e => fiterCheck('showSalesRep', e.target.checked)} name="showSalesRep" />}
                    label={<FormattedMessage id={'header.appointment.filter.showSalesRep'} />}
                  />
                </ListItem>
              </List>
            </Menu>
            <Button
              sx={{ color: '#fff' }}
              onClick={() => setUiState({
                ...uiState,
                clickMenuData: null,
                showAppointmentSidebar: true,
                showCommentSidebar: false,
              })}
            >
              <AddIcon />
              <ComponentNone>
                <FormattedMessage id={'header.appointment.NewReservation'} />
              </ComponentNone>
            </Button>
            <Button
              sx={{ color: '#fff' }}
              onClick={() => setUiState({
                ...uiState,
                clickMenuData: null,
                showCommentSidebar: true,
                showAppointmentSidebar: false
              })}
            >
              <InsertCommentIcon />
              <ComponentNone>
                <FormattedMessage id={'header.appointment.NewComment'} />
              </ComponentNone>
            </Button>
            {isDrawerOpen !== 'right' && <Button sx={{ color: '#fff' }} onClick={() => handleScheduleDrawerOpen()}>
              <ViewWeekIcon />
              <ComponentNone>
                <FormattedMessage id={'header.appointment.doctorSchedule'} />
              </ComponentNone>
            </Button>}
          </div>}
        </AppointmentBody>
        <AccountButton>
          {!appointmentsPage.includes(history.location.pathname) && <>
            {currentUser.announcement > 0 && <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              color="error"
              badgeContent={currentUser.announcement}
              sx={{ mr: isMobile ? '5px' : '10px', mt: '5px' }}
            >
              {!isMobile && !isTablet ?
                <Button
                  sx={{ color: '#fff', textTransform: 'none' }}
                  size="small"
                  onClick={() => history.push('/announcement')}
                >
                  <FormattedMessage id={'header.notice.announcement'} onClick={() => history.push('/announcement')} />
                </Button> :
                <IconButton
                  sx={{ color: '#fff', textTransform: 'none' }}
                  size='small'
                  onClick={() => history.push('/announcement')}
                >
                  <AnnouncementIcon />
                </IconButton>}
            </Badge >}
            {totalNotifications > 0 && <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              color="error"
              badgeContent={totalNotifications}
              sx={{ mr: isMobile ? '5px' : '10px', mt: '5px' }}
            >
              {!isMobile && !isTablet ?
                <Button
                  sx={{ color: '#fff', textTransform: 'none' }}
                  size='small'
                  onClick={() => history.push('/leaveOvertimeWork/leaves/review')}
                >
                  <FormattedMessage id={'header.notice.reviewLeaveOvertime'} />
                </Button> :
                <IconButton
                  sx={{ color: '#fff', textTransform: 'none' }}
                  size='small'
                  onClick={() => history.push('/leaveOvertimeWork/leaves/review')}
                >
                  <LeaveIcon />
                </IconButton>}
            </Badge >}
            {ddappCount > 0 && <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              color="error"
              badgeContent={ddappCount}
              sx={{ mr: isMobile ? '5px' : '10px', mt: '5px' }}
            >
              {!isMobile && !isTablet ?
                <Button
                  sx={{ color: '#fff', textTransform: 'none' }}
                  size='small'
                  onClick={() => history.push('/ddapp')}
                >
                  <FormattedMessage id={'header.notice.ddapp'} />
                </Button> :
                <IconButton
                  sx={{ color: '#fff', textTransform: 'none' }}
                  size='small'
                  onClick={() => history.push('/ddapp')}
                >
                  <DDAPPIcon />
                </IconButton>}
            </Badge >}
          </>}
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            size="large">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-logout"
            sx={{ zIndex: 2100 }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={menuOpen}
            onClose={handleClose}
          >
            <MenuItem disabled>
              {currentUser.displayName}
            </MenuItem>
            <MenuItem onClick={() => { firebaseRedux.logout() }}>
              <FormattedMessage id="header.menu.logout" />
            </MenuItem>
            {userRight['editUserRight'] && <MenuItem onClick={() => openUserRightDialog()}>
              <FormattedMessage id="header.menu.editUserRight" />
            </MenuItem>}
            {userRight['editUserRight'] && <MenuItem onClick={() => openApprovalDialog()}>
              <FormattedMessage id="header.menu.editApproval" />
            </MenuItem>}
            {userRight['editUserRight'] && <MenuItem onClick={() => openWorkTimeDialog()}>
              <FormattedMessage id="header.menu.editWorkTime" />
            </MenuItem>}
          </Menu>
        </AccountButton>
      </Toolbar >
    </AppBar >
  );
}

AppNavBar.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    purchaseOrderSource: PropTypes.arrayOf(PropTypes.string),
    announcement: PropTypes.number.isRequired
  }),
  handleDrawerOpen: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.string.isRequired,
  handleScheduleDrawerOpen: PropTypes.func.isRequired,
  userRight: PropTypes.object.isRequired
};

export default AppNavBar;
