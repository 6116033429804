import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function MedicalHistory({ medicalHistory }) {
  const { formatMessage } = useIntl()

  if (!medicalHistory) {
    return ('No Information...')
  }
  const _fields = [
    { name: 'disease', roots: true, sm: 12, md: 12, order: 0 },
    { name: 'healthSupplementInUse', roots: true, sm: 12, md: 12, order: 1 },
    // { name: 'surgeryHistory', text: formatMessage({ id: `patient.medicalHistory.surgeryHistory` }), type: '-', order: 4 },
    { name: 'surgery', sm: 12, md: 4, order: 5 },
    // { name: 'medicationInUse', text: formatMessage({ id: `patient.medicalHistory.medicationInUse` }), type: '-', order: 6 },
    { name: 'medicationInUseName', sm: 12, md: 4, order: 7 },
    { name: 'allergyDrug', text: formatMessage({ id: 'patient.medicalHistory.allergyDrug' }), type: '-', order: 8 },
    { name: 'drugName', sm: 12, md: 6, order: 9 },
    { name: 'allergicSymptoms', sm: 12, md: 6, order: 10 },
  ]

  if (medicalHistory.pregnancy) {
    // _fields.push({ name: 'pregnancy', text: formatMessage({ id: `patient.medicalHistory.pregnancy` }), type: '-', order: 2 })
    _fields.push({ name: 'numberWeeks', sm: 12, md: 4, order: 3 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field, value, medical) {
    let newValue = medicalHistory[field.name] ? medicalHistory[field.name] : ''
    let labelText = ''

    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    }

    if (field.roots) {
      labelText = formatMessage({ id: `patient.medicalHistory.${field.name}.roots` })
    } else {
      labelText = formatMessage({ id: `patient.medicalHistory.${field.name}` })
    }

    if (['disease', 'healthSupplementInUse'].includes(field.name)) {
      let tableMapping = undefined
      if (field.name === 'disease') {
        tableMapping = medicalHistory.othersDisease
      } else if (field.name === 'healthSupplementInUse') {
        tableMapping = medicalHistory.otherHealthSupplement
      }

      if (value) {
        newValue = Object.keys(value).filter(key => value[key]).map(s => {
          return formatMessage({ id: `patient.medicalHistory.${field.name}.${s}` })
        }).join(', ')

        if (tableMapping) {
          let keys = Object.keys(tableMapping)
          let detail = ''
          if (keys.length > 0) {
            detail = tableMapping[keys[0]].detail || ''
          }

          newValue = newValue + `, ${formatMessage({ id: `patient.medicalHistory.${field.name}.others` })} : ${detail}`
        }
      }
    } else if (['drugName', 'allergicSymptoms', 'surgery', 'medicationInUseName'].includes(field.name)) {
      let tableMapping = {}
      if (field.name === 'surgery') {
        tableMapping = medicalHistory.surgery
      } else if (field.name === 'medicationInUseName') {
        tableMapping = medicalHistory.medicationInUse
      } else {
        tableMapping = medicalHistory.allergyDrug
      }

      if (tableMapping) {
        let keys = Object.keys(tableMapping)
        let newMapping = {}

        if (keys.length > 0) {
          newMapping = tableMapping[keys[0]]
        }

        if (field.name === 'drugName' || field.name === 'medicationInUseName') {
          newValue = newMapping.name || ''
        } else if (field.name === 'allergicSymptoms') {
          newValue = newMapping.symptom || ''
        } else if (field.name === 'surgery') {
          newValue = newMapping.detail || ''
        }
      }
    } else if (['numberWeeks'].includes(field.name)) {
      newValue = medicalHistory.pregnancy
    } else {
      newValue = value[field.name]
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        fullWidth
        size="small"
        variant="standard"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field, medicalHistory[field.name], medicalHistory))}
      </Grid>
    </div>
  );
}

MedicalHistory.propTypes = {
  medicalHistory: PropTypes.object
};

export default MedicalHistory;
