import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import { createUseStyles } from 'react-jss'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ScheduleTabContainer from '../../containers/TabContainer/ScheduleTabContainer'
import TabContainer from '../../containers/TabContainer/TabContainer'
import ScheduleTable from './ScheduleTable'
import { tabletMedia } from '../../constants/index'

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    backgroundColor: '#fff',
    height: '100%',
    [tabletMedia]: {
      display: 'block',
      maxHeight: '68px',
      width: '100vw',
      padding: '10px',
    }
  },
});

function ScheduleSidebar({ currentUser, userRight }) {
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const history = useHistory()
  const [departments, setDepartments] = useState([])
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const tabName = history.location.pathname
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  departments.sort((a, b) => {
    return a.sort - b.sort
  });

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('departments').onSnapshot(snapshot => {
      const data = []
      snapshot.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      });
      setDepartments(data)
    }, err => { })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const pathname = history.location.pathname
    departments.forEach(user => {
      if (pathname === `/schedule/${user.id}` && activeTabIndex !== user.sort) {
        setActiveTabIndex(user.sort)
      }
    })
    return () => {
    };
  }, [history.location.pathname]);

  if (departments.length === 0) {
    return ('Loading...')
  }

  let defaultSelect = Math.max(departments.findIndex(cur => {
    return tabName === `/schedule/${cur.id}`
  }), 0)

  const onTabSelected = (tabIndex) => {
    departments.forEach(user => {
      if (tabIndex === user.sort) {
        history.push(`/schedule/${user.id}`);
      }
    })
  };

  const tabs = [];

  departments.forEach((user, idx) => {
    tabs.push({
      label: formatMessage({ id: process.env.BRANCH_ENV === 'santea' ? `tab.schedule.santea.${user.id}` : `tab.schedule.${user.id}` }),
      component: <ScheduleTable currentUser={currentUser} staffType={user.id} workTimes={user.workTime} userRight={userRight} isTablet={isTablet} />
    })
  })

  if (isTablet) {
    return (
      <div className={classes.root}>
        <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <ScheduleTabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="vertical" />
      </div>
    );
  }
}

ScheduleSidebar.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  userRight: PropTypes.object.isRequired,
};

export default ScheduleSidebar;
