class BaseModel {
  constructor(record) {
    (this.constructor.EXPOSE_ATTRIBUTES || Object.keys(record)).forEach(attr => { this[attr] = record[attr] });
    this['key'] = record['key'];
  }
}

class UserModel extends BaseModel {
  static TABLE = 'users';
  static EXPOSE_ATTRIBUTES = [
    'avatarUrl', 'email', 'displayName', 'department', 'roleLeadType',
    'isManagement', 'isAdmin', 'isCEO', 'active', 'endedOn', 'announcement', 'gender'
  ];
}

const getCurrentUser = (auth) => {
  const currentUser = new UserModel(auth);
  currentUser.key = currentUser.key || auth.uid;
  return currentUser;
};

const getUserList = node => node ? node.map(i => { i.value.key = i.key; return new UserModel(i.value)}) : [];

export {
  getCurrentUser,
  getUserList,
};
