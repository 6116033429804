import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { objectToArray } from '../../modules/data'
import { getComparator, stableSort } from '../../modules/sort';
import FabComponent from '../../components/FabComponent';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import ReviewPatient from './ReviewPatient';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import ExpandButton from '../../components/ExpandButton';
import SearchBox from '../../components/SearchBox';
import NewPatientDialog from '../../components/NewPatientDialog';
import { formatName } from '../../modules/uitls';
import ContextStore from '../../modules/context';

function Patient({ userRight }) {
  const { setBreadcrumbs } = useContext(ContextStore)
  const { formatMessage } = useIntl()
  const history = useHistory();
  const [patients, setPatients] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [expand, setExpand] = useState(true);
  const userMapping = useSelector(state => state.firebase.data.users);
  const [openNewPatientDialog, setOpenNewPatientDialog] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [blackFilter, setBlackFilter] = useState('')
  const [vipFilter, setVipFilter] = useState('')

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.patients' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        if (process.env.BRANCH_ENV === 'lexcellence') {
          let arr = objectToArray(snapshot.val()).map(p => { p.name = formatName(p.name); return p })
          // arr = arr.concat(arr.map(p => ({ ...p, id: `${p.id}a` })))
          // arr = arr.concat(arr.map(p => ({ ...p, id: `${p.id}b` })))
          // arr = arr.concat(arr.map(p => ({ ...p, id: `${p.id}c` })))
          // arr = arr.concat(arr.map(p => ({ ...p, id: `${p.id}d` })))
          // arr = arr.concat(arr.map(p => ({ ...p, id: `${p.id}e` })))
          // arr = arr.concat(arr.map(p => ({ ...p, id: `${p.id}f` })))
          // arr = arr.concat(arr.map(p => ({ ...p, id: `${p.id}g` })))
          // arr = arr.concat(arr.map(p => ({ ...p, id: `${p.id}h` })))
          setPatients(arr)
        } else {
          setPatients(objectToArray(snapshot.val()).map(p => { p.name = formatName(p.name); return p }))
        }
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);


  const headerCells = [
    { text: 'name', sort: 'name' },
    { text: 'birthDate', sort: 'birthDate' },
    { text: 'phone', sort: 'phone' },
    { text: 'identityCardNumber', sort: 'identityCardNumber' },
    { text: 'salesRep', sort: 'salesRep' },
    { text: 'lastAppointmentDateTime', sort: 'lastAppointmentDateTime' },
  ].map(c => { c.text = formatMessage({ id: `patient.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'name' },
    { field: 'birthDate' },
    { field: 'phone' },
    { field: 'identityCardNumber' },
    { field: 'salesRepName' },
    { field: 'lastAppointmentDate' },
  ]

  const filterItems = [
    { name: 'name' },
    { name: 'birthDate', },
    { name: 'phone' },
    { name: 'salesRep' },
    { name: 'identityCardNumber' },
    { name: 'lastAppointmentDateTime' },
  ].map(i => { i.text = formatMessage({ id: `patient.table.${i.name}` }); return i })

  function filterByText() {
    if (currentFilter.name === 'name') {
      return patients.filter(s => s.name.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'birthDate') {
      return patients.filter(s => s.birthDate && s.birthDate.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'phone') {
      return patients.filter(s => s.phone && s.phone.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'salesRep') {
      return patients.filter(s => userMapping[s.salesRep] && userMapping[s.salesRep].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'lastAppointmentDateTime') {
      return patients.filter(s => s.lastAppointmentDateTime && s.lastAppointmentDateTime.split('_')[0].toLowerCase().toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'identityCardNumber') {
      return patients.filter(s => s.identityCardNumber && s.identityCardNumber.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatData = (patient) => {
    const newData = { ...patient }
    newData.salesRepName = userMapping[newData.salesRep] && userMapping[newData.salesRep].displayName
    newData.lastAppointmentDate = newData.lastAppointmentDateTime ? newData.lastAppointmentDateTime.split('_')[0] : ''

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setPage(0)
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const handleClose = () => {
    setOpenNewPatientDialog(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function PatientFilter() {
    return (
      <Grid container spacing={3} sx={{ maxWidth: '480px' }}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            select
            type="text"
            size="small"
            label={formatMessage({ id: 'patient.filter.blackList.root' })}
            variant="outlined"
            fullWidth
            onChange={e => setBlackFilter(e.target.value)}
            value={blackFilter}
          >
            {['all', 'on', 'off'].map((type, idx) => {
              return <MenuItem key={`${type}-${idx}`} value={type}>{formatMessage({ id: `patient.filter.blackList.${type}` })}</MenuItem>
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            select
            type="text"
            size="small"
            label={formatMessage({ id: 'patient.filter.vip.root' })}
            variant="outlined"
            fullWidth
            onChange={e => setVipFilter(e.target.value)}
            value={vipFilter}
          >
            {['all', 'on', 'off'].map((type, idx) => {
              return <MenuItem key={`${type}-${idx}`} value={type}>{formatMessage({ id: `patient.filter.vip.${type}` })}</MenuItem>
            })}
          </TextField>
        </Grid>
      </Grid>
    );
  }

  function filterBySantea(type, data) {
    let newData = data
    if (type === 'blackList') {
      if (blackFilter === 'on') {
        newData = newData.filter(d => d.blackList)
      } else if (blackFilter === 'off') {
        newData = newData.filter(d => !d.blackList)
      }
    } else {
      if (vipFilter === 'on') {
        newData = newData.filter(d => d.vip)
      } else if (vipFilter === 'off') {
        newData = newData.filter(d => !d.vip)
      }
    }

    return newData
  }

  let filteredPatients = currentFilter && currentFilter.text ? filterByText() : patients
  if (blackFilter !== '') {
    filteredPatients = filterBySantea('blackList', filteredPatients)
  }
  if (vipFilter !== '') {
    filteredPatients = filterBySantea('vip', filteredPatients)
  }

  return (
    <Grid container spacing={1} sx={{ p: '40px 20px' }}>
      {openNewPatientDialog && <NewPatientDialog
        dialogTital={formatMessage({ id: 'patient.dialog.information.add' })}
        handleClose={handleClose}
        patients={patients}
      />}
      {(process.env.BRANCH_ENV !== 'ibeauty' || (process.env.BRANCH_ENV === 'ibeauty' && userRight['patient-add'])) &&
        <FabComponent onClick={() => setOpenNewPatientDialog(true)} />
      }
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {['santea', 'lexcellence'].includes(process.env.BRANCH_ENV) && <PatientFilter />}
          <SimpleTableToolbar
            title={'noMessage'}
            bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
            toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table
            size="small"
            aria-label="collapsible table"
          >
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredPatients.length}
              expandable
              actionButton
            />
            <TableBody>
              {stableSort(filteredPatients.map(r => formatData(r)), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(patient => (
                  <EnhancedTableRow
                    key={patient.id}
                    rowCells={rowCells}
                    cellData={patient}
                    expandable
                    expandContent={<ReviewPatient patientId={patient.id} />}
                    actionIcons={<Tooltip title={formatMessage({ id: 'button.info' })}>
                      <IconButton
                        onClick={() => history.push(`patients/${patient.id}/profile`)}
                        size="large">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ display: 'flex', justifyContent: 'center' }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredPatients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={formatMessage({ id: 'button.perPage' })}
          labelDisplayedRows={({ from, to, count }) => `第${from}至${to}列 (共${count}列)`}
        />
      </Grid>
    </Grid>
  );
}

Patient.propTypes = {
  userRight: PropTypes.object.isRequired,
};

export default Patient;
