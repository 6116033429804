import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';

import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';
import SearchBox from '../../components/SearchBox';
import ContextStore from '../../modules/context';

function Merchandises() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState({});
  const [merchandiseMapping, setMerchandiseMapping] = useState({})
  const [merchandiseData, setMerchandise] = useState(null);

  useEffect(() => {
    const ref = firebase.database().ref('merchandises')
    const onDataChange = ref.on('value', snapshot => {
      const data = []
      snapshot.forEach(doc => {
        const newData = { ...doc.val() }
        data.push({ id: doc.key, type: newData.cat1, category: newData.cat2, ...newData })
      })

      setMerchandiseMapping(snapshot.val())
      setMerchandise(data)
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.merchandises' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  if (!merchandiseData) {
    return 'Loading...'
  }

  const merchandises = merchandiseData.filter(i => merchandiseMapping[i.id]).map(i => ({ ...i, ...merchandiseMapping[i.id] }))
  const filteredMerchandises = filterMerchandises()

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'nickname' },
    { text: 'sku' },
    { text: 'onShelfDate' },
    { text: 'offShelfDate' },
  ].map(c => { c.text = formatMessage({ id: `merchandise.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'nickname' },
    { field: 'sku' },
    { field: 'onShelfDate' },
    { field: 'offShelfDate' },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filterItems = [
    { name: 'code' },
    { name: 'name' },
  ].map(i => { i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'type' },
    { name: 'category' },
  ].map(i => { i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` }); return i })

  function filterMerchandises() {
    if (!currentFilter &&
      !categoryFilter.type &&
      !categoryFilter.category) {
      return merchandises
    }
    let items = [...merchandises]
    if (categoryFilter.type) {
      if (categoryFilter.type === 'g') {
        items = items.filter(i => ['G', 'B'].includes(i.type))
      } else if (categoryFilter.type === 'i') {
        items = items.filter(i => ['I', 'B'].includes(i.type))
      }
    }
    if (categoryFilter.category) {
      items = items.filter(i => i.category === categoryFilter.category)
    }

    if (currentFilter) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      items = items.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    if (name === 'type') {
      return [{ value: '' }, { value: 'g' }, { value: 'i' }].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.type.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    } else if (name === 'category') {
      return [
        { value: '' },
        { value: 'M', header: true },
        { value: 'DPO' },
        { value: 'DOI' },
        { value: 'DIV' },
        { value: 'DSC' },
        { value: 'I', header: true },
        { value: 'CIN' },
        { value: 'CPR' },
        { value: 'CBD' },
        { value: 'CIP' },
        { value: 'CCO' },
        { value: 'CWI' },
        { value: 'COT' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.category.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    }
    return []
  }


  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value

    if (name === 'type' && !value) {
      newFilter.category = ''
    }
    setCategoryFilter(newFilter)
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={6} sm={6} md={4}>
      <TextField
        select
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
        value={categoryFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }

  return (
    <div style={{ padding: '40px 20px' }}>
      <Toolbar sx={{ pl: '16px', pr: '8px', }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2, }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="merchandise.table.title" />
        </Typography>
        <Grid container spacing={3} sx={{ display: 'flex', alignItems: 'center' }}>
          {categoryFilterItems.map(f => getFilter(f))}
          <Grid item xs={6} sm={6} md={3} sx={{ ml: '20px' }}>
            <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Grid>
        </Grid>
      </Toolbar>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filteredMerchandises.length}
          />
          <TableBody>
            {stableSort(filteredMerchandises, getComparator(order, orderBy)).map(merchandise => (
              <EnhancedTableRow
                key={merchandise.id}
                rowCells={rowCells}
                cellData={merchandise}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Merchandises;
