import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { reducer as firebaseStateReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import middleware from './middleware';
import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

if (process.env.BRANCH_ENV === 'lexcellence') {
  firebase.initializeApp({
    apiKey: 'AIzaSyABF0EI_QLoMvDkSBHdD2JCyJIsaJ19_jg',
    authDomain: 'reborn-d5f2a.firebaseapp.com',
    databaseURL: 'https://reborn-d5f2a.firebaseio.com',
    projectId: 'reborn-d5f2a',
    storageBucket: 'reborn-d5f2a.appspot.com',
    messagingSenderId: '682826339011',
    appId: '1:682826339011:web:19b1c33620fe8240'
  })
} else if (process.env.BRANCH_ENV === 'ibeauty') {
  firebase.initializeApp({
    apiKey: 'AIzaSyAYUYYiPpO2k-71IsvHglOOjboRs7ePRTA',
    authDomain: 'ibeauty-c0a96.firebaseapp.com',
    databaseURL: 'https://ibeauty-c0a96.firebaseio.com',
    projectId: 'ibeauty-c0a96',
    storageBucket: 'ibeauty-c0a96.appspot.com',
    messagingSenderId: '624073118302',
    appId: '1:624073118302:web:ba05b85750997998a09acf'
  })
} else if (process.env.BRANCH_ENV === 'ibeautyPlastic') {
  firebase.initializeApp({
    apiKey: 'AIzaSyDpN6BMscOTWlxQhq6LL6i0HnOglcKI3CU',
    authDomain: 'ibeauty-plastic.firebaseapp.com',
    databaseURL: 'https://ibeauty-plastic.firebaseio.com',
    projectId: 'ibeauty-plastic',
    storageBucket: 'ibeauty-plastic.appspot.com',
    messagingSenderId: '365691003256',
    appId: '1:365691003256:web:007508bba8d259b72b688d',
  })
} else if (process.env.BRANCH_ENV === 'ibeautyTaichung') {
  firebase.initializeApp({
    apiKey: 'AIzaSyAqJ_9J3eF8NtdJFZPsRyvmY8ytC26qPGE',
    authDomain: 'ibeauty-taichung.firebaseapp.com',
    databaseURL: 'https://ibeauty-taichung.firebaseio.com',
    projectId: 'ibeauty-taichung',
    storageBucket: 'ibeauty-taichung.appspot.com',
    messagingSenderId: '302950316238',
    appId: '1:302950316238:web:e4fa3b51df5a63689df89f',
  })
} else if (process.env.BRANCH_ENV === 'santea') {
  firebase.initializeApp({
    apiKey: 'AIzaSyCcGi8qertGrsBVjjS7M_e7uGEaeamjdeU',
    authDomain: 'santea-267fa.firebaseapp.com',
    databaseURL: 'https://santea-267fa.firebaseio.com',
    projectId: 'santea-267fa',
    storageBucket: 'santea-267fa.appspot.com',
    messagingSenderId: '23860420508',
    appId: '1:23860420508:web:d53db8b05bc20561807259'
  })
} else if (process.env.BRANCH_ENV === 'reborn') {
  firebase.initializeApp({
    apiKey: 'AIzaSyCl4dji80x-ziS2TYbJrBoos8kgz6y5Hmc',
    authDomain: 'reborn-release.firebaseapp.com',
    databaseURL: 'https://reborn-release.firebaseio.com',
    projectId: 'reborn-release',
    storageBucket: 'reborn-release.appspot.com',
    messagingSenderId: '853168487607',
    appId: '1:853168487607:web:b81965943739a2da'
  })
} else if (process.env.BRANCH_ENV === 'ibeautyHsinchu') {
  firebase.initializeApp({
    apiKey: 'AIzaSyCi_ioHJIH8XGiDdvyEheBKBwgV9yiMS4A',
    authDomain: 'ibeauty-hsinchu.firebaseapp.com',
    databaseURL: 'https://ibeauty-hsinchu-default-rtdb.firebaseio.com',
    projectId: 'ibeauty-hsinchu',
    storageBucket: 'ibeauty-hsinchu.appspot.com',
    messagingSenderId: '689235614256',
    appId: '1:689235614256:web:9492f635a19274001babf5'
  })
}

firebase.database()
firebase.firestore()
firebase.storage()
firebase.functions()

const reducer = combineReducers({
  firebase: firebaseStateReducer,
  firestore: firestoreReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createStoreWithFirebase = compose(
  reduxFirestore(firebase, {}), // firebase instance as first argument, rfConfig as optional second
)(createStore);

const configStore = (initialState = {}) => {
  const store = createStoreWithFirebase(reducer, initialState, composeEnhancer(applyMiddleware(...middleware)));
  return {
    store,
  };
};

const { store } = configStore();

global.store = store;

export { store, firebase };
