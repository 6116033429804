import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { createUseStyles } from 'react-jss'

import { sortTimeArray, findNextPunchIn, findNextPunchOut, splitShiftByLeaves } from '../../modules/uitls';

import PunchClock from './PunchClock';
import { tabletMedia } from '../../constants/index';

const useStyles = createUseStyles({
  datePunchClock: {
    marginBottom: '3px',
    color: '#fff',
    backgroundColor: '#ffbf38',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: 1.2,
    padding: '1px',
    fontSize: '12px',
    borderRadius: '.25rem',
    width: '85px',
    verticalAlign: 'baseline',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [tabletMedia]: {
      width: '160px',
      letterSpacing: '5px'
    }
  },
  doctorShift: {
    marginBottom: '3px',
    color: '#efeff4',
    fontWeight: 400,
    lineHeight: 1.2,
    padding: '1px',
    display: 'block',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    width: '85px',
    borderRadius: '.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [tabletMedia]: {
      width: '160px',
      letterSpacing: '5px',
      flexDirection: 'row'
    }
  },
  datePunchClockException: {
    marginBottom: '3px',
    color: '#fff',
    backgroundColor: '#17a2b8',
    fontWeight: 400,
    lineHeight: 1.2,
    padding: '1px',
    display: 'block',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    width: '85px',
    borderRadius: '.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [tabletMedia]: {
      width: '160px',
      letterSpacing: '5px',
      flexDirection: 'row'
    }
  },
  shiftDefultColor: {
    backgroundColor: '#3f51b5'
  },
  shiftSpecialColor: {
    backgroundColor: '#04AA6D',
  },
  shiftNoPlasticColor: {
    backgroundColor: '#04AA6D',
  },
  shiftEveryWeekColor: {
    backgroundColor: '#868e96'
  },
  punchClockError: {
    color: 'red'
  },
  punchClockRevised: {
    color: 'blue'
  }
});

function ScheduleDoctorDate({ date, staff, doctorShift, doctorSpecial, doctorLeave, onSubmit, punchClock, punchClockRevise, userRight, punchClockException }) {
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const punchClocks = punchClock[dayjs(date).format('YYYY-MM-DD')] ? punchClock[dayjs(date).format('YYYY-MM-DD')][staff.id] : []
  const punchClockRevises = punchClockRevise[dayjs(date).format('YYYY-MM-DD')] ? punchClockRevise[dayjs(date).format('YYYY-MM-DD')][staff.id] : []
  const punchClockExceptions = punchClockException ? punchClockException.filter(m => dayjs(m.date).isSame(dayjs(date)) && m.department.includes(staff.department)) : []
  const punchClockArray = punchClockAlgorithm();

  function onEditClick(event, content) {
    if (content === 'punchClock' && !userRight['schedule-edit']) {
      return false
    } else if (content === 'punchClock') {
      const punchClockData = {
        date: dayjs(date).format('YYYY-MM-DD'),
        staffId: staff.id
      }
      onSubmit(punchClocks, content, punchClockData, punchClockRevises);
    } else {
      onSubmit(event, content);
    }
  }

  function punchClockAlgorithm() {
    let newPunchClock = new PunchClock(date, punchClocks, punchClockRevises)
    const exceptionOff = punchClockExceptions.filter(pc => pc.type === 'off');
    const leaves = doctorLeave ? [{
      startDate: doctorLeave.firstDate,
      endDate: doctorLeave.lastDate,
      ...doctorLeave
    }] : []
    const sortedLeaves = sortTimeArray(leaves.concat(exceptionOff));

    const newShift = [...doctorSpecial]
    if (doctorShift) {
      newShift.push(doctorShift)
    }

    const newSpecial = newShift.reduce((acc, cur) => {
      if (!acc) {
        acc = {
          startTime: cur.startTime,
          endTime: cur.endTime
        }
      } else if (acc.startTime > cur.startTime) {
        acc.startTime = cur.startTime
      } else if (acc.endTime < cur.endTime) {
        acc.endTime = cur.endTime
      }

      return acc
    }, null)

    const shift = newSpecial
    let shiftTmp = Object.assign({}, shift);
    shiftTmp.date = dayjs(date).format('YYYY-MM-DD');
    shiftTmp.startTimeM = dayjs(`${shiftTmp.date} ${shiftTmp.startTime}`, 'YYYY-MM-DD HH:mm');
    shiftTmp.endTimeM = dayjs(`${shiftTmp.date} ${shiftTmp.endTime}`, 'YYYY-MM-DD HH:mm');
    let shifts = shift ? splitShiftByLeaves(shiftTmp, sortedLeaves) : [];

    let punchClockArrary = [];

    if (shifts?.length ?? false) {
      if (newPunchClock.isEmpty) {
        punchClockArrary.push({
          key: punchClockArrary.length,
          punchInTime: '-',
          punchOutTime: '-',
          punchInError: true,
          punchOutError: true
        });
      } else {
        let punchClockDupe = newPunchClock.all.concat();
        for (let i = 0; i < shifts.length; ++i) {
          let last = i === shifts.length - 1;
          let shift = shifts[i];
          if (shift.ignorePunchClock) continue;

          let shiftStartTime = shift.startTimeS || shift.startTime;
          let shiftEndTime = shift.endTimeS || shift.endTime;
          const { punchInTime, punchInError } = findNextPunchIn(punchClockDupe, date, shiftStartTime);
          const { punchOutTime, punchOutError, nextPunchClock } = findNextPunchOut(punchClockDupe, date, shiftEndTime, last, false);

          punchClockArrary.push({
            key: punchClockArrary.length,
            punchInTime,
            punchOutTime,
            punchInError,
            punchOutError,
            punchInRevised: punchClockRevises && punchClockRevises.includes(punchInTime),
            punchOutRevised: punchClockRevises && punchClockRevises.includes(punchOutTime)
          });

          if (!last) punchClockDupe = nextPunchClock;
        }
      }
    } else if (!newPunchClock.isEmpty) {
      let punchInTime = newPunchClock.first;
      let punchOutTime = newPunchClock.last;
      punchClockArrary.push({
        key: punchClockArrary.length,
        punchInTime,
        punchOutTime,
        punchInRevised: punchClockRevises && punchClockRevises.includes(punchInTime),
        punchOutRevised: punchClockRevises && punchClockRevises.includes(punchOutTime)
      });
    }

    return punchClockArrary
  }

  return (
    <div style={{ paddingBottom: '20px' }}>
      {punchClockExceptions.map((pcE, idx) => <div className={classes.datePunchClockException} key={idx}>
        <div>
          {pcE.startTime}-{pcE.endTime}
        </div>
        <div>
          {pcE.reason}
        </div>
      </div>)}
      {doctorLeave &&
        <div
          className={classes.doctorShift}
          onClick={() => onEditClick(doctorLeave, 'editDoctorLeave')}
          style={{ backgroundColor: '#fe2851' }
          }>
          <div>
            {formatMessage({ id: `schedule.doctor.typeName.${doctorLeave.repeats}` })}
          </div>
          <div>
            {doctorLeave.startTime} ~ {doctorLeave.endTime}
          </div>
        </div>
      }
      {doctorShift &&
        <div
          className={clsx(classes.doctorShift, classes.shiftDefultColor, {
            [classes.shiftEveryWeekColor]: doctorShift.repeats === 'every_week'
          })}
          onClick={() => onEditClick(doctorShift, 'editDoctorShift')}
        >
          <div>
            {formatMessage({ id: `schedule.doctor.typeName.${doctorShift.repeats}` })}
          </div>
          <div>
            {doctorShift.startTime} ~ {doctorShift.endTime}
          </div>
        </div>
      }
      {doctorSpecial.length > 0 && doctorSpecial.map(special => {
        return <div
          key={special.id}
          className={clsx(classes.doctorShift, classes.shiftDefultColor, {
            [classes.shiftSpecialColor]: special.repeats === 'no',
            [classes.shiftNoPlasticColor]: special.repeats === 'no-plastic'
          })}
          onClick={() => onEditClick(special, 'editDoctorShift')}
        >
          <div>
            {formatMessage({ id: `schedule.doctor.typeName.${special.repeats}` })}
          </div>
          <div>
            {special.startTime} ~ {special.endTime}
          </div>
        </div>
      })}
      {punchClockArray.map((pc, idx) => <div className={classes.datePunchClock} key={idx} onClick={() => onEditClick(pc, 'punchClock')}>
        <label className={pc.punchInError ? classes.punchClockError : (pc.punchInRevised ? classes.punchClockRevised : '')}>
          {pc.punchInTime}
        </label>-
        <label className={pc.punchOutError ? classes.punchClockError : (pc.punchOutRevised ? classes.punchClockRevised : '')}>
          {pc.punchOutTime}
        </label>
      </div>)
      }
    </div>
  );
}

ScheduleDoctorDate.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  date: PropTypes.object.isRequired,
  staff: PropTypes.object.isRequired,
  doctorShift: PropTypes.object,
  doctorSpecial: PropTypes.arrayOf(PropTypes.object),
  doctorLeave: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  punchClock: PropTypes.object.isRequired,
  punchClockRevise: PropTypes.object.isRequired,
  userRight: PropTypes.object.isRequired,
  punchClockException: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default ScheduleDoctorDate;
