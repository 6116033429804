import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekday from 'dayjs/plugin/weekday';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

import { objectToArray } from '../../modules/data'
import ScheduleDay from './ScheduleDay'
import AppointmentSidebar from './AppointmentSidebar'
import CommentSidebar from './CommentSidebar'
import { moibleMedia } from '../../constants/index'
import ContextStore from '../../modules/context';

dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);
dayjs.extend(weekday)

const appointmentTypeStrings = ['santea'].includes(process.env.BRANCH_ENV) ?
  ['beautyTreatment', 'preventative'] : ['beautyTreatment', 'beauty', 'preventative', 'plastic']
const drawerWidth = 240;
const drawerRightWidth = 860;
const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    justifyContent: 'flex-start',
    flexGrow: 2,
    boxSizing: 'border-box',
    overflow: 'hidden',
    position: 'relative',
    fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
    [moibleMedia]: {
      top: '100px',
      height: '100%',
    }
  },
  drawer: {
    position: 'absolute',
    width: `calc(100% - ${drawerWidth}px)`
  },
  drawerRight: {
    position: 'absolute',
    width: `calc(100% - ${drawerRightWidth}px)`
  }
});

function Appointments({ currentUser, userRight }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, setDrawerStatus, drawerStatus, setUiState, uiState } = useContext(ContextStore)
  const classes = useStyles();
  const history = useHistory();
  const [doctors, setDoctors] = useState([])
  const [doctorShifts, setDoctorShifts] = useState({ date: '', data: {} })
  const [doctorLeaves, setDoctorLeaves] = useState({})
  const [doctorShiftsWeek, setDoctorShiftsWeek] = useState({})
  const [scheduleComments, setScheduleComments] = useState({})
  const currentDateM = dayjs(uiState.date)
  const currentDayOfWeek = currentDateM.day()
  const [kardexes, setKardexes] = useState({ date: '', data: {} })
  const [unkardexes, setUnkardexes] = useState({ date: '', data: {} })
  const [patientList, setPatientList] = useState({})
  const { newDoctors, newDoctorShifts, newDoctorLeaves, newDoctorShiftsWeek } = filteredDoctorsAndDoctorShifts()

  useEffect(() => {
    setDrawerStatus({
      ...drawerStatus,
      content: newDoctorShiftsWeek
    })
    return () => {
    };
  }, [newDoctorShiftsWeek]);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setPatientList(snapshot.val())
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('scheduleComments').orderByChild('date').equalTo(`${dayjs(uiState.date).format('YYYY-MM-DD')}`)
    const onDataChange = ref.on('value', snapshot => {
      setScheduleComments(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, [uiState.date]);

  useEffect(() => {
    const ref = firebase.database().ref('kardexes').orderByChild('date').equalTo(`${dayjs(uiState.date).format('YYYY-MM-DD')}`)
    const onDataChange = ref.on('value', snapshot => {
      setKardexes({ date: uiState.date, data: snapshot.val() })
    });
    return () => ref.off('value', onDataChange)
  }, [uiState.date]);

  useEffect(() => {
    const ref = firebase.database().ref('appointments').orderByChild('date').equalTo(`${dayjs(uiState.date).format('YYYY-MM-DD')}`)
    const onDataChange = ref.on('value', snapshot => {
      setUnkardexes({ date: uiState.date, data: snapshot.val() })
    });
    return () => ref.off('value', onDataChange)
  }, [uiState.date]);

  useEffect(() => {
    const ref = firebase.database().ref('users').orderByChild('department').equalTo('doctor')
    const onDataChange = ref.on('value', snapshot => {
      setDoctors(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);


  useEffect(() => {
    if (doctors) {
      const unsubscribe = firebase.firestore().collection('doctorShifts')
        .onSnapshot(snapshot => {
          const shifts = {}
          const snapshots = {}
          snapshot.forEach(doc => {
            snapshots[doc.id] = { ...doc.data() };
          })
          const startOfWeek = currentDateM.startOf('week').format('YYYY-MM-DD')
          const endOfWeek = currentDateM.endOf('week').format('YYYY-MM-DD')
          const doctorShiftsWeek = objectToArray(snapshots).reduce((acc, shift) => {
            if (shift.lastDate && shift.lastDate < startOfWeek) {
              return acc
            }
            if (shift.firstDate && shift.firstDate > endOfWeek) {
              return acc
            }

            acc[shift.doctor] = acc[shift.doctor] || [
              [], [], [], [], [], [], []
            ]

            shifts[shift.doctor] = shifts[shift.doctor] || []

            const repeats = shift.repeats
            let dateM = dayjs(shift.firstDate)
            while (dateM.isSameOrBefore(dayjs(shift.lastDate || endOfWeek, 'YYYY-MM-DD'))) {
              if (dateM.isBefore(dayjs(startOfWeek, 'YYYY-MM-DD'))) {
                dateM = dateM.add(1, 'week')
                continue
              }
              const weeks = dateM.week() - dayjs(dateM).startOf('month').week() + 1

              if (['no', 'no-plastic', 'every_week', 'next_weeks'].includes(repeats) ||
                (repeats === 'even_weeks' && weeks % 2 === 0) ||
                (repeats === 'odd_weeks' && weeks % 2 === 1)
              ) {
                shift.startHour = parseInt(shift.startTime.split(':')[0], 10)
                shift.startMinute = parseInt(shift.startTime.split(':')[1], 10)
                shift.endHour = parseInt(shift.endTime.split(':')[0], 10)
                shift.endMinute = parseInt(shift.endTime.split(':')[1], 10)
                const dayOfWeek = dateM.weekday()
                acc[shift.doctor][dayOfWeek].push(shift)
                if (dayOfWeek === currentDayOfWeek) {
                  shifts[shift.doctor].push(shift)
                }
              }
              dateM = dateM.add(1, 'week')
            }

            return acc
          }, {})

          setDoctorShifts({ date: uiState.date, data: shifts })
          setDoctorShiftsWeek(doctorShiftsWeek)
        });

      return () => unsubscribe()
    }
  }, [uiState.date]);

  useEffect(() => {
    if (doctors) {
      const unsubscribe = firebase.firestore().collection('doctorLeaves')
        .where('firstDate', '==', dayjs(uiState.date).format('YYYY-MM-DD')).onSnapshot(snapshot => {
          const leaves = {}
          const snapshots = {}
          snapshot.forEach(doc => {
            snapshots[doc.id] = { ...doc.data() };
          })
          const startOfWeek = currentDateM.startOf('week').format('YYYY-MM-DD')
          const endOfWeek = currentDateM.endOf('week').format('YYYY-MM-DD')

          for (const leave of objectToArray(snapshots)) {
            leaves[leave.doctor] = leaves[leave.doctor] || []

            const repeats = leave.repeats
            let dateM = dayjs(leave.firstDate)
            while (dateM.isSameOrBefore(dayjs(leave.lastDate || endOfWeek, 'YYYY-MM-DD'))) {
              if (dateM.isBefore(dayjs(startOfWeek, 'YYYY-MM-DD'))) {
                dateM = dateM.add(1, 'week')
                continue
              }

              if (repeats === 'leaves') {
                leave.startHour = parseInt(leave.startTime.split(':')[0], 10)
                leave.startMinute = parseInt(leave.startTime.split(':')[1], 10)
                leave.endHour = parseInt(leave.endTime.split(':')[0], 10)
                leave.endMinute = parseInt(leave.endTime.split(':')[1], 10)
                const dayOfWeek = dateM.weekday()

                if (dayOfWeek === currentDayOfWeek) {
                  leaves[leave.doctor].push(leave)
                }
              }
              dateM = dateM.add(1, 'week')
            }
          }

          setDoctorLeaves(leaves)
        });

      return () => unsubscribe()
    }
  }, [uiState.date]);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.appointments.schedule' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  const APPOINTMENT_TYPE_ALWAYS_SHOW = ['beautyTreatment', 'beauty', 'preventative', 'plastic']
  const filteredAppointmentTypes = appointmentTypeStrings.filter(type =>
    APPOINTMENT_TYPE_ALWAYS_SHOW.includes(type) || doctors.find(doctor => {
      return doctor.appointmentType === type && doctorShifts[doctor.id] && doctorShifts[doctor.id].length
    })
  )

  function filteredDoctorsAndDoctorShifts() {
    let newDoctorShifts = doctorShifts.data
    let newDoctors = doctors
    let newDoctorShiftsWeek = doctorShiftsWeek
    let newDoctorLeaves = doctorLeaves

    if (!doctors) {
      return {}
    }

    if (currentUser.department === 'doctor') {
      const theDoctor = newDoctors.find(doctor => currentUser.key === doctor.id)
      newDoctors = newDoctors.filter(d => d.id === theDoctor.id)
      if (theDoctor) {
        //   newDoctorShifts = _.pick(newDoctorShifts, theDoctor.id)
        //   newDoctorShiftsWeek = _.pick(newDoctorShiftsWeek, theDoctor.id)
        //   newDoctorLeaves = _.pick(newDoctorLeaves, theDoctor.id)
      }
      // if (theDoctor) {
      //   newDoctors = newDoctors.filter(d => d.id === theDoctor.id)
      //   newDoctorShifts = _.pick(newDoctorShifts, theDoctor.id)
      //   newDoctorShiftsWeek = _.pick(newDoctorShiftsWeek, theDoctor.id)
      //   newDoctorLeaves = _.pick(newDoctorLeaves, theDoctor.id)
      // }
    }

    return {
      newDoctorShifts,
      newDoctors,
      newDoctorLeaves,
      newDoctorShiftsWeek
    }
  }

  function concatKardexes() {
    if (!(kardexes.date === unkardexes.date && doctorShifts.date === kardexes.date)) {
      return {}
    }

    if (kardexes.data && !unkardexes.data) {
      return kardexes.data
    } else if (!kardexes.data && unkardexes.data) {
      return unkardexes.data
    } else if (kardexes.data && unkardexes.data) {
      return { ...kardexes.data, ...unkardexes.data }
    } else {
      return {}
    }
  }

  function onCommentClose() {
    setUiState({
      ...uiState,
      showCommentSidebar: false,
      editComment: null
    })
  }

  function onRequestClose() {
    setUiState({
      ...uiState,
      showAppointmentSidebar: false,
      selectedAppointment: false,
      selectedAppointmentEl: {},
      clickMenuData: null,
      editAppointment: null
    })
  }

  return (
    <div className={clsx(classes.root, {
      [classes.drawer]: drawerStatus.display === 'left',
      [classes.drawerRight]: drawerStatus.display === 'right'
    })}>
      {uiState.showAppointmentSidebar && <AppointmentSidebar
        onRequestClose={onRequestClose}
        ui={uiState}
        userRight={userRight}
      />}
      {uiState.showCommentSidebar && <CommentSidebar
        onRequestClose={onCommentClose}
        ui={uiState}
        appointmentTypes={filteredAppointmentTypes}
        doctors={newDoctors}
      />}
      <ScheduleDay
        appointmentTypes={filteredAppointmentTypes}
        doctors={newDoctors}
        doctorShifts={newDoctorShifts}
        doctorLeaves={newDoctorLeaves}
        appointments={concatKardexes()}
        patientList={patientList}
        comments={scheduleComments || {}}
      />
    </div>
  );
}

Appointments.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  userRight: PropTypes.object.isRequired,
};

export default Appointments;
