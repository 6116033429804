import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import { objectToArray } from '../../modules/data';
import ActionDialog from '../../components/ActionDialog';

function PaymentView({ purchaseOrder, productMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [payments, setPayments] = useState([])
  const [openDialog, setOpenDialog] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const ref = firebase.database().ref('payments').orderByChild('purchaseOrder').equalTo(purchaseOrder.id)
    const onDataChange = ref.on('value', snapshot => {
      setPayments(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  async function handleExecute(data) {
    setLoading(true);
    const paymentId = openDialog.id
    const newData = { ...openDialog, reason: { ...data } }

    try {
      await (firebase.functions().httpsCallable('paymentVoid'))({ id: paymentId, ...newData })
    } catch (ex) {
      console.log(ex)
    }

    setLoading(false);
  }

  const headerCells = [
    { text: 'date', sort: 'date' },
    { text: 'status', sort: 'status', root: true },
    { text: 'type', sort: 'type', root: true },
    { text: 'treatment', sort: 'treatment' },
    { text: 'note', sort: 'note' },
    { text: 'totlePaid', sort: 'totlePaid' },
  ].map(c => { c.text = c.root ? formatMessage({ id: `manage.purchase.payment.${c.text}.root` }) : formatMessage({ id: `manage.purchase.payment.${c.text}` }); return c })

  const rowCells = [
    { field: 'date' },
    { field: 'status' },
    { field: 'type' },
    { field: 'treatment' },
    { field: 'note' },
    { field: 'collected' },
  ]

  function formatData(value) {
    let newValue = value
    newValue.status = formatMessage({ id: `manage.purchase.payment.status.${newValue.installment}` })
    newValue.type = formatMessage({ id: `manage.purchase.payment.type.${newValue.paymentType}` })
    newValue.treatment = Object.keys(purchaseOrder.products).reduce((acc, cur) => {
      const productId = purchaseOrder.products[cur].productId
      if (productMapping[productId]) {
        acc.push(productMapping[productId].name)
      }
      return acc
    }, []).join(',')

    return newValue
  }

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', }}>
      {loading && <Backdrop open={true} sx={{ zIndex: 2000, color: '#fff' }}>
        <CircularProgress disableShrink color="inherit" />
      </Backdrop>}
      {openDialog && <ActionDialog
        title={formatMessage({ id: 'manage.purchase.dialog.title.payment' })}
        handleClose={() => setOpenDialog(null)}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'manage.purchase.dialog.voidReason' })}
        action={'void'}
      />}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={payments.length}
            actionButton
          />
          <TableBody>
            {stableSort(payments.map(p => formatData(p)), getComparator(order, orderBy)).map(payment => (
              <EnhancedTableRow
                key={payment.id}
                rowCells={rowCells}
                cellData={payment}
                actionIcons={<>
                  {!payment.void ? <Tooltip title={formatMessage({ id: 'button.void' })}>
                    <IconButton onClick={() => setOpenDialog(payment)} size="large">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip> : <div style={{ color: 'red' }}>{formatMessage({ id: 'manage.purchase.payment.void' })}</div>}
                </>}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div >
  );
}

PaymentView.propTypes = {
  purchaseOrder: PropTypes.object.isRequired,
  productMapping: PropTypes.object.isRequired
};

export default PaymentView;
