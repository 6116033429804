import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { BarChart } from 'react-chartkick';
import 'chartkick/chart.js'
import 'chartjs-adapter-date-fns';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import EnhancedTableHead from '../../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../../components/SimpleTableToolbar';

function PurchaseCategory({ purchaseOrders, productTypeData, productMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [open, setOpen] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatData = (data) => {
    const newData = { ...data }

    const total = Object.keys(productTypeData).reduce((acc, cur) => {
      acc += Number(productTypeData[cur].price)
      return acc
    }, 0)

    if (newData.id === 'total') {
      return total
    } else {
      return productTypeData[newData.id].price
    }
  }

  let count = 0

  const categoryData = purchaseOrders.reduce((acc, cur) => {
    if (cur.payments) {
      for (const p of Object.keys(cur.products)) {
        const product = productMapping[cur.products[p].productId]
        const tags = product.tags.split(',')

        for (const t of tags) {
          if (!acc[t]) {
            acc[t] = { price: cur.products[p].discountPrice * cur.products[p].amount, type: product.cat1 }
          } else {
            acc[t].price += cur.products[p].discountPrice * cur.products[p].amount
          }
        }
      }
    }

    return acc
  }, {})

  const barChartData = Object.keys(categoryData).reduce((acc, cur) => {
    acc.push([
      cur,
      categoryData[cur].price
    ])

    return acc
  }, [])

  const rowCells = []
  const _headerCells = Object.keys(productTypeData).reduce((acc, cur) => {
    if (acc !== cur) {
      acc.push({ text: cur, order: count })
      rowCells.push({ id: cur })
      count++
    }
    return acc
  }, [])

  const headerCells = _headerCells.concat([{ text: 'total', order: count + 1 }]).sort((a, b) => { return a.order - b.order })
    .map(c => { c.text = formatMessage({ id: `report.turnoverType.type.${c.text}` }); return c })

  function formatCurrency(num) {
    num = num.toString().replace(/\$|,/g, '');
    if (isNaN(num))
      num = '0';
    num = Math.floor(num * 100 + 0.50000000001);
    let cents = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10) {
      for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
        num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
      }
    }
    return num;
  }

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataset = context.dataset;
            const value = dataset.data[context.dataIndex];
            const total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
              return previousValue += currentValue;
            });
            const currentValue = dataset.data[context.dataIndex];
            const percentage = Math.floor(((currentValue / total) * 100) + 0.5);

            return `${percentage}% (銷售額: ${formatCurrency(value)})`;
          }
        }
      }
    }
  }

  return (
    <Grid item xs={12} sm={12} md={12} sx={{ padding: '10px' }}>
      <SimpleTableToolbar
        title={'report.turnoverType.title'}
      />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={purchaseOrders.length}
            expandable
          />
          <TableBody>
            <TableRow>
              <TableCell onClick={() => setOpen(!open)}>
                <IconButton aria-label="expand row" size="small">
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              {rowCells.concat({ id: 'total' }).map(p => (
                <TableCell key={p.id}>{formatData(p)}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell sx={open ? { borderTop: 'solid 1px #e0e0e0', paddingBottom: 0, paddingTop: 0 } : { paddingBottom: 0, paddingTop: 0 }} colSpan={rowCells.length + 1}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <BarChart data={barChartData} library={options} dataset={{ barPercentage: 0.3 }} />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

PurchaseCategory.propTypes = {
  purchaseOrders: PropTypes.arrayOf(PropTypes.object.isRequired),
  productTypeData: PropTypes.object.isRequired,
  productMapping: PropTypes.object.isRequired
};

export default PurchaseCategory;
