import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import { createUseStyles } from 'react-jss'

import Button from '@mui/material/Button';

import { objectToArray } from '../../modules/data'


const projectColor = process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence' ?
  '#eeccc4' : process.env.BRANCH_ENV === 'santea' ? '#26CAD3' : '#6EDBD5'

const useStyles = createUseStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '860px',
    backgroundColor: '#ffffff',
    width: '100%',
    height: 'auto',
    padding: '20px 0',
    fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif'
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '15px',
    color: 'rgba(0, 0, 0, 0.54)',
    borderBottom: '1px solid rgba(0,0,0,.1)'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    height: '43px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0,0,0,.1)'
  },
  nameCell: {
    width: '104px',
    overflow: 'hidden',
    paddingLeft: '23px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.78)'
  },
  cell: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexBasis: 0,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '32px',
    margin: '9px 0 4px 0',
  },
  shift: {
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: '#ecf8ff',
    padding: '0 3px',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    marginRight: '10px'
  }
});

function DoctorSchedule({ doctorShiftsWeek }) {
  const classes = useStyles();
  const [doctors, setDoctors] = useState([])
  const daysOfWeek = ['週日', '週一', '週二', '週三', '週四', '週五', '週六']

  useEffect(() => {
    const ref = firebase.database().ref('users').orderByChild('department').equalTo('doctor')
    const onDataChange = ref.on('value', snapshot => {
      setDoctors(objectToArray(snapshot.val()))
    });
    return () => ref.off('value', onDataChange)
  }, []);

  return (
    <div className={classes.main}>
      <div className={classes.rows}>
        <div className={classes.header}>
          <div className={classes.nameCell} />
          {daysOfWeek.map((day, i) => (
            <div className={classes.cell} key={i}>{day}</div>
          ))}
        </div>
        {doctors.map((doctor, i) => (
          doctor.active && <div className={classes.row} key={i}>
            <div className={classes.nameCell}>{doctor.displayName}</div>
            {daysOfWeek.map((day, i) => (
              <div className={classes.cell} key={doctor.id + i}>
                {
                  !doctorShiftsWeek[doctor.id] ||
                    !doctorShiftsWeek[doctor.id][i] ||
                    !doctorShiftsWeek[doctor.id][i].length
                    ? '--'
                    : doctorShiftsWeek[doctor.id][i].map((shift, index) => (
                      <div
                        key={shift.id + index}
                        className={classes.shift}
                        style={{ backgroundColor: (shift.repeats === 'no' || shift.repeats === 'no-plastic') && '#fff3f2' }}
                      >
                        {shift.startTime}-{shift.endTime}
                      </div>
                    ))
                }
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={classes.button}>
        <Button
          style={{
            fontSize: '14px',
            lineHeight: '100%',
            borderRadius: '32px',
            backgroundColor: '#ffffff',
            border: `solid 1px ${projectColor}`,
            margin: '3px',
            color: `${projectColor}`
          }}
          onClick={() => window.open('/schedule/doctor')}
        >
          <FormattedMessage id={'appointment.editSchedules'} />
        </Button>
      </div>
    </div>
  );
}

DoctorSchedule.propTypes = {
  doctorShiftsWeek: PropTypes.object.isRequired,
};

export default DoctorSchedule;
