const objectToArray = (obj, sortKey) => {
  let result = obj || {}
  result = Object.keys(result).map((key) => (Object.assign({}, { ...result[key], id: key })))
  if (sortKey) {
    result = result.sort((a, b) => (a[sortKey] - b[sortKey]))
  }
  return result
}

const objectToNewKey = (obj, newKey) => (
  obj && objectToArray(obj).reduce((acc, item) => {
    acc[item[newKey]] = item
    return acc
  }, {})
)

const compareObjects = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  if (aProps.length !== bProps.length) {
    return false
  }

  aProps.map(propName => {
    if (a[propName] !== b[propName]) {
      return false
    }

    return true
  })

  return true
}

export {
  objectToArray,
  objectToNewKey,
  compareObjects
};
