import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const Images = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  '& > a': {
    width: '120px',
    height: '160px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #e2e6ee',
    position: 'relative',
    margin: '0 10px 10px 0',
    '& img': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  }
}));

function ConsultPage({ field, patient, patientId, kardexId }) {
  const { formatMessage } = useIntl()
  const [templateImage, setTemplateImage] = useState({})
  const [dataImage, setDataImage] = useState({})
  const [photoImage, setPhotoImage] = useState(null)

  useEffect(() => {
    setDataImage({})
    const storageRef = firebase.storage().ref(field.path)
    let promises = []
    if (field.data) {
      for (let dataKey in field.data) {
        if (field.data[dataKey].photo) {
          storageRef.child(`${dataKey}/photo.png`).getDownloadURL().then((url) => {
            if (url) { setPhotoImage({ [dataKey]: url }) }
          }, (err) => {
            console.log(err)
          })
        }

        if (field.mapping[dataKey].childPages) {
          for (let s in field.mapping[dataKey].childPages) {
            for (let dateKey in field.mapping[s].uploadDates) {
              for (let userKey in field.mapping[s].uploadDates[dateKey]) {
                promises.push(storageRef.child(`${s}/${dateKey}/${userKey}.png`).getDownloadURL().then((url) => {
                  return { key: s, date: dateKey, url: url, user: userKey }
                }, (err) => {
                  console.log(err)
                }))
              }
            }
          }
        }
        for (let dateKey in field.data[dataKey].uploadDates) {
          for (let userKey in field.data[dataKey].uploadDates[dateKey]) {
            promises.push(storageRef.child(`${dataKey}/${dateKey}/${userKey}.png`).getDownloadURL().then((url) => {
              return { key: dataKey, date: dateKey, url: url, user: userKey }
            }, (err) => {
              console.log(err)
            }))
          }
        }
      }

      Promise.all(promises).then((results) => {
        const dataImages = results.reduce((acc, item) => {
          if (!item || !item.key) {
            return acc
          }

          if (!acc[item.key]) {
            acc[item.key] = { [item.date]: {} }
          }

          acc[item.key][item.date] = { ...acc[item.key][item.date], [item.user]: item.url }
          return acc
        }, {})

        setDataImage(dataImages)
      })
    }
  }, [field]);

  useEffect(() => {
    setTemplateImage({})
    const storageRef = firebase.storage().ref(field.templatePath)
    let promises = []
    for (let dataKey in field.data) {
      for (const tempKey in field.template) {
        if (field.template[tempKey].name === field.data[dataKey].name) {
          if (field.template[tempKey].specificGender === 'S') {
            promises.push(
              storageRef.child(`${patient.gender === 'male' ? 'M' : 'F'}_${field.template[tempKey].name}.jpg`).getDownloadURL().then((url) => {
                return { key: dataKey, url: url }
              }, (err) => {
                console.log(err)
              })
            )
          } else {
            promises.push(
              storageRef.child(`${field.template[tempKey].specificGender}_${field.template[tempKey].name}.jpg`).getDownloadURL().then((url) => {
                return { key: dataKey, url: url }
              }, (err) => {
                console.log(err)
              })
            )
          }
        }
      }

      for (const tempKey in field.template) {
        if (field.mapping[dataKey].childPages) {
          for (const s in field.data[dataKey].childPages) {
            if (field.template[tempKey].name === field.mapping[s].name) {
              if (field.template[tempKey].specificGender === 'S') {
                promises.push(
                  storageRef.child(`${patient.gender === 'male' ? 'M' : 'F'}_${field.template[tempKey].name}.jpg`).getDownloadURL().then((url) => {
                    return { key: s, url: url }
                  }, (err) => {
                    console.log(err)
                  })
                )
              } else {
                promises.push(
                  storageRef.child(`${field.template[tempKey].specificGender}_${field.template[tempKey].name}.jpg`).getDownloadURL().then((url) => {
                    return { key: s, url: url }
                  }, (err) => {
                    console.log(err)
                  })
                )
              }
            }
          }
        }
      }
    }

    Promise.all(promises).then((results) => {
      const templateImages = results.reduce((acc, item) => {
        if (!item || !item.key) {
          return acc
        }
        acc[item.key] = item.url
        return acc
      }, {})

      setTemplateImage(templateImages)
    })

  }, [field, patient]);

  let docKeys = [];
  Object.keys(field.data).sort((a, b) => {
    return field.mapping[a].createdAt - field.mapping[b].createdAt
  }).forEach(s => {
    if (field.mapping[s].childPages) {
      docKeys.push(s)
      for (const c in field.mapping[s].childPages) {
        docKeys.push(c)
      }
    } else {
      const newStr = field.mapping[s].name.substr(field.mapping[s].name.length - 1, 1)
      if (!['+', '~'].includes(newStr)) {
        docKeys.push(s)
      }
    }
  })

  return (
    <div style={{ padding: '24px 20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ fontSize: '14px', color: '#888', fontWeight: 500 }}>
            {formatMessage({ id: 'kardex.profile.doctorNote' })}
            {`(${Object.keys(field.data).length})`}
          </div>
          <Images>
            {
              docKeys.map((dataKey) => (
                <a
                  key={dataKey}
                  rel="noreferrer"
                  href={`/patients/${patientId}/kardexes/${kardexId}/${field.path}`}
                  target='_blank'
                >
                  <img alt='' src={templateImage[dataKey]} />
                  {field.mapping[dataKey] && field.mapping[dataKey].uploadDates && Object.keys(dataImage).map((i) => {
                    return Object.keys(dataImage[i]).map((date, key) => {
                      return Object.keys(dataImage[i][date]).map((user, key) => {
                        if (dataImage[dataKey][date]) {
                          return <img alt='' key={key} src={dataImage[dataKey][date][user]} />
                        } else {
                          return null
                        }
                      })
                    })
                  })}
                  {photoImage && <img alt='' style={{ maxHeight: '25px', maxWidth: '25px' }} src={photoImage[dataKey]} />}
                </a>
              ))
            }
          </Images>
        </Grid>
      </Grid>
    </div>
  );
}

ConsultPage.propTypes = {
  field: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
  patientId: PropTypes.string,
  kardexId: PropTypes.string
};

export default ConsultPage;
