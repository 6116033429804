import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import firebase from 'firebase/app';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import ListSubheader from '@mui/material/ListSubheader';

import { getComparator, stableSort } from '../../../modules/sort';
import EnhancedTableRow from '../../../components/EnhancedTableRow';
import EnhancedTableHead from '../../../components/EnhancedTableHead';

function Agreement({ agreements, agreementTemplates, kardex, userMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [categoryFilter, setCategoryFilter] = useState({});

  const categoryFilterItems = [
    { name: 'category' },
  ].map(i => { i.text = formatMessage({ id: `agreement.table.detail.${i.name}` }); return i })

  const headerCells = [
    { text: 'displayName', sort: 'name' },
    { text: 'doctor', sort: 'doctor' },
    { text: 'nurse', sort: 'nurse' },
    { text: 'date', sort: 'createdAt' },
  ].map(c => { c.text = formatMessage({ id: `agreement.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'displayName' },
    { field: 'doctor' },
    { field: 'nurse' },
    { field: 'date' },
  ]

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  function getMenuItem(name) {
    return [
      { value: '' },
      { value: 'PS' },
      { value: 'MCS' },
      { value: 'FL' },
      { value: 'L' },
      { value: 'CS' },
      { value: 'A' },
      { value: 'H' },
      { value: 'O' },
    ].map(i => {
      i.label = i.value ? formatMessage({ id: `agreement.category.${i.value}` }) : formatMessage({ id: 'agreement.all' })
      return i
    })
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={3} sm={3} md={3}>
      <TextField
        select
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
        value={categoryFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }


  const formatData = (agreement) => {
    const newData = { ...agreement }

    newData.date = dayjs(newData.createdAt).format('YYYY-MM-DD')
    newData.displayName = agreementTemplates.map(t => {
      if (newData.name === t.name) {
        return t.displayName
      }

      return ''
    })
    kardex.forEach(k => {
      if (newData.kardex === k.id) {
        newData.doctor = userMapping[k.doctor] ? userMapping[k.doctor].displayName : ''
        newData.nurse = userMapping[k.nurse] ? userMapping[k.nurse].displayName : ''
      }
    })

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function filterAgreements() {
    if (!categoryFilter) {
      return agreements
    }

    let items = [...agreements]

    if (categoryFilter.category) {
      items = items.filter(i => i.category === categoryFilter.category)
    }
    return items
  }

  async function openAgreement(agreement) {
    const storageRef = firebase.storage().ref('agreements')
    try {
      storageRef.child(`${agreement.kardex}/${agreement.id}/${agreement.pdf}`).getDownloadURL().then((url) => {
        window.open(url, '_blank')
      }, () => {
        alert('Agreement file not found')
      })
    } catch (ex) {
      console.log(ex)
    }
  }

  const filterAgreement = filterAgreements()

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', marginTop: '10px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Toolbar sx={{ pl: '16px', pr: '8px' }}>
            <Grid container spacing={1} />
            {categoryFilterItems.map(f => getFilter(f))}
          </Toolbar>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filterAgreement.length}
              />
              <TableBody>
                {stableSort(filterAgreement.map(r => formatData(r)), getComparator(order, orderBy)).map(agreement => (
                  <EnhancedTableRow
                    key={agreement.id}
                    rowCells={rowCells}
                    cellData={agreement}
                    onRowClick={() => openAgreement(agreement)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

Agreement.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.object.isRequired),
  agreementTemplates: PropTypes.arrayOf(PropTypes.object.isRequired),
  kardex: PropTypes.arrayOf(PropTypes.object),
  userMapping: PropTypes.object
};

export default Agreement;
