const i18n = {
  'announcement': {
    'title': ' ',
    'upload': {
      'title': '公告上傳',
      'subject': '主旨',
    },
    'table': {
      'date': '日期',
      'subject': '主旨',
      'createby': '發件人',
    },
    'error': '未上傳PDF',
    'void': {
      'title' : '公告作廢',
      'message': '確認作廢公告?'
    }
  }
}
export default i18n