import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw'

import TextField from '@mui/material/TextField';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import MuiPickersDay from '@mui/lab/PickersDay';

export default function DatePickerField({ label, underLine = 'outlined', value = null, required = false, inputFormat = 'YYYY-MM-DD', fullWidth = false, disabled = false, shouldDisableDate, onChange, ...props }) {
  const [textProps, setTextProps] = useState({});

  const limit = {}
  if (props.minDate) {
    limit.minDate = dayjs(props.minDate)
  }
  if (props.maxDate) {
    limit.maxDate = dayjs(props.maxDate)
  }
  if (shouldDisableDate) {
    limit.renderDay = function PickersDay(day, _value, DayComponentProps) { return <MuiPickersDay {...DayComponentProps} disabled={shouldDisableDate(day)} /> }
  }

  function onError(err) {
    if (err === 'minDate') {
      setTextProps({ helperText: props.minDateMessage })
    } else if (err === 'maxDate') {
      setTextProps({ helperText: props.maxDateMessage })
    } else if (err) {
      setTextProps({ helperText: props.invalidDateMessage })
    } else {
      if (required && value === null) {
        setTextProps({ error: true, helperText: '欄位為必填' })
      } else {
        setTextProps({})
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={'zh-tw'}>
      <DatePicker
        disabled={disabled}
        mask="____-__-__"
        inputFormat={inputFormat}
        label={label}
        value={value}
        views={inputFormat.endsWith('DD') ? ['day'] : ['year', 'month']}
        onChange={onChange}
        onError={onError}
        onAccept={() => setTextProps({})}
        leftArrowButtonText="前一個月份"
        rightArrowButtonText="下一個月份"
        allowSameDateSelection
        {...limit}
        componentsProps={{ error: true }}
        renderInput={(params) => {
          params.inputProps.readOnly = inputFormat.endsWith('DD') ? false : true
          return < TextField
            required={required}
            fullWidth={fullWidth}
            size="small"
            variant={underLine}
            onCompositionStart={
              e => {
                e.target.addEventListener('input', e2 => {
                  if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                    e2.preventDefault()
                    e2.stopPropagation()
                    e2.stopImmediatePropagation()
                  }
                }, { once: true })
              }
            }
            {...params}
            {...textProps}
          />
        }}
      />
    </LocalizationProvider>
  );
};

DatePickerField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  minDateMessage: PropTypes.string,
  maxDateMessage: PropTypes.string,
  invalidDateMessage: PropTypes.string,
  shouldDisableDate: PropTypes.func,
  underLine: PropTypes.string,
  inputFormat: PropTypes.string
};
