import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import warehouse from '../../modules/warehouseConfig'
import { formatName } from '../../modules/uitls'

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function ReviewPatient({ patientId }) {
  const { formatMessage } = useIntl()
  const [benefit, setBenefit] = useState(null)
  const [patients, setPatients] = useState(null);
  const [patientMapping, setPatientMapping] = useState(null)
  const [productMapping, setProductMapping] = useState(null)
  const [storedValueCard, setStoredValueCard] = useState(null)

  useEffect(() => {
    const ref = firebase.database().ref('storedValueCard').child(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setStoredValueCard(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('products')
    const onDataChange = ref.on('value', snapshot => {
      setProductMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      setPatientMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patients').child(patientId)
    const onDataChange = ref.on('value', snapshot => {
      setPatients(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, [patientId]);

  useEffect(() => {
    if (patients && patients.employeeId) {
      const ref = firebase.database(warehouse).ref('benefitBalances').child(patients.employeeId)
      const onDataChange = ref && ref.on('value', snapshot => {
        const snapshots = snapshot.val()
        setBenefit(snapshots)
      });
      return () => ref.off('value', onDataChange)
    }
  }, [patients]);

  if (!patients || !patientMapping || (patients.type === '員工' && !benefit)) {
    return ('Loading...')
  }

  const formatData = (patient) => {
    const newData = { ...patient }
    newData.name = formatName(newData.name)
    if (benefit) {
      newData.earned = benefit.earned
      newData.used = benefit.used
      newData.balance = benefit.balanceHistory ?
        benefit.earned - benefit.used + benefit.balanceHistory :
        benefit.earned - benefit.used
    }

    return newData
  }

  const patient = formatData(patients)
  const _fields = [
    { name: 'gender', sm: 3, md: 3, roots: true, order: 1 },
    { name: 'type', sm: 3, md: 3, roots: true, order: 2 },
    { name: 'identityCardNumber', sm: 3, md: 3, order: 4 },
    { name: 'nationality', roots: true, sm: 3, md: 3, order: 5 },
    { name: 'infosource', sm: 3, md: 3, roots: true, order: 6 },
    { name: 'maritalStatus', sm: 3, md: 3, roots: true, order: 7 },
    { name: 'address', sm: 12, md: 12, order: 8 },
    { name: 'comment', sm: 12, md: 12, order: 9 },
    { name: 'underLine', type: '-', sm: 12, md: 12, order: 98 },
  ]

  if (patient.type === '員工') {
    _fields.push({ name: 'employeeId', sm: 3, md: 3, order: 3 })
    _fields.push({ name: 'benefitline', text: formatMessage({ id: 'staff.profile.benefit.roots' }), type: '-', order: 11 })
    _fields.push({ name: 'earned', sm: 3, md: 3, benefit: true, order: 12 })
    _fields.push({ name: 'used', sm: 3, md: 3, benefit: true, order: 13 })
    _fields.push({ name: 'balance', sm: 3, md: 3, benefit: true, order: 14 })
  } else if (patient.type === '員工眷屬') {
    _fields.push({ name: 'employeeId', sm: 3, md: 3, order: 3 })
  } else if (patient.type === '舊帶新') {
    _fields.push({ name: 'referrer', sm: 3, order: 3 })
  }

  if (storedValueCard) {
    _fields.push({ name: 'storedValueCardLine', text: formatMessage({ id: 'patient.profile.storedValueBalance' }), type: '-', order: 15 })
    let newOrder = 16
    for (const product of Object.keys(storedValueCard)) {
      _fields.push({ name: `${productMapping[product].name}`, type: 'storedValue', sm: 3, value: storedValueCard[product], order: newOrder })
      newOrder += 1
    }
  }

  if (['lexcellence', 'ibeautyTaichung', 'ibeauty'].includes(process.env.BRANCH_ENV)) {
    _fields.push({ name: 'customercode', sm: 3, md: 3, order: 0 })
  }

  if (patient.cantWait) {
    _fields.push({ name: 'cantWait', color: '#ffbf38', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (patient.allergy) {
    _fields.push({ name: 'allergy', color: '#fe2851', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (patient.blackList) {
    _fields.push({ name: 'blackList', color: '#000', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (patient.noPhone) {
    _fields.push({ name: 'noPhone', color: '#a1d938', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (patient.noSms) {
    _fields.push({ name: 'noSms', color: '#2882ff', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (patient.vip) {
    _fields.push({ name: 'vip', color: '#c665c9', type: 'status', sm: 3, md: 3, order: 99 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field, value, patient) {
    let newValue = value
    let labelText = ''

    if (['gender', 'maritalStatus'].includes(field.name) && value) {
      newValue = formatMessage({ id: `patient.profile.${field.name}.${value}` })
    } else if (field.name === 'referrer') {
      newValue = patientMapping[value] && formatName(patientMapping[value].name)
    } else if (field.name === 'infosource' &&
      !['戶外廣告', '報章雜誌', '官網', 'Facebook官方帳號', '部落格', 'Google', '宣傳品(體驗卷,DM)', '生活圈', '醫師自帶', 'Instagram'].includes(value)) {
      newValue = `${formatMessage({ id: `patient.profile.${field.name}.other` })}: ${value}`
    }

    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative', mt: '5px' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    } else if (field.type === 'status') {
      return <Grid item key={field.name} xs={3} sm={field.sm} md={2} sx={{ position: 'relative' }}>
        <Chip sx={{ backgroundColor: field.color, color: '#fff', fontSize: '15px', minWidth: '100px' }} label={formatMessage({ id: `patient.profile.status.${field.name}` })} />
      </Grid>
    } else if (field.type === 'storedValue') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={field.name}
          value={field.value}
          variant="standard"
          fullWidth
          size="small"
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    }

    if (field.roots) {
      labelText = formatMessage({ id: `patient.profile.${field.name}.roots` })
    } else if (field.benefit) {
      labelText = formatMessage({ id: `patient.profile.benefit.${field.name}` })
    } else {
      labelText = formatMessage({ id: `patient.profile.${field.name}` })
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        variant="standard"
        fullWidth
        size="small"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field, patient[field.name], patient))}
      </Grid>
    </div>
  );
}

ReviewPatient.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default ReviewPatient;
