import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from '../../../modules/sort';
import EnhancedTableRow from '../../../components/EnhancedTableRow';
import EnhancedTableHead from '../../../components/EnhancedTableHead';

function CertificateOfDiagnosisList({ kardexes, userMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const filterKardex = []
  if (filterKardex.length === 0) {
    Object.keys(kardexes).forEach(k => {
      if (kardexes[k].certificateOfDiagnosis) {
        filterKardex.push(kardexes[k])
      }
    })
  }

  const headerCells = [
    { text: 'displayId', sort: 'displayId' },
    { text: 'date', sort: 'date' },
    { text: 'issuanceDate', sort: 'issuanceDate' },
    { text: 'doctor', sort: 'doctor' },
  ].map(c => { c.text = formatMessage({ id: `certificateOfDiagnosis.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'displayId' },
    { field: 'date' },
    { field: 'issuanceDate' },
    { field: 'doctor' },
  ]

  const formatData = (kardex) => {
    const newData = { ...kardex }
    newData.doctor = userMapping[newData.doctor]?.displayName
    newData.issuanceDate = newData.certificateOfDiagnosis.issuanceDate

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function openCertificateOfDiagnosis(kardex) {
    const storageRef = firebase.storage().ref('certificates')
    try {
      storageRef.child(`${kardex.id}/${kardex.certificateOfDiagnosis.pdf}`).getDownloadURL().then((url) => {
        window.open(url, '_blank')
      }, () => {
        alert('Certificate Of Diagnosis file not found')
      })
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif', marginTop: '10px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filterKardex.length}
              />
              <TableBody>
                {stableSort(filterKardex.map(r => formatData(r)), getComparator(order, orderBy)).map(kardex => (
                  <EnhancedTableRow
                    key={kardex.id}
                    rowCells={rowCells}
                    cellData={kardex}
                    onRowClick={() => openCertificateOfDiagnosis(kardex)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

CertificateOfDiagnosisList.propTypes = {
  kardexes: PropTypes.arrayOf(PropTypes.object.isRequired),
  userMapping: PropTypes.object.isRequired
};

export default CertificateOfDiagnosisList;
