import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import InputMask from 'react-input-mask';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';

import ContextStore from '../../modules/context';
import ImageUpload from '../../components/ImageUpload'
import DatePickerField from '../../components/DatePickerField';
import OccupationMapping from '../../enum/OccupationMapping';
import ButtonProgress from '../../components/ButtonProgress'

const genders = ['male', 'female'];
const positions = ['management', 'employee'];
const departments = ['nurse', 'salesRep', 'customerService', 'doctor', 'management', 'marketing', 'hr', 'financial', 'itDept', 'nutritionist', 'psychologist'];
const appointmentTypes = ['beauty', 'beautyTreatment', 'gynecology', 'neurology', 'plastic', 'preventative']

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '-20px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const UnderLine = styled('div')(() => ({
  position: 'absolute',
  top: '8px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function EditStaffPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const { staffId } = useParams()
  const history = useHistory();
  const [loadingApprove, setLoadingApprove] = useState(false);
  const defaultData = {
    active: true,
    name: '',
    gender: '',
    employeeId: '',
    email: '',
    department: '',
    position: '',
    onBoardingDate: dayjs().format('YYYY-MM-DD'),
    onBoardingDateOld: dayjs().format('YYYY-MM-DD'),
    appointmentType: '',
    smallStamp: '',
    bigStamp: '',
    certificateStamp: '',
    avatarUrl: '',
    occupation: '',
  }
  const defaultProfileData = {
    phone: '',
    address: '',
    birthDate: '',
    identityCardNumber: '',
    emergencyName: '',
    emergencyPhone: '',
    note: '',
    fullTime: ''
  }
  const [data, setData] = useState(defaultData)
  const [profileData, setProfileData] = useState(defaultProfileData)
  let staffData = {}

  useEffect(() => {
    const breadcrumbs = [{
      link: '/staffManagement/staff',
      text: formatMessage({ id: 'sideMenu.staffManagement.staff' })
    }]
    if (staffId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'staff.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'staff.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [history.location.pathname,]);

  useEffect(() => {
    if (staffId !== 'new') {
      const ref = firebase.database().ref('users').child(staffId)
      const onDataChange = ref.on('value', snapshot => {
        const snapshots = formatData(snapshot.val())

        setData(staffData => ({
          ...staffData,
          ...snapshots
        }))
      });
      return () => ref.off('value', onDataChange)
    }
  }, []);

  useEffect(() => {
    const unsubscribe = staffId !== 'new' ? firebase.firestore().collection('userProfiles').doc(staffId).onSnapshot(snapshot => {
      setProfileData(data => ({
        ...data,
        ...snapshot.data()
      }))
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, []);

  if (!data || !profileData) {
    return ('Loading...')
  } else {
    staffData = { ...data, ...profileData }
  }

  function formatData(data) {
    let newData = data
    newData.name = newData.displayName
    newData.position = newData.isManagement ? 'management' : 'employee'
    newData.appointmentType = newData.appointmentType ? newData.appointmentType : ''
    newData.onBoardingDateOld = newData.onBoardingDateOld ? newData.onBoardingDateOld : dayjs().format('YYYY-MM-DD')

    return newData
  }

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)

    if (['fullTime', 'birthDate'].includes(field.name)) {
      if (!value) {
        newValue = ''
      }
    }

    let newData = {}
    if (['phone', 'address', 'birthDate', 'identityCardNumber', 'emergencyName', 'emergencyPhone', 'note', 'fullTime'].includes(field.name)) {
      newData = { ...profileData, [field.name]: newValue, [`${field.name}_err`]: err }
      setProfileData(newData)
    } else {
      newData = { ...staffData, [field.name]: newValue, [`${field.name}_err`]: err }
      setData(newData)
    }
  }

  function onPhoneChanged(field, value) {
    updateData({ name: `${field.name}` }, value);
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }
  const numberRule = /[^0-9]/g
  const _fields = [
    { name: 'name', sm: 6, required: true, order: 0 },
    { name: 'gender', sm: 6, roots: true, select: true, required: true, order: 1 },
    { name: 'employeeId', sm: 6, required: true, order: 2 },
    { name: 'email', sm: 6, required: true, order: 3 },
    { name: 'typeline', type: '-', order: 4 },
    { name: 'department', sm: 6, roots: true, select: true, required: true, order: 5 },
    { name: 'position', sm: 6, roots: true, select: true, required: true, order: 7 },
    { name: 'dateline', type: '-', order: 9 },
    { name: 'onBoardingDate', sm: 3, md: 3, required: true, type: 'time', order: 10 },
    { name: 'profileLine', type: '-', order: 12 },
    { name: 'phone', type: 'phone', sm: 6, md: 3, allowCharacter: numberRule, mask: '9999-999-999', order: 13 },
    { name: 'birthDate', sm: 6, md: 3, type: 'time', order: 14 },
    { name: 'identityCardNumber', sm: 6, md: 3, order: 15 },
    { name: 'fullTime', sm: 6, md: 3, type: 'time', order: 16 },
    { name: 'address', multiline: true, sm: 12, md: 6, order: 17 },
    { name: 'note', multiline: true, sm: 12, md: 6, order: 18 },
    { name: 'emergencyContacts', type: '-', order: 19 },
    { name: 'emergencyName', sm: 6, md: 3, order: 20 },
    { name: 'emergencyPhone', type: 'phone', sm: 6, md: 3, allowCharacter: numberRule, mask: '9999-999-999', order: 21 },
  ]

  if (['reborn', 'lexcellence'].includes(process.env.BRANCH_ENV)) {
    _fields.push({ name: 'onBoardingDateOld', sm: 3, multiline: false, md: 3, required: true, order: 11, type: 'time' })
  }

  if (!data.active) {
    _fields.push({ name: 'endOn', sm: 3, multiline: false, md: 3, required: true, order: 11, type: 'time' })
  }

  if (staffData.department === 'doctor') {
    _fields.push({ name: 'appointmentType', sm: 6, roots: true, select: true, multiline: false, required: true, md: 3, order: 8 })
  } else if (staffData.department && ['nurse', 'salesRep', 'customerService'].includes(staffData.department)) {
    _fields.push({ name: 'occupation', sm: 6, select: true, multiline: false, md: 3, order: 6 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field) {
    let newValue = staffData[field.name]
    let labelText = ''
    let selectFields;


    if (field.type === '-') {
      if (field.name === 'emergencyContacts') {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <UnderLine>
            <Typography color="textSecondary" variant="caption">{formatMessage({ id: `staff.profile.${field.name}` })}</Typography>
          </UnderLine>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    } else if (field.type === 'time') {
      labelText = formatMessage({ id: `staff.profile.${field.name}` })

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md} sx={{ mt: '3px' }}>
        <DatePickerField
          fullWidth
          closeToolbar={field.name === 'birthDate' ? false : true}
          margin="dense"
          required={field.required}
          label={labelText}
          value={newValue !== '' ? dayjs(newValue) : null}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'phone') {
      labelText = formatMessage({ id: `staff.profile.${field.name}` })

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <InputMask
          mask={field.mask}
          maskChar=" "
          onChange={e => { onPhoneChanged(field, e.target.value) }}
          value={staffData[field.name]}
        >
          {() => <TextField
            type="text"
            size="small"
            label={labelText}
            margin="dense"
            variant="outlined"
            onCompositionStart={
              e => {
                e.target.addEventListener('input', e2 => {
                  if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                    e2.preventDefault()
                    e2.stopPropagation()
                    e2.stopImmediatePropagation()
                  }
                }, { once: true })
              }
            }
            error={staffData[`${field.name}_err`] ? true : false}
            helperText={staffData[`${field.name}_err`]}
            fullWidth
          />}
        </InputMask>
      </Grid>
    }

    if (field.roots) {
      labelText = formatMessage({ id: `staff.profile.${field.name}.roots` })
    } else {
      labelText = formatMessage({ id: `staff.profile.${field.name}` })
    }

    if (['gender', 'department', 'position', 'appointmentType'].includes(field.name)) {
      if (field.name === 'gender') {
        selectFields = genders
      } else if (field.name === 'department') {
        selectFields = departments
      } else if (field.name === 'position') {
        selectFields = positions
      } else {
        selectFields = appointmentTypes
      }
    } else if (field.name === 'occupation') {
      selectFields = OccupationMapping.filter(o => o.type === process.env.BRANCH_ENV ||
        (process.env.BRANCH_ENV === 'lexcellence' && o.type === 'reborn') ||
        (process.env.BRANCH_ENV === 'ibeautyTaichung' && o.type === 'ibeauty')
      )

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={labelText}
          value={newValue}
          required={field.required}
          fullWidth
          select={field.select ? field.select : null}
          size="small"
          margin="dense"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          error={staffData[`${field.name}_err`] ? true : false}
          helperText={staffData[`${field.name}_err`]}
        >
          {
            field.select && selectFields.map((selects, idx) => {
              return <MenuItem key={`${idx}`} value={selects.value}>{selects.name}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    }

    return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        required={field.required}
        fullWidth
        select={field.select ? field.select : null}
        size="small"
        margin="dense"
        variant="outlined"
        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        error={staffData[`${field.name}_err`] ? true : false}
        helperText={staffData[`${field.name}_err`]}
      >
        {
          field.select && selectFields.map((selects, idx) => {
            if (['santea'].includes(process.env.BRANCH_ENV) && field.name === 'department') {
              return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `staff.profile.departmentSantea.${selects}` })}</MenuItem>
            } else {
              return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `staff.profile.${field.name}.${selects}` })}</MenuItem>
            }
          })
        }
      </TextField>
    </Grid>
  }

  async function onSaveStaff(userId) {
    setLoadingApprove(true)
    let err = {}
    let newData = staffData

    for (let field of fields) {
      if (field.required && staffData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...staffData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setData(newData)
      return setLoadingApprove(false)
    }

    const userData = {
      active: staffData.active,
      department: staffData.department,
      displayName: staffData.name,
      email: staffData.email,
      employeeId: staffData.employeeId,
      gender: staffData.gender,
      isManagement: staffData.position === 'management' ? true : false,
      onBoardingDate: dayjs(staffData.onBoardingDate).format('YYYY-MM-DD'),
      onBoardingDateOld: staffData.onBoardingDateOld && dayjs(staffData.onBoardingDateOld).format('YYYY-MM-DD'),
      appointmentType: staffData.appointmentType && staffData.appointmentType,
      smallStamp: staffData.smallStamp && staffData.smallStamp,
      bigStamp: staffData.bigStamp && staffData.bigStamp,
      certificateStamp: staffData.certificateStamp && staffData.certificateStamp,
      announcement: staffData.announcement ? staffData.announcement : 0,
      avatarUrl: staffData.avatarUrl,
      occupation: staffData.occupation,
    }

    if (!staffData.active) {
      userData.endOn = dayjs(staffData.endOn).format('YYYY-MM-DD')
    }

    let profileData = {
      phone: staffData.phone,
      address: staffData.address,
      identityCardNumber: staffData.identityCardNumber,
      emergencyName: staffData.emergencyName,
      emergencyPhone: staffData.emergencyPhone,
    }

    if (staffData.fullTime !== '') {
      profileData.fullTime = dayjs(staffData.fullTime).format('YYYY-MM-DD')
    }

    if (staffData.birthDate !== '') {
      profileData.birthDate = dayjs(staffData.birthDate).format('YYYY-MM-DD')
    }

    if (!['reborn', 'lexcellence'].includes(process.env.BRANCH_ENV)) {
      delete userData.onBoardingDateOld
    }

    if (staffData.department !== 'doctor') {
      delete userData.appointmentType
    }

    if (userId === 'new') {
      const newData = {
        email: staffData.email,
        password: Math.random().toString(16),
        displayName: staffData.name,
        disabled: !staffData.active,
      }

      try {
        const res = await (firebase.functions().httpsCallable('createAuthentication'))({ id: userId, ...newData })
        if (res.data) {

          try {
            await (firebase.functions().httpsCallable('saveUsers'))({ id: res.data.uid, profile: { ...profileData }, ...userData })
            setLoadingApprove(false)
            history.push('/staffManagement/staff')
          } catch (ex) {
            setLoadingApprove(false)
            console.log(ex)
          }
        }
      } catch (ex) {
        setLoadingApprove(false)
        console.log(ex)
      }
    } else {
      try {
        await (firebase.functions().httpsCallable('saveUsers'))({ id: userId, profile: { ...profileData }, ...userData })
        setLoadingApprove(false)
        history.push('/staffManagement/staff')
      } catch (ex) {
        setLoadingApprove(false)
        console.log(ex)
      }
    }
  }

  return (
    <div style={{ backgroundColor: '#f2f2f2', padding: '50px 30px', background: '#fff', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{formatMessage({ id: 'staff.profile.information' })}</Typography>
          </DividerText>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
            {formatMessage({ id: 'staff.table.inactive' })}
            <Switch
              checked={staffData.active}
              onChange={e => updateData({ name: 'active' }, e.target.checked)}
              color="primary"
              name="active"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            {formatMessage({ id: 'staff.table.active' })}
          </div>
        </Grid>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          {fields.map(field => createField(field))}
          <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative', marginTop: '10px' }}>
            <Divider />
            <UnderLine>
              <Typography color="textSecondary" variant="caption">{formatMessage({ id: 'staff.profile.stamps.roots' })}</Typography>
            </UnderLine>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={1} sx={{ fontSize: '13px', color: '#828a99' }}>
            <Grid item xs={12} sm={12} md={3}>
              {formatMessage({ id: 'staff.profile.stamps.smallStamp' })}
              <ImageUpload
                currentImageUrl={staffData.smallStamp}
                storagePath={`/stamps/${staffId}`}
                onChange={(url) => updateData({ name: 'smallStamp' }, url)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              {formatMessage({ id: 'staff.profile.stamps.bigStamp' })}
              <ImageUpload
                currentImageUrl={staffData.bigStamp}
                storagePath={`/stamps/${staffId}`}
                onChange={(url) => updateData({ name: 'bigStamp' }, url)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              {formatMessage({ id: 'staff.profile.stamps.certificateStamp' })}
              <ImageUpload
                currentImageUrl={staffData.certificateStamp}
                storagePath={`/stamps/${staffId}`}
                onChange={(url) => updateData({ name: 'certificateStamp' }, url)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              {formatMessage({ id: 'staff.profile.stamps.profile' })}
              <ImageUpload
                currentImageUrl={staffData.avatarUrl}
                storagePath={`/profileImages/${staffId}`}
                onChange={(url) => updateData({ name: 'avatarUrl' }, url)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative', marginTop: '50px' }}>
          <Divider />
          <UnderLine>
            <Typography color="textSecondary" variant="caption"></Typography>
          </UnderLine>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <ButtonProgress
              handleClose={() => history.push('/staffManagement/staff')}
              handleClick={() => onSaveStaff(staffId ? staffId : 'new')}
              loading={loadingApprove}
              buttonText='button.save'
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default EditStaffPage;
