import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import InputMask from 'react-input-mask';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';

import FabComponent from '../../components/FabComponent'
import DateRangePickerField from '../../components/DateRangePickerField';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import DatePickerField from '../../components/DatePickerField'
import ContextStore from '../../modules/context';
import ButtonProgress from '../../components/ButtonProgress'

const departmentType = ['all', 'nurse', 'salesRep', 'customerService', 'doctor', 'management', 'marketing', 'hr', 'financial', 'itDept', 'nutritionist', 'psychologist']
const pcExceptionType = ['off', 'on']


function DeletePunchClockExceptionDialog({ handleClose, pcExceptionId, pcExceptionDate }) {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)

  async function onDelete() {
    setLoading(true)
    try {
      await (firebase.functions().httpsCallable('savePunchClockException'))({ id: pcExceptionId, delete: true })
      setLoading(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'punchClockException.dialog.deleteTitle' })}</DialogTitle>
        <DialogContent sx={{ color: 'red' }}>
          {formatMessage({ id: 'punchClockException.dialog.deleteMsg' }, { date: pcExceptionDate })}
        </DialogContent>
        <DialogActions>
          <ButtonProgress handleClick={() => onDelete()} handleClose={handleClose} loading={loading} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeletePunchClockExceptionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  pcExceptionId: PropTypes.string.isRequired,
  pcExceptionDate: PropTypes.string.isRequired
};

function EditPunchClockExceptionDialog({ handleClose, data, type }) {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const punchClockExceptionId = !data.id ? 'new' : data.id
  const defultPcExceptionData = punchClockExceptionId === 'new' ? {
    date: dayjs(),
    startTime: '',
    endTime: '',
    reason: '',
    type: 'on',
    department: [],
    publicHoliday: false
  } : {
    date: data.date,
    startTime: data.startTime,
    endTime: data.endTime,
    reason: data.reason,
    type: data.type,
    department: data.department,
    publicHoliday: data.publicHoliday
  }
  const [pcExceptionData, setpcExceptionData] = useState(defultPcExceptionData)
  const [typeText, setTypeText] = useState(data.departmentName || '')
  const [checked, setChecked] = useState(data.department || [0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)
    let newData = { ...pcExceptionData, [field.name]: newValue, [`${field.name}_err`]: err }

    if (field.name === 'startDate') {
      newData.endDate = newData.startDate
    }

    setpcExceptionData(newData)
  }

  const handleSave = async () => {
    setLoading(true)
    let err = {}
    let newData = pcExceptionData

    for (let field of fields) {
      if (field.required && pcExceptionData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...pcExceptionData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setpcExceptionData(newData)
      return setLoading(false)
    }

    let departments = []
    for (let d of checked) {
      if (d !== 0 && d !== 'add') {
        departments.push(d)
      }
    }

    let updateData = {}
    if (punchClockExceptionId === 'new') {
      updateData = {
        startDate: dayjs(newData.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(newData.endDate).format('YYYY-MM-DD'),
        startTime: newData.startTime,
        endTime: newData.endTime,
        reason: newData.reason,
        department: departments,
        type: newData.type
      }
    } else {
      updateData = {
        date: dayjs(newData.date).format('YYYY-MM-DD'),
        startTime: newData.startTime,
        endTime: newData.endTime,
        reason: newData.reason,
        department: departments,
        type: newData.type
      }
    }

    if (newData.type === 'off') {
      updateData.publicHoliday = newData.publicHoliday
    }

    try {
      await (firebase.functions().httpsCallable('savePunchClockException'))({ id: punchClockExceptionId, ...updateData })
      setLoading(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      if (value === 'all') {
        for (const d of departmentType) {
          newChecked.push(d)
        }
      } else {
        newChecked.push(value);
      }
    } else {
      if (value === 'all') {
        newChecked.splice(newChecked)
      } else {
        newChecked.splice(currentIndex, 1);
      }
    }

    let newText = ''
    const newFilter = newChecked.filter(s => s !== 'all' && departmentType.includes(s))
    newText = newFilter.map(x => formatMessage({ id: `punchClockException.dialog.department.${x}` })).join(', ')

    if (type === 'edit') {
      updateData({ name: 'department', newChecked })
    }

    setTypeText(newText)
    setChecked(newChecked);
  };

  function onPCExceptionChanged(field, value) {
    updateData({ name: `${field.name}` }, value);
  }

  const _fields = [
    { name: 'startTime', sm: 6, md: 3, order: 2, type: 'time', mask: '99:99' },
    { name: 'endTime', sm: 6, md: 3, order: 3, type: 'time', mask: '99:99' },
    { name: 'type', sm: 6, md: 6, select: true, roots: true, order: 4 },
    { name: 'reason', sm: 12, md: 6, order: 5, required: true },
    { name: 'department', sm: 12, md: 12, type: 'department', order: 6 },
  ]

  if (punchClockExceptionId === 'new') {
    _fields.push({ name: 'startDate', sm: 6, md: 3, type: 'date', order: 0 })
    _fields.push({ name: 'endDate', sm: 6, md: 3, type: 'endDate', order: 1 })
  } else {
    _fields.push({ name: 'date', sm: 6, md: 3, type: 'date', order: 0 })
  }

  const fields = _fields.map(field => {
    field.multiline = field.multiline || false; field.md = field.md || 3; return field
  }).sort((a, b) => a.order - b.order)

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    if (field.name === 'startTime' || field.name === 'endTime') {
      const newTime = String(value).split(':')
      if (newTime[0] > '24' || newTime[1] > '60') {
        return formatMessage({ id: 'form.date.formatError' })
      }
    }

    if (field.name === 'endTime' && pcExceptionData.startTime && pcExceptionData.startTime > value && value !== ':') {
      return formatMessage({ id: 'form.date.formatError' })
    }

    return ''
  }

  function createField(field) {
    let newValue = pcExceptionData[field.name]
    let labelText = ''
    let selectFields;

    if (field.type === '-') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    } else if (field.type === 'date') {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}` })

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          required
          fullWidth
          label={labelText}
          value={dayjs(newValue)}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'endDate') {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}` })

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          required
          fullWidth
          label={labelText}
          value={dayjs(newValue)}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
          maxDateMessage={formatMessage({ id: 'form.date.afterToday' })}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
          minDate={dayjs(pcExceptionData.startDate).toDate()}
        />
      </Grid>
    } else if (field.type === 'department') {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}.roots` })

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={labelText}
          value={typeText}
          fullWidth
          size="small"
          variant="outlined"
          onClick={handleClick}
        />
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          open={open}
          onClose={() => setAnchorEl(null)}
          TransitionComponent={Fade}
        >
          <List>
            {departmentType.map((value, idx) => {
              return (
                <ListItem key={`${value}-${idx}`} dense onClick={handleToggle(value)}>
                  <Checkbox
                    sx={{ padding: '0 4px' }}
                    edge="start"
                    size="small"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                  {formatMessage({ id: `punchClockException.dialog.department.${value}` })}
                </ListItem>
              );
            })}
          </List>
        </Menu>
      </Grid>
    } else if (field.type === 'time') {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}` })

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <InputMask
          mask={field.mask}
          maskChar=" "
          onChange={e => { onPCExceptionChanged(field, e.target.value) }}
          value={pcExceptionData[field.name]}
        >
          {() => <TextField
            multiline={field.multiline}
            type="text"
            label={labelText}
            fullWidth
            size="small"
            variant="outlined"
            onCompositionStart={
              e => {
                e.target.addEventListener('input', e2 => {
                  if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                    e2.preventDefault()
                    e2.stopPropagation()
                    e2.stopImmediatePropagation()
                  }
                }, { once: true })
              }
            }
            error={pcExceptionData[`${field.name}_err`] ? true : false}
            helperText={pcExceptionData[`${field.name}_err`]}
          />}
        </InputMask>
      </Grid>
    }

    if (field.roots) {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}.roots` })
    } else {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}` })
    }

    if (field.name === 'type') {
      selectFields = pcExceptionType
    }

    return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        required={field.required}
        value={newValue}
        fullWidth
        select={field.select ? field.select : null}
        size="small"
        variant="outlined"
        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        error={pcExceptionData[`${field.name}_err`] ? true : false}
        helperText={pcExceptionData[`${field.name}_err`]}
      >
        {
          field.select && selectFields.map((selects, idx) => {
            return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `punchClockException.dialog.${field.name}.${selects}` })}</MenuItem>
          })
        }
      </TextField>
    </Grid>
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {formatMessage({ id: 'punchClockException.dialog.title' })}
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          {pcExceptionData.type === 'off' && <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
            {formatMessage({ id: 'punchClockException.dialog.normalHoliday' })}
            <Switch
              checked={pcExceptionData.publicHoliday}
              onChange={e => updateData({ name: 'publicHoliday' }, e.target.checked)}
              color="primary"
              name="active"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            {formatMessage({ id: 'punchClockException.dialog.publicHoliday' })}
          </Grid>}
          {fields.map(field => createField(field))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonProgress handleClose={handleClose} handleClick={handleSave} loading={loading} buttonText='button.save' />
      </DialogActions>
    </Dialog>
  );
}

EditPunchClockExceptionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

function PunchClockException() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(dayjs().add(3, 'months').format('YYYY-MM') + `${dayjs().add(3, 'months').daysInMonth()}`)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [punchClockException, setPunchClockException] = useState([])
  const [openDialog, setOpenDialog] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(null)

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.staffManagement.punchClockException' })
    }])
    return () => {
    };
  }, [history.location.pathname]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('punchClockExceptions').where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(snapshot => {
      const punchClockException = []
      snapshot.forEach(doc => {
        punchClockException.push({ ...doc.data(), id: doc.id })
      });
      setPunchClockException(punchClockException)
    }, err => { })

    return () => unsubscribe()
  }, [startDate, endDate]);

  const headerCells = [
    { text: 'date', sort: 'date' },
    { text: 'time', sort: 'time' },
    { text: 'type', sort: 'type' },
    { text: 'reason', sort: 'reason' },
    { text: 'department', sort: 'department' },
  ].map(c => { c.text = formatMessage({ id: `punchClockException.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'date' },
    { field: 'time' },
    { field: 'typeName' },
    { field: 'reason' },
    { field: 'departmentName' },
  ]

  const formatData = (pcException) => {
    const newData = { ...pcException }
    newData.time = `${newData.startTime}-${newData.endTime}`
    newData.typeName = newData.type === 'off' ? '補假' : '補班'
    newData.departmentName = newData.department.filter(d => d !== 'all').map(s => formatMessage({ id: `punchClockException.dialog.department.${s}` })).join(', ')

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div style={{ width: '100%', height: '100%', fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif', padding: '40px 20px' }}>
      {openDialog && <EditPunchClockExceptionDialog
        handleClose={() => setOpenDialog(null)}
        data={openDialog.data}
        type={openDialog.type}
      />}
      {openDeleteDialog && <DeletePunchClockExceptionDialog
        pcExceptionId={openDeleteDialog.id}
        pcExceptionDate={openDeleteDialog.date}
        handleClose={() => setOpenDeleteDialog(null)}
      />}
      <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Grid item xs={12} sm={5} md={5}>
          <DateRangePickerField
            startDate={startDate}
            endDate={endDate}
            onStartDateChanged={setStartDate}
            onEndDateChanged={setEndDate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '10px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={punchClockException.length}
                actionButton
              />
              <TableBody>
                {stableSort(punchClockException.map(r => formatData(r)), getComparator(order, orderBy)).map(pcException => (
                  <EnhancedTableRow
                    key={pcException.id}
                    rowCells={rowCells}
                    cellData={pcException}
                    onRowClick={() => { }}
                    actionIcons={<>
                      {!pcException.void && <span>
                        <Tooltip title={formatMessage({ id: 'button.edit' })}>
                          <IconButton
                            onClick={() => setOpenDialog({ type: 'edit', data: pcException })}
                            size="large">
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={formatMessage({ id: 'button.delete' })}>
                          <IconButton onClick={() => setOpenDeleteDialog({ id: pcException.id, date: pcException.date })}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </span>}
                    </>}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <div>
          <FabComponent onClick={() => setOpenDialog({ type: 'add', data: {} })} />
        </div>
      </Grid>
    </div >
  );
}

export default PunchClockException;
