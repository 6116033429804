const i18n = {
  'analytics': {
    'statistics': {
      'title': '數據統計',
      'chartTitle': {
        'turnover': '營業額',
        'customerFlow': '客流量',
        'transactionRate': '成交率',
        'customerTransaction': '客單價',
      }
    }

  }
}
export default i18n