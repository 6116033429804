import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ProgressStep from '../../components/ProgressStep'

function OvertimeListView({ overtimes, currentUser }) {
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.firebase.data.users);
  const [overtimeBalance, setOvertimeBalance] = useState([])
  const overtimeHistory = (overtimes.history || []).map(h => ({ ...h }))
  const [pendingOvertimes, setPendingOvertimes] = useState([])
  const currentStep = overtimeHistory.length > 0 ? overtimeHistory[overtimeHistory.length - 1].step : 0

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('overtimeBalances').doc(overtimes.createdBy).onSnapshot(snapshot => {
      setOvertimeBalance(snapshot.data())
    }, err => { })

    return () => unsubscribe()
  }, [overtimes.createdBy]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('overtimes')
      .where('status', '==', 'pending')
      .where('createdBy', '==', currentUser.key)
      .onSnapshot(snapshot => {
        let snapshots = []
        snapshot.forEach(doc => {
          snapshots.push({ id: doc.id, ...doc.data() })
        })
        setPendingOvertimes(snapshots)
      }, err => { })

    return () => unsubscribe()
  }, [currentUser.key]);

  function timeRangeToPeriods() {
    const startDate = dayjs(overtimes.startDate).format('YYYY-MM-DD')
    const startTime = overtimes.startTime
    const endDate = dayjs(overtimes.endDate).format('YYYY-MM-DD')
    const endTime = overtimes.endTime
    const start = `${startDate} ${startTime}`;
    const end = `${endDate} ${endTime}`;
    const reg = /\d+-\d+-\d+ \d+:\d+/;

    if (reg.test(start) && reg.test(end)) {
      const startM = dayjs(start, 'YYYY-MM-DD HH:mm');
      const endM = dayjs(end, 'YYYY-MM-DD HH:mm');
      dayjs.extend(duration)
      const minutes = dayjs.duration(endM.diff(startM)).asMinutes();
      var periods = 0;
      if (minutes >= 30) {
        periods = Math.floor(Math.floor(minutes / 15) / 2.0);
      }
      return periods;
    }
  }

  const hoursToPeriods = timeRangeToPeriods();

  const BalanceRow = () => {
    if (overtimeBalance.pay === undefined || overtimeBalance.leave === undefined) return null;
    let signing = {
      pay: 0,
      leave: 0,
      shift: 0
    }
    for (const s of pendingOvertimes) {
      signing[s.type.replace('overtime_', '')] += s.hours
    }

    let pay = overtimeBalance.pay.balance * 2
    let leave = overtimeBalance.leave.balance * 2
    let shift = overtimeBalance.shift.balance
    let signPay = signing.pay * 2
    let sigLeave = signing.leave * 2
    let periods = parseFloat(hoursToPeriods);
    let overtime = pay + leave + signPay + sigLeave;

    if (overtimes && !['approved', 'rejected'].includes(overtimes.status)) {
      if (overtimes.overtimeType === 'overtime_pay') {
        pay = `${pay + signPay + periods}`;
      } else if (overtimes.overtimeType === 'overtime_leave') {
        leave = `${leave + sigLeave + periods}`;
      } else if (overtimes.overtimeType === 'overtime_shift') {
        let shiftperiods = 1
        shift = `${shift + signing.shift + shiftperiods}`;
        leave = `${leave + sigLeave + periods}`;
      }
    }

    return (
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.totalOvertime' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.signPay' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.pay' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.signLeave' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.leave' })}</TableCell>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.allowance' })}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">{formatMessage({ id: 'overtimes.overtimeForm.periods' })}</TableCell>
                <TableCell align="left">{overtime}</TableCell>
                <TableCell align="left">{signPay}</TableCell>
                <TableCell align="left">{pay}</TableCell>
                <TableCell align="left">{sigLeave}</TableCell>
                <TableCell align="left">{leave}</TableCell>
                <TableCell align="left">{shift}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>
    )
  };

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))

    log = log.replace(/f{startTime}/g, formatMessage({ id: 'overtimes.table.start' }))
    log = log.replace(/f{startDate}/g, formatMessage({ id: 'overtimes.overtimeForm.startDateTime' }))
    log = log.replace(/f{endTime}/g, formatMessage({ id: 'overtimes.table.end' }))
    log = log.replace(/f{endDate}/g, formatMessage({ id: 'overtimes.overtimeForm.endDateTime' }))
    log = log.replace(/f{reason}/g, formatMessage({ id: 'overtimes.overtimeForm.reason' }))
    log = log.replace(/f{type}/g, formatMessage({ id: 'overtimes.table.type' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), formatMessage({ id: `overtimeType.${m}` }))
    }

    return log
  }

  const ls = overtimes.status !== 'void' ? overtimes.steps.slice(currentStep, overtimes.steps.length).map(s => ({ ...s })) : []
  if (overtimes.status !== 'void' && currentStep === 0) {
    ls[0].name = '編輯 / 作廢'
  }
  const steps = [...overtimeHistory].concat(ls)

  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }

  return (
    <div style={{ width: '100%', }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <ProgressStep
            activeStep={overtimes.history.length}
            steps={steps}
            orie={'horizontal'}
            alternativeLabel={true}
            title={formatMessage({ id: 'overtimes.list.step' })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ fontSize: '15px', color: '#828a99', padding: '10px 0' }}><strong>{formatMessage({ id: 'overtimes.list.balance' })}</strong></div>
          <BalanceRow />
        </Grid>
      </Grid>
    </div>
  )
}

OvertimeListView.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  isAll: PropTypes.bool,
  overtimes: PropTypes.object.isRequired,
};

export default OvertimeListView;
