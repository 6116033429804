import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';

import ContextStore from '../../modules/context';
import { getComparator, stableSort } from '../../modules/sort';
import DatePickerField from '../../components/DatePickerField'
import SimpleTableToolbar from '../../components/SimpleTableToolbar'
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';

function ConsumedDetail({ detailData }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatData = (data) => {
    const newData = { ...data }
    if (newData.kardex) {
      if (newData.prescription) {
        newData.source = newData.prescription === 'normal' ?
          formatMessage({ id: 'report.consumedMerchandise.detail.sourceType.prescription' }) :
          formatMessage({ id: 'report.consumedMerchandise.detail.sourceType.controlledDrugs' })
      } else {
        newData.source = formatMessage({ id: 'report.consumedMerchandise.detail.sourceType.kardex' })
      }

      newData.sourceId = newData.kardexId
    } else if (newData.purchaseOrder) {
      newData.source = formatMessage({ id: 'report.consumedMerchandise.detail.sourceType.purchase' })
      newData.sourceId = newData.purchaseOrderSn
    }

    return newData
  }

  const headerCells = [
    { text: 'unitAmount' },
    { text: 'patient' },
    { text: 'dateTime' },
    { text: 'source' },
    { text: 'sourceId' },
  ].map(c => { c.text = formatMessage({ id: `report.consumedMerchandise.detail.${c.text}` }); return c })

  const rowCells = [
    { field: 'unitAmount' },
    { field: 'patient' },
    { field: 'dateTime' },
    { field: 'source' },
    { field: 'sourceId' },
  ]

  return (
    <Grid container spacing={0} sx={{ padding: '20px' }}>
      <Grid item xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={detailData.length}
            />
            <TableBody>
              {stableSort(detailData.map(p => formatData(p)), getComparator(order, orderBy)).map(data => (
                <EnhancedTableRow
                  key={data.id}
                  rowCells={rowCells}
                  cellData={data}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

ConsumedDetail.propTypes = {
  detailData: PropTypes.arrayOf(PropTypes.object.isRequired)
};

function ConsumedMerchandises({ currentUser }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const { dateType } = useParams()
  const [dateTime, setDateTime] = useState(dayjs().format('YYYY-MM-DD'));
  const [monthTime, setMonthTime] = useState(dayjs().format('YYYY-MM'));
  const [merchandiseMapping, setMerchandiseMapping] = useState({})
  const [currentData, setCurrentData] = useState(null)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: `sideMenu.reports.consume.${dateType}` })
    }])
    return () => {
    };
  }, [history.location.pathname, dateType]);

  useEffect(() => {
    const ref = firebase.database().ref('merchandises')
    const onDataChange = ref.on('value', snapshot => {
      setMerchandiseMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    let startDate = `${dateTime} 00:00:00`
    let endDate = `${dateTime} 23:59:59`
    let customerName = process.env.BRANCH_ENV
    if (customerName === 'lexcellence') {
      customerName = 'reborn'
    }

    if (dateType === 'month') {
      startDate = `${monthTime}-01 00:00:00`
      endDate = `${monthTime}-${dayjs(monthTime).daysInMonth()} 23:59:59`
    }

    (firebase.functions().httpsCallable('getConsumedMerchandises'))({ customerName, startDate, endDate }).then(res => {
      setCurrentData(res.data.data)
    }).catch(ex => {
      console.log(ex)
    })
  }, [dateTime, monthTime, dateType]);


  if (!currentData) {
    return ('Loading...')
  }

  function exportProfile() {
    const rows = []
    const _rows = headerCells.reduce((acc, cur) => {
      acc.push(cur.text)
      return acc
    }, []).concat(['客戶', '消耗時間', '來源', '營收單號/Kardex ID']).join(',')

    const _newData = reduceData.reduce((acc, cur) => {
      for (const c of cur.docData) {
        acc.push({ merchandiseId: cur.id, ...c })
      }

      return acc
    }, [])

    rows.push(_rows)
    const newData = _newData.map(n => formatData(n))
    for (const d of newData) {

      let source = ''
      let sourceId = ''
      if (d.kardex) {
        if (d.prescription) {
          source = d.prescription === 'normal' ?
            formatMessage({ id: 'report.consumedMerchandise.detail.sourceType.prescription' }) :
            formatMessage({ id: 'report.consumedMerchandise.detail.sourceType.controlledDrugs' })
        } else {
          source = formatMessage({ id: 'report.consumedMerchandise.detail.sourceType.kardex' })
        }

        sourceId = d.kardexId
      } else if (d.purchaseOrder) {
        source = formatMessage({ id: 'report.consumedMerchandise.detail.sourceType.purchase' })
        sourceId = d.purchaseOrderSn
      }

      rows.push([
        merchandiseMapping[d.merchandiseId] ? merchandiseMapping[d.merchandiseId].code : '',
        merchandiseMapping[d.merchandiseId] ? merchandiseMapping[d.merchandiseId].name : '',
        merchandiseMapping[d.merchandiseId] ? merchandiseMapping[d.merchandiseId].nickname : '',
        merchandiseMapping[d.merchandiseId] ? merchandiseMapping[d.merchandiseId].sku : '',
        d.unitAmount,
        d.patient,
        d.dateTime,
        source,
        sourceId
      ])
    }

    let fieldName = ''
    if (dateType === 'day') {
      fieldName = `${dateTime}-${formatMessage({ id: `sideMenu.reports.consume.${dateType}` })}_.csv`
    } else {
      fieldName = `${monthTime}月-${formatMessage({ id: `sideMenu.reports.consume.${dateType}` })}_.csv`
    }
    try {
      const content = rows.join('\n')
      const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const a = document.createElement('a')
      a.href = csvUrl
      a.target = '_blank'
      a.download = fieldName
      a.click()
    } catch (error) {
      console.error(error) // eslint-disable-line
      alert('An error occurred. Please refresh and try again.')
    }
  }

  const formatData = (data) => {
    const newData = { ...data }
    newData.code = merchandiseMapping[newData.id] ? merchandiseMapping[newData.id].code : ''
    newData.name = merchandiseMapping[newData.id] ? merchandiseMapping[newData.id].name : ''
    newData.nickname = merchandiseMapping[newData.id] ? merchandiseMapping[newData.id].nickname : ''
    newData.sku = merchandiseMapping[newData.id] ? merchandiseMapping[newData.id].sku : ''
    return newData
  }

  const headerCells = [
    { text: 'code' },
    { text: 'name' },
    { text: 'nickname' },
    { text: 'sku' },
    { text: 'amount' },
  ].map(c => { c.text = formatMessage({ id: `report.consumedMerchandise.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'nickname' },
    { field: 'sku' },
    { field: 'amount' },
  ]

  const reduceCurrentData = currentData.reduce((acc, cur) => {
    for (const m of Object.keys(cur.merchandises)) {
      if (!acc[m]) {
        acc[m] = { amount: cur.merchandises[m], docData: [{ ...cur, unitAmount: cur.merchandises[m] }] }
      } else {
        acc[m].amount += cur.merchandises[m]
        acc[m].docData.push({ ...cur, unitAmount: cur.merchandises[m] })
      }
    }
    return acc
  }, {})

  const reduceData = Object.keys(reduceCurrentData).reduce((acc, cur) => {
    acc.push({ id: cur, ...reduceCurrentData[cur] })
    return acc
  }, [])

  return (
    <Grid container spacing={0} sx={{ marginTop: '10px' }}>
      <Grid item xs={12} sm={12} md={12}>
        <SimpleTableToolbar
          title={`report.consumedMerchandise.title.${dateType}`}
          toolbox={<>
            <IconButton
              sx={{ fontSize: '14px', alignSelf: 'center', color: '#666666' }}
              onClick={() => exportProfile()}
              size="large">
              <GetAppIcon></GetAppIcon>
              {formatMessage({ id: 'button.export' })}
            </IconButton>
            {dateType === 'day' ? <DatePickerField
              required
              label={formatMessage({ id: 'report.date' })}
              value={dayjs(dateTime)}
              onChange={date => setDateTime(dayjs(date).format('YYYY-MM-DD'))}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            /> : <DatePickerField
              required
              label={formatMessage({ id: 'report.month' })}
              value={dayjs(monthTime)}
              inputFormat="YYYY-MMM"
              onChange={date => setMonthTime(dayjs(date).format('YYYY-MM'))}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />}
          </>}
        />
        <TableContainer component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={reduceData.length}
              expandable
            />
            <TableBody>
              {stableSort(reduceData.map(p => formatData(p)), getComparator(order, orderBy)).map(data => (
                <EnhancedTableRow
                  key={data.id}
                  rowCells={rowCells}
                  cellData={data}
                  expandable
                  expandContent={<ConsumedDetail detailData={data.docData} />}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

ConsumedMerchandises.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    announcement: PropTypes.number.isRequired
  }),
};

export default ConsumedMerchandises;
