import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage as FM } from 'react-intl';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';

import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

import TabContainer from '../TabContainer/TabContainer'
import LeaveList from '../../pages/Leaves/LeavesList'
import ReviewLeavesList from '../../pages/Leaves/ReviewLeavesList'
import OvertimeList from '../../pages/Overtimes/OvertimeList'
import ReviewOvertimesList from '../../pages/Overtimes/ReviewOvertimesList'
import PunchClockReviseList from '../../pages/PunchClockRevise/PunchClockReviseList'
import ReviewPunchClockReviseList from '../../pages/PunchClockRevise/ReviewPunchClockReviseList'
import FabComponent from '../../components/FabComponent'

const Root = styled('div')(() => ({
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
}));

const MuiLable = styled('div')(() => ({
  fontSize: '40px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
  flex: 1,
  padding: '0 0 10px 0',
}));

function SecondaryNav({ currentUser, type }) {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [leaves, setLeaves] = useState([])
  const [overtimes, setOvertimes] = useState([])
  const [punchClockRevise, setPunchClockRevise] = useState([])
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const tabName = history.location.pathname

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('leaves').where('status', '==', 'pending').onSnapshot(snapshot => {
      const leaves = []
      snapshot.forEach(doc => {
        leaves.push({ ...doc.data(), id: doc.id })
      });
      setLeaves(leaves)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('overtimes').where('status', '==', 'pending').onSnapshot(snapshot => {
      const overtimes = []
      snapshot.forEach(doc => {
        overtimes.push({ ...doc.data(), id: doc.id })
      });
      setOvertimes(overtimes)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('punchClockRevise').where('status', '==', 'pending').onSnapshot(snapshot => {
      const punchClockRevises = []
      snapshot.forEach(doc => {
        punchClockRevises.push({ ...doc.data(), id: doc.id })
      });
      setPunchClockRevise(punchClockRevises)
    }, err => { })

    return () => unsubscribe()
  }, []);


  const leaveNotifications = leaves.filter(leave => {
    const currentStep = leave.history.length > 0 ? leave.history[leave.history.length - 1].step : 0
    return leave.steps[currentStep].users.includes(currentUser.key)
  })

  const overtimeNotifications = overtimes.filter(overtime => {
    const currentStep = overtime.history.length > 0 ? overtime.history[overtime.history.length - 1].step : 0
    return overtime.steps[currentStep].users.includes(currentUser.key)
  })

  const punchClockReviseNotifications = punchClockRevise.filter(revise => {
    const currentStep = revise.history.length > 0 ? revise.history[revise.history.length - 1].step : 0
    return revise.steps[currentStep].users.includes(currentUser.key)
  })

  useEffect(() => {
    const pathname = history.location.pathname
    if (type === 'owner') {
      if (pathname === '/leaveOvertimeWork/leaves' && activeTabIndex !== 0) {
        setActiveTabIndex(0)
      } else if (pathname === '/leaveOvertimeWork/overtimes' && activeTabIndex !== 1) {
        setActiveTabIndex(1)
      } else if (pathname === '/leaveOvertimeWork/punchClockRevise' && activeTabIndex !== 2) {
        setActiveTabIndex(2)
      }
    } else if (type === 'review') {
      if (pathname === '/leaveOvertimeWork/leaves/review' && activeTabIndex !== 0) {
        setActiveTabIndex(0)
      } else if (pathname === '/leaveOvertimeWork/overtimes/review' && activeTabIndex !== 1) {
        setActiveTabIndex(1)
      } else if (pathname === '/leaveOvertimeWork/punchClockRevise/review' && activeTabIndex !== 2) {
        setActiveTabIndex(2)
      }
    } else if (type === 'all') {
      if (pathname === '/leaveOvertimeWork/leaves/all' && activeTabIndex !== 0) {
        setActiveTabIndex(0)
      } else if (pathname === '/leaveOvertimeWork/overtimes/all' && activeTabIndex !== 1) {
        setActiveTabIndex(1)
      } else if (pathname === '/leaveOvertimeWork/punchClockRevise/all' && activeTabIndex !== 2) {
        setActiveTabIndex(2)
      }
    }
    return () => {
    };
  }, [history.location.pathname]);

  let defaultSelect
  if (type === 'owner') {
    if (tabName === '/leaveOvertimeWork/leaves') {
      defaultSelect = 0
    } else if (tabName === '/leaveOvertimeWork/overtimes') {
      defaultSelect = 1
    } else if (tabName === '/leaveOvertimeWork/punchClockRevise') {
      defaultSelect = 2
    }
  } else if (type === 'review') {
    if (tabName === '/leaveOvertimeWork/leaves/review') {
      defaultSelect = 0
    } else if (tabName === '/leaveOvertimeWork/overtimes/review') {
      defaultSelect = 1
    } else if (tabName === '/leaveOvertimeWork/punchClockRevise/review') {
      defaultSelect = 2
    }
  } else if (type === 'all') {
    if (tabName === '/leaveOvertimeWork/leaves/all') {
      defaultSelect = 0
    } else if (tabName === '/leaveOvertimeWork/overtimes/all') {
      defaultSelect = 1
    } else if (tabName === '/leaveOvertimeWork/punchClockRevise/all') {
      defaultSelect = 2
    }
  }

  const onTabSelected = (tabIndex) => {
    if (type === 'owner') {
      if (tabIndex === 0) {
        history.push('/leaveOvertimeWork/leaves');
      } else if (tabIndex === 1) {
        history.push('/leaveOvertimeWork/overtimes');
      } else if (tabIndex === 2) {
        history.push('/leaveOvertimeWork/punchClockRevise');
      }
    } else if (type === 'review') {
      if (tabIndex === 0) {
        history.push('/leaveOvertimeWork/leaves/review');
      } else if (tabIndex === 1) {
        history.push('/leaveOvertimeWork/overtimes/review');
      } else if (tabIndex === 2) {
        history.push('/leaveOvertimeWork/punchClockRevise/review');
      }
    } else if (type === 'all') {
      if (tabIndex === 0) {
        history.push('/leaveOvertimeWork/leaves/all');
      } else if (tabIndex === 1) {
        history.push('/leaveOvertimeWork/overtimes/all');
      } else if (tabIndex === 2) {
        history.push('/leaveOvertimeWork/punchClockRevise/all');
      }
    }
  };

  const tabs = [];

  if (type === 'owner') {
    tabs.push({ label: formatMessage({ id: 'tab.secondaryNav.leave' }), component: <LeaveList currentUser={currentUser} /> })
    tabs.push({ label: formatMessage({ id: 'tab.secondaryNav.overtime' }), component: <OvertimeList currentUser={currentUser} /> })
    tabs.push({ label: formatMessage({ id: 'tab.secondaryNav.punchClockRevise' }), component: <PunchClockReviseList currentUser={currentUser} /> })
  } else if (type === 'review') {
    tabs.push({
      label: <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="error"
        badgeContent={leaveNotifications.length}
      >
        {formatMessage({ id: 'tab.secondaryNav.reviewLeave' })}
      </Badge>,
      component: < ReviewLeavesList currentUser={currentUser} />
    })
    tabs.push({
      label: <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="error"
        badgeContent={overtimeNotifications.length}
      >
        {formatMessage({ id: 'tab.secondaryNav.reviewOvertime' })}
      </Badge>,
      component: <ReviewOvertimesList currentUser={currentUser} />
    })
    tabs.push({
      label: <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="error"
        badgeContent={punchClockReviseNotifications.length}
      >
        {formatMessage({ id: 'tab.secondaryNav.reviewPunchClockRevise' })}
      </Badge>,
      component: <ReviewPunchClockReviseList currentUser={currentUser} />
    })
  } else if (type === 'all') {
    tabs.push({ label: formatMessage({ id: 'tab.secondaryNav.allLeave' }), component: <ReviewLeavesList currentUser={currentUser} isAll={true} /> })
    tabs.push({ label: formatMessage({ id: 'tab.secondaryNav.allOvertime' }), component: <ReviewOvertimesList currentUser={currentUser} isAll={true} /> })
    tabs.push({ label: formatMessage({ id: 'tab.secondaryNav.allPunchClockRevise' }), component: <ReviewPunchClockReviseList currentUser={currentUser} isAll={true} /> })
  }

  return (
    <Root>
      <FabComponent
        onClick={() => {
          if (activeTabIndex === 0) {
            history.push('/leaveOvertimeWork/leaves/edit/new')
          } else if (activeTabIndex === 1) {
            history.push('/leaveOvertimeWork/overtimes/edit/new')
          } else if (activeTabIndex === 2) {
            history.push('/leaveOvertimeWork/punchClockRevise/edit/new')
          }
        }}
      />
      <MuiLable>
        <FM
          id='leaves.welcome'
          defaultMessage='Hi, {name}'
          values={{
            name: currentUser.displayName
          }}
        />
      </MuiLable>
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
    </Root>
  );
}

SecondaryNav.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  type: PropTypes.string
};

export default SecondaryNav;
