import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import BackdropMui from '@mui/material/Backdrop';

import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import ActionDialog from '../../components/ActionDialog';
import SimpleTableToolbar from '../../components/SimpleTableToolbar'
import DateRangePickerField from '../../components/DateRangePickerField';
import ReviewPunchClockReviseView from './ReviewPunchClockReviseView'

const Backdrop = styled(BackdropMui)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const statusColor = {
  pending: '#c83cc8',
  reject: '#fe2851',
  void: '#fe2851',
  done: '#00b900',
}

function ReviewPunchClockReviseList({ currentUser, isAll }) {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const userMapping = useSelector(state => state.firebase.data.users);
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')

  const [pendingPunchClockRevise, setPendingPunchClockRevise] = useState([])
  const [historPunchClockRevise, sethistorPunchClockRevise] = useState([])
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false)

  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [historyStartDate, setHistoryStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [historyEndDate, setHistoryEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  const [filterText, setFilterText] = useState('')
  const [filterHistoryText, setFilterHistoryText] = useState('')

  useEffect(() => {
    setPendingPunchClockRevise([])
    const unsubscribe = firebase.firestore().collection('punchClockRevise')
      .where('status', '==', 'pending').onSnapshot(snapshot => {
        const punchClockRevises = []
        snapshot.forEach(doc => {
          punchClockRevises.push({ ...doc.data(), id: doc.id })
        });

        const stepUserLeaves = !isAll ? punchClockRevises.filter(punchClockRevise => {
          const currentStep = punchClockRevise.history.length > 0 ? punchClockRevise.history[punchClockRevise.history.length - 1].step : 0
          return punchClockRevise.steps[currentStep].users.includes(currentUser.key)
        }) : punchClockRevises

        setPendingPunchClockRevise(stepUserLeaves)
      }, err => { })

    return () => unsubscribe()
  }, [history.location.pathname]);

  useEffect(() => {
    sethistorPunchClockRevise([])
    const unsubscribe = firebase.firestore().collection('punchClockRevise')
      .where('date', '>=', historyStartDate).where('date', '<=', historyEndDate)
      .where('status', 'in', ['done', 'void', 'reject']).onSnapshot(snapshot => {
        const punchClockRevises = []
        snapshot.forEach(doc => {
          punchClockRevises.push({ ...doc.data(), id: doc.id })
        });
        sethistorPunchClockRevise(punchClockRevises)
      }, err => { })

    return () => unsubscribe()
  }, [historyStartDate, historyEndDate]);


  const formatData = (punchClockRevise) => {
    const newData = { ...punchClockRevise }
    newData.createdBy = userMapping[newData.createdBy].displayName
    newData.createdAt = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD')
    newData.start = `${newData.startDate}`
    newData.statusName = `${formatMessage({ id: `punchClockReviseStatus.${newData.status}` })}`
    newData.end = `${newData.endDate}`
    newData.textColor = statusColor[newData.status]
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  async function handleExecute(data) {
    const { text } = data
    const { filed, punchClockReviseId } = dialogData
    setLoading(true)

    try {
      await (firebase.functions().httpsCallable('savePunchClockRevise'))({
        ...filed,
        id: punchClockReviseId,
        note: text,
        void: true,
      })

      setLoading(false)
    } catch (ex) {
      console.log(ex)
      setLoading(false)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  function HistoryHandleChange(value) {
    setFilterHistoryText(value)
  }

  function handleChange(value) {
    setFilterText(value)
  }

  function getToolbox(isHitory) {
    return <>
      {isHitory ? <Grid item xs={12} sm={12} md={12} >
        <DateRangePickerField
          startDate={isHitory ? historyStartDate : startDate}
          endDate={isHitory ? historyEndDate : endDate}
          onStartDateChanged={isHitory ? setHistoryStartDate : setStartDate}
          onEndDateChanged={isHitory ? setHistoryEndDate : setEndDate}
        />
      </Grid> : <Grid item xs={12} sm={12} md={12} />}
      <Grid item xs={12} sm={4} md={4} style={{ paddingRight: '10px' }}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'searchbox.createdBy' })}
          onChange={(e) => isHitory ? HistoryHandleChange(e.target.value) : handleChange(e.target.value)}
          variant="outlined"
          fullWidth
          value={isHitory ? filterHistoryText : filterText}
        />
      </Grid>
    </>
  }

  const headerCells = [
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'createdAt', sort: 'createdAt' },
    { text: 'date', sort: 'date' },
    { text: 'time', sort: 'time' },
    { text: 'reason', sort: 'reason' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `punchClockRevise.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'createdBy' },
    { field: 'createdAt' },
    { field: 'date' },
    { field: 'time' },
    { field: 'reason' },
    { field: 'statusName', textColor: true },
  ]

  const historyHeaderCells = [
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'date' },
    { text: 'time', sort: 'time' },
    { text: 'reason', sort: 'reason' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `punchClockRevise.table.${c.text}` }); return c })

  const historyRowCells = [
    { field: 'createdBy' },
    { field: 'date' },
    { field: 'time' },
    { field: 'reason' },
    { field: 'statusName', textColor: true },
  ]

  function filterItem(isHistory) {
    if (isHistory) {
      if (filterHistoryText !== '') {
        return historPunchClockRevise.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterHistoryText.toLowerCase()));
      } else {
        return historPunchClockRevise
      }
    } else {
      if (filterText !== '') {
        return pendingPunchClockRevise.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterText.toLowerCase()));
      } else {
        return pendingPunchClockRevise
      }
    }
  }

  const filterHistoryPunchClockRevise = filterItem(true);
  const filterPunchClockRevise = filterItem();

  return (
    <div>
      {loading && <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'punchClockRevise.detail.voidReason' })}
        action={dialogData.action}
      />}
      <Grid container spacing={1} sx={{ mt: '10px', mb: '40px' }}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'punchClockRevise.table.pending'}
            toolbox={getToolbox()}
          />
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                expandable
                onRequestSort={handleRequestSort}
                rowCount={filterPunchClockRevise.length}
              />
              <TableBody>
                {stableSort(filterPunchClockRevise.map(r => formatData(r)), getComparator(order, orderBy)).map(punchClockRevise => (
                  <EnhancedTableRow
                    key={punchClockRevise.id}
                    rowCells={rowCells}
                    cellData={punchClockRevise}
                    expandable
                    expandContent={<ReviewPunchClockReviseView punchClockRevise={punchClockRevise} currentUser={currentUser} isAll={isAll} />}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'punchClockRevise.table.history'}
            toolbox={getToolbox(true)}
          />
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={historyHeaderCells}
                order={order}
                orderBy={orderBy}
                expandable
                onRequestSort={handleRequestSort}
                rowCount={filterHistoryPunchClockRevise.length}
                actionButton
              />
              <TableBody>
                {stableSort(filterHistoryPunchClockRevise.map(r => formatData(r)), getComparator(order, orderBy)).map(punchClockRevise => (
                  <EnhancedTableRow
                    key={punchClockRevise.id}
                    rowCells={historyRowCells}
                    cellData={punchClockRevise}
                    expandable
                    expandContent={<ReviewPunchClockReviseView punchClockRevise={punchClockRevise} isHistory={true} isAll={isAll} />}
                    actionIcons={<>
                      {(!punchClockRevise.void || (punchClockRevise.void && punchClockRevise.status === 'reject'))&& <span>
                        <Tooltip title={formatMessage({ id: 'button.edit' })}>
                          <IconButton
                            onClick={() => history.push(`/leaveOvertimeWork/punchClockRevise/edit/${punchClockRevise.id}`)}
                            size="large">
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={formatMessage({ id: 'button.void' })}>
                          <IconButton
                            onClick={() => setDialogData({ action: 'void', title: '', filed: punchClockRevise, punchClockReviseId: punchClockRevise.id })}
                            size="large">
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </span>}
                    </>}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

ReviewPunchClockReviseList.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  isAll: PropTypes.bool,
};

export default ReviewPunchClockReviseList;
