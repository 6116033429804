const OccupationMapping = [
  { name: '店經理', value: 'storeManager', order: 1, type: 'reborn' },
  { name: '護理長', value: 'nurseLeader', order: 2, type: 'reborn' },
  { name: '客代', value: 'customerRep', order: 3, type: 'reborn' },
  { name: '護理師', value: 'nurse', order: 4, type: 'reborn' },
  { name: '美容師', value: 'beautician', order: 5, type: 'reborn' },
  { name: '客服', value: 'customerService', order: 6, type: 'reborn' },
  { name: '兼職', value: 'partime', order: 89, type: 'reborn' },

  { name: '總監', value: 'director', order: 1, type: 'ibeauty' },
  { name: '店長', value: 'storeManager', order: 2, type: 'ibeauty' },
  { name: '副店長', value: 'deputyStoreManager', order: 3, type: 'ibeauty' },
  { name: '諮詢顧問', value: 'consultant', order: 4, type: 'ibeauty' },
  { name: '諮詢師', value: 'customerCounseling', order: 5, type: 'ibeauty' },
  { name: '美容師', value: 'beautician', order: 6, type: 'ibeauty' },
  { name: '體雕組長', value: 'bodySculptureManager', order: 7, type: 'ibeauty' },
  { name: '護理長', value: 'nurseLeader', order: 8, type: 'ibeauty' },
  { name: '護理師', value: 'nurse', order: 9, type: 'ibeauty' },
  { name: '健髮師', value: 'hairdresser', order: 10, type: 'ibeauty' },
  { name: '櫃檯', value: 'counter', order: 11, type: 'ibeauty' },
  { name: '兼職', value: 'partime', order: 89, type: 'ibeauty' },

  { name: '總監', value: 'director', order: 1, type: 'santea' },
  { name: '店長', value: 'storeManager', order: 2, type: 'santea' },
  { name: '藥師', value: 'pharmacist', order: 3, type: 'santea' },
  { name: '健管師', value: 'healthManager', order: 4, type: 'santea' },
  { name: '櫃檯', value: 'counter', order: 5, type: 'santea' },
  { name: '兼職', value: 'partime', order: 89, type: 'santea' },
];

export default OccupationMapping