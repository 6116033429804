import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import BackdropMui from '@mui/material/Backdrop';

import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import ActionDialog from '../../components/ActionDialog';
import PunchClockReviseListView from './PunchClockReviseListView'

const Backdrop = styled(BackdropMui)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const statusColor = {
  pending: '#c83cc8',
  reject: '#fe2851',
  void: '#fe2851',
  done: '#00b900',
}

function PunchClockReviseList({ currentUser }) {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [revises, setRevises] = useState([])
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('punchClockRevise').where('createdBy', '==', currentUser.key).where('status', '!=', 'void_').onSnapshot(snapshot => {
      const revise = []
      snapshot.forEach(doc => {
        revise.push({ ...doc.data(), id: doc.id })
      });
      setRevises(revise.filter(r => r.date >= `${dayjs().format('YYYY')}-01-01`))
    }, err => { })

    return () => unsubscribe()
  }, [currentUser]);

  const formatData = (revise) => {
    const newData = { ...revise }
    newData.statusName = `${formatMessage({ id: `punchClockReviseStatus.${newData.status}` })}`
    newData.end = `${newData.endDate}`
    newData.createdAt = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD')
    newData.textColor = statusColor[newData.status]
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerCells = [
    { text: 'createdAt', sort: 'createdAt' },
    { text: 'date', sort: 'date' },
    { text: 'time', sort: 'time' },
    { text: 'reason', sort: 'reason' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `punchClockRevise.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'createdAt' },
    { field: 'date' },
    { field: 'time' },
    { field: 'reason' },
    { field: 'statusName', textColor: true },
  ]

  async function handleExecute(data) {
    const { text } = data
    const { filed, punchClockReviseId } = dialogData
    setLoading(true)

    try {
      await (firebase.functions().httpsCallable('savePunchClockRevise'))({
        ...filed,
        id: punchClockReviseId,
        note: text,
        void: true,
      })

      setLoading(false)
    } catch (ex) {
      console.log(ex)
      setLoading(false)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  return (
    <Grid container spacing={1}>
      {loading && <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'punchClockRevise.detail.voidReason' })}
        action={dialogData.action}
      />}
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ paddingBottom: '60px', paddingTop: '20px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                expandable
                onRequestSort={handleRequestSort}
                rowCount={revises.length}
                actionButton
              />
              <TableBody>
                {stableSort(revises.map(r => formatData(r)), getComparator(order, orderBy)).map(revise => (
                  <EnhancedTableRow
                    key={revise.id}
                    rowCells={rowCells}
                    cellData={revise}
                    expandable
                    expandContent={<PunchClockReviseListView punchClockRevise={revise} />}
                    actionIcons={<>
                      {(!revise.void || (revise.void && revise.status === 'reject')) && <span>
                        <Tooltip title={formatMessage({ id: 'button.edit' })}>
                          <IconButton
                            onClick={() => history.push(`/leaveOvertimeWork/punchClockRevise/edit/${revise.id}`)}
                            size="large">
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={formatMessage({ id: 'button.void' })}>
                          <IconButton
                            onClick={() => setDialogData({ action: 'void', title: '', filed: revise, punchClockReviseId: revise.id })}
                            size="large">
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </span>}
                    </>}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Grid>
  );
};

PunchClockReviseList.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

export default PunchClockReviseList;
