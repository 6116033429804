import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import OccupationMapping from '../../enum/OccupationMapping';

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function ReviewStaff({ user }) {
  const { formatMessage } = useIntl()

  const _fields = [
    { name: 'name', sm: 6, order: 0 },
    { name: 'gender', sm: 6, roots: true, order: 1 },
    { name: 'employeeId', sm: 6, order: 2 },
    { name: 'email', sm: 6, order: 3 },
    { name: 'dateline', text: ' ', type: '-', order: 4 },
    { name: 'onBoardingDate', sm: 6, order: 5 },
    { name: 'typeline', text: ' ', type: '-', order: 7 },
    { name: 'department', sm: 6, roots: true, order: 8 },
    { name: 'position', sm: 6, roots: true, order: 10 },
    { name: 'benefitline', text: formatMessage({ id: 'staff.profile.benefit.roots' }), type: '-', order: 12 },
    { name: 'earned', sm: 6, benefit: true, order: 13 },
    { name: 'used', sm: 6, benefit: true, order: 14 },
    { name: 'balance', sm: 6, benefit: true, order: 15 },
  ]

  if (['reborn', 'lexcellence'].includes(process.env.BRANCH_ENV)) {
    _fields.push({ name: 'onBoardingDateOld', sm: 6, multiline: false, md: 3, order: 6 })
  }

  if (user.department === 'doctor') {
    _fields.push({ name: 'appointmentType', sm: 6, roots: true, multiline: false, md: 3, order: 11 })
  } else if (user.department && ['nurse', 'salesRep', 'customerService'].includes(user.department)) {
    _fields.push({ name: 'occupation', sm: 6, multiline: false, md: 3, order: 9 })
  }

  if (!user.active) {
    _fields.push({ name: 'endOn', sm: 6, multiline: false, md: 3, order: 6 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })





  function createField(field, value, user) {
    let newValue = value
    let labelText = ''

    if (field.name === 'department') {
      newValue = user.departmentName
    } else if (field.name === 'occupation') {
      const filterMapping = OccupationMapping.filter(o => (o.type === process.env.BRANCH_ENV || process.env.BRANCH_ENV === 'lexcellence') && o.type === 'reborn')
      filterMapping.forEach(f => {
        if (f.value === newValue) {
          newValue = f.name
        }
      })
    }

    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    }



    if (field.roots) {
      labelText = formatMessage({ id: `staff.profile.${field.name}.roots` })
    } else if (field.benefit) {
      labelText = formatMessage({ id: `staff.profile.benefit.${field.name}` })
    } else {
      labelText = formatMessage({ id: `staff.profile.${field.name}` })
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        fullWidth
        variant="standard"
        size="small"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field, user[field.name], user))}
      </Grid>
    </div>
  );
}

ReviewStaff.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ReviewStaff;
