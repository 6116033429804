import React from 'react'
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import iconPaper from '../static/il-sent.svg'


const Root = styled('div')(({ theme }) => ({
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '50px'
}));

const BlueButton = styled(Button)(({ theme }) => ({
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[700],
    '@media (hover: none)': {
      backgroundColor: blue[500]
    }
  },
  color: '#fff'
}));

export default function ApplicationSent({ title, message, buttonText, buttonClick }) {
  return (
    <Root>
      <img alt='' src={iconPaper} style={{ display: 'inline-block', width: '88px', height: '74px', }}></img>
      <h1>{title}</h1>
      <p>{message}</p>
      <BlueButton
        variant="contained"
        color="primary"
        onClick={buttonClick}
      >
        {buttonText}
      </BlueButton>
    </Root>
  )
};

ApplicationSent.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClick: PropTypes.func.isRequired,
};
