const i18n = {
  'appointment': {
    'popover': {
      'NA': '無',
      'arrangeTreatments': '療程安排',
      'birth': '生日',
      'button': {
        'cancelAppointment': '取消此預約',
        'edit': '編輯預約',
        'kardex': '查看Kardex',
        'sendSms': '發送簡訊'
      },
      'gender': {
        'male': '男',
        'female': '女'
      },
      'comment': '註記',
      'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
      'doctor': '主治醫師',
      'nurse': ['santea'].includes(process.env.BRANCH_ENV) ? '健管師' : '護理師',
      'phone': '聯絡電話',
      'createdAt': '預約表建立時間',
      'room': '診間',
      'yearsOld': '歲',
      'noRoom': '診間待定',
      'customerStatus': {
        'root': '客戶狀態',
        'arrived': '抵達',
        'cancelled': '取消',
        'complete': '離開',
        'late': '遲到',
        'not-confirmed': '預約待提醒',
        'cancelanotherappointment': '取消並重新預約',
        'profile-needed': '病歷準備中',
        'ready': '準備完成',
        'not-arrived': '未出現',
      }
    },
    'sidebar': {
      'reservation': '預約',
      'comment': '註記',
      'date': '日期',
      'time': '時間',
      'startTime': '開始時間',
      'endTime': '結束時間',
      'cancelled': '取消預約',
      'treatment': {
        'showAs': '顯示名稱',
        'showNickName': '顯示簡稱',
        'assistant': '跟診人員',
        'duration': '時間',
        'delete': '刪除'
      },
      'tab': {
        'customerInformation': '客戶基本資料',
        'arrangeTreatments': '療程安排'
      },
      'button': {
        'newPatient': '新建客戶',
        'selectPatient': '選擇客戶',
        'arrangeTreatments': '療程安排'
      },
      'treatmentType': {
        'product': '療程',
        'pre-consultation': '術前諮詢',
        'pre-surgery': '術前準備',
        'post-surgery': '術後',
        'return-checkup': '回診',
      },
      'noTreatments': '尚無排程',
      'minutesShort': '分',
      'patientInfo': {
        'gender': {
          'root': '性別',
          'male': '男',
          'female': '女'
        },
        'age': '年齡',
        'phone': '電話',
        'birthDate': '生日',
        'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
        'doctor': '醫師',
        'nurse': ['santea'].includes(process.env.BRANCH_ENV) ? '健管師' : '護理師',
        'source': {
          'root': '預約來源',
          'phone': '電話',
          'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
          'internets': '網路',
          'ddapp': 'Digital Dr. App'
        },
        'room': '診間',
        'comment': '註記',
      },
      'appointmentType': {
        'root': '療程類別',
        'beauty': '醫學美容',
        'beautyTreatment': ['santea'].includes(process.env.BRANCH_ENV) ? '療程' : '美容保養',
        'gynecology': '婦女全人',
        'neurology': '神經內科',
        'plastic': '整形外科',
        'preventative': '預防醫學',
        'cancelled': '已取消'
      },
      'error': {
        'noAppointmentType': '請選擇療程類別',
        'noAppointmentTime': '請填寫預約時間',
        'noDoctor': '請填寫醫師',
        'noPatient': '請選擇客戶或新增客戶'
      }
    },
    'noRoom': '診間待定',
    'anchor': {
      'newReservation': '新增預約',
      'newComment': '新增註記'
    },
    'editSchedules': '編輯班表',
    'list': {
      'time': '時間',
      'name': '姓名',
      'doctor': '醫師',
      'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
      'nurse': ['santea'].includes(process.env.BRANCH_ENV) ? '健管師' : '護理師',
      'treatment': '療程',
    },
    'history': {
      'displayId': 'Kardex ID',
      'patient': '客戶姓名',
      'addName': '建立人員',
      'addDate': '建立日期',
      'addTime': '建立時間',
      'editName': '修改人員',
      'editDate': '修改日期',
      'editTime': '修改時間',
      'editContent': '修改內容',
      'dailog': {
        'title': '修改內容',
        'editItem': '修改項目',
        'newValue': '更改後',
        'oldValue': '更改前',
        'item': {
          'status': '預約狀態',
          'time': '預約時間',
          'doctor': '醫師',
          'date': '預約日期',
          'comment': '註記',
          'appointmentType': '診療類別'
        }
      }
    },
    'ddapp': {  // [DDAPP]
      'title': {
        'new': '新預約',
        'update': '待確認預約',
        'updateInfo': '更新資訊'
      },
      'button': {
        'remove': '清除訊息',
        'reserve': '保留訊息'
      },
      'message': {
        'normal': '客戶 {name} 欲將 Kardex ID為: {kardexID} 的預約日期 {kardexDate} {kardexTime} 修改為 {date} {time}，請與客戶確認並調整預約表。',
        'cancelled': '客戶 {name} 欲將 Kardex ID為: {kardexID} 的預約取消 ，請與客戶確認並調整預約表。'
      },
      'table': {
        'patientName': '客戶姓名',
        'date': '預約日期',
        'time': '預約時間',
        'doctorName': '預約醫師',
        'phone': '電話',
        'email': 'Email',
        'note': '備註'
      },
      'dialog': {
        'title': '預約',
        'date': '日期',
        'time': '時間',
        'selectPatient': '選擇客戶',
        'addPatient': '新增客戶',
        'arrangeTreatments': '療程安排',
        'doctor': '醫師',
        'nurse': '護理師',
        'salesRep': '客代',
        'source': {
          'root': '預約來源',
          'phone': '電話',
          'salesRep': '客代',
          'internets': '網路',
          'ddapp': 'Digital Dr. App'
        },
        'room': '診間',
        'comment': '註記',
        'appointmentType': {
          'root': '療程類別',
          'beauty': '醫學美容',
          'beautyTreatment': '美容保養',
          'gynecology': '婦女全人',
          'neurology': '神經內科',
          'plastic': '整形外科',
          'preventative': '預防醫學',
          'cancelled': '已取消'
        },
      }
    }
  }
}

export default i18n
