import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PieChart } from 'react-chartkick'
import 'chartkick/chart.js'
import 'chartjs-adapter-date-fns'

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import ContextStore from '../../modules/context';
import { objectToArray } from '../../modules/data';
import SimpleTableToolbar from '../../components/SimpleTableToolbar'
import DatePickerField from '../../components/DatePickerField'
import DateRangePickerField from '../../components/DateRangePickerField'
import PurchaseCategory from './ReportList/PurchaseCategory'
import PurchaseList from './ReportList/PurchaseList';
import TreatmentList from './ReportList/TreatmentList'
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
// import SearchBox from '../../components/SearchBox';

function PurchaseReport({ patientMapping }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const history = useHistory();
  const { typeName, dateType } = useParams()
  const [dateTime, setDateTime] = useState(dayjs().format('YYYY-MM-DD'));
  const [monthTime, setMonthTime] = useState(dayjs().format('YYYY-MM'));
  const [startTime, setStartTime] = useState(dayjs().format('YYYY-MM'));
  const [endTime, setEndTime] = useState(dayjs().add(2, 'month').format('YYYY-MM'));
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [paymentMapping, setPaymentMapping] = useState({})
  const [productMapping, setProductMapping] = useState({})
  const [kardexes, setKardexes] = useState([])
  const userMapping = useSelector(state => state.firebase.data.users);
  // const [currentFilter, setCurrentFilter] = useState(null)

  useEffect(() => {
    if (dateTime !== 'Invalid Date' && monthTime !== 'Invalid Date') {
      let ref = ''
      if (dateType === 'day') {
        ref = firebase.database().ref('kardexes').orderByChild('date').equalTo(dateTime)
      } else if (dateType === 'month') {
        ref = firebase.database().ref('kardexes').orderByChild('date').startAt(`${monthTime}-01`).endAt(`${monthTime}-${dayjs(monthTime).daysInMonth()}`)
      } else if (dateType === 'season') {
        ref = firebase.database().ref('kardexes').orderByChild('date').startAt(`${startTime}-01`).endAt(`${endTime}-${dayjs(endTime).daysInMonth()}`)
      }

      const onDataChange = ref.on('value', snapshot => {
        setKardexes(objectToArray(snapshot.val()))
      });
      return () => ref.off('value', onDataChange)
    } else {
      return null
    }
  }, [dateTime, monthTime, startTime, endTime, dateType]);

  useEffect(() => {
    const ref = firebase.database().ref('products')
    const onDataChange = ref.on('value', snapshot => {
      setProductMapping(snapshot.val())
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    if (![dateTime, monthTime, startTime, endTime].includes('Invalid Date')) {
      let ref = ''
      if (dateType === 'day') {
        ref = firebase.database().ref('purchaseOrders').orderByChild('date').equalTo(dateTime)
      } else if (dateType === 'month') {
        ref = firebase.database().ref('purchaseOrders').orderByChild('date').startAt(`${monthTime}-01`).endAt(`${monthTime}-${dayjs(monthTime).daysInMonth()}`)
      } else if (dateType === 'season') {
        ref = firebase.database().ref('purchaseOrders').orderByChild('date').startAt(startTime).endAt(`${endTime}-${dayjs(endTime).daysInMonth()}`)
      }

      const onDataChange = ref.on('value', snapshot => {
        setPurchaseOrders(objectToArray(snapshot.val()).filter(p => !p.void))
      });
      return () => ref.off('value', onDataChange)
    } else {
      return null
    }
  }, [dateTime, monthTime, startTime, endTime, dateType]);

  useEffect(() => {
    if (![dateTime, monthTime, startTime, endTime].includes('Invalid Date')) {
      let ref = ''
      if (dateType === 'day') {
        ref = firebase.database().ref('payments').orderByChild('createdAt').startAt(dayjs(dateTime).valueOf())
      } else if (dateType === 'month') {
        ref = firebase.database().ref('payments').orderByChild('createdAt').startAt(dayjs(monthTime).valueOf())
      } else if (dateType === 'season') {
        ref = firebase.database().ref('payments').orderByChild('createdAt').startAt(dayjs(startTime).valueOf())
      }

      const onDataChange = ref.on('value', snapshot => {
        const snapshots = snapshot.val()
        if (snapshots) {
          setPaymentMapping(snapshots)
        }
      });
      return () => ref.off('value', onDataChange)
    } else {
      return null
    }
  }, [dateTime, monthTime, startTime, endTime, dateType]);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: `sideMenu.reports.${typeName}.${dateType}` })
    }])
    return () => {
    };
  }, [history.location.pathname, typeName, dateType]);

  const productTypeData = purchaseOrders.reduce((acc, cur) => {
    if (cur.payments) {
      for (const p in cur.products) {
        const product = cur.products[p]
        const cat1 = productMapping[product.productId].cat1
        if (!acc[cat1]) {
          acc[cat1] = {
            price: (product.discountPrice * product.amount),
            payments: cur.payments
          }
        } else {
          acc[cat1] = {
            price: acc[cat1].price += (product.discountPrice * product.amount),
            payments: cur.payments
          }
        }
      }
    }
    return acc
  }, {})

  const paymentData = purchaseOrders.reduce((acc, cur) => {
    if (cur.payments) {
      for (const pay of Object.keys(cur.payments)) {
        if (paymentMapping[pay]) {
          const payment = paymentMapping[pay]
          if (!acc[payment.paymentType]) {
            acc[payment.paymentType] = payment.collected
          } else {
            acc[payment.paymentType] = acc[payment.paymentType] += payment.collected
          }
        }
      }
    }

    return acc
  }, {})

  const customerData = purchaseOrders.concat(kardexes).reduce((acc, cur) => {
    let type = 'old'
    if (cur.patientType) {
      if (cur.patientType === '新客') {
        type = 'new'
      } else if (cur.patientType === '舊帶新') {
        type = 'oldWithNew'
      }
    } else {
      if (patientMapping[cur.patient]) {
        if (patientMapping[cur.patient].type === '新客') {
          type = 'new'
        } else if (patientMapping[cur.patient].type === '舊帶新') {
          type = 'oldWithNew'
        }
      }
    }

    if (!acc[cur.patient]) {
      acc[cur.patient] = { type, path: cur.displayId ? 'kardex' : 'purchase' }
    } else {
      acc = { ...acc, [cur.patient]: { type, path: cur.displayId ? 'kardex' : 'purchase' } }
    }
    return acc
  }, {})

  let tableData = []
  const pieChartData = Object.keys(productTypeData).reduce((acc, cur) => {
    acc.push([
      formatMessage({ id: `product.type.${cur}` }),
      productTypeData[cur].price,
    ])

    return acc
  }, [])

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label;
            const dataset = context.dataset;
            const total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
              return previousValue + currentValue;
            });
            const currentValue = dataset.data[context.dataIndex];
            const percentage = Math.floor(((currentValue / total) * 100) + 0.5);

            return `${label}: \n${percentage}%`;
          }
        }
      }
    }
  }

  function formatCurrency(num) {
    num = num.toString().replace(/\$|,/g, '');
    if (isNaN(num))
      num = '0';
    // let sign = (num === (num = Math.abs(num)));
    num = Math.floor(num * 100 + 0.50000000001);
    let cents = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10) {
      for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
        num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
      }
    }

    return num;
  }

  const formatData = (data) => {
    const newData = { ...data }
    if (newData.total) {
      let newTotal = 0
      let turnoverRate = 0

      for (const s of tableData) {
        const purchase = purchaseOrders.filter(p => p.patient === s.patientId && p.payments)
        if (purchase.length > 0) {
          turnoverRate += 1
        }
        for (const po of purchase) {
          let newPayment = []
          if (po.payments) {

            Object.keys(po.payments).forEach(p => {
              if (paymentMapping[p]) {
                newPayment.push(paymentMapping[p])
              }
            })
          }

          let totalPaid = newPayment.reduce((acc, payment) => {
            if (payment.void) {
              return acc
            }
            return acc + (payment.collected || 0)
          }, 0)

          newTotal += totalPaid
        }
      }

      const newRate = (Math.round(turnoverRate / tableData.length * 10000) / 100.00).toFixed(1)
      newData.purchaseAmount = `${newTotal} ${formatMessage({ id: 'report.customerCount.turnoverRate' }, { rate: newRate })}`
    } else {
      newData.name = patientMapping[newData.patientId]?.name
      newData.customercode = patientMapping[newData.patientId]?.customercode

      newData.purchaseAmount = purchaseOrders.reduce((acc, cur) => {
        if (cur.patient === newData.patientId && cur.payments) {
          for (const p of Object.keys(cur.payments)) {
            if (paymentMapping[p]) {
              acc += paymentMapping[p].collected
            }
          }
        }

        return acc
      }, 0)
    }

    return newData
  }

  // function filterByText(dataType) {
  //   if (dataType === 'po') {
  //     if (currentFilter.name === 'salesRep') {
  //       return purchaseOrders.filter(s => s.agent && userMapping[s.agent.id].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
  //     }
  //   } else {
  //     if (currentFilter.name === 'salesRep') {
  //       return kardexes.filter(s => s.salesRep && userMapping[s.salesRep].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
  //     }
  //   }
  // }

  function createField(field, type) {
    let newValue = 0

    if (type === 'turnover') {

      if (field.name === 'total') {
        newValue = Object.keys(productTypeData).reduce((acc, cur) => {
          acc += Number(productTypeData[cur].price)
          return acc
        }, 0)
      } else {
        if (field.name === 'uncollected') {
          const totalPrice = Object.keys(productTypeData).reduce((acc, cur) => {
            acc += Number(productTypeData[cur].price)
            return acc
          }, 0)

          const uncollectPrice = Object.keys(paymentData).reduce((acc, cur) => {
            acc += Number(paymentData[cur])
            return acc
          }, 0)

          newValue = totalPrice - uncollectPrice
        } else {
          newValue = Object.keys(paymentData).reduce((acc, cur) => {
            if ((field.name === 'swipe' && ['visa', 'master', 'jcb', 'online', 'ae'].includes(cur)) ||
              (field.name === 'thirdParty' && ['ecPay', 'wechatPay', 'alipay', 'unionpay'].includes(cur)) ||
              field.name === 'paid' || field.name === cur) {
              acc += Number(paymentData[cur])
            }
            return acc
          }, 0)
        }
      }

      if (newValue !== 0) {
        return <ListItem key={field.name} >
          <Typography>
            {`${formatMessage({ id: `report.${type}.${field.name}` })}: ${formatCurrency(newValue)}`}
          </Typography>
        </ListItem>
      }
    } else {
      if (field.name === 'new') {
        tableData = [];
        newValue = Object.keys(customerData).reduce((acc, cur) => {
          if (customerData[cur].type === 'new') {
            tableData.push({ patientId: cur })
            acc += 1
          }
          return acc
        }, 0)
      } else if (field.name === 'oldWithNew') {
        tableData = [];
        newValue = Object.keys(customerData).reduce((acc, cur) => {
          if (customerData[cur].type === 'oldWithNew') {
            tableData.push({ patientId: cur })
            acc += 1
          }
          return acc
        }, 0)
      } else if (field.name === 'old') {
        tableData = [];
        newValue = Object.keys(customerData).reduce((acc, cur) => {
          if (customerData[cur].type === 'old') {
            tableData.push({ patientId: cur })
            acc += 1
          }
          return acc
        }, 0)
      }

      return <div key={field.name} style={{ display: 'flex', flexDirection: 'column', }}>
        <ListItem >
          <Typography>
            {formatMessage({ id: `report.${type}.${field.name}` }, { count: newValue })}
          </Typography>
        </ListItem>
        {newValue > 0 && <TableContainer sx={{ padding: '0 10px', maxHeight: '200px' }}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              rowCount={tableData.length}
            />
            <TableBody>
              {tableData.concat([{ total: true, patientId: '', totalPatinet: newValue }]).map(p => formatData(p)).map((data, index) => (
                <EnhancedTableRow
                  key={`${data.patientId}-${index}`}
                  rowCells={rowCells}
                  cellData={data}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
      </div>
    }
  }

  // const onFilterChanged = (name, text) => {
  //   if (text !== '') {
  //     setCurrentFilter({ name, text })
  //   } else {
  //     setCurrentFilter(null)
  //   }
  // }

  const fields = [
    { name: 'total', sm: 3, md: 6 },
    { name: 'cash', sm: 3, md: 6 },
    { name: 'swipe', sm: 3, md: 6 },
    { name: 'wireTransfer', sm: 3, md: 6 },
    { name: 'mktExpence', sm: 3, md: 6 },
    { name: 'points', sm: 3, md: 6 },
    { name: 'benefit', sm: 3, md: 6 },
    { name: 'thirdParty', sm: 3, md: 6 },
    { name: 'uncollected', sm: 3, md: 6 },
    { name: 'paid', sm: 3, md: 6 },
  ]

  const customerFields = [
    { name: 'new', sm: 3, md: 6, },
    { name: 'oldWithNew', sm: 3, md: 6, },
    { name: 'old', sm: 3, md: 6, },
  ]

  const headerCells = [
    { text: 'name' },
    { text: 'purchaseAmount' },
  ].map(c => { c.text = formatMessage({ id: `report.customerCount.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'name' },
    { field: 'purchaseAmount' },
  ]

  // const filterItems = [
  //   { name: 'salesRep' },
  //   { name: 'doctor' },
  // ].map(i => { i.text = formatMessage({ id: `report.customerCount.table.${i.name}` }); return i })

  function getDateComponent() {
    if (dateType === 'day') {
      return <>
        {/* <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} /> */}
        <DatePickerField
          required
          label={formatMessage({ id: 'report.date' })}
          value={dayjs(dateTime)}
          onChange={date => setDateTime(dayjs(date).format('YYYY-MM-DD'))}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </>
    } else if (dateType === 'month') {
      return <>
        {/* <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} /> */}
        <DatePickerField
          required
          label={formatMessage({ id: 'report.month' })}
          value={dayjs(monthTime)}
          inputFormat="YYYY-MMM"
          onChange={date => setMonthTime(dayjs(date).format('YYYY-MM'))}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </>
    } else if (dateType === 'season') {
      return <>
        {/* <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} /> */}
        <DateRangePickerField
          styles={{ display: 'flex', justifyContent: 'flex-end', maxWidth: '600px' }}
          startDate={startTime}
          endDate={endTime}
          onStartDateChanged={setStartTime}
          onEndDateChanged={setEndTime}
          inputFormat="YYYY-MMM"
        />
      </>
    }
  }

  // const filterPo = currentFilter && currentFilter.text ? filterByText('po') : purchaseOrders
  // const filterKardex = currentFilter && currentFilter.text ? filterByText('kardex') : kardexes
  // console.log(filterKardex)
  return (
    <Grid container spacing={0} sx={{ marginTop: '10px' }}>
      <Grid item xs={12} sm={12} md={12}>
        <SimpleTableToolbar
          title={`report.title.${typeName}.${dateType}`}
          toolbox={getDateComponent()}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <PieChart data={pieChartData} library={options} />
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <List component="nav" aria-label="main mailbox folders">
          <Typography >{formatMessage({ id: 'report.turnover.current' })}</Typography>
          {fields.map(field => createField(field, 'turnover'))}
        </List>
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <List component="nav" aria-label="main mailbox folders">
          <Typography >{formatMessage({ id: 'report.customerCount.title' }, { count: Object.keys(customerData).length })}</Typography>
          {customerFields.map(field => createField(field, 'customerCount'))}
        </List>
      </Grid>
      <PurchaseList
        purchaseOrders={purchaseOrders}
        paymentMapping={paymentMapping}
        patientMapping={patientMapping}
        userMapping={userMapping}
      />
      <PurchaseCategory
        purchaseOrders={purchaseOrders}
        productTypeData={productTypeData}
        productMapping={productMapping}
      />
      <TreatmentList
        kardexes={kardexes}
        userMapping={userMapping}
        patientMapping={patientMapping}
      />
    </Grid>
  );
}

PurchaseReport.propTypes = {
  patientMapping: PropTypes.object.isRequired
};

export default PurchaseReport;
