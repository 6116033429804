const i18n = {
  'kardex': {
    'table': {
      'kardexId': 'Kardex ID',
      'date': '日期',
      'arrangeTreatments': '療程',
      'doctor': '醫師',
      'nurse': ['santea'].includes(process.env.BRANCH_ENV) ? '健管師' : '護理師',
      'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
      'staytime': '停留時間'
    },
    'profile': {
      'reservation': '預約',
      'generalInfo': '基本資訊',
      'doctor': '醫師',
      'nurse': ['santea'].includes(process.env.BRANCH_ENV) ? '健管師' : '護理師',
      'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
      'source': {
        'roots': '來源',
        'phone': '電話',
        'internet': '網路',
        'salesRep': ['santea'].includes(process.env.BRANCH_ENV) ? '業務' : '客代',
      },
      'remarks': '備註',
      'status': {
        'not-confirmed': '預約待提醒',
        'profile-needed': '病歷準備中',
        'ready': '準備完成',
        'not-arrived': '未出現',
        'arrived': '抵達',
        'late': '遲到',
        'cancelled': '取消',
        'complete': '離開',
        'cancelanotherappointment': '取消並重新預約'
      },
      'doctorNote': '醫師備註',
      'items': '產品'
    },
    'tab': {
      'medicine': '一般處方箋',
      'controlledDrugMedicine': '管制藥品處方箋',
      'certificateOfDiagnosis': '診斷證明書',
      'consult': '諮詢',
      'treatment': '療程',
      'injection': '針劑能量紀錄表'
    },
    'medicine': {
      'information': '客戶資訊',
      'name': '客戶姓名',
      'identityCardNumber': '身份證字號',
      'birthDate': '出生日期',
      'displayId': '病歷號碼',
      'date': '就診日期',
      'division': {
        'roots': '就醫科別',
        'beauty': '醫學美容',
        'plastic': '整形外科',
        'preventative': '預防醫學'
      },
      'dosingDay': '天數',
      'doctorStamp': {
        'roots': '醫師印章',
        'blank': '保留空白',
        'required': '同Kardex醫師章'
      },
      'comment': '備註',
      'payment': '機構健保代碼',
      'licence': '管制藥品使用執照號碼',
      'disease': '疾病名稱',
      'exportPdf': '輸出',
      'doctorSign': '醫師簽章',
      'receiveMedicine': '領藥',
      'doctorPhone': '醫師連絡電話'
    },
    'certificateOfDiagnosis': {
      'information': '客戶資訊',
      'name': '客戶姓名',
      'identityCardNumber': '身份證字號',
      'gender': {
        'roots': '性別',
        'male': '男',
        'female': '女',
      },
      'nationality': '國籍',
      'birthDate': '出生日期',
      'displayId': '病歷號碼',
      'date': '就診日期',
      'address': '地址',
      'division': {
        'roots': '就醫科別',
        'beauty': '醫學美容',
        'plastic': '整形外科',
        'preventative': '預防醫學'
      },
      'diagnosis': '診斷',
      'comment': '醫囑',
      'exportPdf': '醫師簽章',
      'showPdf': '檢視'
    },
    'treatment': {
      'code': '品號',
      'name': '名稱',
      'cost': '耗材成本',
      'assistant': '跟診人員',
      'title': '療程設定'
    }
  }
}
export default i18n