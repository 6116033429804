import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import BackdropMui from '@mui/material/Backdrop';

import EnhancedTableRow from '../../components/EnhancedTableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { getComparator, stableSort } from '../../modules/sort';
import OvertimeListView from './OvertimeListView'
import ActionDialog from '../../components/ActionDialog';

const Backdrop = styled(BackdropMui)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const statusColor = {
  pending: '#c83cc8',
  reject: '#fe2851',
  void: '#fe2851',
  done: '#00b900',
}

function OvertimeList({ currentUser }) {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('start')
  const [overtimes, setOvertimes] = useState([])
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('overtimes').where('createdBy', '==', currentUser.key).where('status', '!=', 'void_').onSnapshot(snapshot => {
      const overtimes = []
      snapshot.forEach(doc => {
        overtimes.push({ ...doc.data(), id: doc.id })
      });
      setOvertimes(overtimes.filter(o => o.startDate >= `${dayjs().format('YYYY')}-01-01`))
    }, err => { })

    return () => unsubscribe()
  }, [currentUser.key]);


  const formatData = (overtime) => {
    const newData = { ...overtime }
    newData.start = `${newData.startDate} ${newData.startTime}`
    newData.typeName = formatMessage({ id: `overtimeType.${newData.type}` })
    newData.statusName = `${formatMessage({ id: `overtimeStatus.${newData.status}` })}`
    newData.end = `${newData.endDate} ${newData.endTime}`
    newData.hours = `${Number(newData.hours) * 2}`
    newData.textColor = statusColor[newData.status]

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerCells = [
    { text: 'start', sort: 'start' },
    { text: 'end', sort: 'end' },
    { text: 'hours', sort: 'hours' },
    { text: 'type', sort: 'type' },
    { text: 'reason', sort: 'reason' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `overtimes.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'start' },
    { field: 'end' },
    { field: 'hours' },
    { field: 'typeName' },
    { field: 'reason' },
    { field: 'statusName', textColor: true },
  ]

  async function handleExecute(data) {
    const { text } = data
    const { filed, overtimeId } = dialogData
    setLoading(true)

    try {
      await (firebase.functions().httpsCallable('saveOvertimes'))({
        ...filed,
        id: overtimeId,
        note: text,
        void: true,
      })

      setLoading(false)
    } catch (ex) {
      console.log(ex)
      setLoading(false)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  return (
    <Grid container spacing={1}>
      {loading && <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'overtimes.detail.voidReason' })}
        action={dialogData.action}
      />}
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ paddingBottom: '60px', paddingTop: '20px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                expandable
                onRequestSort={handleRequestSort}
                rowCount={overtimes.length}
                actionButton
              />
              <TableBody>
                {stableSort(overtimes.map(r => formatData(r)), getComparator(order, orderBy)).map(overtime => (
                  <EnhancedTableRow
                    key={overtime.id}
                    rowCells={rowCells}
                    cellData={overtime}
                    expandable
                    expandContent={<OvertimeListView overtimes={overtime} currentUser={currentUser} />}
                    actionIcons={<>
                      {(!overtime.void || (overtime.void && overtime.status === 'reject')) && <span>
                        <Tooltip title={formatMessage({ id: 'button.edit' })}>
                          <IconButton
                            onClick={() => history.push(`/leaveOvertimeWork/overtimes/edit/${overtime.id}`)}
                            size="large">
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={formatMessage({ id: 'button.void' })}>
                          <IconButton
                            onClick={() => setDialogData({ action: 'void', title: '', filed: overtime, overtimeId: overtime.id })}
                            size="large">
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </span>}
                    </>}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Grid>
  );
};

OvertimeList.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

export default OvertimeList;
