import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import DatePickerField from '../../components/DatePickerField';
import AlertDialog from '../../components/AlertDialog';
import ButtonProgress from '../../components/ButtonProgress'


const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

function EditPunchClockRevisePage() {
  const { formatMessage } = useIntl()
  const { punchClockReviseId } = useParams()
  const history = useHistory()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false)
  const [punchClockReviseData, setPunchClockReviseData] = useState({
    date: new Date(),
    hour: '00',
    minute: '00',
    reason: '',
    time: ''
  })

  useEffect(() => {
    const unsubscribe = punchClockReviseId !== 'new' ? firebase.firestore().collection('punchClockRevise').doc(punchClockReviseId).onSnapshot(snapshot => {
      if (punchClockReviseId) {
        const snapshots = snapshot.data()
        const times = String(snapshots.time).split(':')
        snapshots.date = dayjs(snapshots.date)
        snapshots.hour = times[0]
        snapshots.minute = times[1]
        setPunchClockReviseData(snapshots)
      }
    }, err => { }) : null
    return () => { if (unsubscribe) unsubscribe() }
  }, [punchClockReviseId]);



  function handleClose() {
    setDialogOpen(null)
  }

  async function onPunchClockReviseSave() {
    setLoadingApprove(true)

    if (punchClockReviseData.reason === '') {
      setLoadingApprove(false)
      return setDialogOpen(true)
    }

    const ReviseId = punchClockReviseId ? punchClockReviseId : 'new';
    const newData = {
      date: dayjs(punchClockReviseData.date).format('YYYY-MM-DD'),
      time: `${punchClockReviseData.hour}:${punchClockReviseData.minute}`,
      reason: punchClockReviseData.reason,
    }

    try {
      await (firebase.functions().httpsCallable('savePunchClockRevise'))({ id: ReviseId, ...newData })
      setLoadingApprove(false)
    } catch (ex) {
      console.log(ex)
    }

    history.push('/leaveOvertimeWork/punchClockRevise/applicationSent')
  }

  function handleDateChange(filed, date) {
    if (date === null || date.toString() === 'Invalid Date') {
      updateData(filed, '')
    } else {
      updateData(filed, date);
    }
  };

  function validateField(field, value) {
    if ((field.name === 'startDate' || field.name === 'endDate') && value === '') {
      return '無效的日期格式'
    }
    return ''
  }

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)

    let newData = { ...punchClockReviseData, [field.name]: newValue, [`${field.name}_err`]: err }
    if (field.name === 'startDate') {
      newData.endDate = value
    }

    setPunchClockReviseData(newData)
  }



  return (
    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif', padding: '0 20px', }}>
      <div style={{ fontSize: '40px', marginBottom: '20px', marginTop: '20px' }}>
        {punchClockReviseId !== 'new' ? formatMessage({ id: 'punchClockRevise.editPunchClockRevise' }) : formatMessage({ id: 'punchClockRevise.applyForPunchClockRevise' })}
      </div>
      {dialogOpen &&
        <AlertDialog
          dialogTital={formatMessage({ id: 'punchClockRevise.punchClockReviseAlert.title' })}
          dialogMsg={formatMessage({ id: 'punchClockRevise.punchClockReviseAlert.content' })}
          handleClose={handleClose}
        />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10} md={4}>
              <DatePickerField
                required
                fullWidth
                label={formatMessage({ id: 'punchClockRevise.table.date' })}
                value={punchClockReviseData.date}
                onChange={date => handleDateChange({ name: 'date' }, date)}
                invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'punchClockRevise.table.hour' })}
                fullWidth
                value={punchClockReviseData.hour}
                onChange={e => updateData({ name: 'hour' }, e.target.value)}
              >
                {hours.map((hour, idx) => {
                  return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <TextField
                select
                type="text"
                size="small"
                variant="outlined"
                label={formatMessage({ id: 'punchClockRevise.table.minute' })}
                fullWidth
                value={punchClockReviseData.minute}
                onChange={e => updateData({ name: 'minute' }, e.target.value)}
              >
                {minutes.map((minute, idx) => {
                  return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={12} md={7}>
              <TextField
                type="text"
                size="small"
                variant="outlined"
                fullWidth
                multiline
                label={formatMessage({ id: 'punchClockRevise.table.reason' })}
                value={punchClockReviseData.reason}
                onChange={e => updateData({ name: 'reason' }, e.target.value)}
              >
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '20px' }}>
              <ButtonProgress
                handleClick={() => onPunchClockReviseSave()}
                handleClose={() => history.push('/leaveOvertimeWork/punchClockRevise')}
                loading={loadingApprove}
                buttonText={'button.submit'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default EditPunchClockRevisePage;
