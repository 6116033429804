import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs'
import firebase from 'firebase/app';
import clsx from 'clsx'
import { useSelector } from 'react-redux';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { createUseStyles } from 'react-jss'

import { useTheme, createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import InputMask from 'react-input-mask';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

import { calculateAge } from '../../modules/time'
import { formatName } from '../../modules/uitls';
import { objectToArray, objectToNewKey } from '../../modules/data'
import DatePickerField from '../../components/DatePickerField';
import TabContainer from '../../containers/TabContainer/TabContainer';
import SelectPatientDialog from '../../components/SelectPatientDialog';
import SelectProductDialog from '../../components/SelectProductDialog';
import NewPatientDialog from '../../components/NewPatientDialog';
import { treatmentColorAndBgColor } from '../../modules/treatment';
import { moibleMedia } from '../../constants/index';

const appointmentTypeStrings = ['santea'].includes(process.env.BRANCH_ENV) ?
  ['beautyTreatment', 'preventative'] : ['beautyTreatment', 'beauty', 'preventative', 'plastic']
const saveButtonColor = process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence' ?
  { color: '#eeccc4' } : process.env.BRANCH_ENV === 'santea' ? { color: '#26CAD3' } : { color: '#6EDBD5' }
const primaryColor = process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence' ? {
  light: '#fffff7',
  main: '#eeccc4',
  dark: '#bb9b94',
  contrastText: '#a58a88',
} : process.env.BRANCH_ENV === 'santea' ? {
  light: '#6efeff',
  main: '#26CAD3',
  dark: '#0099a2',
  contrastText: '#fff',
} : {
  light: '#a3ffff',
  main: '#6EDBD5',
  dark: '#35a9a4',
  contrastText: '#fff',
}

const theme = createTheme({
  palette: {
    primary: primaryColor,
  },
});

const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  userSelect: 'none',
  zIndex: 11,
  minHeight: '40px'
}

const itemColor = {
  margin: '1px 0',
  width: '10px',
  minHeight: '100%',
  display: 'inline-flex',
  borderRadius: '5px',
  alignSelf: 'stretch'
}

const mainWrap = {
  width: '100%'
}

const itemMain = {
  minHeight: '40px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  background: '#fff',
  userSelect: 'none'
}

const itemName = {
  flex: 1,
  padding: '0 10px',
  fontSize: '15px',
  color: '#414141'
}

const itemDuration = {
  padding: '0 20px',
  fontSize: '15px',
  color: '#757575'
}

const itemEdit = {
  borderTop: '1px solid #E4E4E4',
  borderBottom: '1px solid #E4E4E4',
  padding: '0 18px 10px',
  background: '#F6F6F6',
}

// [5, 10, 15, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480]
const durationOptions = [
  { time: 5 }, { time: 10 }, { time: 15 }, { time: 30 }, { time: 45 }, { time: 60 }, { time: 90 },
  { time: 120 }, { time: 150 }, { time: 180 }, { time: 210 }, { time: 240 }, { time: 270 }, { time: 300 }, { time: 330 },
  { time: 360 }, { time: 390 }, { time: 420 }, { time: 450 }, { time: 480 }
]

const SortableItem = sortableElement(({ item, nurses, arrayIndex, handleChange }) => {
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState(false);
  const treatment = item
  let { backgroundColor } = treatmentColorAndBgColor(treatment)
  let deleteOpen = true

  if (treatment.done) {
    deleteOpen = false
  }

  return (
    <div style={rowStyle}>
      <div style={{ ...itemColor, backgroundColor: backgroundColor }} />
      <div style={mainWrap}>
        <div style={itemMain} onClick={() => setOpen(!open)}>
          <div style={itemName}>
            {treatment.name || ''}
          </div>
          <div style={itemDuration}>
            {treatment.duration || ''} <FormattedMessage id={'appointment.sidebar.minutesShort'} />
          </div>
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit style={itemEdit}>
          <Grid container spacing={1} style={{ padding: '8px 0 0 0' }}>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                fullWidth
                id={'name'}
                variant="standard"
                disabled
                onChange={(e, val) => handleChange(arrayIndex, 'name', e.target.value)}
                label={formatMessage({ id: 'appointment.sidebar.treatment.showAs' })}
                value={treatment.name}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Autocomplete
                id="duration"
                freeSolo
                value={String(treatment.duration)}
                inputValue={String(treatment.duration)}
                onChange={(e, val) => handleChange(arrayIndex, 'duration', val ?? '')}
                options={durationOptions.map((option) => String(option.time))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="standard"
                    label={formatMessage({ id: 'appointment.sidebar.treatment.duration' })}
                    onChange={(e, val) => handleChange(arrayIndex, 'duration', e.target.value.replace(/[^0-9]/g, ''))}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5} sm={5} md={5}>
              <TextField
                fullWidth
                id={'nickname'}
                variant="standard"
                onChange={(e) => handleChange(arrayIndex, 'nickname', e.target.value)}
                label={formatMessage({ id: 'appointment.sidebar.treatment.showNickName' })}
                value={treatment.nickname || ''}
              />
            </Grid>
            {treatment.type === 'product' && !treatment.code.startsWith('9') ? <Grid item xs={4} sm={4} md={4}>
              <TextField
                select
                fullWidth
                id={'assistant'}
                variant="standard"
                onChange={(e, val) => handleChange(arrayIndex, 'assistant', e.target.value)}
                label={formatMessage({ id: 'appointment.sidebar.treatment.assistant' })}
                value={treatment.assistant || ''}
              >
                {nurses.map((nurse, key) => (
                  <MenuItem value={nurse.id} key={key} >{nurse.displayName}</MenuItem>
                ))}
              </TextField>
            </Grid> : <Grid item xs={4} sm={4} md={4} />}
            {deleteOpen && <Grid item xs={3} sm={3} md={3} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <Button onClick={(e) => handleChange(arrayIndex, false, item.id)}>
                <DeleteIcon />
                <FormattedMessage id='appointment.sidebar.treatment.delete' defaultMessage='Delete' />
              </Button>
            </Grid>}
          </Grid>
        </Collapse>
      </div>
    </div >
  );
});

const SortableList = sortableContainer(({ items, nurses, handleChange }) => {
  return (
    <div>
      {items.filter(i => !i.parent).map((item, index) => (
        <SortableItem
          key={`item-${item.itemCode}`}
          index={index}
          arrayIndex={index}
          handleChange={handleChange}
          item={item}
          nurses={nurses}
        />
      ))}
    </div>
  );
});

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '400px',
    height: '100vh',
    backgroundColor: '#ffffff',
    borderLeft: '1px solid #D4D4D4',
    zIndex: 10,
    paddingBottom: '60px',
    [moibleMedia]: {
      position: 'fixed',
      top: '100px',
      bottom: 0,
      left: 0,
      height: 'auto',
      width: '100vw',
      paddingBottom: 0
    }
  },
  form: {
    width: '100%',
    height: 'calc(100% - 60px)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  header: {
    padding: '0 17px',
    backgroundColor: '#fafafa',
    '& h2': {
      marginBottom: '5px',
    }
  },
  dateTime: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    marginTop: '20px'
  },
  datePicker: {
    width: '140px',
    display: 'inline-flex',
    marginRight: '30px'
  },
  tabContainer: {
    background: '#fff',
    padding: '0 22px'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  addItemButton: {
    color: 'rgb(238,204,196) !important',
    display: 'flex !important'
  },
  footer: {
    height: '60px',
    width: '100%',
    backgroundColor: '#fafafa',
    boxShadow: 'inset 0 1px 0 0 rgba(0,0,0,.08)',
    bottom: 0,
    padding: '0 24px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      borderRadius: '16px !important',
      border: '1px solid #ccc !important',
      lineHeight: '1 !important',
      margin: '0 10px 0 0 !important',
      minWidth: '88px'
    }
  },
  patientInfo: {
    fontSize: '15px',
    lineHeight: '15px',
    padding: '16px 0 0',
    textAlign: 'left',
    color: '#383838',
    '& span:nth-child(2)':
    {
      display: 'inline-block',
      paddingLeft: '5px'
    },
    '& label:nth-child(2)': {
      minWidth: '170px'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    '&:nth-child(even)': {
      margin: '0 10px 0 25px'
    }
  },
  menuIndex: {
    zIndex: 2100
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
    // color: 'blue'
  },
});

function AppointmentSidebar({ userRight, onRequestClose, ui }) {
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const currentAppointment = ui.editAppointment ? { ...ui.editAppointment } : null
  const isTreatmentDone = currentAppointment?.treatmentDone ?? false
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [patients, setPatients] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSelectPatientDialog, setOpenSelectPatientDialog] = useState(false)
  const [openNewPatientDialog, setOpenNewPatientDialog] = useState(false)
  const [showProductSelect, setShowProductSelect] = useState(false)
  const [treatmentTemplates, setTreatmentTemplates] = useState({})
  const userMapping = useSelector(state => state.firebase.data.users);
  const [selectPatientData, setSelectPatientData] = useState({});
  const [products, setProducts] = useState([])
  const [productMapping, setProductMapping] = useState({})
  const menuOpen = Boolean(anchorEl);
  const [loadingApprove, setLoadingApprove] = useState(false);
  let defultTreatment = currentAppointment ? objectToArray(currentAppointment.treatments)
    .map(t => ({ ...t, itemCode: Math.floor(Math.random() * (100000)) })).sort((a, b) => {
      return a.order - b.order
    }) : []
  let defultData = currentAppointment ? {
    doctor: currentAppointment.doctor || '',
    nurse: currentAppointment.nurse || '',
    salesRep: currentAppointment.salesRep || '',
    source: currentAppointment.source || '',
    appointmentType: currentAppointment.appointmentType || '',
    room: currentAppointment.room || '',
    comment: currentAppointment.comment || '',
    date: dayjs(currentAppointment.date),
    time: currentAppointment.time,
    patient: currentAppointment.patient,
  } : {
    doctor: ui.clickMenuData && ui.clickMenuData.doctor ? ui.clickMenuData.doctor.id : '',
    nurse: '',
    salesRep: '',
    source: '',
    appointmentType: ui.clickMenuData ? ui.clickMenuData.appointmentType : '',
    room: '',
    comment: '',
    date: dayjs(ui.date),
    time: ui.clickMenuData ? `${String(ui.clickMenuData.hour).padStart(2, '0')}:00` : ':',
  }
  const [data, setData] = useState(defultData);
  const [treatmentList, setTreatmentList] = useState(defultTreatment);
  const appointmentId = currentAppointment ? currentAppointment.id : 'new'
  const doctors = [];
  const nurses = [];
  const salesReps = [];
  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down('mobile'));

  Object.keys(userMapping).forEach(p => {
    if (userMapping[p].active) {
      if (userMapping[p].department === 'doctor') {
        doctors.push({ id: p, ...userMapping[p] })
      } else if (userMapping[p].department === 'nurse') {
        nurses.push({ id: p, ...userMapping[p] })
      } else if (userMapping[p].department === 'salesRep') {
        salesReps.push({ id: p, ...userMapping[p] })
      }
    }
  })

  const filterDoctors = doctors.filter(doctor => doctor.appointmentType === data.appointmentType)

  useEffect(() => {
    if (ui.editAppointment) {
      setData(defultData)
      setTreatmentList(defultTreatment)
    }
    return () => { }
  }, [ui.editAppointment]);

  useEffect(() => {
    const ref = firebase.database().ref('products')
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      if (snapshots) {
        const newData = [];
        for (const p of Object.keys(snapshots)) {
          if (snapshots[p].consumable && snapshots[p].cat1 !== 'DP') {
            if (snapshots[p].offShelfDate && snapshots[p].onShelfDate) {
              if (snapshots[p].offShelfDate >= dayjs().format('YYYY-MM-DD') && snapshots[p].onShelfDate <= dayjs().format('YYYY-MM-DD')) {
                newData.push({ id: p, ...snapshots[p] })
              }
            } else if (snapshots[p].offShelfDate && !snapshots[p].onShelfDate) {
              if (snapshots[p].offShelfDate >= dayjs().format('YYYY-MM-DD')) {
                newData.push({ id: p, ...snapshots[p] })
              }
            } else if (snapshots[p].onShelfDate && !snapshots[p].offShelfDate) {
              if (snapshots[p].onShelfDate <= dayjs().format('YYYY-MM-DD')) {
                newData.push({ id: p, ...snapshots[p] })
              }
            } else {
              newData.push({ id: p, ...snapshots[p] })
            }
          }
        }
        setProducts(newData)
        setProductMapping(snapshots)
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('treatmentTemplates')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setTreatmentTemplates(objectToNewKey(snapshot.val(), 'type'))
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('patients').child(`${data.patient}`)
    const onDataChange = ref.on('value', snapshot => {
      const snapshots = snapshot.val()
      if (snapshots) {
        let newData = data
        let newSelect = { ...snapshots, name: formatName(snapshots?.name ?? '') }

        if (currentAppointment && !currentAppointment.salesRep) {
          newData.salesRep = snapshots.salesRep || ''
        } else if (!newData.salesRep && newSelect.salesRep) {
          newData.salesRep = newSelect.salesRep
        }

        setData(newData)
        setSelectPatientData(newSelect)
      }
    });
    return () => ref.off('value', onDataChange)
  }, [data.patient]);

  useEffect(() => {
    const ref = firebase.database().ref('patientList')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setPatients(objectToArray(snapshot.val()))
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref('rooms')
    const onDataChange = ref.on('value', snapshot => {
      if (snapshot.val()) {
        setRooms(snapshot.val())
      }
    });
    return () => ref.off('value', onDataChange)
  }, []);

  async function saveAppointment() {
    setLoadingApprove(true)
    const newData = { ...data }
    const { updateWithMeta, pushWithMeta } = firebase

    let timeError = false

    if (newData.time) {
      const newTimeH = newData.time.split(':')[0] || undefined
      const newTimeM = newData.time.split(':')[1] || undefined
      if ((!newTimeH || !newTimeM) || (newTimeH === ' ' || newTimeM === ' ')) {
        timeError = true
      }
      if (newTimeH > '24') {
        timeError = true
      }

      if (newTimeM > '60') {
        timeError = true
      }
    } else {
      timeError = true
    }

    if (timeError) {
      setLoadingApprove(false)
      return alert(formatMessage({ id: 'appointment.sidebar.error.noAppointmentTime' }))
    }

    if (!newData.appointmentType || newData.appointmentType === '') {
      setLoadingApprove(false)
      return alert(formatMessage({ id: 'appointment.sidebar.error.noAppointmentType' }))
    }

    if (newData.appointmentType && !['', 'beautyTreatment'].includes(newData.appointmentType) && (!newData.doctor || newData.doctor === '')) {
      setLoadingApprove(false)
      return alert(formatMessage({ id: 'appointment.sidebar.error.noDoctor' }))
    }

    newData.date = dayjs(newData.date).format('YYYY-MM-DD')
    newData.hour = parseInt(newData.time.split(':')[0])
    newData.minute = parseInt(newData.time.split(':')[1])
    newData.comment = newData.comment || ''
    if (appointmentId === 'new') {
      newData.status = 'not-confirmed'
    }

    const treatments = treatmentList.slice();

    let order = 0
    newData.treatments = treatments.reduce((acc, cur) => {
      if (cur.type === 'product') {
        const key = cur.id ? cur.id : firebase.database().ref('treatments').push().key
        acc[key] = {
          assistant: cur?.assistant ?? '',
          cat0: cur.code.slice(0, 1),
          cat1: cur.cat1,
          code: cur.code,
          duration: Number(cur.duration),
          done: cur.done ?? null,
          name: cur.name,
          product: cur.product ?? cur.id,
          type: cur.type,
          nickname: cur.nickname ?? '',
          cost: cur.cost ?? null,
          parent: cur.parent ?? null,
          order: cur.parent ? 99 : order,
          quantity: 1,
          hidden: cur.hidden ?? null
        }

        if (acc[cur.parent] && acc[cur.parent].cat0 === '8') {
          acc[key].assistant = acc[cur.parent].assistant
        }

        if (cur.package) {
          for (const p of Object.keys(cur.package)) {
            const newKey = firebase.database().ref('treatments').push().key
            if (productMapping[p].consumable) {
              acc[newKey] = {
                assistant: cur?.assistant ?? '',
                cat0: productMapping[p].code.slice(0, 1),
                cat1: productMapping[p].cat1,
                code: productMapping[p].code,
                duration: productMapping[p].duration !== '' ? Number(productMapping[p].duration) : 0,
                parent: key,
                name: productMapping[p].name,
                product: p,
                type: cur.type,
                nickname: productMapping[p].nickname || '',
                order: 99,
                quantity: cur.package[p]?.amount || 1,
                hidden: cur.hidden ?? null
              }
            }
          }
        }
      } else {
        const key = firebase.database().ref('treatments').push().key
        acc[key] = {
          color: cur.color,
          duration: Number(cur.duration),
          name: cur.name,
          nickname: cur.nickname || '',
          order
        }
      }
      order++
      return acc
    }, {})

    if (newData.doctor === '') {
      newData.doctor = null
    }

    if (appointmentId === 'new') {
      if (newData.patient) {
        const res = await pushWithMeta('kardexes', { ...newData })
        if (res) {
          firebase.database().ref('patients').child(newData.patient).child('kardexes').update({ [res.key]: true })
        }
      } else {
        await pushWithMeta('appointments', { ...newData })
      }
    } else {
      if (!newData.patient) {
        delete newData.patient
      }
      const isKardex = await firebase.database().ref('kardexes').child(appointmentId).once('value').then(snapshot => {
        return snapshot.val()
      })

      const isAppointment = await firebase.database().ref('appointments').child(appointmentId).once('value').then(snapshot => {
        return snapshot.val()
      })

      if (isKardex) {
        await updateWithMeta(`kardexes/${appointmentId}`, { ...newData })
      } else if (isAppointment) {
        await updateWithMeta(`appointments/${appointmentId}`, { ...newData })
      }
    }

    setLoadingApprove(false)
    onRequestClose()
  }

  function selectTreatment(treatment, isProduct) {
    if (!treatment) {
      return handleMenuClose()
    }

    treatment = Object.assign({}, treatment, {
      name: treatment.name ? treatment.name : treatment.displayName,
      nickname: treatment.nickname ? treatment.nickname : treatment.displayName,
      type: isProduct ? 'product' : null,
      product: isProduct ? treatment.id : null,
      itemCode: Math.floor(Math.random() * (100000)),
      assistant: treatment.assistant ? treatment.assistant : '',
      order: treatment.order,
      duration: treatment.duration,
    })
    // delete treatment.key

    setTreatmentList(treatmentList.concat(treatment))
    handleMenuClose()
  }

  function shouldCancelStart(e) {
    if (['name', 'duration', 'assistant', 'nickname'].includes(e.target.id)) {
      return true
    }
  }

  function onSortEnd({ oldIndex, newIndex }) {
    setTreatmentList(list => (arrayMoveImmutable(list, oldIndex, newIndex)))
  };

  function handleTreatmentChange(index, key, value) {
    let treatments = treatmentList
    if (key === false) {
      treatments.splice(index, 1)
      let newTreatment = []
      for (const s in treatments) {
        if (treatments[s].parent !== value) {
          newTreatment.push(treatments[s])
        }
      }
      treatments = newTreatment
    } else {
      treatments[index][key] = value
    }
    setTreatmentList([...treatments])
  }

  function updateData(field, value) {
    let newValue = value
    let newData = { ...data, [field.name]: newValue }

    if (field.name === 'appointmentType') {
      newData.doctor = ''
    }

    setData(newData)
  }

  function getNewPatientData(data) {
    updateData({ name: 'patient' }, data)
  }

  const handleSelectPatienSave = (patient) => {
    updateData({ name: 'patient' }, patient.id)
  }

  const handleSelectPatienClose = () => {
    setOpenSelectPatientDialog(false)
  }

  const handleClose = () => {
    setOpenNewPatientDialog(false)
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onTabSelected = (tabIndex) => {
    setActiveTabIndex(tabIndex)
  }

  function onSelectProduct(products) {
    if (products.length) {
      setTreatmentList(treatmentList.concat(products))
    }

    handleMenuClose()
  }

  const tabs = [
    { label: formatMessage({ id: 'appointment.sidebar.tab.customerInformation' }), component: <div></div> },
    { label: formatMessage({ id: 'appointment.sidebar.tab.arrangeTreatments' }), component: <div></div> },
  ]

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={classes.container}>
          {showProductSelect && <SelectProductDialog
            defaultSelectedItems={[]}
            handleClose={() => setShowProductSelect(false)}
            handleSave={onSelectProduct}
            products={products}
            dialogTitle={formatMessage({ id: 'selectProductDialog.title' })}
            isMobile={isMobile}
            productMapping={productMapping}
          />}
          {openNewPatientDialog && <NewPatientDialog
            dialogTital={formatMessage({ id: 'patient.dialog.information.add' })}
            handleClose={handleClose}
            patients={patients || []}
            handleSave={getNewPatientData}
          />}
          {openSelectPatientDialog && <SelectPatientDialog
            handleClose={handleSelectPatienClose}
            handleSave={handleSelectPatienSave}
            patients={patients || []}
            isMobile={isMobile}
            dialogTitle={formatMessage({ id: 'selectPatientDialog.root' })}
          />}
          <form className={classes.form}>
            <div className={classes.header}>
              <h2>
                <FormattedMessage id='appointment.sidebar.reservation' />
              </h2>
              <div className={classes.dateTime}>
                <DatePickerField
                  required
                  fullWidth
                  disabled={isTreatmentDone}
                  underLine={'standard'}
                  label={formatMessage({ id: 'appointment.sidebar.date' })}
                  value={data.date}
                  onChange={date => updateData({ name: 'date' }, date)}
                  invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
                />
                <InputMask
                  mask={'99:99'}
                  maskChar=""
                  onChange={e => updateData({ name: 'time' }, e.target.value)}
                  value={data.time}
                  disabled={isTreatmentDone}
                >
                  {() => <TextField
                    style={{ marginLeft: '10px' }}
                    type="text"
                    size="small"
                    variant="standard"
                    required
                    fullWidth
                    disabled={isTreatmentDone}
                    label={formatMessage({ id: 'appointment.sidebar.time' })}
                    onCompositionStart={
                      e => {
                        e.target.addEventListener('input', e2 => {
                          if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                            e2.preventDefault()
                            e2.stopPropagation()
                            e2.stopImmediatePropagation()
                          }
                        }, { once: true })
                      }
                    }
                    error={data.time_err ? true : false}
                    helperText={data.time_err}
                  />}
                </InputMask>
              </div>

              <TabContainer color="primary" activeTabIndex={activeTabIndex} defaultSelect={0} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" position="static" />
              {activeTabIndex === 0 && <div style={{ marginTop: '20px' }}>
                {(!currentAppointment || !currentAppointment.patient) && <div>
                  <Button color="primary" onClick={() => setOpenSelectPatientDialog(true)}>
                    <PersonIcon />
                    {formatMessage({ id: 'appointment.sidebar.button.selectPatient' })}
                  </Button>
                  {process.env.BRANCH_ENV === 'ibeauty' ? userRight['patient-add'] && <Button color="primary" onClick={() => setOpenNewPatientDialog(true)}>
                    <AddIcon />
                    {formatMessage({ id: 'appointment.sidebar.button.newPatient' })}
                  </Button> : <Button color="primary" onClick={() => setOpenNewPatientDialog(true)}>
                    <AddIcon />
                    {formatMessage({ id: 'appointment.sidebar.button.newPatient' })}
                  </Button>}
                </div>}

                {data.patient && <div>
                  <div className={classes.patientInfo} style={{ fontWeight: '500' }}>
                    {selectPatientData.name}
                  </div>
                  <div className={clsx(classes.patientInfo, classes.row)}>
                    <label>
                      {formatMessage({ id: 'appointment.sidebar.patientInfo.gender.root' })}
                      : {selectPatientData.gender && formatMessage({ id: `appointment.sidebar.patientInfo.gender.${selectPatientData.gender}` })}
                    </label>
                    <label>
                      {formatMessage({ id: 'appointment.sidebar.patientInfo.age' })}
                      : {calculateAge(selectPatientData.birthDate)}
                    </label>
                  </div>
                  <div className={clsx(classes.patientInfo, classes.row)}>
                    <label>
                      {formatMessage({ id: 'appointment.sidebar.patientInfo.phone' })}
                      : {selectPatientData.phone}
                    </label>
                    <label>
                      {formatMessage({ id: 'appointment.sidebar.patientInfo.birthDate' })}
                      : {selectPatientData.birthDate}
                    </label>
                  </div>
                  <div className={clsx(classes.patientInfo, classes.row)}>
                    <label>
                      {formatMessage({ id: 'appointment.sidebar.patientInfo.salesRep' })}
                      : {userMapping[selectPatientData.salesRep] && userMapping[selectPatientData.salesRep].displayName}
                    </label>
                  </div>
                </div>}
                <div className={classes.row} style={{ marginTop: '20px' }}>
                  <TextField
                    select
                    type="text"
                    size="small"
                    variant="standard"
                    label={formatMessage({ id: 'appointment.sidebar.patientInfo.doctor' })}
                    fullWidth
                    disabled={(data.appointmentType === 'beautyTreatment' || data.appointmentType === '') ? true : false}
                    value={data.doctor}
                    onChange={e => updateData({ name: 'doctor' }, e.target.value)}
                  >
                    {
                      filterDoctors.map((doctor, idx) => {
                        return doctor.active && <MenuItem key={`${idx}`} value={doctor.id}>{doctor.displayName}</MenuItem>
                      })
                    }
                  </TextField>
                  <TextField
                    select
                    style={{ marginLeft: '10px' }}
                    type="text"
                    size="small"
                    variant="standard"
                    label={formatMessage({ id: 'appointment.sidebar.patientInfo.nurse' })}
                    fullWidth
                    value={data.nurse}
                    onChange={e => updateData({ name: 'nurse' }, e.target.value)}
                  >
                    {
                      process.env.BRANCH_ENV !== 'ibeauty' ? nurses.map(nurse => {
                        return <MenuItem key={nurse.id} value={nurse.id}>{nurse.displayName}</MenuItem>
                      }) : nurses.concat(salesReps).map(nurse => {
                        return <MenuItem key={nurse.id} value={nurse.id}>{nurse.displayName}</MenuItem>
                      })
                    }
                  </TextField>
                </div>
                <div className={classes.row} style={{ marginTop: '20px' }}>
                  <TextField
                    select
                    type="text"
                    size="small"
                    variant="standard"
                    label={formatMessage({ id: 'appointment.sidebar.patientInfo.source.root' })}
                    fullWidth
                    value={data.source}
                    onChange={e => updateData({ name: 'source' }, e.target.value)}
                  >
                    <MenuItem value='phone'>{<FormattedMessage id='appointment.sidebar.patientInfo.source.phone' />}</MenuItem>
                    <MenuItem value='internet'>{<FormattedMessage id='appointment.sidebar.patientInfo.source.internets' />}</MenuItem>
                    <MenuItem value='salesRep'>{<FormattedMessage id='appointment.sidebar.patientInfo.source.salesRep' />}</MenuItem>
                    {['lexcellence', 'ibeauty'].includes(process.env.BRANCH_ENV) &&
                      <MenuItem value='ddapp'>{<FormattedMessage id='appointment.sidebar.patientInfo.source.ddapp' />}</MenuItem>
                    }
                  </TextField>
                  <TextField
                    select
                    style={{ marginLeft: '10px' }}
                    type="text"
                    size="small"
                    variant="standard"
                    label={formatMessage({ id: 'appointment.sidebar.patientInfo.salesRep' })}
                    fullWidth
                    value={data.salesRep}
                    onChange={e => updateData({ name: 'salesRep' }, e.target.value)}
                  >
                    {
                      salesReps.map(salesRep => {
                        return <MenuItem key={salesRep.id} value={salesRep.id}>{salesRep.displayName}</MenuItem>
                      })
                    }
                  </TextField>
                </div>
                <div className={classes.row} style={{ marginTop: '20px' }}>
                  <TextField
                    select
                    type="text"
                    size="small"
                    variant="standard"
                    label={formatMessage({ id: 'appointment.sidebar.appointmentType.root' })}
                    fullWidth
                    value={data.appointmentType}
                    onChange={e => updateData({ name: 'appointmentType' }, e.target.value)}
                  >
                    {
                      appointmentTypeStrings.map((type, idx) => {
                        return <MenuItem key={`${idx}`} value={type}>{formatMessage({ id: `appointment.sidebar.appointmentType.${type}` })}</MenuItem>
                      })
                    }
                  </TextField>
                  <TextField
                    select
                    style={{ marginLeft: '10px' }}
                    type="text"
                    size="small"
                    variant="standard"
                    label={formatMessage({ id: 'appointment.sidebar.patientInfo.room' })}
                    fullWidth
                    value={data.room}
                    onChange={e => updateData({ name: 'room' }, e.target.value)}
                  >
                    {
                      rooms.map((room, idx) => {
                        return <MenuItem key={`${idx}`} value={room}>{room}</MenuItem>
                      })
                    }
                  </TextField>
                </div>
                <div className={classes.row} style={{ marginTop: '20px' }}>
                  <TextField
                    type="text"
                    size="small"
                    variant="standard"
                    label={formatMessage({ id: 'appointment.sidebar.patientInfo.comment' })}
                    fullWidth
                    value={data.comment}
                    multiline
                    onChange={e => updateData({ name: 'comment' }, e.target.value)}
                  />
                </div>
              </div>}
              {activeTabIndex === 1 && <div>
                <div style={{ marginTop: '10px' }}>
                  <Button color="primary" onClick={handleMenu}>
                    <AddIcon />
                    {formatMessage({ id: 'appointment.sidebar.button.arrangeTreatments' })}
                  </Button>
                  <Menu
                    id="menu-appbar"
                    className={classes.menuIndex}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={menuOpen}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => { setShowProductSelect(true); handleMenuClose() }}>
                      <FormattedMessage id="appointment.sidebar.treatmentType.product" />
                    </MenuItem>
                    {Object.keys(treatmentTemplates).map((type, key) => (
                      type !== 'product' && <MenuItem key={key} onClick={() => selectTreatment(treatmentTemplates[type])}>
                        {treatmentTemplates[type].displayName}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
                {treatmentList.length ?
                  <SortableList
                    distance={5}
                    items={treatmentList}
                    onSortEnd={onSortEnd}
                    shouldCancelStart={shouldCancelStart}
                    nurses={nurses}
                    handleChange={handleTreatmentChange}
                  /> :
                  <div style={{
                    height: '80px',
                    borderRadius: '4px',
                    border: '2px dashed #979797',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '15px',
                    opacity: 0.5,
                    marginTop: '5px'
                  }}>
                    <FormattedMessage id={'appointment.sidebar.noTreatments'} />
                  </div>}
              </div>}
            </div>
          </form>
          <div className={classes.footer}>
            <Button size='large' onClick={onRequestClose}>{formatMessage({ id: 'button.cancel' })}</Button>
            <div className={classes.wrapper}>
              <Button size='large' onClick={() => saveAppointment()} style={saveButtonColor} disabled={loadingApprove} >{formatMessage({ id: 'button.save' })}</Button>
              {loadingApprove && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        </div>
      </ThemeProvider >
    </StyledEngineProvider>
  );
}

AppointmentSidebar.propTypes = {
  userRight: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired
};

export default AppointmentSidebar;
